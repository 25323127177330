import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Layout from '../../../components/mui/Layout'
import Feedback from '../../../components/mui/Feedback'
import DetailedViewProperty from '../../propertyDetails'
import CustomerModalComponent from '../../dashboard/filtered/FilteredPreviewPage/components/CustomerModalComponent'
import { showById } from '../../../redux/actions/filtered/adviserForm/filteredAdviserAction'

const CallCenterPage = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector( state => state.auth.isAuthenticated)
  const isLoading = useSelector(state => state.loading.isLoading)
  const isCompletedForm = useSelector(state => state.filtered.isCompletedForm)
  const isOpenModal = useSelector( state => state.filteredAdviser.isOpenCustomerModal)
  const formData = useSelector(state => state.filteredAdviser.formData)
  const arrayListFavorites = useSelector(state => state.filteredAdviser.arrayListFavorites)
  const params = useParams()
  const uID = params.uID
  
  /**
   *
   * @description Execute endpoint show by id filtered for adviser
   * @return dispatch
   *
   */
  const handleShowById = async () => await dispatch( showById(uID) )

  useEffect(() => {
    handleShowById()
  }, [uID, isCompletedForm])

  return (
    <Layout.Page title="Filtrado - Ficha Comercial">
      <Feedback.ScrollBar style={{ height: 'calc(100vh - 15px)', overflowX: 'hidden' }}>
        {!isLoading && (
          <DetailedViewProperty
            isAuthenticated={isAuthenticated}
            isAuthenticatedPage={false}
            hasHeader={true}
            uID={uID}
            formData={formData}
            hasFavorites={arrayListFavorites.length === 0 ? false : true}
          />
        )}
      </Feedback.ScrollBar>

      <CustomerModalComponent
        isAuthenticated={isAuthenticated}
        isAuthenticatedPage={false}
        isOpenModal={isOpenModal}
        filterID={uID}
        customerID={formData.customer_id}
      />
      
      <Feedback.ModalLoading
        maxWidth="xs"
        isOpen={isLoading}
        title="Cargando..."
        content="Espere un momento por favor..."
      />
    </Layout.Page>
  )
}

export default CallCenterPage
