import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import TableActivityLog from './TableActivityLog'
import Feedback from '../../components/mui/Feedback'
import { setActivityLogReset } from '../../redux/actions/dashboard/activities/activityLogAction'

const ModalActivityLog = ({ isOpen, page, pages, logName, subjectId }) => {
	const dispatch = useDispatch()
	
	useEffect(() => {
	}, [isOpen, page, pages, logName, subjectId])
	
	const handleCloseModal = async () => await dispatch( setActivityLogReset() )
	
	return (
		<Feedback.Modal
			maxWidth="lg"
			isOpen={isOpen}
			isClose={handleCloseModal}
			title={`Logs de Actividades (${logName})`}
		>
			<TableActivityLog
				page={page}
				pages={pages}
				logName={logName}
				subjectId={subjectId}
			/>
		</Feedback.Modal>
	)
}

ModalActivityLog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	page: PropTypes.number.isRequired,
	pages: PropTypes.number.isRequired,
	logName: PropTypes.string.isRequired,
	subjectId: PropTypes.any.isRequired
}

export default ModalActivityLog
