import React, { useState } from 'react'
import MuiSelect from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFormLabel-root.Mui-focused': {
            fontWeight: '500',
            color: '#1b1b1b !important',
            fontSize: '18px'
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid #1b1b1b !important'
        },
        '& .MuiInput-underline:before:hover': {
            borderBottom: '2px solid #1b1b1b !important'
        }
    },
    formControl: {
        width: '100%'
    }
}))

export default function SelectMultiple(props) {
    const classes = useStyles()
    const { label, name, variant, arrayDropdown, getState, error, message, ...other } = props
    const [itemMultiple, setItemMultiple] = useState([])
    
    const handleChange = (event) => {
        const { name, value } = event.target
        const _tmp = []
        for (let i = 0; i < value.length; i += 1) {
            _tmp.push(value[i])
        }
        getState(_tmp)
        setItemMultiple(_tmp)
    }

    return (
        <FormControl className={classes.formControl} error={error || false}>
            <InputLabel id={name}>{label}</InputLabel>
            <MuiSelect
                labelId={name}
                id={name}
                multiple
                name={name}
                variant={variant || "standard"}
                value={itemMultiple}
                onChange={handleChange}
                classes={{ root: classes.root }}
                {...other}
            >
                <MenuItem value="">-- Seleccione una opción --</MenuItem>
                {
                    arrayDropdown.map((item, index) => (
                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                    ))
                }
            </MuiSelect>
            <FormHelperText>{message || ""}</FormHelperText>
        </FormControl>
    )
}