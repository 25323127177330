import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import { Permission } from '../../../../../services/permissions/Permission'
import errorServices from '../../../../../services/errors'
import { swalSuccess } from '../../../../../services/sweetalert'
import axios from '../../../../../services/axios'

const FormComponent = ({ uID, handleRefresh, handleCloseModal }) => {
	const { permissions } = useSelector( state  => state.auth.userData)
	const tokenType = useSelector(state => state.auth.tokenType)
	const accessToken = useSelector(state => state.auth.accessToken)
	const [isLoading, setLoading] = useState(false)
	const [arrayArea, setArrayArea] = useState([])
	const [arrayPropertyFloor, setArrayPropertyFloor] = useState([])
	const [arrayAreaComercial, setArrayAreaComercial] = useState([])
	const [formData, setFormData] = useState({
		area_id: '',
		area_name: '',
		amenities: [],
		floor_id: '',
		width: '',
		long: '',
		floor_condition: 0,
		paint_condition: 0,
		roof_condition: 0,
		windows_condition: 0,
		doors_condition: 0,
		general_condition: 0,
		comments: '',
	})
	
	useEffect(() => {
	}, [uID])
	
	const validationSchema = () => (
		Yup.object({
			area_id: Yup.string()
			.required('El tipo de área es requerido'),
			area_name: Yup.string()
			.required('El nombre del área es requerido'),
			floor_id: Yup.string()
			.required('El piso es requerido')
		})
	)
	
	const handleDropdownArea = async () => {
		if (permissions.find((item) => item === 'areas.dropdown')) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const data = await axios.get(`dashboard/settings/areas`)
				
				if (data.status === 200) {
					setArrayArea(data.data.data.records)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	useEffect(() => {
		handleDropdownArea()
	}, [])
	
	const handleDropdownPropertyFloor = async () => {
		if (permissions.find((item) => item === 'propertyFloors.dropdown')) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const data = await axios.get(`dashboard/settings/properties/floors`)
				
				if (data.status === 200) {
					setArrayPropertyFloor(data.data.data.records)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	useEffect(() => {
		handleDropdownPropertyFloor()
	}, [])
	
	const handleDropdownAreaComercial = async (id) => {
		if (permissions.find((item) => item === 'amenities.dropdownCommercialArea')) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const data = await axios.post(`dashboard/settings/amenities/by-commercial-area`, { search: id })
				
				if (data.status === 200) {
					setArrayAreaComercial(data.data.data)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	const hanbleSubmit = async () => {
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			let amenities = []
			
			if (formData.amenities.length > 0) {
				formData.amenities.map((item) => {
					amenities.push({ "id": item })
				})
			}

			const param = {
				id: uID,
				area_id: formData.area_id === '' ? null : formData.area_id,
				area_name: formData.area_name === '' ? null : formData.area_name,
				floor_id: formData.floor_id === '' ? null : formData.floor_id,
				amenities: amenities.length === 0 ? [] : amenities,
				width: formData.width === '' ? null : formData.width,
				long: formData.long === '' ? null : formData.long,
				floor_condition: formData.floor_condition === '' ? null : formData.floor_condition,
				paint_condition: formData.paint_condition === '' ? null : formData.paint_condition,
				roof_condition: formData.roof_condition === '' ? null : formData.roof_condition,
				windows_condition: formData.windows_condition === '' ? null : formData.windows_condition,
				doors_condition: formData.doors_condition === '' ? null : formData.doors_condition,
				general_condition: formData.general_condition === '' ? null : formData.general_condition,
				comments: formData.comments === '' ? null : formData.comments
			}

			const data = await axios.post(`dashboard/properties/areas`, param)

			if (data.status === 200) {
				swalSuccess(data.data.data)
				handleRefresh()
				handleCloseModal()
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	const handleOnChange = ({ target }) => setFormData({...formData, [target.name]: target.value})
	
	return (
		<Formik
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={() => {}}
		>
			{({
			  errors,
			  touched,
			  handleChange,
			  handleBlur,
			  isValid,
			  dirty
		  }) => (
				<Form noValidate>
					<div className="row">
						<Permission permission="areas.dropdown">
							<div className="col-sm-12 col-md-6 mb-3">
								<Inputs.Select
									size="small"
									label="Tipo de área"
									name="area_id"
									value={formData.area_id}
									itemsArray={arrayArea}
									onChange={(e) => {
										handleDropdownAreaComercial(e.target.value)
										handleOnChange(e)
										handleChange(e)
									}}
									onBlur={handleBlur}
									hasError={errors.area_id && touched.area_id ? true : false}
									errorMessage={errors.area_id && touched.area_id ? errors.area_id : ''}
									className="font-family-roboto-regular fs-6 text-dark w-100"
								/>
							</div>
						</Permission>
						<div className="col-sm-12 col-md-6 mb-3">
							<Inputs.TextBox
								size="small"
								name="area_name"
								label="Nombre del área"
								placeholder="Ingrese nombre del área..."
								hasLabelFixed={true}
								value={formData.area_name}
								onChange={(e) => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								hasError={errors.area_name && touched.area_name ? true : false}
								errorMessage={errors.area_name && touched.area_name ? errors.area_name : ''}
								className="font-family-roboto-medium text-dark fs-6 w-100"
							/>
						</div>
						<Permission permission="amenities.dropdownCommercialArea">
							<div className="col-sm-12 col-md-6 mb-3">
								<Inputs.SelectMultiple
									label="Amenidades"
									name="amenities"
									value={formData.amenities}
									itemsArray={arrayAreaComercial}
									onChange={handleOnChange}
									className="font-family-roboto-medium text-dark fs-6 w-100"
								/>
							</div>
						</Permission>
						<Permission permission="propertyFloors.dropdown">
							<div className="col-sm-12 col-md-6 mb-3">
								<Inputs.Select
									label="Piso"
									name="floor_id"
									value={formData.floor_id}
									itemsArray={arrayPropertyFloor}
									onChange={(e) => {
										handleOnChange(e)
										handleChange(e)
									}}
									onBlur={handleBlur}
									hasError={errors.floor_id && touched.floor_id ? true : false}
									errorMessage={errors.floor_id && touched.floor_id ? errors.floor_id : ''}
									className="font-family-roboto-regular fs-6 text-dark w-100"
								/>
							</div>
						</Permission>
						<div className="col-sm-12 col-md-6 mb-3">
							<Inputs.TextBox
								name="width"
								label="Ancho"
								placeholder="Ingrese ancho..."
								hasLabelFixed={true}
								value={formData.width}
								onChange={handleOnChange}
								className="font-family-roboto-medium text-dark fs-6 w-100"
							/>
						</div>
						<div className="col-sm-12 col-md-6 mb-3">
							<Inputs.TextBox
								name="long"
								label="Largo"
								placeholder="Ingrese largo..."
								hasLabelFixed={true}
								value={formData.long}
								onChange={handleOnChange}
								className="font-family-roboto-medium text-dark fs-6 w-100"
							/>
						</div>
						<div className="col-sm-12 col-md-6 mb-3">
							<Inputs.Rating
								size="large"
								name="floor_condition"
								hasLabel={true}
								label="Estado del piso"
								value={formData.floor_condition}
								max={5}
								onChange={handleOnChange}
							/>
						</div>
						<div className="col-sm-12 col-md-6 mb-3">
							<Inputs.Rating
								size="large"
								name="paint_condition"
								hasLabel={true}
								label="Estado de la pintura"
								value={formData.paint_condition}
								max={5}
								onChange={handleOnChange}
							/>
						</div>
						<div className="col-sm-12 col-md-6 mb-3">
							<Inputs.Rating
								size="large"
								name="roof_condition"
								hasLabel={true}
								label="Estado del techo"
								value={formData.roof_condition}
								max={5}
								onChange={handleOnChange}
							/>
						</div>
						<div className="col-sm-12 col-md-6 mb-3">
							<Inputs.Rating
								size="large"
								name="windows_condition"
								hasLabel={true}
								label="Estado de las ventanas"
								value={formData.windows_condition}
								max={5}
								onChange={handleOnChange}
							/>
						</div>
						<div className="col-sm-12 col-md-6 mb-3">
							<Inputs.Rating
								size="large"
								name="doors_condition"
								hasLabel={true}
								label="Estado de las puertas"
								value={formData.doors_condition}
								max={5}
								onChange={handleOnChange}
							/>
						</div>
						<div className="col-sm-12 col-md-6 mb-3">
							<Inputs.Rating
								size="large"
								name="general_condition"
								hasLabel={true}
								label="Estado general"
								value={formData.general_condition}
								max={5}
								onChange={handleOnChange}
							/>
						</div>
						<div className="col-sm-12 mb-3">
							<Inputs.TextArea
								size="small"
								name="comments"
								label="Comentarios"
								placeholder="Ingrese comentarios..."
								hasLabelFixed={true}
								value={formData.comments}
								onChange={handleOnChange}
								className="font-family-roboto-medium text-dark fs-6 w-100"
							/>
						</div>
						<Permission permission="properties.areas.store">
							<div className="col-sm-12">
								<Inputs.LoadingButton
									isDisabled={!(dirty && isValid)}
									variant="contained"
									isLoading={isLoading}
									isLoadingPosition="start"
									startIcon={<Layout.Icons.SaveIcon height={18} width={18} />}
									label="Guardar"
									type="button"
									onClick={hanbleSubmit}
									className={`${(dirty && isValid) && 'bg-primary'} font-family-roboto-regular fs-6 text-capitalize mt-2 d-flex float-end`}
								/>
							</div>
						</Permission>
					</div>
				</Form>
			)}
		</Formik>
	)
}

FormComponent.propTypes = {
	uID: PropTypes.string.isRequired,
	handleRefresh: PropTypes.func.isRequired,
	handleCloseModal: PropTypes.func.isRequired,
}

export default FormComponent
