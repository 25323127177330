import React from 'react'
import PropTypes from 'prop-types'
import MuiSkeleton from '@mui/material/Skeleton'

const Skeleton = ({ variant, width, height, ...more }) => {
	return (
		<MuiSkeleton
			variant={variant}
			width={width}
			height={height}
			{...more}
		/>
	)
}

Skeleton.propTypes = {
	variant: PropTypes.string.isRequired,
	width: PropTypes.any,
	height: PropTypes.any
}

export default Skeleton
