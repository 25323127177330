export const PER_PAGE_CITY = 'PER_PAGE_CITY';
export const PAGE_CITY = 'PAGE_CITY';
export const SEARCH_CITY = 'SEARCH_CITY';
export const PAGES_CITY = 'PAGES_CITY';
export const ARRAY_LIST_CITY = 'ARRAY_LIST_CITY';
export const ARRAY_LIST_TO_DROPDOWN_CITY = 'ARRAY_LIST_TO_DROPDOWN_CITY';
export const REFRESH_TABLE_CITY = 'REFRESH_TABLE_CITY';
export const IS_OPEN_MODAL_CITY = 'IS_OPEN_MODAL_CITY';
export const IS_LOADING_SHOW_CITY = 'IS_LOADING_SHOW_CITY';
export const IS_LOADING_FORM_CITY = 'IS_LOADING_FORM_CITY';
export const FORM_TYPE_CITY = 'FORM_TYPE_CITY';
export const FORM_DATA_CITY = 'FORM_DATA_CITY';
