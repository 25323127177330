import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Layout from '../Layout'

const Modal = ({ maxWidth, isOpen, isClose, title = '', children, hasAction = false, contentActions = null, ...more }) => {
	
	return (
		<Dialog
			disableEscapeKeyDown
			fullWidth={true}
			maxWidth={maxWidth}
			open={isOpen}
			{...more}
		>
			<DialogTitle
				className="p-3"
			>
				<div className="font-family-roboto-medium fs-4 text-dark-light d-inline">
					{title}
				</div>
				<Layout.IconButton
					color="inherit"
					size="small"
					onClick={isClose}
					className="float-end bg-secondary w-26px h-26px"
				>
					<Layout.Icons.CloseIcon
						height={16}
						width={16}
						className="text-white"
					/>
				</Layout.IconButton>
			</DialogTitle>
			<DialogContent
				dividers
				{...more}
			>
				{children}
			</DialogContent>
			{hasAction && (
				<DialogActions>
					{contentActions}
				</DialogActions>
			)}
		</Dialog>
	)
}

Modal.propTypes = {
	maxWidth: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	isClose: PropTypes.func.isRequired,
	title: PropTypes.string,
	children: PropTypes.any.isRequired,
	hasAction: PropTypes.bool,
	contentActions: PropTypes.any
}

export default Modal
