import React from 'react'
import PropTypes from 'prop-types'
import MuiAvatar from '@mui/material/Avatar'

const Avatar = ({ alt, src = '', size = 'medium', icon, children = null  }) => {
	
	return (
		<MuiAvatar
			alt={alt}
			src={src}
			size={size}
		>
			{icon ? children : null}
		</MuiAvatar>
	)
}

Avatar.propTypes = {
	alt: PropTypes.string.isRequired,
	src: PropTypes.string,
	size: PropTypes.string,
	icon: PropTypes.bool.isRequired,
	children: PropTypes.any
}

export default Avatar
