import * as types from '../../../actionsTypes/algolia/algoliaPropertyTypesTypes'
import { getArrayToDropdown } from './services'
import errorServices from '../../../../services/errors'

export const getArrayToDropdownPropertyType = param => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getArrayToDropdown(param).then(({ data }) => {
			dispatch( setArrayListToDropdown(data.data) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const setAlgoliaPropertyTypeReset = () => (
	async (dispatch) => {
		await dispatch( setArrayListToDropdown([]) )
		await dispatch( setIsLoading(false) )
	}
)

export const setArrayListToDropdown = value => ({
	type: types.ARRAY_LIST_TO_DROPDOWN_ALGOLIA_PROPERTY_TYPE,
	payload: value
})

export const setIsLoading = value => ({
	type: types.IS_LOADING_ALGOLIA_PROPERTY_TYPE,
	payload: value
})
