import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Feedback from '../../../../../components/mui/Feedback';
import Navigation from '../../../../../components/mui/Navigation';
import FormProfileComponent from './FormProfileComponent';
import FormPasswordResetComponent from './FormPasswordResetComponent';
import { getShowProfile } from '../services';
import { setFormData, setIsOpenModal, setIsLoadingShow } from '../redux/actions/profileAction';
import usePermission from '../../../../../hooks/usePermission';
import swalErrors from '../../../../../hooks/useErrors';
import { initialValuesProfile as initialValues } from '../../../../../helpers/variablesInitialValues';

const ModalProfileComponent = () => {
	const dispatch = useDispatch();
	const isOpenModal = useSelector( ({ profile }) => profile.isOpenModal);
	const permissionShowProfile = usePermission('users.showProfile');
	const permissionUpdateProfile = usePermission('users.updateProfile');
	const permissionUpdateProfilePassword = usePermission('users.updateProfilePassword');
	const [value, setValue] = useState(0);
	
	const handleShowProfile = async () => {
		if (isOpenModal && value === 0 && permissionShowProfile) {
			await dispatch( setIsLoadingShow(true) );
			
			try {
				const { data } = await getShowProfile();
				
				if (data.status === 200) {
					const object = {
						firstName: data.data.first_name,
						lastName: data.data.last_name,
						email: data.data.email,
						mobile: data.data.mobile,
						phone: data.data.phone,
						address: data.data.address,
					};
					
					await dispatch( setFormData(object) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingShow(false) );
		}
	};
	
	useEffect(() => {
		handleShowProfile();
	}, [isOpenModal, value]);
	
	const handleChange = (event, newValue) => {;
		if (newValue === 1) {
			dispatch( setFormData(initialValues) );
		}
		
		setValue(newValue);
	};
	
	const handleCloseModal = async () => {
		await dispatch( setFormData(initialValues) );
		await dispatch( setIsOpenModal(false) );
	};
	
	const handlePropsTabs = index => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	};
	
	return (
		<Feedback.Modal
			maxWidth="md"
			isOpen={isOpenModal}
			isClose={handleCloseModal}
			title="Actualización del perfil"
		>
			<Tabs
				value={value}
				onChange={handleChange}
				centered
			>
				<Tab
					label="Perfil"
					{...handlePropsTabs(0)}
				/>
				<Tab
					label="Seguridad"
					{...handlePropsTabs(1)}
				/>
			</Tabs>
			
			<Navigation.TabPanel
				value={value}
				index={0}
			>
				{permissionUpdateProfile && (
					<FormProfileComponent />
				)}
			</Navigation.TabPanel>
			<Navigation.TabPanel
				value={value}
				index={1}
			>
				{permissionUpdateProfilePassword && (
					<FormPasswordResetComponent />
				)}
			</Navigation.TabPanel>
		</Feedback.Modal>
	);
};

export default ModalProfileComponent;
