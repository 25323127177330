import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from "@material-ui/core/IconButton"
import Display from "../../../../../components/display/Display"
import Controls from "../../../../../components/controls/Controls"
import Feedback from "../../../../../components/feedback/Feedback"
import errorServices from '../../../../../services/errors'
import axios from '../../../../../services/axios'
import { swalSuccess } from '../../../../../services/sweetalert'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		// top: theme.spacing(1)
	},
}))

const ModalKey = ({ getPropertyID, open, setOpen, handleList }) => {
	const classes = useStyles()
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [getLoading, setLoading] = useState(false)
	const [arrayDropdownKey, setDropdownKey] = useState([])
	const [getKeyID, setKeyID] = useState('')
	const [getKeyIDError, setKeyIDError] = useState(false)
	const [getKeyIDMessage, setKeyIDMessage] = useState('')
	const [arrayDropdownAdviser, setDropdownAdviser] = useState([])
	const [getAdviserID, setAdviserID] = useState('')
	const [showAdviser, setShowAdviser] = useState(false)
	const [getQuantity, setQuantity] = useState('')
	const [getQuantityError, setQuantityError] = useState(false)
	const [getQuantityMessage, setQuantityMessage] = useState('')
	const [getDescription, setDescription] = useState('')
	
	useEffect(() => {
	}, [getPropertyID])
	
	const handleShowAdviser = value => {
		open && setShowAdviser(value === 1 ? true : false)
	}
	
	useEffect(() => {
		handleShowAdviser()
	}, [open])
	
	const handleDropdownKey = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get('dashboard/settings/keys')
			
			if (data.status === 200) {
				setDropdownKey(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
		open && handleDropdownKey()
	}, [open])
	
	const handleDropdownAdviser = async () => {
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				realstate: 1
			}
			
			const data = await axios.post('dashboard/settings/users/by-adviser', param)
			
			if (data.status === 200) {
				setDropdownAdviser(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
		open && handleDropdownAdviser()
	}, [open])
	
	const handleClose = () => {
		setOpen(false)
	}
	
	const setHandleForm = async (e) => {
		e.preventDefault()
		if (open) {
			if (getKeyID === "" || getQuantity === "") {
				setKeyIDError(getKeyID === "" ? true : false)
				setKeyIDMessage(getKeyID === "" ? "El campo es requerido" : "")
				setQuantityError(getQuantity === "" ? true : false)
				setQuantityMessage(getQuantity === "" ? "El campo es requerido" : "")
				return false
			}
			
			setLoading(true)
			
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const param = {
					id: getPropertyID,
					key_id: getKeyID,
					quantity: getQuantity,
					adviser_id: getAdviserID,
					description: getDescription
				}
				
				const data = await axios.post('dashboard/properties/keys', param)
				
				if (data.status === 200) {
					setShowAdviser(false)
					setKeyID('')
					setKeyIDError(false)
					setKeyIDMessage('')
					setQuantity('')
					setQuantityError(false)
					setQuantityMessage('')
					setAdviserID('')
					setDescription('')
					swalSuccess(data.data.data)
					handleList()
					handleClose()
				}
			} catch (error) {
				await errorServices(error)
			}
			
			setLoading(false)
		}
	}
	
	return (
		<>
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				fullWidth={true}
				maxWidth="sm"
				aria-labelledby="dialogForm"
				open={open}
			>
				<DialogTitle id="dialogForm">
					Captación de la llaves
					
					<IconButton
						onClick={handleClose}
						className="bg-white height-32 width-32"
						classes={{ root: classes.closeButton }}
						aria-label="like"
					>
						<Display.Icons.CloseIcon
							className="text-dark"
							style={{ fontSize: 20 }}
						/>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							
							<Grid item xs={12}>
								<Controls.Select
									label="Ubicación"
									name="key_id"
									defaultValue={getKeyID}
									error={getKeyIDError}
									message={getKeyIDMessage}
									onChange={(e) => {
										handleShowAdviser(e.target.value)
										setKeyID(e.target.value)
										
										if(e.target.value === ""){
											setKeyIDError(true)
											setKeyIDMessage('El campo es requerido')
										} else {
											setKeyIDError(false)
											setKeyIDMessage('')
										}
									}}
									arrayDropdown={arrayDropdownKey}
								/>
							</Grid>
							{showAdviser && (
								<Grid item xs={12} className="animate animate__fadeIn">
									<Controls.Select
										label="Asesor"
										name="adviser_id"
										defaultValue={getAdviserID}
										onChange={(e) => setAdviserID(e.target.value)}
										arrayDropdown={arrayDropdownAdviser}
									/>
								</Grid>
							)}
							<Grid item xs={12}>
								<Controls.TextFieldNumber
									label="Cantidad"
									name="quantity"
									defaultValue={getQuantity}
									error={getQuantityError}
									message={getQuantityMessage}
									onChange={e => {
										setQuantity(e.target.value)
										
										if(e.target.value === ""){
											setQuantityError(true)
											setQuantityMessage('El campo es requerido')
										} else {
											setQuantityError(false)
											setQuantityMessage('')
										}
									}}
									onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
								/>
							</Grid>
							<Grid item xs={12}>
								<Controls.TextArea
									label="Descripción"
									name="description"
									defaultValue={getDescription}
									onChange={e => setDescription(e.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<div className="float-right">
									<Controls.Button
										label="Guardar"
										color="primary"
										variant="contained"
										endIcon={<Display.Icons.SaveOutlinedIcon />}
										onClick={setHandleForm}
									/>
								</div>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
			<Feedback.DialogLoading
				getLoading={getLoading}
				setLoading={setLoading}
			/>
		</>
	)
}

export default ModalKey
