import { initialValuesAuth as initialValues } from '../../../../helpers/variablesInitialValues';
import * as types from '../types/authType';
import { setResetForgotPassword } from './forgotPasswordAction';
import { setResetPasswordReset } from './passwordResetAction';
import { setResetVerification } from './verificationAction';
import { setResetLoading } from '../../../../redux/loading/actions/loadingAction';
import { setAlgoliaPropertyReset } from '../../../../redux/actions/algolia/properties/algoliaPropertyAction';
import { setAlgoliaCityReset } from '../../../../redux/actions/algolia/cities/algoliaCityAction';
import { setAlgoliaNeighborhoodReset } from '../../../../redux/actions/algolia/negihborhoods/algoliaNeighborhood';
import { setOwnerAuthorizationReset } from '../../../../redux/actions/owners/authorization/ownerAthorizationAction';
import { setOwnerInventoryReset } from '../../../../redux/actions/owners/inventory/ownerInventoryAction';
import { setFilteredAdviserReset } from '../../../../redux/actions/filtered/adviserForm/filteredAdviserAction';
import { setIsOpenSidebar, setIsLoadingSidebar } from '../../../../redux/sidebar/actions/sidebarAction';
import { setActivityLogReset } from '../../../../redux/actions/dashboard/activities/activityLogAction';
import { setPermissionReset } from '../../../dashboard/settings/PermissionPage/redux/actions/permissionAction';
import { setRoleReset } from '../../../dashboard/settings/RolePage/redux/actions/roleAction';
import { setRealStateReset } from '../../../dashboard/settings/RealStatePage/redux/actions/realStateAction';
import { setUserReset } from '../../../dashboard/settings/UserPage/redux/actions/userAction';
import { setProfileReset } from '../../../dashboard/settings/UserPage/redux/actions/profileAction';
import { setBankReset } from '../../../dashboard/settings/BankPage/redux/actions/bankAction';
import { setAreaReset } from '../../../dashboard/settings/AreaPage/redux/actions/areaAction';
import { setAmenityReset } from '../../../dashboard/settings/AmenityPage/redux/actions/amenityAction';
import { setProjectReset } from '../../../dashboard/settings/ProjectPage/redux/actions/projectAction';
import { setKeyReset } from '../../../dashboard/settings/KeyPage/redux/actions/keyAction';
import { setCountryReset } from '../../../dashboard/settings/location/CountryPage/redux/actions/countryAction';
import { setStateReset } from '../../../dashboard/settings/location/StatePage/redux/actions/stateAction';
import { setCityReset } from '../../../dashboard/settings/location/CityPage/redux/actions/cityAction';
import { setNeighborhoodReset } from '../../../dashboard/settings/location/NeighborhoodPage/redux/actions/neighborhoodAction';
import { setLocalityReset } from '../../../dashboard/settings/location/LocalityPage/redux/actions/localityAction';
import { setOriginChannelReset } from '../../../dashboard/settings/filtered/OriginChannelPage/redux/actions/originChannelAction';
import { setContactReset } from '../../../dashboard/settings/filtered/ContactPage/redux/actions/contactAction';
import { setCommercialStatusReset } from '../../../dashboard/settings/commercial/CommercialStatusPage/redux/actions/commercialStatusAction';
import { setBuildingTypeReset } from '../../../dashboard/settings/buildings/BuildingTypePage/redux/actions/buildingTypeAction';
import { setOwnerDomusReset } from '../../../../redux/actions/dashboard/settings/owners/domus/ownerDomusAction';
import { setPropertyDomusReset } from '../../../../redux/actions/dashboard/settings/properties/domus/propertyDomusAction';
import { setPropertyDestinationReset } from '../../../dashboard/settings/properties/PropertyDestinationPage/redux/actions/propertyDestinationAction';
import { setPropertyFacadeReset } from '../../../dashboard/settings/properties/PropertyFacadePage/redux/actions/propertyFacadeAction';
import { setPropertyFloorReset } from '../../../dashboard/settings/properties/PropertyFloorPage/redux/actions/propertyFloorAction';
import { setPropertyReserveReset } from '../../../dashboard/settings/properties/PropertyReservePage/redux/actions/propertyReserveAction';
import { setPropertyRoomServiceReset } from '../../../dashboard/settings/properties/PropertyRoomServicePage/redux/actions/propertyRoomServiceAction';
import { setPropertyStatusReset } from '../../../dashboard/settings/properties/PropertyStatusPage/redux/actions/propertyStatusAction';
import { setPropertyStatusCommercialReset } from '../../../dashboard/settings/properties/PropertyStatusCommercialPage/redux/actions/propertyStatusCommercialAction';
import { setPropertyTypeReset } from '../../../dashboard/settings/properties/PropertyTypePage/redux/actions/propertyTypeAction';
import { setFilteredReset } from '../../../../redux/actions/dashboard/filtered/filteredAction';
import { setCommercialReset } from '../../../dashboard/CommercialPage/redux/actions/commercialAction';
import { setOwnerReset } from '../../../dashboard/OwnerPage/redux/actions/ownerAction';
import { setBuildingReset } from '../../../dashboard/BuildingPage/redux/actions/buildingAction';
import { setPropertyReset } from '../../../../redux/actions/dashboard/properties/property/PropertyAction';

/**
 *
 * @description Set reset global state
 * @return dispatch
 *
 */
export const setReset = () => (
	async (dispatch) => {
		await dispatch( setIsAuthenticated(false) );
		await dispatch( setTokenType('') );
		await dispatch( setAccessToken('') );
		await dispatch( setExpiresAt('') );
		await dispatch( setUserData(initialValues) );
		await dispatch( setResetForgotPassword() );
		await dispatch( setResetPasswordReset() );
		await dispatch( setResetVerification() );
		await dispatch( setResetLoading() );
		await dispatch( setAlgoliaPropertyReset() );
		await dispatch( setAlgoliaCityReset() );
		await dispatch( setAlgoliaNeighborhoodReset() );
		await dispatch( setOwnerAuthorizationReset() );
		await dispatch( setOwnerInventoryReset() );
		await dispatch( setFilteredAdviserReset() );
		await dispatch( setIsOpenSidebar(true) );
		await dispatch( setIsLoadingSidebar(false) );
		await dispatch( setActivityLogReset() );
		await dispatch( setPermissionReset() );
		await dispatch( setRoleReset() );
		await dispatch( setRealStateReset() );
		await dispatch( setUserReset() );
		await dispatch( setProfileReset() );
		await dispatch( setBankReset() );
		await dispatch( setAreaReset() );
		await dispatch( setAmenityReset() );
		await dispatch( setProjectReset() );
		await dispatch( setKeyReset() );
		await dispatch( setCountryReset() );
		await dispatch( setStateReset() );
		await dispatch( setCityReset() );
		await dispatch( setNeighborhoodReset() );
		await dispatch( setLocalityReset() );
		await dispatch( setContactReset() );
		await dispatch( setCommercialStatusReset() );
		await dispatch( setOriginChannelReset() );
		await dispatch( setBuildingTypeReset() );
		await dispatch( setOwnerDomusReset() );
		await dispatch( setPropertyDomusReset() );
		await dispatch( setPropertyDestinationReset() );
		await dispatch( setPropertyFacadeReset() );
		await dispatch( setPropertyFloorReset() );
		await dispatch( setPropertyReserveReset() );
		await dispatch( setPropertyRoomServiceReset() );
		await dispatch( setPropertyStatusReset() );
		await dispatch( setPropertyStatusCommercialReset() );
		await dispatch( setPropertyTypeReset() );
		await dispatch( setFilteredReset() );
		await dispatch( setCommercialReset() );
		await dispatch( setOwnerReset() );
		await dispatch( setBuildingReset() );
		await dispatch( setPropertyReset() );
	}
);

/**
 *
 * @description Set loadingForm state
 * @param value
 * @return object
 *
 */
export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_AUTH,
	payload: value
});

/**
 *
 * @description Set authenticated state
 * @param value
 * @return object
 *
 */
export const setIsAuthenticated = value => ({
	type: types.IS_AUTHENTICATED,
	payload: value
});

/**
 *
 * @description Set tokenType state
 * @param value
 * @return object
 *
 */
export const setTokenType = value => ({
	type: types.TOKEN_TYPE,
	payload: value
});

/**
 *
 * @description Set accessToken state
 * @param value
 * @return object
 *
 */
export const setAccessToken = value => ({
	type: types.ACCESS_TOKEN,
	payload: value
});

/**
 *
 * @description Set expiresAt state
 * @param value
 * @return object
 *
 */
export const setExpiresAt = value => ({
	type: types.EXPIRES_AT,
	payload: value
});

/**
 *
 * @description Set userData state
 * @param value
 * @return object
 *
 */
export const setUserData = value => ({
	type: types.USER_DATA,
	payload: value
});
