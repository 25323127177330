import React from 'react'
import SettingsOutlined from '@material-ui/icons/SettingsOutlined'

export default function SettingsOutlinedIcon(props) {

    const { ...other } = props

    return (
        <SettingsOutlined {...other} />
    )
}