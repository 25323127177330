import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { history } from '../../../../../redux/store'
import Layout from '../../../../../components/mui/Layout'
import Feedback from '../../../../../components/mui/Feedback'
import VerticalCardsLoading from '../../../../../components/properties/VerticalCardsLoading'
import SquarePropertyCard from '../../../../../components/properties/cards/Properties'
import { getArray, setPage } from '../../../../../redux/actions/algolia/properties/algoliaPropertyAction'
import { getArrayListFavorites, storeFavorite } from '../../../../../redux/actions/dashboard/filtered/filteredAction'

const PropertiesComponent = ({ isAuthenticatedPage }) => {
  const dispatch = useDispatch(  )
  const { realStateId } = useSelector( state => state.auth.userData)
  const formData = useSelector(state => state.filtered.formDataDetail)
  const arrayListFavorites = useSelector(state => state.filtered.arrayListFavorites)
  const isActiveSearch = useSelector(state => state.filtered.isActiveSearch)
  const isActiveHistorical = useSelector(state => state.filtered.isActiveHistorical)
  const isSelectedToggleSwitch = useSelector(state => state.filtered.isSelectedToggleSwitch)
  const isLoading = useSelector(state => state.algoliaProperty.isLoading)
  const perPage = useSelector(state => state.algoliaProperty.perPage)
  const page = useSelector(state => state.algoliaProperty.page)
  const hasNextPage = useSelector(state => state.algoliaProperty.hasNextPage)
  const arrayList = useSelector(state => state.algoliaProperty.arrayList)
  
  useEffect(() => {
  }, [isAuthenticatedPage])
  
  /**
   *
   * @description Execute endpoint array list of properties algolia
   * @return dispatch
   *
   */
  const handleArrayList = async (isPagination = false) => {
    const param = {
      per_page: perPage,
      page: page,
      realstate_id: isAuthenticatedPage ? realStateId : formData.realState,
      action_type: formData.service === '' ? null : formData.service,
      search: formData.code === '' ? null : formData.code,
      city: formData.city === '' ? null : formData.city,
      neighborhood: formData.neighborhoods.length === 0 ? null : formData.neighborhoods,
      type: formData.propertyTypes.length === 0 ? null : formData.propertyTypes,
      strata: formData.strata.length === 0 ? null : formData.strata,
      number_room: (formData.numberRoom === '' || formData.numberRoom === 0) ? null : formData.numberRoom,
      number_bathroom: (formData.numberBathRoom === '' || formData.numberBathRoom === 0) ? null : formData.numberBathRoom,
      area_total: (formData.areaTotal === '' || formData.areaTotal === 0) ? null : formData.areaTotal,
      range_total_lease: (formData.rangeTotalLease === '' || formData.rangeTotalLease === 0) ? null : formData.rangeTotalLease,
      range_total_buy: (formData.rangeTotalBuy === '' || formData.rangeTotalBuy === 0) ? null : formData.rangeTotalBuy,
    }

    if (isPagination) {
      await dispatch( getArray(param, arrayList, isPagination, formData.code ?? null) )
    } else {
      await dispatch( getArray(param) )
    }
  }

  useEffect(() => {
    if (isSelectedToggleSwitch === 0) {
      handleArrayList(page === 0 ? false : true)
    }
  }, [
    isSelectedToggleSwitch,
    isActiveSearch,
    formData.realState,
    formData.code,
    page
  ])
  
  /**
   *
   * @description Execute dispatch next page state
   * @return dispatch
   *
   */
  const handleNextPage = () => dispatch( setPage(page + 1) )
  
  /**
   *
   * @description Execute endpoint array list of filtered favorites
   * @return dispatch
   *
   */
  const handleArrayListFavorites = async () => await dispatch( getArrayListFavorites(formData.id) )
  
  /**
   *
   * @description Execute endpoint store of filtered favorites
   * @return dispatch
   *
   */
  const handleStoreLike = async (id) => {
    const param = {
      filter: formData.id,
      property: id
    }

    await dispatch( storeFavorite(param) )
    await handleArrayListFavorites()
  }

  return (
    <>
      {!isActiveHistorical && isSelectedToggleSwitch === 0 && (
        <Layout.Box
          id="scrollableDivProperties"
          width="95%"
          margin="0 auto"
          overflow="scroll"
          className="mt-2 mb-2"
        >
          <Feedback.InfinityScrolling
            dataLength={arrayList.length}
            next={handleNextPage}
            hasMore={hasNextPage}
            loader={isLoading && <VerticalCardsLoading />}
            endMessage={<div className="mt-4 mb-4" />}
            scrollableTarget="scrollableDivProperties"
          >
            <Layout.Box
              gap={2}
              display="grid"
              margin="0 auto"
              gridTemplateColumns="repeat(auto-fill,minmax(300px,1fr))"
            >
              {!isLoading && arrayList.length === 0 && (
                <div className="font-family-roboto-regular fs-6 text-center">No hay datos para esta consulta</div>
              )}
              {!isLoading && arrayList.length > 0 && (
                arrayList.map((item) => {
                  // VALIDATE THE PRICE ACCORDING TO THE SERVICE
                  //let price = item?.action_type === 'Arriendo'
                  //  ? item?.action_canon_string
                  //  : item?.action_type === 'Compra o Venta'
                  //  ? item?.action_sale_string
                  //  : item?.action_type === 'Venta'
                  //  ? item?.action_sale_string
                  //  : item?.action_type === 'Arriendo/Venta'
                  //  ? `${item?.action_canon_string} / ${item?.action_sale_string}`
                  //  : ''

                  //if(index == 0) console.log("PropertiesComponent", {item})

                  return (
                    <SquarePropertyCard
                      key={item?.id}
                      goToDetail={() => history.push(isAuthenticatedPage ? `/dashboard/propiedades/detalle/${item?.property_id}` : `/properties/detail/${item?.id}`)}
                      property={
                        item
                      //   {
                      //   type: `${item?.type} en ${item?.action_type}`,
                      //   price_format: price,
                      //   codpro: item?.domus_code,
                      //   images_count: item?.has_photo,
                      //   image1: item?.path,
                      //   neighborhood: item?.neighborhood,
                      //   city: item?.city,
                      //   area_lot: item?.area_total,
                      //   bathrooms: item?.number_bathroom,
                      //   bedrooms: item?.number_room,
                      //   parking: item?.number_garage,
                      // }
                      }
                      handleStoreLike={() => handleStoreLike(item?.property_action?.property_id)}
                      isActiveLike={arrayListFavorites?.find(({ property_id }) => property_id === item?.property_action?.property_id) ? true : false}
                    />
                  )
                })
              )}
            </Layout.Box>
          </Feedback.InfinityScrolling>
        </Layout.Box>
      )}
    </>
  )
}

PropertiesComponent.propTypes = {
  isAuthenticatedPage: PropTypes.bool.isRequired,
}

export default PropertiesComponent
