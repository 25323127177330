import React from 'react'
import PropTypes from 'prop-types'
import PhotoCamera from '@mui/icons-material/PhotoCamera'

const PhotoCameraIcon = ({ height, width, ...more }) => {
	
	return (
		<PhotoCamera
			height={height}
			width={width}
			{...more}
		/>
	)
}

PhotoCameraIcon.propTypes = {
	height: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired
}

export default PhotoCameraIcon
