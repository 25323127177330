import React from 'react'
import PropTypes from 'prop-types'
import MuiCard from '@mui/material/Card'

const Card = ({ children, ...more }) => {
	
	return (
		<MuiCard {...more}>
			{children}
		</MuiCard>
	)
}

Card.propTypes = {
	children: PropTypes.any.isRequired
}

export default Card
