export const PER_PAGE_BUILDING = 'PER_PAGE_BUILDING';
export const PAGE_BUILDING = 'PAGE_BUILDING';
export const SEARCH_BUILDING = 'SEARCH_BUILDING';
export const REALSTATE_BUILDING = 'REALSTATE_BUILDING';
export const PAGES_BUILDING = 'PAGES_BUILDING';
export const ARRAY_LIST_BUILDING = 'ARRAY_LIST_BUILDING';
export const ARRAY_LIST_TO_DROPDOWN_BUILDING = 'ARRAY_LIST_TO_DROPDOWN_BUILDING';
export const REFRESH_TABLE_BUILDING = 'REFRESH_TABLE_BUILDING';
export const IS_OPEN_MODAL_BUILDING = 'IS_OPEN_MODAL_BUILDING';
export const IS_LOADING_MAP_BUILDING = 'IS_LOADING_MAP_BUILDING';
export const IS_LOADING_SHOW_BUILDING = 'IS_LOADING_SHOW_BUILDING';
export const IS_LOADING_FORM_BUILDING = 'IS_LOADING_FORM_BUILDING';
export const FORM_TYPE_BUILDING = 'FORM_TYPE_BUILDING';
export const FORM_DATA_BUILDING = 'FORM_DATA_BUILDING';
export const FORM_ID_BUILDING = 'FORM_ID_BUILDING';
