import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Collapse from '@mui/material/Collapse'
import CircularProgress from '@mui/material/CircularProgress'
import Layout from '../../../../../components/mui/Layout'
import Feedback from '../../../../../components/mui/Feedback'
import OccurrencesRowComponent from './OccurrencesRowComponent'
import { filteredOccurrenceColumns as columns } from '../../../../../helpers/variablesColumns'
import { Permission } from '../../../../../services/permissions/Permission'

const OccurrencesComponent = ({ isActive }) => {
	const isOpenOccurrences = useSelector( state => state.filtered.isOpenOccurrences)
	const isLoadingOccurrences = useSelector( state => state.filtered.isLoadingOccurrences)
	const { occurrences } = useSelector( state => state.filtered.formData)
	
	useEffect(() => {
	}, [isActive])
	
	return (
		<Permission
			permission="filtereds.verification"
		>
			{isActive && (
				<div className="col-sm-12 mt-3 animate__animated animate__fadeIn">
					{isLoadingOccurrences && (
						<CircularProgress
							className="d-flex mx-auto"
							style={{height: '32px', width: '32px'}}
							color="inherit"
						/>
					)}
					{!isLoadingOccurrences && (
						<Collapse in={isOpenOccurrences}>
							<div className="row">
								<div className="col-sm-12">
									<div className="font-family-roboto-semibold fs-4 text-dark-light">
										Últimos casos
									</div>
								</div>
								<div className="col-sm-12">
									<Feedback.ScrollBar
										style={{ height: '220px' }}
									>
										<Layout.Table
											className="table table-sm table-borderless table-hover align-middle m-0 w-100 max-h-220"
										>
											<Layout.TableHead
												columns={columns}
												className="table-secondary"
												fontClassName="font-family-roboto-bold fs-7"
											/>
											<tbody>
												{occurrences.length > 0 && (
													occurrences.map(({ id, number, status, created_date, created_at, service, amount }, index) => (
														<OccurrencesRowComponent
															key={index}
															position={index}
															id={id}
															number={number}
															status={status}
															createdDate={created_date}
															createdAt={created_at}
															service={service}
															amount={amount}
														/>
													))
												)}
											</tbody>
										</Layout.Table>
									</Feedback.ScrollBar>
								</div>
							</div>
						</Collapse>
					)}
				</div>
			)}
		</Permission>
	)
}

OccurrencesComponent.propTypes = {
	isActive: PropTypes.bool.isRequired
}

export default OccurrencesComponent
