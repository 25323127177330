import * as types from '../types/verificationType';

/**
 *
 * @description Set reset verification state
 * @return dispatch
 *
 */
export const setResetVerification = () => (
	async (dispatch) => {
		await dispatch( setColorStatus('') );
		await dispatch( setTextStatus('') );
		await dispatch( setIsActiveAlert(false) );
		await dispatch( setIsActiveButton(false) );
		await dispatch( setIsLoadingForm(false) );
	}
);

/**
 *
 * @description Set colorStatus state
 * @param value
 * @return object
 *
 */
export const setColorStatus = value => ({
	type: types.COLOR_STATUS_VERIFICATION,
	payload: value
});

/**
 *
 * @description Set textStatus state
 * @param value
 * @return object
 *
 */
export const setTextStatus = value => ({
	type: types.TEXT_STATUS_VERIFICATION,
	payload: value
});

/**
 *
 * @description Set activeAlert state
 * @param value
 * @return object
 *
 */
export const setIsActiveAlert = value => ({
	type: types.IS_ACTIVE_ALERT_VERIFICATION,
	payload: value
});

/**
 *
 * @description Set activeButton state
 * @param value
 * @return object
 *
 */
export const setIsActiveButton = value => ({
	type: types.IS_ACTIVE_BUTTON_VERIFICATION,
	payload: value
});

/**
 *
 * @description Set loadingForm state
 * @param value
 * @return object
 *
 */
export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_VERIFICATION,
	payload: value
});
