import React, {useState} from "react"
import {useSelector} from 'react-redux'
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import IconButton from "@material-ui/core/IconButton"
import Feedback from "../../../../../components/feedback/Feedback"
import Display from "../../../../../components/display/Display"
import ModalAuthorizationByOwnerStatus from "./ModalAuthorizationByOwnerStatus"
import errorServices from '../../../../../services/errors'
import { swalSuccess } from '../../../../../services/sweetalert'
import axios from '../../../../../services/axios'

const AuthorizationRowByOwnerRow = ({ row, handleList }) => {
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const {
		id,
		created_date,
		created_at,
		status,
		authorization,
		person_type,
		email,
		company,
		representative_first_name,
		representative_last_name,
		first_name,
		last_name
	} = row
	const [open, setOpen] = useState(false)
	const [getLoading, setLoading] = useState(false)
	
	const handleSendMail = async () => {
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/authorizations/owners/send-mail-request/${id}`)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				handleList()
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	const handleApproved = () => {
		setOpen(!open)
	}
	
	return (
		<>
			<TableRow>
				<TableCell className="p-2" align="center" width="20%">
					<Display.DateWithTimezone
						timezone={created_at}
						updated_date={created_date}
					/>
				</TableCell>
				<TableCell className="p-2" align="center" width="10%">
					<div
						className={`badge ${status === 'Solicitud' && 'bg-dark'} ${status === 'Proceso' && 'bg-warning'} ${status === 'Aprobado' && 'bg-success'} ${status === 'Rechazado' && 'bg-danger'}`}
					>
						{status}
					</div>
				</TableCell>
				<TableCell className="p-2" align="justify" width="25%">
					{person_type !== 'Natural' && (`${company}`)}
					<br/>
					{person_type !== 'Natural' && email}
					<br/>
					{person_type !== 'Natural' && (`Representante Legal: ${representative_first_name} ${representative_last_name}`)}
				</TableCell>
				<TableCell className="p-2" align="justify" width="25%">
					{person_type === 'Natural' && (`${first_name} ${last_name}`)}
					<br/>
					{person_type === 'Natural' && email}
				</TableCell>
				<TableCell className="p-2" align="center" width="15%">
					{authorization}
				</TableCell>
				<TableCell className="p-2" align="center" width="5%">
					{(status === 'Solicitud' || status === 'Rechazado') && (
						<Display.Tooltip
							label="Reenviar solicitud"
							placement="top"
						>
							<IconButton
								type="link"
								className="color-dark"
								onClick={handleSendMail}
							>
								<Display.Icons.MailOutlineIcon />
							</IconButton>
						</Display.Tooltip>
					)}
					{(status === 'Proceso') && (
						<Display.Tooltip
							label="Asignar estatus"
							placement="top"
						>
							<IconButton
								type="link"
								className="color-dark"
								onClick={handleApproved}
							>
								<Display.Icons.EditOutlinedIcon />
							</IconButton>
						</Display.Tooltip>
					)}
				</TableCell>
			</TableRow>
			<Feedback.DialogLoading
				getLoading={getLoading}
				setLoading={setLoading}
			/>
			<ModalAuthorizationByOwnerStatus
				id={id}
				open={open}
				setOpen={setOpen}
				handleList={handleList}
			/>
		</>
	)
}

export default AuthorizationRowByOwnerRow
