import React, { useState } from 'react'
import MuiTable from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    table: {
        borderRadius: '10px 10px 0 0',
        border: '1px solid #D9DCDF',
        '& thead th': {
            border: '1px solid #f9f9f9',
            color: '#f9f9f9',
            backgroundColor: '#272727',
            fontSize: '16px',
            fontWeight: 500,
            padding: '5px 10px 5px 10px !important'
        },
        '& tbody td': {
            color: '#5D5D5D !important',
            backgroundColor: '#ffffff',
            border: '1px solid #d1d1d1',
            fontSize: '18px',
            fontWeight: 400
        },
        // '& tbody tr:hover td': {
        //     backgroundColor: '#e9e9e9',
        //     border: '1px solid #d1d1d1',
        // }
    }
}))

export default function Table(headCells, records) {
    const classes = useStyles()
    const pages = [10, 20, 50, 100]
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(pages[page])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const TblContainer = props => (
        <MuiTable className={classes.table}>
            {props.children}
        </MuiTable>
    )

    const TblHead = props => {
        return (
            <TableHead>
                <TableRow>
                    {
                        headCells.map((headCell, index) => (
                            <TableCell
                                key={index}
                                align={headCell.align}
                                width={headCell.width}
                            >
                                { headCell.label }
                            </TableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
        )
    }

    const TblPagination = () => (
        <TablePagination
            component="div"
            page={page}
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            count={records.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    )

    const recordsWithPagination = () => {
        return records.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    }

    return {
        TblContainer,
        TblHead,
        TblPagination,
        recordsWithPagination
    }
}
