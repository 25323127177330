import React from 'react';
import Layout from '../../../components/mui/Layout';
import Logo from '../../../components/mui/Logo'
import './Header.scss';

const HeaderComponent = () => {
	
	return (
		<Layout.CardHeader
			title={<Logo className="d-block mx-auto text-center logo" />}
		/>
	);
};

export default HeaderComponent

