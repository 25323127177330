const ROOT = 'dashboard/settings/roles';

export default {
	arrayList: `${ROOT}/list`,
	arrayListToDropdown: `${ROOT}`,
	store: `${ROOT}`,
	show: `${ROOT}/`,
	update: `${ROOT}/`,
	assignPermissions: `${ROOT}/assign-permissions/`,
	destroy: `${ROOT}/`,
};
