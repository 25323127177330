import * as types from '../../actionsTypes/dashboard/filteredTypes'
import {
	initialValuesFiltered as initialValues,
	initialValuesFilter,
	initialValuesFilteredDetail,
	initialValuesFilteredAdviserForm,
	initialValuesFilteredCustomerForm
} from '../../../helpers/variablesInitialValues'

const initialState = {
	perPageRegister: 20,
	perPageDiscarded: 20,
	perPageCommercial: 20,
	perPageLogs: 10,
	pageRegister: 1,
	pageDiscarded: 1,
	pageCommercial: 1,
	pageLogs: 0,
	pagesRegister: 1,
	pagesDiscarded: 1,
	pagesCommercial: 1,
	pagesLogs: 0,
	hasNextPageRegister: true,
	hasNextPageDiscarded: true,
	hasNextPageCommercial: true,
	arrayListRegister: [],
	arrayListDiscarded: [],
	arrayListCommercial: [],
	arrayListFavorites: [],
	arrayListPreviewFavorites: [],
	arrayListLogs: [],
	isActiveSearch: false,
	isOpenModal: false,
	isOpenCustomerModal: false,
	isOpenOccurrences: false,
	isActiveValidateButton: false,
	isActiveHistorical: false,
	isLoadingRegister: true,
	isLoadingDiscarded: true,
	isLoadingCommercial: true,
	isLoadingFavorites: false,
	isLoadingLogs: false,
	isLoadingValidate: false,
	isLoadingOccurrences: false,
	isLoadingForm: false,
	isCompletedForm: false,
	isSelectedToggleSwitch: 0,
	formFilter: initialValuesFilter,
	formData: initialValues,
	formDataDetail: initialValuesFilteredDetail,
	formDataPreview: initialValuesFilteredAdviserForm,
	formDataCustomer: initialValuesFilteredCustomerForm,
	formType: 1
}

const filteredReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_REGISTER_FILTER:
			return {
				...state,
				perPageRegister: payload
			}
			
		case types.PER_PAGE_DISCARDED_FILTER:
			return {
				...state,
				perPageDiscarded: payload
			}
			
		case types.PER_PAGE_COMMERCIAL_FILTER:
			return {
				...state,
				perPageCommercial: payload
			}
			
		case types.PER_PAGE_LOGS_FILTER:
			return {
				...state,
				perPageLogs: payload
			}
		
		case types.PAGE_REGISTER_FILTER:
			return {
				...state,
				pageRegister: payload
			}
			
		case types.PAGE_DISCARDED_FILTER:
			return {
				...state,
				pageDiscarded: payload
			}
			
		case types.PAGE_COMMERCIAL_FILTER:
			return {
				...state,
				pageCommercial: payload
			}
			
		case types.PAGE_LOGS_FILTER:
			return {
				...state,
				pageLogs: payload
			}
			
		case types.HAS_NEXT_PAGE_REGISTER_FILTER:
			return {
				...state,
				hasNextPageRegister: payload
			}
			
		case types.HAS_NEXT_PAGE_DISCARDED_FILTER:
			return {
				...state,
				hasNextPageDiscarded: payload
			}
			
		case types.HAS_NEXT_PAGE_COMMERCIAL_FILTER:
			return {
				...state,
				hasNextPageCommercial: payload
			}
		
		case types.SEARCH_FILTER:
			return {
				...state,
				search: payload
			}
		
		case types.PAGES_REGISTER_FILTER:
			return {
				...state,
				pagesRegister: payload
			}
			
		case types.PAGES_DISCARDED_FILTER:
			return {
				...state,
				pagesDiscarded: payload
			}
			
		case types.PAGES_COMMERCIAL_FILTER:
			return {
				...state,
				pagesCommercial: payload
			}
			
		case types.PAGES_LOGS_FILTER:
			return {
				...state,
				pagesLogs: payload
			}
		
		case types.ARRAY_LIST_REGISTER_FILTER:
			return {
				...state,
				arrayListRegister: payload
			}
			
		case types.ARRAY_LIST_DISCARDED_FILTER:
			return {
				...state,
				arrayListDiscarded: payload
			}
			
		case types.ARRAY_LIST_COMMERCIAL_FILTER:
			return {
				...state,
				arrayListCommercial: payload
			}
			
		case types.ARRAY_LIST_FAVORITES_FILTER:
			return {
				...state,
				arrayListFavorites: payload
			}
			
		case types.ARRAY_LIST_PREVIEW_FAVORITES_FILTER:
			return {
				...state,
				arrayListPreviewFavorites: payload
			}
			
		case types.ARRAY_LIST_LOGS_FILTER:
			return {
				...state,
				arrayListLogs: payload
			}
		
		case types.IS_ACTIVE_SEARCH_FILTER:
			return {
				...state,
				isActiveSearch: payload
			}
			
		case types.IS_OPEN_MODAL_FILTER:
			return {
				...state,
				isOpenModal: payload
			}
			
		case types.IS_OPEN_CUSTOMER_MODAL_FILTER:
			return {
				...state,
				isOpenCustomerModal: payload
			}
			
		case types.IS_OPEN_OCCURRENCES_FILTER:
			return {
				...state,
				isOpenOccurrences: payload
			}
			
		case types.IS_ACTIVE_VALIDATE_BUTTON_FILTER:
			return {
				...state,
				isActiveValidateButton: payload
			}
			
		case types.IS_ACTIVE_HISTORICAL_FILTER:
			return {
				...state,
				isActiveHistorical: payload
			}
		
		case types.IS_LOADING_REGISTER_FILTER:
			return {
				...state,
				isLoadingRegister: payload
			}
			
		case types.IS_LOADING_DISCARDED_FILTER:
			return {
				...state,
				isLoadingDiscarded: payload
			}
			
		case types.IS_LOADING_COMMERCIAL_FILTER:
			return {
				...state,
				isLoadingCommercial: payload
			}
			
		case types.IS_LOADING_FAVORITES_FILTER:
			return {
				...state,
				isLoadingFavorites: payload
			}
			
		case types.IS_LOADING_LOGS_FILTER:
			return {
				...state,
				isLoadingLogs: payload
			}
		
		case types.IS_LOADING_VALIDATE_FILTER:
			return {
				...state,
				isLoadingValidate: payload
			}
			
		case types.IS_LOADING_OCCURRENCES_FILTER:
			return {
				...state,
				isLoadingOccurrences: payload
			}
			
		case types.IS_LOADING_FORM_FILTER:
			return {
				...state,
				isLoadingForm: payload
			}
			
		case types.IS_COMPLETED_FORM_FILTER:
			return {
				...state,
				isCompletedForm: payload
			}
		
		case types.IS_SELECTED_TOGGLE_SWITCH_FILTER:
			return {
				...state,
				isSelectedToggleSwitch: payload
			}
		
		case types.FORM_FILTER_FILTER:
			return {
				...state,
				formFilter: payload
			}
		
		case types.FORM_DATA_FILTER:
			return {
				...state,
				formData: payload
			}
			
		case types.FORM_DATA_DETAIL_FILTER:
			return {
				...state,
				formDataDetail: payload
			}
			
		case types.FORM_DATA_PREVIEW_FILTER:
			return {
				...state,
				formDataPreview: payload
			}
			
		case types.FORM_DATA_CUSTOMER_FILTER:
			return {
				...state,
				formDataCustomer: payload
			}
			
		case types.FORM_TYPE_FILTER:
			return {
				...state,
				formType: payload
			}
		
		default:
			return state
	}
}

export default filteredReducer
