import React from 'react'
import VerifiedUserOutlined from '@material-ui/icons/VerifiedUserOutlined'

export default function VerifiedUserOutlinedIcon(props) {

    const { ...other } = props

    return (
        <VerifiedUserOutlined { ...other } />
    )
}
