import * as types from '../../../actionsTypes/owners/ownerAuthorizationTypes'
import { initialValuesOwnerAuthorization as initialValues } from '../../../../helpers/variablesInitialValues'
import { setIsLoading } from '../../loadingAction'
import { getShowById, setUpdateById } from './services'
import { swalSuccess } from '../../../../services/sweetalert'
import errorServices from '../../../../services/errors'

export const showById = id => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getShowById(id).then(({ data }) => {
			dispatch( setFormData(data.data) )
			dispatch( setMessageStatus(data.data.message_status) )
			dispatch( setTypeStatus(data.data.status) )
			dispatch( setIsActiveButton(data.data.status === 'Solicitud' ? true : false))
		}).catch(({ response }) => {
			dispatch( setFormData(initialValues) )
			dispatch( setIsActiveButton(false) )
			dispatch( setMessageStatus(response.data.errors[0]) )
		})
		
		await dispatch( setIsLoading(false) )
	}
)

export const updateById = (id, param) => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setUpdateById(id, param).then(({ data }) => {
			dispatch( showById(id) )
			swalSuccess('Autorización exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setOwnerAuthorizationReset = () => (
	async (dispatch) => {
		await dispatch( setMessageStatus('') )
		await dispatch( setTypeStatus('') )
		await dispatch( setIsActiveButton(false) )
		await dispatch( setIsLoadingForm(false) )
		await dispatch( setFormData(initialValues) )
	}
)

export const setMessageStatus = value => ({
	type: types.MESSAGE_STATUS_OWNER_AUTHORIZATION,
	payload: value
})

export const setTypeStatus = value => ({
	type: types.TYPE_STATUS_OWNER_AUTHORIZATION,
	payload: value
})

export const setIsActiveButton = value => ({
	type: types.IS_ACTIVE_BUTTON_OWNER_AUTHORIZATION,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_OWNER_AUTHORIZATION,
	payload: value
})

export const setFormData = value => ({
	type: types.FORM_DATA_OWNER_AUTHORIZATION,
	payload: value
})
