import React from 'react'
import MoreVert from '@material-ui/icons/MoreVert'

const MoreVertIcon = ({ ...more }) => {
	
	return (
		<MoreVert
			{...more}
		/>
	)
}

export default MoreVertIcon
