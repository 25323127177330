import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Layout from '../../../../components/mui/Layout';
import Feedback from '../../../../components/mui/Feedback';
import Breadcrumbs from '../../../../components/v2-mobili/breadcrumbs/Breadcrumbs';
import { setIsOpenSidebar } from '../../../../redux/sidebar/actions/sidebarAction';
import { setFormData, setIsOpenModal } from '../../settings/UserPage/redux/actions/profileAction';
import { setReset } from '../../../auth/redux/actions/authAction';
import { setLogOut } from '../../../auth/services';
import swalErrors from '../../../../hooks/useErrors';
import usePermission from '../../../../hooks/usePermission';
import { initialValuesProfile as initialValues, initialValuesAuth } from '../../../../helpers/variablesInitialValues';
import './Header.scss';

const Header = () => {
	const dispatch = useDispatch();
	const isOpen = useSelector(({ sidebar }) => sidebar.isOpen);
	const userData = useSelector(({ auth }) => auth.userData);
	const permission = usePermission('users.showProfile');
	const [isLoading, setIsLoading] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleToggle = () => dispatch( setIsOpenSidebar(!isOpen) );

	const handleIsOpenMenu = (event) => setAnchorEl(event.currentTarget);

	const handleCloseMenu = () => setAnchorEl(null);

	const handleIsOpenModal = async () => {
		if (permission) {
			await dispatch( setFormData( initialValues) );
			await dispatch( setIsOpenModal(true) );
			handleCloseMenu();
		}
	};

	const handleLogout = async () => {
		setIsLoading(true);
		
		try {
			const { data } = await setLogOut();
			
			if (data.status === 200) {
				await localStorage.clear();
				await localStorage.setItem('persist:root', JSON.stringify(initialValuesAuth));
				await localStorage.clear();
				await dispatch( setReset() );
			}
		} catch (error) {
			await swalErrors(error);
		}
		
		setIsLoading(false);
	};

	return (
		<>
			<AppBar className='h-60px bg-dark' position='fixed'>
				<Toolbar>
					<div className={`d-flex justify-content-center align-items-center menu-responsive animated animate__animated ${!isOpen && 'menu-responsive-open'}`}>
						<Layout.IconButton
							color='inherit'
							size='large'
							onClick={handleToggle}
							edge='start'>
							<Layout.Icons.MenuIcon
								width={20}
								height={20}
								className='text-white'
							/>
						</Layout.IconButton>
						<Layout.Box sx={{ marginLeft: '12px' }}>
							<Breadcrumbs />
						</Layout.Box>
					</div>

					<div className='position-absolute r-3'>
						<Stack
							direction='row'
							spacing={2}
							onClick={handleIsOpenMenu}>
							<Layout.Avatar
								alt='avatar'
								src={userData.avatar}
								size='sm'
								icon={false}
							/>
							<Layout.IconButton
								color='inherit'
								size='large'
								aria-label='account of current user'
								aria-controls='menu-appbar'
								aria-haspopup='true'>
								<Layout.Icons.MoreIcon
									width={20}
									height={20}
									className='text-white'
								/>
							</Layout.IconButton>
						</Stack>
						<Menu
							id='menu-appbar'
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left'
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left'
							}}
							open={Boolean(anchorEl)}
							onClose={handleCloseMenu}
							sx={{
								display: { xs: 'block' }
							}}
						>
							{permission && (
								<MenuItem onClick={handleIsOpenModal}>
									<ListItemIcon>
										<Layout.Icons.AdminPanelSettingsIcon
											height={20}
											width={20}
										/>
									</ListItemIcon>
									<ListItemText>Profile</ListItemText>
								</MenuItem>
							)}
							<Layout.Divider component='li' color='light' />
							<MenuItem onClick={handleLogout}>
								<ListItemIcon>
									<Layout.Icons.ExitToAppIcon
										height={20}
										width={20}
									/>
								</ListItemIcon>
								<ListItemText>Salir</ListItemText>
							</MenuItem>
						</Menu>
					</div>
				</Toolbar>
			</AppBar>
			
			<Feedback.ModalLoading
				isOpen={isLoading}
				title='Cerrando sesión...'
				content='Espere un momento por favor...'
			/>
		</>
	);
};

export default Header;
