import * as types from '../../../../actionsTypes/dashboard/settings/owners/ownerMobiliTypes'
import { initialValuesOwnerMobili as initialValues } from '../../../../../helpers/variablesInitialValues'

const initialState = {
	isActiveButton: false,
	isLoadingButton: false,
	isLoadingForm: false,
	formData: initialValues
}

const ownerMobiliReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.IS_ACTIVE_BUTTON_OWNER_MOBILI:
			return {
				...state,
				isActiveButton: payload
			}
		
		case types.IS_LOADING_BUTTON_OWNER_MOBILI:
			return {
				...state,
				isLoadingButton: payload
			}
		
		case types.IS_LOADING_FORM_OWNER_MOBILI:
			return {
				...state,
				isLoadingForm: payload
			}
		
		case types.FORM_DATA_OWNER_MOBILI:
			return {
				...state,
				formData: payload
			}
		
		default:
			return state
	}
}

export default ownerMobiliReducer
