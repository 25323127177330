import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { history } from '../../../../redux/store'
import Layout from '../../../../components/mui/Layout'
import Inputs from '../../../../components/mui/Inputs'
import SearchComponent from '../components/SearchComponent'
import { setIsLoading } from '../../../../redux/actions/loadingAction'
import { Permission } from '../../../../services/permissions/Permission'
import errorServices from '../../../../services/errors'
import { swalSuccess } from '../../../../services/sweetalert'
import axios from '../../../../services/axios'

const PropertyStatusOlPage = () => {
	const params = useParams()
	const uID = params.uID
	const dispatch = useDispatch()
	const { permissions } = useSelector( state  => state.auth.userData)
	const tokenType = useSelector(state => state.auth.tokenType)
	const accessToken = useSelector(state => state.auth.accessToken)
	const [isLoading, setLoadingForm] = useState(false)
	const [arrayDropdownAdviser, setDropdownAdviser] = useState([])
	const [arrayDropdownGetter, setDropdownGetter] = useState([])
	const [arrayDropdownStatusCommercial, setDropdownStatusCommercial] = useState([])
	const [propertyUpdatedBy, setPropertyUpdatedBy] = useState('')
	const [propertyDate, setPropertyDate] = useState('')
	const [propertyDateAt, setPropertyDateAt] = useState('')
	const [formData, setFormData] = useState({
		status_commercial_id: '',
		getter_id: '',
		adviser_id: '',
		comment: ''
	})
	const [module, setModule] = useState({
		step: 10,
		title: 'Asignación de Estatus Comercial'
	})
	
	const validationSchema = () => (
		Yup.object({
			status_commercial_id: Yup.string()
			.required('El estatus comercial es requerido'),
			getter_id: Yup.string()
			.required('El captador es requerido'),
		})
	)
	
	const handleDropdownStatusCommercial = async () => {
		if (permissions.find((item) => item === 'propertyStatusCommercial.dropdown')) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const data = await axios.get('dashboard/settings/properties/status-commercial')
				
				if (data.status === 200) {
					setDropdownStatusCommercial(data.data.data.records)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	useEffect(() => {
		handleDropdownStatusCommercial()
	}, [])
	
	const handleDropdownGetter = async () => {
		if (permissions.find((item) => item === 'usersByAdviser.dropdown')) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const param = {
					property_id : uID,
					type: 'Captación'
				}
				
				const data = await axios.post('dashboard/settings/users/by-adviser', param)
				
				if (data.status === 200) {
					setDropdownGetter(data.data.data.records)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	useEffect(() => {
		handleDropdownGetter()
	}, [])
	
	const handleDropdownAdviser = async () => {
		if (permissions.find((item) => item === 'usersByAdviser.dropdown')) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const param = {
					property_id : uID
				}
				
				const data = await axios.post('dashboard/settings/users/by-adviser', param)
				
				if (data.status === 200) {
					setDropdownAdviser(data.data.data.records)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	useEffect(() => {
		handleDropdownAdviser()
	}, [])
	
	const handleEditProperty = async () => {
		dispatch( setIsLoading(true) )
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/${uID}`)
			
			if (data.status === 200) {
				const response = data.data.data
				setFormData({
					status_commercial_id: response.status_commercial_id === null ? '' : response.status_commercial_id,
					getter_id: response.getter_id === null ? '' : response.getter_id,
					adviser_id: response.adviser_id === null ? '' : response.adviser_id,
					comment: response.comment === null ? '' : response.comment
				})
				
				if (response.has_building === 'Si') {
					setModule({...module, step: 9 })
				}
				
				setPropertyUpdatedBy(response.updated_by === null ? '' : response.updated_by)
				setPropertyDate(response.updated_date === null ? '' : response.updated_date)
				setPropertyDateAt(response.updated_at === null ? '' : response.updated_at)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		dispatch( setIsLoading(false) )
	}
	
	useEffect(() => {
		handleEditProperty()
	}, [])
	
	const hanbleSubmit = async () => {
		setLoadingForm(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				id: uID,
				status_commercial_id: formData.status_commercial_id === '' ? null :formData.status_commercial_id,
				getter_id: formData.getter_id === '' ? null :formData.getter_id,
				adviser_id: formData.adviser_id === '' ? null :formData.adviser_id,
				comment: formData.comment === '' ? null :formData.comment
			}
			
			const data = await axios.put(`dashboard/properties/status/${uID}`, param)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				await history.push(`/dashboard/propiedades`)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoadingForm(false)
	}
	
	const handleOnChange = ({ target }) => setFormData({...formData, [target.name]: target.value})
	
	return (
		<Layout.Page title="Propiedades - Estatus Comercia">
			<SearchComponent
				module={module}
				back={`/dashboard/propiedades/servicios/${uID}`}
			/>
			
			<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
				<Layout.CardContent className="m-0 p-4">
					<Formik
						initialValues={formData}
						validationSchema={validationSchema}
						onSubmit={() => {}}
					>
						{({
						  errors,
						  touched,
						  handleChange,
						  handleBlur,
						  isValid
					  }) => (
							<Form noValidate>
								<div className="row">
									<div className="col-sm-12 mb-2">
										<div className="font-family-roboto-regular fs-6 text-dark">
											<strong>Fecha: </strong>
											<Layout.Timezone
												date={propertyDate}
												dateAt={propertyDateAt}
											/>
										</div>
									</div>
									<div className="col-sm-12 mb-3">
										<div className="font-family-roboto-regular fs-6 text-dark">
											<strong>Usuario: </strong> { propertyUpdatedBy }
										</div>
									</div>
								</div>
								<div className="row">
									<Permission permission="propertyStatusCommercial.dropdown">
										<div className="col-sm-12 col-md-6 mb-3">
											<Inputs.Select
												size="small"
												label="Estatus comercial"
												name="status_commercial_id"
												value={formData.status_commercial_id}
												itemsArray={arrayDropdownStatusCommercial}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.status_commercial_id && touched.status_commercial_id ? true : false}
												errorMessage={errors.status_commercial_id && touched.status_commercial_id ? errors.status_commercial_id : ''}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
									</Permission>
									<Permission permission="usersByAdviser.dropdown">
										<div className="col-sm-12 col-md-6 mb-3">
											<Inputs.Select
												size="small"
												label="Captador"
												name="getter_id"
												value={formData.getter_id}
												itemsArray={arrayDropdownGetter}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.getter_id && touched.getter_id ? true : false}
												errorMessage={errors.getter_id && touched.getter_id ? errors.getter_id : ''}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
									</Permission>
									<Permission permission="usersByAdviser.dropdown">
										<div className="col-sm-12 col-md-6 mb-3">
											<Inputs.Select
												size="small"
												label="Asesor"
												name="adviser_id"
												value={formData.adviser_id}
												itemsArray={arrayDropdownAdviser}
												onChange={handleOnChange}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
									</Permission>
									<div className="col-sm-12 col-md-6 mb-3">
										<Inputs.TextArea
											variant="outlined"
											size="small"
											name="comment"
											label="Comentarios"
											placeholder="Ingrese comentarios..."
											hasLabelFixed={true}
											value={formData.comment}
											onChange={handleOnChange}
											className="font-family-roboto-regular fs-6 text-dark w-100"
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<Inputs.LoadingButton
											variant="outlined"
											isLoading={isLoading}
											isLoadingPosition="start"
											startIcon={<Layout.Icons.SaveIcon height={18} width={18}/>}
											label="Finalizar"
											type="button"
											onClick={hanbleSubmit}
											className={`${isValid && 'bg-primary'} font-family-roboto-medium fs-6 text-inherit`}
										/>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</Layout.CardContent>
			</Layout.Card>
		</Layout.Page>
	)
}

export default PropertyStatusOlPage
