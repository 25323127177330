const ROOT = 'dashboard/settings/amenities';

export default {
	arrayList: `${ROOT}/list`,
	arrayListToDropdownCommercialArea: `${ROOT}/by-commercial-area`,
	arrayListToDropdownLegalArea: `${ROOT}/by-legal-area`,
	arrayListToDropdownCommercialBuilding: `${ROOT}/by-commercial-building`,
	store: `${ROOT}`,
	show: `${ROOT}/`,
	status: `${ROOT}/status/`,
	update: `${ROOT}/`,
	destroy: `${ROOT}/`,
};
