export const PER_PAGE_PROPERTY_STATUS_COMMERCIAL = 'PER_PAGE_PROPERTY_STATUS_COMMERCIAL';
export const PAGE_PROPERTY_STATUS_COMMERCIAL = 'PAGE_PROPERTY_STATUS_COMMERCIAL';
export const SEARCH_PROPERTY_STATUS_COMMERCIAL = 'SEARCH_PROPERTY_STATUS_COMMERCIAL';
export const PAGES_PROPERTY_STATUS_COMMERCIAL = 'PAGES_PROPERTY_STATUS_COMMERCIAL';
export const ARRAY_LIST_PROPERTY_STATUS_COMMERCIAL = 'ARRAY_LIST_PROPERTY_STATUS_COMMERCIAL';
export const ARRAY_LIST_TO_DROPDOWN_PROPERTY_STATUS_COMMERCIAL = 'ARRAY_LIST_TO_DROPDOWN_PROPERTY_STATUS_COMMERCIAL';
export const REFRESH_TABLE_PROPERTY_STATUS_COMMERCIAL = 'REFRESH_TABLE_PROPERTY_STATUS_COMMERCIAL';
export const IS_OPEN_MODAL_PROPERTY_STATUS_COMMERCIAL = 'IS_OPEN_MODAL_PROPERTY_STATUS_COMMERCIAL';
export const IS_LOADING_SHOW_PROPERTY_STATUS_COMMERCIAL = 'IS_LOADING_SHOW_PROPERTY_STATUS_COMMERCIAL';
export const IS_LOADING_FORM_PROPERTY_STATUS_COMMERCIAL = 'IS_LOADING_FORM_PROPERTY_STATUS_COMMERCIAL';
export const FORM_TYPE_PROPERTY_STATUS_COMMERCIAL = 'FORM_TYPE_PROPERTY_STATUS_COMMERCIAL';
export const FORM_DATA_PROPERTY_STATUS_COMMERCIAL = 'FORM_DATA_PROPERTY_STATUS_COMMERCIAL';
