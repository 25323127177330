import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Feedback from '../../../../../components/mui/Feedback';
import Inputs from '../../../../../components/mui/Inputs';
import Layout from '../../../../../components/mui/Layout';
import PermissionsRowComponent from './PermissionsRowComponent';
import { getArrayListToDropdown } from '../../PermissionPage/services';
import { setAssignPermissionsById } from '../services';
import { setIsLoadingForm, setArrayListToDropdown } from '../../PermissionPage/redux/actions/permissionAction';
import { setFormData, setIsOpenModalPermission, setIsLoadingForm as setIsLoadingFormRole, setFormType } from '../redux/actions/roleAction';
import { swalSuccess } from '../../../../../hooks/useSweetAlert';
import swalErrors from '../../../../../hooks/useErrors';
import usePermission from '../../../../../hooks/usePermission';
import { initialValuesRole as initialValues } from '../../../../../helpers/variablesInitialValues';

const PermissionsComponent = () => {
	const dispatch = useDispatch();
	const isOpenModal = useSelector( ({ role }) => role.isOpenModalPermission);
	const isLoadingForm = useSelector(({ role }) => role.isLoadingForm);
	const formType = useSelector(({ role }) => role.formType);
	const formData = useSelector(({ role }) => role.formData);
	const isLoadingPermission = useSelector(({ permission }) => permission.isLoadingForm);
	const permissions = useSelector(({ permission }) => permission.arrayListToDropdown);
	const permissionDropdownPermissions = usePermission('permissions.dropdown');
	const permissionByRole = usePermission('roles.permissionsByRole');
	const permissionAssignPermissions = usePermission('roles.assignPermissions');
	
	const handleArrayListPermissions = async () => {
		if (formType === 'permission' && isOpenModal && permissionDropdownPermissions) {
			await dispatch( setIsLoadingForm(true) );
			
			try {
				const { data } = await getArrayListToDropdown();
				
				if (data.status === 200) {
					const array = data.data.records;
					
					if (array.length > 0) {
						array.map(({ items }) => {
							items.map(({ name }, index) => {
								let isActive = false;

								if (formData.permissions.length > 0) {
									isActive = formData.permissions.find(item => item.name === name) ? true : false;
								}

								items[index].active = isActive;
							});
						});
					}
					
					await dispatch( setArrayListToDropdown(array) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingForm(false) );
		}
	}
	
	useEffect(() => {
		handleArrayListPermissions();
	}, [formType, isOpenModal]);
	
	const handleChange = (id, name) => {
		const array = formData.permissions;
		const find = array.find(item => item.name === name);

		if (find) {
			array.forEach((item, index, map) => {
				if (item.name === name) {
					map.splice(index, 1);
				}
			});
		} else {
			array.push({ id: id, name: name });
		}

		const param = {
			id: formData.id,
			name: formData.name,
			permissions: array
		};
		
		dispatch( setFormData(param) );
	};
	
	const handleAssignPermissionById = async () => {
		if (permissionAssignPermissions) {
			await dispatch( setIsLoadingFormRole(true) );
			
			try {
				const { data } = await setAssignPermissionsById(formData.id, formData);
				
				if (data.status === 200) {
					await swalSuccess('Asignación de permisos', data.data);
					await handleCloseModal();
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingFormRole(false) );
		}
	};
	
	const handleCloseModal = async () => {
		await dispatch( setArrayListToDropdown([]) );
		await dispatch( setFormData(initialValues) );
		await dispatch( setFormType('') );
		await dispatch( setIsLoadingFormRole(false) );
		await dispatch( setIsLoadingForm(false) );
		await dispatch( setIsOpenModalPermission(false) );
	};
	
	return (
		<Feedback.Modal
			maxWidth="md"
			isOpen={isOpenModal}
			isClose={handleCloseModal}
			title={`Asignar permisos al role ${formData.name}`}
			className="vh-100"
			hasAction={true}
			contentActions={
				permissionAssignPermissions && (
					<Inputs.LoadingButton
						isLoading={isLoadingForm}
						isLoadingPosition="start"
						startIcon={<Layout.Icons.SaveIcon height={18} width={18} />}
						label="Guardar"
						type="button"
						className="font-family-roboto-regular fs-6 bg-primary text-capitalize"
						onClick={handleAssignPermissionById}
					/>
				)
			}
		>
			{permissionByRole && (
				<div className="row">
					{isLoadingPermission && (
						<div className="col-sm-12 vh-100 bottom-100 text-center">
							<CircularProgress
								style={{height: '32px', width: '32px'}}
								color="inherit"
							/>
						</div>
					)}
					{!isLoadingPermission && (
						permissions.length > 0 && (
							permissions.map(({ group, items }, index) => (
								<PermissionsRowComponent
									key={index}
									group={group}
									items={items}
									handleChange={handleChange}
								/>
							))
						)
					)}
				</div>
			)}
		</Feedback.Modal>
	);
};

export default PermissionsComponent;
