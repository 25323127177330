// eslint-disable-next-line no-unused-vars
import React from "react"

export default function DateWithTimezone({ timezone, updated_date }) {
    const options = {
        timeZone: 'America/Bogota',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    }

    if (timezone !== '') {
        const date = new Date(timezone * 1000)

        let str = date.toLocaleDateString("en-GB", options)
        str = str.replace('/', '-')
        str = str.replace('/', '-')
        str = str.replace(',', '')

        if (updated_date !== '') {
            const newStr = str.split(' ')

            return `${updated_date} - ${newStr[1]}`
        }

        // str = str.replace('.', '')
        // str = str.replace('.', '')

        return str
    }

    return ''
}