import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Inputs from '../../../../../components/mui/Inputs';
import Layout from '../../../../../components/mui/Layout';
import { setUpdateProfilePasswordById } from "../services";
import { setFormData, setIsLoadingForm, setIsOpenModal } from '../redux/actions/profileAction';
import { swalSuccess } from '../../../../../hooks/useSweetAlert';
import swalErrors from '../../../../../hooks/useErrors';
import usePermission from '../../../../../hooks/usePermission';
import { initialValuesProfile as initialValues } from '../../../../../helpers/variablesInitialValues';

const FormPasswordReset = () => {
	const dispatch = useDispatch();
	const isLoadingForm = useSelector( ({ profile }) => profile.isLoadingForm);
	const formData = useSelector( ({ profile }) => profile.formData);
	const permission = usePermission('users.updateProfilePassword');
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			password: Yup.string()
			.min(6, 'La contraseña debe contener al menos 6 caracteres')
			.required('La contraseña es requerida'),
			passwordConfirm: Yup.string()
			.min(6, 'La confirmación de la contraseña debe contener al menos 6 caracteres')
			.required('La confirmación de la contraseña es requerida')
			.oneOf([Yup.ref('password'), null], 'La confirmación debe ser igual a la contraseña')
		})
	);
	
	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		if (permission) {
			await dispatch( setIsLoadingForm(true) );
			
			try {
				const param = {
					password: formData.password,
					password_confirm: formData.passwordConfirm,
				}
				
				const { data } = await setUpdateProfilePasswordById(param);
				
				if (data.status === 200) {
					await swalSuccess('Actualización correcta', data.data);
					resetForm({ values: initialValues});
					setValues(initialValues);
					await dispatch( setFormData(initialValues) );
					await dispatch( setIsOpenModal(false) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingForm(false) );
		}
	};
	
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.value }) );
	
	return (
		<Formik
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
			  errors,
			  touched,
			  handleChange,
			  handleBlur,
			  isValid,
			  dirty
		  }) => (
				<Form noValidate>
					<div className="row mt-3">
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBoxPassword
								name="password"
								label="Contraseña"
								placeholder="Ingrese contraseña..."
								value={formData.password}
								onBlur={handleBlur}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								hasError={errors.password && touched.password ? true : false}
								errorMessage={errors.password && touched.password ? errors.password : ''}
								className="font-family-roboto-medium text-dark fs-5 mt-3 w-100"
							/>
						</div>
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBoxPassword
								name="passwordConfirm"
								label="Confirmar contraseña"
								placeholder="Ingrese confirmación..."
								value={formData.passwordConfirm}
								onBlur={handleBlur}
								onChange={e => {
									handleOnChange(e)
									handleChange(e)
								}}
								hasError={errors.passwordConfirm && touched.passwordConfirm ? true : false}
								errorMessage={errors.passwordConfirm && touched.passwordConfirm ? errors.passwordConfirm : ''}
								className="font-family-roboto-medium text-dark fs-5 mt-3 w-100"
							/>
						</div>
						
						{permission && (
							<div className="col-sm-12">
								<Inputs.LoadingButton
									isDisabled={!(dirty && isValid)}
									isLoading={isLoadingForm}
									isLoadingPosition="start"
									startIcon={<Layout.Icons.LockOpenIcon height={18} width={18} />}
									label="Resetear contraseña"
									type="submit"
									className={`${(dirty && isValid) && 'bg-primary'} font-family-roboto-regular fs-6 text-capitalize mt-2 d-flex float-end`}
								/>
							</div>
						)}
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default FormPasswordReset;
