import React from 'react'
import Layout from '../../../../../components/mui/Layout'
import SearchComponent from './components/SearchComponent'
import DomusComponent from './components/DomusComponent'
import MobiliComponent from './components/MobiliComponent'

const PropertyDomusPage = () => {
	
	return (
		<Layout.Page title="Consulta Domus para Propietarios">
			<SearchComponent />
			
			<div className="row mt-4 mr-3 ml-3">
				<div className="col-sm-12 col-md-12 col-lg-6">
					<DomusComponent />
				</div>
				
				<div className="col-sm-12 col-md-12 col-lg-6">
					<MobiliComponent />
				</div>
			</div>
		
		</Layout.Page>
	)
}

export default PropertyDomusPage
