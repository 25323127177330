import React from 'react'
import PropTypes from 'prop-types'
import Button  from '@mui/material/Button'
import MuiButtonGroup from '@mui/material/ButtonGroup'

const ButtonGroup = (props) => {
	const {
		variant,
		size = 'medium',
		color = 'inherit',
		itemsArray,
		className = '',
		condition = '',
		onClick = null,
		...more
	} = props
	
	return (
		<MuiButtonGroup
			variant={variant}
			size={size}
			color={color}
		>
			{itemsArray.length > 0 && (
				itemsArray.map(({ id, name, active }, number) => (
					<Button
						key={number}
						onClick={onClick}
						className={`${className} ${id === condition && 'active'}`}
						{...more}
					>
						{name}
					</Button>
				))
			)}
		</MuiButtonGroup>
	)
}

ButtonGroup.propTypes = {
	variant: PropTypes.string,
	size: PropTypes.string,
	color: PropTypes.any,
	itemsArray: PropTypes.array.isRequired,
	className: PropTypes.string,
	condition: PropTypes.any,
	onClick: PropTypes.func
}

export default ButtonGroup
