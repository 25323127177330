import React, {useState, useRef, useEffect} from 'react'
import {useSelector} from 'react-redux'
import S3FileUpload from 'react-s3'
import ReactJwtDecode from 'jwt-decode'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Display from "../../../../../components/display/Display"
import Feedback from "../../../../../components/feedback/Feedback"
import Controls from "../../../../../components/controls/Controls"
import ToursRow from "./ToursRow"
import { makeStyles } from '@material-ui/core/styles'
import errorServices from '../../../../../services/errors'
import axios from '../../../../../services/axios'
import './PropertyPage.scss'
window.Buffer = window.Buffer || require("buffer").Buffer

const useStyles = makeStyles(theme => ({
	root: {
		width: '100% !important',
		height: '45px !important',
	},
	label: {
		textTransform: 'none',
		fontSize: '18px',
	},
	dark: {
		fontWeight: '700',
		fontSize: '18px !important',
		color: '#f9f9f9',
		border: '0px solid transparent !important',
		backgroundColor: '#3B3B3B !important',
		boxShadow: 'none !important',
		'&:hover': {
			backgroundColor: '#888888',
			color: '#f9f9f9',
			boxShadow: 'none !important',
		},
		'& .MuiButton-iconSizeMedium > *:first-child' : {
			fontSize: '18px !important'
		}
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		// top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}))

const imgFormat = [
	{ title: 'image/jpeg' },
	{ title: 'image/png' },
	{ title: 'image/bmp' },
]

const videoFormat = [
	{ title: 'video/x-flv' },
	{ title: 'video/mp4' },
	{ title: 'application/x-mpegURL' },
	{ title: 'video/MP2T' },
	{ title: 'video/3gpp' },
	{ title: 'video/quicktime' },
	{ title: 'video/x-msvideo' },
	{ title: 'video/x-ms-wmv' }
]

const defaultState = {
	link: ''
}

const FileUpload = ({ has_origin, getPropertyID, handleList, getTitleModal, getOpenModal, setOpenModal }) => {
	const classes = useStyles()
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [fullWidth, setFullWidth] = useState(true)
	const [getLoading, setLoading] = useState(false)
	const [arrayArea, setArea] = useState([])
	const [getAreaID, setAreaID] = useState("")
	const [getOrigin, setOrigin] = useState('Captación')
	const [getTypeFile, setTypeFile] = useState('Fotos')
	const [getImgFormat, setImgFormat] = useState(imgFormat)
	const [getVideoFormat, setVideoFormat] = useState(videoFormat)
	const [getFilesSuccess, setFilesSuccess] = useState([])
	const [getFilesErrors, setFilesErrors] = useState([])
	const [rows, setRows] = useState([])
	const fileRef = useRef(null)
	
	const getHandleDropdownArea = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get('dashboard/settings/areas')
			
			if (data.status === 200) {
				setArea(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
		getOpenModal && getHandleDropdownArea()
	}, [getPropertyID, getOpenModal])
	
	const handleUpload = async ({ target }) => {
		if (getOpenModal) {
			setFilesSuccess([])
			setFilesErrors([])
			setLoading(true)
			
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const data = await axios.get(`dashboard/properties/albums/folder-root/${has_origin ? getOrigin : 'Captación'}/${getTypeFile}/${getPropertyID}`)
				
				if (data.status === 200) {
					await validationFiles(target, data.data.data)
				}
			} catch (error) {
				await errorServices(error)
			}
			
			setLoading(false)
		}
	}
	
	const validationFiles = async (target, rootFolder) => {
		const files = [...target.files]
		
		if (files.length > 0) {
			const arraySuccess = []
			const arrayErrors = []
			const data = process.env.REACT_APP_SERVER_BUCKET === 'produ' ? process.env.REACT_APP_S3_PROD : process.env.REACT_APP_S3_TESTING
			const config = ReactJwtDecode(data)
			config.dirName = rootFolder
			
			for (const file of files) {
				let find
				
				if (getTypeFile === 'Fotos' || getTypeFile === 'Fotos360') {
					find = getImgFormat.find(({ title }) => title === file.type)
				} else {
					find = getVideoFormat.find(({ title }) => title === file.type)
				}
				
				if (find === undefined) {
					arrayErrors.push({ name: file.name, type: 'Formato inválido' })
				} else {
					//rename file name, remove white spaces and points
					const newFile = new File([file], `${file.name.replaceAll(/[.\s]+/gm, '')}.${file.type.split('/')[1]}`, {type: file.type, lastModified: file.lastModified})
					await S3FileUpload.uploadFile(newFile, config)
					.then(data => {
						arraySuccess.push({ name: file.name, location: data.location })
					}).catch(err => {
						arrayErrors.push({ name: file.name, type: 'Error al intentar subir archivo' })
					})
				}
			}
			
			setFilesSuccess(arraySuccess)
			setFilesErrors(arrayErrors)
			
			if (arraySuccess.length > 0) {
				try {
					axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
					
					const param = {
						id: getPropertyID,
						area_id: getAreaID === '' ? null : getAreaID,
						origin: has_origin ? getOrigin : 'Captación',
						type: getTypeFile,
						arrayFiles: arraySuccess
					}
					
					const data = await axios.post('dashboard/properties/albums', param)
					
					if (data.status === 200) {
						setAreaID('')
						setOrigin('Captación')
						setTypeFile('Fotos')
						fileRef.current = null
						handleList()
					}
				} catch (error) {
					await errorServices(error)
				}
			}
		}
	}
	
	useEffect(() => {
	}, [getFilesSuccess, getFilesErrors])
	
	const handleStoreTours = async () => {
		if (rows.length > 0) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const param = {
					id: getPropertyID,
					area_id: getAreaID === '' ? null : getAreaID,
					origin: has_origin ? getOrigin : 'Captación',
					type: getTypeFile,
					arrayFiles: rows
				}
				
				const data = await axios.post('dashboard/properties/albums', param)
				
				if (data.status === 200) {
					setAreaID('')
					setOrigin('Captación')
					setTypeFile('Fotos')
					setRows([])
					handleList()
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	const handleClose = () => {
		setOrigin('Captación')
		setTypeFile('Fotos')
		fileRef.current = null
		setFilesSuccess([])
		setFilesErrors([])
		setOpenModal(false)
	}
	
	const handleOnChange = (index, name, value) => {
		const copyRows = [...rows]
		copyRows[index] = {
			...copyRows[index],
			['link']: value
		}
		setRows(copyRows)
	}
	
	const handleOnAdd = () => {
		setRows(rows.concat(defaultState))
	}
	
	const handleOnRemove = index => {
		const copyRows = [...rows]
		copyRows.splice(index, 1)
		setRows(copyRows)
	}
	
	return (
		<>
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				fullWidth={fullWidth}
				maxWidth="sm"
				aria-labelledby="dialogForm"
				open={getOpenModal}
			>
				<DialogTitle id="dialogForm">
					{getTitleModal}
					
					<IconButton
						onClick={handleClose}
						className="bg-white height-32 width-32"
						classes={{ root: classes.closeButton }}
						aria-label="like"
					>
						<Display.Icons.CloseIcon
							className="text-dark"
							style={{ fontSize: 20 }}
						/>
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Controls.Select
								label="Tipo de Área"
								name="area_id"
								defaultValue={getAreaID}
								onChange={e => setAreaID(e.target.value)}
								arrayDropdown={arrayArea}
							/>
						</Grid>
						{has_origin && (
							<>
								<Grid item xs={12}>
									<FormGroup row>
										<FormLabel component="legend">Origen</FormLabel>
									</FormGroup>
								</Grid>
								<Grid item xs={12} className="align-content-center text-center">
									<FormGroup row>
										<div className="btn-group" role="group" aria-label="Basic example">
											<button
												type="button"
												onClick={() => setOrigin('Captación')}
												className={`btn btn-outline-white height-45 w-25 font-family-roboto-bold font-size-18 ${getOrigin === 'Captación' && 'active'}`}
											>
												Captación
											</button>
											<button
												type="button"
												onClick={() => setOrigin('Inventario')}
												className={`btn btn-outline-white height-45 w-25 font-family-roboto-bold font-size-18 borderLR ${getOrigin === 'Inventario' && 'active'}`}
											>
												Inventario
											</button>
											<button
												type="button"
												onClick={() => setOrigin('Reparación')}
												className={`btn btn-outline-white height-45 w-25 font-family-roboto-bold font-size-18 ${getOrigin === 'Reparación' && 'active'}`}
											>
												Reparación
											</button>
											<button
												type="button"
												onClick={() => setOrigin('Desocupación')}
												className={`btn btn-outline-white height-45 w-25 font-family-roboto-bold font-size-18 ${getOrigin === 'Desocupación' && 'active'}`}
											>
												Desocupación
											</button>
										</div>
									</FormGroup>
								</Grid>
							</>
						)}
						<Grid item xs={12}>
							<FormGroup row>
								<FormLabel component="legend">Tipo de archivo</FormLabel>
							</FormGroup>
						</Grid>
						<Grid item xs={12} className="align-content-center text-center">
							<FormGroup row>
								<div className="btn-group" role="group" aria-label="Basic example">
									<button
										type="button"
										onClick={() => setTypeFile('Fotos')}
										className={`btn btn-outline-white height-45 w-25 font-family-roboto-bold font-size-18 ${getTypeFile === 'Fotos' && 'active'}`}
									>
										Fotos
									</button>
									<button
										type="button"
										onClick={() => setTypeFile('Fotos360')}
										className={`btn btn-outline-white height-45 w-25 font-family-roboto-bold font-size-18 ${getTypeFile === 'Fotos360' && 'active'}`}
									>
										Fotos360
									</button>
									<button
										type="button"
										onClick={() => setTypeFile('Videos')}
										className={`btn btn-outline-white height-45 w-25 font-family-roboto-bold font-size-18 ${getTypeFile === 'Videos' && 'active'}`}
									>
										Videos
									</button>
									<button
										type="button"
										onClick={() => setTypeFile('Tours')}
										className={`btn btn-outline-white height-45 w-25 font-family-roboto-bold font-size-18 ${getTypeFile === 'Tours' && 'active'}`}
									>
										Tours
									</button>
								</div>
							</FormGroup>
						</Grid>
						{getTypeFile === 'Tours' && (
							<Grid item xs={12} className="animated animate__fadeIn">
								{rows.length > 0 && (
									rows.map((row, index) => (
										<ToursRow
											{...row}
											onChange={(name, value) => handleOnChange(index, name, value)}
											onRemove={() => handleOnRemove(index)}
											key={index}
										/>
									))
								)}
								<IconButton
									color="inherit"
									onClick={handleOnAdd}
									className="bg-grey height-32 width-32"
								>
									<Display.Icons.AddOutlinedIcon
										className="text-dark"
										style={{ fontSize: 20 }}
									/>
								</IconButton>
							</Grid>
						)}
						{getFilesSuccess.length > 0 && (
							<Grid item xs={12} className="animated animate__fadeIn">
								<ul className="list-group">
									{getFilesSuccess.map(({ name }, index) => (
										<li key={index} className="list-group-item list-group-item-success">
											<h6><strong>{ name }</strong></h6>
										</li>
									))}
								</ul>
							</Grid>
						)}
						{getFilesErrors.length > 0 && (
							<Grid item xs={12} className="animated animate__fadeIn">
								<ul className="list-group">
									{getFilesErrors.map(({ name, type }, index) => (
										<li key={index} className="list-group-item list-group-item-danger">
											<h6>{ name }</h6>
											<small><strong>{ type }</strong></small>
										</li>
									))}
								</ul>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid container spacing={1}>
						{getTypeFile === 'Tours' && (
							rows.length > 0 && (
								<Grid item xs={12} className="align-content-center text-center animated animate__fadeIn">
									<FormGroup row>
										<Button
											classes={{ root: classes.root, label: classes.label }}
											className={classes.dark}
											variant="contained"
											component="label"
											disabled={getLoading}
											onClick={handleStoreTours}
											endIcon={<Display.Icons.AttachFileIcon style={{ fontSize: 18, color: '#ffffff' }} />}
										>
											Cargar tours
										</Button>
									</FormGroup>
								</Grid>
							)
						)}
						{getTypeFile !== 'Tours' && (
							<Grid item xs={12} className="align-content-center text-center animated animate__fadeIn">
								<FormGroup row>
									<Button
										classes={{ root: classes.root, label: classes.label }}
										className={classes.dark}
										variant="contained"
										component="label"
										disabled={getLoading}
										endIcon={<Display.Icons.AttachFileIcon style={{ fontSize: 18, color: '#ffffff' }} />}
									>
										Cargar archivos
										<input
											type="file"
											ref={fileRef}
											onChange={handleUpload}
											disabled={getLoading}
											multiple
											hidden
										/>
									</Button>
								</FormGroup>
							</Grid>
						)}
					</Grid>
				</DialogActions>
			</Dialog>
			<Feedback.DialogLoading
				getLoading={getLoading}
				setLoading={setLoading}
			/>
		</>
	)
}

export default FileUpload
