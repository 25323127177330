import React from 'react';
import Layout from '../../../components/mui/Layout';
import FormComponent from './components/FormComponent';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import '../Auth.scss';

const ForgotPasswordPage = () => {
	
	return (
		<Layout.Page title="Solicitud de cambio de contraseña">
			<Layout.Card className="card form-container">
				<HeaderComponent />
				<Layout.CardContent className="m-0 pt-4 pr-4 pl-4 pb-0">
					<FormComponent />
				</Layout.CardContent>
				<FooterComponent
					to="/"
					label="Volver al inicio de sesión"
				/>
			</Layout.Card>
		</Layout.Page>
	);
};

export default ForgotPasswordPage;
