import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Feedback from '../../../../../components/mui/Feedback'
import CustomerFormComponent from './CustomerFormComponent'
import { setFormDataCustomer, setIsOpenCustomerModal } from '../../../../../redux/actions/dashboard/filtered/filteredAction'
import { setIsOpenCustomerModal as setIsOpenCustomerModalNoAuth } from '../../../../../redux/actions/filtered/adviserForm/filteredAdviserAction'
import { Permission } from '../../../../../services/permissions/Permission'
import { initialValuesFilteredCustomerForm as initialValues } from '../../../../../helpers/variablesInitialValues'

const CustomerModalComponent = ({ isAuthenticated, isAuthenticatedPage, isOpenModal, filterID, customerID = '' }) => {
	const dispatch = useDispatch()
	
	useEffect(() => {
	}, [
		isAuthenticated,
		isAuthenticatedPage,
		isOpenModal,
		filterID,
		customerID
	])
	
	/**
	 *
	 * @description Execute reset formData, close modal state
	 * @return dispatch
	 *
	 */
	const handleCloseModal = () => {
		dispatch( setFormDataCustomer(initialValues) )
		dispatch( isAuthenticatedPage ? setIsOpenCustomerModal(false) : setIsOpenCustomerModalNoAuth(false) )
	}
	
	return (
		<>
			{isAuthenticated && (
				<Feedback.Modal
					maxWidth="md"
					isOpen={isOpenModal}
					isClose={handleCloseModal}
					title="Actualizar Cliente"
				>
					<Permission permission="filtereds.customers.show">
						<CustomerFormComponent
							filterID={filterID}
							customerID={customerID}
						/>
					</Permission>
				</Feedback.Modal>
			)}
		</>
	)
}

CustomerModalComponent.propTypes = {
	isAuthenticated: PropTypes.bool.isRequired,
	isAuthenticatedPage: PropTypes.bool.isRequired,
	isOpenModal: PropTypes.bool.isRequired,
	filterID: PropTypes.string.isRequired,
	customerID: PropTypes.string
}

export default CustomerModalComponent
