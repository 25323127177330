import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Inputs from '../../../../../components/mui/Inputs';
import { getArrayListToDropdownCommercialBuilding } from '../services';
import { setIsLoadingForm, setArrayListToDropdownCommercialBuilding } from '../redux/actions/amenityAction';
import usePermission from '../../../../../hooks/usePermission';
import swalErrors from '../../../../../hooks/useErrors';

const DropdownSelectCommercialBuilding = ({ name, value = '', areaId = '', handleChange, ...more }) => {
	const dispatch = useDispatch();
	const isLoading = useSelector( ({ amenity }) => amenity.isLoadingForm);
	const arrayListToDropdown = useSelector( ({ amenity }) => amenity.arrayListToDropdownCommercialBuilding);
	const permission = usePermission('amenities.dropdownCommercialBuilding');
	
	/**
	 *
	 * @description Execute endpoint array list areas in dropdown
	 * @return dispatch
	 *
	 */
	const handleArrayList = async () => {
		if (permission && areaId !== '') {
			dispatch( setIsLoadingForm(true) );
			
			try {
				const { data } = await getArrayListToDropdownCommercialBuilding({ search: areaId });
				
				if (data.status === 200) {
					await dispatch( setArrayListToDropdownCommercialBuilding(data.data) );
				}
			} catch (error) {
				// await swalErrors(error);
			}
			
			dispatch( setIsLoadingForm(false) );
		}
	};
	
	useEffect(() => {
		handleArrayList();
	}, [areaId]);
	
	return (
		<>
			{permission && !isLoading && (
				<Inputs.SelectMultiple
					label="Amenidad"
					name={name}
					value={value}
					itemsArray={arrayListToDropdown}
					onChange={handleChange}
					className="animated__animated animated__fadeIn"
					{...more}
				/>
			)}
		</>
	);
};

DropdownSelectCommercialBuilding.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.any,
	areaId: PropTypes.any,
	handleChange: PropTypes.func.isRequired
};

export default DropdownSelectCommercialBuilding;
