import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Inputs from '../../../../components/mui/Inputs';
import DropdownActionComponent from './DropdownActionComponent';
import { setUpdateStatusById } from '../services';
import { setIsOpenModal, setRefreshTable, setFormData, setFormType, setIsLoadingShow, setIsLoadingForm, setArrayListProperties, setPageProperties, setPagesProperties } from '../redux/actions/ownerAction';
import { setActivityLogReset, setIsOpenModal as setIsOpenModalActivity, setLogName, setSubjectId } from '../../../../redux/actions/dashboard/activities/activityLogAction';
import swalErrors from '../../../../hooks/useErrors';
import { swalSuccess } from '../../../../hooks/useSweetAlert';
import usePermission from '../../../../hooks/usePermission';

const TableRowComponent = (props) => {
	const {
		id,
		type,
		personType,
		company,
		name,
		email,
		mobile,
		status
	} = props;
	const dispatch = useDispatch();
	const refreshTable = useSelector(({ owner }) => owner.refreshTable);
	const permission = usePermission('owners.status');
	
	useEffect(() => {
	}, [
		id,
		type,
		personType,
		company,
		name,
		email,
		mobile,
		status
	]);
	
	/**
	 *
	 * @description Execute dispatch open modal, reset formData, loading show, loading form, formType and activityLog state
	 * @return dispatch
	 *
	 */
	const handleIsOpenModal = async (formType) => {
		if (formType === 'activity-log') {
			await dispatch( setActivityLogReset() );
			await dispatch( setLogName('Personas') );
			await dispatch( setSubjectId(id) );
			await dispatch( setIsOpenModalActivity(true) );
		} else {
			if (formType === 'properties') {
				await dispatch( setArrayListProperties([]) );
				await dispatch( setPageProperties(1) );
				await dispatch( setPagesProperties(1) );
			}
			
			await dispatch( setFormData({ id: id, name: personType === 'Natural' ? name : company }) );
			await dispatch( setFormType(formType) );
			await dispatch( setIsLoadingShow(false) );
			await dispatch( setIsLoadingForm(false) );
			await dispatch( setIsOpenModal(true) );
		}
	};
	
	/**
	 *
	 * @description Execute endpoint change status
	 * @return dispatch
	 *
	 */
	const handleUpdateStatusById = async () => {
		if (permission) {
			try {
				const { data } = await setUpdateStatusById(id);
				
				if (data.status === 200) {
					await swalSuccess('Cambio de estatus', data.data);
					await dispatch( setRefreshTable(!refreshTable) );
				}
			} catch (error) {
				await swalErrors(error);
			}
		}
	};
	
	return (
		<>
			<tr className="m-0 p-0">
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="10%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark text-center">
						{ personType }
					</div>
				</td>
				{(type === 'all' || type === 'Jurídica') && (
					<td
						className="m-0 pt-0 pb-0 pl-2 pr-2"
						width={type === 'all' ? '20%' : '30%'}
					>
						<div className="font-family-roboto-regular fs-6 text-dark">
							{ company }
						</div>
					</td>
				)}
				{(type === 'all' || type === 'Natural') && (
					<td
						className="m-0 pt-0 pb-0 pl-2 pr-2"
						width={type === 'all' ? '20%' : '30%'}
					>
						<div className="font-family-roboto-regular fs-6 text-dark">
							{ name }
						</div>
					</td>
				)}
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width={type === 'all' ? '20%' : '30%'}
				>
					<div className="font-family-roboto-regular fs-6 text-dark">
						{ email }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="15%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark text-center">
						{ mobile }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="10%"
				>
					<div className="text-center">
						<Inputs.Switch
							name="status"
							color={status === 'Active' ? 'success' : 'error'}
							isChecked={status === 'Active' ? true : false}
							position="end"
							onChange={handleUpdateStatusById}
						/>
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="5%"
				>
					<div className="mx-auto d-flex justify-content-center">
						<DropdownActionComponent
							handleIsOpenModal={handleIsOpenModal}
						/>
					</div>
				</td>
			</tr>
		</>
	);
};

TableRowComponent.propTypes = {
	id: PropTypes.number.isRequired,
	type: PropTypes.string,
	personType: PropTypes.string,
	company: PropTypes.string,
	name: PropTypes.string,
	email: PropTypes.string,
	mobile: PropTypes.string,
	status: PropTypes.string.isRequired
};

export default TableRowComponent;
