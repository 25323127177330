import { initialValuesCountry as initialValues } from '../../../../../../../helpers/variablesInitialValues';
import * as types from '../types/countryType';

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	search: '',
	arrayList: [],
	arrayListToDropdown: [],
	refreshTable: false,
	isOpenModal: false,
	isOpenModalPermission: false,
	isLoadingShow: false,
	isLoadingForm: false,
	formType: '',
	formData: initialValues
};

const countryReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_COUNTRY:
			return {
				...state,
				perPage: payload
			};
		
		case types.PAGE_COUNTRY:
			return {
				...state,
				page: payload
			};
		
		case types.PAGES_COUNTRY:
			return {
				...state,
				pages: payload
			};
		
		case types.SEARCH_COUNTRY:
			return {
				...state,
				search: payload
			};
		
		case types.ARRAY_LIST_COUNTRY:
			return {
				...state,
				arrayList: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_COUNTRY:
			return {
				...state,
				arrayListToDropdown: payload
			};
		
		case types.REFRESH_TABLE_COUNTRY:
			return {
				...state,
				refreshTable: payload
			};
		
		case types.IS_OPEN_MODAL_COUNTRY:
			return {
				...state,
				isOpenModal: payload
			};
		
		case types.IS_LOADING_SHOW_COUNTRY:
			return {
				...state,
				isLoadingShow: payload
			};
		
		case types.IS_LOADING_FORM_COUNTRY:
			return {
				...state,
				isLoadingForm: payload
			};
		
		case types.FORM_TYPE_COUNTRY:
			return {
				...state,
				formType: payload
			};
		
		case types.FORM_DATA_COUNTRY:
			return {
				...state,
				formData: payload
			};
		
		default:
			return state
	}
};

export default countryReducer;
