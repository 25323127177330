import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Box, styled } from '@mui/material'
import { setIsSelectedToggleSwitch } from '../../../../../redux/actions/dashboard/filtered/filteredAction'
import { filteredToggleSwitch } from '../../../../../helpers/variablesColumns'
import { OptionsWrapperStyles } from './Styles'

const commomOptionTypes = {
  flexGrow: 1,
  display: 'flex',
  cursor: 'pointer',
  minHeight: 'auto',
  userSelect: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'inherit',
}

export const OptionType = styled(Box)(({ theme, active }) => ({
  padding: theme.spacing(5 / 8, 10 / 8),
  ...commomOptionTypes,
  backgroundColor: theme.palette.common.white,
  fontFamily: theme.typography.fontFamily,
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.standard,
  }),
  ...(active && {
    fontWeight: 700,
    color: theme.palette.white.default,
    backgroundColor: theme.palette.dark.default,
    borderRadius: '2px',
  }),
}))

const ToggleSwitchComponent = ({ isAuthenticated = false, hasProperties = false, hasFavorites = false, hasActivityLog = false }) => {
  const dispatch = useDispatch()
  const isActiveHistorical = useSelector(state => state.filtered.isActiveHistorical)
  const isSelectedToggleSwitch = useSelector(state => state.filtered.isSelectedToggleSwitch)
  
  /**
   *
   * @description Execute dispatch select toggle switch state
   * @return dispatch
   *
   */
  const handleSelected = value => dispatch( setIsSelectedToggleSwitch(value) )

  // VALIDATE ACTIVE TAB FOR WHEN AUTHENTICATED
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     dispatch(setIsSelectedToggleSwitch(1))
  //   }
  // }, [])

  return (
    <>
      {!isActiveHistorical && (
        <Grid container margin="0 auto" my={15 / 8} maxWidth="340px">
          <Grid item container sx={{ ...OptionsWrapperStyles, border: 'none' }}>
            {hasProperties && (
              <OptionType
                sx={{
                  minHeight: '36px',
                }}
                active={isSelectedToggleSwitch === 0 ? true : false}
                onClick={() => handleSelected(0)}
              >
                Inmuebles
              </OptionType>
            )}
            {hasFavorites && (
              <OptionType
                sx={{
                  minHeight: '36px',
                }}
                active={isSelectedToggleSwitch === 1 ? true : false}
                onClick={() => handleSelected(1)}
              >
                Favoritos
              </OptionType>
            )}
            {hasActivityLog && isAuthenticated && (
              <OptionType
                sx={{
                  minHeight: '36px',
                }}
                active={isSelectedToggleSwitch === 2 ? true : false}
                onClick={() => handleSelected(2)}
              >
                Actividad
              </OptionType>
            )}
            {/*{filteredToggleSwitch.length > 0 && (*/}
            {/*  filteredToggleSwitch.map(({ id, label }, index) => (*/}
            {/*    <OptionType*/}
            {/*      sx={{*/}
            {/*        minHeight: '36px',*/}
            {/*      }}*/}
            {/*      key={index}*/}
            {/*      active={isSelectedToggleSwitch === index ? true : false}*/}
            {/*      onClick={() => handleSelected(index)}*/}
            {/*    >*/}
            {/*      {label}*/}
            {/*    </OptionType>*/}
            {/*  ))*/}
            {/*)}*/}
          </Grid>
        </Grid>
      )}
    </>
  )
}

ToggleSwitchComponent.propTypes = {
  isAuthenticated: PropTypes.bool,
  hasProperties: PropTypes.bool,
  hasFavorites: PropTypes.bool,
  hasActivityLog: PropTypes.bool
}
export default ToggleSwitchComponent
