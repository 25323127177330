export const PER_PAGE_ACTIVITY_LOG = 'PER_PAGE_ACTIVITY_LOG'
export const PAGE_ACTIVITY_LOG = 'PAGE_ACTIVITY_LOG'
export const PAGES_ACTIVITY_LOG = 'PAGES_ACTIVITY_LOG'
export const START_DATE_ACTIVITY_LOG = 'START_DATE_ACTIVITY_LOG'
export const FINISH_DATE_ACTIVITY_LOG = 'FINISH_DATE_ACTIVITY_LOG'
export const ACTIVITY_NAME_ACTIVITY_LOG = 'ACTIVITY_NAME_ACTIVITY_LOG'
export const LOG_NAME_ACTIVITY_LOG = 'LOG_NAME_ACTIVITY_LOG'
export const SUBJECT_ID_ACTIVITY_LOG = 'SUBJECT_ID_ACTIVITY_LOG'
export const ARRAY_LIST_ACTIVITY_LOG = 'ARRAY_LIST_ACTIVITY_LOG'
export const ARRAY_LIST_DROPDOWN_ACTIVITY_LOG = 'ARRAY_LIST_DROPDOWN_ACTIVITY_LOG'
export const IS_OPEN_MODAL_ACTIVITY_LOG = 'IS_OPEN_MODAL_ACTIVITY_LOG'
export const IS_LOADING_ACTIVITY_LOG = 'IS_LOADING_ACTIVITY_LOG'
