import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../../../../components/mui/Layout';
import TableRowComponent from './TableRowComponent';
import { getArrayList } from '../services';
import { setIsLoading } from '../../../../../redux/loading/actions/loadingAction';
import { setPerPage, setPage, setPages, setArrayList } from '../redux/actions/realStateAction';
import swalErrors from '../../../../../hooks/useErrors';
import usePermission from '../../../../../hooks/usePermission';
import { realStateColumns as columns, perPages } from '../../../../../helpers/variablesColumns';

const TableComponent = () => {
	const dispatch = useDispatch();
	const isLoading = useSelector( ({ loading }) => loading.isLoading);
	const refreshTable = useSelector( ({ realState }) => realState.refreshTable);
	const perPage = useSelector( ({ realState }) => realState.perPage);
	const page = useSelector( ({ realState }) => realState.page);
	const search = useSelector( ({ realState }) => realState.search);
	const pages = useSelector( ({ realState }) => realState.pages);
	const arrayList = useSelector( ({ realState }) => realState.arrayList);
	const permission = usePermission('realstates.list');
	
	/**
	 *
	 * @description Execute endpoint array list of realstates
	 * @return dispatch
	 *
	 */
	const handleArrayList = async () => {
		if (permission) {
			await dispatch(setIsLoading(page === 1 ? true : false));
			
			try {
				const param = {
					perPage: perPage,
					page: page,
					search: search === '' ? null : search
				};
				
				const {data} = await getArrayList(param);
				
				if (data.status === 200) {
					await dispatch(setPages(data.data.pages));
					await dispatch(setArrayList(data.data.records));
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch(setIsLoading(false));
		}
	};
	
	useEffect(() => {
		handleArrayList();
	}, [
		perPage,
		page,
		refreshTable
	]);
	
	/**
	 *
	 * @description Execute dispatch perPage state
	 * @return dispatch
	 *
	 */
	const handleChangePerPage = ({ target }) => dispatch( setPerPage(target.value) );
	
	/**
	 *
	 * @description Execute dispatch page state
	 * @return dispatch
	 *
	 */
	const handleChangePage = (event, value) => dispatch( setPage(value) );
	
	return (
		<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
			<Layout.CardContent className="m-0 p-4">
				{permission && (
					<>
						<Layout.TablePagination
							arrayList={arrayList.length}
							perPage={perPage}
							perPages={perPages}
							page={page}
							pages={pages}
							handleChangePerPage={handleChangePerPage}
							handleChangePage={handleChangePage}
						/>
						<Layout.Table>
							<Layout.TableHead columns={columns} />
							<tbody>
							{isLoading && (
								<Layout.TableRowLoading columns={columns} />
							)}
							{!isLoading && (
								<>
									{arrayList.length === 0 && (
										<Layout.TableRowNoFound colSpan={columns.length} />
									)}
									{arrayList.length > 0 && (
										arrayList.map((row, index) => (
											<TableRowComponent
												key={index}
												id={row.id}
												logo={row.logo}
												name={row.name}
												numberId={row.number_id}
												brokerId={row.broker_id}
												apiTokenDomus={row.api_token_domus}
												idDomus={row.id_domus}
												apiTokenPipeDrive={row.api_token_pipedrive}
												subDomainPipeDrive={row.subdomain_pipedrive}
												color={row.color}
												status={row.status}
											/>
										))
									)}
								</>
							)}
							</tbody>
						</Layout.Table>
						<Layout.TablePagination
							arrayList={arrayList.length}
							perPage={perPage}
							perPages={perPages}
							page={page}
							pages={pages}
							handleChangePerPage={handleChangePerPage}
							handleChangePage={handleChangePage}
						/>
					</>
				)}
			</Layout.CardContent>
		</Layout.Card>
	);
};

export default TableComponent;
