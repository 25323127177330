import * as types from '../../../actionsTypes/properties/propertyDetailTypes'
import { initialValuesPropertyDetail as initialValues } from '../../../../helpers/variablesInitialValues'
import { setIsLoading } from '../../loadingAction'
import { getArrayListFilesByAlbumsTypes, getShowById } from './services'
import errorServices from '../../../../services/errors'

export const getArray = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingTable(true) )
		
		await getArrayListFilesByAlbumsTypes(param).then(({ data }) => {
			dispatch( setPages(data.data.pages) )
			dispatch( setArrayListAlbumsTypesFiles(data.data.records) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingTable(false) )
	}
)

export const showById = id => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getShowById(id).then(({ data }) => {
			const object = {
				status: data.data.status,
				colorStatus: data.data.color_status,
				albumName: data.data.album_name,
				createdDate: data.data.created_date,
				createdAt: data.data.created_at,
				createdAgo: data.data.created_ago,
				mobiliCode: data.data.mobili_code,
				domusCode: data.data.domus_code,
				hasBuilding: data.data.has_building,
				city: data.data.city,
				neighborhood: data.data.neighborhood,
				address: data.data.address,
				reference: data.data.reference,
				strata: data.data.strata,
				numberRoom: data.data.number_room,
				numberBathRoom: data.data.number_bathroom,
				garage: data.data.has_garage ? `Si - ${data.data.number_garage} puestos` : 'No',
				type: data.data.type,
				destination: data.data.destination,
				action: data.data.action
			}
			
			dispatch( setFormData(object) )
			dispatch( setArrayListAlbumsTypes(data.data.albums_types) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)
export const getPropertyDetails = (id) => async (dispatch) => {
	await dispatch(setIsLoading(true));
	
	await getShowById(id).then(({ data }) => {
		dispatch(setFormData({ ...data.data }));
		dispatch(setArrayListAlbumsTypes(data.data.albums_types));
	}).catch((error) => errorServices(error));
	
	await dispatch(setIsLoading(false));
};

export const setOwnerInventoryReset = () => (
	async (dispatch) => {
		await dispatch( setPerPage(20) )
		await dispatch( setPage(1) )
		await dispatch( setPages(1) )
		await dispatch( setIsLoadingTable(false) )
		await dispatch( setArrayListAlbumsTypes([]) )
		await dispatch( setArrayListAlbumsTypesFiles([]) )
		await dispatch( setFormData(initialValues) )
	}
)

export const setPerPage = value => ({
	type: types.PER_PAGE_PROPERTY_DETAIL_NO_AUTH,
	payload: value
})

export const setPage = value => ({
	type: types.PAGE_PROPERTY_DETAIL_NO_AUTH,
	payload: value
})

export const setPages = value => ({
	type: types.PAGES_PROPERTY_DETAIL_NO_AUTH,
	payload: value
})

export const setIsLoadingTable = value => ({
	type: types.IS_LOADING_TABLE_PROPERTY_DETAIL_NO_AUTH,
	payload: value
})

export const setArrayListAlbumsTypes = value => ({
	type: types.ARRAY_LIST_ALBUMS_TYPES_PROPERTY_DETAIL_NO_AUTH,
	payload: value
})

export const setArrayListAlbumsTypesFiles = value => ({
	type: types.ARRAY_LIST_ALBUMS_TYPES_FILES_PROPERTY_DETAIL_NO_AUTH,
	payload: value
})

export const setFormData = value => ({
	type: types.FORM_DATA_PROPERTY_DETAIL_NO_AUTH,
	payload: value
})
