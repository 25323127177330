import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import AlertLabel from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

function MuiAlert(props) {
    return (
        <AlertLabel
            elevation={6}
            variant="filled"
            {...props}
        />
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiAlert-root': {
            top: '90px',
            position: 'relative',
            zIndex: '999 !important'
        }
    }
}))

export default function Alert(props) {

    const classes = useStyles()
    const { openAlert, background, label, setOpenAlert } = props

    const handleClose = (event) => {
        setOpenAlert(!openAlert)
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open={openAlert}
            autoHideDuration={6000}
            onClose={handleClose}
            className={classes.root}
        >
            <MuiAlert onClose={handleClose} severity={background}>
                {label}
            </MuiAlert>
        </Snackbar>
    )
}
