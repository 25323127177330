import { initialValuesOwnerMobili as initialValues } from '../../../../../../helpers/variablesInitialValues'
import * as types from '../../../../../actionsTypes/dashboard/settings/owners/ownerMobiliTypes'
import { setOwnerDomusReset } from '../domus/ownerDomusAction'
import { setStore } from './services'
import { getShowByMobile } from '../../../owners/services'
import errorServices from '../../../../../../services/errors'
import { swalSuccess } from '../../../../../../services/sweetalert'

export const store = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingButton(true) )
		
		await setStore(param).then(({ data }) => {
			dispatch( setOwnerDomusReset() )
			swalSuccess('Migración exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingButton(false) )
	}
)

export const showByMobile = mobile => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getShowByMobile(mobile).then(({ data }) => {
			const object = {
				personType: data.data.person_type,
				company: data.data.company === null ? '' : data.data.company,
				firstName: data.data.first_name === null ? '' : data.data.first_name,
				lastName: data.data.last_name === null ? '' : data.data.last_name,
				email: data.data.email,
				mobile: data.data.mobile,
				phone: data.data.phone === null ? '' : data.data.phone,
				state: data.data.state === null ? '' : data.data.state,
				city: data.data.city === null ? '' : data.data.city
			}
			
			dispatch( setFormData(object) )
			dispatch( setIsActiveButton(false) )
		}).catch(error => {
			dispatch( setIsActiveButton(true) )
			errorServices(error)
		})
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setOwnerMobiliReset = () => (
	async (dispatch) => {
		await dispatch( setIsActiveButton(false) )
		await dispatch( setIsLoadingButton(false) )
		await dispatch( setIsLoadingForm(false) )
		await dispatch( setFormData(initialValues) )
	}
)

export const setIsActiveButton = value => ({
	type: types.IS_ACTIVE_BUTTON_OWNER_MOBILI,
	payload: value
})

export const setIsLoadingButton = value => ({
	type: types.IS_LOADING_BUTTON_OWNER_MOBILI,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_OWNER_MOBILI,
	payload: value
})

export const setFormData = value => ({
	type: types.FORM_DATA_OWNER_MOBILI,
	payload: value
})
