import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Stack from '@mui/material/Stack'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Layout from '../../../../../components/mui/Layout'
import StatisticByOriginContactRow from "./StatisticByOriginContactRow"
import { setIsLoading } from '../../../../../redux/actions/loadingAction'
import errorServices from '../../../../../services/errors'
import axios from '../../../../../services/axios'
import { statisticOperatorsColumns, statisticAgentColumns as columns, perPages } from '../../../../../helpers/variablesColumns'

const StatisticByOriginContact = ({ getRealstateID = '', getService = '', getStatus = '', getInitDate = '', getEndDate = '' }) => {
	const dispatch = useDispatch()
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const isLoading = useSelector( state => state.loading.isLoading)
	const [arrayList, setArrayList] = useState([])
	const [page, setPage] = useState(1)
	const [pages, setPages] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [getTotalRows, setTotalRows] = useState(0)
	const [getOperator, setOperator] = useState('sum')
	const [anchorEl, setAnchorEl] = useState(null)
	const listAll = statisticOperatorsColumns
	
	/**
	 *
	 * @description Execute openMenu state
	 * @return void
	 *
	 */
	const handleIsOpenMenu = event => setAnchorEl(event.currentTarget)
	
	/**
	 *
	 * @description Execute closeMenu state
	 * @return void
	 *
	 */
	const handleCloseMenu = () => setAnchorEl(null)
	
	/**
	 *
	 * @description Execute action change operator and closeMenu state
	 * @return void
	 *
	 */
	const handleAction = (id) => {
		setOperator(id)
		handleCloseMenu()
	}
	
	/**
	 *
	 * @description Execute endpoint array list by contact of filtered statistic
	 * @return void
	 *
	 */
	const handleList = async () => {
		dispatch( setIsLoading(true) )
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				perPage: perPage,
				page : page,
				service: getService === '' ? null : getService,
				status: getStatus === '' ? null : getStatus,
				init_date: getInitDate === '' ? null : getInitDate,
				end_date: getEndDate === '' ? null : getEndDate,
				operator: getOperator,
				realstate: getRealstateID === '' ? null : getRealstateID
			}
			
			const data = await axios.post('dashboard/filtered/statistics/by-origin-contact', param)
			
			if (data.status === 200) {
				setPages(data.data.data.pages)
				setTotalRows(data.data.data.totalRows)
				setArrayList(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		dispatch( setIsLoading(false) )
	}
	
	useEffect(() => {
		handleList()
	}, [
		page,
		perPage,
		getService,
		getStatus,
		getInitDate,
		getEndDate,
		getOperator,
		getRealstateID
	])
	
	/**
	 *
	 * @description Execute perPage state
	 * @return void
	 *
	 */
	const handleChangePerPage = ({ target }) => setPerPage(target.value)
	
	/**
	 *
	 * @description Execute page state
	 * @return void
	 *
	 */
	const handleChangePage = (event, value) => setPage(value)
	
	return (
		<div className="row">
			<div className="col-sm-12">
				<Layout.TablePagination
					arrayList={arrayList.length}
					perPage={perPage}
					perPages={perPages}
					page={page}
					pages={pages}
					handleChangePerPage={handleChangePerPage}
					handleChangePage={handleChangePage}
				/>
				<Layout.Table>
					<thead className="table-dark align-middle align-self-center">
					<tr>
						<td width="5%"></td>
						<td
							align="left"
							width="35%"
						>
							<div className="font-family-roboto-medium fs-6">
								Fuente de contacto
							</div>
						</td>
						<td
							align="center"
							width="15%"
						>
							<div className="font-family-roboto-medium fs-6">
								Contactos
							</div>
						</td>
						<td
							align="center"
							width="10%"
						>
							<div className="font-family-roboto-medium fs-6">
								Calidad
							</div>
						</td>
						<td
							align="center"
							width="35%"
						>
							<div className="mx-auto d-flex justify-content-center">
								<Stack direction="row" spacing={2}>
									<div className="font-family-roboto-medium fs-6 ml-2 top-7">
										Presupuesto
									</div>
									<Layout.IconButton
										color="inherit"
										size="small"
										aria-label="actions"
										aria-controls="menu-actions"
										aria-haspopup="true"
										className="bg-secondary"
										onClick={handleIsOpenMenu}
									>
										<Layout.Icons.MoreIcon
											width={20}
											height={20}
											className="text-white"
										/>
									</Layout.IconButton>
									<Menu
										id="menu-appbar"
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left',
										}}
										open={Boolean(anchorEl)}
										onClose={handleCloseMenu}
										sx={{
											display: { xs: 'block' },
										}}
									>
										{listAll.length > 0 && (
											listAll.map(({ id, name , icon }, index) => (
												<MenuItem
													key={index}
													onClick={() => handleAction(id)}
												>
													<ListItemIcon className="font-family-roboto-regular fs-6 text-dark">
														<img
															src={icon}
															height={18}
															width={18}
															loading="lazy"
															alt=""
														/>
													</ListItemIcon>
													<ListItemText className="font-family-roboto-regular fs-6 text-dark">
														{name}
													</ListItemText>
												</MenuItem>
											))
										)}
									</Menu>
								</Stack>
							</div>
						</td>
					</tr>
					</thead>
					<tbody>
					{isLoading && (
						<Layout.TableRowLoading
							columns={columns}
						/>
					)}
					{!isLoading && (
						<>
							{arrayList.length === 0 && (
								<Layout.TableRowNoFound
									colSpan={columns.length}
								/>
							)}
							{arrayList.length > 0 && (
								arrayList.map((item, index) => (
									<StatisticByOriginContactRow
										key={index}
										row={item}
										headCells={columns}
										getService={getService}
										getStatus={getStatus}
										getInitDate={getInitDate}
										getEndDate={getEndDate}
										getOperator={getOperator}
										getRealstateID={getRealstateID}
									/>
								))
							)}
						</>
					)}
					</tbody>
				</Layout.Table>
				<Layout.TablePagination
					arrayList={arrayList.length}
					perPage={perPage}
					perPages={perPages}
					page={page}
					pages={pages}
					handleChangePerPage={handleChangePerPage}
					handleChangePage={handleChangePage}
				/>
			</div>
		</div>
	)
}

StatisticByOriginContact.propTypes = {
	getRealstateID: PropTypes.string,
	getService: PropTypes.string,
	getStatus: PropTypes.string,
	getInitDate: PropTypes.string,
	getEndDate: PropTypes.string
}

export default StatisticByOriginContact
