import React from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import HideImage from '@mui/icons-material/HideImage'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Visibility from '@mui/icons-material/Visibility'
import { SxProps, Theme } from '@mui/material'

// import no_image_property from '@/assets/no_image_property.png'

// import area_lot from '@/assets/svg/area_lot.svg'
import area_lot from '../../../assets/svg/area_lot.svg'
import bathrooms from '../../../assets/svg/bathrooms.svg'
import bedrooms from '../../../assets/svg/bedrooms.svg'
import parking from '../../../assets/svg/parking.svg'
import { toFirstUpperCase } from './utils/toFirstUpperCase'
import { formatCurrencyCOP } from './utils/formatCurrencyCop'
import PropTypes from 'prop-types'
import FavoriteBorderIcon from '../../mui/Layout/icons/FavoriteBorderIcon'
import FavoriteIcon from '../../mui/Layout/icons/FavoriteIcon'

const SquarePropertyCard = ({
  property,
  onClick,
  goToDetail,
  handleStoreLike,
  isActiveLike = false,
  showIconLike = true,
}) => {
  const hasImages = property.image != null;
	const firstImageUrl = property.image;

  const price = () => {
		// action_canon arriendo
		// action_sale venta/compra
    if(property.property_action == null) return '';
		const price =
			(property.property_action.action_type) === 'Arriendo'
				? property.property_action.canon
				: property.property_action.sale_value;
		if (price.toString().includes('$')) {
			return price.toString().replaceAll('$', '');
		}
		return price;
	};

  const locationString = () => {
		if (property.neighborhood == null) return '...';
		const city = property?.neighborhood?.city?.name;
		
		return (
			'Barrio ' +
			toFirstUpperCase(property?.neighborhood?.name ?? '') +
			', ' +
			(city != null ? city : '')
		);
	};
  return (
    <Paper
      elevation={0}
      sx={CardWrapperStyles}
      onClick={onClick}
      variant="outlined"
    >
      <Box sx={CardHeadStyles}>
        {/* <Avatar sx={{ width: 36, height: 36 }}></Avatar> */}

        <div>
          <Typography
            fontWeight={700}
            color="darkGrey.default"
            fontSize={`${12 / 16}rem`}
          >
            {property?.property_type?.name}
          </Typography>
          <Typography fontWeight={700} color="dark.default">
            {formatCurrencyCOP(+price())}
          </Typography>
        </div>
        {/* <a href={`detail/${property.codpro}`} target="_blank">
					ir
				</a> */}
        {/* <IconButton
          size="small"
          onClick={() => {
            window.open(`detalle/${property.codpro}`, '_blank')
          }}
        >
          <Visibility fontSize="small" />
        </IconButton> */}
        {showIconLike ? (
          isActiveLike ? (
            <IconButton size="medium" color="error" onClick={handleStoreLike}>
              <FavoriteIcon height={16} width={16} fontSize="medium" />
            </IconButton>
          ) : (
            <IconButton size="medium" color="inherit" onClick={handleStoreLike}>
              <FavoriteBorderIcon height={16} width={16} fontSize="medium" />
            </IconButton>
          )
        ) : null}
      </Box>
        <Box sx={ImageContainerStyles}>
				{property.domus_code != null && (
					<Box sx={FloatingDomusCodeStyles}>
						<Typography fontWeight={700} fontSize={`${12 / 16}rem`}>
							{'Domus: ' + property.domus_code}
						</Typography>
					</Box>
				)}
        {property.code != null && (
					<Box sx={FloatingCodeStyles}>
						<Typography fontWeight={700} fontSize={`${12 / 16}rem`}>
							{property.code}
						</Typography>
					</Box>
				)}
        {!hasImages && <NoImage />}
        {hasImages && (
          <img
            alt="property name"
            src={firstImageUrl}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transformOrigin: 'center center',
              aspectRatio: 1.77,
            }}
          />
        )}
      </Box>
      <Stack px={15 / 8} flexDirection="row" alignItems="center" gap={5 / 8}>
        <LocationOnIcon
          fontSize="small"
          color="secondary"
          sx={{
            width: '15px',
            height: '15px',
          }}
        />
        <Typography
          color="darkGrey.default"
          fontWeight={400}
          fontSize={`${13 / 16}rem`}
        >
          {locationString()}
        </Typography>
      </Stack>
      <Box display="flex" justifyContent="space-between" px={15 / 8}>
        <PropertyItem value={property?.area_total} icon={area_lot} alt="ar" />
        <PropertyItem value={property?.number_bathroom} icon={bathrooms} alt="ba" />
        <PropertyItem value={property?.number_room} icon={bedrooms} alt="cu" />
        <PropertyItem value={property?.number_garage} icon={parking} alt="pa" />
      </Box>
    </Paper>
  )
}

const PropertyItem = ({ value, icon, letter, alt }) => {
  return (
    <Stack direction="row" spacing={5 / 8} alignItems="center">
      <Box
        sx={{
          ...CircleStyle,
          position: 'relative',
          textAlign: 'center',
          // color: 'darkGrey.default',
        }}
      >
        <img src={icon} alt={alt} />
      </Box>
      <Typography
        color="darkGrey.default"
        fontWeight={700}
        fontSize={`${12 / 16}rem`}
      >
        {value}
      </Typography>
    </Stack>
  )
}

const NoImage = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'dark.default',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <HideImage fontSize="large" color="secondary" />
      <Typography color="white.default">Propiedad sin imagen</Typography>
    </Box>
  )
}

const CardWrapperStyles = {
  display: 'grid',
  // cursor: 'pointer',
  paddingY: ({ spacing }) => spacing(10 / 8),
  gap: ({ spacing }) => spacing(10 / 8),
  maxHeight: '270px',
}

const CardHeadStyles = {
  gap: '5px',
  display: 'grid',
  borderRadius: '5px',
  alignItems: 'center',
  paddingX: ({ spacing }) => spacing(15 / 8),
  gridTemplateColumns: '1fr max-content', // 36px 1fr max-content
}

/**
 * @type {React.CSSProperties}
 */
const ImageContainerStyles = {
  height: '130px', //180px
  overflow: 'hidden',
  position: 'relative',
  zIndex: 'inherit',
  cursor: 'pointer',
  // outline: "2px solid red",
}

const FloatingCodeStyles = {
  padding: '5px',
  position: 'absolute',
  right: 10,
  bottom: 10,
  borderRadius: '5px',
  display: 'inline-flex',
  background: `#f6fde5`,
}

const FloatingDomusCodeStyles = {
  padding: '5px',
  position: 'absolute',
  left: 10,
  bottom: 10,
  borderRadius: '5px',
  display: 'inline-flex',
  background: `#f6fde5`,
}

const CircleStyle = {
  height: '25px',
  width: '25px',
  aspectRatio: 1,
  // borderRadius: '50%',
  // backgroundColor: 'mediumGrey.default',
}

SquarePropertyCard.propTypes = {
  onClick: PropTypes.func,
  property: PropTypes.object.isRequired,
}
export default SquarePropertyCard
