import React from 'react'
import logo from "../../../assets/images/logo/logo.png"

const Logo = ({ ...more }) => {

  return (
    <img
      alt=""
      loading="lazy"
      src={logo}
      { ...more }
    />
  )
}

export default Logo
