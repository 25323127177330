import { useFormikContext, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FieldErrors from "../../../../../../components/v2-mobili/errors/FieldErrors";

const channelOptions = [
  ...Array(10)
    .fill()
    .map((_, index) => `Origen ${index + 1}`),
];
const SourceChannelField = () => {
  const { getFieldProps, errors, touched } = useFormikContext();
  return (
    <Grid container flexDirection="column" item>
      <TextField
        select
        error={errors.sourceChannel && touched.sourceChannel}
        size="small"
        label="Canal de orígen"
        {...getFieldProps("sourceChannel")}
      >
        {channelOptions.map((option, key) => (
          <MenuItem key={key} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <FieldErrors>
        <ErrorMessage name="sourceChannel" />
      </FieldErrors>
    </Grid>
  );
};

export default SourceChannelField;
