import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../../../../../components/mui/Layout'
import Inputs from '../../../../../../components/mui/Inputs'
import { showByMobile, setSearch, setOwnerDomusReset } from '../../../../../../redux/actions/dashboard/settings/owners/domus/ownerDomusAction'
import { store, showByMobile as showByMobileMobili } from '../../../../../../redux/actions/dashboard/settings/owners/mobili/ownerMobiliAction'
import { Permission } from '../../../../../../services/permissions/Permission'

const SearchComponent = () => {
	const dispatch = useDispatch()
	const search = useSelector( state => state.ownerDomus.search)
	const formData = useSelector( state => state.ownerDomus.formData)
	const isActive = useSelector( state => state.ownerMobili.isActiveButton)
	const isLoading = useSelector( state => state.ownerMobili.isLoadingButton)
	
	useEffect(() => {
	}, [search])
	
	const getSearchByMobile = async () => {
		if (search === '') {
			handleClear()
		} else {
			await dispatch( showByMobile(search) )
			await dispatch( showByMobileMobili(search) )
		}
	}
	
	const handleClear = () => dispatch( setOwnerDomusReset() )
	
	const handleChangeSearch = ({ target }) => dispatch( setSearch(target.value) )
	
	const handleKeyPress = async ({ charCode }) => charCode === 13 && await getSearchByMobile()
	
	const handleSubmit = async  () => {
		const param = {
			person_type: formData.personType,
			company: formData.company,
			first_name: formData.firstName,
			last_name: formData.lastName,
			email: formData.email,
			mobile: formData.mobile,
			phone: formData.phone,
			state_id: formData.stateId,
			city: formData.city
		}
		
		await dispatch( store(param) )
	}
	
	return (
		<Layout.Card className="border-radius-0 m-0 p-0">
			<Layout.CardContent className="m-0 p-3">
				<div className="row mt-2 mb-0">
					<div className="col-sm-12 col-md-5 col-lg-4 mb-1 d-flex justify-content-start">
						<Permission
							permission="domus.owners.showOnDomus"
						>
							<Inputs.TextBox
								type="text"
								variant="outlined"
								size="small"
								name="search"
								label="Buscar"
								placeholder="Ingrese código del propietario..."
								value={search}
								onChange={handleChangeSearch}
								onKeyPress={handleKeyPress}
								className="font-family-roboto-medium text-dark fs-5 w-100"
							/>
						</Permission>
					</div>
					<div className="col-sm-12 col-md-2 col-lg-3"></div>
					<div className="col-sm-12 col-md-5 col-lg-5 d-flex justify-content-end">
						{isActive && (
							<Permission
								permission="domus.owners.storeOnMobili"
							>
								<Inputs.LoadingButton
									isLoading={isLoading}
									isLoadingPosition="start"
									startIcon={<Layout.Icons.SaveIcon width={20} height={20} />}
									label="Guardar"
									type="button"
									className="font-family-roboto-regular fs-6 bg-primary text-capitalize mr-3 animate__animated animated__fadeIn"
									onClick={handleSubmit}
								/>
							</Permission>
						)}
						<Layout.Tooltip
							title="Limpiar búsqueda"
							placement="top"
						>
							<Layout.IconButton
								color="inherit"
								size="small"
								onClick={handleClear}
								edge="start"
								className="bg-secondary font-roboto-semibold mt-1"
							>
								<Layout.Icons.CloseIcon
									width={18}
									height={18}
									className="font-roboto-semibold text-white"
								/>
							</Layout.IconButton>
						</Layout.Tooltip>
					</div>
				</div>
			</Layout.CardContent>
		</Layout.Card>
	)
}

export default SearchComponent
