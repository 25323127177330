import React from 'react'
import MuiTypography from '@material-ui/core/Typography'

export default function Typography(props) {
    const { variant, color, label, ...other } = props

    return (
        <MuiTypography
            variant={variant}
            color={color}
            {...other}
        >
            {label}
        </MuiTypography>
    )
}