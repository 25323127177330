import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import Grid from "@material-ui/core/Grid"
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Feedback from "../../../../../components/feedback/Feedback"
import Display from "../../../../../components/display/Display"
import Controls from "../../../../../components/controls/Controls"
import ModalAuthorization from "./ModalAuthorization"
import AuthorizationRow from "./AuthorizationRow"
import errorServices from '../../../../../services/errors'
import axios from '../../../../../services/axios'

const headCells = [
	{ id: 'action', label: '', align: 'center', width: '10%' },
	{ id: 'date', label: 'Fecha', align: 'center', width: '30%' },
	{ id: 'status', label: 'Estatus', align: 'center', width: '30%' },
	{ id: 'owners', label: 'Propietarios asignados', align: 'center', width: '30%' },
]

const Authorization = ({ getPropertyID }) => {
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [getLoading, setLoading] = useState(false)
	const [open, setOpen] = useState(false)
	const [getCells, setCells] = useState(headCells)
	const [getRecords, setRecords] = useState([])
	const [getPages, setPages] = useState(0)
	const [getTotalRows, setTotalRows] = useState(0)
	const [getPage, setPage] = useState(1)
	const { TblContainer, TblHead } = Display.Table(getCells, getRecords)
	
	const handleList = async () => {
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				perPage: 10,
				page: getPage,
				property_id: getPropertyID
			}
			
			const data = await axios.post('dashboard/properties/authorizations/list', param)
			
			if (data.status === 200) {
				setPages(data.data.data.pages)
				setTotalRows(data.data.data.totalRows)
				setRecords(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	useEffect(() => {
		handleList()
	}, [getPropertyID, getPage])
	
	const handleChangePage = (value) => {
		setPage(value)
	}
	
	const handleOpen = () => {
		setOpen(!open)
	}
	
	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<div className="float-right">
						<Controls.Button
							label="Nuevo"
							color="primary"
							variant="contained"
							endIcon={<Display.Icons.AddOutlinedIcon />}
							onClick={handleOpen}
						/>
					</div>
				</Grid>
				{getTotalRows > 19 && (
					<Grid item xs={12} className="m-0 animate__animated animate__fadeIn">
						<Display.Pagination
							pageCount={getPages}
							pageIndex={getPage}
							handleChangePage={handleChangePage}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<TblContainer>
						<TblHead />
						<TableBody>
							{getLoading && (
								<TableRow>
									{headCells.map(({ width }, index) => (
										<TableCell
											key={index}
											className="p-2"
											align="center"
											width={width}
										>
											<Feedback.Loading type="text" animation="wave" width="100%" height="30px" />
										</TableCell>
									))}
								</TableRow>
							)}
							{!getLoading && getRecords.length > 0 && (
								getRecords.map((item, number) => (
									<AuthorizationRow
										key={number}
										row={item}
										handleList={handleList}
									/>
								))
							)}
							{!getLoading && getRecords.length === 0 && (
								<TableRow>
									<TableCell className="p-2" colSpan={headCells.length} align="center" width="100%">No hay registros</TableCell>
								</TableRow>
							)}
						</TableBody>
					</TblContainer>
				</Grid>
				{getTotalRows > 19 && (
					<Grid item xs={12} className="m-0 animate__animated animate__fadeIn">
						<Display.Pagination
							pageCount={getPages}
							pageIndex={getPage}
							handleChangePage={handleChangePage}
						/>
					</Grid>
				)}
			</Grid>
			<Feedback.DialogLoading
				getLoading={getLoading}
				setLoading={setLoading}
			/>
			<ModalAuthorization
				getPropertyID={getPropertyID}
				open={open}
				setOpen={setOpen}
				handleList={handleList}
			/>
		</>
	)
}

export default Authorization
