export const PER_PAGE_ORIGIN_CHANNEL = 'PER_PAGE_ORIGIN_CHANNEL';
export const PAGE_ORIGIN_CHANNEL = 'PAGE_ORIGIN_CHANNEL';
export const SEARCH_ORIGIN_CHANNEL = 'SEARCH_ORIGIN_CHANNEL';
export const PAGES_ORIGIN_CHANNEL = 'PAGES_ORIGIN_CHANNEL';
export const ARRAY_LIST_ORIGIN_CHANNEL = 'ARRAY_LIST_ORIGIN_CHANNEL';
export const ARRAY_LIST_TO_DROPDOWN_ORIGIN_CHANNEL = 'ARRAY_LIST_TO_DROPDOWN_ORIGIN_CHANNEL';
export const REFRESH_TABLE_ORIGIN_CHANNEL = 'REFRESH_TABLE_ORIGIN_CHANNEL';
export const IS_OPEN_MODAL_ORIGIN_CHANNEL = 'IS_OPEN_MODAL_ORIGIN_CHANNEL';
export const IS_LOADING_SHOW_ORIGIN_CHANNEL = 'IS_LOADING_SHOW_ORIGIN_CHANNEL';
export const IS_LOADING_FORM_ORIGIN_CHANNEL = 'IS_LOADING_FORM_ORIGIN_CHANNEL';
export const FORM_TYPE_ORIGIN_CHANNEL = 'FORM_TYPE_ORIGIN_CHANNEL';
export const FORM_DATA_ORIGIN_CHANNEL = 'FORM_DATA_ORIGIN_CHANNEL';
