import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Layout from '../../../../../components/mui/Layout'
import Inputs from '../../../../../components/mui/Inputs'

const DeleteComponent = ({ name, handleCloseModal, handleToDeleteById }) => {
	
	useEffect(() => {
	}, [name])
	
	return (
		<div className="row">
			<div className="col-sm-12">
				<div className="font-family-roboto-regular fs-5 text-dark-light">
					¿Estás seguro que desea eliminar el área {name}?
				</div>
			</div>
			<div className="col-sm-12 mt-2">
				<div className="d-flex float-end">
					<Inputs.Button
						variant="outlined"
						label="Cancelar"
						startIcon={<Layout.Icons.CloseIcon width={20} height={20} />}
						onClick={handleCloseModal}
						className="font-family-roboto-regular fs-6 bg-danger-outlined text-capitalize mr-2"
					/>
					<Inputs.Button
						variant="contained"
						label="Aceptar"
						startIcon={<Layout.Icons.CheckIcon width={20} height={20} />}
						onClick={handleToDeleteById}
						className="font-family-roboto-regular fs-6 bg-primary text-capitalize mr-2"
					/>
				</div>
			</div>
		</div>
	)
}

DeleteComponent.propTypes = {
	name: PropTypes.string.isRequired,
	handleCloseModal: PropTypes.func,
	handleToDeleteById: PropTypes.func
}

export default DeleteComponent
