import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../../../components/mui/Layout';
import Feedback from '../../../../components/mui/Feedback';
import KanbanRowComponent from './KanbanRowComponent';
import { getArrayList } from '../services';
import usePermission from '../../../../hooks/usePermission';
import swalErrors from '../../../../hooks/useErrors';

const KanbanDetailComponent = ({ statusId }) => {
  const dispatch = useDispatch();
  const { search, start, finish } = useSelector( ({ commercial }) => commercial.formFilter);
  const refreshTable = useSelector( ({ commercial }) => commercial.refreshTable);
  const [isLoading, setIsLoading] = useState( false);
  const [perPage, setPerPage] = useState( 20);
  const [page, setPage] = useState( 1);
  const [pages, setPages] = useState( 1);
  const [hasNextPage, setHasNextPage] = useState( true);
  const [arrayList, setArrayList] = useState( []);
  const permission = usePermission('commercial.list');

  /**
   *
   * @description Execute endpoint array list commercial by status in dropdown
   * @return dispatch
   *
   */
  const handleArrayList = async (isPagination = false) => {
    if (permission) {
      setIsLoading(true);

      try {
        const param = {
          status: statusId,
          perPage: perPage,
          page: page,
          search: search === '' ? null : search,
          start: start === '' ? null : start,
          finish: finish === '' ? null : finish
        }

        const { data } = await getArrayList(param);

        if (data.status === 200) {
          setArrayList(data.data.records);

          setPage(data.data.page);
          setPages(data.data.pages);
          setHasNextPage(data.data.pages > data.data.page ? true : false);

          if (isPagination) {
            setArrayList(data.data.records.length === 0 ? [] : [...arrayList, ...data.data.records]);
          } else {
            setArrayList(data.data.records.length === 0 ? [] : data.data.records);
          }
        }
      } catch (error) {
        await swalErrors(error);
      }

      setIsLoading(false);
    } else {
      setArrayList([]);
    }
  }

  useEffect(() => {
    handleArrayList(page === 1 ? false : true);
  }, [
    statusId,
    page,
    refreshTable
  ]);

  /**
   *
   * @description Execute dispatch next page state
   * @return dispatch
   *
   */
  const handleNextPage = () => setPage(page + 1);

  return (
    <Layout.Box
      id="scrollRegister"
      width="100%"
      margin="0 auto"
      overflow="scroll"
      className="card-kanban"
    >
      <Feedback.InfinityScrolling
        dataLength={arrayList.length}
        next={handleNextPage}
        hasMore={hasNextPage}
        loader={isLoading && <Layout.KanbanRowLoading />}
        endMessage={<div className="mt-4 mb-4" />}
        scrollableTarget="scrollRegister"
      >
        {!isLoading && arrayList.length === 0 && (
          <div className="font-family-roboto-regular fs-6 text-center">No hay datos para esta consulta</div>
        )}
        {!isLoading && arrayList.length > 0 && (
          arrayList.map((item, index) => (
            <KanbanRowComponent
              key={index}
              commercial={item}
            />
          ))
        )}
      </Feedback.InfinityScrolling>
    </Layout.Box>
  );
};

KanbanDetailComponent.propTypes = {
  statusId: PropTypes.number.isRequired
};

export default KanbanDetailComponent;
