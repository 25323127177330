import Autocomplete from './Autocomplete';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import CheckBox from './CheckBox';
import LoadingButton from './LoadingButton';
import Rating from './Rating';
import Select from './Select';
import SelectMultiple from './SelectMultiple';
import Switch from './Switch';
import TextArea from './TextArea';
import TextBox from './TextBox';
import TextBoxPassword from './TextBoxPassword';

const index = {
  Autocomplete,
  Button,
  ButtonGroup,
  CheckBox,
  LoadingButton,
  Rating,
  Select,
  SelectMultiple,
  Switch,
  TextArea,
  TextBox,
  TextBoxPassword,
};

export default index;
