import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import MuiAutocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'

const Autocomplete = (props) => {
	const {
		variant,
		size = 'medium',
		name,
		label,
		values = null,
		itemsArray,
		onChange = null,
		onBlur = null,
		hasError = false,
		errorMessage = '',
		handleChangeSearch = null,
		...more
	} = props
	
	const loading = itemsArray.length < 1
	
	return (
		<>
			<MuiAutocomplete
				multiple
				limitTags={1}
				id={name}
				name={name}
				options={itemsArray}
				loading={loading}
				value={values}
				getOptionLabel={option => option.name}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				onChange={(_, values) => {
					onChange(values)
				}}
				renderTags={(tagValue, getTagProps) =>
					tagValue.map(({ name }, index) => (
						<Chip
							variant={variant}
							size={size}
							label={name}
							{...getTagProps({ index })}
							sx={{
								["&.MuiAutocomplete-tag"]: {
									mx: 0.2,
									my: 0.2,
								},
							}}
						/>
					))
				}
				renderInput={(params) => (
					<TextField
						{...params}
						variant={variant}
						size={size}
						label={label}
						onChange={e => handleChangeSearch(e.target.value)}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
				{...more}
			/>
			{hasError && errorMessage !== "" && (
				<div className="mt-1 bg-grey-light border-l-4 border-danger p-2 font-family-roboto-regular fs-6 text-danger animate__animated animate__fadeIn">
					{errorMessage}
				</div>
			)}
		</>
	)
}

Autocomplete.propTypes = {
	variant: PropTypes.string.isRequired,
	size: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	values: PropTypes.any,
	itemsArray: PropTypes.array.isRequired,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	hasError: PropTypes.bool,
	errorMessage: PropTypes.string,
	handleChangeSearch: PropTypes.func
}

export default Autocomplete
