import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Layout from '../../../../../components/mui/Layout'
import TableRowComponent from './TableRowComponent'

const TableComponent = (props) => {
	const {
		isLoading = false,
		page,
		pages,
		perPage,
		perPages,
		columns,
		arrayList,
		handleChangePerPage,
		handleChangePage
	} = props
	
	useEffect(() => {
	}, [
		isLoading,
		page,
		pages,
		perPage,
		perPages,
		columns,
		arrayList
	])

	return (
		<>
			<Layout.TablePagination
			  arrayList={arrayList.length}
			  perPage={perPage}
			  perPages={perPages}
			  page={page}
			  pages={pages}
			  handleChangePerPage={handleChangePerPage}
			  handleChangePage={handleChangePage}
			/>
			<Layout.Table>
		    <Layout.TableHead
		      columns={columns}
		    />
		      <tbody>
		        {isLoading && (
	            <Layout.TableRowLoading
                columns={columns}
	            />
		        )}
		        {!isLoading && (
	            <>
                {arrayList.length === 0 && (
                  <Layout.TableRowNoFound
                    colSpan={columns.length}
                  />
                )}
		            {arrayList.length > 0 && (
			            arrayList.map(({ id, client, email, amount }, number) => (
				            <TableRowComponent
					            key={number}
					            id={id}
					            client={client}
					            email={email}
					            amount={amount}
				            />
			            ))
		            )}
	            </>
		        )}
		    </tbody>
			</Layout.Table>
			<Layout.TablePagination
			  arrayList={arrayList.length}
			  perPage={perPage}
			  perPages={perPages}
			  page={page}
			  pages={pages}
			  handleChangePerPage={handleChangePerPage}
			  handleChangePage={handleChangePage}
			/>
		</>
	)
}

TableComponent.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	page: PropTypes.number.isRequired,
	pages: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	perPages: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	arrayList: PropTypes.array.isRequired,
	handleChangePerPage: PropTypes.func,
	handleChangePage: PropTypes.func
}

export default TableComponent
