import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import {setFormDataCustomer, showCustomerById, updateCustomerById } from '../../../../../redux/actions/dashboard/filtered/filteredAction'
import { Permission } from '../../../../../services/permissions/Permission'

const CustomerModalComponent = ({ filterID, customerID = '' }) => {
	const dispatch = useDispatch()
	const isLoadingForm = useSelector( state => state.filtered.isLoadingForm)
	const formData = useSelector( state => state.filtered.formDataCustomer)
	
	/**
	 *
	 * @description Execute endpoint show by id customer
	 * @return object
	 *
	 */
	const handleShowById = async () => await dispatch( showCustomerById(customerID) )
	
	useEffect(() => {
		if (customerID !== '') {
			handleShowById()
		}
	}, [filterID, customerID])
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			firstName: Yup.string()
			.required('El nombre es requerido'),
			lastName: Yup.string()
			.required('El apellido es requerido'),
			email: Yup.string()
			.email('El email no es válido')
			.required('El email es requerido'),
			mobile: Yup.string()
			.required('El celular es requerido')
		})
	)
	
	/**
	 *
	 * @description Execute submit of the form
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async () => {
		const param = {
			first_name: formData.firstName === '' ? null : formData.firstName,
			last_name: formData.lastName === '' ? null : formData.lastName,
			email: formData.email === '' ? null : formData.email,
			mobile: formData.mobile === '' ? null : formData.mobile,
			phone: formData.phone === '' ? null : formData.phone,
			filter_id: filterID,
		}
		
		await dispatch( updateCustomerById(formData.id, param) )
	}
	
	/**
	 *
	 * @description Execute dispatch formData state
	 * @return dispatch
	 *
	 */
	const handleOnChange = ({ target }) => dispatch( setFormDataCustomer( { ...formData, [target.name]: target.value }) )
	
	return (
		<Formik
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={() => {}}
		>
			{({
			  errors,
			  handleChange,
			  handleBlur,
			  isValid,
			  touched
		  }) => (
				<Form noValidate>
					<div className="row">
						<div className="col-sm-12 col-md-6">
							<Inputs.TextBox
								type="number"
								variant="outlined"
								size="small"
								name="mobile"
								label="Celular"
								placeholder="Ingrese celular..."
								hasLabelFixed={true}
								value={formData.mobile}
								onChange={(e) => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								hasError={errors.mobile && touched.mobile ? true : false}
								errorMessage={errors.mobile && touched.mobile ? errors.mobile : ''}
								className="font-family-roboto-medium text-dark fs-6 w-100"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12 col-md-6 mt-3">
							<Inputs.TextBox
								type="text"
								variant="outlined"
								size="small"
								name="firstName"
								label="Nombre"
								placeholder="Ingrese nombre..."
								hasLabelFixed={true}
								value={formData.firstName}
								onChange={(e) => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								hasError={errors.firstName && touched.firstName ? true : false}
								errorMessage={errors.firstName && touched.firstName ? errors.firstName : ''}
								className="font-family-roboto-medium text-dark fs-6 w-100"
							/>
						</div>
						<div className="col-sm-12 col-md-6 mt-3">
							<Inputs.TextBox
								type="text"
								variant="outlined"
								size="small"
								name="lastName"
								label="Apellido"
								placeholder="Ingrese apellido..."
								hasLabelFixed={true}
								value={formData.lastName}
								onChange={(e) => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								hasError={errors.lastName && touched.lastName ? true : false}
								errorMessage={errors.lastName && touched.lastName ? errors.lastName : ''}
								className="font-family-roboto-medium text-dark fs-6 w-100"
							/>
						</div>
						<div className="col-sm-12 col-md-6 mt-3">
							<Inputs.TextBox
								type="text"
								variant="outlined"
								size="small"
								name="email"
								label="Email"
								placeholder="Ingrese email..."
								hasLabelFixed={true}
								value={formData.email}
								onChange={(e) => {
									handleOnChange(e)
									handleChange(e)
								}}
								onBlur={handleBlur}
								hasError={errors.email && touched.email ? true : false}
								errorMessage={errors.email && touched.email ? errors.email : ''}
								className="font-family-roboto-medium text-dark fs-6 w-100"
							/>
						</div>
						<div className="col-sm-12 col-md-6 mt-3">
							<Inputs.TextBox
								type="number"
								variant="outlined"
								size="small"
								name="phone"
								label="Teléfono"
								placeholder="Ingrese teléfono..."
								hasLabelFixed={true}
								value={formData.phone}
								onChange={handleOnChange}
								className="font-family-roboto-medium text-dark fs-6 w-100"
							/>
						</div>
					</div>
					<Permission permission="filtereds.customers.update">
						<div className="row">
							<div className="col-sm-12">
								<Inputs.LoadingButton
									isDisabled={!isValid}
									variant="contained"
									isLoading={isLoadingForm}
									isLoadingPosition="start"
									startIcon={<Layout.Icons.EditIcon height={18} width={18} />}
									label="Actualizar"
									type="button"
									onClick={hanbleSubmit}
									className={`${isValid && 'bg-primary'} font-family-roboto-regular fs-6 text-capitalize mt-2 d-flex float-end`}
								/>
							</div>
						</div>
					</Permission>
				</Form>
			)}
		</Formik>
	)
}

CustomerModalComponent.propTypes = {
	filterID: PropTypes.string.isRequired,
	customerID: PropTypes.string
}

export default CustomerModalComponent
