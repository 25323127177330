import * as types from '../../../actionsTypes/dashboard/activityLogTypes'
import { getArrayList, getArrayListDropdown } from './services'
import errorServices from '../../../../services/errors'

export const getArray = param => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getArrayList(param).then(({ data }) => {
			dispatch( setPages(data.data.pages) )
			dispatch( setArrayList(data.data.records) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const getArrayDropdown = param => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getArrayListDropdown(param).then(({ data }) => {
			dispatch( setArrayListDropdown(data.data) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const setActivityLogReset = () => (
	async (dispatch) => {
		await dispatch( setPerPage(10) )
		await dispatch( setPage(1) )
		await dispatch( setPages(1) )
		await dispatch( setStartDate('') )
		await dispatch( setFinishDate('') )
		await dispatch( setActivityName('') )
		await dispatch( setLogName('') )
		await dispatch( setSubjectId('') )
		await dispatch( setArrayList([]) )
		await dispatch( setArrayListDropdown([]) )
		await dispatch( setIsOpenModal(false) )
		await dispatch( setIsLoading(false) )
	}
)

export const setPerPage = value => ({
	type: types.PER_PAGE_ACTIVITY_LOG,
	payload: value
})

export const setPage = value => ({
	type: types.PAGE_ACTIVITY_LOG,
	payload: value
})

export const setPages = value => ({
	type: types.PAGES_ACTIVITY_LOG,
	payload: value
})

export const setStartDate = value => ({
	type: types.START_DATE_ACTIVITY_LOG,
	payload: value
})

export const setFinishDate = value => ({
	type: types.FINISH_DATE_ACTIVITY_LOG,
	payload: value
})

export const setActivityName = value => ({
	type: types.ACTIVITY_NAME_ACTIVITY_LOG,
	payload: value
})

export const setLogName = value => ({
	type: types.LOG_NAME_ACTIVITY_LOG,
	payload: value
})

export const setSubjectId = value => ({
	type: types.SUBJECT_ID_ACTIVITY_LOG,
	payload: value
})

export const setArrayList = value => ({
	type: types.ARRAY_LIST_ACTIVITY_LOG,
	payload: value
})

export const setArrayListDropdown = value => ({
	type: types.ARRAY_LIST_DROPDOWN_ACTIVITY_LOG,
	payload: value
})

export const setIsOpenModal = value => ({
	type: types.IS_OPEN_MODAL_ACTIVITY_LOG,
	payload: value
})

export const setIsLoading = value => ({
	type: types.IS_LOADING_ACTIVITY_LOG,
	payload: value
})
