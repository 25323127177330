import React from 'react'
import PropTypes from 'prop-types'
import SimpleBarReact from 'simplebar-react'

const ScrollBar = ({ children, ...more }) => {
	return (
		<SimpleBarReact
			{...more}
		>
			{children}
		</SimpleBarReact>
	)
}

ScrollBar.propTypes = {
	children: PropTypes.any.isRequired
}

export default ScrollBar
