import logoHeader from './logo_header.svg'
import iconLeftArrow from './left_arrowr.svg'
import iconLocation from './location.svg'
import iconBath from './bath.svg'
import iconBed from './bed.svg'
import iconGarage from './garage.svg'
import iconM2 from './m2.svg'
import iconGroup from './group.svg'
import logo from './logo_footer.svg'
import iconFacebook from './facebook.svg'
import iconInstagram from './instagram.svg'
import iconTel from './iconTel.svg'
import iconBathBlue from './iconBathBlue.svg'
import iconBedBlue from './iconBedBlue.svg'
import iconGarageBlue from './iconGarageBlue.svg'
import iconHouseBlue from './iconHouseBlue.svg'
import iconM2Blue from './iconM2Blue.svg'
import AddFilesBro from './Add_files_bro_1.svg'
import IconDelete from './delete.svg'

export default {
  iconM2Blue,
  iconHouseBlue,
  iconGarageBlue,
  iconBedBlue,
  iconBathBlue,
  logoHeader,
  iconLeftArrow,
  iconLocation,
  iconBath,
  iconBed,
  iconGarage,
  iconM2,
  iconGroup,
  logo,
  iconFacebook,
  iconInstagram,
  iconTel,
  AddFilesBro,
  IconDelete,
}
