import { initialValuesPropertyType as initialValues } from '../../../../../../../helpers/variablesInitialValues';
import * as types from '../types/propertyType';

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	search: '',
	arrayList: [],
	arrayListToDropdown: [],
	refreshTable: false,
	isOpenModal: false,
	isOpenModalPermission: false,
	isLoadingShow: false,
	isLoadingForm: false,
	formType: '',
	formData: initialValues
};

const propertyTypeReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_PROPERTY_TYPE:
			return {
				...state,
				perPage: payload
			};
		
		case types.PAGE_PROPERTY_TYPE:
			return {
				...state,
				page: payload
			};
		
		case types.PAGES_PROPERTY_TYPE:
			return {
				...state,
				pages: payload
			};
		
		case types.SEARCH_PROPERTY_TYPE:
			return {
				...state,
				search: payload
			};
		
		case types.ARRAY_LIST_PROPERTY_TYPE:
			return {
				...state,
				arrayList: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_PROPERTY_TYPE:
			return {
				...state,
				arrayListToDropdown: payload
			};
		
		case types.REFRESH_TABLE_PROPERTY_TYPE:
			return {
				...state,
				refreshTable: payload
			};
		
		case types.IS_OPEN_MODAL_PROPERTY_TYPE:
			return {
				...state,
				isOpenModal: payload
			};
		
		case types.IS_LOADING_SHOW_PROPERTY_TYPE:
			return {
				...state,
				isLoadingShow: payload
			};
		
		case types.IS_LOADING_FORM_PROPERTY_TYPE:
			return {
				...state,
				isLoadingForm: payload
			};
		
		case types.FORM_TYPE_PROPERTY_TYPE:
			return {
				...state,
				formType: payload
			};
		
		case types.FORM_DATA_PROPERTY_TYPE:
			return {
				...state,
				formData: payload
			};
		
		default:
			return state
	}
};

export default propertyTypeReducer;
