export const PER_PAGE_NEIGHBORHOOD = 'PER_PAGE_NEIGHBORHOOD';
export const PAGE_NEIGHBORHOOD = 'PAGE_NEIGHBORHOOD';
export const SEARCH_NEIGHBORHOOD = 'SEARCH_NEIGHBORHOOD';
export const PAGES_NEIGHBORHOOD = 'PAGES_NEIGHBORHOOD';
export const ARRAY_LIST_NEIGHBORHOOD = 'ARRAY_LIST_NEIGHBORHOOD';
export const ARRAY_LIST_TO_DROPDOWN_NEIGHBORHOOD = 'ARRAY_LIST_TO_DROPDOWN_NEIGHBORHOOD';
export const REFRESH_TABLE_NEIGHBORHOOD = 'REFRESH_TABLE_NEIGHBORHOOD';
export const IS_OPEN_MODAL_NEIGHBORHOOD = 'IS_OPEN_MODAL_NEIGHBORHOOD';
export const IS_LOADING_SHOW_NEIGHBORHOOD = 'IS_LOADING_SHOW_NEIGHBORHOOD';
export const IS_LOADING_FORM_NEIGHBORHOOD = 'IS_LOADING_FORM_NEIGHBORHOOD';
export const FORM_TYPE_NEIGHBORHOOD = 'FORM_TYPE_NEIGHBORHOOD';
export const FORM_DATA_NEIGHBORHOOD = 'FORM_DATA_NEIGHBORHOOD';
