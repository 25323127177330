import Icons from './Icons'
import Pagination from './Pagination'
import Rating from './Rating'
import Table from './Table'
import Tooltip from './Tooltip'
import Typography from './Typography'
import DateWithTimezone from './DateWithTimezone'

const Display = {
    Icons,
    Pagination,
    Rating,
    Table,
    Tooltip,
    Typography,
    DateWithTimezone
}

export default Display
