import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../../../components/mui/Layout'
import Inputs from '../../../../../components/mui/Inputs'
import { Permission } from '../../../../../services/permissions/Permission'
import { setFormFilter, setIsActiveSearch, setResetValidate, setIsOpenModal, setIsActiveHistorical, setIsSelectedToggleSwitch, setPageRegister, setPageDiscarded, setPageCommercial } from '../../../../../redux/actions/dashboard/filtered/filteredAction'
import { initialValuesFilter } from '../../../../../helpers/variablesInitialValues'
import DropdownSelect from "../../../settings/RealStatePage/components/DropdownSelect";

const SearchComponent = () => {
	const dispatch = useDispatch()
	const isActiveSearch = useSelector( state => state.filtered.isActiveSearch)
	const formFilter = useSelector( state => state.filtered.formFilter)
	
	/**
	 *
	 * @description Execute open modal, reset formData, formType, loading show and loading form state
	 * @return dispatch
	 *
	 */
	const handleIsOpenModal = () => {
		dispatch( setResetValidate() )
		dispatch( setIsSelectedToggleSwitch(0) )
		dispatch( setIsActiveHistorical(false) )
		dispatch( setIsOpenModal(true) )
	}
	
	/**
	 *
	 * @description Execute dispatch search state
	 * @return dispatch
	 *
	 */
	const handleOnChange = ({ target }) => {
		dispatch( setFormFilter( { ...formFilter, [target.name]: target.value }) )
		
		if (target.name === 'start' || target.name === 'finish') {
			dispatch( setPageRegister(1) )
			dispatch( setPageDiscarded(1) )
			dispatch( setPageCommercial(1) )
			dispatch( setIsActiveSearch(!isActiveSearch) )
		}
	}
	
	/**
	 *
	 * @description Execute dispatch refreshTable state
	 * @return dispatch
	 *
	 */
	const handleKeyPress = ({ charCode }) => {
		if (charCode === 13) {
			dispatch( setPageRegister(1) )
			dispatch( setPageDiscarded(1) )
			dispatch( setPageCommercial(1) )
			dispatch( setIsActiveSearch(!isActiveSearch) )
		}
	}
	
	/**
	 *
	 * @description Execute dispatch search, refreshTable state
	 * @return dispatch
	 *
	 */
	const handleClear = () => {
		dispatch( setPageRegister(1) )
		dispatch( setPageDiscarded(1) )
		dispatch( setPageCommercial(1) )
		dispatch( setFormFilter(initialValuesFilter) )
		dispatch( setIsActiveSearch(!isActiveSearch) )
	}
	
	return (
		<Layout.Card className="border-radius-0 m-0 p-0">
			<Layout.CardContent className="m-0 p-3">
				<div className="row mt-2 mb-0">
					<div className="col-sm-12">
						<Permission permission="filtereds.store">
							<Inputs.Button
								label="Nuevo contacto"
								startIcon={<Layout.Icons.AddCircleIcon width={20} height={20} />}
								onClick={handleIsOpenModal}
								className="font-family-roboto-regular fs-6 bg-primary text-capitalize mb-2"
							/>
						</Permission>
						<DropdownSelect
							name="realState"
							value={formFilter.realState}
							handleChange={handleOnChange}
							className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-300px"
						/>
						<Permission permission="filtereds.list">
							<>
								<Inputs.TextBox
									type="text"
									name="search"
									label="Buscar"
									placeholder="Ingrese la búsqueda..."
									value={formFilter.search}
									onChange={handleOnChange}
									onKeyPress={handleKeyPress}
									className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-300px"
								/>
								<Inputs.TextBox
									type="date"
									name="start"
									label="Desde"
									placeholder="Ingrese la fecha..."
									value={formFilter.start}
									onChange={handleOnChange}
									onKeyPress={handleKeyPress}
									className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-150px"
								/>
								<Inputs.TextBox
									type="date"
									name="finish"
									label="Hasta"
									placeholder="Ingrese la fecha..."
									value={formFilter.finish}
									onChange={handleOnChange}
									onKeyPress={handleKeyPress}
									className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-150px"
								/>
								<Permission permission="usersByAgent.dropdown">
									<></>
								</Permission>
								<Permission permission="usersByAdviser.dropdown">
									<></>
								</Permission>
							</>
						</Permission>
						<Layout.Tooltip
							title="Limpiar búsqueda"
							placement="top"
						>
							<Layout.IconButton
								onClick={handleClear}
								edge="start"
								className="bg-secondary font-roboto-semibold d-inline float-end mt-1 ml-2 mb-2"
							>
								<Layout.Icons.CloseIcon
									width={18}
									height={18}
									className="font-roboto-semibold text-white"
								/>
							</Layout.IconButton>
						</Layout.Tooltip>
					</div>
				</div>
			</Layout.CardContent>
		</Layout.Card>
	)
}

export default SearchComponent
