import { initialValuesOwner as initialValues } from '../../../../../helpers/variablesInitialValues';
import * as types from '../types/ownerType';

const initialState = {
	perPage: 10,
	perPagePropertyOwner: 10,
	page: 1,
	pagePropertyOwner: 1,
	pages: 1,
	pagesPropertyOwner: 1,
	hasNextPagePropertyOwner: true,
	search: '',
	personType: 'all',
	arrayList: [],
	arrayListPropertyOwner: [],
	arrayListToDropdown: [],
	refreshTable: false,
	isOpenModal: false,
	isLoadingShow: false,
	isLoadingForm: false,
	isLoadingPropertyOwner: false,
	formType: '',
	formData: initialValues
};

const ownerReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_OWNER:
			return {
				...state,
				perPage: payload
			}
		
		case types.PER_PAGE_PROPERTIES_OWNER:
			return {
				...state,
				perPagePropertyOwner: payload
			}
		
		case types.PAGE_OWNER:
			return {
				...state,
				page: payload
			}
		
		case types.PAGE_PROPERTIES_OWNER:
			return {
				...state,
				pagePropertyOwner: payload
			}
		
		case types.PAGES_OWNER:
			return {
				...state,
				pages: payload
			}
		
		case types.PAGES_PROPERTIES_OWNER:
			return {
				...state,
				pagesPropertyOwner: payload
			}
			
		case types.HAS_NEXT_PAGE_PROPERTIES_OWNER:
			return {
				...state,
				hasNextPagePropertyOwner: payload
			}
		
		case types.SEARCH_OWNER:
			return {
				...state,
				search: payload
			}
		
		case types.PERSON_TYPE_OWNER:
			return {
				...state,
				personType: payload
			}
		
		case types.ARRAY_LIST_OWNER:
			return {
				...state,
				arrayList: payload
			}
		
		case types.ARRAY_LIST_PROPERTIES_OWNER:
			return {
				...state,
				arrayListPropertyOwner: payload
			}
		
		case types.ARRAY_LIST_TO_DROPDOWN_OWNER:
			return {
				...state,
				arrayListToDropdown: payload
			}
		
		case types.REFRESH_TABLE_OWNER:
			return {
				...state,
				refreshTable: payload
			}
		
		case types.IS_OPEN_MODAL_OWNER:
			return {
				...state,
				isOpenModal: payload
			}
			
		case types.IS_LOADING_SHOW_OWNER:
			return {
				...state,
				isLoadingShow: payload
			};
		
		case types.IS_LOADING_FORM_OWNER:
			return {
				...state,
				isLoadingForm: payload
			}
		
		case types.IS_LOADING_PROPERTIES_OWNER:
			return {
				...state,
				isLoadingPropertyOwner: payload
			}
		
		case types.FORM_TYPE_OWNER:
			return {
				...state,
				formType: payload
			}
		
		case types.FORM_DATA_OWNER:
			return {
				...state,
				formData: payload
			}
		
		default:
			return state
	}
};

export default ownerReducer;
