import React from 'react'
import Layout from '../mui/Layout'
import Feedback from '../mui/Feedback'

const VerticalCardsLoading = () => {
	const arrayList = [
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{}
	]
	
	return (
		<div className="row mt-2 mb-2 animate__animated animate__fadeIn">
			{arrayList.length > 0 && (
				arrayList.map((row, index) => (
					<div
						key={index}
						className="col-sm-12 col-md-6 col-lg-6"
					>
						<Layout.Card className="border-radius-5 mb-2">
							<Layout.CardHeader
								action={<Feedback.Skeleton variant="circular" height={28} width={28} className="mr-3 mt-3 mb-3" />}
								title={<Feedback.Skeleton variant="text" height={20} width="50%" />}
								subheader={<Feedback.Skeleton variant="text" height={25} width="70%" />}
								className="m-0 pl-2 pr-2 p-2"
							/>
							<Layout.CardContent className="m-0 p-0">
								<div className="row m-0 p-0">
									<div className="col-12 m-0 p-0">
										<Feedback.Skeleton variant="rectangular" height="130px" width="100%" />
									</div>
								</div>
								<div className="row m-0 p-0">
									<div className="col-sm-12 m-0 pl-3 pr-3 pt-2">
										<Feedback.Skeleton variant="text" height={20} width="75%" />
									</div>
								</div>
								<div className="col-sm-12 m-0 pl-3 pr-3 pt-2 pb-2 d-flex mx-auto justify-content-center">
									<div className="col-sm-3">
										<Feedback.Skeleton variant="rounded" height={30} width={30} className="d-flex mx-auto text-center" />
									</div>
									<div className="col-sm-3">
										<Feedback.Skeleton variant="rounded" height={30} width={30} className="d-flex mx-auto text-center" />
									</div>
									<div className="col-sm-3">
										<Feedback.Skeleton variant="rounded" height={30} width={30} className="d-flex mx-auto text-center" />
									</div>
									<div className="col-sm-3">
										<Feedback.Skeleton variant="rounded" height={30} width={30} className="d-flex mx-auto text-center" />
									</div>
								</div>
							</Layout.CardContent>
						</Layout.Card>
					</div>
				))
			)}
		</div>
	)
}

export default VerticalCardsLoading
