import { initialValuesOwner as initialValues } from '../../../../../helpers/variablesInitialValues';
import * as types from '../types/ownerType';

/**
 *
 * @description Set reset owner state
 * @return dispatch
 *
 */
export const setOwnerReset = () => (
	async (dispatch) => {
		await dispatch( setPerPage(10) );
		await dispatch( setPerPageProperties(10) );
		await dispatch( setPage(1) );
		await dispatch( setPageProperties(1) );
		await dispatch( setPages(1) );
		await dispatch( setPagesProperties(1) );
		await dispatch( setSearch('') );
		await dispatch( setPersonType('all') );
		await dispatch( setArrayList([]) );
		await dispatch( setArrayListProperties([]) );
		await dispatch( setArrayListToDropdown([]) );
		await dispatch( setRefreshTable(false) );
		await dispatch( setIsOpenModal(false) );
		await dispatch( setIsLoadingShow(false) );
		await dispatch( setIsLoadingForm(false) );
		await dispatch( setIsLoadingProperties(false) );
		await dispatch( setFormType('') );
		await dispatch( setFormData(initialValues) );
	}
);

/**
 *
 * @description Set perPage state
 * @param value
 * @return object
 *
 */
export const setPerPage = value => ({
	type: types.PER_PAGE_OWNER,
	payload: value
});

/**
 *
 * @description Set perPageProperties state
 * @param value
 * @return object
 *
 */
export const setPerPageProperties = value => ({
	type: types.PER_PAGE_PROPERTIES_OWNER,
	payload: value
});

/**
 *
 * @description Set page state
 * @param value
 * @return object
 *
 */
export const setPage = value => ({
	type: types.PAGE_OWNER,
	payload: value
});

/**
 *
 * @description Set pageProperties state
 * @param value
 * @return object
 *
 */
export const setPageProperties = value => ({
	type: types.PAGE_PROPERTIES_OWNER,
	payload: value
});

/**
 *
 * @description Set pages state
 * @param value
 * @return object
 *
 */
export const setPages = value => ({
	type: types.PAGES_OWNER,
	payload: value
});

/**
 *
 * @description Set pagesProperties state
 * @param value
 * @return object
 *
 */
export const setPagesProperties = value => ({
	type: types.PAGES_PROPERTIES_OWNER,
	payload: value
});

/**
 *
 * @description Set hasNextPageProperties state
 * @param value
 * @return object
 *
 */
export const setHasNextPageProperties = value => ({
	type: types.HAS_NEXT_PAGE_PROPERTIES_OWNER,
	payload: value
})

/**
 *
 * @description Set search state
 * @param value
 * @return object
 *
 */
export const setSearch = value => ({
	type: types.SEARCH_OWNER,
	payload: value
});

/**
 *
 * @description Set personType state
 * @param value
 * @return object
 *
 */
export const setPersonType = value => ({
	type: types.PERSON_TYPE_OWNER,
	payload: value
});

/**
 *
 * @description Set arrayList state
 * @param value
 * @return object
 *
 */
export const setArrayList = value => ({
	type: types.ARRAY_LIST_OWNER,
	payload: value
});

/**
 *
 * @description Set arrayListProperties state
 * @param value
 * @return object
 *
 */
export const setArrayListProperties = value => ({
	type: types.ARRAY_LIST_PROPERTIES_OWNER,
	payload: value
});

/**
 *
 * @description Set arrayListDropdown state
 * @param value
 * @return object
 *
 */
export const setArrayListToDropdown = value => ({
	type: types.ARRAY_LIST_TO_DROPDOWN_OWNER,
	payload: value
});

/**
 *
 * @description Set refreshTable state
 * @param value
 * @return object
 *
 */
export const setRefreshTable = value => ({
	type: types.REFRESH_TABLE_OWNER,
	payload: value
});

/**
 *
 * @description Set isOpenModal state
 * @param value
 * @return object
 *
 */
export const setIsOpenModal = value => ({
	type: types.IS_OPEN_MODAL_OWNER,
	payload: value
});

/**
 *
 * @description Set isLoadingShow state
 * @param value
 * @return object
 *
 */
export const setIsLoadingShow = value => ({
	type: types.IS_LOADING_SHOW_OWNER,
	payload: value
});

/**
 *
 * @description Set isLoadingForm state
 * @param value
 * @return object
 *
 */
export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_OWNER,
	payload: value
});

/**
 *
 * @description Set isLoadingProperties state
 * @param value
 * @return object
 *
 */
export const setIsLoadingProperties = value => ({
	type: types.IS_LOADING_PROPERTIES_OWNER,
	payload: value
});

/**
 *
 * @description Set formType state
 * @param value
 * @return object
 *
 */
export const setFormType = value => ({
	type: types.FORM_TYPE_OWNER,
	payload: value
});

/**
 *
 * @description Set formData state
 * @param value
 * @return object
 *
 */
export const setFormData = value => ({
	type: types.FORM_DATA_OWNER,
	payload: value
});
