import React from 'react'
import Layout from '../../../../components/mui/Layout'
import SearchComponent from './components/SearchComponent'
import ModalComponent from './components/ModalComponent'
import KanbanComponent from './components/KanbanComponent'

const FilteredPage = () => {
	
	return (
		<Layout.Page title="Filtrado - Registro de Casos">
			<SearchComponent />
		
			<KanbanComponent />
		
			<ModalComponent />
		</Layout.Page>
	)
}

export default FilteredPage
