import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../../../../components/mui/Layout';
import TableRowComponent from './TableRowComponent';
import { getArrayList } from '../services';
import { setIsLoading } from '../../../../../redux/loading/actions/loadingAction';
import { setPerPage, setPage, setPages, setArrayList } from '../redux/actions/roleAction';
import swalErrors from '../../../../../hooks/useErrors';
import usePermission from '../../../../../hooks/usePermission';
import { roleColumns as columns, perPages } from '../../../../../helpers/variablesColumns';

const TableComponent = () => {
	const dispatch = useDispatch();
	const isLoading = useSelector( ({ loading }) => loading.isLoading);
	const refreshTable = useSelector( ({ role }) => role.refreshTable);
	const perPage = useSelector( ({ role }) => role.perPage);
	const page = useSelector( ({ role }) => role.page);
	const search = useSelector( ({ role }) => role.search);
	const pages = useSelector( ({ role }) => role.pages);
	const arrayList = useSelector( ({ role }) => role.arrayList);
	const permission = usePermission('roles.list');
	
	/**
	 *
	 * @description Execute endpoint array list of roles
	 * @return dispatch
	 *
	 */
	const handleArrayList = async () => {
		if (permission) {
			await dispatch( setIsLoading(page === 1 ? true : false) );

			try {
				const param = {
					perPage: perPage,
					page: page,
					search: search === '' ? null : search
				};

				const { data } = await getArrayList(param);

				if (data.status === 200) {
					await dispatch( setPages(data.data.pages) );
					await dispatch( setArrayList(data.data.records) );
				}
			} catch (error) {
				await swalErrors(error);
			}

			await dispatch( setIsLoading(false) );
		}
	};
	
	useEffect(() => {
			handleArrayList();
	}, [
		perPage,
		page,
		refreshTable
	]);
	
	/**
	 *
	 * @description Execute dispatch perPage state
	 * @return dispatch
	 *
	 */
	const handleChangePerPage = ({ target }) => dispatch( setPerPage(target.value) );
	
	/**
	 *
	 * @description Execute dispatch page state
	 * @return dispatch
	 *
	 */
	const handleChangePage = (event, value) => dispatch( setPage(value) );
	
	return (
		<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
			<Layout.CardContent className="m-0 p-4">
				{permission && (
					<>
						<Layout.TablePagination
							arrayList={arrayList.length}
							perPage={perPage}
							perPages={perPages}
							page={page}
							pages={pages}
							handleChangePerPage={handleChangePerPage}
							handleChangePage={handleChangePage}
						/>
						<Layout.Table>
							<Layout.TableHead columns={columns} />
							<tbody>
								{isLoading && (
									<Layout.TableRowLoading columns={columns} />
								)}
								{!isLoading && (
									<>
										{arrayList.length === 0 && (
											<Layout.TableRowNoFound colSpan={columns.length} />
										)}
										{arrayList.length > 0 && (
											arrayList.map(({ id, name, permissions }, index) => (
												<TableRowComponent
													key={index}
													id={id}
													name={name}
													permissions={permissions}
												/>
											))
										)}
									</>
								)}
							</tbody>
						</Layout.Table>
						<Layout.TablePagination
							arrayList={arrayList.length}
							perPage={perPage}
							perPages={perPages}
							page={page}
							pages={pages}
							handleChangePerPage={handleChangePerPage}
							handleChangePage={handleChangePage}
						/>
					</>
				)}
			</Layout.CardContent>
		</Layout.Card>
	);
};

export default TableComponent;
