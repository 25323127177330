import React from 'react'
import PropTypes from 'prop-types'
import Feedback from '../../../../../../components/mui/Feedback'
import { TextFieldReadOnly } from '../fields'
//import { groupFeatures } from '../../utils/utilFunctionCustom'
import styles from './formFicha.module.css'

export function FormFicha({ dataArrayFeatures = [], urlDetalleInmueble = false, formData, isInventoryInternal = false }) {
  // List of features
  const featureList = formData?.areas?.map((area, index) => (
    <ul key={index}>
      {area?.amenities?.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  ))

  return (
    <Feedback.ScrollBar style={{ height: '400px', overflowY: 'none' }}>
      <div className={styles.cardForm}>
        {urlDetalleInmueble && (
          <div className={styles.dFlexBetween}>
            {formData?.action !== 'Arriendo' && (
              <TextFieldReadOnly
                label={'Venta'}
                value={formData.sale === null ? '' : `$${formData.sale} COP`}
              />
            )}
            {formData?.action !== 'Venta' && (
              <TextFieldReadOnly
                label={'Arriendo'}
                value={formData.canon === null ? '' : `$${formData.canon} COP`}
              />
            )}
            <TextFieldReadOnly
              label={'Administración'}
              value={formData.value_admin === null ? '' : `$${formData.value_admin} COP`}
            />
          </div>
        )}

        {/* INTERNAL INVENTORY CARD FIELDS */}
        {isInventoryInternal && (
          <>
            <div className={styles.dFlexBetween}>
              <TextFieldReadOnly
                label={'Quién realizó la actividad'}
                value={''}
              />
            </div>
            <div className={styles.dFlexBetween}>
              <TextFieldReadOnly
                label={'Cuándo se realizó la actividad'}
                value={''}
              />
            </div>
            <div className={styles.dFlexBetween}>
              <TextFieldReadOnly
                label={'Descripción'}
                value={''}
                multiline
                rows={2}
              />
            </div>
          </>
        )}

        <div className={styles.dFlexBetween}>
          <TextFieldReadOnly
            label={'Tipo de propiedad'}
            value={formData.type === null ? '' : formData.type}
          />
          <TextFieldReadOnly
            label={'Destinación'}
            value={formData.destination === null ? '' : formData.destination}
          />
        </div>
        <div className={styles.dFlexBetween}>
          <TextFieldReadOnly
            label={'Acción'}
            value={formData.action === null ? '' : formData.action}
          />
          {urlDetalleInmueble !== true && (
            <TextFieldReadOnly
              label={'Álbum'}
              value={formData.album_name === null ? '' : formData.album_name}
            />
          )}
          {/* {urlDetalleInmueble && (
						<TextFieldReadOnly label={'Estrato'} value={'6'} />
					)} */}
        </div>
        <div className={styles.dFlexBetween}>
          <TextFieldReadOnly
            label={'Propiedad'}
            value={formData.mobili_code === null ? '' : formData.mobili_code}
          />
          <TextFieldReadOnly
            label={'Código Domus'}
            value={`${formData.domus_code === null ? '' : formData.domus_code}`}
          />
        </div>
        {urlDetalleInmueble && (
          <>
            <section className={styles.box}>
              <h5 className={styles.boxTitle}>Descripción:</h5>
              <p>{formData.reference === null ? '' : formData.reference}</p>
            </section>
            {featureList?.length > 0 && (
              <section className={styles.box}>
                <h5 className={styles.boxTitle}>Características:</h5>
                <div className={styles.boxFeatures}>{featureList}</div>
              </section>
            )}
          </>
        )}
      </div>
    </Feedback.ScrollBar>
  )
}

FormFicha.prototype = {
  dataArrayFeatures: PropTypes.array,
  urlDetalleInmueble: PropTypes.bool,
}
