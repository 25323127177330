import * as types from '../../actionsTypes/dashboard/activityLogTypes'

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	startDate: '',
	finishDate: '',
	activityName: '',
	logName: '',
	subjectId: '',
	arrayList: [],
	arrayListDropdown: [],
	isOpenModal: false,
	isLoading: false
}

const activityLogReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_ACTIVITY_LOG:
			return {
				...state,
				perPage: payload
			}
		
		case types.PAGE_ACTIVITY_LOG:
			return {
				...state,
				page: payload
			}
		
		case types.PAGES_ACTIVITY_LOG:
			return {
				...state,
				pages: payload
			}
			
		case types.START_DATE_ACTIVITY_LOG:
			return {
				...state,
				startDate: payload
			}
			
		case types.FINISH_DATE_ACTIVITY_LOG:
			return {
				...state,
				finishDate: payload
			}
			
		case types.ACTIVITY_NAME_ACTIVITY_LOG:
			return {
				...state,
				activityName: payload
			}
			
		case types.LOG_NAME_ACTIVITY_LOG:
			return {
				...state,
				logName: payload
			}
			
		case types.SUBJECT_ID_ACTIVITY_LOG:
			return {
				...state,
				subjectId: payload
			}
		
		case types.ARRAY_LIST_ACTIVITY_LOG:
			return {
				...state,
				arrayList: payload
			}
			
		case types.ARRAY_LIST_DROPDOWN_ACTIVITY_LOG:
			return {
				...state,
				arrayListDropdown: payload
			}
		
		case types.IS_OPEN_MODAL_ACTIVITY_LOG:
			return {
				...state,
				isOpenModal: payload
			}
		
		case types.IS_LOADING_ACTIVITY_LOG:
			return {
				...state,
				isLoading: payload
			}
		
		default:
			return state
	}
}

export default activityLogReducer
