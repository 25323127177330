import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import { ArrowUpwardRounded, ArrowDownwardRounded } from '@material-ui/icons'
import { useAssets } from './AssetsContext'

import tour360Placeholder from '../../../../../../assets/images/placeholders/360Placeholder.png'
import videoPlaceholder from '../../../../../../assets/images/placeholders/videoPlaceholder.png'
import './Styles.css'

export default function AssetCard({ onSelect, selected, localMain=false, asset_type,published=false, position, openFullScreen, mutate, ...resource }) {
  const { changeOrderList, orderList } = useAssets()
  const { name } = resource.asset
  const imagePath = resource.asset.full_thumbnail_path ?? resource.asset.full_path
  const url = { 
    1:  imagePath,
    2: imagePath,
    3: videoPlaceholder,
    4: tour360Placeholder,
  }[asset_type]
  const tags = []
  // const [checkedValue, setChecked] = React.useState(false);
  return (
    <Box display="grid" gridTemplateColumns={ published ? 'minmax(min-content,1fr) 100px' : 'calc(100% - 108px)'} gap={1}>
    <Stack
      my={1}
      p={20 / 8}
      // maxWidth="600px"
      borderRadius="8px"
      flexDirection="row"
      border={`1px solid ${selected ? '#1CD2D2' : '#C5C5C5'}`}
      display="grid"
      gap={2}
      bgcolor={selected ? '#87FFFF1F' : 'inherit'}
      gridTemplateColumns="max-content 1fr max-content"
      position="relative"
    >
      <Box className='backdrop' position="relative" sx={{ width: '100px', height: '100px',
        transition:'all ease-in-out .2s',
         "&:hover .fullscreen":{
            display:'block'
          },
          "&:hover.backdrop::after":{
            display:'block'
          }
      }}>
        <img
          src={url}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
        <svg className="fullscreen"  onClick={()=>{
          openFullScreen(url)
        }} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.0238 8.92863V3.00244L15.4762 3.01911" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22.0238 3.00244L14.881 10.0346" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.4762 22.0488L22.0238 22.0512L22.0083 15.5035" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22.0238 21.9392L14.881 14.9071" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M2.9762 8.92858V2.9762H9.52382" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.1191 10.0346L2.9762 3.00244" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9.52382 22.0428L2.9762 22.0512V16.0714" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.1191 14.881L2.9762 22.0238" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </Box>
      <Stack>
        <div>
          <Typography className='line-clamp-1'>{ asset_type === 4 ? resource.asset.path : name}</Typography>
          <i>{resource.asset_origin.value}</i>
          {/* <Typography>Order:<b>{resource.order}</b></Typography> */}
          {/* <Typography>Main:<b>{resource.main}</b></Typography> */}
          {/* <Typography><pre>{JSON.stringify(resource,null,2)}</pre></Typography> */}
        </div>

        <Stack flexDirection="row" gap={1}>
          {tags.map((tag, i) => (
            <Chip
              key={i + tag}
              label={tag}
              onDelete={() => {}}
              size="small"
              variant="outlined"
              sx={{
                '&.MuiChip-root': {
                  outline: '1px solid',
                  borderRadius: '2px',
                },
              }}
            />
          ))}
        </Stack>
      </Stack>
      <div style={{ width: '50px', display: 'grid', placeContent: 'center' }}>
        <Checkbox
          size="medium"
          checked={selected}
          onChange={(_, checked) => {
            onSelect(checked)
          }}
        />
      </div>
      {localMain && (
        <Box position="absolute" bottom="10px" right="20px" sx={{
          bgcolor:"rgba(123, 198, 227, 0.6)",
          p:'2px 4px',
          borderRadius:'4px',
          userSelect:'none'
        }}> <p style={{
          fontSize:'14px',
          color:"#31448e",
          margin:0,
          fontWeight:500
        }}>Principal</p> 
        </Box>
      )}
    </Stack>
    {published && (
      <Stack alignItems="flex-start" justifyContent="center">
      {!position.first && (
        <span>
        <IconButton disabled={position.first}  onClick={()=>{
            const { order,asset_id } = resource;

            let array = orderList;
            for(let i=0; i< array.length; i++){
              if(array[i].asset_id === asset_id && array[i].order === order){
                // console.log({
                //     asset_id: array[i].asset_id,
                //     order: array[i].order
                // })
                const temp = array[i] 
                array[i] = array[i-1]
                array[i-1] =  temp;
                break;
              }
            }
            changeOrderList({orderList:array})
            mutate(array)
          }}>
          <ArrowUpwardRounded/>
        </IconButton>
        </span>
      )}
        {!position.last && (

        <span>
        <IconButton disabled={position.last} onClick={()=>{
            const { order,asset_id } = resource;

            let array = orderList;
            for(let i=0; i< array.length; i++){
              if(array[i].asset_id === asset_id && array[i].order === order){
                // console.log({
                //     asset_id: array[i].asset_id,
                //     order: array[i].order
                // })
                const temp = array[i] 
                array[i] = array[i+1]
                array[i+1] =  temp;
                break;
              }
            }
            changeOrderList({orderList:array})
            mutate(array)
          }} >
          <ArrowDownwardRounded />
        </IconButton>
        </span>
        )}
      </Stack>
      )}
    </Box>   
  )
}
