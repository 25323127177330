import React from 'react'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'

export default function DeleteOutlinedIcon(props) {

    const { ...other } = props

    return (
        <DeleteOutlined { ...other } />
    )
}