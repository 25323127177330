import React from 'react'
import PropTypes from 'prop-types'
import NavigateNext from '@mui/icons-material/NavigateNext'

const NavigateNextIcon = ({ height, width, ...more }) => {

  return (
    <NavigateNext
      height={height}
      width={width}
      {...more}
    />
  )
}

NavigateNextIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
}

export default NavigateNextIcon
