import endPoints from './endPoints';
import axios from '../../../../../services/axios';

const {
	arrayList,
	arrayListToDropdown,
	arrayListToDropdownAgent,
	arrayListToDropdownAdviser,
	store,
	resendEmail,
	show,
	status,
	update,
	assignPermissions,
	destroy,
	showProfile,
	updateProfile,
	updateProfilePassword
} = endPoints;

/**
 *
 * @description Execute endpoint array list of users
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const getArrayList = async (data) => (
	await axios.post(arrayList, data)
);

/**
 *
 * @description Execute endpoint array list of users in dropdown
 * @method get
 * @return jsonResponse
 *
 */
export const getArrayListToDropdown = async () => (
	await axios.get(`${arrayListToDropdown}`)
);

/**
 *
 * @description Execute endpoint array list of agents in dropdown
 * @method get
 * @return jsonResponse
 *
 */
export const getArrayListToDropdownAgent = async (data) => (
	await axios.post(arrayListToDropdownAgent, data)
);

/**
 *
 * @description Execute endpoint array list of adviser in dropdown
 * @method get
 * @return jsonResponse
 *
 */
export const getArrayListToDropdownAdviser = async (data) => (
	await axios.post(arrayListToDropdownAdviser, data)
);

/**
 *
 * @description Execute endpoint store
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const setStore = (data) => async (dispatch) => {
	try {
		return await axios.post(store, data)
	} catch (error) {
		throw error;
	}
};
/**
 *
 * @description Execute endpoint resend email verification
 * @method get
 * @return jsonResponse
 *
 */
export const getResendEmailById = async (id) => (
	await axios.get(`${resendEmail}${id}`)
);

/**
 *
 * @description Execute endpoint show by id
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const getShowById = async (id) => (
	await axios.get(`${show}${id}`)
);

/**
 *
 * @description Execute endpoint change status by id
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const setUpdateStatusById = async (id) => (
	await axios.get(`${status}${id}`)
);

/**
 *
 * @description Execute endpoint update by id
 * @param id
 * @param data
 * @method put
 * @return jsonResponse
 *
 */
export const setUpdateById = (id, data) => async (dispatch) => {
	try {
		return await axios.put(`${update}${id}`, data)
	} catch (error) {
		throw error;
	}
};

/**
 *
 * @description Execute endpoint assign of permissions by id
 * @param id
 * @param data
 * @method put
 * @return jsonResponse
 *
 */
export const setAssignPermissionsById = async (id, data) => (
	await axios.put(`${assignPermissions}${id}`, data)
);

/**
 *
 * @description Execute endpoint delete by id
 * @param id
 * @method delete
 * @return jsonResponse
 *
 */
export const setDeleteById = async (id) => (
	await axios.delete(`${destroy}${id}`)
);

/**
 *
 * @description Execute endpoint show profile
 * @method get
 * @return jsonResponse
 *
 */
export const getShowProfile = async () => (
	await axios.post(`${showProfile}`)
);

/**
 *
 * @description Execute endpoint update profile
 * @param data
 * @method post
 * @return jsonResponse
 *
 */

export const setUpdateProfileById = async (data) => (
	await axios.post(updateProfile, data)
);

/**
 *
 * @description Execute endpoint update profile password
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const setUpdateProfilePasswordById = async (data) => (
	await axios.post(updateProfilePassword, data)
);
