/*eslint no-unused-vars: "off"*/
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { SxProps, Theme } from "@mui/material";

const HeadTitles = [
  "No. de casos",
  "Estado",
  "Fecha de apertura",
  "Servicio",
  "Presupuesto",
];

/**
 * @type { SxProps<Theme>}
 */
const CommonStyles = {
  gap: "10px",
  display: "grid",
  borderRadius: "2px",
  color: "dark.default",
  gridTemplateColumns:
    "minmax(max-content,80px) minmax(min-content,70px) minmax(max-content,120px) minmax(max-content,80px) 1fr",
  p: ({ spacing }) => spacing(5 / 8, 20 / 8),
};
const Head = () => {
  return (
    <Box sx={{ ...CommonStyles, bgcolor: "mediumGrey.default" }}>
      {HeadTitles.map((title, index) => (
        <Typography fontWeight="700" fontSize="0.75rem" key={title + index}>
          {title}
        </Typography>
      ))}
    </Box>
  );
};

const CasesTable = ({ list, sxProps }) => {
  if (list?.length < 1) return <></>;
  return (
    <Box
      sx={{ ...sxProps, position: "relative" }}
      className="s345-KanbanWrapperStyle"
    >
      <Box position="sticky" top={0}>
        <Head />
      </Box>
      {list.map((rowCase, index) => (
        <Box
          sx={{
            ...CommonStyles,
            bgcolor: (index % 2 != 0 && "lightGrey.default") ?? "inherit",
          }}
          key={index}
        >
          {rowCase.map((item, index) => (
            <Typography key={item[0] + index} fontSize="0.75rem">
              {item}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );
};

const HeadTitleForActivityRecords = ["Fecha", "Actividad", "Funcionario"];

const RowStylesForActivityRecords = {
  ...CommonStyles,
  gridTemplateColumns: "minmax(max-content,80px) 1fr minmax(max-content,120px)",
};
const HeadForActivityRecords = () => {
  return (
    <Box sx={{ ...RowStylesForActivityRecords, bgcolor: "mediumGrey.default" }}>
      {HeadTitleForActivityRecords.map((title, index) => (
        <Typography fontWeight="700" fontSize="0.75rem" key={title + index}>
          {title}
        </Typography>
      ))}
    </Box>
  );
};

const TableForActivityRecords = ({ list, sxProps }) => {
  if (list?.length < 1) return <></>;
  return (
    <Box
      sx={{ ...sxProps, position: "relative" }}
      className="s345-KanbanWrapperStyle"
    >
      <Box position="sticky" top={0}>
        <HeadForActivityRecords />
      </Box>
      {list.map((rowRecords, index) => (
        <Box
          sx={{
            ...RowStylesForActivityRecords,
            bgcolor: (index % 2 != 0 && "lightGrey.default") ?? "inherit",
            p: ({ spacing }) => spacing(10 / 8, 20 / 8),
          }}
          key={index}
        >
          {rowRecords.map((item, index) => (
            <Typography key={item[0] + index} fontSize="0.75rem">
              {item}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export { CasesTable, TableForActivityRecords };
