import { initialValuesContact as initialValues } from '../../../../../../../helpers/variablesInitialValues';
import * as types from '../types/contactType';

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	search: '',
	arrayList: [],
	arrayListToDropdown: [],
	refreshTable: false,
	isOpenModal: false,
	isOpenModalPermission: false,
	isLoadingShow: false,
	isLoadingForm: false,
	formType: '',
	formData: initialValues
};

const contactReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_CONTACT:
			return {
				...state,
				perPage: payload
			};
		
		case types.PAGE_CONTACT:
			return {
				...state,
				page: payload
			};
		
		case types.PAGES_CONTACT:
			return {
				...state,
				pages: payload
			};
		
		case types.SEARCH_CONTACT:
			return {
				...state,
				search: payload
			};
		
		case types.ARRAY_LIST_CONTACT:
			return {
				...state,
				arrayList: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_CONTACT:
			return {
				...state,
				arrayListToDropdown: payload
			};
		
		case types.REFRESH_TABLE_CONTACT:
			return {
				...state,
				refreshTable: payload
			};
		
		case types.IS_OPEN_MODAL_CONTACT:
			return {
				...state,
				isOpenModal: payload
			};
		
		case types.IS_LOADING_SHOW_CONTACT:
			return {
				...state,
				isLoadingShow: payload
			};
		
		case types.IS_LOADING_FORM_CONTACT:
			return {
				...state,
				isLoadingForm: payload
			};
		
		case types.FORM_TYPE_CONTACT:
			return {
				...state,
				formType: payload
			};
		
		case types.FORM_DATA_CONTACT:
			return {
				...state,
				formData: payload
			};
		
		default:
			return state
	}
};

export default contactReducer;
