import React from 'react'
import ChevronRight from '@material-ui/icons/ChevronRight'

export default function ChevronRightIcon(props) {

    const { ...other } = props

    return (
        <ChevronRight
            {...other}
        />
    )
}