import * as types from '../../../../actionsTypes/dashboard/propertyTypes'
import {STATUS_COMMERCIAL_PROPERTY} from "../../../../actionsTypes/dashboard/propertyTypes";

export const setPropertyReset = () => (
	async (dispatch) => {
		await dispatch( setPerPage(10) )
		await dispatch( setPage(1) )
		await dispatch( setPages(1) )
		await dispatch( setSearch('') )
		await dispatch( setArrayList([]) )
		await dispatch( setRefreshTable(false) )
	}
)

export const setPerPage = value => ({
	type: types.PER_PAGE_PROPERTY,
	payload: value
})

export const setPage = value => ({
	type: types.PAGE_PROPERTY,
	payload: value
})

export const setPages = value => ({
	type: types.PAGES_PROPERTY,
	payload: value
})

export const setSearch = value => ({
	type: types.SEARCH_PROPERTY,
	payload: value
})

export const setRealState = value => ({
	type: types.REALSTATE_PROPERTY,
	payload: value
})

export const setStatusCommercial = value => ({
	type: types.STATUS_COMMERCIAL_PROPERTY,
	payload: value
})

export const setArrayList = value => ({
	type: types.ARRAY_LIST_PROPERTY,
	payload: value
})

export const setRefreshTable = value => ({
	type: types.REFRESH_TABLE_PROPERTY,
	payload: value
})
