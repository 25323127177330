import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../../components/mui/Layout';
import Navigation from '../../../components/mui/Navigation';

const FooterComponent = ({ to, label }) => {
	
	return (
		<Layout.CardActions className="m-0 p-4">
			<div className="row">
				<div className="col-sm-12 mb-2">
					<Layout.Divider
						variant="middle"
						color="light"
						textAlign="center"
						text="O"
					/>
				</div>
				<div className="col-sm-12 mb-2 text-center">
					<Navigation.NavLink
						to={to}
						className="font-family-roboto-regular fs-6 text-primary text-underline"
					>
						{label}
					</Navigation.NavLink>
				</div>
				<div className="col-sm-12 text-center">
					<div className="font-family-roboto-medium fs-6 text-grey">
						<small>Powered by Mobili</small>
					</div>
				</div>
			</div>
		</Layout.CardActions>
	);
};

FooterComponent.propTypes = {
	to: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired
};

export default FooterComponent;

