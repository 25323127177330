import { initialValuesPropertyDomus as initialValues } from '../../../../../../helpers/variablesInitialValues'
import * as types from '../../../../../actionsTypes/dashboard/settings/properties/propertyDomusTypes'
import { setIsLoading } from '../../../../loadingAction'
import { setIsActiveButton, setPropertyMobiliReset } from '../mobili/propertyMobiliAction'
import { getShowByCode } from './services'
import errorServices from '../../../../../../services/errors'

export const getPropertyDomusShowByCode = code => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		await dispatch( setIsLoading(true) )
		
		await getShowByCode(code).then(({ data }) => {
			let amenitiesName = ''
			
			if (data.data.amenities.length > 0) {
				let array = []
				
				data.data.amenities.map(({ name }) => {
					array.push(name)
				})
				
				amenitiesName = array.length === 0 ? '' : array.toString().split(', ')
			}
			
			const object = {
				codeDomus: data.data.domus_code,
				statusCommercialId: data.data.status_commercial_id === null ? null : data.data.status_commercial_id,
				statusCommercialName: data.data.status_commercial === null ? null : data.data.status_commercial,
				registerDate: data.data.register_date === null ? null : data.data.register_date,
				registerAt: data.data.register_date === null ? null : data.data.register_at,
				updateDate: data.data.update_date === null ? null : data.data.update_date,
				updateAt: data.data.update_date === null ? null : data.data.update_at,
				publicationDate: data.data.publication_date === null ? null : data.data.publication_date,
				publicationAt: data.data.publication_date === null ? null : data.data.publication_at,
				consignationDate: data.data.consignation_date === null ? null : data.data.consignation_date,
				consignationAt: data.data.consignation_date === null ? null : data.data.consignation_at,
				actionId: data.data.action_id === null ? null : data.data.action_id,
				actionName: data.data.action === null ? null : data.data.action,
				valueAdmin: data.data.value_admin === null ? null : data.data.value_admin,
				valueAdminString: data.data.value_admin_string === null ? null : data.data.value_admin_string,
				canon: data.data.canon === null ? null : data.data.canon,
				canonString: data.data.canon_string === null ? null : data.data.canon_string,
				saleValue: data.data.sale_value === null ? null : data.data.sale_value,
				saleValueString: data.data.sale_value_string === null ? null : data.data.sale_value_string,
				commissionPercentage: data.data.commission_percentage === null ? null : data.data.commission_percentage,
				stateId: data.data.state_id === null ? null : data.data.state_id,
				stateName: data.data.state === null ? null : data.data.state,
				cityId: data.data.city_id === null ? null : data.data.city_id,
				cityName: data.data.city === null ? null : data.data.city,
				neighborhoodId: data.data.neighborhood_id === null ? null : data.data.neighborhood_id,
				neighborhoodName: data.data.neighborhood === null ? null : data.data.neighborhood,
				zoneId: data.data.zone_id === null ? null : data.data.zone_id,
				zoneName: data.data.zone === null ? null : data.data.zone,
				address: data.data.address === null ? null : data.data.address,
				reference: data.data.reference === null ? null : data.data.reference,
				comment: data.data.comment === null ? null : data.data.comment,
				latitude: data.data.latitude === null ? null : data.data.latitude,
				longitude: data.data.longitude === null ? null : data.data.longitude,
				numberRoom: data.data.number_room === null ? null : data.data.number_room,
				numberBathroom: data.data.number_bathroom === null ? null : data.data.number_bathroom,
				numberGarage: data.data.number_garage === null ? null : data.data.number_garage,
				strata: data.data.strata === null ? null : data.data.strata,
				enrollment: data.data.enrollment === null ? null : data.data.enrollment,
				yearConstruction: data.data.year_construction === null ? null : data.data.year_construction,
				areaConstruction: data.data.area_construction === null ? null : data.data.area_construction,
				areaTotal: data.data.area_total === null ? null : data.data.area_total,
				areaFront: data.data.front === null ? null : data.data.front,
				areaBackground: data.data.background === null ? null : data.data.background,
				typeId: data.data.type_id === null ? null : data.data.type_id,
				typeName: data.data.type === null ? null : data.data.type,
				destinationId: data.data.destination_id === null ? null : data.data.destination_id,
				destinationName: data.data.destination === null ? null : data.data.destination,
				amenitiesName: amenitiesName
			}
			
			dispatch( setFormData(object) )
			dispatch( setArrayListAmenities(data.data.amenities.length === 0 ? [] : data.data.amenities) )
			dispatch( setArrayListImages(data.data.images.length === 0 ? [] : data.data.images) )
			dispatch( setArrayListImages360(data.data.images360.length === 0 ? [] : data.data.images360) )
			dispatch( setArrayListVideos(data.data.videos.length === 0 ? [] : data.data.videos) )
			dispatch( setArrayListTours(data.data.tours.length === 0 ? [] : data.data.tours) )
			dispatch( setIsActiveButton(true) )
		}).catch(error => {
			dispatch( setPropertyDomusReset() )
			errorServices(error)
		})
		
		await dispatch( setIsLoading(false) )
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setPropertyDomusReset = () => (
	async (dispatch) => {
		await dispatch( setPropertyMobiliReset() )
		await dispatch( setSearch('') )
		await dispatch( setArrayListAmenities([]) )
		await dispatch( setArrayListImages([]) )
		await dispatch( setArrayListImages360([]) )
		await dispatch( setArrayListVideos([]) )
		await dispatch( setArrayListTours([]) )
		await dispatch( setIsLoadingForm(false) )
		await dispatch( setFormData(initialValues) )
	}
)

export const setSearch = value => ({
	type: types.SEARCH_PROPERTY_DOMUS,
	payload: value
})

export const setArrayListAmenities = value => ({
	type: types.ARRAY_LIST_AMENITIES_PROPERTY_DOMUS,
	payload: value
})

export const setArrayListImages = value => ({
	type: types.ARRAY_LIST_IMAGES_PROPERTY_DOMUS,
	payload: value
})

export const setArrayListImages360 = value => ({
	type: types.ARRAY_LIST_IMAGES360_PROPERTY_DOMUS,
	payload: value
})

export const setArrayListVideos = value => ({
	type: types.ARRAY_LIST_VIDEOS_PROPERTY_DOMUS,
	payload: value
})

export const setArrayListTours = value => ({
	type: types.ARRAY_LIST_TOURS_PROPERTY_DOMUS,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_PROPERTY_DOMUS,
	payload: value
})

export const setFormData = value => ({
	type: types.FORM_DATA_PROPERTY_DOMUS,
	payload: value
})
