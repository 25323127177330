import endPoints from './endPoints'
// import axios from '../../../../../services/axios'
import axios from 'axios'

const {
	arrayListDropdown
} = endPoints

/**
 *
 * @description Execute endpoint array list in dropdown of neighborhoods algolia
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const getArrayToDropdown = async (cityId) => (
	await axios.get(`${arrayListDropdown}${cityId}`)
)
