import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Inputs from '../../../../../components/mui/Inputs';
import { getArrayListToDropdown } from '../services';
import { setIsLoadingForm, setArrayListToDropdown } from '../redux/actions/bankAction';
import usePermission from '../../../../../hooks/usePermission';
import swalErrors from '../../../../../hooks/useErrors';

const DropdownSelect = ({ name, value = '', handleChange, ...more }) => {
	const dispatch = useDispatch();
	const isLoading = useSelector( ({ bank }) => bank.isLoadingForm);
	const arrayListToDropdown = useSelector( ({ bank }) => bank.arrayListToDropdown);
	const permission = usePermission('banks.dropdown');
	
	/**
	 *
	 * @description Execute endpoint array list banks in dropdown
	 * @return dispatch
	 *
	 */
	const handleArrayList = async () => {
		if (permission) {
			dispatch( setIsLoadingForm(true) );
			
			try {
				const { data } = await getArrayListToDropdown();
				
				if (data.status === 200) {
					await dispatch( setArrayListToDropdown(data.data.records) );
				}
			} catch (error) {
				// await swalErrors(error);
			}
			
			dispatch( setIsLoadingForm(false) );
		}
	};
	
	useEffect(() => {
		handleArrayList();
	}, []);
	
	return (
		<>
			{permission && !isLoading && (
				<Inputs.Select
					label="Banco"
					name={name}
					value={value}
					itemsArray={arrayListToDropdown}
					onChange={handleChange}
					className="animated__animated animated__fadeIn"
					{...more}
				/>
			)}
		</>
	);
};

DropdownSelect.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.any,
	handleChange: PropTypes.func.isRequired
};

export default DropdownSelect;
