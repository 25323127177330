import { Typography } from '@mui/material'
import React from 'react'
import styles from './footer.module.css'
import images from '../img'

export const Footer = () => {
  const textStyle = {
    color: 'dark.default',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '125%',
  }

  const textStyleBlue = {
    color: 'primary',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
    marginBottom: '10px',
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.dFlexBetween}>
        <div className={styles.dFlexLogo}>
          <div className={styles.logo}>
            <img loading='lazy' src={images.logo} alt='log' />
          </div>

          <Typography {...textStyle}>
            Desde 1983, en Financar Inmobiliaria ofrecemos servicio de avalúo,
            arriendo, venta, gestión de proyectos y administración de inmuebles.
          </Typography>
        </div>

        <div className={styles.dFlexLocation}>
          <Typography {...textStyleBlue}>
            Oficinas Barranquilla, Colombia
          </Typography>
          <Typography {...textStyle}>
            SEDE BUENAVISTA BC EMPRESARIAL Cra 53 Cl 110 esquina, Local 7, BC
            Empresarial
          </Typography>
        </div>
        <div className={styles.dFlexContact}>
          <Typography {...textStyleBlue}>Contacto</Typography>
          <Typography {...textStyle}>
            PBX: (605) 3303333 <br /> <br /> contacto1@financar.com.co
          </Typography>
        </div>
        <div className={styles.dFlexAbout}>
          <Typography {...textStyleBlue}>Acerca de</Typography>
          <a href='#!'>Política de tratamiento de datos</a>
          <a href='#!'>Quejas y reclamos</a>
        </div>
      </div>

      <div className={styles.dFlexStart}>
        <img src={images.iconFacebook} alt='facebook' />
        <img src={images.iconInstagram} alt='facebook' />
        <img src={images.iconTwitter} alt='facebook' />
        <img src={images.iconLinkedin} alt='facebook' />
      </div>
      <p className={styles.copyright}>
        Financar © 2022. Reservados todos los derechos.
      </p>
    </footer>
  )
}
