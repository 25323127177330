import React  from 'react'

export default function Pagination({ pageCount, pageIndex, handleChangePage }) {
    const pagination= []

    if (pageCount < 2) return null

    let prev_num = true

    for (let i = 0; i < pageCount; i++) {
        if (i < 1 || (i + 1) === pageCount || Math.abs(pageIndex - i) < 3) {
            prev_num = true
            pagination.push(i+1)
        } else if (prev_num) {
            prev_num = false
            pagination.push(null)
        }
    }

    return (
        <nav aria-label="Page navigation">
            <ul className="pagination m-auto justify-content-center align-items-center">
                {pagination.map((item, index) => {
                    if (item === null) {
                        return (
                            <li
                                key={index}
                                className="page-item"
                            >
                                <div className="page-link text-dark">...</div>
                            </li>
                        )
                    }

                    if (item === pageIndex) {
                        return (
                            <li
                                key={index}
                                className="page-item active"
                            >
                                <div className="page-link active bg-dark text-white">{item}</div>
                            </li>
                        )
                    }

                    return (
                        <li
                            key={index}
                            className="page-item text-dark"
                            onClick={() => handleChangePage(item)}
                        >
                            <div className="page-link text-dark">{item}</div>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}
