import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Inputs from '../../../../../components/mui/Inputs';
import DropdownActionComponent from './DropdownActionComponent';
import { setUpdateStatusById } from '../services';
import { setIsOpenModal, setRefreshTable, setFormData, setFormType, setIsLoadingShow, setIsLoadingForm } from '../redux/actions/realStateAction';
import { setActivityLogReset, setIsOpenModal as setIsOpenModalActivity, setLogName, setSubjectId } from '../../../../../redux/actions/dashboard/activities/activityLogAction';
import swalErrors from '../../../../../hooks/useErrors';
import { swalSuccess } from '../../../../../hooks/useSweetAlert';
import usePermission from '../../../../../hooks/usePermission';

const TableRowComponent = (props) => {
	const {
		id,
		logo = null,
		name,
		numberId,
		brokerId,
		apiTokenDomus,
		idDomus,
		apiTokenPipeDrive,
		subDomainPipeDrive,
		color = '',
		status
	} = props;
	const dispatch = useDispatch();
	const refreshTable = useSelector(({ realState }) => realState.refreshTable);
	const permission = usePermission('realstates.status');
	
	useEffect(() => {
	}, [
		id,
		logo,
		name,
		numberId,
		brokerId,
		apiTokenDomus,
		idDomus,
		apiTokenPipeDrive,
		subDomainPipeDrive,
		color,
		status
	]);
	
	/**
	 *
	 * @description Execute dispatch open modal, reset formData, loading show, loading form, formType and activityLog state
	 * @return dispatch
	 *
	 */
	const handleIsOpenModal = async (formType) => {
		if (formType === 'activity-log') {
			await dispatch( setActivityLogReset() );
			await dispatch( setLogName('Inmobiliarias') );
			await dispatch( setSubjectId(id) );
			await dispatch( setIsOpenModalActivity(true) );
		} else {
			await dispatch( setFormData({ id: id }) );
			await dispatch( setFormType(formType) );
			await dispatch( setIsLoadingShow(false) );
			await dispatch( setIsLoadingForm(false) );
			await dispatch( setIsOpenModal(true) );
		}
	};
	
	/**
	 *
	 * @description Execute endpoint change status
	 * @return dispatch
	 *
	 */
	const handleUpdateStatusById = async () => {
		if (permission) {
			try {
				const { data } = await setUpdateStatusById(id);
				
				if (data.status === 200) {
					await swalSuccess('Cambio de estatus', data.data);
					await dispatch( setRefreshTable(!refreshTable) );
				}
			} catch (error) {
				await swalErrors(error);
			}
		}
	};
	
	return (
		<tr className="m-0 p-0">
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="15%"
			>
				{logo !== null && (
					<div className="mx-auto justify-content-center d-flex">
						<img className="logoRealState" src={logo} alt={name} />
					</div>
				)}
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="20%"
			>
				<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
					{ name }
				</div>
				<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
					<span><strong>Número: </strong></span> { numberId }
				</div>
				<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
					<span><strong>Broker: </strong></span> { brokerId }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="20%"
			>
				<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
					<span><strong>Api Token: </strong></span> { apiTokenDomus }
				</div>
				<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
					<span><strong>ID: </strong></span> { idDomus }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="20%"
			>
				<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
					<span><strong>Api Token: </strong></span> { apiTokenPipeDrive }
				</div>
				<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
					<span><strong>Sub-Dominio: </strong></span> { subDomainPipeDrive }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="10%"
			>
				{color !== null && (
					<div className="mx-auto justify-content-center d-flex">
						<div className="badge p-2 w-100 text-truncate" style={{ background: color, height: '50px !important' }}>
							{color}
						</div>
					</div>
				)}
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="10%"
			>
				<div className="text-center">
					<Inputs.Switch
						name="status"
						color={status === 'Active' ? 'success' : 'error'}
						isChecked={status === 'Active' ? true : false}
						position="end"
						onChange={handleUpdateStatusById}
					/>
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="5%"
			>
				<div className="mx-auto d-flex justify-content-center">
					<DropdownActionComponent handleIsOpenModal={handleIsOpenModal} />
				</div>
			</td>
		</tr>
	);
};

TableRowComponent.propTypes = {
	id: PropTypes.number.isRequired,
	logo: PropTypes.string,
	name: PropTypes.string.isRequired,
	numberId: PropTypes.number.isRequired,
	brokerId: PropTypes.number.isRequired,
	apiTokenDomus: PropTypes.string.isRequired,
	idDomus: PropTypes.number.isRequired,
	apiTokenPipeDrive: PropTypes.string,
	subDomainPipeDrive: PropTypes.string,
	color: PropTypes.string,
	status: PropTypes.any.isRequired
};

export default TableRowComponent;

