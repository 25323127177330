import React from 'react'
import Facebook from '@material-ui/icons/Facebook'
import PropTypes from 'prop-types'

const FacebookIcon = ({ height, width, ...props }) => {

    return (
        <Facebook
            height={height}
            width={width}
            {...props}
        />
    )
}

FacebookIcon.propTypes = {
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
}

export default FacebookIcon