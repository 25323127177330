import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Feedback from '../../../../../components/feedback/Feedback'
import Display from '../../../../../components/display/Display'
import { withStyles } from '@material-ui/core/styles'
import errorServices from '../../../../../services/errors'
import { swalSuccess } from '../../../../../services/sweetalert'
import axios from '../../../../../services/axios'

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5',
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
))

const DropdownStatusCommercial = ({ property_id, handleList }) => {
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [getLoading, setLoading] = useState(false)
	const [arrayDropdown, setDropdown] = useState([])
	
	const handleToggle = (event) => {
		setAnchorEl(event.currentTarget)
	}
	
	const handleClose = () => {
		setAnchorEl(null)
	}
	
	const handleDropdown = async () => {
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`

			const data = await axios.get('dashboard/settings/properties/status-commercial')

			if (data.status === 200) {
				setDropdown(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	useEffect(() => {
		Boolean(anchorEl) && handleDropdown()
	}, [property_id, Boolean(anchorEl)])
	
	const handleChangeStatuscommercial = async (id) => {
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				status_commercial_id: id
			}
			
			const data = await axios.put(`dashboard/properties/status-commercial/${property_id}`, param)
			
			if (data.status === 200) {
				swalSuccess('Cambio de estatus comercial exitoso', data.data.data)
				handleList()
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	return (
		<>
			<Display.Tooltip
				label="Cambiar estatus comercial"
				placement="top"
			>
				<IconButton
					aria-label="more"
					aria-controls={`customized-menu${property_id}`}
					aria-haspopup="true"
					onClick={handleToggle}
				>
					<Display.Icons.MoreVertIcon className="text-dark" style={{ fontSize: 20 }} />
				</IconButton>
			</Display.Tooltip>
			<StyledMenu
				id={`customized-menu${property_id}`}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{getLoading && (
					<MenuItem>
						<div className="float-left position-relative d-inline">
							<Feedback.Loading
								type="spin"
								width="26px"
								height="26px"
							/>
						</div>
						<div className="float-left position-relative d-inline d-inline ml-3 mt-2 font-family-roboto-regular font-size-18 text-grey">
							Cargando...
						</div>
					</MenuItem>
				)}
				{!getLoading && (
					arrayDropdown.length > 0 && (
						arrayDropdown.map(({ id, name }, index) => (
							<MenuItem
								key={index}
								onClick={() => handleChangeStatuscommercial(id)}
							>
								<ListItemText primary={name} />
							</MenuItem>
						))
					)
				)}
			</StyledMenu>
		</>
	)
}

export default DropdownStatusCommercial
