import * as types from '../types/loadingType';

const initialState = {
	isLoading: false
};

const loadingReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.IS_LOADING:
			return {
				...state,
				isLoading: payload
			};
		
		default:
			return state;
	}
};

export default loadingReducer;
