import endPoints from './endPoints'
import axios from '../../../../../../../services/axios'

const {
	show
} = endPoints

export const getShowByCode = async (code) => (
	await axios.get(`${show}${code}`)
)
