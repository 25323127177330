import * as types from '../../actionsTypes/dashboard/propertyTypes'

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	search: '',
	realState: '',
	statusCommercial: '',
	arrayList: [],
	refreshTable: false,
}

const propertyReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_PROPERTY:
			return {
				...state,
				perPage: payload
			}
		
		case types.PAGE_PROPERTY:
			return {
				...state,
				page: payload
			}
		
		case types.PAGES_PROPERTY:
			return {
				...state,
				pages: payload
			}
		
		case types.SEARCH_PROPERTY:
			return {
				...state,
				search: payload
			}
		
		case types.REALSTATE_PROPERTY:
			return {
				...state,
				realState: payload
			}
			
		case types.STATUS_COMMERCIAL_PROPERTY:
			return {
				...state,
				statusCommercial: payload
			}
		
		case types.ARRAY_LIST_PROPERTY:
			return {
				...state,
				arrayList: payload
			}
		
		case types.REFRESH_TABLE_PROPERTY:
			return {
				...state,
				refreshTable: payload
			}
		
		default:
			return state
	}
}

export default propertyReducer
