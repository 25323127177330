export const PER_PAGE_PROPERTY_ROOM_SERVICE = 'PER_PAGE_PROPERTY_ROOM_SERVICE';
export const PAGE_PROPERTY_ROOM_SERVICE = 'PAGE_PROPERTY_ROOM_SERVICE';
export const SEARCH_PROPERTY_ROOM_SERVICE = 'SEARCH_PROPERTY_ROOM_SERVICE';
export const PAGES_PROPERTY_ROOM_SERVICE = 'PAGES_PROPERTY_ROOM_SERVICE';
export const ARRAY_LIST_PROPERTY_ROOM_SERVICE = 'ARRAY_LIST_PROPERTY_ROOM_SERVICE';
export const ARRAY_LIST_TO_DROPDOWN_PROPERTY_ROOM_SERVICE = 'ARRAY_LIST_TO_DROPDOWN_PROPERTY_ROOM_SERVICE';
export const REFRESH_TABLE_PROPERTY_ROOM_SERVICE = 'REFRESH_TABLE_PROPERTY_ROOM_SERVICE';
export const IS_OPEN_MODAL_PROPERTY_ROOM_SERVICE = 'IS_OPEN_MODAL_PROPERTY_ROOM_SERVICE';
export const IS_LOADING_SHOW_PROPERTY_ROOM_SERVICE = 'IS_LOADING_SHOW_PROPERTY_ROOM_SERVICE';
export const IS_LOADING_FORM_PROPERTY_ROOM_SERVICE = 'IS_LOADING_FORM_PROPERTY_ROOM_SERVICE';
export const FORM_TYPE_PROPERTY_ROOM_SERVICE = 'FORM_TYPE_PROPERTY_ROOM_SERVICE';
export const FORM_DATA_PROPERTY_ROOM_SERVICE = 'FORM_DATA_PROPERTY_ROOM_SERVICE';
