import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../../../../components/mui/Layout';
import Inputs from '../../../../../../components/mui/Inputs';
import { setFormData, setFormType, setRefreshTable, setSearch, setIsOpenModal, setIsLoadingShow, setIsLoadingForm } from '../redux/actions/propertyStatusCommercialAction';
import usePermission from '../../../../../../hooks/usePermission';
import { initialValuesPropertyStatus as initialValues } from '../../../../../../helpers/variablesInitialValues';

const SearchComponent = () => {
	const dispatch = useDispatch();
	const refreshTable = useSelector( ({ propertyStatusCommercial })=> propertyStatusCommercial.refreshTable);
	const search = useSelector( ({ propertyStatusCommercial }) => propertyStatusCommercial.search);
	const permissionStore = usePermission('propertyStatusCommercial.store');
	const permissionList = usePermission('propertyStatusCommercial.list');
	
	/**
	 *
	 * @description Execute open modal, reset formData, formType, loading show and loading form state
	 * @return dispatch
	 *
	 */
	const handleIsOpenModal = async () => {
		await dispatch( setIsOpenModal(true) );
		await dispatch( setFormData(initialValues) );
		await dispatch( setFormType('store') );
		await dispatch( setIsLoadingShow(false) );
		await dispatch( setIsLoadingForm(false) );
	};
	
	/**
	 *
	 * @description Execute dispatch search state
	 * @return dispatch
	 *
	 */
	const handleChange = ({ target }) => dispatch( setSearch(target.value) );
	
	/**
	 *
	 * @description Execute dispatch refreshTable state
	 * @return dispatch
	 *
	 */
	const handleKeyPress = ({ charCode }) => charCode === 13 && dispatch( setRefreshTable(!refreshTable) );
	
	/**
	 *
	 * @description Execute dispatch search, refreshTable state
	 * @return dispatch
	 *
	 */
	const handleClear = async () => {
		await dispatch( setSearch('') );
		await dispatch( setRefreshTable(!refreshTable) );
	};
	
	return (
		<Layout.Card className="border-radius-0 m-0 p-0">
			<Layout.CardContent className="m-0 p-3">
				<div className="row mt-2 mb-0">
					<div className="col-sm-12">
						{permissionStore && (
							<Inputs.Button
								label="Nuevo"
								startIcon={<Layout.Icons.AddCircleIcon width={20} height={20} />}
								onClick={handleIsOpenModal}
								className="font-family-roboto-regular fs-6 bg-primary text-capitalize mb-2"
							/>
						)}
						{permissionList && (
							<Inputs.TextBox
								name="search"
								label="Buscar"
								placeholder="Ingrese la búsqueda..."
								value={search}
								onChange={handleChange}
								onKeyPress={handleKeyPress}
								className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-300px"
							/>
						)}
						<Layout.Tooltip
							title="Limpiar búsqueda"
							placement="top"
						>
							<Layout.IconButton
								onClick={handleClear}
								edge="start"
								className="bg-secondary font-roboto-semibold d-inline float-end mt-1 ml-2 mb-2"
							>
								<Layout.Icons.CloseIcon
									width={18}
									height={18}
									className="font-roboto-semibold text-white"
								/>
							</Layout.IconButton>
						</Layout.Tooltip>
					</div>
				</div>
			</Layout.CardContent>
		</Layout.Card>
	);
};

export default SearchComponent;
