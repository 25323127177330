import React from 'react'
import MuiSelect from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFormLabel-root.Mui-focused': {
            fontWeight: '500',
            color: '#1b1b1b !important',
            fontSize: '18px'
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid #1b1b1b !important'
        },
        '& .MuiInput-underline:before:hover': {
            borderBottom: '2px solid #1b1b1b !important'
        }
    },
    formControl: {
        width: '100%'
    }
}))

export default function SelectBuilding(props) {
    const classes = useStyles()
    const { label, name, variant, defaultValue, onChange, arrayDropdown, error, message, ...other } = props

    return (
        <FormControl className={classes.formControl} error={error || false}>
            <InputLabel id={name}>{label}</InputLabel>
            <MuiSelect
                labelId={name}
                id={name}
                name={name}
                variant={variant || "standard"}
                value={defaultValue}
                defaultValue={defaultValue}
                onChange={onChange || null}
                classes={{ root: classes.root }}
                {...other}
            >
                <MenuItem value="">-- Seleccione una opción --</MenuItem>
                {
                    arrayDropdown.map((item, index) => (
                        <MenuItem key={index} value={item.id}>{item.company} ({item.nit})</MenuItem>
                    ))
                }
            </MuiSelect>
            <FormHelperText>{message || ""}</FormHelperText>
        </FormControl>
    )
}
