import { initialValuesAuth as initialValues } from '../../../../helpers/variablesInitialValues';
import * as types from '../types/authType';

const initialState = {
	isLoadingForm: false,
	isAuthenticated: false,
	accessToken: '',
	tokenType: '',
	expiresAt: '',
	userData: initialValues
};

const authReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.IS_LOADING_FORM_AUTH:
			return {
				...state,
				isLoadingForm: payload
			};
		
		case types.IS_AUTHENTICATED:
			return {
				...state,
				isAuthenticated: payload
			};
		
		case types.TOKEN_TYPE:
			return {
				...state,
				tokenType: payload
			};
		
		case types.ACCESS_TOKEN:
			return {
				...state,
				accessToken: payload
			};
		
		case types.EXPIRES_AT:
			return {
				...state,
				expiresAt: payload
			};
		
		case types.USER_DATA:
			return {
				...state,
				userData: payload
			};
		
		default:
			return state;
	}
};

export default authReducer;
