import React, {useState} from 'react'
import { v4 as uuidv4 } from 'uuid';
import useSWRImmutable from 'swr/immutable'
import toast, { Toaster } from "react-hot-toast";
import JSZip from 'jszip'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import PublicIcon from '@mui/icons-material/Public';
import SaveIcon from '@mui/icons-material/Save';
import Unpublished from '@mui/icons-material/Unpublished';
import ShareOutlined from '@mui/icons-material/ShareOutlined';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import Button from '@mui/material/Button'
import Button4 from '@material-ui/core/Button'
import Collapse from '@mui/material/Collapse'
import Switch from '@material-ui/core/Switch'
import TextField from '@mui/material/TextField'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import Delete from '@mui/icons-material/Delete'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Display from '../../../../../components/display/Display'
import Feedback from '../../../../../components/feedback/Feedback'
import axios from '../../../../../services/axios'
import { makeStyles } from '@material-ui/core/styles'
import { history } from '../../../../../redux/store'
import './PropertyPage.scss'

import AssetRow from './NewAssetComponets/AssetRow'
import CommonUploadBox from './NewAssetComponets/CommonUploadBox'
import AssetCard from './NewAssetComponets/AssetCard'
import { useAssets } from './NewAssetComponets/AssetsContext'
import SelectAssetOrigin from './NewAssetComponets/SelectAssetOrigin'

import FullscreenThumbnail from './NewAssetComponets/FullscreenThumbnail'
import handleAssetUploadToS3, { updateAssetsInMobili, publishResources, deleteResource, updatePublishedResources, unpublishResources } from './NewAssetComponets/HandleS3Upload'

import 'moment/locale/es';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100% !important',
		height: '45px !important',
	},
	label: {
		textTransform: 'none',
		fontSize: '18px',
	},
	dark: {
		fontWeight: '700',
		fontSize: '18px !important',
		color: '#f9f9f9',
		border: '0px solid transparent !important',
		backgroundColor: '#3B3B3B !important',
		boxShadow: 'none !important',
		'&:hover': {
			backgroundColor: '#888888',
			color: '#f9f9f9',
			boxShadow: 'none !important',
		},
		'& .MuiButton-iconSizeMedium > *:first-child' : {
			fontSize: '18px !important'
		}
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		// top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}))


const ModalOriginList = ({ getPropertyID, getMobiliCode, getTitleModal, getOpenModal, setOpenModal, handleList, property_id }) => {
	const classes = useStyles()
	// const [getOrigin, setOrigin] = useState('Captación')
	// const [value, setValue] = React.useState(0)
	const [getLoading, setLoading] = useState(false)
	
	
	const handleClose = () => {
		// setOrigin('Captación')
		// setValue(0)
		setOpenModal(false)
		handleList()
	}

	const { updateFileList, files, updateFile, origin, removeAssetFromList, changeOrderList, orderList } = useAssets()

	const [activeTab,setActiveTab] = React.useState("Fotos")

	/**
	 * @type { 'list' | 'upload' }
	 */
	const [typeOfView, setTypeOfView] = React.useState('list') 

	const [ publishedResults, setPublishedResults] = React.useState(false)

	const [selectedResources, setSelectedResources] = React.useState([])

	const [mainResource, setMainResource] = React.useState(-1)

	const [tourPath, setTourPath] = React.useState(null);

	const [originAtFilter, setOriginForFilter] = React.useState('none');

	const [date, setDate] = React.useState(Date.now());

	const [collectionDateAtFilter, setCollectionDateAtFilter] = React.useState('none');

	const [bigPicture, setBigPicture] = React.useState({
		open:false,
		url:null,
	});

	const [deleteConfirmation, showDeleteConfirmation] = React.useState(false);

	const [loadingAction,setLoadingAction] = React.useState('none');

	const { data, mutate } = useSWRImmutable(getOpenModal && `dashboard/properties/assets/${getPropertyID}`,(url)=>axios.get(url).then(res => res.data.data));

	const { data:ORIGINS } = useSWRImmutable("dashboard/properties/assets/origins", (url)=>axios.get(url).then(res => res.data.data) )

	const originNumber = (['Captacion', 'Inventario', 'Reparacion', 'Desocupacion'].indexOf(origin) + 1)

	const asset_type = ((["Fotos", "Fotos 360", "Videos", "Tour Virtual"]).indexOf( activeTab ) + 1 )

	const collectionDateValues = React.useMemo( function(){
		const arr = new Set()
		for(const resource of (data ?? [])){
			arr.add(resource.origin_date)
		}
		return Array.from(arr);
	},[data]);

	const filterData = (data ?? []).filter( resource => 
			resource.asset.asset_type.key == asset_type &&
			(resource.published == publishedResults ) && 
			( originAtFilter != 'none' ? resource.asset_origin.value === originAtFilter : true) && 
			( collectionDateAtFilter != 'none' ? resource.origin_date === collectionDateAtFilter : true)
		)
	
	const publishedResources = React.useMemo(function(){
		return (data ?? []).filter( (resource)=>(
			resource.asset.asset_type.key == asset_type &&
			(resource.published === 1 )))
	},[data])
	

	const orderOfPublishedResources = React.useMemo(()=>{
		const list = publishedResources.map( function(a){ return a }  );
		// changeOrderList(list);
		return list
	},[publishedResources])

	React.useEffect(()=>{
		// console.log(publishedResources)
		changeOrderList({orderList:publishedResources.map( function(a){ return a }  )})
	}, [publishedResources])

	const deleteResourcesAction = ()=>{
		setLoadingAction('delete');
		deleteResource({
			asset_files: selectedResources.map(
				(resource)=>{ return { "id":resource.asset_id } }
			)
		}, ()=>{
			setLoadingAction('none');
			toast.success("Recursos borrados!")
			mutate();
			setSelectedResources([]);
			setMainResource(-1);
		}).catch(e=>{
			setLoadingAction('none');
			toast.error("No se pudo borrar!")
		})
	}
	
	return (
		<>
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				fullWidth={true}
				fullScreen
				maxWidth="xl"
				aria-labelledby="dialogForm"
				open={getOpenModal}
			>
				<DialogTitle id="dialogForm">
					{getTitleModal}
					
					<IconButton
						onClick={handleClose}
						className="bg-white height-32 width-32"
						classes={{ root: classes.closeButton }}
						aria-label="like"
					>
						<Display.Icons.CloseIcon
							className="text-dark"
							style={{ fontSize: 20 }}
						/>
					</IconButton>
				</DialogTitle>
				<DialogContent
					className="overflow-hidden"
					dividers
					style={{
						position:'relative', minHeight:'60vh', height:'100%',
						margin: "0 auto",
  					maxWidth: "1280px",
  					width: "100%",
						padding:"10px"
					}}
					
				>
				<Toaster toastOptions={{ position: "bottom-center" }} />
				{ getOpenModal && <FullscreenThumbnail {...bigPicture} onClose={()=>{
					setBigPicture({open:false, url:null})
				}}  />}
				<Collapse in={typeOfView === 'list'}>
					<Stack direction="row" gap={2} justifyContent="center" mb={2}>
						{
							["Fotos", "Fotos 360", "Videos", "Tour Virtual"].map(
								function(item,index){
									const active =  activeTab === item;
									return <Box key={index} sx={{
										p:"6px 20px",
										cursor:'pointer',
										borderRadius:'2px',
										transition:'all ease-in-out .2s',
										bgcolor: active ? 'dark.default' : 'inherit',
										...(active && {
											fontWeight:700,
											color:"#fff"
										})
									}}
									onClick={()=>{
											setActiveTab(item)
											setSelectedResources([]);
											setMainResource(-1);
											setPublishedResults(false);
											// clickSwitch()
										}
									}
									>
										{item}
									</Box>
								}
							)
						}
					</Stack>
				</Collapse>
				<Box display="grid" gridTemplateColumns="1fr max-content" gap={2}>
					<Box borderRight="1px solid" paddingRight={2}>
					{/* // ? MAIN Section */}
				{typeOfView === 'list' && (
					<>
						<Stack direction="row" justifyContent="space-between">
							<Stack direction="row" gap={1}>
								<Box>
									{selectedResources.length}
								</Box>
								<span>Recursos seleccionados</span>
							</Stack>

								<FormGroup>
									<FormControlLabel control={<Switch  checked={publishedResults} color='primary' size='small' onChange={(_)=>{
										setPublishedResults((prev)=> !prev)
										setSelectedResources([])
										setMainResource(-1);
									}} />} label="Recursos Publicados" />
								</FormGroup>
						</Stack>
						
						<Box sx={{overflowY:'scroll'}} maxHeight="60vh" my={1}>
							{/* <pre>
								{JSON.stringify(filterData,null,2)}
							</pre> */}
							{filterData?.map(
								(resource,index)=> <AssetCard key={index} 
									{...resource}
									asset_type={asset_type}
									selected={selectedResources.find(el=> el.asset_id === resource.asset_id) != undefined}
								 	onSelect={(checked)=>{
										if(!checked){
											setSelectedResources(selectedResources.filter( (_resource)=> _resource.asset_id != resource.asset_id))
											return;
										}
										setSelectedResources([ ...selectedResources, resource ])
									}}
									localMain={ ( mainResource === -1 && publishedResults && resource.main === 1 ) || mainResource === resource.asset_id }
									// localMain={ ( mainResource === -1 && publishedResults && index === 0 ) || mainResource === resource.asset_id }
									openFullScreen={(url)=>setBigPicture({
										open:true,
										url,
									})}
									published={publishedResults}
									position={ {
										first:index === 0,
										last: index === (filterData.length - 1),
									}}
									mutate={(_)=>{
										const newOptimisticData = []
										for(const ordered of orderList){
											for(const r of publishedResources){
												if(ordered.asset_id === r.asset_id){
													newOptimisticData.push(
														r
													)
												}
											}
										}
										// console.log({newOptimisticData})
										// return;
										mutate(newOptimisticData,{
											optimisticData: newOptimisticData,
											rollbackOnError: true,
											populateCache: false,
											revalidate: false
										})
									}}
									/>
							)}
						</Box>
					</>
				)}
				{typeOfView === 'upload' && (
					<Box>

						<Button startIcon={<ChevronLeft />} onClick={()=>{
								setTypeOfView('list');
								mutate();
								setTourPath(null)
								files.map(
									(asset)=>{
										if(asset.mobiliStatus === 'uploaded'){
											removeAssetFromList(asset.name)
										}
									}
								)
							}}>
							volver al listado
						</Button>

						{/* <pre>
							{JSON.stringify(files.map(file=>`${file.name}, ${file.sizeFormated}`),null,2)}
						</pre> */}

						{files.length === 0 && (
							<Box minHeight="40vh - 150px">
								Los recursos serán listados aquí
							</Box>
						)}
						
						<Collapse in={files.length > 0 || tourPath?.length >0}>
							<Box my={1}>
								<SelectAssetOrigin />
							</Box>
						</Collapse>


						<AssetRow asset_type={asset_type} />
						
						<div style={{height:'20px'}} />
						{ asset_type === 4 ? (
							<Box>
								<TextField label="URL del Tour virtual" onChange={(event)=> {{
									setTourPath(event.target.value)
								}}} fullWidth />
							</Box>
						) : (
							<CommonUploadBox asset_type={asset_type} multiple={true} onInputChange={(files)=>{
								// console.log({files});
								updateFileList(files);
							}}/>
						)}

						<Stack justifyContent="flex-end" flexDirection="row" gap={2} mt={2}>
							{/* {JSON.stringify(new Intl.DateTimeFormat('en-US').format(new Date(date)))} */}
						<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
							<DatePicker
								label="Fecha de recolección"
								value={date}
								onChange={(newValue) => {
									setDate(newValue)
								}}
								maxDate={new Date()}
								renderInput={(params) => (
									<TextField {...params} sx={{ width: '100%', alignSelf:'flex-end' }} size="small" />
								)}
							/>
						</LocalizationProvider>
							<button className='btn-upload mt-5' onClick={()=>
								{
									if(asset_type === 4){
										// tour
										if( tourPath.length === 0 )return;
										const uuid = uuidv4();
										updateAssetsInMobili({
											"property_id":getPropertyID,
											"asset_origin_type_id": originNumber,
											"asset_type_id": asset_type,
											"origin_date":new Intl.DateTimeFormat('en-US').format(new Date(date)),
											"asset_files":[
												{
													name:uuid,
													path: tourPath, 
													uuid,
												}
											]
										}, (resp)=>{
											if(resp.mobiliStatus === 'uploaded'){
												setTypeOfView('list')
												mutate();
												setTourPath(null)
											}
										})
										return;
									}
									handleAssetUploadToS3(property_id,files,updateFile, function( asset ){
										// return;
										updateAssetsInMobili({
											name: asset.name,
											"property_id":getPropertyID,
											"asset_origin_type_id": originNumber,
											"asset_type_id": asset_type,
											"origin_date":new Intl.DateTimeFormat('en-US').format(new Date(date)),
											"asset_files": [
												{
													uuid:asset.uuid,
													name: asset.name,
													path: asset.path,
												}
											]	
										},updateFile)
									})
								}
								}
							>Subir
							</button>
						</Stack>


					</Box>
				)}
				{typeOfView === 'list' && (
					<>
					{/*deleteConfirmation, showDeleteConfirmation */}
					{deleteConfirmation && (
						<Box position="absolute" border="1px solid" borderRadius="8px"  p={2} zIndex={3} sx={{
							background:"#fff",
							boxShadow:'2px 2px 2px rgba(0,0,0,.3)'
						}}>
							<svg style={{
									position:"absolute",
									right:'5px',
									top:'5px',
									cursor:'pointer',
								}}
								onClick={()=>showDeleteConfirmation(false)}
							  height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)"><circle cx="8.5" cy="8.5" r="8"/><g transform="matrix(0 1 -1 0 17 0)"><path d="m5.5 11.5 6-6"/><path d="m5.5 5.5 6 6"/></g></g></svg>
							<p style={{margin:'8px 0px 0px 0px'}}>Estas seguro de querer eliminar los recursos seleccionados ?</p>
							<Stack direction="row" gap={2} mt={1} justifyContent="flex-end">
								<Button4 size='small' variant='outlined' color='secondary' onClick={()=>{
									deleteResourcesAction();
									showDeleteConfirmation(false)
								}}>Si</Button4>
								<Button4 size='small' variant='outlined' color='default' onClick={()=>showDeleteConfirmation(false)}>No</Button4>
							</Stack>
						</Box>
					)}
					<Box position="sticky" bottom={0} display="flex" justifyContent="flex-end" gap={2} zIndex={2}>

						{selectedResources.length > 0 && (
							<div style={{display:'grid', placeContent:'center'}}>
							<IconButton color='primary' size='small' disabled={loadingAction === 'download'} onClick={async()=>{
									setLoadingAction("download");

									const zip = new JSZip();

									zip.file("leeme.txt", "a folder with photos");
									console.log({selectedResources})

									for await(const resource of selectedResources){
										setLoadingAction("download")
										// resource.asset.full_path
										const transfer = await fetch(`${resource.asset.full_path}?cacheblock=true`, {
											// mode:'no-cors',
											// headers:{
											// 	'Access-Control-Allow-Origin':'*'
											// }
										}).catch(e=>{
											setLoadingAction("none")
										});

										console.log({path:resource.asset.full_path})
										console.log({transfer})

										if(transfer.ok){
											const blob = await transfer.blob();
											console.log(blob)
											zip.file(resource.asset.name, blob);
										}else{
											console.log("error",transfer)
											continue;
										}
									}
									// return;

									zip.generateAsync({type:"blob"}).then(function(content) {
										// see FileSaver.js
										// URL.createObjectURL(content)
										const url = URL.createObjectURL(content);
										console.log({url})
										window.open(url)
										setLoadingAction("none")
										// saveAs(content, "example.zip");
									});

									// setTimeout(()=>setLoadingAction("none"),2000)
							}} >
								{loadingAction === 'download' ? <CircularProgress color='inherit' size="23px"/> : <DownloadIcon/>}
							</IconButton>
							</div>
						)}

						{selectedResources.length > 0 && (
							<Button4 variant='outlined' color='secondary' startIcon={ loadingAction === 'delete' ? <CircularProgress color='error' size="20px"/> : <Delete/> } disabled={loadingAction === 'delete'} onClick={()=>{
								showDeleteConfirmation(true)
							}} >Eliminar</Button4>
						)}

						{(selectedResources.length > 0 && publishedResults) && (
							<Button4 variant='outlined' color='secondary' startIcon={ loadingAction === 'unpublish' ? <CircularProgress color='error' size="20px"/> : <Unpublished/> } disabled={loadingAction === 'delete'} onClick={()=>{
								// showDeleteConfirmation(true)
								setLoadingAction("unpublish")
								unpublishResources({
									"property_id": getPropertyID,
									"asset_type_id": asset_type,
									"asset_files": selectedResources.map( function(resource){
										return { id: resource.asset_id }
									})
								}, function(){
									toast.success("Cambios hechos!")
									setLoadingAction('none')
									mutate();
									setSelectedResources([]);
									setMainResource(-1);
								}).catch(()=>{
									setLoadingAction('none')
									toast.error("No se pudieron quitar los recursos de publicación")
								})
							}} >Quitar</Button4>
						)}

						{selectedResources.length == 1 && (
							<Button4 
								variant='outlined'
								color='default' 
								startIcon={<CenterFocusWeakIcon/>}
								onClick={()=> setMainResource( selectedResources[0].asset_id )}
								>Imagen principal</Button4>
						)}
						{ publishedResults && (
							<button 
								className='btn-upload publish-btn'
								disabled={ loadingAction === "updatePublished" }
								onClick={()=> {
									const hasMainImage =  asset_type === 1 ? orderList.find( resource=> resource.main === 1 ) != undefined : false;
									setLoadingAction('updatePublished')
									updatePublishedResources(
									{
										"property_id": getPropertyID,
										"asset_type_id": asset_type,
										"asset_files": orderList
											.map(
											function(resource,index){
												// with have select a main image 
												if(mainResource != -1){
													return {
														id: resource.asset_id,
														...( asset_type === 1 ? {
															main: mainResource === resource.asset_id ? 1 : 0,
														} : { main : 0 })
													}
												}
												// we havent
												return {
													id: resource.asset_id,
													...(asset_type === 1 ? {
														main: (!hasMainImage && index === 0 ? 1 : resource.main ?? 0 ),	
													} : { main : 0 })
												}
											}
										)
									},
									()=>{
										toast.success("Cambios guardados")
										setLoadingAction('none')
										mutate();
										setSelectedResources([]);
										setMainResource(-1);
									}
								).catch(()=>{
										setLoadingAction('none');
										toast.error("No se pudieron guardar los cambios")
									})
								}}
								> { loadingAction === "updatePublished" ? <CircularProgress size="20px" /> :  <SaveIcon/> } Guardar </button>
						)}
						{(!publishedResults && selectedResources.length > 0) && (
							<button  className='btn-upload publish-btn' disabled={loadingAction === 'publish'} onClick={()=>{

								// PublishResources({
								// 	"property_id": getPropertyID,
								// 	"asset_type_id": asset_type,
								// 	"asset_files":[]
								// },()=>{
								// 		mutate();
								// 		setSelectedResources([]);
								// 		setMainResource(-1);
								// })
								// return;

								const hasMainImage =  asset_type === 1 ? publishedResources.find( resource=> resource.main === 1 ) != undefined : false;

								// loadingAction,setLoadingAction
								setLoadingAction('publish')
								publishResources(
									{
										"property_id": getPropertyID,
										"asset_type_id": asset_type,
										"asset_files": [...publishedResources, ...selectedResources]
											.map(
											function(resource,index){
												// with have select a main image 
												if(mainResource != -1){
													return {
														id: resource.asset_id,
														...( asset_type === 1 ? {
															main: mainResource === resource.asset_id ? 1 : 0,
														} : { main : 0 })
													}
												}
												// we havent
												return {
													id: resource.asset_id,
													...(asset_type === 1 ? {
														main: (!hasMainImage && index === 0 ? 1 : resource.main ?? 0 ),	
													} : { main : 0 })
												}
											}
										)
									},
									()=>{
										toast.success("Publicados correctamente 🫡")
										setLoadingAction('none');
										mutate();
										setSelectedResources([]);
										setMainResource(-1);
									}
								).catch(e=>{
									setLoadingAction('none');
									toast.error("No se pudo publicar los recursos")
								})
							}}> {loadingAction === 'publish' ? ( <CircularProgress size="20px" /> ) : <PublicIcon /> }   Publicar </button>	
						)}
						<button className='btn-upload' onClick={()=>{setTypeOfView('upload')}}> Cargar nuevos recursos</button>
					</Box>
					</>
				)}
				{/* // ? END MAIN Section */}
				</Box>
						<Box sx={{overflowY:"scroll", maxHeight:"80vh", padding:"0px 18px"}}>
							{/* <Box>
								<IconButton onClick={()=>{
									history.push(`/owners/inventory/${getMobiliCode}/NO_ALBUM_NAME/NO_ALBUM_ID`)
								}}>
									S
								</IconButton>
							</Box> */}
							<FormControl component="fieldset" disabled={typeOfView != 'list'}>
								<FormLabel component="legend">Origen:</FormLabel>
								<RadioGroup aria-label="origen" name="origin" value={originAtFilter} onChange={(event)=>{
									setOriginForFilter(event.target.value);
								}}>
									<FormControlLabel value="none" control={<Radio color='primary' size='small' />} label="Todos"/>
									{(ORIGINS ?? []).map(origin=> (
										<FormControlLabel key={origin.value} value={origin.value} control={<Radio color='primary' size='small' />} label={origin.value}/>
									))}
								</RadioGroup>
							</FormControl>

							<Box mt={2}>
									{/* <input type="text" value="" id="copyText" hidden /> */}
									<FormControl component="fieldset" disabled={typeOfView != 'list'}>
										<FormLabel component="legend">Fecha recolección:</FormLabel>
										<RadioGroup aria-label="date" name="date" value={collectionDateAtFilter} onChange={(event)=>{
											setCollectionDateAtFilter(event.target.value);
										}}>
											<FormControlLabel value="none" control={<Radio color='primary' size='small' />} label="Todas"/>
											{collectionDateValues.map(collectionDate=> (
												<Stack key={collectionDate} direction="row" gap={.5} alignItems="center">
													<FormControlLabel  value={collectionDate} control={<Radio color='primary' size='small' />} label={collectionDate}/>
													<div style={{ opacity: collectionDateAtFilter == collectionDate ? 1 : .1  }}>
														<IconButton size='small' onClick={()=>{
															// const copyText = document.getElementById("copyText");
															if(filterData.length === 0){
																toast.error("No hay recursos que compartir")
																return;
															}
															const origin = ORIGINS.find( (origin)=>  origin.value == originAtFilter)
															if(!origin){
																toast.error("No se encontró origin");
																return;
															}
															console.log({origin})

															// encrypted_origin_date
															const origin_date = filterData.find( resource =>  resource.origin_date === collectionDate );
															if(!origin_date){
																toast.error("No se encontró fecha de recolección");
																return;
															}
															// debugger;
															// console.log({origin_date})
															let url = new URL(`${process.env.REACT_APP_FINANCAR_URL}/detalle/${getPropertyID}`);
															const other = new URLSearchParams();
															other.append("origin_id" , origin.encrypted_key )
															other.append("origin_date" , origin_date.encrypted_origin_date )
															url.search = other.toString()

															// ** only available with https
															if(window.location.protocol.includes("https")){
																navigator.clipboard.writeText(url.href).then(()=>{
																	toast.success("Copiado a portapapeles!")
																})
															}else {
																window.open(url.href)
															}
														}} disabled={originAtFilter === 'none' && collectionDateAtFilter != collectionDate }>
															<ShareOutlined />
														</IconButton>
													</div>
												</Stack>
											))}
										</RadioGroup>
									</FormControl>
							</Box>
							{/* <pre>
								{JSON.stringify(orderOfPublishedResources.map(a=> {return {ordered:a.order, id:a.asset_id, m:a.main}}),null,1)}
							</pre>

							<pre>
								orderList: <br/>
								{JSON.stringify(orderList.map(a=> {return {order:a.order, id:a.asset_id}}),null,1)}
							</pre> */}
						</Box>
						{/* // ? END GRID Section */}
				</Box>
				</DialogContent>
			</Dialog>
			<Feedback.DialogLoading
				getLoading={getLoading}
				setLoading={setLoading}
			/>
		</>
	)
}


export default ModalOriginList
