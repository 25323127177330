export const PER_PAGE_AREA = 'PER_PAGE_AREA';
export const PAGE_AREA = 'PAGE_AREA';
export const SEARCH_AREA = 'SEARCH_AREA';
export const PAGES_AREA = 'PAGES_AREA';
export const ARRAY_LIST_AREA = 'ARRAY_LIST_AREA';
export const ARRAY_LIST_TO_DROPDOWN_AREA = 'ARRAY_LIST_TO_DROPDOWN_AREA';
export const REFRESH_TABLE_AREA = 'REFRESH_TABLE_AREA';
export const IS_OPEN_MODAL_AREA = 'IS_OPEN_MODAL_AREA';
export const IS_LOADING_SHOW_AREA = 'IS_LOADING_SHOW_AREA';
export const IS_LOADING_FORM_AREA = 'IS_LOADING_FORM_AREA';
export const FORM_TYPE_AREA = 'FORM_TYPE_AREA';
export const FORM_DATA_AREA = 'FORM_DATA_AREA';
