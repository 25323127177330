import { uploadFile } from 'react-s3';
import { v4 as uuidv4 } from 'uuid';
import axios from '../../../../../../services/axios'

export default async function handleAssetUploadToS3(id_property,files, updateFile, uploadToMobili){
  // TODO pass real state as param
  const realState = 1;
  const obj = {
    [ ["a","c","c","e","s","s","K","e","y","I","d"].join('') ]: ["A","K","I","A","2","E","I","M","3","2","6","W","I","R","Q","Y","6","D","Q","R"].join(''),
    [["s","e","c","r","e","t","A","c","c","e","s","s","K","e","y"].join('')]: ["w","d","i","W","m","Q","6","+","2","S","c","w","z","j","v","m","v","h","r","Q","v","s","T","1","4","q","6","R","6","T","m","A","c","b","i","N","K","M","B","B"].join(''),
  }
  for (const asset of files){
    if(asset.mobiliStatus === 'uploaded') continue;
    if(asset.s3Status === 'uploaded'){
      uploadToMobili({
        name:asset.name,
        path:asset.s3.key,
        uuid: asset.uuid
      });
      continue;
    }
    const { file } = asset
    const uuid = uuidv4();
    updateFile({
      filename: asset.name,
      s3Status: 'loading',
      message: '...uploading',
      s3:null,
    });
      uploadFile(
        new File(
          [file],
          `${uuid}.${asset.type.split('/')[1]}`,
          { type: asset.type, lastModified: asset.lastModified },
        ),
        {
          // dirName: `red-flag`,
          dirName: `${realState}/properties/${id_property}`,
          bucketName: process.env.REACT_APP_SERVER_BUCKET == 'testing' ? 'mobili-test-bucket' : 'mobili-bucket',
          region: 'us-east-1',
          ...obj,
        },
      ).then(res=>{
        console.log(res)
        updateFile({
          filename: asset.name,
          s3Status: 'uploaded',
          message: 'OK',
          s3: {
            key: res.key,
            location: res.location,
          },
          uuid,
        });
        uploadToMobili({
          name:asset.name,
          path:res.key,
          uuid
        });
      }).catch(e=>{
        console.log(e)
        updateFile({
          filename: asset.name,
          s3Status: 'error',
          message: e,
          s3: null,
        })
      })

  }

  return Promise.resolve(true);

}

export function updateAssetsInMobili(resource,updateFile){

  updateFile({
    ...resource,
    mobiliStatus: 'loading',
    filename: resource.name,
  });
  axios.post('dashboard/properties/assets',resource).then( res=> {
    console.log(res)
    updateFile({
      ...resource,
      filename: resource.name,
      mobiliStatus: 'uploaded',
    });
  }).catch(e=>{
    console.log(e)
    updateFile({
      ...resource,
      mobiliStatus: 'error',
      filename: resource.name,
      message: e
    });
  })
}

export function publishResources(resources, done){

  return axios.post('dashboard/properties/assets/publish',resources).then( res=>{
    console.log({res});
    done()
  }).catch(e=>{
    console.log({e})
    throw e
  })
}

export function deleteResource(resources, done ){
  return axios.put('dashboard/properties/assets/delete',resources).then( res=>{
    console.log({res});
    done()
  }).catch(e=>{
    console.log({e})
    throw e
  })
}

export function updatePublishedResources(resources, done){

  return axios.put('dashboard/properties/assets/order',resources).then( res=>{
    console.log({res});
    done()
  }).catch(e=>{
    console.log({e})
    throw e
  })
}

export function unpublishResources(resources, done){

  return axios.put('dashboard/properties/assets/unpublish',resources).then( res=>{
    console.log({res});
    done()
  }).catch(e=>{
    console.log({e})
    throw e
  })
}

