import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setIsOpenSidebar } from '../../../../redux/actions/dashboard/sidebarAction'

const useSlider = (initialValue = false) => {
  const [isOpenSlider, setIsOpenSlider] = useState(initialValue)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [dataGalleries, setDataGalleries] = useState([])

  const dispatch = useDispatch()

  const setDataGalleriesSliderFunc = (data) => {
    setDataGalleries(data)
  }

  const closeSliderFunc = () => {
    document.getElementsByTagName('header')[0].style.zIndex = ''
    setIsOpenSlider(false)
  }

  const openSliderFunc = (index) => {
    dispatch(setIsOpenSidebar(false))
    document.getElementsByTagName('header')[0].style.zIndex = '0'

    setCurrentIndex(index)

    setTimeout(() => {
      setIsOpenSlider(true)
    }, '600')
  }

  const goToNext = () => {
		const total = dataGalleries?.length || 0;
		if (currentIndex === total - 1) {
			return setCurrentIndex(0);
		}
		setCurrentIndex(currentIndex + 1);
  };

  const goToPrevious = () => {
		const total = dataGalleries?.length || 0;
		if (currentIndex === 0) {
			return setCurrentIndex(total - 1);
		}
		setCurrentIndex(currentIndex - 1);
  };

  return {
    isOpenSlider,
    closeSliderFunc,
    openSliderFunc,
    setDataGalleriesSliderFunc,
    dataGalleries,
    currentIndex,
    goToNext,
    goToPrevious,
  }
}

export default useSlider
