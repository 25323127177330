import React from "react";

// Context

const initialState = {
  /**
   * cuando validamos que existe el telefono en la db
   */
  validCellphone: null,
  /**
   * la informacion recuperada del usuario
   */
  userRecoveredData: null,
  /**
   * los últimos casos recuperados de la db
   */
  latestCases: {
    data: null,
    status: "initial",
    status_message: "",
    isLoading: false,
  },
};

const CHANGE_VALIDATE_PHONE_STATUS = "CHANGE_VALIDATE_PHONE_STATUS";

const SET_LATEST_CASES = "SET_LATEST_CASES";

const reducer = (state, action) => {
  if (action.type === CHANGE_VALIDATE_PHONE_STATUS) {
    const { status } = action.payload;
    return {
      ...state,
      validCellphone: status,
    };
  } else if (action.type === SET_LATEST_CASES) {
    const { latestCases } = action.payload;
    return {
      ...state,
      latestCases,
    };
  } else {
    throw Error("Action does not exist");
  }
};

export const ContactContextValue = React.createContext({
  ...initialState,
  /**
   *
   * @param {boolean} status
   */
  changeValidatePhoneStatus: (status) => {},
  /**
   *
   * @param {Object} list
   */
  setLastestCases: (list) => {},
  // setUserRecoverData: ()=>{}
});

ContactContextValue.displayName = "ContactContextValue";

const ContactProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const setLastestCases = (latestCases) => {
    console.log("setLastestCases", latestCases);
    if (latestCases == null) throw Error("latestCases must be not null");
    // if (!("status" in latestCases)) {
    //   new Error("status must be not null");
    //   return;
    // }
    // if (!Array.isArray(latestCases?.data)) {
    //   new Error("latestCases data must be array type");
    //   return;
    // }
    console.log("exc");
    dispatch({
      type: SET_LATEST_CASES,
      payload: {
        latestCases,
      },
    });
  };

  const changeValidatePhoneStatus = (status) => {
    if (status == null) throw Error("status must be not null");
    if (typeof status != "boolean") {
      throw Error("status must be boolean type");
    }
    dispatch({
      type: CHANGE_VALIDATE_PHONE_STATUS,
      payload: {
        status,
      },
    });
  };

  return (
    <ContactContextValue.Provider
      value={{
        ...state,
        setLastestCases,
        changeValidatePhoneStatus,
      }}
    >
      {children}
    </ContactContextValue.Provider>
  );
};

export default ContactProvider;

// Context Provider
