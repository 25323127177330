import React from 'react';
import Layout from '../../../components/mui/Layout';
import HeaderComponent from '../../auth/components/HeaderComponent';
import FooterComponent from '../../auth/components/FooterComponent';
import '../../auth/Auth.scss';

const UnAuthorizationPage = () => {
	
	return (
		<Layout.Page title="Acceso no autorizado">
			<Layout.Card className="card form-container">
				<HeaderComponent />
				<Layout.CardContent className="m-0 pt-4 pr-4 pl-4 pb-0">
					<div className="row">
						<div className="col-sm-12 m-2">
							<h1 className="font-family-roboto-medium fs-1 text-danger mx-auto text-center">
								Usted no tiene permiso para esta solicitud
							</h1>
							<h6 className="font-family-roboto-regular fs-5 text-gray mx-auto text-center">
								Lo sentimos pero no pudimos encontrar la página que buscas
							</h6>
						</div>
					</div>
				</Layout.CardContent>
				<FooterComponent
					to="/dashboard/main"
					label="Volver al escritorio"
				/>
			</Layout.Card>
		</Layout.Page>
	);
};

export default UnAuthorizationPage;
