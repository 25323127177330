import Autocomplete from './Autocomplete'
import Button from './Button'
import ButtonSearch from './ButtonSearch'
import ButtonAction from './ButtonAction'
import ButtonSubmit from './ButtonSubmit'
import DatePicker from './DatePicker'
import Checkbox from './Checkbox'
import Select from './Select'
import SelectBuilding from './SelectBuilding'
import SelectMultiple from './SelectMultiple'
import Switch from './Switch'
import TextArea from './TextArea'
import TextField from './TextField'
import TextFieldPassword from './TextFieldPassword'
import TextFieldNumber from './TextFieldNumber'
import TextFieldSearch from './TextFieldSearch'
import TextSearch from './TextSearch'

const Controls = {
    Autocomplete,
    Button,
    ButtonSearch,
    ButtonAction,
    ButtonSubmit,
    Checkbox,
    DatePicker,
    Select,
    SelectBuilding,
    SelectMultiple,
    Switch,
    TextArea,
    TextField,
    TextFieldPassword,
    TextFieldNumber,
    TextFieldSearch,
    TextSearch
}

export default Controls
