import endPoints from './endPoints';
import axios from '../../../../../services/axios';

const {
	arrayList,
	arrayListToDropdown,
	store,
	show,
	update,
	status,
	destroy
} = endPoints;

/**
 *
 * @description Execute endpoint array list of realstates
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const getArrayList = async (data) => (
	await axios.post(arrayList, data)
);

/**
 *
 * @description Execute endpoint array list of realstates in dropdown
 * @method get
 * @return jsonResponse
 *
 */
export const getArrayListToDropdown = async () => (
	await axios.get(`${arrayListToDropdown}`)
);

/**
 *
 * @description Execute endpoint store
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const setStore = async (data) => {
	axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
	
	return await axios.post(store, data);
};

/**
 *
 * @description Execute endpoint show by id
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const getShowById = async (id) => (
	await axios.get(`${show}${id}`)
);

/**
 *
 * @description Execute endpoint update by id
 * @param id
 * @param data
 * @method put
 * @return jsonResponse
 *
 */
export const setUpdateById = async (id, data) => {
	axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
	
	return await axios.post(`${update}${id}?_method=PUT`, data);
};

/**
 *
 * @description Execute endpoint change status by id
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const setUpdateStatusById = async (id) => (
	await axios.get(`${status}${id}`)
);

/**
 *
 * @description Execute endpoint delete by id
 * @param id
 * @method delete
 * @return jsonResponse
 *
 */
export const setDeleteById = async (id) => (
	await axios.delete(`${destroy}${id}`)
);


