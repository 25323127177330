import { initialValuesBuilding as initialValues } from '../../../../../helpers/variablesInitialValues';
import * as types from '../types/buildingType';

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	search: '',
	realState: '',
	arrayList: [],
	arrayListToDropdown: [],
	refreshTable: false,
	isOpenModal: false,
	isOpenModalPermission: false,
	isLoadingMap: false,
	isLoadingShow: false,
	isLoadingForm: false,
	formType: '',
	formData: initialValues,
	formId: ''
};

const buildingReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_BUILDING:
			return {
				...state,
				perPage: payload
			};
		
		case types.PAGE_BUILDING:
			return {
				...state,
				page: payload
			};
		
		case types.PAGES_BUILDING:
			return {
				...state,
				pages: payload
			};
		
		case types.SEARCH_BUILDING:
			return {
				...state,
				search: payload
			};
			
		case types.REALSTATE_BUILDING:
			return {
				...state,
				realState: payload
			};
		
		case types.ARRAY_LIST_BUILDING:
			return {
				...state,
				arrayList: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_BUILDING:
			return {
				...state,
				arrayListToDropdown: payload
			};
		
		case types.REFRESH_TABLE_BUILDING:
			return {
				...state,
				refreshTable: payload
			};
		
		case types.IS_OPEN_MODAL_BUILDING:
			return {
				...state,
				isOpenModal: payload
			};
		
		case types.IS_LOADING_MAP_BUILDING:
			return {
				...state,
				isLoadingMap: payload
			};
			
		case types.IS_LOADING_SHOW_BUILDING:
			return {
				...state,
				isLoadingShow: payload
			};
		
		case types.IS_LOADING_FORM_BUILDING:
			return {
				...state,
				isLoadingForm: payload
			};
		
		case types.FORM_TYPE_BUILDING:
			return {
				...state,
				formType: payload
			};
		
		case types.FORM_DATA_BUILDING:
			return {
				...state,
				formData: payload
			};
			
		case types.FORM_ID_BUILDING:
			return {
				...state,
				formId: payload
			};
		
		default:
			return state
	}
};

export default buildingReducer;
