import * as types from '../types/commercialType';
import { initialValuesCommercialFilter as initialValues } from '../../../../../helpers/variablesInitialValues';

const initialState = {
	formFilter: initialValues,
	refreshTable: false,
};

const commercialReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.FORM_FILTER_COMMERCIAL:
			return {
				...state,
				formFilter: payload
			}

		case types.REFRESH_TABLE_COMMERCIAL:
			return {
				...state,
				refreshTable: payload
			}

		default:
			return state
	}
};

export default commercialReducer;
