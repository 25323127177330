import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../../../redux/store';
import Layout from '../../../../components/mui/Layout';
import Feedback from '../../../../components/mui/Feedback';
import SquarePropertyCard from '../../../../components/properties/cards/Properties';
import VerticalCardsLoading from '../../../../components/properties/VerticalCardsLoading';
import { getArrayListProperties } from '../services';
import { setPageProperties, setIsLoadingProperties, setArrayListProperties, setHasNextPageProperties, setPagesProperties } from '../redux/actions/ownerAction';
import usePermission from '../../../../hooks/usePermission';
import swalErrors from "../../../../hooks/useErrors";

const ListPropertyCards = () => {
  const dispatch = useDispatch();
  const { id } = useSelector(({ owner }) => owner.formData);
  const isLoading = useSelector(({ owner }) => owner.isLoadingPropertyOwner);
  const perPage = useSelector(({ owner }) => owner.perPagePropertyOwner);
  const page = useSelector(({ owner }) => owner.pagePropertyOwner);
  const hasNextPage = useSelector(({ owner }) => owner.hasNextPagePropertyOwner);
  const arrayList = useSelector(({ owner }) => owner.arrayListPropertyOwner);
  const permission = usePermission('owners.properties.list');
  
  /**
   *
   * @description Execute endpoint array list of owners properties
   * @return dispatch
   *
   */
  const handleArrayList = async (isPagination = false) => {
    if (permission) {
      await dispatch(setIsLoadingProperties(page === 1 ? true : false));
      
      try {
        const param = {
          perPage: perPage,
          page: page,
          owner_id: id
        };
        
        const { data } = await getArrayListProperties(param);
        
        if (data.status === 200) {
          await dispatch( setPagesProperties(data.data.pages) );
          await dispatch( setHasNextPageProperties(data.data.pages > page ? true : false) );
          
          if (isPagination) {
            await dispatch(setArrayListProperties(data.data.records.length === 0 ? [] : [...arrayList, ...data.data.records]));
          } else {
            await dispatch(setArrayListProperties(data.data.records.length === 0 ? [] : data.data.records));
          }
        }
      } catch (error) {
        await swalErrors(error);
      }
      
      await dispatch(setIsLoadingProperties(false));
    }
  };

  useEffect(() => {
    handleArrayList(page === 1 ? false : true);
  }, [page]);
  
  /**
   *
   * @description Execute dispatch pageProperties state
   * @return dispatch
   *
   */
  const handleNextPage = () => dispatch( setPageProperties(page + 1) );
  
  return (
    <Layout.Box
      id="scrollableDivProperties"
      gap={2}
      width="95%"
      margin="0 auto"
      overflow="scroll"
      height="700"
      gridTemplateColumns="repeat(auto-fill,minmax(300px,1fr))"
      className="mt-2 mb-2"
    >
      <Feedback.InfinityScrolling
        dataLength={arrayList.length}
        next={handleNextPage}
        hasMore={hasNextPage}
        loader={isLoading && <VerticalCardsLoading />}
        endMessage={<div className="mt-4 mb-4" />}
        scrollableTarget="scrollableDivProperties"
      >
        <Layout.Box
          gap={2}
          display="grid"
          margin="0 auto"
          gridTemplateColumns="repeat(auto-fill,minmax(300px,1fr))"
        >
          {!isLoading && arrayList.length === 0 && (
            <div className="font-family-roboto-regular fs-6 text-center">No hay datos para esta consulta</div>
          )}
          {!isLoading && arrayList.length > 0 && (
            arrayList.map((item,index) => {
              // VALIDATE THE PRICE ACCORDING TO THE SERVICE
              //let price = item?.action_type === 'Arriendo'
              //  ? item?.action_canon_string
              //  : item?.action_type === 'Compra o Venta'
              //  ? item?.action_sale_string
              //  : item?.action_type === 'Venta'
              //  ? item?.action_sale_string
              //  : item?.action_type === 'Arriendo/Venta'
              //  ? `${item?.action_canon_string} / ${item?.action_sale_string}`
              //  : ''
                {/* if(index === 0) console.log("ListPropertyCards",{item}) */}
      
              return (
                <SquarePropertyCard
                  key={item?.id}
                  goToDetail={() => history.push(`/dashboard/propiedades/detalle/${item?.id}`)}
                  showIconLike={false}
                  property={
                    item
                  //   {
                  //   type: `${item?.type} en ${item?.action_type}`,
                  //   price_format: price,
                  //   codpro: item?.domus_code,
                  //   images_count: item?.has_photo,
                  //   image1: item?.path,
                  //   neighborhood: item?.neighborhood,
                  //   city: item?.city,
                  //   area_lot: item?.area_total,
                  //   bathrooms: item?.number_bathroom,
                  //   bedrooms: item?.number_room,
                  //   parking: item?.number_garage
                  // }
                  }
                />
              )
            })
          )}
        </Layout.Box>
      </Feedback.InfinityScrolling>
    </Layout.Box>
  );
};

export default ListPropertyCards;
