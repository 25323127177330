import { Button } from '@mui/material'
import CollectionsIcon from '@mui/icons-material/Collections'
import styles from './gallerySlider.module.css'
import { useTaps } from '../../../../components/v2-mobili/gallery/hooks/useTaps'
const URL_REALSTATE = process.env.REACT_APP_FINANCAR_URL;

export function GallerySlider({ property }) {
  const { handleTap, isActive } = useTaps()

  const handleOpenDetail = (id) => {
    const url = `${URL_REALSTATE}/detalle/${id}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <div className={styles.gridContainerImg}>
        <img src={property?.path} />
        <Button
          onClick={() => handleOpenDetail(property.id)}
          sx={{ textTransform: 'none' }}
          variant="contained"
          startIcon={<CollectionsIcon />}
        >
          Abrir Detalle
        </Button>
      </div>
    </>
  )
}
