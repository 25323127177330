import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReactJwtDecode from 'jwt-decode';
import Inputs from '../../../../components/mui/Inputs';
import Layout from '../../../../components/mui/Layout';
import { setLogIn } from '../../services';
import { setIsLoading } from '../../../../redux/loading/actions/loadingAction';
import { setIsLoadingForm, setIsAuthenticated, setTokenType, setAccessToken, setExpiresAt, setUserData } from '../../redux/actions/authAction';
import swalErrors from '../../../../hooks/useErrors';
import { initialValuesLogin as initialValues } from '../../../../helpers/variablesInitialValues';
import avatar from '../../../../assets/images/avatar-default.jpg';

const FormComponent = () => {
	const dispatch = useDispatch();
	const isLoadingForm = useSelector( ({ auth }) => auth.isLoadingForm);
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
  */
	const validationSchema = () => (
		Yup.object({
			email: Yup.string()
			.email('El email no es válido')
			.required('El email es requerido'),
			password: Yup.string()
			.min(6, 'La contraseña debe contener al menos 6 caracteres')
			.required('La contraseña es requerida')
		})
	);
	
	/**
	 *
	 * @description Execute submit of the form
	 * @param values
	 * @param resetForm
	 * @param setValues
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		await dispatch( setIsLoadingForm(true) );
		await dispatch( setIsLoading(true) );
		
		try {
			const { data } = await setLogIn(values);
			
			if (data.status === 200) {
				const object = {
					firstName: data.data.user_data.first_name,
					lastName: data.data.user_data.last_name,
					email: data.data.user_data.email,
					realStateId: data.data.user_data.realstate_id,
					avatar: data.data.user_data.avatar === null ? avatar : data.data.user_data.avatar,
					permissions: data.data.user_data.permissions.length === 0 ? [] : data.data.user_data.permissions
				};
				
				const decode = ReactJwtDecode(data.data.access_token);
				
				if (decode) {
					object.roles = decode.roles;
				}
				
				resetForm({ values: initialValues});
				setValues(initialValues);
				await dispatch( setTokenType(data.data.token_type) );
				await dispatch( setAccessToken(data.data.access_token) );
				await dispatch( setExpiresAt(data.data.expires_at) );
				await dispatch( setUserData(object) );
				await dispatch( setIsAuthenticated(true) );
			}
		} catch (error) {
			await swalErrors(error);
		}
		
		await dispatch( setIsLoadingForm(false) );
		await dispatch( setIsLoading(false) );
	};
	
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
			  values,
			  errors,
			  touched,
			  handleChange,
			  handleBlur,
			  isValid,
			  dirty
		  }) => (
				<Form noValidate>
					<div className="row">
						<div className="col-sm-12 mb-2">
							<Inputs.TextBox
								type="email"
								name="email"
								label="Email"
								placeholder="Ingrese email..."
								value={values.email}
								onBlur={handleBlur}
								onChange={handleChange}
								hasError={errors.email && touched.email ? true : false}
								errorMessage={errors.email && touched.email ? errors.email : ''}
								className="font-family-roboto-medium fs-6 text-dark w-100"
							/>
						</div>
						<div className="col-sm-12 mb-3">
							<Inputs.TextBoxPassword
								name="password"
								label="Contraseña"
								placeholder="Ingrese contraseña..."
								value={values.password}
								onBlur={handleBlur}
								onChange={handleChange}
								hasError={errors.password && touched.password ? true : false}
								errorMessage={errors.password && touched.password ? errors.password : ''}
								className="font-family-roboto-medium fs-6 text-dark w-100 mt-3"
							/>
						</div>
						<div className="col-sm-12">
							<Inputs.LoadingButton
								isDisabled={!(dirty && isValid)}
								isLoading={isLoadingForm}
								isLoadingPosition="start"
								startIcon={<Layout.Icons.LockOpenIcon height={18} width={18} />}
								label="Iniciar sesión"
								type="submit"
								className={`${(dirty && isValid) ? 'bg-primary' : 'bg-grey-light'} font-family-roboto-regular fs-6 text-capitalize w-100`}
							/>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default FormComponent;
