import Swal from 'sweetalert2';
import { history } from '../redux/store';
import { initialValuesAuth } from '../helpers/variablesInitialValues';

export const swalSuccess = async (title, message) => (
	await Swal.fire({
		title: title,
		html: message,
		icon: 'success',
		allowOutsideClick: false,
		allowEscapeKey: false,
		showConfirmButton: true,
		confirmButtonColor: '#3085d6',
		confirmButtonText: 'Aceptar'
	})
);

export const swalError = async (message, status = null) => {
	if (status === 401) {
		await localStorage.clear();
		await localStorage.setItem('persist:root', JSON.stringify(initialValuesAuth));
		await localStorage.clear();
		await history.push('/');
	}
	
	return await Swal.fire({
		title: 'Error',
		html: message,
		icon: 'error',
		allowOutsideClick: false,
		allowEscapeKey: false,
		showConfirmButton: true,
		confirmButtonColor: '#3085d6',
		confirmButtonText: 'Aceptar'
	});
};

export const swalConfirm = async (message) => (
	await Swal.fire({
		title: '',
		html: message,
		icon: 'warning',
		allowOutsideClick: false,
		allowEscapeKey: false,
		showConfirmButton: true,
		confirmButtonColor: '#3085d6',
		confirmButtonText: 'Aceptar',
		showDenyButton: true,
		denyButtonText: 'Cancelar',
		reverseButtons: true
	}).then(({ isDenied }) => {
		if (isDenied) {
			return false
		}
	})
);
