import React from 'react'
import EditOutlined from '@material-ui/icons/EditOutlined'

export default function EditOutlinedIcon(props) {

    const { ...other } = props

    return (
        <EditOutlined { ...other } />
    )
}
