export const initialValuesTimezone = {
	timeZone: `${process.env.REACT_APP_TIMEZONE}`,
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	second: 'numeric'
};

export const initialValuesLogin = {
	email: '',
	password: ''
};

export const initialValuesForgotPassword = {
	email: ''
};

export const initialValuesPasswordReset = {
	password: '',
	passwordConfirm: ''
};

export const initialValuesAuth = {
	avatar: '',
	firstName: '',
	lastName: '',
	email: '',
	realStateId: '',
	realState: '',
	permissions: [],
	roles: []
};

export const initialValuesOwnerAuthorization = {
	status: '',
	createdDate: '',
	createdAt: '',
	mobiliCode: '',
	domusCode: '',
	address: '',
	reference: '',
	owner: '',
	mobile: '',
	email: ''
};

export const initialValuesOwnerInventory = {
	status: '',
	colorStatus: '',
	album: '',
	createdDate: '',
	createdAt: '',
	createdAgo: '',
	mobiliCode: '',
	domusCode: '',
	hasBuilding: '',
	city: '',
	neighborhood: '',
	address: '',
	reference: '',
	strata: '',
	numberRoom: '',
	numberBathRoom: '',
	garage: '',
	type: '',
	destination: '',
	action: ''
};

export const initialValuesPropertyDetail = {
	status: '',
	colorStatus: '',
	album: '',
	createdDate: '',
	createdAt: '',
	createdAgo: '',
	mobiliCode: '',
	domusCode: '',
	hasBuilding: '',
	city: '',
	neighborhood: '',
	address: '',
	reference: '',
	strata: '',
	numberRoom: '',
	numberBathRoom: '',
	garage: '',
	type: '',
	destination: '',
	action: ''
};

export const initialValuesFilteredAdviserForm = {
	id: '',
	service: '',
	numberCount: '',
	createdDate: '',
	createdAt: '',
	agent: '',
	adviser: '',
	customer: '',
	email: '',
	mobile: '',
	phone: '',
	originChannel: '',
	contact: '',
	ranking: '',
	requirement: '',
	cities: '',
	neighborhoods: '',
	types: '',
	stratas: '',
	estimation: '',
	price: '',
	rangeTotalLease: '',
	rangeTotalBuy: '',
	leaseDate: '',
	emptyDate: '',
	paymentMethod: '',
	totalArea: '',
	room: '',
	bathroom: '',
	parking: '',
	observation: '',
};

export const initialValuesFilteredCustomerForm = {
	id: '',
	firstName: '',
	lastName: '',
	email: '',
	mobile: '',
	phone: ''
};

export const initialValuesPermission = {
	id: '',
	name: '',
	publicName: '',
	description: '',
	group: ''
};

export const initialValuesRole = {
	id: '',
	name: '',
	permissions: []
};

export const initialValuesRealState = {
	id: '',
	name: '',
	email: '',
	numberId: '',
	brokerId: '',
	apiTokenDomus: '',
	idDomus: '',
	apiTokenPipeDrive: '',
	subDomainPipeDrive: '',
	color: '',
	logo: '',
	logoPreview: '',
	hasChangeLogo: false
};

export const initialValuesUser = {
	id: '',
	avatar: '',
	firstName: '',
	lastName: '',
	email: '',
	mobile: '',
	phone: '',
	address: '',
	password: '',
	passwordConfirm: '',
	realState: '',
	role: '',
	typeRole: '',
	permissions: []
};

export const initialValuesProfile = {
	avatar: '',
	firstName: '',
	lastName: '',
	email: '',
	mobile: '',
	phone: '',
	address: '',
	password: '',
	passwordConfirm: ''
};

export const initialValuesBank = {
	id: '',
	name: ''
};

export const initialValuesArea = {
	id: '',
	name: ''
};

export const initialValuesAmenity = {
	id: '',
	type: '',
	typeInventory: '',
	name: '',
	area: ''
};

export const initialValuesProject = {
	id: '',
	name: '',
	realState: ''
};

export const initialValuesKey = {
	id: '',
	name: ''
};

export const initialValuesCountry = {
	id: '',
	name: ''
};

export const initialValuesState = {
	id: '',
	country: '',
	name: ''
};

export const initialValuesCity = {
	id: '',
	country: '',
	state: '',
	name: ''
};

export const initialValuesNeighborhood = {
	id: '',
	country: '',
	state: '',
	city: '',
	name: '',
	locality: ''
};

export const initialValuesLocality = {
	id: '',
	name: ''
};

export const initialValuesOriginChannel = {
	id: '',
	name: ''
};

export const initialValuesContact = {
	id: '',
	name: ''
};

export const initialValuesCommercialStatus = {
	id: '',
	name: '',
	realState: ''
};


export const initialValuesBuildingType = {
	id: '',
	name: ''
};

export const initialValuesOwnerDomus = {
	personType: '',
	company: '',
	firstName: '',
	lastName: '',
	email: '',
	mobile: '',
	phone: '',
	address: '',
	stateId: '',
	state: '',
	cityId: '',
	city: ''
};

export const initialValuesOwnerMobili = {
	personType: '',
	company: '',
	firstName: '',
	lastName: '',
	email: '',
	mobile: '',
	phone: '',
	address: '',
	state: '',
	city: '',
};

export const initialValuesPropertyType = {
	id: '',
	name: ''
};

export const initialValuesPropertyDomus = {
	codeDomus: '',
	statusCommercialId: '',
	statusCommercialName: '',
	registerDate: '',
	registerAt: '',
	updateDate: '',
	updateAt: '',
	publicationDate: '',
	publicationAt: '',
	consignationDate: '',
	consignationAt: '',
	actionId: '',
	actionName: '',
	valueAdmin: '',
	canon: '',
	canonString: '',
	saleValue: '',
	saleValueString: '',
	commissionPercentage: '',
	stateId: '',
	stateName: '',
	cityId: '',
	cityName: '',
	neighborhoodId: '',
	neighborhoodName: '',
	zoneId: '',
	zoneName: '',
	address: '',
	reference: '',
	comment: '',
	latitude: '',
	longitude: '',
	numberRoom: '',
	numberBathroom: '',
	numberGarage: '',
	strata: '',
	enrollment: '',
	yearConstruction: '',
	areaConstruction: '',
	areaTotal: '',
	areaFront: '',
	areaBackground: '',
	typeId: '',
	typeName: '',
	destinationId: '',
	destinationName: '',
	amenitiesName: ''
};

export const initialValuesPropertyMobili = {
	id: '',
	codeDomus: '',
	statusCommercialName: '',
	registerDate: '',
	registerAt: '',
	updateDate: '',
	updateAt: '',
	publicationDate: '',
	publicationAt: '',
	consignationDate: '',
	consignationAt: '',
	updatedDate: '',
	updatedAgo: '',
	updatedAt: '',
	synchronizedDate: '',
	synchronizedAgo: '',
	synchronizedAt: '',
	actionName: '',
	valueAdmin: '',
	valueAdminString: '',
	canon: '',
	canonString: '',
	saleValue: '',
	saleValueString: '',
	commissionPercentage: '',
	stateName: '',
	cityName: '',
	neighborhoodName: '',
	zoneName: '',
	address: '',
	reference: '',
	comment: '',
	latitude: '',
	longitude: '',
	numberRoom: '',
	numberBathroom: '',
	numberGarage: '',
	strata: '',
	enrollment: '',
	yearConstruction: '',
	areaConstruction: '',
	areaTotal: '',
	areaFront: '',
	areaBackground: '',
	typeName: '',
	destinationName: '',
	amenitiesName: ''
};

export const initialValuesPropertyDestination = {
	id: '',
	name: ''
};

export const initialValuesPropertyFacade = {
	id: '',
	name: ''
};

export const initialValuesPropertyFloor = {
	id: '',
	name: ''
};

export const initialValuesPropertyReserve = {
	id: '',
	name: ''
};

export const initialValuesPropertyRoomService = {
	id: '',
	name: ''
};

export const initialValuesPropertyStatus = {
	id: '',
	name: ''
};

export const initialValuesPropertyStatusCommercial = {
	id: '',
	name: '',
	color: ''
};

export const initialValuesFilter = {
	realState: '',
	search: '',
	start: '',
	finish: '',
	agent: '',
	adviser: ''
};

export const initialValuesFiltered = {
	realState: '',
	mobile: '',
	firstName: '',
	lastName: '',
	originChannel: '',
	originChannelName: '',
	contact: '',
	contactName: '',
	occurrences: [],
	occurrencesTest: []
};

export const initialValuesFilteredDetail = {
	id: '',
	status: '',
	step: '',
	realState: '',
	createdDate: '',
	createdAt: '',
	createdAgo: '',
	customer: '',
	email: '',
	mobile: '',
	phone: '',
	originChannel: '',
	contact: '',
	service: 'Arriendo',
	searchCheck: false,
	code: '',
	arrayListCode: [],
	city: 1,
	neighborhoods: [],
	propertyTypes: [],
	strata: [],
	numberRoom: '',
	numberBathRoom: '',
	areaTotal: '',
	leaseDate: '',
	emptyDate: '',
	rangeTotalLease: '',
	rangeTotalBuy: '',
	price: '',
	estimation: '',
	paymentMethod: [],
	requirementCheck: false,
	parkingCheck: false,
	observation: '',
	ranking: '',
	adviser: '',
};

export const initialValuesCommercialFilter = {
	search: '',
	start: '',
	finish: ''
};

export const initialValuesBuilding = {
	id: '',
	realState: '',
	nit: '',
	company: '',
	type: '',
	strata: '',
	country: '',
	state: '',
	city: '',
	neighborhood: '',
	drop1: '',
	value1: '',
	text1: '',
	suffix1: '',
	drop2: '',
	value2: '',
	text2: '',
	value3: '',
	suffix2: '',
	address: '',
	reference: '',
	longitude: '-74.7889',
	latitude: '10.9878',
	yearConstruction: '',
	areaConstruction: '',
	areaTotal: '',
	adminId: '',
	adminName: '',
	adminPhone: '',
	area: '',
	amenities: [],
	bank: '',
	bankType: '',
	bankAccount: '',
};


export const initialValuesOwner = {
	id: '',
	personType: 'Natural',
	company: '',
	representativeFirstName: '',
	representativeLastName: '',
	representativeEmail: '',
	representativeMobile: '',
	firstName: '',
	lastName: '',
	docType: 'DNI',
	docId: '',
	email: '',
	mobile: '',
	phone: '',
	address: '',
	notes: '',
	acceptData: false,
	accountType: 'Ahorro',
	accountNumber: '',
	bank: '',
	state: '',
	city: '',
	originChannel: ''
};

export const initialValuesCommercialByStatus = {
	status: ''
};