import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Feedback from '../../../../components/mui/Feedback';
import DropdownSelect from '../../settings/commercial/CommercialStatusPage/components/DropdownSelect';
import { setUpdateStatusById } from '../services';
import swalErrors from '../../../../hooks/useErrors';
import usePermission from '../../../../hooks/usePermission';
import Inputs from '../../../../components/mui/Inputs';
import Layout from '../../../../components/mui/Layout';
import { initialValuesCommercialByStatus as initialValues } from '../../../../helpers/variablesInitialValues';
import { setRefreshTable } from '../redux/actions/commercialAction'

const KanbanModalComponent = ({ id, isOpenModal, handleIsOpenModal }) => {
  const dispatch = useDispatch();
  const { realStateId } = useSelector( ({ auth })=> auth.userData);
  const refreshTable = useSelector( ({ commercial })=> commercial.refreshTable);
  const [formData, setFormData] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const permission = usePermission('commercial.updateStatus');

  useEffect(() => {
  }, [id, isOpenModal]);

  /**
   *
   * @description Validation schema
   * @return object
   *
   */
  const validationSchema = () => (
    Yup.object({
      status: Yup.string()
        .required('El estatus es requerido')
    })
  );

  /**
   *
   * @description Execute submit of the form
   * @param values
   * @param resetForm
   * @param setValues
   * @return dispatch
   *
   */
  const hanbleSubmit = async (values, { resetForm, setValues }) => {
    if (isOpenModal && permission) {
      setIsLoading(true);

      try {
        const param = {
          status: formData.status
        };

        const { data } = await setUpdateStatusById(id, param);

        if (data.status === 200) {
          dispatch( setRefreshTable(!refreshTable) );
          resetForm({ values: initialValues});
          setValues(initialValues);
          setFormData(initialValues);
          handleCloseModal();
        }
      } catch (error) {
        await swalErrors(error);
      }

      setIsLoading(false);
    }
  };

  /**
   *
   * @description Execute dispatch formData state
   * @return void
   *
   */
  const handleOnChange = ({ target }) => setFormData( { ...formData, [target.name]: target.value });

  /**
   *
   * @description Execute close modal state
   * @return void
   *
   */
  const handleCloseModal = () => handleIsOpenModal();

  return (
    <Feedback.Modal
      maxWidth="sm"
      isOpen={isOpenModal}
      isClose={handleCloseModal}
      title="Cambiar Estatus"
    >
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={hanbleSubmit}
      >
        {({
          resetForm,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          touched
        }) => (
          <Form noValidate>
            <div className="row">
              <div className="col-sm-12">
                {isOpenModal && (
                  <DropdownSelect
                    realState={realStateId}
                    name="status"
                    value={formData.status}
                    handleChange={e => {
                      handleOnChange(e)
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    hasError={errors.status && touched.status ? true : false}
                    errorMessage={errors.status && touched.status ? errors.status : ''}
                    className="font-family-roboto-regular fs-6 text-dark w-100"
                  />
                )}
              </div>
              {permission && (
                <div className="col-sm-12">
                  <Inputs.LoadingButton
                    isDisabled={!(dirty && isValid)}
                    isLoading={isLoading}
                    isLoadingPosition="start"
                    startIcon={<Layout.Icons.SaveIcon height={18} width={18} />}
                    label="Guardar"
                    type="submit"
                    className={`bg-primary font-family-roboto-regular fs-6 text-capitalize mt-2 d-flex float-end`}
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Feedback.Modal>
  );
};

KanbanModalComponent.propTypes = {
  id: PropTypes.number.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  handleIsOpenModal: PropTypes.func.isRequired
};

export default KanbanModalComponent;
