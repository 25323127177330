import * as types from '../../actionsTypes/owners/ownerInventoryTypes'
import { initialValuesOwnerInventory as initialValues } from '../../../helpers/variablesInitialValues'

const initialState = {
	perPage: 20,
	page: 1,
	pages: 1,
	isLoadingTable: false,
	arrayListAlbumsTypes: [],
	arrayListAlbumsTypesFiles: [],
	formData: initialValues
}

const ownerInventoryReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_OWNER_INVENTORY:
			return {
				...state,
				perPage: payload
			}
		
		case types.PAGE_OWNER_INVENTORY:
			return {
				...state,
				page: payload
			}
		
		case types.PAGES_OWNER_INVENTORY:
			return {
				...state,
				pages: payload
			}
			
		case types.IS_LOADING_TABLE_OWNER_INVENTORY:
			return {
				...state,
				isLoadingTable: payload
			}
		
		case types.ARRAY_LIST_ALBUMS_TYPES_OWNER_INVENTORY:
			return {
				...state,
				arrayListAlbumsTypes: payload
			}
			
		case types.ARRAY_LIST_ALBUMS_TYPES_FILES_OWNER_INVENTORY:
			return {
				...state,
				arrayListAlbumsTypesFiles: payload
			}
		
		case types.FORM_DATA_OWNER_INVENTORY:
			return {
				...state,
				formData: payload
			}
		
		default:
			return state
	}
}

export default ownerInventoryReducer
