import { useFormikContext, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FieldErrors from "../../../../components/v2-mobili/errors/FieldErrors";

const cityOptions = [
  ...Array(10)
    .fill()
    .map((_, index) => `City ${index + 1}`),
];
const CityField = ({ readOnly = false }) => {
  const { getFieldProps, errors, touched } = useFormikContext();
  return (
    <Grid container flexDirection="column" item>
      <TextField
        select
        error={errors.city && touched.city}
        size="small"
        label="Ciudad"
        InputProps={{
          readOnly: readOnly,
        }}
        {...getFieldProps("city")}
      >
        {cityOptions.map((option, key) => (
          <MenuItem key={key} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <FieldErrors>
        <ErrorMessage name="city" />
      </FieldErrors>
    </Grid>
  );
};

export default CityField;
