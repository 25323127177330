export const PER_PAGE_PROPERTY_FLOOR = 'PER_PAGE_PROPERTY_FLOOR';
export const PAGE_PROPERTY_FLOOR = 'PAGE_PROPERTY_FLOOR';
export const SEARCH_PROPERTY_FLOOR = 'SEARCH_PROPERTY_FLOOR';
export const PAGES_PROPERTY_FLOOR = 'PAGES_PROPERTY_FLOOR';
export const ARRAY_LIST_PROPERTY_FLOOR = 'ARRAY_LIST_PROPERTY_FLOOR';
export const ARRAY_LIST_TO_DROPDOWN_PROPERTY_FLOOR = 'ARRAY_LIST_TO_DROPDOWN_PROPERTY_FLOOR';
export const REFRESH_TABLE_PROPERTY_FLOOR = 'REFRESH_TABLE_PROPERTY_FLOOR';
export const IS_OPEN_MODAL_PROPERTY_FLOOR = 'IS_OPEN_MODAL_PROPERTY_FLOOR';
export const IS_LOADING_SHOW_PROPERTY_FLOOR = 'IS_LOADING_SHOW_PROPERTY_FLOOR';
export const IS_LOADING_FORM_PROPERTY_FLOOR = 'IS_LOADING_FORM_PROPERTY_FLOOR';
export const FORM_TYPE_PROPERTY_FLOOR = 'FORM_TYPE_PROPERTY_FLOOR';
export const FORM_DATA_PROPERTY_FLOOR = 'FORM_DATA_PROPERTY_FLOOR';
