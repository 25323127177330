const ROOT = 'dashboard/filtered'

export default {
	arrayList: `${ROOT}/list`,
	arrayListFavorites: `${ROOT}/favorites/list/`,
	arrayListLogs: `${ROOT}logs/list`,
	verification: `${ROOT}/verification/`,
	store: `${ROOT}`,
	show: `${ROOT}/`,
	preview: `${ROOT}/preview/`,
	update: `${ROOT}/`,
	updateAdviser: `${ROOT}/adviser/`,
	storeFavorite: `${ROOT}/favorites`,
	deleteFavorite: `${ROOT}/favorites/`,
	showCustomer: `${ROOT}/customers/`,
	updateCustomer: `${ROOT}/customers/`,
}
