import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { history } from '../../../../redux/store'
import Layout from '../../../../components/mui/Layout'
import Inputs from '../../../../components/mui/Inputs'
import SearchComponent from '../components/SearchComponent'
import { setIsLoading } from '../../../../redux/actions/loadingAction'
import { strataArray, actionsSwitch } from '../../../../helpers/variablesColumns'
import { Permission } from '../../../../services/permissions/Permission'
import errorServices from '../../../../services/errors'
import { swalSuccess } from '../../../../services/sweetalert'
import axios from '../../../../services/axios'

const PropertyTypeOldPage = () => {
	const params = useParams()
	const uID = params.uID
	const dispatch = useDispatch()
	const { permissions } = useSelector( state  => state.auth.userData)
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [isLoading, setLoadingForm] = useState(false)
	const [arrayPropertyType, setPropertyType] = useState([])
	const [arrayPropertyDestination, setPropertyDestination] = useState([])
	const [arrayPropertyStatus, setPropertyStatus] = useState([])
	const [arrayPropertyFacade, setPropertyFacade] = useState([])
	const [formData, setFormData] = useState({
		type_id: '',
		destination_id: '',
		status_id: '',
		facade_id: '',
		strata: '',
		furnished: 'No',
		enrollment: '',
		year_construction: '',
		area_construction: '',
		area_total: '',
		front: '',
		background: '',
		ranking: 0,
		characteristic: '',
		defect: ''
	})
	const [module, setModule] = useState({
		step: 4,
		title: 'Tipo de Propiedad'
	})
	
	const validationSchema = () => (
		Yup.object({
			type_id: Yup.string()
			.required('El tipo de propiedad es requerido'),
			destination_id: Yup.string()
			.required('La destinación es requerida'),
			status_id: Yup.string()
			.required('El estado de la vivienda es requerido'),
			facade_id: Yup.string()
			.required('El estado de la fachada es requerido'),
			strata: Yup.string()
			.required('El estrato es requerido'),
			furnished: Yup.string()
			.required('El amoblado es requerido')
		})
	)
	
	const handleEditProperty = async () => {
		dispatch( setIsLoading(true) )
		
		await handleDropdownPropertyType()
		await handleDropdownPropertyDestination()
		await handleDropdownPropertyStatus()
		await handleDropdownPropertyFacade()
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/${uID}`)
			
			if (data.status === 200) {
				const response = data.data.data
				if (response.has_building === 'Si') {
					setModule({...module, step: 3 })
				}
				
				setFormData({
					type_id: response.type_id === null ? '' : response.type_id,
					destination_id: response.destination_id === null ? '' : response.destination_id,
					status_id: response.status_id === null ? '' : response.status_id,
					facade_id: response.facade_id === null ? '' : response.facade_id,
					strata: response.strata === null ? '' : response.strata,
					furnished: response.furnished === null ? 'No' : (response.furnished === 'Si' ? 'Si' : 'No'),
					enrollment: response.enrollment === null ? '' : response.enrollment,
					year_construction: response.year_construction === null ? '' : response.year_construction,
					area_construction: response.area_construction === null ? '' : response.area_construction,
					area_total: response.area_total === null ? '' : response.area_total,
					front: response.front === null ? '' : response.front,
					background: response.background === null ? '' : response.background,
					ranking: response.ranking === null ? 0 : response.ranking,
					characteristic: response.characteristic === null ? '' : response.characteristic,
					defect: response.defect === null ? '' : response.defect
				})
			}
		} catch (error) {
			await errorServices(error)
		}
		
		dispatch( setIsLoading(false) )
	}
	
	useEffect(() => {
		handleEditProperty()
	}, [])
	
	const handleDropdownPropertyType = async () => {
		if (permissions.find((item) => item === 'propertyTypes.dropdown')) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const data = await axios.get(`dashboard/settings/properties/types`)
				
				if (data.status === 200) {
					setPropertyType(data.data.data.records)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	const handleDropdownPropertyDestination = async () => {
		if (permissions.find((item) => item === 'propertyDestinations.dropdown')) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const data = await axios.get(`dashboard/settings/properties/destinations`)
				
				if (data.status === 200) {
					setPropertyDestination(data.data.data.records)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	const handleDropdownPropertyStatus = async () => {
		if (permissions.find((item) => item === 'propertyStatus.dropdown')) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const data = await axios.get(`dashboard/settings/properties/status`)
				
				if (data.status === 200) {
					setPropertyStatus(data.data.data.records)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	const handleDropdownPropertyFacade = async () => {
		if (permissions.find((item) => item === 'propertyFacades.dropdown')) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const data = await axios.get(`dashboard/settings/properties/facades`)
				
				if (data.status === 200) {
					setPropertyFacade(data.data.data.records)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	const hanbleSubmit = async () => {
		setLoadingForm(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				type_id: formData.type_id === '' ? null : formData.type_id,
				destination_id: formData.destination_id === '' ? null : formData.destination_id,
				status_id: formData.status_id === '' ? null : formData.status_id,
				facade_id: formData.facade_id === '' ? null : formData.facade_id,
				strata: formData.strata === '' ? null : formData.strata,
				furnished: formData.furnished === '' ? null : formData.furnished,
				enrollment: formData.enrollment === '' ? null : formData.enrollment,
				year_construction: formData.year_construction === '' ? null : formData.year_construction,
				area_construction: formData.area_construction === '' ? null : formData.area_construction,
				area_total: formData.area_total === '' ? null : formData.area_total,
				front: formData.front === '' ? null : formData.front,
				background: formData.background === '' ? null : formData.background,
				ranking: formData.ranking === 0 ? null : formData.ranking,
				characteristic: formData.characteristic === '' ? null : formData.characteristic,
				defect: formData.defect === '' ? null : formData.defect,
			}
			
			const data = await axios.put(`dashboard/properties/property-type/${uID}`, param)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				await history.push(`/dashboard/propiedades/areas/${uID}`)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoadingForm(false)
	}
	
	const handleOnChange = ({ target }) => setFormData({...formData, [target.name]: target.value})
	
	return (
		<Layout.Page title="Propiedades - Tipos">
			<SearchComponent
				module={module}
				back={`/dashboard/propiedades/datos-generales/${uID}`}
				next={`/dashboard/propiedades/areas/${uID}`}
			/>
			<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
				<Layout.CardContent className="m-0 p-4">
					<Formik
						initialValues={formData}
						validationSchema={validationSchema}
						onSubmit={() => {}}
					>
						{({
						  errors,
						  touched,
						  handleChange,
						  handleBlur,
						  isValid
					  }) => (
							<Form noValidate>
								<div className="row">
									<Permission permission="propertyTypes.dropdown">
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<Inputs.Select
												size="small"
												label="Tipo de propiedad"
												name="type_id"
												value={formData.type_id}
												itemsArray={arrayPropertyType}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.type_id && touched.type_id ? true : false}
												errorMessage={errors.type_id && touched.type_id ? errors.type_id : ''}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
									</Permission>
									<Permission permission="propertyDestinations.dropdown">
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<Inputs.Select
												size="small"
												label="Destinación"
												name="destination_id"
												value={formData.destination_id}
												itemsArray={arrayPropertyDestination}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.destination_id && touched.destination_id ? true : false}
												errorMessage={errors.destination_id && touched.destination_id ? errors.destination_id : ''}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
									</Permission>
									<Permission permission="propertyStatus.dropdown">
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<Inputs.Select
												size="small"
												label="Estado de la Vivienda"
												name="status_id"
												value={formData.status_id}
												itemsArray={arrayPropertyStatus}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.status_id && touched.status_id ? true : false}
												errorMessage={errors.status_id && touched.status_id ? errors.status_id : ''}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
									</Permission>
									<Permission permission="propertyFacades.dropdown">
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<Inputs.Select
												size="small"
												label="Estado de la Fachada"
												name="facade_id"
												value={formData.facade_id}
												itemsArray={arrayPropertyFacade}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.facade_id && touched.facade_id ? true : false}
												errorMessage={errors.facade_id && touched.facade_id ? errors.facade_id : ''}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
									</Permission>
									<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
										<Inputs.Select
											size="small"
											label="Estrato"
											name="strata"
											value={formData.strata}
											itemsArray={strataArray}
											onChange={(e) => {
												handleOnChange(e)
												handleChange(e)
											}}
											onBlur={handleBlur}
											hasError={errors.strata && touched.strata ? true : false}
											errorMessage={errors.strata && touched.strata ? errors.strata : ''}
											className="font-family-roboto-regular fs-6 text-dark w-100"
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
										<Inputs.TextBox
											type="text"
											variant="outlined"
											size="small"
											name="enrollment"
											label="Matrícula"
											placeholder="Ingrese matrícula..."
											hasLabelFixed={true}
											value={formData.enrollment}
											onChange={handleOnChange}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
										<Inputs.TextBox
											type="number"
											variant="outlined"
											size="small"
											name="year_construction"
											label="Año de Construcción"
											placeholder="Ingrese año de construcción..."
											hasLabelFixed={true}
											value={formData.year_construction}
											onChange={handleOnChange}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
										<Inputs.TextBox
											type="number"
											variant="outlined"
											size="small"
											name="area_construction"
											label="Área de Construcción"
											placeholder="Ingrese área de construcción..."
											hasLabelFixed={true}
											value={formData.area_construction}
											onChange={handleOnChange}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
										<Inputs.TextBox
											type="number"
											variant="outlined"
											size="small"
											name="area_total"
											label="Área o Lote Tolal(m2)"
											placeholder="Ingrese área o lote tolal(m2)..."
											hasLabelFixed={true}
											value={formData.area_total}
											onChange={handleOnChange}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
										<Inputs.TextBox
											type="number"
											variant="outlined"
											size="small"
											name="front"
											label="Frente(m2)"
											placeholder="Ingrese frente(m2)..."
											hasLabelFixed={true}
											value={formData.front}
											onChange={handleOnChange}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
										<Inputs.TextBox
											type="number"
											variant="outlined"
											size="small"
											name="background"
											label="Fondo(m2)"
											placeholder="Ingrese Fondo(m2)..."
											hasLabelFixed={true}
											value={formData.background}
											onChange={handleOnChange}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
										<Inputs.Rating
											size="large"
											name="ranking"
											hasLabel={true}
											label="Calificación General"
											value={formData.ranking}
											max={5}
											onChange={handleOnChange}
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 mb-3">
										<Inputs.TextArea
											variant="outlined"
											size="small"
											name="characteristic"
											label="Mejor Característica"
											placeholder="Ingrese mejor característica..."
											hasLabelFixed={true}
											value={formData.characteristic}
											onChange={handleOnChange}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 mb-3">
										<Inputs.TextArea
											variant="outlined"
											size="small"
											name="defect"
											label="Defecto"
											placeholder="Ingrese defecto..."
											hasLabelFixed={true}
											value={formData.defect}
											onChange={handleOnChange}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
									<div className="col-sm-12 mb-3">
										<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
											¿Se encuentra amoblado?
										</div>
										<Inputs.ButtonGroup
											variant="outlined"
											size="small"
											name="furnished"
											value={formData.furnished === 'Si' ? 'No' : 'Si'}
											itemsArray={actionsSwitch}
											className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
											condition={formData.furnished}
											onClick={handleOnChange}
										/>
									</div>
									<div className="col-sm-12">
										<Inputs.LoadingButton
											variant="outlined"
											isLoading={isLoading}
											isLoadingPosition="start"
											startIcon={<Layout.Icons.SaveIcon height={18} width={18}/>}
											label="Guardar"
											type="button"
											onClick={hanbleSubmit}
											className={`${isValid && 'bg-primary'} font-family-roboto-medium fs-6 text-inherit`}
										/>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</Layout.CardContent>
			</Layout.Card>
		</Layout.Page>
	)
}

export default PropertyTypeOldPage
