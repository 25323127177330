import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import MainPage from '../pages/dashboard/MainPage';
import PermissionPage from '../pages/dashboard/settings/PermissionPage';
import RolePage from '../pages/dashboard/settings/RolePage';
import RealStatePage from '../pages/dashboard/settings/RealStatePage';
import UserPage from '../pages/dashboard/settings/UserPage';
import BankPage from '../pages/dashboard/settings/BankPage';
import AreaPage from '../pages/dashboard/settings/AreaPage';
import AmenityPage from '../pages/dashboard/settings/AmenityPage';
import ProjectPage from '../pages/dashboard/settings/ProjectPage';
import KeyPage from '../pages/dashboard/settings/KeyPage';
import CountryPage from '../pages/dashboard/settings/location/CountryPage';
import StatePage from '../pages/dashboard/settings/location/StatePage';
import CityPage from '../pages/dashboard/settings/location/CityPage';
import NeighborhoodPage from '../pages/dashboard/settings/location/NeighborhoodPage';
import LocalityPage from '../pages/dashboard/settings/location/LocalityPage';
import OriginChannelPage from '../pages/dashboard/settings/filtered/OriginChannelPage';
import ContactPage from '../pages/dashboard/settings/filtered/ContactPage';
import CommercialStatusPage from '../pages/dashboard/settings/commercial/CommercialStatusPage';
import BuildingTypePage from '../pages/dashboard/settings/buildings/BuildingTypePage';
import OwnerDomusPage from '../pages/dashboard/settings/owners/OwnerDomusPage';
import PropertyDomusPage from '../pages/dashboard/settings/properties/PropertyDomusPage';
import PropertyDestinationPage from '../pages/dashboard/settings/properties/PropertyDestinationPage';
import PropertyFacadePage from '../pages/dashboard/settings/properties/PropertyFacadePage';
import PropertyFloorPage from '../pages/dashboard/settings/properties/PropertyFloorPage';
import PropertyReservePage from '../pages/dashboard/settings/properties/PropertyReservePage';
import PropertyRoomServicePage from '../pages/dashboard/settings/properties/PropertyRoomServicePage';
import PropertyStatusPage from '../pages/dashboard/settings/properties/PropertyStatusPage';
import PropertyStatusCommercialPage from '../pages/dashboard/settings/properties/PropertyStatusCommercialPage';
import PropertyTypePage from '../pages/dashboard/settings/properties/PropertyTypePage';
import FilteredPage from '../pages/dashboard/filtered/FilteredPage';
import FilteredDetailPage from '../pages/dashboard/filtered/FilteredDetailPage';
import FilteredPreviewPage from '../pages/dashboard/filtered/FilteredPreviewPage';
import FilteredStatisticPage from '../pages/dashboard/filtered/FilteredStatisticPage';
import CommercialPage from '../pages/dashboard/CommercialPage';
import OwnerPage from '../pages/dashboard/OwnerPage';
import BuildingPage from '../pages/dashboard/BuildingPage';
// PROPERTY OLD DESIGN
import PropertyOldPage from '../pages/dashboard/properties/PropertyOldPage';
import PropertyBuildingOldPage from '../pages/dashboard/properties/PropertyBuildingOldPage';
import PropertyBuildingShowOldPage from '../pages/dashboard/properties/PropertyBuildingShowOldPage';
import PropertyLocationOldPage from '../pages/dashboard/properties/PropertyLocationOldPage';
import PropertyGeneralDataOldPage from '../pages/dashboard/properties/PropertyGeneralDataOldPage';
import PropertyTypeOldPage from '../pages/dashboard/properties/PropertyTypeOldPage';
import PropertyAreaOldPage from '../pages/dashboard/properties/PropertyAreaOldPage';
import PropertyActionOldPage from '../pages/dashboard/properties/PropertyActionOldPage';
import PropertyConditionOldPage from '../pages/dashboard/properties/PropertyConditionOldPage';
import PropertyServiceOldPage from '../pages/dashboard/properties/PropertyServiceOldPage';
import PropertyFileOldPage from '../pages/dashboard/properties/PropertyFileOldPage';
import PropertyStatusOldPage from '../pages/dashboard/properties/PropertyStatusOldPage';
// NEW PROPERTY
import PropertyDetailPage from '../pages/dashboard/properties/PropertyDetailPage';
import UnAuthorizationPage from '../pages/errors/UnAuthorizationPage';
import PermissionRoutes from '../services/permissions/PermissionRoutes';

import ContactPage2 from '../pages/dashboard/ContactPage';
import TestPage from '../pages/dashboard/TestPage';

const ProtectedRoutes = () => {
  const routes = [
    {
      permission: 'main.page',
      index: true,
      path: 'main',
      element: <MainPage />,
    },
    {
      permission: 'permissions.page',
      index: false,
      path: 'ajustes/permisos',
      element: <PermissionPage />,
    },
    {
      permission: 'roles.page',
      index: false,
      path: 'ajustes/roles',
      element: <RolePage />,
    },
    {
      permission: 'realstates.page',
      index: false,
      path: 'ajustes/inmobiliarias',
      element: <RealStatePage />,
    },
    {
      permission: 'users.page',
      index: false,
      path: 'ajustes/usuarios',
      element: <UserPage />,
    },
    {
      permission: 'banks.page',
      index: false,
      path: 'ajustes/bancos',
      element: <BankPage />,
    },
    {
      permission: 'areas.page',
      index: false,
      path: 'ajustes/areas',
      element: <AreaPage />,
    },
    {
      permission: 'amenities.page',
      index: false,
      path: 'ajustes/amenidades',
      element: <AmenityPage />,
    },
    {
      permission: 'projects.page',
      index: false,
      path: 'ajustes/proyectos',
      element: <ProjectPage />,
    },
    {
      permission: 'keys.page',
      index: false,
      path: 'ajustes/llaves',
      element: <KeyPage />,
    },
    {
      permission: 'countries.page',
      index: false,
      path: 'ajustes/ubicacion/paises',
      element: <CountryPage />,
    },
    {
      permission: 'states.page',
      index: false,
      path: 'ajustes/ubicacion/departamentos',
      element: <StatePage />,
    },
    {
      permission: 'cities.page',
      index: false,
      path: 'ajustes/ubicacion/ciudades',
      element: <CityPage />,
    },
    {
      permission: 'neighborhoods.page',
      index: false,
      path: 'ajustes/ubicacion/barrios',
      element: <NeighborhoodPage />,
    },
    {
      permission: 'localities.page',
      index: false,
      path: 'ajustes/ubicacion/localidades',
      element: <LocalityPage />,
    },
    {
      permission: 'originChannels.page',
      index: false,
      path: 'ajustes/filtrado/canal-de-origen',
      element: <OriginChannelPage />,
    },
    {
      permission: 'contacts.page',
      index: false,
      path: 'ajustes/filtrado/fuente-de-contacto',
      element: <ContactPage />,
    },
    {
      permission: 'commercialStatus.page',
      index: false,
      path: 'ajustes/comercial/estatus',
      element: <CommercialStatusPage />,
    },
    {
      permission: 'buildingTypes.page',
      index: false,
      path: 'ajustes/edificaciones/tipos',
      element: <BuildingTypePage />,
    },
    {
      permission: 'domus.owners.page',
      index: false,
      path: 'ajustes/propietarios/consulta-domus',
      element: <OwnerDomusPage />,
    },
    {
      permission: 'domus.page',
      index: false,
      path: 'ajustes/propiedades/consulta-domus',
      element: <PropertyDomusPage />,
    },
    {
      permission: 'propertyDestinations.page',
      index: false,
      path: 'ajustes/propiedades/destinaciones',
      element: <PropertyDestinationPage />,
    },
    {
      permission: 'propertyFacades.page',
      index: false,
      path: 'ajustes/propiedades/fachadas',
      element: <PropertyFacadePage />,
    },
    {
      permission: 'propertyFloors.page',
      index: false,
      path: 'ajustes/propiedades/pisos',
      element: <PropertyFloorPage />,
    },
    {
      permission: 'propertyReserves.page',
      index: false,
      path: 'ajustes/propiedades/reservas',
      element: <PropertyReservePage />,
    },
    {
      permission: 'propertyRoomServices.page',
      index: false,
      path: 'ajustes/propiedades/cuarto-de-servicio',
      element: <PropertyRoomServicePage />,
    },
    {
      permission: 'propertyStatus.page',
      index: false,
      path: 'ajustes/propiedades/estatus',
      element: <PropertyStatusPage />,
    },
    {
      permission: 'propertyStatusCommercial.page',
      index: false,
      path: 'ajustes/propiedades/estatus-comercial',
      element: <PropertyStatusCommercialPage />,
    },
    {
      permission: 'propertyTypes.page',
      index: false,
      path: 'ajustes/propiedades/tipos',
      element: <PropertyTypePage />,
    },
    {
      permission: 'filtereds.register.page',
      index: false,
      path: 'filtrado/registro-de-casos',
      element: <FilteredPage />,
    },
    {
      permission: 'filtereds.detail.page',
      index: false,
      path: 'filtrado/registro-de-casos/detalle/:uID',
      element: <FilteredDetailPage />,
    },
    {
      permission: 'filtereds.preview.page',
      index: false,
      path: 'filtrado/registro-de-casos/vista-previa/:uID',
      element: <FilteredPreviewPage />,
    },
    {
      permission: 'filtereds.preview.page',
      index: false,
      path: 'filtrado/comercial/:uID',
      element: <FilteredPreviewPage />,
    },
    {
      permission: 'filtereds.detail.page',
      index: false,
      path: 'filtrado/comercial/detalle/:uID',
      element: <FilteredDetailPage isCommercial />,
    },
    {
      permission: 'filtereds.statistic.page',
      index: false,
      path: 'filtrado/estadisticas',
      element: <FilteredStatisticPage />,
    },
    {
      permission: 'commercial.page',
      index: false,
      path: 'comercial',
      element: <CommercialPage />,
    },
    {
      permission: 'owners.page',
      index: false,
      path: 'propietarios',
      element: <OwnerPage />,
    },
    {
      permission: 'buildings.page',
      index: false,
      path: 'edificaciones',
      element: <BuildingPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades',
      element: <PropertyOldPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades/edificacion',
      element: <PropertyBuildingOldPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades/edificacion/:uID',
      element: <PropertyBuildingShowOldPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades/localizacion/:uID',
      element: <PropertyLocationOldPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades/datos-generales/:uID',
      element: <PropertyGeneralDataOldPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades/tipos/:uID',
      element: <PropertyTypeOldPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades/areas/:uID',
      element: <PropertyAreaOldPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades/acciones/:uID',
      element: <PropertyActionOldPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades/condiciones/:uID',
      element: <PropertyConditionOldPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades/servicios/:uID',
      element: <PropertyServiceOldPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades/archivos/:uID',
      element: <PropertyFileOldPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades/estatus-comercial/:uID',
      element: <PropertyStatusOldPage />,
    },
    {
      permission: 'properties.page',
      index: false,
      path: 'propiedades/detalle/:uID',
      element: <PropertyDetailPage isPropertyDetail={true} />,
    },
    // {
    //   permission: 'properties.page',
    //   index: false,
    //   path: 'inventory-fiche/:uID',
    //   element: <PropertyDetailPage isInventoryInternal={true} />,
    // },
    // {
    //   permission: 'filtereds.register.page',
    //   index: false,
    //   path: 'nuevo-contrato',
    //   element: <ContactPage2 />,
    // },
    {
      permission: 'main.page',
      index: false,
      path: 'test',
      element: <TestPage />,
    },
  ];

  return (
    <Routes>
      {routes.length > 0 &&
        routes.map(({ permission, index, path, element }, number) => (
          <Fragment key={number}>
            <Route
              index={index}
              path={path}
              element={PermissionRoutes(permission) ? element : <UnAuthorizationPage />}
            />
          </Fragment>
        ))}
    </Routes>
  );
};

export default ProtectedRoutes;
