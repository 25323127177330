import React, { useState } from 'react'
import MuiCheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const CheckBox = ({ label = '', name, position, value, size = 'medium', ...more }) => {
	const [check, setCheck] = useState(false)
	
	const handleChange = () => setCheck(!check)
	
	return (
		<FormControlLabel
			control={<MuiCheckbox color="primary" name={name} checked={check} size={size} onClick={handleChange} {...more} />}
			label={label}
			labelPlacement={position}
			value={value}
		/>
	)
}

export default CheckBox
