import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Display from "../display/Display"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100% !important',
        '& .MuiFormLabel-root.Mui-focused': {
            fontWeight: '500',
            color: '#1b1b1b',
            fontSize: '18px'
        },
        '& .MuiFormLabel-root.Mui-focused.Mui-error': {
            fontWeight: '500',
            color: '#f44336',
            fontSize: '18px'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #1b1b1b !important'
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottom: '2px solid #f44336 !important'
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: '2px solid #1b1b1b !important'
        }
    }
}))

export default function TextFieldPassword(props) {
    const classes = useStyles()
    const { label, name, variant, defaultValue, error, message, ...other } = props
    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    return (
        <TextField
            label={label}
            name={name}
            variant={variant || "standard"}
            value={defaultValue || ""}
            defaultValue={defaultValue || ""}
            error={error || false}
            helperText={message || ""}
            classes={{ root : classes.root }}
            type={showPassword ? 'text' : 'password'}
            InputProps={
                {
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            onClick={handleClickShowPassword}
                        >
                            {
                                showPassword ? 
                                    <Display.Icons.VisibilityIcon /> 
                                : 
                                    <Display.Icons.VisibilityOffIcon />
                            }
                        </InputAdornment>
                    )
                }
            }
            {...other}
        />
    )
}