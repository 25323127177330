import React from 'react'
import FavoriteBorder from "@material-ui/icons/FavoriteBorder"

export default function FavoriteBorderIcon(props) {

    const { ...other } = props

    return (
        <FavoriteBorder {...other} />
    )
}
