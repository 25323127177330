import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Layout from '../../../components/mui/Layout'
import Feedback from '../../../components/mui/Feedback'
import { PropertyDetails } from '../views/inventoryPage/components/propertyDetails'
import { FormFicha } from '../views/inventoryPage/components/formFicha'
import { Footer, Header } from '../../../components/v2-mobili'
import ThemeProviderComponent from '../../../theme-v2/themeProvider'
import ContentComponent from './components/ContentComponent'
import { showById } from '../../../redux/actions/owners/authorization/ownerAthorizationAction'
import styles from '../views/inventoryPage/inventory.module.css'

const AuthorizationPage = () => {
	const dispatch = useDispatch()
	const isLoading = useSelector(state => state.loading.isLoading)
	const formData = useSelector(state => state.ownerAuthorization.formData)
	const params = useParams()
	const uID = params.uID
	
	/**
	 *
	 * @description Execute endpoint show by id property authorization
	 * @return dispatch
	 *
	 */
	const handleShowById = async () => await dispatch( showById(uID) )
	
	useEffect(() => {
		handleShowById()
	}, [uID])
	
	return (
		<>
			<Layout.Page title="Autorización del Propietario">
				<Feedback.ScrollBar style={{ height: 'calc(100vh - 15px)', overflowX: 'hidden' }}>
					{!isLoading && (
						<ThemeProviderComponent>
							<Header />
							<main className={styles.gridContainer}>
								<section className={styles.left}>
									<PropertyDetails
										formData={formData}
									/>
									<FormFicha
										urlDetalleInmueble={true}
										formData={formData}
									/>
								</section>
								<section className={styles.footerMain}>
									<Footer />
								</section>
								<section className={styles.right}>
									<ContentComponent uID={uID} />
								</section>
							</main>
						</ThemeProviderComponent>
					)}
				</Feedback.ScrollBar>
			</Layout.Page>
			<Feedback.ModalLoading
				maxWidth="xs"
				isOpen={isLoading}
				title="Cargando..."
				content="Espere un momento por favor..."
			/>
		</>
	)
}

export default AuthorizationPage
