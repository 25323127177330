import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'

const HeaderContent = ({ title, subtitle }) => {
	return (
		<Card className="border-radius-0">
			<CardContent className="m-0 p-4">
				<div className="row">
					<div className="col-sm-12">
						<div className="font-family-roboto-regular fs-4 text-dark">
							{title}
						</div>
					</div>
					<div className="col-sm-12 mt-1">
						<div className="font-family-roboto-regular fs-6 text-grey">
							{subtitle}
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	)
}

HeaderContent.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string
}

export default HeaderContent
