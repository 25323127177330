import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { history } from '../../redux/store'
import { Alert, Box, Button, Typography } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import EditIcon from '@mui/icons-material/Edit'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ThemeProviderComponent from '../../theme-v2/themeProvider'
import PropertyHeader from './components/propertyHeader/PropertyHeader'
import PropertyForm from './components/propertyForm/PropertyForm'
import SquarePropertyCard from '../../components/properties/cards/Properties'
import ToggleSwitchComponent from '../dashboard/filtered/FilteredDetailPage/components/ToggleSwitchComponent'
import TablePagination from '../../components/mui/Layout/TablePagination'
import VerticalCardsLoading from '../../components/properties/VerticalCardsLoading'
import { Footer, Header, Modal } from '../../components/v2-mobili'
import { useModal } from '../../components/v2-mobili/hooks'
import TableActivityLog from '../../components/activity-logs/TableActivityLog'
import PropertiesFavoritesComponent from '../dashboard/filtered/FilteredDetailPage/components/PropertiesFavoritesComponent'
import { deleteFavoriteById, setIsSelectedToggleSwitch, showById, storeFavorite } from '../../redux/actions/dashboard/filtered/filteredAction'
import { getArray, setPage, setPerPage } from '../../redux/actions/algolia/properties/algoliaPropertyAction'
import { setActivityLogReset, setLogName, setSubjectId } from '../../redux/actions/dashboard/activities/activityLogAction'
import { perPages } from '../../helpers/variablesColumns'
import styles from './propertyDetails.module.css'

const DetailedViewProperty = ({ isAuthenticated, isAuthenticatedPage, hasHeader = false, uID, formData, hasProperties = false, hasFavorites = false }) => {
  const dispatch = useDispatch()
  const { realStateId } = useSelector( state => state.auth.userData)
  const formDataAlgolia = useSelector(state => state.filtered.formData)
  const isSelectedToggleSwitch = useSelector(state => state.filtered.isSelectedToggleSwitch)
  const isLoading = useSelector(state => state.algoliaProperty.isLoading)
  const perPage = useSelector(state => state.algoliaProperty.perPage)
  const page = useSelector(state => state.algoliaProperty.page)
  const pages = useSelector(state => state.algoliaProperty.pages)
  const arrayList = useSelector(state => state.algoliaProperty.arrayList)
  const pageActivityLog = useSelector(state => state.activityLog.page)
  const pagesActivityLog = useSelector(state => state.activityLog.pages)
  const logName = useSelector(state => state.activityLog.logName)
  const subjectId = useSelector(state => state.activityLog.subjectId)
  
  useEffect(() => {
    if (hasFavorites) {
      dispatch( setIsSelectedToggleSwitch(1) )
    } else {
      if (isAuthenticated) {
        dispatch( setIsSelectedToggleSwitch(2) )
      }
    }
  }, [
    isAuthenticated,
    isAuthenticatedPage,
    uID,
    formData,
    hasFavorites,
    hasHeader,
    hasProperties
  ])
  
  const handleArrayList = async () => {
    const param = {
      perPage: perPage,
      page: page,
      realstate: realStateId,
      action: formDataAlgolia.service === '' ? null : formDataAlgolia.service,
      code: formDataAlgolia.code === '' ? null : formDataAlgolia.code,
      city: formDataAlgolia.city === '' ? null : formDataAlgolia.city,
      neighborhood: formDataAlgolia.neighborhoods.length === 0 ? null : formDataAlgolia.neighborhoods,
      type: formDataAlgolia.propertyTypes.length === 0 ? null : formDataAlgolia.propertyTypes,
      strata: formDataAlgolia.strata.length === 0 ? null : formDataAlgolia.strata,
      number_room: formDataAlgolia.numberRoom === '' || formDataAlgolia.numberRoom === 0 ? null : formDataAlgolia.numberRoom,
      number_bathroom: formDataAlgolia.numberBathRoom === '' || formDataAlgolia.numberBathRoom === 0 ? null : formDataAlgolia.numberBathRoom,
      area_total: formDataAlgolia.areaTotal === '' || formDataAlgolia.areaTotal === 0 ? null : formDataAlgolia.areaTotal,
      range_total_lease: formDataAlgolia.rangeTotalLease === '' || formDataAlgolia.rangeTotalLease === 0 ? null : formDataAlgolia.rangeTotalLease,
      range_total_buy: formDataAlgolia.rangeTotalBuy === '' || formDataAlgolia.rangeTotalBuy === 0 ? null : formDataAlgolia.rangeTotalBuy,
    }

    await dispatch( getArray(param) )
  }
  
  useEffect(() => {
    if (isSelectedToggleSwitch === 0 && hasProperties) {
      handleArrayList()
    }
  }, [
    perPage,
    page,
    isSelectedToggleSwitch
  ])
  
  /**
   *
   * @description Execute dispatch perPage state
   * @return dispatch
   *
   */
  const handleChangePerPage = ({ target }) => dispatch( setPerPage(target.value) )
  
  /**
   *
   * @description Execute dispatch page state
   * @return dispatch
   *
   */
  const handleChangePage = (event, value) => dispatch( setPage(value) )
  
  // const handleShowById = async () => await dispatch( showById(uID) )
  
  useEffect(() => {
    // dispatch( setIsSelectedToggleSwitch(1) )
    
    // if (isSelectedToggleSwitch === 0 && formDataAlgolia.realState === '') {
    // handleShowById()
    // }
  }, [])
  
  /**
   *
   * @description Execute endpoint add or delete favorite of filtered
   * @return dispatch
   *
   */
  const handleStoreLike = async (value, disLike = false) => {
    if (isAuthenticated) {
      if (disLike) {
        await dispatch( deleteFavoriteById(value) )
        return
      }
  
      const param = {
        filter: formData.id,
        property: value
      }
  
      await dispatch( storeFavorite(param) )
    }
  }
  
  // const [isOpenModal, closeModalFunc, openModalFunc] = useModal()
  const modalShare = useModal()
  const location = useLocation()
  const [showAlert, setShowAlert] = useState(true)

  const goToEditComercial = async () => await history.push(`/dashboard/filtrado/comercial/detalle/${uID}`)

  const handleCopyEnlace = () => {
    let url = document.createElement('input')
    // url.setAttribute('value', window.location.href.split('?')[0].split('#')[0])
    url.setAttribute('value', `${window.location.protocol}//${window.location.host}/filter/customer/${uID}`)
    document.body.appendChild(url)
    url.select()
    document.execCommand('copy')
    document.body.removeChild(url)

    setShowAlert(false)

    setTimeout(() => {
      setShowAlert(true)
    }, 5000)
  }
  
  /**
   *
   * @description Execute endpoint show name and subject id for activity log
   * @return dispatch
   *
   */
  const handleActivityLog = async () => {
    await dispatch( setActivityLogReset() )
    await dispatch( setLogName('Filtrado') )
    await dispatch( setSubjectId(uID) )
  }
  
  useEffect(() => {
    if (isSelectedToggleSwitch === 2) {
      handleActivityLog()
    }
  }, [uID, isSelectedToggleSwitch])
  
  return (
    <ThemeProviderComponent>
      {hasHeader && <Header />}
      <main className={styles.gridContainer}>
        <section className={styles.left}>
          <PropertyHeader
            isAuthenticated={isAuthenticated}
            isAuthenticatedPage={isAuthenticatedPage}
            formData={formData}
            hasButtonBack={!hasHeader}
            {...formData}
          />
          <PropertyForm formData={formData} />
          {location.pathname.split('/')[3] === 'comercial' && (
            <Box mt={2} display={'flex'} justifyContent="center">
              <Button
                onClick={goToEditComercial}
                sx={{ textTransform: 'none' }}
                variant="outlined"
                startIcon={<EditIcon />}
              >
                Editar
              </Button>
            </Box>
          )}
        </section>
        <section className={styles.footerMain}>
          <Footer />
        </section>

        <section className={styles.right}>
          {/* SHOW TABS */}
          <ToggleSwitchComponent
            isAuthenticated={isAuthenticated}
            hasProperties={hasProperties}
            hasFavorites={hasFavorites}
            hasActivityLog={isAuthenticated}
          />

          {/* SHOW PROPERTIES CARDS */}
          {isSelectedToggleSwitch === 0 && hasProperties && (
            <>
              <TablePagination
                arrayList={arrayList.length}
                perPage={perPage}
                perPages={perPages}
                page={page}
                pages={pages}
                handleChangePerPage={handleChangePerPage}
                handleChangePage={handleChangePage}
              />
              {isLoading && <VerticalCardsLoading />}
              
              <Box
                id="scrollableDivFilter"
                gap={2}
                width="95%"
                display="grid"
                margin="0 auto"
                // overflow="scroll"
                overflow="auto"
                height="700px"
                gridTemplateColumns="repeat(auto-fill,minmax(300px,1fr))"
                className="mt-2 mb-2"
              >
                {arrayList.length > 0 && (
                  arrayList.map((item,index) => {
                    // VALIDATE THE PRICE ACCORDING TO THE SERVICE
                    let price =
                      item?.action_type === 'Arriendo'
                        ? item?.action_canon_string
                        : item?.action_type === 'Compra o Venta'
                        ? item?.action_sale_string
                        : item?.action_type === 'Venta'
                        ? item?.action_sale_string
                        : item?.action_type === 'Arriendo/Venta'
                        ? `${item?.action_canon_string} / ${item?.action_sale_string}`
                        : ''
                        {/* if(index === 0) console.log("Property details",{item}) */}
  
                    return (
                      <SquarePropertyCard
                        key={item?.id}
                        goToDetail={() => history.push(isAuthenticatedPage ? `/dashboard/propiedades/detalle/${item?.property_id}` : `/properties/detail/${item?.id}`)}
                        handleStoreLike={() => handleStoreLike(item?.property_action?.property_id)}
                        property={
                          item
                        //   {
                        //   type: `${item?.type} en ${item?.action_type}`,
                        //   price_format: price,
                        //   codpro: item?.domus_code,
                        //   images_count: item?.has_photo,
                        //   image1: item?.path,
                        //   neighborhood: item?.neighborhood,
                        //   city: item?.city,
                        //   area_lot: item?.area_total,
                        //   bathrooms: item?.number_bathroom,
                        //   bedrooms: item?.number_room,
                        //   parking: item?.number_garage
                        // }
                        }
                      />
                    )
                  })
                )}
              </Box>
            </>
          )}

          {/* SHOW FAVORITE CARDS */}
          {hasFavorites && (
            <PropertiesFavoritesComponent
              isAuthenticated={isAuthenticated}
              isAuthenticatedPage={isAuthenticatedPage}
              uID={uID}
            />
          )}
          
          {isSelectedToggleSwitch === 1 && hasFavorites && (
            //location.pathname.split('/')[3] === 'comercial' && (
              <Box mt={2} display="flex" justifyContent="center">
                <Button
                  onClick={modalShare.openModalFunc2}
                  sx={{ textTransform: 'none' }}
                  variant="outlined"
                  startIcon={<ShareIcon />}
                >
                  Compartir
                </Button>
              </Box>
            //)
          )}

          {/* ACTIVITY LOG */}
          {isAuthenticated && isSelectedToggleSwitch === 2 && (
            <div className="p-2">
              <TableActivityLog
                page={pageActivityLog}
                pages={pagesActivityLog}
                logName={logName}
                subjectId={subjectId}
              />
            </div>
          )}
        </section>

        <Modal
          show={modalShare.isOpenModal}
          closeModal={modalShare.closeModalFunc2}
          width={'modalMd'}
          position={'pMedium'}
        >
          <Box
            display="flex"
            alignItems={'flex-start'}
            justifyContent="flex-start"
            flexDirection={'column'}
            gap={3}
          >
            <Typography variant="h3">Compartir ficha comercial</Typography>
            <Typography variant="h4">Enviar vínculo</Typography>
            <Typography variant="span">
              Cualquier persona que tenga el vínculo podrá acceder a la ficha
              compartida.
            </Typography>

            <Box sx={{ display: showAlert ? 'none' : '' }}>
              <Alert
                sx={{ width: '100%' }}
                iconMapping={{
                  success: <CheckCircleOutlineIcon fontSize="inherit" />,
                }}
              >
                Enlace copiado con Exito!
              </Alert>
            </Box>

            <Button
              onClick={handleCopyEnlace}
              sx={{ textTransform: 'none' }}
              variant="outlined"
              startIcon={<InsertLinkIcon />}
            >
              Copiar enlace
            </Button>
          </Box>
        </Modal>
      </main>
    </ThemeProviderComponent>
  )
}

DetailedViewProperty.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isAuthenticatedPage: PropTypes.bool.isRequired,
  hasHeader: PropTypes.bool,
  uID: PropTypes.string,
  formData: PropTypes.object,
  hasProperties: PropTypes.bool,
  hasFavorites: PropTypes.bool
}

export default DetailedViewProperty
