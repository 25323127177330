import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100% !important',
    '& .MuiFormLabel-root.Mui-focused': {
      fontWeight: '500',
      color: '#1b1b1b !important',
      fontSize: '18px'
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid #1b1b1b !important'
    },
    '& .MuiInput-underline:before:hover': {
      borderBottom: '2px solid #1b1b1b !important'
    }
  }
}))

export default function Autocomplete(props) {
  const classes = useStyles()
  const { size, label, variant, itemsArray, handleChangeSearch, ...more } = props
  const [value, setValue] = useState("")
  
  return (
    <MuiAutocomplete
      variant={variant || "standard"}
      value={value}
      defaultValue={value}
      options={itemsArray}
      getOptionLabel={option => option.company}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_, values) => {
        if (values === null) {
          setValue('')
          handleChangeSearch('')
        } else {
          setValue(values.company)
          handleChangeSearch(values.id)
        }
      }}
      renderInput={(params) =>
        <TextField
          {...params}
          variant={variant}
          size={size}
          label={label}
          />
      }
      classes={{ root: classes.root }}
      {...more}
    />
  )
}
