import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SxProps, Theme } from '@mui/material';
import Layout from '../../../../components/mui/Layout';
import KanbanDetailComponent from './KanbanDetailComponent';
import { getArrayListToDropdown } from '../../settings/commercial/CommercialStatusPage/services';
import { setIsLoading } from '../../../../redux/actions/loadingAction';
import usePermission from '../../../../hooks/usePermission';
import swalErrors from '../../../../hooks/useErrors';

const KanbanComponent = () => {
  const dispatch = useDispatch();
  const { realStateId } = useSelector( ({ auth })=> auth.userData);
  const refreshTable = useSelector( ({ commercial })=> commercial.refreshTable);
  const [arrayList, setArrayList] = useState( []);
  const permission = usePermission('commercialStatus.dropdownByRealState');

  /**
   *
   * @description Execute endpoint array list commercial status by realstate in dropdown
   * @return dispatch
   *
   */
  const handleArrayList = async () => {
    if (permission) {
      dispatch( setIsLoading(true) );

      try {
        const { data } = await getArrayListToDropdown(realStateId);

        if (data.status === 200) {
          setArrayList(data.data.records);
        }
      } catch (error) {
        // await swalErrors(error);
      }

      dispatch( setIsLoading(false) );
    } else {
      setArrayList([]);
    }
  }

  useEffect(() => {
    handleArrayList();
  }, [
    realStateId,
    refreshTable
  ]);

  return (
    <Layout.Box sx={KanbanWrapperStyle}>
      {arrayList.length > 0 && (
        arrayList.map(({ id, name }, index) => (
          <Layout.Box sx={KanbanColumnStyle} key={index}>
            <Layout.Typography
              variant="h5"
              textAlign="center"
              color="dark.default"
            >
              {name}
            </Layout.Typography>
            <Layout.Box
              sx={ScrollContentStyle}
              className="card-kanban"
            >
              <KanbanDetailComponent statusId={id} />
            </Layout.Box>
          </Layout.Box>
        ))
      )}
    </Layout.Box>
  );
};

/**
 * @type {SxProps<Theme>}
 */
const KanbanWrapperStyle = {
  m: 2,
  gap: "30px",
  display: "grid",
  margin: '0 auto',
  mt:2,
  maxWidth:'1080px',
  gridTemplateColumns: "repeat(auto-fill, minmax(340px,1fr))",
}

/**
 * @type {SxProps<Theme>}
 */
const KanbanColumnStyle = {
  display: "flex",
  borderRadius: "5px",
  flexDirection: "column",
  gap: ({ spacing }) => spacing(2.5), // 20px
  p: ({ spacing }) => spacing(1.875, 1.25), // 15px 10px
  bgcolor: "lightGrey.default",
  height: "90vh", // ? 715px figma has design
  overflow: "hidden",
  position: "relative",
}

const ScrollContentStyle = {
  gap: 2,
  display: "flex",
  overflowY: "scroll",
  overflowX: "none",
  flexDirection: "column",
}

export default KanbanComponent;
