import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Inputs from '../../../../../components/mui/Inputs';

const PermissionsRowComponent = ({ group, items, handleChange }) => {
	
	useEffect(() => {
	}, [group, items]);
	
	return (
		<div
			className="col-sm-12 mb-2 bg-grey-light border-l-4 border-dark p-2 font-family-roboto-regular fs-6 animate__animated animate__fadeIn"
		>
			<div className="font-family-roboto-semibold fs-6">Módulo {group}</div>
			
			{items.length > 0 && (
				<div className="row">
					{items.map(({ id, name, public_name, description, active }, number) => (
						<div
							key={number}
							className="col-12 col-sm-6 col-md-3 col-lg-4"
						>
							<Inputs.CheckBox
								label={public_name}
								name="permission"
								isChecked={active}
								value={name}
								position="end"
								onChange={() => handleChange(id, name)}
							/>
							<div className="font-family-roboto-semibold"><small>{description}</small></div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

PermissionsRowComponent.propTypes = {
	group: PropTypes.string.isRequired,
	items: PropTypes.array.isRequired,
	handleChange: PropTypes.func.isRequired
};

export default PermissionsRowComponent;
