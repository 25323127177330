import endPoints from './endPoints'
import axios from '../../../../../../../services/axios'

const {
	store,
	show
} = endPoints

export const setStoreByCode = async (data) => (
	await axios.post(store, data)
)

export const getShowByCode = async (code) => (
	await axios.get(`${show}${code}`)
)
