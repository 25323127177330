import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import CollapseComponent from './CollapseComponent'
import Layout from '../../../../../components/mui/Layout'
import Navigation from '../../../../../components/mui/Navigation'

const ListItemComponent = ({ level, icon, label, path, hasChildren, children }) => {
	const [open, setOpen] = useState(false)
	
	const handleOpen = () => hasChildren && setOpen(!open)
	
	return (
		<>
			{!hasChildren && (
				<Navigation.NavLink
	        to={path}
	        className="link-dark"
	      >
					<ListItem disablePadding>
						<ListItemButton className="p-2">
							<ListItemIcon className={`d-inline m-0 p-0 ${level === 'secondary' && 'l-2'}`}>{icon}</ListItemIcon>
							<ListItemText className={`d-inline m-0 p-0 font-family-roboto-regular fs-6 text-dark ${level === 'secondary' && 'l-2'}`}>{label}</ListItemText>
						</ListItemButton>
					</ListItem>
	      </Navigation.NavLink>
			)}
			{hasChildren && (
		    <ListItem
		      disablePadding
	        onClick={handleOpen}
		    >
			    <ListItemButton className="p-2">
            <ListItemIcon className={`d-inline m-0 p-0 ${level === 'secondary' && 'l-2'}`}>{icon}</ListItemIcon>
            <ListItemText className={`d-inline m-0 p-0 font-family-roboto-regular fs-6 text-dark ${level === 'secondary' && 'l-2'}`}>{label}</ListItemText>
	          {open ? <Layout.Icons.ExpandLessIcon height={20} width={20} /> : <Layout.Icons.ExpandMoreIcon height={20} width={20} />}
		      </ListItemButton>
		    </ListItem>
			)}
			{hasChildren && (
				<Collapse
					in={open}
					timeout="auto"
					unmountOnExit
				>
					<CollapseComponent
						children={children}
					/>
				</Collapse>
			)}
		</>
	)
}

ListItemComponent.propTypes = {
	level: PropTypes.string.isRequired,
	icon: PropTypes.element.isRequired,
	label: PropTypes.string.isRequired,
	path: PropTypes.string,
	hasChildren: PropTypes.bool.isRequired,
	children: PropTypes.array
}

export default ListItemComponent
