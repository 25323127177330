import summation from '../assets/images/icons/summation.png';
import average from '../assets/images/icons/average.png';

export const arrayLoading = [
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{},
];

export const perPages = [
	{ id: 10, name: '10' },
	{ id: 20, name: '20' },
	{ id: 50, name: '50' },
	{ id: 100, name: '100' },
	{ id: 200, name: '200' },
	{ id: 500, name: '500' }
];

export const activityLogsColumns = [
	{ label: "Fecha", align: 'center', width: '20%' },
	{ label: "Descripción", align: 'center', width: '25%' },
	{ label: "Actividad", align: 'center', width: '10%' },
	{ label: "Cambios", align: 'center', width: '20%' },
	{ label: "Funcionario", align: 'center', width: '25%' }
];

export const permissionColumns = [
	{ label: 'Grupo', align: 'center', width: '15%' },
	{ label: 'Nombre', align: 'left', width: '25%' },
	{ label: 'Nombre público', align: 'left', width: '25%' },
	{ label: 'Descripción', align: 'left', width: '30%' },
	{ label: '', align: 'center',  width: '5%' }
];

export const roleColumns = [
	{ label: 'Nombre', align: 'left', width: '95%' },
	{ label: '', align: 'center', width: '5%' }
];

export const typeRoles = [
	{ id: 'Asesor de Arriendo', name: 'Asesor de Arriendo' },
	{ id: 'Asesor de Compra/Venta', name: 'Asesor de Compra/Venta' },
	{ id: 'Asesor de Captación', name: 'Asesor de Captación' },
	{ id: 'Asesor de Proyecto', name: 'Asesor de Proyecto' }
];

export const realStateColumns = [
	{ label: 'Logo', align: 'center', width: '15%' },
	{ label: 'Nombre', align: 'left', width: '20%' },
	{ label: 'Credenciales Domus', align: 'center', width: '20%' },
	{ label: 'Credenciales Pipedrive', align: 'center', width: '20%' },
	{ label: 'Color', align: 'center', width: '10%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const userSuperAdminColumns = [
	{ label: '', align: 'left', width: '5%' },
	{ label: '', align: 'left', width: '5%' },
	{ label: 'Inmobiliaria', align: 'left', width: '20%' },
	{ label: 'Nombres y Apellidos', align: 'left', width: '30%' },
	{ label: 'Email', align: 'left', width: '25%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const userAdminColumns = [
	{ label: '', align: 'left', width: '5%' },
	{ label: '', align: 'left', width: '5%' },
	{ label: 'Nombres y Apellidos', align: 'left', width: '40%' },
	{ label: 'Email', align: 'left', width: '35%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const bankColumns = [
	{ label: 'Nombre', align: 'left', width: '90%' },
	{ label: 'Estatus', align: 'center', width: '5%' },
	{ label: '', align: 'center', width: '5%' }
];

export const areaColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const amenityColumns = [
	{ label: 'Tipo', align: 'center', width: '20%' },
	{ label: 'Tipo Inventario', align: 'center', width: '20%' },
	{ label: 'Nombre', align: 'left', width: '20%' },
	{ label: 'Áreas Activas', align: 'left', width: '25%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const amenityArrayType = [
	{ id: '', name: '-- Seleccione una opción --' },
	{ id: 'Edificación', name: 'Edificación' },
	{ id: 'Area', name: 'Area' },
	{ id: 'Ambos', name: 'Ambos' }
];

export const amenityArrayTypeInventory = [
	{ id: '', name: '-- Seleccione una opción --' },
	{ id: 'Legal', name: 'Legal' },
	{ id: 'Comercial', name: 'Comercial' },
	{ id: 'Ambos', name: 'Ambos' }
];

export const projectSuperAdminColumns = [
	{ label: 'Inmobiliaria', align: 'left', width: '40%' },
	{ label: 'Nombre', align: 'left', width: '45%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const projectAdminColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const commercialStatusSuperAdminColumns = [
	{ label: 'Inmobiliaria', align: 'left', width: '40%' },
	{ label: 'Orden', align: 'center', width: '10%' },
	{ label: 'Nombre', align: 'left', width: '35%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const commercialStatusAdminColumns = [
	{ label: 'Orden', align: 'center', width: '10%' },
	{ label: 'Nombre', align: 'left', width: '75%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const keyColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const countryColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const stateColumns = [
	{ label: 'País', align: 'left', width: '40%' },
	{ label: 'Departamento', align: 'left', width: '45%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const cityColumns = [
	{ label: 'Departamento', align: 'left', width: '40%' },
	{ label: 'Ciudad', align: 'left', width: '45%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const neighborhoodColumns = [
	{ label: 'Ciudad', align: 'left', width: '40%' },
	{ label: 'Barrio', align: 'left', width: '45%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const localityColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const contactColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const originChannelColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const buildingTypeColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const propertyDestinationColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const propertyFacadeColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const propertyFloorColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const propertyReserveColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const propertyRoomServiceColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const propertyStatusColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const propertyStatusCommercialColumns = [
	{ label: 'Nombre', align: 'left', width: '75%' },
	{ label: 'Color', align: 'center', width: '10%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const propertyTypeColumns = [
	{ label: 'Nombre', align: 'left', width: '85%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const filteredOccurrenceColumns = [
	{ label: "No. de casos", align: 'center', width: '15%' },
	{ label: "Estado", align: 'center', width: '15%' },
	{ label: "Fecha de apertura", align: 'center', width: '25%' },
	{ label: "Servicio", align: 'center', width: '15%' },
	{ label: "Presupuesto", align: 'center', width: '23%' },
	{ label: "", align: 'center', width: '7%' }
];

export const filteredLogsColumns = [
	{ label: "Fecha", align: 'center', width: '20%' },
	{ label: "Actividad", align: 'center', width: '40%' },
	{ label: "Funcionario", align: 'center', width: '40%' }
];

export const filteredToggleSwitch = [
  { id: 'Inmuebles', label: 'Inmuebles' },
  { id: 'Favoritos', label: 'Favoritos' },
  { id: 'Actividad', label: 'Actividad' },
];

export const filteredServiceSwitch = [
	{ id: "Arriendo", label: "Arriendo" },
	{ id: "Compra", label: "Compra" },
	{ id: "Captación", label: "Captación" },
	{ id: "Proyecto", label: "Proyecto" }
];

export const filteredStrataSwitch = [
	{ id: 1, label: 1 },
	{ id: 2, label: 2 },
	{ id: 3, label: 3 },
	{ id: 4, label: 4 },
	{ id: 5, label: 5 },
	{ id: 6, label: 6 }
];

export const filteredPaymentMethod = [
	{ id: 1, label: "De Contado" },
	{ id: 2, label: "A Crédito" }
];


export const statisticOperatorsColumns = [
	{ id: 'sum', name: 'Sumatoria', icon: summation},
	{ id: 'average', name: 'Promedio', icon: average }
];

export const statisticStatusColumns = [
	{ label: '', align: 'center', width: '5%' },
	{ label: 'Estado', align: 'left', width: '35%' },
	{ label: 'Contactos', align: 'center', width: '15%' },
	{ label: 'Calidad', align: 'center', width: '10%' },
	{ label: 'Presupuesto', align: 'center', width: '35%' }
];

export const statisticAgentColumns = [
	{ label: '', align: 'center', width: '5%' },
	{ label: 'Agente', align: 'left', width: '35%' },
	{ label: 'Contactos', align: 'center', width: '15%' },
	{ label: 'Calidad', align: 'center', width: '10%' },
	{ label: 'Presupuesto', align: 'center', width: '35%' }
];

export const statisticAdviserColumns = [
	{ label: '', align: 'center', width: '5%' },
	{ label: 'Funcionario', align: 'left', width: '35%' },
	{ label: 'Contactos', align: 'center', width: '15%' },
	{ label: 'Calidad', align: 'center', width: '10%' },
	{ label: 'Presupuesto', align: 'center', width: '35%' }
];

export const statisticCustomerColumns = [
	{ id: 'full_name', label: 'Cliente', align: 'center', width: '30%' },
  { id: 'email', label: 'Email', align: 'center', width: '30%' },
  { id: 'amount', label: 'Presupuesto', align: 'center', width: '30%' },
  { id: 'action', label: 'Acción', align: 'center', width: '10%' }
];

export const ownerAllColumns = [
	{ label: 'Tipo', align: 'center', width: '10%' },
	{ label: 'Razón social', align: 'center', width: '20%' },
	{ label: 'Nombre y Apellido', align: 'center', width: '20%' },
	{ label: 'Email', align: 'center', width: '20%' },
	{ label: 'Celular', align: 'center', width: '15%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const ownerNaturalColumns = [
	{ label: 'Tipo', align: 'center', width: '10%' },
	{ label: 'Nombre y Apellido', align: 'center', width: '30%' },
	{ label: 'Email', align: 'center', width: '30%' },
	{ label: 'Celular', align: 'center', width: '15%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const ownerLegalColumns = [
	{ label: 'Tipo', align: 'center', width: '10%' },
	{ label: 'Razón social', align: 'center', width: '30%' },
	{ label: 'Email', align: 'center', width: '30%' },
	{ label: 'Celular', align: 'center', width: '15%' },
	{ label: 'Estatus', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const ownerFilter = [
	{ id: 'all', name: 'Todos' },
	{ id: 'Natural', name: 'Natural' },
	{ id: 'Jurídica', name: 'Jurídica' }
];

export const ownerPersonType = [
	{ id: 'Natural', name: 'Natural' },
	{ id: 'Jurídica', name: 'Jurídica' }
];

export const ownerDocType1 = [
	{ id: 'DNI', name: 'Cédula o Dni' },
	{ id: 'Pasaporte', name: 'Pasaporte' }
];

export const ownerDocType2 = [
	{ id: 'NIT', name: 'NIT' }
];

export const ownerAccountType = [
	{ id: 'Ahorro', name: 'Ahorro' },
	{ id: 'Corriente', name: 'Corriente' },
];

export const buildingColumns = [
	{ label: 'Inmobiliria', align: 'left', width: '20%' },
	{ label: 'Compañía', align: 'left', width: '20%' },
	{ label: 'Dirección', align: 'left', width: '20%' },
	{ label: 'Estrato', align: 'center', width: '5%' },
	{ label: 'Año Const.', align: 'center', width: '10%' },
	{ label: 'Área Const.', align: 'center', width: '10%' },
	{ label: 'Área Total', align: 'center', width: '10%' },
	{ label: '', align: 'center', width: '5%' }
];

export const propertyAreasColumns = [
	{ id: 'type', label: 'Tipo del Área', align: 'center', width: '20%' },
	{ id: 'name', label: 'Nombre del Área', align: 'center', width: '20%' },
	{ id: 'floor', label: 'Piso', align: 'center', width: '15%' },
	{ id: 'width', label: 'Ancho', align: 'center', width: '10%' },
	{ id: 'long', label: 'Largo', align: 'center', width: '10%' },
	{ id: 'amenity', label: 'Amenidades', align: 'center', width: '20%' },
	{ id: 'action', label: '', align: 'center', width: '5%' }
];

export const actionsSwitch = [
	{ id: 'Si', name: 'Si' },
	{ id: 'No', name: 'No' }
];

export const actionsSwitchBank = [
	{ id: 'Ahorro', name: 'Ahorro' },
	{ id: 'Corriente', name: 'Corriente' }
];

export const strataArray = [
	{ id: 1, name: 1 },
	{ id: 2, name: 2 },
	{ id: 3, name: 3 },
	{ id: 4, name: 4 },
	{ id: 5, name: 5 },
	{ id: 6, name: 6 }
];

export const addressArray = [
	{ id: "Calle", name: "Calle" },
	{ id: "Carrera", name: "Carrera" },
	{ id: "Diagonal", name: "Diagonal" },
	{ id: "Transversal", name: "Transversal" },
	{ id: "Vía", name: "Vía" },
	{ id: "Avenida", name: "Avenida" }
];
