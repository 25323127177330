export const PER_PAGE_PROPERTY_FACADE = 'PER_PAGE_PROPERTY_FACADE';
export const PAGE_PROPERTY_FACADE = 'PAGE_PROPERTY_FACADE';
export const SEARCH_PROPERTY_FACADE = 'SEARCH_PROPERTY_FACADE';
export const PAGES_PROPERTY_FACADE = 'PAGES_PROPERTY_FACADE';
export const ARRAY_LIST_PROPERTY_FACADE = 'ARRAY_LIST_PROPERTY_FACADE';
export const ARRAY_LIST_TO_DROPDOWN_PROPERTY_FACADE = 'ARRAY_LIST_TO_DROPDOWN_PROPERTY_FACADE';
export const REFRESH_TABLE_PROPERTY_FACADE = 'REFRESH_TABLE_PROPERTY_FACADE';
export const IS_OPEN_MODAL_PROPERTY_FACADE = 'IS_OPEN_MODAL_PROPERTY_FACADE';
export const IS_LOADING_SHOW_PROPERTY_FACADE = 'IS_LOADING_SHOW_PROPERTY_FACADE';
export const IS_LOADING_FORM_PROPERTY_FACADE = 'IS_LOADING_FORM_PROPERTY_FACADE';
export const FORM_TYPE_PROPERTY_FACADE = 'FORM_TYPE_PROPERTY_FACADE';
export const FORM_DATA_PROPERTY_FACADE = 'FORM_DATA_PROPERTY_FACADE';
