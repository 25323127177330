import { initialValuesPermission as initialValues } from '../../../../../../helpers/variablesInitialValues';
import * as types from '../types/permissionType';

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	search: '',
	arrayList: [],
	arrayListToDropdown: [],
	refreshTable: false,
	isOpenModal: false,
	isLoadingShow: false,
	isLoadingForm: false,
	formType: '',
	formData: initialValues
};

const permissionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_PERMISSION:
			return {
				...state,
				perPage: payload
			};
		
		case types.PAGE_PERMISSION:
			return {
				...state,
				page: payload
			};
		
		case types.PAGES_PERMISSION:
			return {
				...state,
				pages: payload
			};
		
		case types.SEARCH_PERMISSION:
			return {
				...state,
				search: payload
			};
		
		case types.ARRAY_LIST_PERMISSION:
			return {
				...state,
				arrayList: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_PERMISSION:
			return {
				...state,
				arrayListToDropdown: payload
			};
		
		case types.REFRESH_TABLE_PERMISSION:
			return {
				...state,
				refreshTable: payload
			};
		
		case types.IS_OPEN_MODAL_PERMISSION:
			return {
				...state,
				isOpenModal: payload
			};
		
		case types.IS_LOADING_SHOW_PERMISSION:
			return {
				...state,
				isLoadingShow: payload
			};
			
		case types.IS_LOADING_FORM_PERMISSION:
			return {
				...state,
				isLoadingForm: payload
			};
		
		case types.FORM_TYPE_PERMISSION:
			return {
				...state,
				formType: payload
			};
		
		case types.FORM_DATA_PERMISSION:
			return {
				...state,
				formData: payload
			};
		
		default:
			return state;
	}
};

export default permissionReducer;
