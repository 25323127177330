import React from 'react'
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Display from "../../../../../components/display/Display"
import Controls from "../../../../../components/controls/Controls"

const ToursRow = ({ onChange, onRemove, link }) => {
	
	return (
		<Grid container spacing={1} className="mb-2">
			<Grid item xs={10} md={9} lg={10}>
				<Controls.TextField
					label="Tour..."
					name="link"
					placeholder="Ingrese link para el tour virtual..."
					defaultValue={link}
					onChange={e => onChange("link", e.target.value)}
				/>
			</Grid>
			<Grid item xs={2} md={3} lg={2} className="float-end d-flex">
				<IconButton
					color="inherit"
					onClick={onRemove}
					className="bg-grey mt-4 height-32 width-32"
				>
					<Display.Icons.RemoveIcon
						className="text-dark"
						style={{ fontSize: 20 }}
					/>
				</IconButton>
			</Grid>
		</Grid>
	)
}

export default ToursRow
