import React, { useRef, useState, useEffect, forwardRef } from 'react'
import MuiDialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import Display from "../display/Display"
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken = `${process.env.REACT_APP_MAP_BOX_TOKEN}`

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogBuilding(props) {
    const { maxWidth, dialogName, open, title, ...other } = props
    const map = useRef(null)
    const mapContainer = useRef(null)
    const [fullWidth, setFullWidth] = useState(true)
    const [getLongitude, setLongitude] = useState('-74.7889')
    const [getLatitude, setLatitude] = useState('10.9878')
    const [getZoom, setZoom] = useState(9)
    
    const MountMap = ()=>{
        map.current = new mapboxgl.Map({
            container: 'map-building',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [getLongitude, getLatitude],
            zoom: getZoom
        })

        new mapboxgl.Marker().setLngLat([getLongitude, getLatitude]).addTo(map.current)

        map.current.addControl(new mapboxgl.NavigationControl(), 'top-right')

        map.current.on('move', (e) => {
            setLongitude(map.current.getCenter().lng.toFixed(4))
            setLatitude(map.current.getCenter().lat.toFixed(4))
            setZoom(map.current.getZoom().toFixed(2))
        })
    }

    return (
        <MuiDialog
            disableBackdropClick
            disableEscapeKeyDown
            onEntered={()=>MountMap()}
            TransitionComponent={Transition}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            aria-labelledby={dialogName}
            open={open}
            {...other}
        >
            <DialogTitle id={dialogName}>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Display.Typography
                            variant="subtitle1"
                            color="error"
                            label="Los datos con (*) son requeridos"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="map-container" id="map-building" />
                    </Grid>
                </Grid>
            </DialogContent>
        </MuiDialog>
    )
}