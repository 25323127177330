import Link from './Link'
import NavLink from './NavLink'
import ScrollTop from './ScrollTop'
import TabPanel from './TabPanel'

const index = {
  Link,
  NavLink,
  ScrollTop,
  TabPanel
}

export default index
