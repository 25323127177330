import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from 'react-router-dom';
import PublicRouter from './PublicRouter';
import ProtectedRouter from './ProtectedRouter';
import { setReset } from '../pages/auth/redux/actions/authAction';
import axios from '../services/axios';
import { initialValuesAuth } from '../helpers/variablesInitialValues';

const AppRouter = ({ history }) => {
	const dispatch = useDispatch()
	const isAuthenticated = useSelector( ({ auth }) => auth.isAuthenticated)
	const tokenType = useSelector( ({ auth }) => auth.tokenType)
	const accessToken = useSelector( ({ auth }) => auth.accessToken)
	
	/**
	 *
	 * @description Execute refresh of the system as a guard
	 * @return void
	 *
	 */
	const handleRefresh = () => {
		axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
		axios.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";
		
		const path = history.location.pathname;
		
		if (isAuthenticated) {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`;
			
			if (path === '/' || path === '/forgot-password' || path === '/password-reset' || path === '/verification') {
				history.push('dashboard/main');
			} else {
				history.push(path);
			}
		} else {
			handleReset();
			
			if (path === '/' || path.indexOf('dashboard/') === 1) {
				history.push('/');
			} else {
				history.push(path);
			}
		}
	};
	
	/**
	 *
	 * @description Execute reset global of the system
	 * @return void
	 *
	 */
	const handleReset = async () => {
		axios.defaults.headers.common['Authorization'] = '';
		await dispatch( setReset() );
		await localStorage.clear();
		await localStorage.setItem('persist:root', JSON.stringify(initialValuesAuth));
		await localStorage.clear();
	};
	
	useEffect(() => {
		handleRefresh();
	}, [
		history,
		isAuthenticated
	]);
	
	return (
		<HistoryRouter history={history}>
			<Routes>
				<Route path="/*" element={<PublicRouter />} />
				<Route path="/dashboard/*" element={<ProtectedRouter />} />
			</Routes>
		</HistoryRouter>
	);
};

AppRouter.propTypes = {
	history: PropTypes.object.isRequired
};

export default AppRouter;
