import * as types from '../../../../actionsTypes/dashboard/settings/properties/propertyMobiliTypes'
import { initialValuesPropertyMobili as initialValues } from '../../../../../helpers/variablesInitialValues'

const initialState = {
	arrayListImages: [],
	arrayListImages360: [],
	arrayListVideos: [],
	arrayListTours: [],
	isActiveButton: false,
	isLoadingButton: false,
	isLoadingForm: false,
	eventType: '',
	formData: initialValues
}

const propertyMobiliReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.ARRAY_LIST_IMAGES_PROPERTY_MOBILI:
			return {
				...state,
				arrayListImages: payload
			}
		
		case types.ARRAY_LIST_IMAGES360_PROPERTY_MOBILI:
			return {
				...state,
				arrayListImages360: payload
			}
		
		case types.ARRAY_LIST_VIDEOS_PROPERTY_MOBILI:
			return {
				...state,
				arrayListVideos: payload
			}
		
		case types.ARRAY_LIST_TOURS_PROPERTY_MOBILI:
			return {
				...state,
				arrayListTours: payload
			}
		
		case types.IS_ACTIVE_BUTTON_PROPERTY_MOBILI:
			return {
				...state,
				isActiveButton: payload
			}
			
		case types.IS_LOADING_BUTTON_PROPERTY_MOBILI:
			return {
				...state,
				isLoadingButton: payload
			}
			
		case types.IS_LOADING_FORM_PROPERTY_MOBILI:
			return {
				...state,
				isLoadingForm: payload
			}
		
		case types.EVENT_TYPE_PROPERTY_MOBILI:
			return {
				...state,
				eventType: payload
			}
			
		case types.FORM_DATA_PROPERTY_MOBILI:
			return {
				...state,
				formData: payload
			}
		
		default:
			return state
	}
}

export default propertyMobiliReducer
