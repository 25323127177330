import React from 'react'
import MuiTooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import Zoom from '@material-ui/core/Zoom'

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: '10px !important',
        top: '10px',
        position: 'relative'
    }
}))

export default function Tooltip(props) {

    const classes = useStylesBootstrap()
    const { label, placement, children, ...other } = props

    return (
        <MuiTooltip 
            arrow 
            title={label} 
            placement={placement} 
            classes={classes} 
            TransitionComponent={Zoom}
            {...other}
        >
            {children}
        </MuiTooltip>
    )
}