import React from 'react'
import Apps from '@material-ui/icons/Apps'

export default function AppsIcon(props) {

    const { ...other } = props

    return (
        <Apps { ...other } />
    )
}
