import React  from 'react'
import { Popover as MuiPopover } from '@material-ui/core'

export default function Popover(props) {
    const { open, title, verticalAlign, textAlign, onConfirm, ...other } = props

    return (
        <MuiPopover
            open={open}
            anchorOrigin={{
                vertical: verticalAlign,
                horizontal: textAlign,
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            {title}
        </MuiPopover>
    )
}
