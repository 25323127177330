export const PER_PAGE_AMENITY = 'PER_PAGE_AMENITY';
export const PAGE_AMENITY = 'PAGE_AMENITY';
export const SEARCH_AMENITY = 'SEARCH_AMENITY';
export const PAGES_AMENITY = 'PAGES_AMENITY';
export const ARRAY_LIST_AMENITY = 'ARRAY_LIST_AMENITY';
export const ARRAY_LIST_TO_DROPDOWN_COMMERCIAL_AREA_AMENITY = 'ARRAY_LIST_TO_DROPDOWN_COMMERCIAL_AREA_AMENITY';
export const ARRAY_LIST_TO_DROPDOWN_LEGAL_AREA_AMENITY = 'ARRAY_LIST_TO_DROPDOWN_LEGAL_AREA_AMENITY';
export const ARRAY_LIST_TO_DROPDOWN_COMMERCIAL_BUILDING_AMENITY = 'ARRAY_LIST_TO_DROPDOWN_COMMERCIAL_BUILDING_AMENITY';
export const REFRESH_TABLE_AMENITY = 'REFRESH_TABLE_AMENITY';
export const IS_OPEN_MODAL_AMENITY = 'IS_OPEN_MODAL_AMENITY';
export const IS_LOADING_SHOW_AMENITY = 'IS_LOADING_SHOW_AMENITY';
export const IS_LOADING_FORM_AMENITY = 'IS_LOADING_FORM_AMENITY';
export const FORM_TYPE_AMENITY = 'FORM_TYPE_AMENITY';
export const FORM_DATA_AMENITY = 'FORM_DATA_AMENITY';
