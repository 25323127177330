const ROOT = 'dashboard/owners';

export default {
	arrayList: `${ROOT}/list`,
	arrayListProperties: `${ROOT}/properties/list`,
	store: `${ROOT}`,
	show: `${ROOT}/`,
	showByMobile: `${ROOT}/by-mobile/`,
	status: `${ROOT}/status/`,
	update: `${ROOT}/`,
	destroy: `${ROOT}/`,
};
