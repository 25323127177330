import * as types from '../../actionsTypes/algolia/algoliaPropertyTypesTypes'

const initialState = {
	arrayListToDropdown: [],
	isLoading: false
}

const algoliaPropertyTypeReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.ARRAY_LIST_TO_DROPDOWN_ALGOLIA_PROPERTY_TYPE:
			return {
				...state,
				arrayListToDropdown: payload
			}
		
		case types.IS_LOADING_ALGOLIA_PROPERTY_TYPE:
			return {
				...state,
				isLoading: payload
			}
		
		default:
			return state
	}
}

export default algoliaPropertyTypeReducer
