import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import PropTypes from "prop-types";
import { theme } from "../theme-v2/theme";

const ThemeProviderComponent = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme()}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ThemeProviderComponent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ThemeProviderComponent;
