import React from 'react'
import Layout from '../Layout'
import Feedback from '../Feedback'

const KanbanRowLoading = () => {
	const arrayList = [
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{}
	]
	
	return (
		<>
			{arrayList.length > 0 && (
				arrayList.map((row, index) => (
					
					<Layout.Card className="card-filter border-radius-5 bg-white m-0 mb-2 p-2 animate__animated animate__fadeIn">
						<Layout.CardContent className="m-0 pt-1 pl-3 pr-3 pb-3">
							<div className="row mb-1">
								<div className="col-3 m-0 p-0">
									<Feedback.Skeleton
										variant="text"
										width="75%"
										height={25}
									/>
								</div>
								<div className="col-6 m-0 p-0">
									<Feedback.Skeleton
										className="mx-auto d-flex justify-content-center"
										variant="text"
										width="100%"
										height={25}
									/>
								</div>
								<div className="col-3 m-0 p-0">
									<Feedback.Skeleton
										className="d-flex float-end"
										variant="text"
										width="75%"
										height={25}
									/>
								</div>
							</div>
							<div className="row mb-1">
								<div className="col-12 m-0 p-0">
									<Feedback.Skeleton
										variant="text"
										width="60%"
										height={25}
									/>
								</div>
							</div>
							<div className="row mb-1">
								<div className="col-12 col-sm-6 m-0 p-0">
									<Feedback.Skeleton
										variant="text"
										width="50%"
										height={25}
									/>
								</div>
								<div className="col-12 col-sm-6 m-0 p-0">
									<Feedback.Skeleton
										className="d-flex float-end"
										variant="text"
										width="25%"
										height={25}
									/>
								</div>
							</div>
						</Layout.CardContent>
					</Layout.Card>
				))
			)}
		</>
	)
}

export default KanbanRowLoading
