import { initialValuesCity as initialValues } from '../../../../../../../helpers/variablesInitialValues';
import * as types from '../types/cityType';

/**
 *
 * @description Set reset city state
 * @return dispatch
 *
 */
export const setCityReset = () => (
	async (dispatch) => {
		await dispatch( setPerPage(10) );
		await dispatch( setPage(1) );
		await dispatch( setPages(1) );
		await dispatch( setSearch('') );
		await dispatch( setArrayList([]) );
		await dispatch( setArrayListToDropdown([]) );
		await dispatch( setRefreshTable(false) );
		await dispatch( setIsOpenModal(false) );
		await dispatch( setIsLoadingShow(false) );
		await dispatch( setIsLoadingForm(false) );
		await dispatch( setFormType('') );
		await dispatch( setFormData(initialValues) );
	}
);

/**
 *
 * @description Set perPage state
 * @param value
 * @return object
 *
 */
export const setPerPage = value => ({
	type: types.PER_PAGE_CITY,
	payload: value
});

/**
 *
 * @description Set page state
 * @param value
 * @return object
 *
 */
export const setPage = value => ({
	type: types.PAGE_CITY,
	payload: value
});

/**
 *
 * @description Set pages state
 * @param value
 * @return object
 *
 */
export const setPages = value => ({
	type: types.PAGES_CITY,
	payload: value
});

/**
 *
 * @description Set search state
 * @param value
 * @return object
 *
 */
export const setSearch = value => ({
	type: types.SEARCH_CITY,
	payload: value
});

/**
 *
 * @description Set arrayList state
 * @param value
 * @return object
 *
 */
export const setArrayList = value => ({
	type: types.ARRAY_LIST_CITY,
	payload: value
});

/**
 *
 * @description Set arrayListDropdown state
 * @param value
 * @return object
 *
 */
export const setArrayListToDropdown = value => ({
	type: types.ARRAY_LIST_TO_DROPDOWN_CITY,
	payload: value
});

/**
 *
 * @description Set refreshTable state
 * @param value
 * @return object
 *
 */
export const setRefreshTable = value => ({
	type: types.REFRESH_TABLE_CITY,
	payload: value
});

/**
 *
 * @description Set isOpenModal state
 * @param value
 * @return object
 *
 */
export const setIsOpenModal = value => ({
	type: types.IS_OPEN_MODAL_CITY,
	payload: value
});

/**
 *
 * @description Set isLoadingShow state
 * @param value
 * @return object
 *
 */
export const setIsLoadingShow = value => ({
	type: types.IS_LOADING_SHOW_CITY,
	payload: value
});

/**
 *
 * @description Set isLoadingForm state
 * @param value
 * @return object
 *
 */
export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_CITY,
	payload: value
});

/**
 *
 * @description Set formType state
 * @param value
 * @return object
 *
 */
export const setFormType = value => ({
	type: types.FORM_TYPE_CITY,
	payload: value
});

/**
 *
 * @description Set formData state
 * @param value
 * @return object
 *
 */
export const setFormData = value => ({
	type: types.FORM_DATA_CITY,
	payload: value
});
