import * as types from '../../actionsTypes/owners/ownerAuthorizationTypes'
import { initialValuesOwnerAuthorization as initialValues } from '../../../helpers/variablesInitialValues'

const initialState = {
	messageStatus: '',
	typeStatus: '',
	isActiveButton: false,
	isLoadingForm: false,
	formData: initialValues
}

const ownerAuthorizationReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.MESSAGE_STATUS_OWNER_AUTHORIZATION:
			return {
				...state,
				messageStatus: payload
			}
			
		case types.TYPE_STATUS_OWNER_AUTHORIZATION:
			return {
				...state,
				typeStatus: payload
			}
		
		case types.IS_ACTIVE_BUTTON_OWNER_AUTHORIZATION:
			return {
				...state,
				isActiveButton: payload
			}
		
		case types.IS_LOADING_FORM_OWNER_AUTHORIZATION:
			return {
				...state,
				isLoadingForm: payload
			}
			
		case types.FORM_DATA_OWNER_AUTHORIZATION:
			return {
				...state,
				formData: payload
			}
		
		default:
			return state
	}
}

export default ownerAuthorizationReducer
