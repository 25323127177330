import endPoints from './endPoints';
import axios from '../../../services/axios';

const {
	signIn,
	verification,
	resendVerification,
	forgotPassword,
	passwordVerification,
	passwordResendVerification,
	passwordReset,
	signOut
} = endPoints;

/**
 *
 * @description Execute endpoint login
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const setLogIn = async (data) => (
	await axios.post(signIn, data)
);

/**
 *
 * @description Execute endpoint verification
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const getVerification = async (id) => (
	await axios.get(`${verification}${id}`)
);

/**
 *
 * @description Execute endpoint resend verification
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const setResendVerification = async (id) => (
	await axios.get(`${resendVerification}${id}`)
);

/**
 *
 * @description Execute endpoint forgot password
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const setForgotPassword = async (data) => (
	await axios.post(forgotPassword, data)
);

/**
 *
 * @description Execute endpoint forgot password verification
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const getPasswordVerification = async (id) => (
	await axios.get(`${passwordVerification}${id}`)
);

/**
 *
 * @description Execute endpoint forgot password resend verification
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const setPasswordResendVerification = async (id) => (
	await axios.get(`${passwordResendVerification}${id}`)
);

/**
 *
 * @description Execute endpoint password reset
 * @param id
 * @param data
 * @method put
 * @return jsonResponse
 *
 */
export const setPasswordReset = async (id, data) => (
	await axios.put(`${passwordReset}${id}`, data)
);

/**
 *
 * @description Execute endpoint logout
 * @method get
 * @return jsonResponse
 *
 */
export const setLogOut = async () => (
	await axios.get(signOut)
);
