import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Inputs from '../../../../../components/mui/Inputs';
import Layout from '../../../../../components/mui/Layout';
import Feedback from '../../../../../components/mui/Feedback';
import { setStore, getShowById, setUpdateById } from '../services';
import { setFormData, setIsLoadingShow, setIsLoadingForm, setRefreshTable, setIsOpenModal } from '../redux/actions/permissionAction';
import usePermission from '../../../../../hooks/usePermission';
import swalErrors from '../../../../../hooks/useErrors';
import { swalSuccess } from '../../../../../hooks/useSweetAlert';
import { initialValuesPermission as initialValues } from '../../../../../helpers/variablesInitialValues';

const FormComponent = () => {
	const dispatch = useDispatch();
	const refreshTable = useSelector( ({ permission }) => permission.refreshTable);
	const isLoadingShow = useSelector( ({ permission }) => permission.isLoadingShow);
	const isLoadingForm = useSelector( ({ permission }) => permission.isLoadingForm);
	const formType = useSelector( ({ permission }) => permission.formType);
	const formData = useSelector( ({ permission }) => permission.formData);
	const permissionStore = usePermission('permissions.store');
	const permissionShow = usePermission('permissions.show');
	const permissionUpdate = usePermission('permissions.update');
	
	/**
	 *
	 * @description Execute endpoint show by id
	 * @return dispatch
	 *
	 */
	const handleShowById = async () => {
		if (formType === 'show' && permissionShow) {
			await dispatch( setIsLoadingShow(true) );
			
			try {
				const { data } = await getShowById(formData.id);
				
				if (data.status === 200) {
					const object = {
						id: data.data.id,
						name: data.data.name === null ? '' : data.data.name,
						publicName: data.data.public_name === null ? '' : data.data.public_name,
						description: data.data.description === null ? '' : data.data.description,
						group: data.data.group === null ? '' : data.data.group
					};
					
					await dispatch( setFormData(object) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingShow(false) );
		}
	};
	
	useEffect(() => {
		handleShowById();
	}, [formType]);
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			name: Yup.string()
			.required('El nombre es requerido'),
			publicName: Yup.string()
			.required('El nombre público es requerido'),
			description: Yup.string()
			.required('La descripción es requerida'),
			group: Yup.string()
			.required('El grupo es requerido')
		})
	);
	
	/**
	 *
	 * @description Execute submit of the form
	 * @param values
	 * @param resetForm
	 * @param setValues
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		if (formType === 'store' ? permissionStore : permissionUpdate) {
			await dispatch( setIsLoadingForm(true) );
			
			try {
				const param = {
					name: formData.name === '' ? null : formData.name,
					public_name: formData.publicName === '' ? null : formData.publicName,
					description: formData.description === '' ? null : formData.description,
					group: formData.group === '' ? null : formData.group
				}
				
				let data;
				
				if (formType === 'store') {
					data = await setStore(param);
				} else {
					data = await setUpdateById(formData.id, param);
				}
				
				if (data.data.status === 200) {
					resetForm({ values: initialValues});
					setValues(initialValues);
					await swalSuccess('Captación exitosa', data.data.data);
					await dispatch( setFormData(initialValues) );
					await dispatch( setRefreshTable(!refreshTable) );
					await dispatch( setIsOpenModal(false) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingForm(false) );
		}
	};
	
	/**
	 *
	 * @description Execute dispatch formData state
	 * @return dispatch
	 *
	 */
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.value }) );
	
	return (
		<>
			{isLoadingShow && (
				<Feedback.Loading />
			)}
			{!isLoadingShow && (
				<Formik
					initialValues={formData}
					validationSchema={validationSchema}
					onSubmit={hanbleSubmit}
				>
					{({
					  errors,
					  touched,
					  handleChange,
					  handleBlur,
					  isValid,
					  dirty
				  }) => (
						<Form noValidate>
							<div className="row animate__animated animate__fadeIn">
								<div className="col-sm-12">
									<Inputs.TextBox
										name="name"
										label="Nombre"
										placeholder="Ingrese nombre..."
										value={formData.name}
										onBlur={handleBlur}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										hasError={errors.name && touched.name ? true : false}
										errorMessage={errors.name && touched.name ? errors.name : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12">
									<Inputs.TextBox
										name="publicName"
										label="Nombre público"
										placeholder="Ingrese nombre público..."
										value={formData.publicName}
										onBlur={handleBlur}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										hasError={errors.publicName && touched.publicName ? true : false}
										errorMessage={errors.publicName && touched.publicName ? errors.publicName : ''}
										className="font-family-roboto-medium text-dark fs-6 w-100 mt-3"
									/>
								</div>
								<div className="col-sm-12">
									<Inputs.TextBox
										name="description"
										label="Descripción"
										placeholder="Ingrese descripción..."
										value={formData.description}
										onBlur={handleBlur}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										hasError={errors.description && touched.description ? true : false}
										errorMessage={errors.description && touched.description ? errors.description : ''}
										className="font-family-roboto-medium text-dark fs-6 w-100 mt-3"
									/>
								</div>
								<div className="col-sm-12">
									<Inputs.TextBox
										name="group"
										label="Grupo"
										placeholder="Ingrese grupo..."
										value={formData.group}
										onBlur={handleBlur}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										hasError={errors.group && touched.group ? true : false}
										errorMessage={errors.group && touched.group ? errors.group : ''}
										className="font-family-roboto-medium text-dark fs-6 w-100 mt-3"
									/>
								</div>
								{(formType === 'store' ? permissionStore : permissionUpdate) && (
									<div className="col-sm-12">
										<Inputs.LoadingButton
											isDisabled={formType === 'store' ? !(dirty && isValid) : false}
											isLoading={isLoadingForm}
											isLoadingPosition="start"
											startIcon={formType === 'store' ? <Layout.Icons.SaveIcon height={18} width={18} /> : <Layout.Icons.EditIcon height={18} width={18} />}
											label={`${formType === 'store' ? 'Guardar' : 'Actualizar'}`}
											type="submit"
											className={`${formType === 'store' ? ((dirty && isValid) && 'bg-primary') : 'bg-primary'} font-family-roboto-regular fs-6 text-capitalize mt-2 d-flex float-end`}
										/>
									</div>
								)}
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default FormComponent;
