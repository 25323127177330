import React, { useState } from 'react'
import MuiMuiSwitch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiSwitch-colorPrimary.Mui-checked' : {
            color: '#85BF02 !important'
        },
        '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track' : {
            backgroundColor: '#b5c5b6 !important'
        }
    }
}))

export default function Switch(props) {
    const classes = useStyles()
    const { label, name, checked, ...other } = props
    const [state, setState] = useState(checked)

    const handleChange = () => {
        setState(!checked)
    }

    return (
        <>
            { 
                label === "" ?
                    <MuiMuiSwitch 
                        checked={checked || state}
                        name={name}
                        color="primary"
                        classes={{ root: classes.root }}
                        {...other}
                    />
                :
                    <FormControlLabel
                        control={
                            <MuiMuiSwitch 
                                checked={checked || state}
                                name={name}
                                onChange={handleChange}
                                color="primary"
                                classes={{ root: classes.root }}
                                {...other}
                            />
                        }
                        label={label}
                        labelPlacement="end"
                    />
            }
        </>
    )
}
