export const PER_PAGE_USER = 'PER_PAGE_USER'
export const PAGE_USER = 'PAGE_USER'
export const PAGES_USER = 'PAGES_USER'
export const SEARCH_USER = 'SEARCH_USER'
export const REALSTATE_USER = 'REALSTATE_USER'
export const ARRAY_LIST_USER = 'ARRAY_LIST_USER'
export const ARRAY_LIST_TO_DROPDOWN_USER = 'ARRAY_LIST_TO_DROPDOWN_USER'
export const ARRAY_LIST_TO_DROPDOWN_AGENT_USER = 'ARRAY_LIST_TO_DROPDOWN_AGENT_USER'
export const ARRAY_LIST_TO_DROPDOWN_ADVISER_USER = 'ARRAY_LIST_TO_DROPDOWN_ADVISER_USER'
export const REFRESH_TABLE_USER = 'REFRESH_TABLE_USER'
export const IS_OPEN_MODAL_USER = 'IS_OPEN_MODAL_USER'
export const IS_OPEN_MODAL_PERMISSION_USER = 'IS_OPEN_MODAL_PERMISSION_USER'
export const IS_LOADING_FORM_USER = 'IS_LOADING_FORM_USER'
export const FORM_TYPE_USER = 'FORM_TYPE_USER'
export const FORM_DATA_USER = 'FORM_DATA_USER'
