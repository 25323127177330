import React from 'react'
import PropTypes from 'prop-types'
import { initialValuesTimezone } from '../../../helpers/variablesInitialValues'

const Timezone = ({ date = '', dateAt = '' }) => {
	if(!date && !dateAt) return '';
	if (date !== '' && dateAt !== '') {
		const today = new Date(dateAt * 1000)
		
		let str = today.toLocaleDateString("en-GB", initialValuesTimezone)
		str = str.replace('/', '-')
		str = str.replace('/', '-')
		str = str.replace(',', '')
		
		const newStr = str.split(' ')
		
		return `${date} - ${newStr[1]}`
	}
	
	return ''
}

Timezone.propTypes = {
	date: PropTypes.string,
	dateAt: PropTypes.any
}

export default Timezone

