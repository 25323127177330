import React from 'react'
import PropTypes from 'prop-types'
import Feedback from '../Feedback'

const Table = ({ children, className = '' }) => {
	return (
		<div className="row mt-2 mb-3">
			<div className="col-sm-12">
				<div className="table-responsive">
					<Feedback.ScrollBar style={{ width: '100%' }}>
						<table className={className === '' ? "table table-bordered table-striped table-hover align-middle m-0 w-100" : className}>
							{children}
						</table>
					</Feedback.ScrollBar>
				</div>
			</div>
		</div>
	)
}

Table.propTypes = {
	children: PropTypes.any.isRequired,
	className: PropTypes.string
}

export default Table
