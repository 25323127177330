import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Layout from '../../../../components/mui/Layout'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from "@material-ui/core/CardContent"
import Display from "../../../../components/display/Display"
import Controls from "../../../../components/controls/Controls"
import Feedback from "../../../../components/feedback/Feedback"
import FileUpload from "../PropertyOldPage/components/FileUpload"
import errorServices from '../../../../services/errors'
import { swalSuccess } from '../../../../services/sweetalert'
import axios from '../../../../services/axios'

const PropertyFileOldPage = () => {
	const params = useParams()
	const uID = params.uID
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [getPropertyID, setPropertyID] = useState(uID)
	const [getTotalRecords, setTotalRecords] = useState(0)
	const [loading, setLoading] = useState(false)
	const [getModule, setModule] = useState({ module: 'Captación de la Propiedad', title: 'Captación de las Propiedades', subtitle: 'Paso 9 - Cargar Archivos' })
	const [getOpenModal, setOpenModal] = useState(false)
	const [getTitleModal, setTitleModal] = useState('')
	
	const getHandleEditProperty = async () => {
		setLoading(false)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/${uID}`)
			
			if (data.status === 200) {
				if (data.data.data.has_building === 'Si'){
					setModule({ title: 'Captación de las Propiedades', subtitle: 'Paso 8 - Cargar Archivos' })
				}
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	useEffect(() => {
		getHandleEditProperty()
	}, [])
	
	const handleModal = () => {
		setPropertyID(uID)
		setOpenModal(true)
		setTitleModal(`Cargar archivos a la propiedad`)
	}
	
	const handleList = () => setLoading(!loading)
	
	return (
		<Layout.Page title="Propiedades - Archivos">
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Card className="p-3">
						<CardContent>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={6}>
									<Display.Typography
										variant="h5"
										color="textPrimary"
										label={getModule.title}
									/>
									<Display.Typography
										variant="subtitle1"
										color="textSecondary"
										label={getModule.subtitle}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={6}>
									<div className="float-right">
										<Controls.Button
											label="Subir archivos"
											color="primary"
											variant="contained"
											onClick={handleModal}
											endIcon={<Display.Icons.AddOutlinedIcon style={{ fontSize: 18 }} />}
										/>
										<Controls.ButtonAction
											label="Atrás"
											color="secondary"
											variant="contained"
											startIcon={<Display.Icons.ChevronLeftIcon />}
											href={`/dashboard/propiedades/servicios/${uID}`}
										/>
										<Controls.ButtonAction
											label="Siguiente"
											color="primary"
											variant="contained"
											endIcon={<Display.Icons.ChevronRightIcon />}
											href={`/dashboard/propiedades/estatus-comercial/${uID}`}
										/>
									</div>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			
			<FileUpload
				has_origin={false}
				getPropertyID={getPropertyID}
				handleList={handleList}
				getTitleModal={getTitleModal}
				getOpenModal={getOpenModal}
				setOpenModal={setOpenModal}
			/>
		</Layout.Page>
	)
}

export default PropertyFileOldPage
