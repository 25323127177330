import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Feedback from '../../../../components/mui/Feedback';
import FormComponent from './FormComponent';
import DeleteComponent from './DeleteComponent';
import ListPropertyCards from './ListPropertyCards';
import { setFormData, setFormType, setIsOpenModal, setIsLoadingShow, setIsLoadingForm } from '../redux/actions/ownerAction';
import usePermission from '../../../../hooks/usePermission';
import { initialValuesOwner as initialValues } from '../../../../helpers/variablesInitialValues';

const ModalComponent = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector( ({ owner }) => owner.isOpenModal);
  const formType = useSelector( ({ owner }) => owner.formType);
  const { personType, company, firstName, lastName } = useSelector(state => state.owner.formData);
  const permissionStore = usePermission('owners.store');
  const permissionShow = usePermission('owners.show');
  const permissionDelete = usePermission('owners.delete');
  const permissionPropertiesList = usePermission('owners.properties.list');
  let name = `${firstName} ${lastName}`;

  if (personType !== 'Persona Natural') {
    name = company;
  }
  
  /**
   *
   * @description Execute reset formData, loading show, loading form, close modal state
   * @return dispatch
   *
   */
  const handleCloseModal = async () => {
    await dispatch( setFormData(initialValues) );
    await dispatch( setFormType('') );
    await dispatch( setIsLoadingShow(false) );
    await dispatch( setIsLoadingForm(false) );
    await dispatch( setIsOpenModal(false) );
  };

  const ObjTitle = {
    delete: `Eliminación del propietario`,
    store: 'Captación de propietarios',
    show: 'Captación de propietarios',
    properties: 'Propiedades asignadas',
  };

  return (
    <Feedback.Modal
      maxWidth={formType === 'Delete' ? 'sm' : 'md'}
      className={formType === 'Delete' ? 'h-auto' : 'h-100'}
      isOpen={isOpenModal}
      isClose={handleCloseModal}
      title={ObjTitle[formType]}
    >
      {(formType === 'store' || formType === 'show') && (
        (formType === 'store' ? permissionStore : permissionShow) && (
          <div className="h-100">
            <FormComponent />
          </div>
        )
      )}
      {formType === 'delete' && (
        permissionDelete && (
          <DeleteComponent />
        )
      )}
      {formType === 'properties' && (
        permissionPropertiesList && (
          <ListPropertyCards />
        )
      )}
    </Feedback.Modal>
  );
};

export default ModalComponent;
