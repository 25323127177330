import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const CheckBox = (props) => {
	const {
		label,
		name,
		isChecked = false,
		position,
		value,
		size = 'medium',
		readOnly = false,
		hasError = false,
		errorMessage = '',
		...more
	} = props;
	const [isCheck, setIsCheck] = useState(isChecked);
	
	const handleChange = () => setIsCheck(!isCheck);
	
	return (
		<FormControl
			component="fieldset"
			error={hasError}
		>
			<FormGroup
				aria-label="position"
				row
			>
				<FormControlLabel
					control={
						<MuiCheckbox
							color="primary"
							name={name}
							checked={isCheck}
							size={size}
							readOnly={readOnly}
							onClick={handleChange}
							{...more}
						/>
					}
					label={label}
					labelPlacement={position}
					value={value}
				/>
			</FormGroup>
			{hasError && errorMessage !== "" && (
				<div className="mt-1 bg-grey-light border-l-4 border-danger p-2 font-family-roboto-regular fs-6 text-danger animate__animated animate__fadeIn">
					{errorMessage}
				</div>
			)}
		</FormControl>
	);
};

CheckBox.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	isChecked: PropTypes.bool,
	position: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,
	size: PropTypes.string,
	readOnly: PropTypes.bool,
	hasError: PropTypes.bool,
	errorMessage: PropTypes.string
};

export default CheckBox;
