import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

import useContact from "../hooks/useContact";

import * as Styles from "../../../../../components/v2-mobili/modals/styles";

import LatestCasesComp from "./comp/LastestCasesComp";

import CellPhoneField from "./fields/CellPhoneField";
import FirstNamesField from "./fields/FirstNamesField";
import LastNamesField from "./fields/LastNamesField";
import SourceChannelField from "./fields/SourceChannelField";
import ContactSourceField from "./fields/ContactSourceField";
import ValidateButton from "./fields/ValidateButton";

const validatorSchema = Yup.object().shape({
  firstNames: Yup.string().required("Campo nombres es requerido"),
  lastNames: Yup.string().required("Campo apellidos es requerido"),
  cellphone: Yup.number()
    .nullable()
    .positive("no se permiten negativos")
    .required("Requerido"),
  sourceChannel: Yup.string().required("Campo de canal requerido"),
  contactSource: Yup.string().required("Campo de fuente requerido"),
});
const ModalForContract = () => {
  const { validCellphone, changeValidatePhoneStatus } = useContact();
  const [formOpenState, setFormOpenState] = React.useState(true);
  const [hiddenButtons, setHiddenButtons] = React.useState(false);
  const closeModal = () => setFormOpenState(false);

  const initialValues = {
    firstNames: "",
    lastNames: "",
    cellphone: "",
    sourceChannel: "",
    contactSource: "",
  };

  return (
    <Dialog
      maxWidth="lg"
      open={formOpenState}
      transitionDuration={200}
      onClose={closeModal}
      PaperProps={{
        sx: {
          ...Styles.PaperPropsStyles,
          maxWidth: "660px",
        },
      }}
    >
      <DialogContent
        sx={{
          mt: 0,
          gap: (theme) => theme.spacing(25 / 8),
          ...Styles.DialogContentStyle,
          p: (theme) => theme.spacing(30 / 8),
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validatorSchema}
          onSubmit={(values, {}) => {}}
        >
          {({ values, resetForm }) => (
            <Form
              style={{
                gap: "25px",
                display: "flex",
                // outline: "1px solid red",
                flexDirection: "column",
              }}
            >
              <Grid
                item
                top={0}
                right="5px"
                zIndex={3}
                alignSelf="flex-end"
                position="absolute"
              >
                <IconButton
                  color="primary"
                  sx={{ color: "mobiligrey.default" }}
                  onClick={() => {
                    closeModal();
                    resetForm();
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </Grid>
              <Typography color="darkGrey.default" variant="h3">
                Nuevo Contacto
              </Typography>

              {/* FORM */}
              <Box display="flex" gap={25 / 8} flexDirection="column">
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                <Grid
                  container
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gap={2}
                >
                  <CellPhoneField />
                  <ValidateButton />
                </Grid>
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={20 / 8}>
                  <FirstNamesField />
                  <LastNamesField />
                </Box>
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={20 / 8}>
                  <SourceChannelField />
                  <ContactSourceField />
                </Box>
              </Box>
              {/* END FORM */}
              {/* Mientras no se haya validado el celular, seguimos reservando el alto de 40px, */}
              {validCellphone === false && (
                <Box
                  height="40px"
                  onClick={() => setHiddenButtons((p) => !p)}
                  overflow="hidden"
                >
                  <Collapse in={!hiddenButtons} sx={{ height: "100%" }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      height="100%"
                    >
                      {/* <Grid item height="100%"> */}
                      <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ height: "100%", fontWeight: "700" }}
                      >
                        GUARDAR COMO BORRADOR
                      </Button>
                      {/* </Grid> */}
                      <Button
                        disableElevation
                        variant="contained"
                        sx={{ height: "40px", fontWeight: "700" }}
                        onClick={() => {
                          setHiddenButtons((p) => !p);
                          changeValidatePhoneStatus(false);
                        }}
                      >
                        CREAR Y CONTINUAR
                      </Button>
                    </Grid>
                  </Collapse>
                </Box>
              )}
            </Form>
          )}
        </Formik>
        <Collapse in={validCellphone}>
          <LatestCasesComp />
        </Collapse>
        {validCellphone && (
          <Box height="41px" onClick={() => {}} overflow="hidden">
            <Collapse in={!hiddenButtons} sx={{ height: "100%" }}>
              <Grid container justifyContent="space-between" height="100%">
                {/* <Grid item height="100%"> */}
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ height: "100%", fontWeight: "700", minWidth: "150px" }}
                  onClick={() => {
                    changeValidatePhoneStatus(false);
                  }}
                >
                  REGRESAR
                </Button>
                {/* </Grid> */}
                <Button
                  disableElevation
                  variant="contained"
                  sx={{ height: "40px", fontWeight: "700", minWidth: "200px" }}
                  onClick={() => {}}
                >
                  CREAR NUEVO CASO
                </Button>
              </Grid>
            </Collapse>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalForContract;
