// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
export const imageFileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  `image/x-icon`,
];

export const videoFileTypes = [
  "video/mp4",
  "video/mpeg",
  "video/ogg",
  "video/quicktime",
  "video/webm",
  "video/x-msvideo",
  "video/x-flv",
  "video/x-matroska",
  "video/x-ms-wmv",
  'application/x-mpegURL',
  'video/3gpp',
  'video/MP2T'
]

/**
 * @param { File | Blob } file 
 */
export const validAssetFileType = (file, asset_type) => {
  if(asset_type == 1 || asset_type == 2){
    return imageFileTypes.includes(file.type);
  }
  else if(asset_type == 3){
    // video
    return videoFileTypes.includes(file.type);
  }
  else {
    throw new Error("This should not happend, Tour virtual is not uploaded from this box")
  }
  
};
/**
 * @param {number} size
 */
export const returnFileSize = (size) => {
  if (size < 1024) {
    return size + "bytes";
  } else if (size > 1024 && size < 1048576) {
    return (size / 1024).toFixed(1) + "KB";
  } else if (size > 1048576) {
    return (size / 1048576).toFixed(1) + "MB";
  }
};