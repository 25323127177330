import React from 'react'
import ImportExport from '@material-ui/icons/ImportExport'

export default function ImportExportIcon(props) {
    const { ...other } = props

    return (
        <ImportExport {...other} />
    )
}
