export const PER_PAGE_OWNER = 'PER_PAGE_OWNER';
export const PER_PAGE_PROPERTIES_OWNER = 'PER_PAGE_PROPERTIES_OWNER';
export const PAGE_OWNER = 'PAGE_OWNER';
export const PAGE_PROPERTIES_OWNER = 'PAGE_PROPERTIES_OWNER';
export const PAGES_OWNER = 'PAGES_OWNER';
export const PAGES_PROPERTIES_OWNER = 'PAGES_PROPERTIES_OWNER';
export const HAS_NEXT_PAGE_PROPERTIES_OWNER = 'HAS_NEXT_PAGE_PROPERTIES_OWNER';
export const SEARCH_OWNER = 'SEARCH_OWNER';
export const PERSON_TYPE_OWNER = 'PERSON_TYPE_OWNER';
export const ARRAY_LIST_OWNER = 'ARRAY_LIST_OWNER';
export const ARRAY_LIST_PROPERTIES_OWNER = 'ARRAY_LIST_PROPERTIES_OWNER';
export const ARRAY_LIST_TO_DROPDOWN_OWNER = 'ARRAY_LIST_TO_DROPDOWN_OWNER';
export const REFRESH_TABLE_OWNER = 'REFRESH_TABLE_OWNER';
export const IS_OPEN_MODAL_OWNER = 'IS_OPEN_MODAL_OWNER';
export const IS_LOADING_SHOW_OWNER = 'IS_LOADING_SHOW_OWNER';
export const IS_LOADING_FORM_OWNER = 'IS_LOADING_FORM_OWNER';
export const IS_LOADING_PROPERTIES_OWNER = 'IS_LOADING_PROPERTIES_OWNER';
export const FORM_TYPE_OWNER = 'FORM_TYPE_OWNER';
export const FORM_DATA_OWNER = 'FORM_DATA_OWNER';
