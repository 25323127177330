export const PER_PAGE_PROPERTY_RESERVE = 'PER_PAGE_PROPERTY_RESERVE';
export const PAGE_PROPERTY_RESERVE = 'PAGE_PROPERTY_RESERVE';
export const SEARCH_PROPERTY_RESERVE = 'SEARCH_PROPERTY_RESERVE';
export const PAGES_PROPERTY_RESERVE = 'PAGES_PROPERTY_RESERVE';
export const ARRAY_LIST_PROPERTY_RESERVE = 'ARRAY_LIST_PROPERTY_RESERVE';
export const ARRAY_LIST_TO_DROPDOWN_PROPERTY_RESERVE = 'ARRAY_LIST_TO_DROPDOWN_PROPERTY_RESERVE';
export const REFRESH_TABLE_PROPERTY_RESERVE = 'REFRESH_TABLE_PROPERTY_RESERVE';
export const IS_OPEN_MODAL_PROPERTY_RESERVE = 'IS_OPEN_MODAL_PROPERTY_RESERVE';
export const IS_LOADING_SHOW_PROPERTY_RESERVE = 'IS_LOADING_SHOW_PROPERTY_RESERVE';
export const IS_LOADING_FORM_PROPERTY_RESERVE = 'IS_LOADING_FORM_PROPERTY_RESERVE';
export const FORM_TYPE_PROPERTY_RESERVE = 'FORM_TYPE_PROPERTY_RESERVE';
export const FORM_DATA_PROPERTY_RESERVE = 'FORM_DATA_PROPERTY_RESERVE';
