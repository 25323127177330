import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { history } from '../../../../redux/store'
import Layout from '../../../../components/mui/Layout'
import Inputs from '../../../../components/mui/Inputs'
import SearchComponent from '../components/SearchComponent'
import { setIsLoading } from '../../../../redux/actions/loadingAction'
import { Permission } from '../../../../services/permissions/Permission'
import { actionsSwitch } from '../../../../helpers/variablesColumns'
import errorServices from '../../../../services/errors'
import { swalSuccess } from '../../../../services/sweetalert'
import axios from '../../../../services/axios'

const PropertyActionOldPage = () => {
	const params = useParams()
	const uID = params.uID
	const dispatch = useDispatch()
	const { permissions } = useSelector( state  => state.auth.userData)
	const tokenType = useSelector(state => state.auth.tokenType)
	const accessToken = useSelector(state => state.auth.accessToken)
	const [isLoading, setLoadingForm] = useState(false)
	const [arrayPropertyReserve, setPropertyReserve] = useState([])
	const [showToLeaseAction, setShowToLeaseAction] = useState(true)
	const [showSellAction, setShowSellAction] = useState(true)
	const [showActual, setShowActual] = useState(false)
	const [formData, setFormData] = useState({
		action_type1: "Si",
		action_type2: "Si",
		value_admin: '',
		canon: "",
		cuota_admin: 8,
		market_commission: 20,
		insures_lease: "Si",
		ensures_administration: "Si",
		sale_value: "",
		reserve_id: "",
		sale_commission: 3,
		accept_swap: "No",
		is_leased: "No",
		canon_now: "",
		cuota_admin_now: "",
		contract_expiration_date: ""
	})
	const [module, setModule] = useState({
		step: 6,
		title: 'Acciones'
	})
	
	const validationSchema = () => {
		let validateObject = {}
		
		if (formData.action_type1 === 'Si' && formData.action_type2 === 'Si') {
			validateObject = {
				value_admin: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
				.required('El valor de administración es requerido'),
				canon: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
				.required('El canon es requerido'),
				market_commission: Yup.number()
				.required('La comisión de mercado es requerida'),
				sale_value: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
				.required('El valor de la venta es requerido'),
				sale_commission: Yup.number()
				.required('La comisión de venta es requerida')
			}
		} else if (formData.action_type1 === 'Si' && formData.action_type2 === 'No') {
			validateObject = {
				value_admin: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
				.required('El valor de administración es requerido'),
				canon: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
				.required('El canon es requerido'),
				market_commission: Yup.number()
				.required('La comisión de mercado es requerida')
			}
		} else if (formData.action_type1 === 'No' && formData.action_type2 === 'Si') {
			validateObject = {
				value_admin: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
				.required('El valor de administración es requerido'),
				sale_value: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
				.required('El valor de la venta es requerido'),
				sale_commission: Yup.number()
				.required('La comisión de venta es requerida')
			}
		} else if (formData.action_type1 === 'No' && formData.action_type2 === 'No') {
			validateObject = {
				value_admin: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
				.required('El valor de administración es requerido')
			}
		}
		
		return Yup.object(validateObject)
	}
	
	useEffect(() => {
		validationSchema()
	}, [
		formData.action_type1,
		formData.action_type2
	])
	
	const handleEditProperty = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`

				const data = await axios.get(`dashboard/properties/${uID}`)

				if (data.status === 200) {
					if (data.data.data.has_building === 'Si') {
						setModule({...module, step: 5 })
					}
				}
			} catch (error) {
				await errorServices(error)
			}
		}

		useEffect(() => {
			handleEditProperty()
		}, [])
	
	const handleEditPropertyAction = async () => {
		dispatch( setIsLoading(true) )

		await handleDropdownPropertyReserve()

		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`

			const data = await axios.get(`dashboard/properties/actions/${uID}`)

			if (data.status === 200) {
				const response = data.data.data
				setFormData({
					action_type1: (response.action_type === 'Arriendo' || response.action_type === 'Arriendo/Venta') ? 'Si' : 'No',
					action_type2: (response.action_type === 'Venta' || response.action_type === 'Arriendo/Venta') ? 'Si' : 'No',
					value_admin: response.value_admin === null ? '' : response.value_admin,
					canon: (response.action_type === 'Arriendo' || response.action_type === 'Arriendo/Venta') ? (response.canon === null ? '' : response.canon) : '',
					cuota_admin: (response.action_type === 'Arriendo' || response.action_type === 'Arriendo/Venta') ? (response.cuota_admin === null ? '' : response.cuota_admin) : '',
					market_commission: (response.action_type === 'Arriendo' || response.action_type === 'Arriendo/Venta') ? (response.market_commission === null ? '' : response.market_commission) : '',
					insures_lease: (response.action_type === 'Arriendo' || response.action_type === 'Arriendo/Venta') ? (response.insures_lease === 'Si' ? 'Si' : 'No') : 'No',
					ensures_administration: (response.action_type === 'Arriendo' || response.action_type === 'Arriendo/Venta') ? (response.ensures_administration === 'Si' ? 'Si' : 'No') : 'No',
					sale_value: (response.action_type === 'Venta' || response.action_type === 'Arriendo/Venta') ? (response.sale_value === null ? '' : response.sale_value) : '',
					reserve_id: (response.action_type === 'Venta' || response.action_type === 'Arriendo/Venta') ? (response.reserve_id === null ? '' : response.reserve_id) : '',
					sale_commission: (response.action_type === 'Venta' || response.action_type === 'Arriendo/Venta') ? (response.sale_commission === null ? '' : response.sale_commission) : '',
					accept_swap: (response.action_type === 'Arriendo' || response.action_type === 'Arriendo/Venta') ? (response.accept_swap === 'Si' ? 'Si' : 'No') : 'No',
					is_leased: (response.action_type === 'Arriendo' || response.action_type === 'Arriendo/Venta') ? (response.is_leased === 'Si' ? 'Si' : 'No') : 'No',
					canon_now: ((response.action_type === 'Venta' || response.action_type === 'Arriendo/Venta') && response.is_leased === 'Si') ? (response.canon_now === null ? '' : response.canon_now) : '',
					cuota_admin_now: ((response.action_type === 'Venta' || response.action_type === 'Arriendo/Venta') && response.is_leased === 'Si') ? (response.cuota_admin_now === null ? '' : response.cuota_admin_now) : '',
					contract_expiration_date: ((response.action_type === 'Venta' || response.action_type === 'Arriendo/Venta') && response.is_leased === 'Si') ? (response.contract_expiration_date === null ? '' : response.contract_expiration_date) : '',
				})
				setShowToLeaseAction((response.action_type === 'Arriendo' || response.action_type === 'Arriendo/Venta') ? true : false)
				setShowSellAction((response.action_type === 'Venta' || response.action_type === 'Arriendo/Venta') ? true : false)
				setShowActual(((response.action_type === 'Venta' || response.action_type === 'Arriendo/Venta') && response.is_leased === 'Si') ? true : false)
			}
		} catch (error) {
			await errorServices(error)
		}

		dispatch( setIsLoading(false) )
	}

	useEffect(() => {
		handleEditPropertyAction()
	}, [])
	
	const handleDropdownPropertyReserve = async () => {
		if (permissions.find((item) => item === 'propertyReserves.dropdown')) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
	
				const data = await axios.get(`dashboard/settings/properties/reserves`)
	
				if (data.status === 200) {
					setPropertyReserve(data.data.data.records)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	const hanbleSubmit = async () => {
		if (formData.action_type1 === 'No' && formData.action_type2 === 'No') {
			await history.push(`/dashboard/propiedades/condiciones/${uID}`)

			return false
		}

		setLoadingForm(true)

		let action_type = 'Arriendo'

		if (formData.action_type1 === 'Si' && formData.action_type2 === 'Si') {
			action_type = 'Arriendo/Venta'
		} else {
			if (formData.action_type2 === 'Si') {
				action_type = 'Venta'
			}
		}

		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`

			const param = {
				id: uID,
				action_type: action_type,
				value_admin: formData.value_admin === '' ? null : formData.value_admin,
				canon: formData.canon === '' ? null : formData.canon,
				cuota_admin: formData.cuota_admin === '' ? null : formData.cuota_admin,
				market_commission: formData.market_commission === '' ? null : formData.market_commission,
				insures_lease: formData.insures_lease === '' ? null : formData.insures_lease,
				ensures_administration: formData.ensures_administration === '' ? null : formData.ensures_administration,
				sale_value: formData.sale_value === '' ? null : formData.sale_value,
				sale_commission: formData.sale_commission === '' ? null : formData.sale_commission,
				reserve_id: formData.reserve_id === '' ? null : formData.reserve_id,
				accept_swap: formData.accept_swap === '' ? null : formData.accept_swap,
				is_leased: formData.is_leased === '' ? null : formData.is_leased,
				canon_now: formData.canon_now === '' ? null : formData.canon_now,
				cuota_admin_now: formData.cuota_admin_now === '' ? null : formData.cuota_admin_now,
				contract_expiration_date: formData.contract_expiration_date === '' ? null : formData.contract_expiration_date,
			}

			const data = await axios.post(`dashboard/properties/actions`, param)

			if (data.status === 200) {
				swalSuccess(data.data.data)
				await history.push(`/dashboard/propiedades/condiciones/${uID}`)
			}
		} catch (error) {
			await errorServices(error)
		}

		setLoadingForm(false)
	}
	
	const handleOnChange = ({ target }) => {
		setFormData({...formData, [target.name]: target.value})
		
		if (target.name === 'action_type1') {
			setShowToLeaseAction(target.value === 'Si' ? true : false)
		} else if (target.name === 'action_type2') {
			setShowSellAction(target.value === 'Si' ? true : false)
		}
		
		if (target.name === 'is_leased') {
			setShowActual(target.value === 'Si' ? true : false)
		}
	}
	
	return (
		<Layout.Page title="Propiedades - Acciones">
			<SearchComponent
				module={module}
				back={`/dashboard/propiedades/areas/${uID}`}
				next={`/dashboard/propiedades/condiciones/${uID}`}
			/>
			
			<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
				<Layout.CardContent className="m-0 p-4">
					<Formik
						initialValues={formData}
						validationSchema={validationSchema}
						onSubmit={() => {}}
					>
						{({
						  errors,
						  touched,
						  handleChange,
						  handleBlur,
						  isValid
					  }) => (
							<Form noValidate>
								{/* <pre>{JSON.stringify(errors,null,2)}</pre> */}
								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
										<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
											Arrendar
										</div>
										<Inputs.ButtonGroup
											variant="outlined"
											size="small"
											name="action_type1"
											value={formData.action_type1 === 'Si' ? 'No' : 'Si'}
											itemsArray={actionsSwitch}
											className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
											condition={formData.action_type1}
											onClick={handleOnChange}
										/>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
										<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
											Vender
										</div>
										<Inputs.ButtonGroup
											variant="outlined"
											size="small"
											name="action_type2"
											value={formData.action_type2 === 'Si' ? 'No' : 'Si'}
											itemsArray={actionsSwitch}
											className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
											condition={formData.action_type2}
											onClick={handleOnChange}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 col-md-6 mb-3">
										<Inputs.TextBox
											type="text"
											variant="outlined"
											size="small"
											name="value_admin"
											label="Valor de Administración"
											placeholder="Ingrese valor de administración..."
											hasLabelFixed={true}
											value={formData.value_admin}
											onChange={(e) => {
												handleOnChange(e)
												handleChange(e)
											}}
											onBlur={handleBlur}
											hasError={errors.value_admin && touched.value_admin ? true : false}
											errorMessage={errors.value_admin && touched.value_admin ? errors.value_admin : ''}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
								</div>
								{showToLeaseAction && (
									<div className="row animate__animated animate__fadeIn">
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<Inputs.TextBox
												type="text"
												variant="outlined"
												size="small"
												name="canon"
												label="Canon esperado"
												placeholder="Ingrese canon esperado..."
												hasLabelFixed={true}
												value={formData.canon}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.canon && touched.canon ? true : false}
												errorMessage={errors.canon && touched.canon ? errors.canon : ''}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<Inputs.TextBox
												type="number"
												variant="outlined"
												size="small"
												name="cuota_admin"
												label="Comisión de administración (Valor %)"
												placeholder="Ingrese comisión de administración..."
												hasLabelFixed={true}
												value={formData.cuota_admin}
												onChange={handleOnChange}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<Inputs.TextBox
												type="number"
												variant="outlined"
												size="small"
												name="market_commission"
												label="Comisión de mercado (Valor %)"
												placeholder="Ingrese comisión de mercado..."
												hasLabelFixed={true}
												value={formData.market_commission}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.market_commission && touched.market_commission ? true : false}
												errorMessage={errors.market_commission && touched.market_commission ? errors.market_commission : ''}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
												¿Asegura Arriendo?
											</div>
											<Inputs.ButtonGroup
												variant="outlined"
												size="small"
												name="insures_lease"
												value={formData.insures_lease === 'Si' ? 'No' : 'Si'}
												itemsArray={actionsSwitch}
												className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
												condition={formData.insures_lease}
												onClick={handleOnChange}
											/>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
												¿Asegura Administración?
											</div>
											<Inputs.ButtonGroup
												variant="outlined"
												size="small"
												name="ensures_administration"
												value={formData.ensures_administration === 'Si' ? 'No' : 'Si'}
												itemsArray={actionsSwitch}
												className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
												condition={formData.ensures_administration}
												onClick={handleOnChange}
											/>
										</div>
									</div>
								)}
								{showSellAction && (
									<div className="row animate__animated animate__fadeIn">
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<Inputs.TextBox
												type="text"
												variant="outlined"
												size="small"
												name="sale_value"
												label="Valor de la venta"
												placeholder="Ingrese valor de la venta..."
												hasLabelFixed={true}
												value={formData.sale_value}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.sale_value && touched.sale_value ? true : false}
												errorMessage={errors.sale_value && touched.sale_value ? errors.sale_value : ''}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
										<Permission permission="propertyReserves.dropdown">
											<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
												<Inputs.Select
													size="small"
													label="Reserva de la Propiedad"
													name="reserve_id"
													value={formData.reserve_id}
													itemsArray={arrayPropertyReserve}
													onChange={handleOnChange}
													className="font-family-roboto-regular fs-6 text-dark w-100"
												/>
											</div>
										</Permission>
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<Inputs.TextBox
												type="number"
												variant="outlined"
												size="small"
												name="sale_commission"
												label="Comisión de Venta (Valor %)"
												placeholder="Ingrese comisión de venta..."
												hasLabelFixed={true}
												value={formData.sale_commission}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.sale_commission && touched.sale_commission ? true : false}
												errorMessage={errors.sale_commission && touched.sale_commission ? errors.sale_commission : ''}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
												¿Acepta Permuta?
											</div>
											<Inputs.ButtonGroup
												variant="outlined"
												size="small"
												name="accept_swap"
												value={formData.accept_swap === 'Si' ? 'No' : 'Si'}
												itemsArray={actionsSwitch}
												className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
												condition={formData.accept_swap}
												onClick={handleOnChange}
											/>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
												¿Está Arrendado?
											</div>
											<Inputs.ButtonGroup
												variant="outlined"
												size="small"
												name="is_leased"
												value={formData.is_leased === 'Si' ? 'No' : 'Si'}
												itemsArray={actionsSwitch}
												className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
												condition={formData.is_leased}
												onClick={handleOnChange}
											/>
										</div>
									</div>
								)}
								{showActual && (
									<div className="row animate__animated animate__fadeIn">
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<Inputs.TextBox
												type="number"
												variant="outlined"
												size="small"
												name="canon_now"
												label="Canon actual"
												placeholder="Ingrese canon actual..."
												hasLabelFixed={true}
												value={formData.canon_now}
												onChange={handleOnChange}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<Inputs.TextBox
												type="number"
												variant="outlined"
												size="small"
												name="cuota_admin_now"
												label="Cuota de administración actual"
												placeholder="Ingrese cuota de administración actual..."
												hasLabelFixed={true}
												value={formData.cuota_admin_now}
												onChange={handleOnChange}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
											<Inputs.TextBox
												type="date"
												variant="outlined"
												size="small"
												name="contract_expiration_date"
												label="Fecha de vencimiento del contrato"
												placeholder="Ingrese fecha de vencimiento del contrato..."
												hasLabelFixed={true}
												value={formData.contract_expiration_date}
												onChange={handleOnChange}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
									</div>
								)}
								<div className="row">
									<div className="col-sm-12">
										<Inputs.LoadingButton
											variant="outlined"
											isLoading={isLoading}
											isLoadingPosition="start"
											startIcon={<Layout.Icons.SaveIcon height={18} width={18}/>}
											label="Guardar"
											type="button"
											onClick={hanbleSubmit}
											// isDisabled={!isValid}
											className={`${isValid && 'bg-primary'} font-family-roboto-medium fs-6 text-inherit`}
										/>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</Layout.CardContent>
			</Layout.Card>
		</Layout.Page>
	)
}

export default PropertyActionOldPage
