import React, { useRef, useState, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import { ApiKeyManager } from '@esri/arcgis-rest-request'
import { geocode } from "@esri/arcgis-rest-geocoding"
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken = `${process.env.REACT_APP_MAP_BOX_TOKEN}`

export default function Map(props) {
    const { longitude, latitude, setHandleAddress, setLongitude, setLatitude } = props
    const [getZoom, setZoom] = useState(9)
    const map = useRef(null)
    const mapContainer = useRef(null)
    const authentication = ApiKeyManager.fromKey(`${process.env.REACT_APP_REACT_MAP_BOX_KEY_ARCGIS}`);
    //get current map marker
	const [marker , setMarker ] = useState(null);

    useEffect(() => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [longitude, latitude],
            zoom: getZoom
        })

        //save current marker
        setMarker(new mapboxgl.Marker().setLngLat([longitude, latitude]).addTo(map.current))

        map.current.addControl(new mapboxgl.NavigationControl(), 'top-right')

        map.current.on('move', (e) => {
            setLongitude(map.current.getCenter().lng.toFixed(4))
            setLatitude(map.current.getCenter().lat.toFixed(4))
            setZoom(map.current.getZoom().toFixed(2))
        })

        return () => map.current.remove()
    }, [])

    const setHandleMap = () =>{
        if (setHandleAddress !== ""){
            geocode({
                address: setHandleAddress,
                authentication
            })
            .then((res) => {
                let lng = res.candidates[0]?.location.x ?? 0
                let lat = res.candidates[0]?.location.y ?? 0

                setLongitude(lng.toFixed(4))
                setLatitude(lat.toFixed(4))

                if (map.current){
                    map.current.flyTo({
                        center: [lng.toFixed(4), lat.toFixed(4)],
                        zoom: 12,
                        speed: 2,
                        curve: 1,
                        easing(t) {
                            return t;
                        }
                    })
                    //remove old marker
					if(marker) marker.remove()
					//add new marker
                    setMarker(new mapboxgl.Marker().setLngLat([lng.toFixed(4), lat.toFixed(4)]).addTo(map.current))
                }
            })
        }
    }

    useEffect(() => {
        setHandleMap()
    }, [setHandleAddress])

    return (
        <div className="map-container" ref={mapContainer} />
    )
}
