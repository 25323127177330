import React from 'react'
// TODO change tag by origin
/**
 * @type {{
 *    files:{
 *      name:string,
 *      sizeFormated:string,
 *      previewBlob:string,
 *      file:File,
 *      s3Status: 'loading' | 'uploaded' | 'error' | null,
 *      mobiliStatus: 'loading' | 'uploaded' | 'error' | null,
 *      message:string,
 *      uuid:string,
 *      s3:{
 *        key:string,
 *        location:string
*       } | null
 *    }[],
 *    origin: string | null,
 *    orderList: {
 *      order:number,
 *      asset_id:number
*     }[]
 *  }
 * } initialState
 */
const initialState = {
  files: [],
  origin: 'Captacion',
  orderList:[]
}

const mapEventToState = {
  UPDATE_FILE_LIST: (state, action) => {
    const { files } = action.payload

    /**
     *  @type {string[]} names
     */
    const names = (state.files ?? []).map((asset) => asset.name)

    const newFiles = []
    for (const file of files) {
      if (names.includes(file.name)) {
        continue
      }
      newFiles.push(file)
    }

    return {
      ...state,
      files: [state.files, newFiles].flat(),
    }
  },
  REMOVE_ASSET_FROM_LIST: (state, action) => {
    const { files: FILES } = state
    const { name } = action.payload

    return {
      ...state,
      files: FILES.filter((asset) => asset.name != name),
    }
  },
  SELECT_ORIGIN: (state, action) => {
    const { origin } = action.payload;
   
    return {
      ...state,
      origin,
    }
  },
  UPDATE_FILE: (state, action)=> {
    const { filename, ...more } = action.payload;
    // console.log({more})
    const { files } = state;

    const newFiles = files.map(
      (asset)=>{
        if(asset.name === filename){
          return {
            ...asset,
            filename,
            ...more
          }
        }
        return asset;
      }
    );

    return {
      ...state,
      files: newFiles
    }
  },
   CHANGE_ORDER_LIST: (state, action)=> {
    const { orderList } = action.payload
    return {
      ...state,
      orderList,
    }
   }
}

const reducer = (state, action) =>
  mapEventToState[action.type]
    ? mapEventToState[action.type](state, action)
    : state

const AssetsContextValue = React.createContext({
  ...initialState,
  /** @type { ( Files[] )=>void} */
  updateFileList: function () {},
  removeAssetFromList: function () {},
  selectOrigin:function (){},
  updateFile:function(){},
  changeOrderList: function(){}
})

AssetsContextValue.displayName = 'AssetsContext'

export const useAssets = () => {
  const ctx = React.useContext(AssetsContextValue)
  if (!ctx) {
    throw Error('AssetsContextValue -> No provider found')
  }
  return ctx
}

export function AssetsProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  /** @param { Files[] } files */
  const updateFileList = (files) => {
    dispatch({
      type: 'UPDATE_FILE_LIST',
      payload: {
        files,
      },
    })
  }

  const removeAssetFromList = (name) => {
    dispatch({
      type: 'REMOVE_ASSET_FROM_LIST',
      payload: {
        name,
      },
    })
  }

  const selectOrigin = (origin)=>{
    dispatch({
      type:'SELECT_ORIGIN',
      payload:{
        origin,
      }
    })
  }

  const updateFile = (payload)=>{
    dispatch({
      type:'UPDATE_FILE',
      payload,
    })
  }

  const changeOrderList = (payload)=>{
    dispatch({
      type:'CHANGE_ORDER_LIST',
      payload,
    })
  }

  return (
    <AssetsContextValue.Provider
      value={{
        ...state,
        updateFile,
        selectOrigin,
        updateFileList,
        changeOrderList,
        removeAssetFromList,
      }}
    >
      {children}
    </AssetsContextValue.Provider>
  )
}
