import React, { useEffect }  from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/mui/Layout'
import TableActivityLogFilters from './TableActivityLogFilters'
import TableActivityLogRow from './TableActivityLogRow'
import { getArray, setPage, setPerPage } from "../../redux/actions/dashboard/activities/activityLogAction";
import { activityLogsColumns as columns, perPages } from '../../helpers/variablesColumns'

const TableActivityLog = ({ page, pages, logName, subjectId }) => {
	const dispatch = useDispatch()
	const isActiveHistorical = useSelector(state => state.filtered.isActiveHistorical)
	const isLoading = useSelector(state => state.activityLog.isLoading)
	const perPage = useSelector(state => state.activityLog.perPage)
	const startDate = useSelector(state => state.activityLog.startDate)
	const finishDate = useSelector(state => state.activityLog.finishDate)
	const activityName = useSelector(state => state.activityLog.activityName)
	const arrayList = useSelector(state => state.activityLog.arrayList)
	
	const handleArrayList = async () => {
		const param = {
			perPage: perPage,
			page: page,
			start: startDate === '' ? null : startDate,
			finish: finishDate === '' ? null : finishDate,
			activity: activityName === '' ? null : activityName,
			logName: logName,
			subjectId: subjectId
		}
		
		await dispatch( getArray(param) )
	}
	
	useEffect(() => {
		if (subjectId !== '' && logName !== '') {
			handleArrayList()
		}
	}, [
		perPage,
		page,
		startDate,
		finishDate,
		activityName,
		logName,
		subjectId
	])
	
	const handleChangePerPage = ({ target }) => dispatch( setPerPage(target.value) )
	
	const handleChangePage = (event, value) => dispatch( setPage(value) )
	
	return (
		<div className={`row ${isActiveHistorical && 'mt-4'}`}>
			<TableActivityLogFilters logName={logName} />
			<div className="col-sm-12">
				<Layout.TablePagination
					arrayList={arrayList.length}
					perPage={perPage}
					perPages={perPages}
					page={page}
					pages={pages}
					handleChangePerPage={handleChangePerPage}
					handleChangePage={handleChangePage}
				/>
				<Layout.Table className="table table-borderless table-striped table-hover align-middle m-0 w-100">
					<Layout.TableHead
						columns={columns}
						className="table-secondary"
						fontClassName="font-family-roboto-bold fs-7"
					/>
					<tbody>
						{isLoading && (
							<Layout.TableRowLoading
								columns={columns}
							/>
						)}
						{!isLoading && (
							<>
								{arrayList.length === 0 && (
									<Layout.TableRowNoFound
										colSpan={columns.length}
									/>
								)}
								{arrayList.length > 0 && (
									arrayList.map(({ created_date, created_at, log_name, description, activity, properties, user_name }, number) => (
										<TableActivityLogRow
											key={number}
											createdDate={created_date}
											createdAt={created_at}
											logName={log_name}
											description={description}
											activity={activity}
											properties={properties}
											userName={user_name}
										/>
									))
								)}
							</>
						)}
					</tbody>
				</Layout.Table>
				<Layout.TablePagination
					arrayList={arrayList.length}
					perPage={perPage}
					perPages={perPages}
					page={page}
					pages={pages}
					handleChangePerPage={handleChangePerPage}
					handleChangePage={handleChangePage}
				/>
			</div>
		</div>
	)
}

TableActivityLog.propTypes = {
	page: PropTypes.number.isRequired,
	pages: PropTypes.number.isRequired,
	logName: PropTypes.string.isRequired,
	subjectId: PropTypes.any.isRequired
}

export default TableActivityLog
