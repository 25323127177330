import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import DateIcon from '@mui/icons-material/Today'
import FlagIcon from '@mui/icons-material/Flag'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import Layout from '../../../../../components/mui/Layout'
import { history } from '../../../../../redux/store'

const KanbanRowComponent = ({ filtered, status }) => {
	const {
		id,
		service,
		created_ago,
		month,
		amount,
		customer
	} = filtered
	
	useEffect(() => {
	}, [filtered])
	
	/**
	 *
	 * @description Execute redirect filtered detail (by status registrado), or redirect filtered preview (by status <> registrado)
	 * @return dispatch
	 *
	 */
	const handleShow = async () => {
		if (status === 'Registrado') {
			await history.push(`/dashboard/filtrado/registro-de-casos/detalle/${id}`)
		} else {
			await history.push(`/dashboard/filtrado/registro-de-casos/vista-previa/${id}`)
		}
	}
	
	return (
		<Layout.Paper
			className='mb-2 cursor-pointer'
			elevation={0}
			sx={Styles}
			onClick={handleShow}
		>
			{/* START HEADER */}
			<Layout.Box sx={HeaderSectionStyles} gap={1.25}>
				<Grid container alignItems='center' gap={0.625}>
					<DateIcon
						fontSize='0.875rem'
						sx={{ color: 'darkGrey.default' }}
					/>
					<Layout.Typography sx={TextTimeAgoStyle}>
						{created_ago}
					</Layout.Typography>
				</Grid>
				<Layout.Box
					sx={{
						...CommomStatusStyle,
						bgcolor: determinateStatusColorForContract(service)
					}}>
					<Layout.Typography
						textAlign='center'
						fontSize='0.8rem'
						fontWeight='700'>
						{service === 'not_assigned' ? 'No Asignado' : service}
					</Layout.Typography>
				</Layout.Box>
				<Grid container alignItems='center' gap={0.625}>
					<Layout.Typography sx={TextTimeAgoStyle}>
						{month.substr(0, 3)}
					</Layout.Typography>
					<FlagIcon
						fontSize='0.875rem'
						sx={{ color: 'darkGrey.default' }}
					/>
				</Grid>
			</Layout.Box>
			{/* END HEADER */}
			<Grid container flexDirection='column'>
				<Layout.Typography
					variant='h4'
					textOverflow='ellipsis'
					overflow='hidden'
					whiteSpace='nowrap'
					// fontWeight="800"
					sx={{
						lineHeight: ({ typography }) =>
							typography.h4.fontSize.replace('rem', '')
					}}>
					{customer}
				</Layout.Typography>

				<Grid
					container
					justifyContent='space-between'
					alignItems='center'>
					<Layout.Typography
						color='darkGrey.default'
						fontSize='0.75rem'
						fontWeight='600'>
						{amount}
					</Layout.Typography>
					<Layout.IconButton color='inherit' size='small' sx={{}}>
						<NotificationsNoneIcon fontSize='small' />
					</Layout.IconButton>
				</Grid>
			</Grid>
		</Layout.Paper>
	);
};

/**
 * @param {ContractStatus} service
 * @return {()=>string} mui theme color
 */
export const determinateStatusColorForContract = (service) => {
	switch (service) {
		case 'not_assigned':
			return 'mediumGrey.default';
		case 'Compra':
			return 'green.default';
		case 'Compra / Venta':
			return 'green.default';
		case 'Compra/Venta':
			return 'green.default';
		case 'Captación':
			return 'orange.default';
		case 'Arriendo':
			return 'skyBlue.default';
		case 'Proyecto':
			return 'purple.default';
		default:
			return 'mediumGrey.default';
	}
};

/**
 * @type {SxProps<Theme>}
 */
const Styles = {
	pt: ({ spacing }) => spacing(1.25),
	px: ({ spacing }) => spacing(1.25), // 10px
	pb: ({ spacing }) => spacing(1.875), // 15px,

	display: 'flex',
	// minHeight: "103px", // TODO remove after complete code
	// maxHeight: "100%", //figma design, could be greater,
	flexDirection: 'column',
	gap: ({ spacing }) => spacing(1.25), // 10px
	//TODO Hover pseudo
	'&:hover': {
		// boxShadow: ({ shadows }) => shadows[10],
		boxShadow: `0px 0px 1px rgba(15, 23, 42, 0.03),
       0px 4px 6px -1px rgba(15, 23, 42, 0.05),
        0px 0px 5px 5px rgba(15, 23, 42, 0.03)`
	},
	cursor: 'pointer'
};

/**
 * @type {SxProps<Theme>}
 */
const HeaderSectionStyles = {
	// height: ({ spacing }) => spacing(2.25), 18px
	display: "grid",
	gridTemplateColumns: "max-content 1fr max-content",
}
/**
 * @type {React.CSSProperties}
 */
const TextTimeAgoStyle = {
	fontWeight: 700,
	fontSize: "0.8rem",
	color: "darkGrey.default",
}

/**
 * @type {React.CSSProperties}
 */
const CommomStatusStyle = {
	width: "100%",
	height: "100%",
	py: "2px",
	backgroundColor: "inherit",
	borderRadius: ({ spacing }) => spacing(2.5), // 20px
}

KanbanRowComponent.propTypes = {
	filtered: PropTypes.object.isRequired,
	status: PropTypes.string.isRequired
}

export default KanbanRowComponent
