import React from 'react';
import Layout from '../../../../components/mui/Layout';
import SearchComponent from './components/SearchComponent';
import TableComponent from './components/TableComponent';
import ModalComponent from './components/ModalComponent';

const RealStatePage = () => {
	
	return (
		<Layout.Page title="Inmobiliarias">
			<SearchComponent />
			
			<TableComponent />
			
			<ModalComponent />
		</Layout.Page>
	);
};

export default RealStatePage;
