/*eslint no-unused-vars: "off"*/
import { SxProps, Theme } from "@mui/material";
/**
 * @type {SxProps<Theme>}
 */
export const PaperPropsStyles = {
  m: 1,
  // minWidth: "500px",
  width: "100%",
  borderRadius: "2px",
};

/**
 * @type {SxProps<Theme>}
 */
export const DialogContentStyle = {
  p: (theme) => theme.spacing(0.5, 2),
  display: "flex",
  flexDirection: "column",
  mt: 2,
};
