import React, { useEffect, useState } from 'react'
import Layout from '../../../components/mui/Layout'
import { Typography, Chip, styled, Box, Tab } from '@mui/material'
import ThemeProviderComponent from '../../../theme-v2/themeProvider'
import { FormFicha } from './components/formFicha'
import { Gallery } from './components/gallery'
import { Footer } from './components/footer'
import styles from './fichaView.module.css'
import {
  postListFilesByAlbumsTypes,
  postPropertiesShow,
} from '../../../api/properties'
import Loader from './components/loader/loader'

const ChipCustom = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'myPropBackground',
})(({ theme, myPropBackground }) => ({
  backgroundColor: myPropBackground
    ? myPropBackground
    : theme.palette.orange.default,
  color: theme.palette.white.main,
  fontWeight: 700,
  fontSize: '12px',
  height: '20px',
  padding: '0 12px',
  marginBottom: '5px',
}))

const Subtitle = styled('span')(({ theme }) => ({
  color: theme.palette.dark.default,
  fontWeight: 400,
  fontStyle: 'normal',
  fontSize: '1rem',
  lineHeight: '150%',
  display: 'block',
  margin: '5px 0',
}))

const firstPage = 0

const TestPage = () => {
  const [information, setInformation] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(firstPage)
  const [hasMore, setHasMore] = useState(true)
  const [images, setImages] = useState([])

  const getData = async () => {
    page === firstPage && setIsLoading(true)
    // album_id: Jesus
    // 'eyJpdiI6IlcyVXVZMEtYUkRQcDh2NGN4MExyQWc9PSIsInZhbHVlIjoiTVFOOE9xS1lLNklLNkprb2JjTnkzUT09IiwibWFjIjoiYWI5YTRiNjk0MTVhNGI5YjY1NGJkOTViMmEzOTAxMDE3YjNkMzliZDJjNTlkMGIyMzYwMDJjZmQ1NzlkNzY5NyIsInRhZyI6IiJ9'

    const { data, status } = await postPropertiesShow(
      'eyJpdiI6ImVUMkJ6Q2diMldkUHozRC9ieHk5c1E9PSIsInZhbHVlIjoiMyt3UFk3WjNyRVVvTEM2K1JyMDh2dz09IiwibWFjIjoiMzJmMmRhOTA3ZTRhODRkNzgzOTAzODU2NDczZDRhZjBiZjcxZTYwYTBjODY0N2ZmN2JiYjExOTY1N2ZjYzdiYyIsInRhZyI6IiJ9',
    )

    if (status !== 200) {
      console.log('salto error', status, data)
      alert('Ocurrio un error', status, data)
      return
    }

    let records
    if (data?.data?.albums_types[0]?.name === 'fotos') {
      records = await postListFilesByAlbumsTypes(
        data?.data?.albums_types[0]?.id,
        page,
      )
    }

    setInformation({
      ...information,
      ...data?.data,
      images: records?.data?.data,
    })
    setImages([...images, ...records?.data?.data?.records])
    setHasMore(() => records?.data?.data?.pages > page + 1)
    setIsLoading(false)
  }

  useEffect(() => {
    getData()
  }, [page])

  if (isLoading) {
    return <Loader />
  }

  return (
    <ThemeProviderComponent>
      <Layout.Page title="Test">
        <main className={styles.gridContainer}>
          <section className={styles.left}>
            <div className={styles.cardCustom}>
              <ChipCustom
                label={information?.status}
                myPropBackground={information?.color_status}
              />
              <Typography color="dark.default" variant="h3">
                {information?.address}
              </Typography>
              <Subtitle>{information?.reference}</Subtitle>
              <div className={styles.dFlex}>
                <div className={styles.dFlex_left}>
                  <span> Ciudad: </span>
                  <span>{information?.city}</span>
                </div>
                <div className={styles.dFlex_right}>
                  <span>Barrio: </span>
                  <span>{information?.neighborhood}</span>
                </div>
              </div>
            </div>
            <FormFicha information={information} />
          </section>
          <section className={styles.footerMain}>
            <Footer />
          </section>
          <section className={styles.right}>
            <Gallery
              images={images}
              isLoading={isLoading}
              setPage={setPage}
              hasMore={hasMore}
            />
          </section>
        </main>
      </Layout.Page>
    </ThemeProviderComponent>
  )
}

export default TestPage
