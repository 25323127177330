import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../../../components/mui/Layout'
import Inputs from '../../../../../components/mui/Inputs'
import { history } from '../../../../../redux/store'
import DropdownSelect from '../../../settings/RealStatePage/components/DropdownSelect';
import { setRealState, setSearch, setRefreshTable, setStatusCommercial } from '../../../../../redux/actions/dashboard/properties/property/PropertyAction'
import { Permission } from '../../../../../services/permissions/Permission'
import axios from "../../../../../services/axios";
import errorServices from "../../../../../services/errors";

const SearchComponent = () => {
	const dispatch = useDispatch()
	const { permissions } = useSelector( state  => state.auth.userData)
	const tokenType = useSelector(state => state.auth.tokenType)
	const accessToken = useSelector(state => state.auth.accessToken)
	const refreshTable = useSelector( state => state.property.refreshTable)
	const search = useSelector( state => state.property.search)
	const realState = useSelector( state => state.property.realState)
	const statusCommercial = useSelector( state => state.property.statusCommercial)
	const [arrayDropdownStatusCommercial, setDropdownStatusCommercial] = useState([])
	
	const handleDropdownStatusCommercial = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get('dashboard/settings/properties/status-commercial')
			
			if (data.status === 200) {
				setDropdownStatusCommercial(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
		if (permissions.find((item) => item === 'propertyStatusCommercial.dropdown')) {
			handleDropdownStatusCommercial()
		}
	}, [permissions])
	
	const handleChange = ({ target }) => dispatch( setSearch(target.value) )
	
	const handleChangeRealState = ({ target }) => dispatch( setRealState(target.value) )
	const handleChangeStatusComercial = ({ target }) => dispatch( setStatusCommercial(target.value) )
	
	const handleKeyPress = ({ charCode }) => charCode === 13 && dispatch( setRefreshTable(!refreshTable) )
	
	const handleClear = () => {
		dispatch( setSearch('') )
		dispatch( setRealState('') )
		dispatch( setStatusCommercial('') )
		dispatch( setRefreshTable(!refreshTable) )
	}
	
	const handleNew = async () => await history.push(`/dashboard/propiedades/edificacion`)
	
	return (
		<Layout.Card className="border-radius-0 m-0 p-0">
			<Layout.CardContent className="m-0 p-3">
				<div className="row mt-2 mb-0">
					<div className="col-sm-12">
						<Permission permission="properties.store">
							<Inputs.Button
								label="Nuevo"
								startIcon={<Layout.Icons.AddCircleIcon width={20} height={20} />}
								className="font-family-roboto-regular fs-6 bg-primary text-capitalize mb-2 min-w-100"
								onClick={handleNew}
							/>
						</Permission>
						<DropdownSelect
							name="realState"
							value={realState}
							handleChange={handleChangeRealState}
							className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-300px"
						/>
						<Permission permission="propertyStatusCommercial.dropdown">
							<Inputs.Select
								label="Estatus comercial"
								name="statusCommercial"
								value={statusCommercial}
								itemsArray={arrayDropdownStatusCommercial}
								onChange={handleChangeStatusComercial}
								className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-300px"
							/>
						</Permission>
						<Permission permission="properties.list">
							<Inputs.TextBox
								name="search"
								label="Buscar"
								placeholder="Ingrese la búsqueda..."
								value={search}
								onChange={handleChange}
								onKeyPress={handleKeyPress}
								className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-300px"
							/>
						</Permission>
						<Layout.Tooltip
							title="Limpiar búsqueda"
							placement="top"
						>
							<Layout.IconButton
								onClick={handleClear}
								edge="start"
								className="bg-secondary font-roboto-semibold d-inline float-end mt-1 ml-2 mb-2"
							>
								<Layout.Icons.CloseIcon
									width={18}
									height={18}
									className="font-roboto-semibold text-white"
								/>
							</Layout.IconButton>
						</Layout.Tooltip>
					</div>
				</div>
			</Layout.CardContent>
		</Layout.Card>
	)
}

export default SearchComponent
