import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { history } from '../../../../redux/store'
import Layout from '../../../../components/mui/Layout'
import Inputs from '../../../../components/mui/Inputs'
import SearchComponent from '../components/SearchComponent'
import { setIsLoading } from '../../../../redux/actions/loadingAction'
import { actionsSwitch } from '../../../../helpers/variablesColumns'
import errorServices from '../../../../services/errors'
import { swalSuccess } from '../../../../services/sweetalert'
import axios from '../../../../services/axios'

const PropertyConditionOldPage = () => {
	const params = useParams()
	const uID = params.uID
	const dispatch = useDispatch()
	const tokenType = useSelector(state => state.auth.tokenType)
	const accessToken = useSelector(state => state.auth.accessToken)
	const [isLoading, setLoadingForm] = useState(false)
	const [showExclusiveMandate, setShowExclusiveMandate] = useState(false)
	const [showPosterInstalled, setShowPosterInstalled] = useState(false)
	const [formData, setFormData] = useState({
		exclusive_mandate: "No",
		exclusive_mandate_date: '',
		poster_installed: "No",
		poster_installed_date: ''
	})
	const [module, setModule] = useState({
		step: 7,
		title: 'Condiciones'
	})
	
	const handleEditProperty = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/${uID}`)
			
			if (data.status === 200) {
				if (data.data.data.has_building === 'Si') {
					setModule({...module, step: 6 })
				}
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
		handleEditProperty()
	}, [])
	
	const handleEditPropertyCondition = async () => {
		dispatch( setIsLoading(true) )
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/conditions/${uID}`)
			
			if (data.status === 200) {
				const response = data.data.data
				setFormData({
					exclusive_mandate: response.exclusive_mandate === 'Si' ? 'Si' : 'No',
					exclusive_mandate_date: response.exclusive_mandate === 'Si' ? (response.exclusive_mandate_date === null ? '' : response.exclusive_mandate_date) : '',
					poster_installed: response.poster_installed === 'Si' ? 'Si' : 'No',
					poster_installed_date: response.poster_installed === 'Si' ? (response.poster_installed_date === null ? '' : response.poster_installed_date) : '',
				})
				setShowExclusiveMandate(response.exclusive_mandate === 'Si' ? true : false)
				setShowPosterInstalled(response.poster_installed === 'Si' ? true : false)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		dispatch( setIsLoading(false) )
	}
	
	useEffect(() => {
		handleEditPropertyCondition()
	}, [])
	
	const hanbleSubmit = async () => {
		setLoadingForm(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				id: uID,
				exclusive_mandate: formData.exclusive_mandate === 'Si' ? 'Si' : 'No',
				exclusive_mandate_date: formData.exclusive_mandate === 'Si' ? (formData.exclusive_mandate_date === '' ? null : formData.exclusive_mandate_date) : null,
				poster_installed: formData.poster_installed === 'Si' ? 'Si' : 'No',
				poster_installed_date: formData.poster_installed === 'Si' ? (formData.poster_installed_date === '' ? null : formData.poster_installed_date) : null,
			}
			
			const data = await axios.post(`dashboard/properties/conditions`, param)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				await history.push(`/dashboard/propiedades/servicios/${uID}`)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoadingForm(false)
	}
	
	const handleOnChange = ({ target }) => {
		setFormData({...formData, [target.name]: target.value})
		
		if (target.name === 'exclusive_mandate') {
			setShowExclusiveMandate(target.value === 'Si' ? true : false)
		} else if (target.name === 'poster_installed') {
			setShowPosterInstalled(target.value === 'Si' ? true : false)
		}
	}
	
	return (
		<Layout.Page title="Propiedades - Condiciones">
			<SearchComponent
				module={module}
				back={`/dashboard/propiedades/acciones/${uID}`}
				next={`/dashboard/propiedades/servicios/${uID}`}
			/>
			
			<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
				<Layout.CardContent className="m-0 p-4">
					<div className="row">
						<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
							<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
								¿Mandato exclusivo?
							</div>
							<Inputs.ButtonGroup
								variant="outlined"
								size="small"
								name="exclusive_mandate"
								value={formData.exclusive_mandate === 'Si' ? 'No' : 'Si'}
								itemsArray={actionsSwitch}
								className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
								condition={formData.exclusive_mandate}
								onClick={handleOnChange}
							/>
						</div>
						{showExclusiveMandate && (
							<div className="col-sm-12 col-md-6 col-lg-4 mb-3 animate__animated animate__fadeIn">
								<Inputs.TextBox
									type="date"
									variant="outlined"
									size="small"
									name="exclusive_mandate_date"
									label="Fecha de expiración"
									placeholder="Ingrese fecha de expiración..."
									hasLabelFixed={true}
									value={formData.exclusive_mandate_date}
									onChange={handleOnChange}
									className="font-family-roboto-medium text-dark fs-6 w-100 mt-4"
								/>
							</div>
						)}
					</div>
					<div className="row">
						<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
							<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
								¿Cartel instalado?
							</div>
							<Inputs.ButtonGroup
								variant="outlined"
								size="small"
								name="poster_installed"
								value={formData.poster_installed === 'Si' ? 'No' : 'Si'}
								itemsArray={actionsSwitch}
								className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
								condition={formData.poster_installed}
								onClick={handleOnChange}
							/>
						</div>
						{showPosterInstalled && (
							<div className="col-sm-12 col-md-6 col-lg-4 mb-3 animate__animated animate__fadeIn">
								<Inputs.TextBox
									type="date"
									variant="outlined"
									size="small"
									name="poster_installed_date"
									label="Fecha de instalación del Cartel"
									placeholder="Ingrese fecha de instalación del cartel..."
									hasLabelFixed={true}
									value={formData.poster_installed_date}
									onChange={handleOnChange}
									className="font-family-roboto-medium text-dark fs-6 w-100 mt-4"
								/>
							</div>
						)}
					</div>
					<div className="row">
						<div className="col-sm-12">
							<Inputs.LoadingButton
								variant="outlined"
								isLoading={isLoading}
								isLoadingPosition="start"
								startIcon={<Layout.Icons.SaveIcon height={18} width={18}/>}
								label="Guardar"
								type="button"
								onClick={hanbleSubmit}
								className="bg-primary font-family-roboto-medium fs-6 text-inherit"
							/>
						</div>
					</div>
				</Layout.CardContent>
			</Layout.Card>
		</Layout.Page>
	)
}

export default PropertyConditionOldPage
