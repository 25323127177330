import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useSlider from '../hooks/useSlider'
import Skeleton from '../skeleton'
import images from '../img'
import { Slider } from '../slider/Slider'
import styles from './galleryPictures.module.css'
import { useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoaderGallery from '../../../../pages/dashboard/TestPage/components/loader/LoaderGallery'

export function GalleryPictures({
  dataArrayFotos,
  isLoading,
  setPage,
  hasMore,
  byProps,
  height,
  openSliderFuncProps,
  //closeModalFuncProps,
  setDataGalleriesSliderFuncProps,
  galleryColumns,
}) {
  const {
    isOpenSlider,
    closeSliderFunc,
    openSliderFunc,
    setDataGalleriesSliderFunc,
    dataGalleries,
    currentIndex,
    goToNext,
    goToPrevious,
  } = useSlider()

  const boxGalleryRef = useRef()
  const [btnGoUpActive, setBtnGoUpActive] = useState(false)

  const scrollUp = () => {
    const scrollContent = boxGalleryRef.current
    const scrolled = scrollContent.scrollTop

    if (scrolled > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setBtnGoUpActive(false)
      scrollContent.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const scrollContent = boxGalleryRef.current
    scrollContent.addEventListener('scroll', () => {
      const scrolled = scrollContent.scrollTop
      if (scrolled > 175) {
        setBtnGoUpActive(true)
      } else if (scrolled < 175) {
        setBtnGoUpActive(false)
      }
    })
  }, [])

  const getCurrentIndex = (index) => {
    openSliderFunc(index)
    setDataGalleriesSliderFunc(dataArrayFotos)
  }

  const getCurrentIndex2 = (index) => {
    setDataGalleriesSliderFuncProps(dataArrayFotos)
    // closeModalFuncProps()
    openSliderFuncProps(index, 'fotos')
  }


  let imgItems = dataArrayFotos?.map((item, index) => {
    const url = item.asset?.full_thumbnail_path || item.asset?.full_path;
    return (
      <div key={item?.name} className={styles.imagesBox}>
        <button
          onClick={() =>
            byProps ? getCurrentIndex2(index) : getCurrentIndex(index)
          }
        >
          <img loading="lazy" src={url} alt={item?.asset?.name} />
        </button>
      </div>
    )
  })

  return (
    <main className={styles.main}>
      <div className={styles.wrapperBoxGallery}>
        <div
          id="scrollableDivPictures"
          ref={boxGalleryRef}
          className={styles.boxGallery}
          style={{
            overflow: 'auto',
            maxHeight: height ? height : '780px',
            paddingRight: '10px',
          }}
        >
          {isLoading && (
            <section
              className={
                galleryColumns === 3 ? styles.galleries2 : styles.galleries
              }
            >
              <Skeleton type={'gallery'} />
            </section>
          )}
          <InfiniteScroll
            dataLength={imgItems.length}
            next={() => setPage((prevPage) => prevPage + 1)}
            hasMore={hasMore}
            loader={<LoaderGallery />}
            scrollableTarget="scrollableDivPictures"
          >
            {!isLoading && imgItems.length === 0 ? (
              <section
                className={
                  galleryColumns === 3 ? styles.galleries2 : styles.galleries
                }
              >
                <div>No hay fotos</div>
              </section>
            ) : (
              <section
                className={
                  galleryColumns === 3 ? styles.galleries2 : styles.galleries
                }
              >
                {' '}
                {imgItems}
              </section>
            )}
          </InfiniteScroll>

          <button
            id="btnGoUp"
            onClick={scrollUp}
            className={`${styles.btnGoUp} ${
              btnGoUpActive ? styles.active : ''
            }`}
          >
            <img src={images.iconUpArrow} alt="iconUpArrow" />
          </button>
        </div>
      </div>
      <Slider
        typeSlider={'fotos'}
        arrayDataMedia={dataGalleries}
        closeSlider={closeSliderFunc}
        show={isOpenSlider}
        currentIndexInitial={currentIndex}
        goToNext={goToNext}
        goToPrevious={goToPrevious}
      />
    </main>
  )
}

GalleryPictures.propTypes = {
  dataArrayFotos: PropTypes.array,
  isLoading: PropTypes.bool,
  openSliderFuncProps: PropTypes.func,
  //closeModalFuncProps: PropTypes.func,
  setDataGalleriesSliderFuncProps: PropTypes.func,
  byProps: PropTypes.bool,
  height: PropTypes.string,
  galleryColumns: PropTypes.number,
}
