import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Layout from '../../../../components/mui/Layout';
import usePermission from '../../../../hooks/usePermission';

const DropdownActionComponent = ({ handleIsOpenModal }) => {
  const permissionShow = usePermission('owners.show');
  const permissionDelete = usePermission('owners.delete');
  const permissionPropertiesList = usePermission('owners.properties.list');
  const [anchorEl, setAnchorEl] = useState(null);
  
  /**
   *
   * @description Execute set open menu
   * @return void
   *
   */
  const handleIsOpenMenu = event => setAnchorEl(event.currentTarget);
  
  /**
   *
   * @description Execute set close menu
   * @return void
   *
   */
  const handleCloseMenu = () => setAnchorEl(null);
  
  /**
   *
   * @description Execute dispatch formType, open modal, activityLog state
   * @return void
   *
   */
  const handleAction = async (action) => {
    await handleIsOpenModal(action);
    await handleCloseMenu();
  };
  
  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        onClick={handleIsOpenMenu}
      >
        <Layout.IconButton
          color="inherit"
          size="medium"
          aria-label="actions"
          aria-controls="menu-actions"
          aria-haspopup="true"
        >
          <Layout.Icons.MoreIcon
            width={20}
            height={20}
            className="text-dark"
          />
        </Layout.IconButton>
      </Stack>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          display: { xs: 'block' },
        }}
      >
        <MenuItem onClick={() => handleAction('activity-log')}>
          <ListItemIcon className="font-family-roboto-regular fs-6 text-dark">
            <Layout.Icons.ZoomInIcon
              height={18}
              width={18}
            />
          </ListItemIcon>
          <ListItemText className="font-family-roboto-regular fs-6 text-dark">
            Ver Actividad
          </ListItemText>
        </MenuItem>
        {permissionPropertiesList && (
          <MenuItem onClick={() => handleAction('properties')}>
            <ListItemIcon className="font-family-roboto-regular fs-6 text-dark">
              <Layout.Icons.HomeWorkIcon height={18} width={18} />
            </ListItemIcon>
            <ListItemText className="font-family-roboto-regular fs-6 text-dark">
              Ver Propiedades
            </ListItemText>
          </MenuItem>
        )}
        {permissionShow && (
          <MenuItem onClick={() => handleAction('show')}>
            <ListItemIcon className="font-family-roboto-regular fs-6 text-dark">
              <Layout.Icons.EditIcon
                height={18}
                width={18}
              />
            </ListItemIcon>
            <ListItemText className="font-family-roboto-regular fs-6 text-dark">
              Editar
            </ListItemText>
          </MenuItem>
        )}
        {permissionDelete && (
          <MenuItem onClick={() => handleAction('delete')}>
            <ListItemIcon className="font-family-roboto-regular fs-6 text-dark">
              <Layout.Icons.DeleteIcon
                height={18}
                width={18}
              />
            </ListItemIcon>
            <ListItemText className="font-family-roboto-regular fs-6 text-dark">
              Eliminar
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

DropdownActionComponent.propTypes = {
  handleIsOpenModal: PropTypes.func.isRequired
};

export default DropdownActionComponent;
