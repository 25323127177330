import React from 'react'
import PropTypes from 'prop-types'
import styles from './skeleton.module.css'

export default function Skeleton({ type, countGallerySkeleton = 14 }) {
  const GallerySkeleton = () => (
    <div className={styles.cardGallerySkeleton}>
      <span></span>
      <span></span>
    </div>
  )

  if (type === 'gallery') {
    const GallerySkeletonArr = []
    Array(countGallerySkeleton)
      .fill(<GallerySkeleton />)
      .forEach((item, index) => {
        GallerySkeletonArr.push(<GallerySkeleton key={index} />)
      })
    return GallerySkeletonArr
  }

  if (type === 'gallery') return <GallerySkeleton />
}

Skeleton.propTypes = {
  type: PropTypes.string,
  countCardTotalSkeleton: PropTypes.number,
}
