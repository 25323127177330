import React, { useEffect} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Inputs from '../../../../components/mui/Inputs'
import Layout from '../../../../components/mui/Layout'
import { updateById } from '../../../../redux/actions/owners/authorization/ownerAthorizationAction'
import imgRequest from '../../../../assets/svg/autorization-request.svg'
import imgPending from '../../../../assets/svg/autorization-pending.svg'
import imgSuccess from '../../../../assets/svg/autorization-success.svg'
import imgError from '../../../../assets/svg/autorization-error.svg'

const ContentComponent = ({ uID }) => {
	const dispatch = useDispatch()
	const isLoading = useSelector( state => state.loading.isLoading)
	const isLoadingForm = useSelector( state => state.ownerAuthorization.isLoadingForm)
	const isActiveButton = useSelector( state => state.ownerAuthorization.isActiveButton)
	const messageStatus = useSelector( state => state.ownerAuthorization.messageStatus)
	const typeStatus = useSelector( state => state.ownerAuthorization.typeStatus)
	
	useEffect(() => {
	}, [uID])
	
	const handleAuthorization = async (value) => {
		const param = {
			authorization: value === 1 ? 'Autorizó' : 'No autorizó'
		}
		
		await dispatch (updateById(uID, param) )
	}
	
	return (
		<div className="row">
			{isLoading && (
				<div className="col-sm-12 animate__animated animate__fadeIn">
					<div className="mx-auto d-flex justify-content-center">
						<CircularProgress
							style={{height: '32px', width: '32px'}}
							color="inherit"
						/>
					</div>
				</div>
			)}
			{(!isLoading && !isActiveButton) && (
				<>
					<div className="col-sm-12 animate__animated animate__fadeIn mt-5 mb-4">
						<div className="font-family-roboto-bold fs-2 text-dark-light text-center">
							{messageStatus}
						</div>
					</div>
					{typeStatus !== 'Solicitud' && (
						<div className="col-sm-12 animate__animated animate__fadeIn mb-4">
							<img className="mx-auto d-flex text-center" src={(typeStatus === 'Proceso' && imgPending) || (typeStatus === 'Aprobado' && imgSuccess) || (typeStatus === 'Rechazado' && imgError)} alt="request" />
						</div>
					)}
				</>
			)}
			{(!isLoading && isActiveButton) && (
				<>
					<div className="col-sm-12 animate__animated animate__fadeIn mt-5 mb-4">
						<div className="font-family-roboto-bold fs-2 text-dark-light text-center">
							Paso de autorización
						</div>
					</div>
					<div className="col-sm-12 animate__animated animate__fadeIn mb-4">
						<img className="mx-auto d-flex text-center" src={imgRequest} alt="request" />
					</div>
					<div className="col-sm-12 animate__animated animate__fadeIn mb-4">
						<div className="font-family-roboto-semibold fs-4 text-grey text-center">
							Seleccione una opción para completar el paso de autorización
						</div>
					</div>
					<div className="col-sm-12 animate__animated animate__fadeIn">
						<div className="mx-auto d-flex justify-content-center">
							<Inputs.LoadingButton
								size="large"
								variant="contained"
								isLoading={isLoadingForm}
								isLoadingPosition="start"
								startIcon={<Layout.Icons.CloseIcon height={18} width={18} />}
								label="No Autorizo"
								type="button"
								className="bg-secondary-outlined font-family-roboto-medium fs-6 text-uppercase mr-3"
								onClick={() => handleAuthorization(0)}
							/>
							<Inputs.LoadingButton
								size="large"
								disabled={isLoadingForm}
								variant="contained"
								isLoading={isLoadingForm}
								isLoadingPosition="end"
								endIcon={<Layout.Icons.VerifiedUserIcon height={18} width={18} />}
								label="Autorizo"
								type="button"
								className="bg-primary font-family-roboto-medium fs-6 text-uppercase text-white ml-3"
								onClick={() => handleAuthorization(1)}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	)
}

ContentComponent.propTypes = {
	uID: PropTypes.string.isRequired
}

export default ContentComponent
