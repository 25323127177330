import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../../../components/mui/Layout';
import TableRowComponent from './TableRowComponent';
import { getArrayList } from '../services';
import { setIsLoading } from '../../../../redux/loading/actions/loadingAction';
import { setPerPage, setPage, setPages, setArrayList } from '../redux/actions/ownerAction';
import swalErrors from '../../../../hooks/useErrors';
import usePermission from '../../../../hooks/usePermission';
import { ownerAllColumns, ownerNaturalColumns, ownerLegalColumns, perPages } from '../../../../helpers/variablesColumns';

const TableComponent = () => {
	const dispatch = useDispatch();
	const isLoading = useSelector( ({ loading }) => loading.isLoading);
	const refreshTable = useSelector( ({ owner }) => owner.refreshTable);
	const perPage = useSelector( ({ owner }) => owner.perPage);
	const page = useSelector( ({ owner }) => owner.page);
	const search = useSelector( ({ owner }) => owner.search);
	const personType = useSelector( ({ owner }) => owner.personType)
	const pages = useSelector( ({ owner }) => owner.pages);
	const arrayList = useSelector( ({ owner }) => owner.arrayList);
	const permission = usePermission('owners.list');
	
	/**
	 *
	 * @description Execute endpoint array list of owners
	 * @return dispatch
	 *
	 */
	const handleArrayList = async () => {
		if (permission) {
			await dispatch(setIsLoading(page === 1 ? true : false));
			
			try {
				const param = {
					perPage: perPage,
					page: page,
					search: search === '' ? null : search,
					person_type: personType
				};
				
				const { data } = await getArrayList(param);
				
				if (data.status === 200) {
					await dispatch(setPages(data.data.pages));
					await dispatch(setArrayList(data.data.records));
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch(setIsLoading(false));
		}
	};
	
	useEffect(() => {
		handleArrayList()
	}, [
		perPage,
		page,
		personType,
		refreshTable
	]);
	
	/**
	 *
	 * @description Execute dispatch perPage state
	 * @return dispatch
	 *
	 */
	const handleChangePerPage = ({ target }) => dispatch( setPerPage(target.value) );
	
	/**
	 *
	 * @description Execute dispatch page state
	 * @return dispatch
	 *
	 */
	const handleChangePage = (event, value) => dispatch( setPage(value) );
	
	return (
		<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
			<Layout.CardContent className="m-0 p-4">
				{permission && (
					<>
						<Layout.TablePagination
							arrayList={arrayList.length}
							perPage={perPage}
							perPages={perPages}
							page={page}
							pages={pages}
							handleChangePerPage={handleChangePerPage}
							handleChangePage={handleChangePage}
						/>
						<Layout.Table>
							<Layout.TableHead columns={personType === 'Natural' ? ownerNaturalColumns : ( personType === 'Jurídica' ? ownerLegalColumns : ownerAllColumns)} />
							<tbody>
								{isLoading && (
									<Layout.TableRowLoading columns={personType === 'Natural' ? ownerNaturalColumns : ( personType === 'Jurídica' ? ownerLegalColumns : ownerAllColumns)} />
								)}
								{!isLoading && (
									<>
										{arrayList.length === 0 && (
											<Layout.TableRowNoFound colSpan={personType === 'Natural' ? ownerNaturalColumns.length : ( personType === 'Jurídica' ? ownerLegalColumns.length : ownerAllColumns.length)} />
										)}
										{arrayList.length > 0 && (
											arrayList.map(({ id, person_type, company, first_name, last_name, email, mobile, status }, number) => (
												<TableRowComponent
													key={number}
													id={id}
													type={personType}
													personType={person_type}
													company={company}
													name={person_type === 'Natural' ? `${first_name} ${last_name || ''}` : ''}
													email={email}
													mobile={mobile}
													status={status}
												/>
											))
										)}
									</>
								)}
							</tbody>
						</Layout.Table>
						<Layout.TablePagination
							arrayList={arrayList.length}
							perPage={perPage}
							perPages={perPages}
							page={page}
							pages={pages}
							handleChangePerPage={handleChangePerPage}
							handleChangePage={handleChangePage}
						/>
					</>
				)}
			</Layout.CardContent>
		</Layout.Card>
	);
};

export default TableComponent;
