import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Feedback from '../../../../../components/mui/Feedback'
import FormComponent from './FormComponent'
import { Permission } from '../../../../../services/permissions/Permission'

const ModalComponent = ({ isOpen, uID, handleRefresh, handleIsOpenModal }) => {
	
	useEffect(() => {
	}, [isOpen, uID])
	
	const handleCloseModal = () => handleIsOpenModal()
	
	return (
		<Feedback.Modal
			maxWidth="sm"
			isOpen={isOpen}
			isClose={handleCloseModal}
			title="Ingresar nueva Área"
		>
			<Permission permission="properties.areas.store">
				<FormComponent
					uID={uID}
					handleRefresh={handleRefresh}
					handleCloseModal={handleCloseModal}
				/>
			</Permission>
		</Feedback.Modal>
	)
}

ModalComponent.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	uID: PropTypes.string.isRequired,
	handleIsOpenModal: PropTypes.func.isRequired
}

export default ModalComponent
