import React from 'react'
import PropTypes from 'prop-types'
import MuiCardActions from '@mui/material/CardActions'

const CardActions = ({ children, ...more }) => {
	
	return (
		<MuiCardActions {...more}>
			{children}
		</MuiCardActions>
	)
}

CardActions.propTypes = {
	children: PropTypes.any.isRequired
}

export default CardActions
