import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setIsOpenSidebar } from '../../../../redux/actions/dashboard/sidebarAction'

const useSlider = (initialValue = false) => {
  const dispatch = useDispatch()
  const [isOpenSlider, setIsOpenSlider] = useState(initialValue)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [dataGalleries, setDataGalleries] = useState([])
  const [getTypeSlider, setGetTypeSlider] = useState('')

  const setDataGalleriesSliderFunc = (data) => {
    setDataGalleries(data)
  }

  const closeSliderFunc = () => {
    document.getElementsByTagName('header')[0].style.zIndex = ''
    setIsOpenSlider(false)
  }

  const openSliderFunc = (index) => {
    document.getElementsByTagName('header')[0].style.zIndex = '0'
    dispatch(setIsOpenSidebar(false))
    setCurrentIndex(index)
    setIsOpenSlider(true)
    // setTimeout(() => {
    //   setIsOpenSlider(true)
    // }, '600')
  }

  const goToNext = () => {
    const total = dataGalleries.length || 0
    if (currentIndex === total - 1) {
      return setCurrentIndex(0)
    }
    setCurrentIndex(currentIndex + 1)
  }

  const goToPrevious = () => {
    const total = dataGalleries.length || 0
    if (currentIndex === 0) {
      return setCurrentIndex(total - 1)
    }
    setCurrentIndex(currentIndex - 1)
  }

  const closeSliderFunc2 = () => {
    setIsOpenSlider(false)
  }

  const openSliderFunc2 = (index, typeSlider) => {
    dispatch(setIsOpenSidebar(false))
    setCurrentIndex(index)
    setGetTypeSlider(typeSlider)
    setIsOpenSlider(true)
    // setTimeout(() => {
    //   setIsOpenSlider(true)
    // }, '600')
  }

  return {
    isOpenSlider,
    closeSliderFunc,
    openSliderFunc,
    setDataGalleriesSliderFunc,
    dataGalleries,
    currentIndex,
    goToNext,
    goToPrevious,
    openSliderFunc2,
    closeSliderFunc2,
    getTypeSlider,
  }
}

export default useSlider
