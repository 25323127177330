import React from 'react'
import MuiRating from '@material-ui/lab/Rating'
import Box from '@material-ui/core/Box'
import Display from '../display/Display'

export default function Rating(props) {
    const { label, name, defaultValue, ...other } = props

    return (
        <Box component="fieldset" mb={3} borderColor="transparent">
            <Display.Typography
                variant="subtitle1"
                color="textSecondary"
                label={label}
            />
            <MuiRating
                name={name}
                value={defaultValue}
                defaultValue={defaultValue}
                {...other}
            />
        </Box>
    )
}
