import React from 'react'
import PropTypes from 'prop-types'
import MuiRating from '@mui/material/Rating'

const Rating = ({ size, name, hasLabel = false, label = '', value = 0, max, ...more }) => {
	
	return (
		<>
			{hasLabel && (
				<div className="font-family-roboto-medium text-dark fs-6 w-100">
					{label}
				</div>
			)}
			<MuiRating
				size={size}
				name={name}
				value={value}
				max={max}
				{...more}
			/>
		</>
	)
}

Rating.propTypes = {
	size: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	hasLabel: PropTypes.bool,
	label: PropTypes.string,
	value: PropTypes.number,
	max: PropTypes.number.isRequired
}

export default Rating
