export const PER_PAGE_STATE = 'PER_PAGE_STATE';
export const PAGE_STATE = 'PAGE_STATE';
export const SEARCH_STATE = 'SEARCH_STATE';
export const PAGES_STATE = 'PAGES_STATE';
export const ARRAY_LIST_STATE = 'ARRAY_LIST_STATE';
export const ARRAY_LIST_TO_DROPDOWN_STATE = 'ARRAY_LIST_TO_DROPDOWN_STATE';
export const REFRESH_TABLE_STATE = 'REFRESH_TABLE_STATE';
export const IS_OPEN_MODAL_STATE = 'IS_OPEN_MODAL_STATE';
export const IS_LOADING_SHOW_STATE = 'IS_LOADING_SHOW_STATE';
export const IS_LOADING_FORM_STATE = 'IS_LOADING_FORM_STATE';
export const FORM_TYPE_STATE = 'FORM_TYPE_STATE';
export const FORM_DATA_STATE = 'FORM_DATA_STATE';
