export const PER_PAGE_COMMERCIAL_STATUS = 'PER_PAGE_COMMERCIAL_STATUS';
export const PAGE_COMMERCIAL_STATUS = 'PAGE_COMMERCIAL_STATUS';
export const SEARCH_COMMERCIAL_STATUS = 'SEARCH_COMMERCIAL_STATUS';
export const REALSTATE_COMMERCIAL_STATUS = 'REALSTATE_COMMERCIAL_STATUS';
export const PAGES_COMMERCIAL_STATUS = 'PAGES_COMMERCIAL_STATUS';
export const ARRAY_LIST_COMMERCIAL_STATUS = 'ARRAY_LIST_COMMERCIAL_STATUS';
export const ARRAY_LIST_TO_DROPDOWN_COMMERCIAL_STATUS = 'ARRAY_LIST_TO_DROPDOWN_COMMERCIAL_STATUS';
export const REFRESH_TABLE_COMMERCIAL_STATUS = 'REFRESH_TABLE_COMMERCIAL_STATUS';
export const IS_OPEN_MODAL_COMMERCIAL_STATUS = 'IS_OPEN_MODAL_COMMERCIAL_STATUS';
export const IS_LOADING_SHOW_COMMERCIAL_STATUS = 'IS_LOADING_SHOW_COMMERCIAL_STATUS';
export const IS_LOADING_FORM_COMMERCIAL_STATUS = 'IS_LOADING_FORM_COMMERCIAL_STATUS';
export const FORM_TYPE_COMMERCIAL_STATUS = 'FORM_TYPE_COMMERCIAL_STATUS';
export const FORM_DATA_COMMERCIAL_STATUS = 'FORM_DATA_COMMERCIAL_STATUS';
