import React from 'react'
import PropTypes from 'prop-types'
import MuiBox from '@mui/material/Box'

const Box = ({ children = null, ...more  }) => {
	
	return (
		<MuiBox
			{...more}
		>
			{children}
		</MuiBox>
	)
}

Box.propTypes = {
	children: PropTypes.any
}

export default Box
