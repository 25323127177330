export const PER_PAGE_KEY = 'PER_PAGE_KEY';
export const PAGE_KEY = 'PAGE_KEY';
export const SEARCH_KEY = 'SEARCH_KEY';
export const PAGES_KEY = 'PAGES_KEY';
export const ARRAY_LIST_KEY = 'ARRAY_LIST_KEY';
export const ARRAY_LIST_TO_DROPDOWN_KEY = 'ARRAY_LIST_TO_DROPDOWN_KEY';
export const REFRESH_TABLE_KEY = 'REFRESH_TABLE_KEY';
export const IS_OPEN_MODAL_KEY = 'IS_OPEN_MODAL_KEY';
export const IS_LOADING_SHOW_KEY = 'IS_LOADING_SHOW_KEY';
export const IS_LOADING_FORM_KEY = 'IS_LOADING_FORM_KEY';
export const FORM_TYPE_KEY = 'FORM_TYPE_KEY';
export const FORM_DATA_KEY = 'FORM_DATA_KEY';
