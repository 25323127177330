import endPoints from './endPoints'
import axios from '../../../../../services/axios'

const {
	arrayList,
	arrayListFavorites,
	arrayListLogs,
	verification,
	store,
	show,
	preview,
	update,
	updateAdviser,
	storeFavorite,
	deleteFavorite,
	showCustomer,
	updateCustomer
} = endPoints

export const getArrayList = async (data) => (
	await axios.post(arrayList, data)
)

export const getArrayListFavoritesById = async (id) => (
	await axios.get(`${arrayListFavorites}${id}`)
)

export const getArrayListLogsById = async (data) => (
	await axios.post(arrayListLogs, data)
)

export const getVerificationByMobile = async (mobile) => (
	await axios.get(`${verification}${mobile}`)
)

export const setStore = async (data) => (
	await axios.post(store, data)
)


export const getShowById = async (id) => (
	await axios.get(`${show}${id}`)
)

export const getPreviewById = async (id) => (
	await axios.get(`${preview}${id}`)
)

export const setUpdateById = async (id, data) => (
	await axios.put(`${update}${id}`, data)
)

export const setUpdateByIdAdviser = async (id, data) => (
	await axios.put(`${updateAdviser}${id}`, data)
)

export const setStoreFavorite = async (data) => (
	await axios.post(storeFavorite, data)
)

export const setDeleteFavoriteById = async (id) => (
	await axios.delete(`${deleteFavorite}${id}`)
)

export const getShowCustomerById = async (id) => (
	await axios.get(`${showCustomer}${id}`)
)

export const setUpdateCustomerById = async (id, data) => (
	await axios.put(`${updateCustomer}${id}`, data)
)

