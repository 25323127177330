import { Typography } from '@mui/material'
import React from 'react'
import images from '../img'
import styles from './footer.module.css'

export const Footer = () => {
  const textStyle = {
    color: 'dark.default',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '125%',
  }

  const textStyleBlue = {
    color: 'primary',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
    marginBottom: '10px',
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.dFlexBetween}>
        <div className={styles.dFlexLogo}>
          <div className={styles.logo}>
            <img loading="lazy" src={images.logo} alt="log" />
          </div>

          <Typography {...textStyle}>
            Desde 1983, en Financar Inmobiliaria ofrecemos servicio de avalúo,
            arriendo, venta, gestión de proyectos y administración de inmuebles.
          </Typography>
        </div>

        <div className={styles.dFlexWrapper}>
          <Typography {...textStyleBlue}>Oficinas Barranquilla</Typography>

          <Typography {...textStyle}>
            Cra 53 Cl 110 esquina, Local 7,
          </Typography>
          <Typography {...textStyle}>BC Empresarial</Typography>
          <a href="#!">Política de tratamiento de datos</a>
        </div>
        <div className={styles.dFlexWrapper}>
          <Typography {...textStyleBlue}>Contacto</Typography>
          <Typography {...textStyle}>
            PBX: (605) 3303333 <br /> <br /> contacto1@financar.com.co
          </Typography>
          <a href="#!">Quejas y reclamos</a>
        </div>
      </div>

      <div className={styles.dFlexStart}>
        <div className={styles.socialMedia}>
          <img src={images.iconFacebook} alt="iconFacebook" />
          <img src={images.iconInstagram} alt="iconInstagram" />
        </div>
        <p className={styles.copyright}>
          Financar © 2022. Reservados todos los derechos.
        </p>
      </div>
    </footer>
  )
}
