import React from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

export const TextFieldReadOnly = ({ value, label }) => {
  return (
    <Grid container flexDirection='column' item>
      <TextField
        type='text'
        size='small'
        label={label}
        value={value}
        InputProps={{ readOnly: true }}
      />
    </Grid>
  )
}

TextFieldReadOnly.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
}
