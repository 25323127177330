import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../../redux/store';
import Layout from '../../../components/mui/Layout';
import Inputs from '../../../components/mui/Inputs';
import Feedback from '../../../components/mui/Feedback';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import { getVerification, setResendVerification } from '../services';
import { setIsLoading } from '../../../redux/loading/actions/loadingAction';
import { setIsLoadingForm, setIsActiveButton, setColorStatus, setTextStatus, setIsActiveAlert, setResetVerification } from '../redux/actions/verificationAction';
import { swalSuccess } from '../../../hooks/useSweetAlert';
import swalErrors from '../../../hooks/useErrors';
import '../Auth.scss';

const VerificationPage = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const uID = params.uID;
	const isLoading = useSelector( ({ loading }) => loading.isLoading);;
	const isLoadingForm = useSelector( ({ verification })=> verification.isLoadingForm);
	const isActiveButton = useSelector( ({ verification }) => verification.isActiveButton);
	const isActiveAlert = useSelector( ({ verification }) => verification.isActiveAlert);
	const colorStatus = useSelector( ({ verification }) => verification.colorStatus);
	const textStatus = useSelector( ({ verification }) => verification.textStatus);
	
	/**
	 *
	 * @description Execute verification of the user
	 * @return dispatch
	 *
	 */
	const handleVerification = async () => {
		await dispatch( setIsLoading(true) );
		
		try {
			const { data } = await getVerification(uID);
			
			if (data.status === 200) {
				await dispatch( setColorStatus('success') );
				await dispatch( setTextStatus(data.data) );
				await dispatch( setIsActiveButton(false) );
				await dispatch( setIsActiveAlert(true) );
			}
		} catch ({ response }) {
			await dispatch( setIsActiveButton((response.data.errors[0] === 'El usuario se encuentra verificado' || response.data.errors[0] === 'El usuario no se encuentra registrado') ? false : true) );
			await dispatch( setColorStatus('danger') );
			await dispatch( setTextStatus(response.data.errors[0]) );
			await dispatch( setIsActiveAlert(true) );
		}
		
		await dispatch( setIsLoading(false) );
	};
	
	useEffect(() => {
		handleVerification();
	}, [uID]);
	
	/**
	 *
	 * @description Execute resend verification of the user
	 * @return dispatch
	 *
	 */
	const handleResendVerification = async () => {
		await dispatch( setIsLoadingForm(true) );
		
		try {
			const { data } = await setResendVerification(uID);
			
			if (data.status === 200) {
				await swalSuccess('Solicitud exitosa', data.data);
				await dispatch( setResetVerification() );
				await history.push('/');
			}
		} catch (error) {
			await swalErrors(error);
		}
		
		await dispatch( setIsLoadingForm(false) );
	};
	
	return (
		<Layout.Page title="Cambio de contraseña">
			<Layout.Card className="card form-container">
				<HeaderComponent />
				<Layout.CardContent className="m-0 pt-4 pr-4 pl-4 pb-0">
					{!isLoading && isActiveAlert && (
						<div className={`animated animate__fadeIn mb-2 alert alert-${colorStatus}`}>
							<div className="font-family-roboto-medium fs-6 text-center">
								{textStatus}
							</div>
						</div>
					)}
					{isActiveButton && (
						<Inputs.LoadingButton
							variant="contained"
							isLoading={isLoadingForm}
							isLoadingPosition="start"
							startIcon={<Layout.Icons.MailIcon height={18} width={18} />}
							label="Reenviar solicitud"
							type="button"
							className="animated animate__fadeIn font-family-roboto-regular fs-6 bg-primary text-capitalize w-100"
							onClick={handleResendVerification}
						/>
					)}
				</Layout.CardContent>
				<FooterComponent
					to="/"
					label="Volver al inicio de sesión"
				/>
			</Layout.Card>
			<Feedback.ModalLoading
				isOpen={isLoading}
				title="Cargando..."
				content="Espere un momento por favor..."
			/>
		</Layout.Page>
	);
};

export default VerificationPage;
