import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Grid } from '@mui/material'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import { Permission } from '../../../../../services/permissions/Permission'
import { BottomActionStyles } from './Styles'
import { setFormDataDetail, updateByIdAdviser } from '../../../../../redux/actions/dashboard/filtered/filteredAction'
import { getArrayToDropdownAdviser } from '../../../../../redux/actions/dashboard/settings/users/userAction'

const FormComponentAdviser = ({ isActive }) => {
	const dispatch = useDispatch()
	const isLoadingForm = useSelector( state => state.filtered.isLoadingForm)
	const formType = useSelector( state => state.filtered.formType)
	const formData = useSelector( state => state.filtered.formDataDetail)
	const arrayDropdownAdviser = useSelector( state => state.user.arrayDropdownAdviser)
	
	useEffect(() => {
	}, [isActive])
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			adviser: Yup.string()
				.required('El funcionario es requerido')
		})
	)
	
	/**
	 *
	 * @description Execute endpoint array list in dropdown of adviser
	 * @return dispatch
	 *
	 */
	const handleDropdownAdviser = async () => {
		const param = {
			realstate: formData.realState,
			type: formData.service
		}
		
		await dispatch( getArrayToDropdownAdviser(param) )
	}
	
	useEffect(() => {
		if (isActive && formType === 2) handleDropdownAdviser()
	}, [isActive, formType, formData.service])
	
	/**
	 *
	 * @description Execute dispatch formDataDetail state
	 * @return dispatch
	 *
	 */
	const handleOnChange = (attr, value) => dispatch( setFormDataDetail( { ...formData, [attr]: value }) )
	
	useEffect(() => {
	}, [formData])
	
	/**
	 *
	 * @description Execute submit of the form
	 * @param status
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (status) => {
		if (formType === 2) {
			const param = {
				ranking: formData.ranking === '' ? null : formData.ranking,
				adviser: formData.adviser,
				status: status
			}
			
			await dispatch(updateByIdAdviser(formData.id, param))
		}
	}
	
	return (
		<Permission
			permission="filtereds.show"
		>
			{(isActive && formType === 2) && (
				<Layout.Box
					className="animate__animated animate__fadeIn"
				>
					<Formik
						initialValues={formData}
						validationSchema={validationSchema}
						onSubmit={() => {}}
					>
						{({
						  errors,
						  touched,
						  handleChange,
						  handleBlur,
						  isValid,
						  dirty
					  }) => (
							<Form noValidate>
								<Layout.Paper
									sx={{ ...BottomActionStyles, flexDirection: "column" }}
								>
									<Grid
										container
										gap={2}
										display="grid"
										gridTemplateColumns="max-content 1fr"
										alignItems="center"
									>
										<Inputs.Rating
											size="medium"
											name="ranking"
											value={formData.ranking}
											max={5}
											onChange={(_, newValue) => {
												handleOnChange("ranking", newValue)
												handleChange(newValue)
											}}
										/>
										
										<Grid
											container
											flexDirection="column"
											item
										>
											<Permission
												permission="usersByAdviser.dropdown"
											>
												<Inputs.Select
													size="small"
													label="Funcionario"
													name="adviser"
													value={formData.adviser}
													itemsArray={arrayDropdownAdviser}
													onChange={e => {
														handleOnChange("adviser", e.target.value)
														handleChange(e)
													}}
													onBlur={handleBlur}
													hasError={errors.adviser && touched.adviser ? true : false}
													errorMessage={errors.adviser && touched.adviser ? errors.adviser : ''}
													className="font-family-roboto-regular fs-6 text-dark w-100"
												/>
											</Permission>
										</Grid>
									</Grid>
									
									<Permission permission="filtereds.updateAdviser">
										<div className="row mt-3">
											<div className="col-12 col-sm-6">
												<Inputs.LoadingButton
													isDisabled={!(dirty && isValid)}
													size="large"
													variant="outlined"
													isLoading={isLoadingForm}
													isLoadingPosition="start"
													startIcon={<Layout.Icons.DeleteIcon height={18} width={18} />}
													label="Desechar"
													type="button"
													onClick={() => hanbleSubmit('Desechado')}
													className={`${(dirty && isValid) && 'bg-secondary-outlined'} font-family-roboto-medium fs-6 text-uppercase w-100`}
												/>
											</div>
											<div className="col-12 col-sm-6">
												<Inputs.LoadingButton
													isDisabled={!(dirty && isValid)}
													size="large"
													variant="outlined"
													isLoading={isLoadingForm}
													isLoadingPosition="end"
													endIcon={<Layout.Icons.SendIcon height={18} width={18} />}
													label="Comercial"
													type="button"
													onClick={() => hanbleSubmit('Comercial')}
													className={`${(dirty && isValid) && 'bg-primary'} font-family-roboto-medium fs-6 text-uppercase w-100`}
												/>
											</div>
										</div>
									</Permission>
								</Layout.Paper>
							</Form>
						)}
					</Formik>
				</Layout.Box>
			)}
		</Permission>
	)
}

FormComponentAdviser.propTypes = {
	isActive: PropTypes.bool.isRequired
}

export default FormComponentAdviser
