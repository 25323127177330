import React from 'react'
import Update from '@material-ui/icons/Update'

export default function UpdateIcon(props) {

    const { ...other } = props

    return (
        <Update { ...other } />
    )
}
