import * as types from '../../../actionsTypes/algolia/algoliaPropertyTypes'
import { getArrayList } from './services'
import errorServices from '../../../../services/errors'

export const getArray = (param, arrayList = [], isPagination = false, withCode = null) => (
  async (dispatch) => {
    await dispatch(setIsLoading(true))

    await getArrayList(param).then(({ data }) => {
        dispatch(setPages(data.data.last_page))
        dispatch(setHasNextPage(data.data.last_page > param.page))
	    
        if (isPagination && withCode == null) {
          dispatch(setArrayList(data.data.records.length === 0 ? [] : [...arrayList, ...data.data.records]))
        } else {
          dispatch(setArrayList(data.data.records.length === 0 ? [] : data.data.records))
        }
      }).catch((error) =>{
				// console.log({error})
				errorServices(error)
			})

    await dispatch(setIsLoading(false))
  }
)

export const setAlgoliaPropertyReset = () => (
	async (dispatch) => {
		await dispatch( setPerPage(20) )
		await dispatch( setPage(1) )
		await dispatch( setPages(0) )
		await dispatch( setArrayList([]) )
		await dispatch( setIsLoading(false) )
	}
)

export const setPerPage = value => ({
	type: types.PER_PAGE_ALGOLIA_PROPERTY,
	payload: value
})

export const setPage = value => ({
	type: types.PAGE_ALGOLIA_PROPERTY,
	payload: value
})

export const setPages = value => ({
	type: types.PAGES_ALGOLIA_PROPERTY,
	payload: value
})

export const setHasNextPage = value => ({
	type: types.HAS_NEXT_PAGE_ALGOLIA_PROPERTY,
	payload: value
})

export const setArrayList = value => ({
	type: types.ARRAY_LIST_ALGOLIA_PROPERTY,
	payload: value
})

export const setIsLoading = value => ({
	type: types.IS_LOADING_ALGOLIA_PROPERTY,
	payload: value
})
