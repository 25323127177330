import React from 'react'
import PropTypes from 'prop-types'

const TableBody = ({ children }) => {
	return (
		<div className="row mt-3 mb-3">
			<div className="col-sm-12">
				<div className="table-responsive">
					<table className="table table-bordered table-striped table-hover align-middle m-0">
						{children}
					</table>
				</div>
			</div>
		</div>
	)
}

TableBody.propTypes = {
	children: PropTypes.any.isRequired
}

export default TableBody
