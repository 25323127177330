import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Inputs from '../../../../components/mui/Inputs';
import Layout from '../../../../components/mui/Layout';
import Feedback from '../../../../components/mui/Feedback';
import DropdownSelectState from '../../settings/location/StatePage/components/DropdownSelect';
import DropdownSelectCity from '../../settings/location/CityPage/components/DropdownSelect';
import DropdownSelectOriginChannel from '../../settings/filtered/OriginChannelPage/components/DropdownSelect';
import DropdownSelectBank from '../../settings/BankPage/components/DropdownSelect';
import { setStore, getShowById, setUpdateById } from '../services';
import { setFormData, setIsLoadingShow, setIsLoadingForm, setRefreshTable, setIsOpenModal } from '../redux/actions/ownerAction';
import usePermission from '../../../../hooks/usePermission';
import swalErrors from '../../../../hooks/useErrors';
import { initialValuesOwner as initialValues } from '../../../../helpers/variablesInitialValues';
import { ownerPersonType, ownerDocType1, ownerDocType2, ownerAccountType } from '../../../../helpers/variablesColumns';

const FormComponent = () => {
	const dispatch = useDispatch();
	const refreshTable = useSelector( ({ owner }) => owner.refreshTable);
	const isLoadingShow = useSelector( ({ owner }) => owner.isLoadingShow);
	const isLoadingForm = useSelector( ({ owner }) => owner.isLoadingForm);
	const formType = useSelector( ({ owner }) => owner.formType);
	const formData = useSelector( ({ owner }) => owner.formData);
	const permissionStore = usePermission('owners.store');
	const permissionShow = usePermission('owners.show');
	const permissionUpdate = usePermission('owners.update');
	
	/**
	 *
	 * @description Execute endpoint show by id
	 * @return dispatch
	 *
	 */
	const handleShowById = async () => {
		if (formType === 'show' && permissionShow) {
			await dispatch( setIsLoadingShow(true) );
			
			try {
				const { data } = await getShowById(formData.id);
				
				if (data.status === 200) {
					const object = {
						id: data.data.id,
						personType: data.data.person_type,
						company: data.data.company === null ? '' : data.data.company,
						representativeFirstName: data.data.representative_first_name === null ? '' : data.data.representative_first_name,
						representativeLastName: data.data.representative_last_name === null ? '' : data.data.representative_last_name,
						representativeEmail: data.data.representative_email === null ? '' : data.data.representative_email,
						representativeMobile: data.data.representative_mobile === null ? '' : data.data.representative_mobile,
						firstName: data.data.first_name === null ? '' : data.data.first_name,
						lastName: data.data.last_name === null ? '' : data.data.last_name,
						docType: data.data.doc_type === null ? '' : data.data.doc_type,
						docId: data.data.doc_id === null ? '' : data.data.doc_id,
						email: data.data.email,
						mobile: data.data.mobile,
						phone: data.data.phone === null ? '' : data.data.phone,
						address: data.data.address === null ? '' : data.data.address,
						notes: data.data.notes === null ? '' : data.data.notes,
						acceptData: data.data.accept_data ? true : false,
						accountType: data.data.account_type === null ? '' : data.data.account_type,
						accountNumber: data.data.account_number === null ? '' : data.data.account_number,
						bank: data.data.bank === null ? '' : data.data.bank,
						state: data.data.state === null ? '' : data.data.state,
						city: data.data.city === null ? '' : data.data.city,
						originChannel: data.data.origin_channel === null ? '' : data.data.origin_channel
					};
					
					await dispatch( setFormData(object) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingShow(false) );
		}
	};
	
	useEffect(() => {
		handleShowById();
	}, [formType]);
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => {
		if (formData.personType === 'Jurídica') {
			return Yup.object({
				company: Yup.string()
				.required('La razón social es requerida'),
				representativeFirstName: Yup.string()
				.required('El nombre del representante es requerida'),
				representativeLastName: Yup.string()
				.required('El apellido del representante es requerida'),
				representativeEmail: Yup.string()
				.email('El email del representante no es válido')
				.required('El email del representante es requerido'),
				representativeMobile: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
				.required('El celular del representante es requerida'),
				email: Yup.string()
				.email('El email no es válido')
				.required('El email es requerido'),
				mobile: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
				.required('El celular es requerida'),
			})
		}
		
		return Yup.object({
			firstName: Yup.string()
			.required('El nombre es requerida'),
			lastName: Yup.string()
			.required('El apellido es requerida'),
			email: Yup.string()
			.email('El email no es válido')
			.required('El email es requerido'),
			mobile: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
			.required('El celular es requerida'),
		})
	}
	
	/**
	 *
	 * @description Execute submit of the form
	 * @param values
	 * @param resetForm
	 * @param setValues
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		if (formType === 'store' ? permissionStore : permissionUpdate) {
			await dispatch( setIsLoadingForm(true) );
			
			try {
				const param = {
					person_type: formData.personType,
					company: formData.company,
					representative_first_name: formData.representativeFirstName,
					representative_last_name: formData.representativeLastName,
					representative_email: formData.representativeEmail,
					representative_mobile: formData.representativeMobile,
					first_name: formData.firstName,
					last_name: formData.lastName,
					doc_type: formData.docType,
					doc_id: formData.docId,
					email: formData.email,
					mobile: formData.mobile,
					phone: formData.phone,
					address: formData.address,
					notes: formData.notes,
					accept_data: formData.acceptData ? 1 : 0,
					account_type: formData.accountType,
					account_number: formData.accountNumber,
					bank_id: formData.bank,
					city_id: formData.city,
					origin_channel_id: formData.originChannel
				};
				
				let data;
				
				if (formType === 'store') {
					data = await setStore(param);
				} else {
					data = await setUpdateById(formData.id, param);
				}
				
				if (data.data.status === 200) {
					resetForm({ values: initialValues});
					setValues(initialValues);
					await dispatch( setFormData(initialValues) );
					await dispatch( setRefreshTable(!refreshTable) );
					await dispatch( setIsOpenModal(false) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingForm(false) );
		}
	};
	
	/**
	 *
	 * @description Execute dispatch formData state
	 * @return dispatch
	 *
	 */
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.name === 'acceptData' ? !formData.acceptData : target.value }) );
	
	return (
		<>
			{isLoadingShow && (
				<Feedback.Loading />
			)}
			{!isLoadingShow && (
				<Formik
					initialValues={formData}
					validationSchema={validationSchema}
					onSubmit={hanbleSubmit}
				>
					{({
					  errors,
					  touched,
					  handleChange,
					  handleBlur,
					  isValid,
					  dirty
				  }) => (
						<Form noValidate>
							<div className="row">
								<div className="col-sm-12 col-md-6">
									<div className="font-family-roboto-light fs-6 text-dark w-100">
										Tipo de persona
									</div>
									<Inputs.ButtonGroup
										variant="outlined"
										name="personType"
										value={formData.personType === 'Natural' ? 'Jurídica' : 'Natural'}
										itemsArray={ownerPersonType}
										className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
										condition={formData.personType}
										onClick={handleOnChange}
									/>
								</div>
							</div>
							
							{formData.personType === 'Natural' && (
								<div className="row animate__animated animate__fadeIn">
									<div className="col-sm-12 col-md-6 mt-3">
										<Inputs.TextBox
											name="firstName"
											label="Nombre"
											placeholder="Ingrese nombre..."
											hasLabelFixed={true}
											value={formData.firstName}
											onChange={e => {
												handleOnChange(e)
												handleChange(e)
											}}
											onBlur={handleBlur}
											hasError={errors.firstName && touched.firstName ? true : false}
											errorMessage={errors.firstName && touched.firstName ? errors.firstName : ''}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
									<div className="col-sm-12 col-md-6 mt-3">
										<Inputs.TextBox
											name="lastName"
											label="Apellido"
											placeholder="Ingrese apellido..."
											hasLabelFixed={true}
											value={formData.lastName}
											onChange={e => {
												handleOnChange(e)
												handleChange(e)
											}}
											onBlur={handleBlur}
											hasError={errors.lastName && touched.lastName ? true : false}
											errorMessage={errors.lastName && touched.lastName ? errors.lastName : ''}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
								</div>
							)}
							
							{formData.personType === 'Jurídica' && (
								<>
									<div className="row animate__animated animate__fadeIn">
										<div className="col-sm-12 col-md-12 mt-3">
											<Inputs.TextArea
												name="company"
												label="Razón social"
												placeholder="Ingrese razón social..."
												hasLabelFixed={true}
												value={formData.company}
												onChange={e => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.company && touched.company ? true : false}
												errorMessage={errors.company && touched.company ? errors.company : ''}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
									</div>
									<div className="row animate__animated animate__fadeIn">
										<div className="col-sm-12 col-md-6 mt-3">
											<Inputs.TextBox
												name="representativeFirstName"
												label="Nombre del representante"
												placeholder="Ingrese nombre del representante..."
												hasLabelFixed={true}
												value={formData.representativeFirstName}
												onChange={e => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.representativeFirstName && touched.representativeFirstName ? true : false}
												errorMessage={errors.representativeFirstName && touched.representativeFirstName ? errors.representativeFirstName : ''}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-6 mt-3">
											<Inputs.TextBox
												name="representativeLastName"
												label="Apellido del representante"
												placeholder="Ingrese apellido del representante..."
												hasLabelFixed={true}
												value={formData.representativeLastName}
												onChange={e => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.representativeLastName && touched.representativeLastName ? true : false}
												errorMessage={errors.representativeLastName && touched.representativeLastName ? errors.representativeLastName : ''}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-6 mt-3">
											<Inputs.TextBox
												type="email"
												name="representativeEmail"
												label="Email del representante"
												placeholder="Ingrese email del representante..."
												hasLabelFixed={true}
												value={formData.representativeEmail}
												onChange={e => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.representativeEmail && touched.representativeEmail ? true : false}
												errorMessage={errors.representativeEmail && touched.representativeEmail ? errors.representativeEmail : ''}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-6 mt-3">
											<Inputs.TextBox
												type="text"
												name="representativeMobile"
												label="Celular del representante"
												placeholder="Ingrese celular del representante..."
												hasLabelFixed={true}
												value={formData.representativeMobile}
												onChange={e => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.representativeMobile && touched.representativeMobile ? true : false}
												errorMessage={errors.representativeMobile && touched.representativeMobile ? errors.representativeMobile : ''}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
									</div>
								</>
							)}
							
							<div className="row">
								<div className="col-sm-12 col-md-6 mt-3">
									<Inputs.ButtonGroup
										variant="outlined"
										name="docType"
										value={formData.personType === 'Natural' ? (formData.docType === 'DNI' ? 'Pasaporte' : 'DNI') : 'NIT'}
										itemsArray={formData.personType === 'Natural' ? ownerDocType1 : ownerDocType2}
										className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined animate__animated animate__fadeIn"
										condition={formData.docType}
										onClick={handleOnChange}
									/>
								</div>
								<div className="col-sm-12 col-md-6 mt-3">
									<Inputs.TextBox
										type="text"
										name="docId"
										label="Documento de identidad"
										placeholder="Ingrese documento de identidad..."
										hasLabelFixed={true}
										value={formData.docId}
										onChange={handleOnChange}
										className="font-family-roboto-medium text-dark fs-6 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mt-3">
									<Inputs.TextBox
										type="email"
										name="email"
										label="Email"
										placeholder="Ingrese email..."
										hasLabelFixed={true}
										value={formData.email}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.email && touched.email ? true : false}
										errorMessage={errors.email && touched.email ? errors.email : ''}
										className="font-family-roboto-medium text-dark fs-6 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mt-3">
									<Inputs.TextBox
										type="text"
										name="mobile"
										label="Celular"
										placeholder="Ingrese celular..."
										hasLabelFixed={true}
										value={formData.mobile}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.mobile && touched.mobile ? true : false}
										errorMessage={errors.mobile && touched.mobile ? errors.mobile : ''}
										className="font-family-roboto-medium text-dark fs-6 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mt-3">
									<Inputs.TextBox
										type="text"
										name="phone"
										label="Teléfono"
										placeholder="Ingrese teléfono..."
										hasLabelFixed={true}
										value={formData.phone}
										onChange={handleOnChange}
										className="font-family-roboto-medium text-dark fs-6 w-100"
									/>
								</div>
							</div>
							
							<div className="row">
								<div className="col-sm-12 col-md-6 mt-3 animate__animated animate__fadeIn">
									<DropdownSelectState
										name="state"
										value={formData.state}
										countryId={1}
										handleChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mt-3 animate__animated animate__fadeIn">
									<DropdownSelectCity
										name="city"
										stateId={formData.state}
										value={formData.city}
										handleChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mt-3">
									<Inputs.TextArea
										name="address"
										label="Dirección"
										placeholder="Ingrese dirección..."
										hasLabelFixed={true}
										value={formData.address}
										onChange={handleOnChange}
										className="font-family-roboto-medium text-dark fs-6 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mt-3">
									<Inputs.TextArea
										name="notes"
										label="Notas"
										placeholder="Ingrese notas..."
										hasLabelFixed={true}
										value={formData.notes}
										onChange={handleOnChange}
										className="font-family-roboto-medium text-dark fs-6 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mt-3 animate__animated animate__fadeIn">
									<DropdownSelectOriginChannel
										name="originChannel"
										value={formData.originChannel}
										handleChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12 col-md-6 mt-3">
									<Inputs.Switch
										label="¿Aceptó habeas data?"
										name="acceptData"
										color="success"
										isChecked={formData.acceptData}
										position="end"
										onChange={handleOnChange}
									/>
								</div>
								{formData.acceptData && (
									<>
										<div className="col-sm-12 col-md-6 mt-2 animate__animated animate__fadeIn">
											<div className="font-family-roboto-light fs-6 text-dark w-100">
												Tipo de cuenta
											</div>
											<Inputs.ButtonGroup
												variant="outlined"
												name="accountType"
												value={formData.accountType === 'Ahorro' ? 'Corriente' : 'Ahorro'}
												itemsArray={ownerAccountType}
												className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
												condition={formData.accountType}
												onClick={handleOnChange}
											/>
										</div>
										<div className="col-sm-12 col-md-6 mt-3 animate__animated animate__fadeIn">
											<DropdownSelectBank
												name="bank"
												value={formData.bank}
												handleChange={handleOnChange}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-6 mt-3 animate__animated animate__fadeIn">
											<Inputs.TextBox
												type="number"
												name="accountNumber"
												label="Número de cuenta"
												placeholder="Ingrese número de cuenta..."
												hasLabelFixed={true}
												value={formData.accountNumber}
												onChange={handleOnChange}
												className="font-family-roboto-medium text-dark fs-6 w-100"
											/>
										</div>
									</>
								)}
							</div>
							
							{(formType === 'store' ? permissionStore : permissionUpdate) && (
								<div className="row">
									<div className="col-sm-12 col-md-12 mt-2">
										<Inputs.LoadingButton
											isDisabled={formType === 'store' ? !(dirty && isValid) : false}
											isLoading={isLoadingForm}
											isLoadingPosition="start"
											startIcon={formType === 'store' ? <Layout.Icons.SaveIcon height={18} width={18} /> : <Layout.Icons.EditIcon height={18} width={18} />}
											label={`${formType === 'store' ? 'Guardar' : 'Actualizar'}`}
											type="submit"
											className={`${formType === 'store' ? ((dirty && isValid) && 'bg-primary') : 'bg-primary'} font-family-roboto-regular fs-6 text-capitalize d-flex float-end`}
										/>
									</div>
								</div>
							)}
						</Form>
					)}
				</Formik>
			)}
		</>
	)
}

export default FormComponent
