import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../../../../components/mui/Layout'
import TableRowComponent from './TableRowComponent'
import { setIsLoading } from '../../../../../redux/actions/loadingAction'
import { Permission } from '../../../../../services/permissions/Permission'
import { propertyAreasColumns as columns, perPages } from '../../../../../helpers/variablesColumns'
import errorServices from '../../../../../services/errors'
import axios from '../../../../../services/axios'

const TableComponent = ({ uID, search, refresh }) => {
	const dispatch = useDispatch()
	const { permissions } = useSelector( state  => state.auth.userData)
	const [isLoading, setLoadingForm] = useState(false)
	const tokenType = useSelector(state => state.auth.tokenType)
	const accessToken = useSelector(state => state.auth.accessToken)
	const [page, setPage] = useState(1)
	const [pages, setPages] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [arrayList, setArraList] = useState([])
	
	useEffect(() => {
	}, [uID, search, refresh])
	
	const handleList = async () => {
		dispatch( setIsLoading(true) )
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				id: uID,
				perPage: perPage,
				page: page,
				search: search === '' ? null : search
			}

			const data = await axios.post(`dashboard/properties/areas/list`, param)

			if (data.status === 200) {
				setPages(data.data.data.pages)
				setArraList(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		dispatch( setIsLoading(false) )
	}
	
	useEffect(() => {
		handleList()
	}, [search, page, perPage, refresh])
	
	const handleChangePerPage = ({ target }) => setPerPage(target.value)
	
	const handleChangePage = (event, value) => setPage(value)
	
	return (
		<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
			<Layout.CardContent className="m-0 p-4">
				<Permission permission="properties.areas.list">
					<Layout.TablePagination
						arrayList={arrayList.length}
						perPage={perPage}
						perPages={perPages}
						page={page}
						pages={pages}
						handleChangePerPage={handleChangePerPage}
						handleChangePage={handleChangePage}
					/>
					<Layout.Table>
						<Layout.TableHead
							columns={columns}
						/>
						<tbody>
						{isLoading && (
							<Layout.TableRowLoading
								columns={columns}
							/>
						)}
						{!isLoading && (
							<>
								{arrayList.length === 0 && (
									<Layout.TableRowNoFound
										colSpan={columns.length}
									/>
								)}
								{arrayList.length > 0 && (
									arrayList.map(({ id, areaName, area_name, floorName, width, long, amenities }, number) => (
										<TableRowComponent
											key={number}
											id={id}
											area={areaName}
											areaName={area_name}
											floorName={floorName}
											width={width}
											long={long}
											amenities={amenities}
											handleList={handleList}
										/>
									))
								)}
							</>
						)}
						</tbody>
					</Layout.Table>
					<Layout.TablePagination
						arrayList={arrayList.length}
						perPage={perPage}
						perPages={perPages}
						page={page}
						pages={pages}
						handleChangePerPage={handleChangePerPage}
						handleChangePage={handleChangePage}
					/>
				</Permission>
			</Layout.CardContent>
		</Layout.Card>
	)
}

TableComponent.propTypes = {
	uID: PropTypes.string.isRequired,
	search: PropTypes.string.isRequired
}

export default TableComponent
