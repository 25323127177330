import React from 'react'
import PropTypes from 'prop-types'
import MuiCardContent from '@mui/material/CardContent'

const CardContent = ({ children, ...more }) => {
	
	return (
		<MuiCardContent {...more}>
			{children}
		</MuiCardContent>
	)
}

CardContent.propTypes = {
	children: PropTypes.any.isRequired
}

export default CardContent
