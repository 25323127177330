import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { history } from '../../../../redux/store'
import Layout from '../../../../components/mui/Layout'
import Inputs from '../../../../components/mui/Inputs'
import SearchComponent from '../components/SearchComponent'
import { setIsLoading } from '../../../../redux/actions/loadingAction'
import { actionsSwitch } from '../../../../helpers/variablesColumns'
import errorServices from '../../../../services/errors'
import { swalSuccess } from '../../../../services/sweetalert'
import axios from '../../../../services/axios'

const PropertyServiceOldPage = () => {
	const params = useParams()
	const uID = params.uID
	const dispatch = useDispatch()
	const tokenType = useSelector(state => state.auth.tokenType)
	const accessToken = useSelector(state => state.auth.accessToken)
	const [isLoading, setLoadingForm] = useState(false)
	const [showEnergy, setShowEnergy] = useState(true)
	const [showGas, setShowGas] = useState(true)
	const [showWater, setShowWater] = useState(true)
	const [showDescription, setShowDescription] = useState(false)
	const [formData, setFormData] = useState({
		electric_power: "Si",
		electric_billing: '',
		electric_number_reference: '',
		water: "Si",
		water_billing: '',
		water_number_reference: '',
		gas: "Si",
		gas_billing: '',
		gas_number_reference: '',
		phone: "No",
		service_share: "No",
		service_share_descripcion: ""
	})
	const [module, setModule] = useState({
		step: 10,
		title: 'Servicios'
	})
	
	const handleEditProperty = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/${uID}`)
			
			if (data.status === 200) {
				if (data.data.data.has_building === 'Si') {
					setModule({...module, step: 7 })
				}
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
		handleEditProperty()
	}, [])
	
	const handleEditPropertyCondition = async () => {
		dispatch( setIsLoading(true) )
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/services/${uID}`)
			
			if (data.status === 200) {
				const response = data.data.data
				setFormData({
					electric_power: response.electric_power === 'Si' ? 'Si' : 'No',
					electric_billing: response.electric_power === 'Si' ? (response.electric_billing === null ? '' : response.electric_billing) : '',
					electric_number_reference: response.electric_power === 'Si' ? (response.electric_number_reference === null ? '' : response.electric_number_reference) : '',
					water: response.water === 'Si' ? 'Si' : 'No',
					water_billing: response.water === 'Si' ? (response.water_billing === null ? '' : response.water_billing) : '',
					water_number_reference: response.water === 'Si' ? (response.water_number_reference === null ? '' : response.water_number_reference) : '',
					gas: response.gas === 'Si' ? 'Si' : 'No',
					gas_billing: response.gas === 'Si' ? (response.gas_billing === null ? '' : response.gas_billing) : '',
					gas_number_reference: response.gas === 'Si' ? (response.gas_number_reference === null ? '' : response.gas_number_reference) : '',
					phone: response.water === 'Si' ? 'Si' : 'No',
					service_share: response.service_share === 'Si' ? 'Si' : 'No',
					service_share_descripcion: response.service_share === 'Si' ? (response.service_share_descripcion === null ? '' : response.service_share_descripcion) : '',
				})
				setShowEnergy(response.electric_power === 'Si' ? true : false)
				setShowWater(response.water === 'Si' ? true : false)
				setShowGas(response.gas === 'Si' ? true : false)
				setShowDescription(response.service_share === 'Si' ? true : false)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		dispatch( setIsLoading(false) )
	}
	
	useEffect(() => {
		handleEditPropertyCondition()
	}, [])
	
	const hanbleSubmit = async () => {
		setLoadingForm(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				id: uID,
				electric_power: formData.electric_power === 'Si' ? 'Si' : 'No',
				electric_billing: formData.electric_power === 'Si' ? (formData.electric_billing === '' ? null : formData.electric_billing) : null,
				electric_number_reference: formData.electric_power === 'Si' ? (formData.electric_number_reference === '' ? null : formData.electric_number_reference) : null,
				water: formData.water === 'Si' ? 'Si' : 'No',
				water_billing: formData.water === 'Si' ? (formData.water_billing === '' ? null : formData.water_billing) : null,
				water_number_reference: formData.water === 'Si' ? (formData.water_number_reference === '' ? null : formData.water_number_reference) : null,
				gas: formData.gas === 'Si' ? 'Si' : 'No',
				gas_billing: formData.gas === 'Si' ? (formData.gas_billing === '' ? null : formData.gas_billing) : null,
				gas_number_reference: formData.gas === 'Si' ? (formData.gas_number_reference === '' ? null : formData.gas_number_reference) : null,
				phone: formData.phone === 'Si' ? 'Si' : 'No',
				service_share: formData.service_share === 'Si' ? 'Si' : 'No',
				service_share_descripcion: formData.service_share === 'Si' ? (formData.service_share_descripcion === '' ? null : formData.service_share_descripcion) : null,
			}
			
			const data = await axios.post(`dashboard/properties/services`, param)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				// await history.push(`/dashboard/propiedades/archivos/${uID}`)
				history.push(`/dashboard/propiedades/estatus-comercial/${uID}`)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoadingForm(false)
	}
	
	const handleOnChange = ({ target }) => {
		setFormData({...formData, [target.name]: target.value})
		
		if (target.name === 'electric_power') {
			setShowEnergy(target.value === 'Si' ? true : false)
		} else if (target.name === 'water') {
			setShowWater(target.value === 'Si' ? true : false)
		} else if (target.name === 'gas') {
			setShowGas(target.value === 'Si' ? true : false)
		} else if (target.name === 'service_share') {
			setShowDescription(target.value === 'Si' ? true : false)
		}
	}
	
	return (
		<Layout.Page title="Propiedades - Condiciones">
			<SearchComponent
				module={module}
				back={`/dashboard/propiedades/condiciones/${uID}`}
				next={`/dashboard/propiedades/estatus-comercial/${uID}`}
			/>
			
			<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
				<Layout.CardContent className="m-0 p-4">
					<div className="row">
						<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
							<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
								¿Energía eléctrica?
							</div>
							<Inputs.ButtonGroup
								variant="outlined"
								size="small"
								name="electric_power"
								value={formData.electric_power === 'Si' ? 'No' : 'Si'}
								itemsArray={actionsSwitch}
								className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
								condition={formData.electric_power}
								onClick={handleOnChange}
							/>
						</div>
					</div>
					{showEnergy && (
						<div className="row animate__animated animate__fadeIn">
							<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="electric_billing"
									label="Facturación Promedio"
									placeholder="Ingrese facturación promedio..."
									hasLabelFixed={true}
									value={formData.electric_billing}
									onChange={handleOnChange}
									className="font-family-roboto-medium text-dark fs-6 w-100 top-3"
								/>
							</div>
							<div className="col-sm-12 col-md-6 col-lg-4">
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="electric_number_reference"
									label="Número de Referencia"
									placeholder="Ingrese número de referencia..."
									hasLabelFixed={true}
									value={formData.electric_number_reference}
									onChange={handleOnChange}
									className="font-family-roboto-medium text-dark fs-6 w-100 top-3"
								/>
							</div>
						</div>
					)}
					<div className="row">
						<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
							<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
								¿Agua?
							</div>
							<Inputs.ButtonGroup
								variant="outlined"
								size="small"
								name="water"
								value={formData.water === 'Si' ? 'No' : 'Si'}
								itemsArray={actionsSwitch}
								className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
								condition={formData.water}
								onClick={handleOnChange}
							/>
						</div>
					</div>
					{showWater && (
						<div className="row animate__animated animate__fadeIn">
							<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="water_billing"
									label="Facturación Promedio"
									placeholder="Ingrese facturación promedio..."
									hasLabelFixed={true}
									value={formData.water_billing}
									onChange={handleOnChange}
									className="font-family-roboto-medium text-dark fs-6 w-100 top-3"
								/>
							</div>
							<div className="col-sm-12 col-md-6 col-lg-4">
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="water_number_reference"
									label="Número de Referencia"
									placeholder="Ingrese número de referencia..."
									hasLabelFixed={true}
									value={formData.water_number_reference}
									onChange={handleOnChange}
									className="font-family-roboto-medium text-dark fs-6 w-100 top-3"
								/>
							</div>
						</div>
					)}
					<div className="row">
						<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
							<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
								¿Gas?
							</div>
							<Inputs.ButtonGroup
								variant="outlined"
								size="small"
								name="gas"
								value={formData.gas === 'Si' ? 'No' : 'Si'}
								itemsArray={actionsSwitch}
								className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
								condition={formData.gas}
								onClick={handleOnChange}
							/>
						</div>
					</div>
					{showGas && (
						<div className="row animate__animated animate__fadeIn animate__fade">
							<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="gas_billing"
									label="Facturación Promedio"
									placeholder="Ingrese facturación promedio..."
									hasLabelFixed={true}
									value={formData.gas_billing}
									onChange={handleOnChange}
									className="font-family-roboto-medium text-dark fs-6 w-100 top-3"
								/>
							</div>
							<div className="col-sm-12 col-md-6 col-lg-4">
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="gas_number_reference"
									label="Número de Referencia"
									placeholder="Ingrese número de referencia..."
									hasLabelFixed={true}
									value={formData.gas_number_reference}
									onChange={handleOnChange}
									className="font-family-roboto-medium text-dark fs-6 w-100 top-3"
								/>
							</div>
						</div>
					)}
					<div className="row">
						<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
							<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
								¿Teléfono?
							</div>
							<Inputs.ButtonGroup
								variant="outlined"
								size="small"
								name="phone"
								value={formData.phone === 'Si' ? 'No' : 'Si'}
								itemsArray={actionsSwitch}
								className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
								condition={formData.phone}
								onClick={handleOnChange}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
							<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
								¿Algunos de los servicios son compartidos?
							</div>
							<Inputs.ButtonGroup
								variant="outlined"
								size="small"
								name="service_share"
								value={formData.service_share === 'Si' ? 'No' : 'Si'}
								itemsArray={actionsSwitch}
								className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
								condition={formData.service_share}
								onClick={handleOnChange}
							/>
						</div>
					</div>
					{showDescription && (
						<div className="row animate__animated animate__fadeIn animate__fade">
							<div className="col-sm-12 col-md-6 col-lg-4 mb-3">
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="service_share_descripcion"
									label="Por favor describa el servicio y las condiciones"
									placeholder="Ingrese por favor condiciones..."
									hasLabelFixed={true}
									value={formData.service_share_descripcion}
									onChange={handleOnChange}
									className="font-family-roboto-medium text-dark fs-6 w-100 top-3"
								/>
							</div>
						</div>
					)}
					<div className="row">
						<div className="col-sm-12">
							<Inputs.LoadingButton
								variant="outlined"
								isLoading={isLoading}
								isLoadingPosition="start"
								startIcon={<Layout.Icons.SaveIcon height={18} width={18}/>}
								label="Guardar"
								type="button"
								onClick={hanbleSubmit}
								className="bg-primary font-family-roboto-medium fs-6 text-inherit"
							/>
						</div>
					</div>
				</Layout.CardContent>
			</Layout.Card>
		</Layout.Page>
	)
}

export default PropertyServiceOldPage
