import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { history } from '../../../../redux/store';
import Inputs from '../../../../components/mui/Inputs';
import Layout from '../../../../components/mui/Layout';
import { setPasswordReset } from '../../services';
import { setIsLoadingForm, setIsActiveButton, setColorStatus, setTextStatus, setIsActiveAlert, setIsActiveForm } from '../../redux/actions/passwordResetAction';
import { swalSuccess } from '../../../../hooks/useSweetAlert';
import swalErrors from '../../../../hooks/useErrors';
import { initialValuesPasswordReset as initialValues } from '../../../../helpers/variablesInitialValues';

const FormComponent = ({ uID }) => {
	const dispatch = useDispatch();
	const isLoadingForm = useSelector( ({ auth }) => auth.isLoadingForm);
	
	useEffect(() => {
	}, [uID]);
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			password: Yup.string()
			.min(6, 'La contraseña debe contener al menos 6 caracteres')
			.required('La contraseña es requerida'),
			passwordConfirm: Yup.string()
			.min(6, 'La confirmación de la contraseña debe contener al menos 6 caracteres')
			.required('La confirmación de la contraseña es requerida')
			.oneOf([Yup.ref('password'), null], 'La confirmación debe ser igual a la contraseña')
		})
	);
	
	/**
	 *
	 * @description Execute submit of the form
	 * @param password
	 * @param passwordConfirm
	 * @param resetForm
	 * @param setValues
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async ({ password, passwordConfirm }, { resetForm, setValues }) => {
		await dispatch( setIsLoadingForm(true) );
		
		try {
			const param = {
				password: password,
				password_confirm: passwordConfirm
			}
			
			const { data } = await setPasswordReset(uID, param);
			
			if (data.status === 200) {
				resetForm({ values: initialValues});
				setValues(initialValues);
				await swalSuccess('Cambio de contraseña exitoso', data.data);
				await dispatch( setColorStatus('') );
				await dispatch( setTextStatus('') );
				await dispatch( setIsActiveAlert(false) );
				await dispatch( setIsActiveButton(false) );
				await dispatch( setIsActiveForm(false) );
				await history.push('/');
			}
		} catch (error) {
			await swalErrors(error);
		}
		
		await dispatch( setIsLoadingForm(false) );
	};
	
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
			  values,
			  errors,
			  touched,
			  handleChange,
			  handleBlur,
			  isValid,
			  dirty
		  }) => (
				<Form noValidate>
					<div className="row">
						<div className="col-sm-12 mb-3">
							<Inputs.TextBoxPassword
								name="password"
								label="Contraseña"
								placeholder="Ingrese contraseña..."
								value={values.password}
								onBlur={handleBlur}
								onChange={handleChange}
								hasError={errors.password && touched.password ? true : false}
								errorMessage={errors.password && touched.password ? errors.password : ''}
								className="font-family-roboto-medium fs-6 text-dark w-100 mt-3"
							/>
						</div>
						<div className="col-sm-12 mb-3">
							<Inputs.TextBoxPassword
								name="passwordConfirm"
								label="Confirmar contraseña"
								placeholder="Ingrese confirmación..."
								value={values.passwordConfirm}
								onBlur={handleBlur}
								onChange={handleChange}
								hasError={errors.passwordConfirm && touched.passwordConfirm ? true : false}
								errorMessage={errors.passwordConfirm && touched.passwordConfirm ? errors.passwordConfirm : ''}
								className="font-family-roboto-medium fs-6 text-dark w-100 mt-3"
							/>
						</div>
						<div className="col-sm-12">
							<Inputs.LoadingButton
								isDisabled={!(dirty && isValid)}
								isLoading={isLoadingForm}
								isLoadingPosition="start"
								startIcon={<Layout.Icons.LockOpenIcon height={18} width={18} />}
								label="Resetear contraseña"
								type="submit"
								className={`${(dirty && isValid) ? 'bg-primary' : 'bg-grey-light'} font-family-roboto-regular fs-6 text-capitalize w-100`}
							/>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

FormComponent.propTypes = {
	uID: PropTypes.string.isRequired
};

export default FormComponent;
