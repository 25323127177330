import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Layout from '../mui/Layout'
import Feedback from '../mui/Feedback'

const TableActivityLogRow = ({ createdDate, createdAt, logName, description, activity, properties, userName }) => {
	const [before, setBefore] = useState([])
	const [after, setAfter] = useState([])
	
	useEffect(() => {
	}, [
		createdDate,
		createdAt,
		logName,
		description,
		activity,
		properties,
		userName
	])
	
	const handleProperties = async () => {
		const arrayBefore = []
		const arrayAfter = []
		
		if (properties?.old){
			const beforeKeys = Object.keys(properties.old)
			const beforeValues = Object.values(properties.old)
			
			beforeKeys.map((item, index) => {
				arrayBefore.push(`${[item]}: ${beforeValues[index]}`)
			})
		}
		
		if (properties?.attributes){
			const afterKeys = Object.keys(properties.attributes)
			const afterValues = Object.values(properties.attributes)
			
			afterKeys.map((item, index) => {
				arrayAfter.push(`${[item]}: ${afterValues[index]}`)
			})
		}
		
		setBefore(arrayBefore)
		setAfter(arrayAfter)
	}
	
	useEffect(() => {
		handleProperties()
	}, [description])
	
	return (
		<tr className="m-0 p-0 h-30px">
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2 text-center"
				width="20%"
			>
				<div className="font-family-roboto-regular fs-7 text-dark">
					<Layout.Timezone
						date={createdDate}
						dateAt={createdAt}
					/>
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="25%"
			>
				<div className="font-family-roboto-regular fs-7 text-dark">
					{description}
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2 text-center"
				width="10%"
			>
				<div className="font-family-roboto-regular fs-7 text-dark">
					{activity}
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2 max-h-120px"
				width="20%"
			>
				<Feedback.ScrollBar
					style={{ maxHeight: '120px', minHeight: '30px' }}
				>
					<div className="font-family-roboto-regular fs-7 text-dark">
						{before.length > 0 && (
							<div className="w-100">
								<strong>Anterior: </strong>
								<ul className="list-group border-0 bg-transparent">
									{before.map((item, index) => (
										<li key={index} className="border-0 d-flex text-start">{item}</li>
									))}
								</ul>
							</div>
						)}
						{after.length > 0 && (
							<div className="w-100">
								<strong>Actual: </strong>
								<ul className="list-group border-0 bg-transparent">
									{after.map((item, index) => (
										<li key={index} className="border-0 d-flex text-start">{item}</li>
									))}
								</ul>
							</div>
						)}
					</div>
				</Feedback.ScrollBar>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2 text-center"
				width="25%"
			>
				<div className="font-family-roboto-regular fs-7 text-dark">
					{(userName === null && activity === 'Autorización') ? 'Propietario' : userName}
				</div>
			</td>
		</tr>
	)
}

TableActivityLogRow.propTypes = {
	createdDate: PropTypes.string.isRequired,
	createdAt: PropTypes.number.isRequired,
	logName: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	properties: PropTypes.any,
	userName: PropTypes.string
}

export default TableActivityLogRow
