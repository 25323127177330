import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../../../../components/mui/Layout'
import Feedback from '../../../../../components/mui/Feedback'
import KanbanRowComponent from './KanbanRowComponent'
import { getArrayListDiscarded, setPageDiscarded } from '../../../../../redux/actions/dashboard/filtered/filteredAction'

const KanbanDiscardedComponent = ({ isActiveSearch, realState, search, start, finish, agent, adviser }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.filtered.isLoadingDiscarded)
  const perPage = useSelector(state => state.filtered.perPageDiscarded)
  const page = useSelector(state => state.filtered.pageDiscarded)
  const hasNextPage = useSelector(state => state.filtered.hasNextPageDiscarded,)
  const arrayList = useSelector(state => state.filtered.arrayListDiscarded)
  
  /**
   *
   * @description Execute endpoint array list by status desechado of filtered
   * @return dispatch
   *
   */
  const handleArrayList = async (isPagination = false) => {
    const param = {
      realstate: realState,
      perPage: perPage,
      page: page,
      search: search === '' ? null : search,
      start: start === '' ? null : start,
      finish: finish === '' ? null : finish,
      agent: agent === '' ? null : agent,
      adviser: adviser === '' ? null : adviser,
      status: 'Desechado'
    }
    
    if (isPagination) {
      await dispatch( getArrayListDiscarded(param, arrayList, isPagination) )
    } else {
      await dispatch( getArrayListDiscarded(param) )
    }
  }

  useEffect(() => {
    handleArrayList(page === 1 ? false : true)
  }, [
    realState,
    agent,
    adviser,
    isActiveSearch,
    page
  ])
  
  /**
   *
   * @description Execute dispatch next page state
   * @return dispatch
   *
   */
  const handleNextPage = () => dispatch( setPageDiscarded(page + 1) )

  return (
    <Layout.Box
      id="scrollDiscarded"
      width="100%"
      margin="0 auto"
      overflow="scroll"
      className="card-kanban"
    >
      <Feedback.InfinityScrolling
        dataLength={arrayList.length}
        next={handleNextPage}
        hasMore={hasNextPage}
        loader={isLoading && <Layout.KanbanRowLoading />}
        endMessage={<div className="mt-4 mb-4" />}
        scrollableTarget="scrollDiscarded"
      >
        {!isLoading && arrayList.length === 0 && (
          <div className="font-family-roboto-regular fs-6 text-center">No hay datos para esta consulta</div>
        )}
        {!isLoading && arrayList.length > 0 && (
          arrayList.map((filtered, index) => (
            <KanbanRowComponent
              key={index}
              filtered={filtered}
              status="Desechado"
            />
          ))
        )}
      </Feedback.InfinityScrolling>
    </Layout.Box>
  )
}

const ScrollContentStyle = {
  gap: 2,
  display: "flex",
  overflow: "scroll",
  flexDirection: "column",
}

KanbanDiscardedComponent.propTypes = {
  isActiveSearch: PropTypes.bool.isRequired,
  realState: PropTypes.any,
  search: PropTypes.string,
  start: PropTypes.string,
  finish: PropTypes.string,
  agent: PropTypes.any,
  adviser: PropTypes.any,
}

export default KanbanDiscardedComponent
