import React from 'react'
import PropTypes from 'prop-types'
import Inputs from '../Inputs'
import Layout from '../Layout'

const TablePagination = ({ arrayList, perPage, perPages, page, pages, handleChangePerPage, handleChangePage }) => {
	return (
		<>
			{arrayList > 0 && (
				<div className="row">
					<div className="col-sm-12 col-md-3 mb-1 d-flex justify-content-start">
						<Inputs.Select
							size="small"
							label="Paginar"
							name="per_page"
							value={perPage}
							itemsArray={perPages}
							onChange={handleChangePerPage}
							className="w-100px"
						/>
					</div>
					<div className="col-sm-12 col-md-9 mt-1 mb-1 d-flex justify-content-end">
						<Layout.Pagination
							page={page}
							count={pages}
							onChange={handleChangePage}
						/>
					</div>
				</div>
			)}
		</>
	)
}

TablePagination.propTypes = {
	arrayList: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	perPages: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pages: PropTypes.number.isRequired,
	handleChangePerPage: PropTypes.func.isRequired,
	handleChangePage: PropTypes.func.isRequired
}

export default TablePagination
