import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../../../../components/mui/Layout'
import VerticalCardsLoading from '../../../../../components/properties/VerticalCardsLoading'
import SquarePropertyCard from '../../../../../components/properties/cards/Properties'
import { getArrayListFavorites, deleteFavoriteById } from '../../../../../redux/actions/dashboard/filtered/filteredAction'
import { getArrayListFavorites as getArrayListFavoritesAdviser } from '../../../../../redux/actions/filtered/adviserForm/filteredAdviserAction'
import { history } from '../../../../../redux/store'

const PropertiesFavoritesComponent = ({ isAuthenticated, isAuthenticatedPage, uID }) => {
  const dispatch = useDispatch()
  const isSelectedToggleSwitch = useSelector(state => state.filtered.isSelectedToggleSwitch)
  const isLoading = useSelector(state => isAuthenticatedPage ? state.filtered.isLoadingFavorites : state.filteredAdviser.isLoadingFavorites)
  const arrayList = useSelector(state => isAuthenticatedPage ? state.filtered.arrayListFavorites : state.filteredAdviser.arrayListFavorites)
  
  /**
   *
   * @description Execute endpoint array list of filtered favorites
   * @return dispatch
   *
   */
  const handleArrayList = async () => {
    if (isAuthenticatedPage) {
      await dispatch( getArrayListFavorites(uID) )
    } else {
      await dispatch( getArrayListFavoritesAdviser(uID) )
    }
  }

  useEffect(() => {
    if (isSelectedToggleSwitch === 1) {
      handleArrayList()
    }
  }, [uID, isSelectedToggleSwitch])
  
  /**
   *
   * @description Execute endpoint delete of filtered favorite
   * @return dispatch
   *
   */
  const handleDeleteLike = async (id) => {
    if (isAuthenticated) {
      await dispatch( deleteFavoriteById(id) )
      await handleArrayList()
    }
  }

  return (
    <>
      {isSelectedToggleSwitch === 1 && (
        <>
          {isLoading && <VerticalCardsLoading />}
          {!isLoading && (
            <Layout.Box
              gap={2}
              width="95%"
              display="grid"
              margin="0 auto"
              overflow="scroll"
              gridTemplateColumns="repeat(auto-fill,minmax(300px,1fr))"
              className="mt-2 animate__animated animate__fadeIn"
            >
              {arrayList?.length > 0 && (
                arrayList.map((item, index) => {
                  // VALIDATE THE PRICE ACCORDING TO THE SERVICE
                  //let price =
                  //  item?.action_type === 'Arriendo'
                  //    ? item?.action_canon_string
                  //    : item?.action_type === 'Compra o Venta'
                  //    ? item?.action_sale_string
                  //    : item?.action_type === 'Venta'
                  //    ? item?.action_sale_string
                  //    : item?.action_type === 'Arriendo/Venta'
                  //    ? `${item?.action_canon_string} / ${item?.action_sale_string}`
                  //    : ''

                      //if(index === 0) console.log("Favorites",{item})

                  return (
                    <SquarePropertyCard
                      key={index}
                      goToDetail={() => history.push(isAuthenticatedPage ? `/dashboard/propiedades/detalle/${item?.property_id}` : `/properties/detail/${item?.id}`)}
                      property={item
                      //   {
                      //   type: `${item?.type} en ${item?.action_type}`,
                      //   price_format: price,
                      //   codpro: item?.domus_code,
                      //   images_count: item?.has_photo,
                      //   image1: item?.path,
                      //   neighborhood: item?.neighborhood,
                      //   city: item?.city,
                      //   area_lot: item?.area_total,
                      //   bathrooms: item?.number_bathroom,
                      //   bedrooms: item?.number_room,
                      //   parking: item?.number_garage,
                      // }
                      }
                      handleStoreLike={() => handleDeleteLike(item?.filter_favorite_id)}
                      isActiveLike={true}
                    />
                  )
                })
              )}
            </Layout.Box>
          )}
        </>
      )}
    </>
  )
}

PropertiesFavoritesComponent.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isAuthenticatedPage: PropTypes.bool.isRequired,
  uID: PropTypes.string.isRequired
}

export default PropertiesFavoritesComponent
