import React from 'react'
import ExpandLess from '@material-ui/icons/ExpandLess'

export default function ExpandLessIcon(props) {

    const { ...other } = props

    return (
        <ExpandLess { ...other }  />
    )
}
