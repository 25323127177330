import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DropdownActionComponent from './DropdownActionComponent';
import { setIsOpenModal, setIsOpenModalPermission, setFormData, setFormType, setIsLoadingShow, setIsLoadingForm } from '../redux/actions/roleAction';
import { setActivityLogReset, setIsOpenModal as setIsOpenModalActivity, setLogName, setSubjectId } from '../../../../../redux/actions/dashboard/activities/activityLogAction';

const TableRowComponent = ({ id, name, permissions = [] }) => {
	const dispatch = useDispatch();
	
	useEffect(() => {
	}, [id, name, permissions]);
	
	/**
	 *
	 * @description Execute dispatch open modal, reset formData, loading show, loading form, formType and activityLog state
	 * @return dispatch
	 *
	 */
	const handleIsOpenModal = async (formType) => {
		if (formType === 'activity-log') {
			await dispatch( setActivityLogReset() )
			await dispatch( setLogName('Roles') )
			await dispatch( setSubjectId(id) )
			await dispatch( setIsOpenModalActivity(true) )
		} else {
			const object = {
				id: id,
				name: name,
				permissions: permissions.length === 0 ? [] : permissions,
			};
			
			await dispatch( setFormData(object) );
			await dispatch( setFormType(formType) );
			await dispatch( setIsLoadingForm(false) );
			
			if (formType === 'show' || formType === 'delete') {
				await dispatch( setIsLoadingShow(false) );
				await dispatch( setIsOpenModal(true) );
			}
			
			if (formType === 'permission') {
				await dispatch( setIsOpenModalPermission(true) );
			}
		}
	};
	
	return (
		<tr className="m-0 p-0">
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="95%"
			>
				<div className="font-family-roboto-regular fs-6 text-dark">
					{ name }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="5%"
			>
				<div className="mx-auto d-flex justify-content-center">
					<DropdownActionComponent handleIsOpenModal={handleIsOpenModal} />
				</div>
			</td>
		</tr>
	);
};

TableRowComponent.propTypes = {
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	permissions: PropTypes.array,
};

export default TableRowComponent;
