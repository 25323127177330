import { createTheme } from '@mui/material/styles'
import { MobiliBranColors } from './theme'
import colors from '../assets/scss/colors.scss'

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
  /**
   * @type {MobiliBranColors}
   */
  const color = colors
  const flatObjectColors = {}

  // * in case we need the map, just assign to a var
  Object.keys(color).map((key) => {
    if (key === 'primary') return {}
    flatObjectColors[key] = {
      default: color[key],
    }
    return {
      [key]: {
        default: color[key],
      },
    }
  })
  // console.log(flatObjectColors);
  const themes = createTheme({
    palette: {
      ...flatObjectColors,
      primary: {
        main: color.primary,
        dark: color.dark,
        contrastText: color.white,
        light: color.skyBlue,
      },
      background: {
        default: color.lightestGrey,
        paper: color.white,
      },
      text: {
        primary: color.dark,
        secondary: color.darkGrey,
        disabled: color.lightGrey,
        grey: color.mobiligrey,
      },
      secondary: {
        main: color.darkGrey,
        dark: color.dark,
        contrastText: color.dark,
        light: color.lightGrey,
      },
      // just works for contained variant
      buttonGreyContained: {
        main: color.lightGrey, // default contained, outlined all
        dark: color.mediumGrey, // hover contained
        contrastText: color.darkGrey,
        light: color.lightGrey,
      },
      customization,
    },
    typography: {
      h1: {
        lineHeight: '1',
        fontWeight: '800',
        fontSize: '3.125rem', //50px
        letterSpacing: '-0.022em',
      },
      h2: {
        lineHeight: '1',
        fontWeight: '800',
        fontSize: '2.31rem', //37px
        letterSpacing: 'normal',
      },
      h3: {
        lineHeight: '1',
        fontWeight: '800',
        fontSize: '1.75rem', //28px
        letterSpacing: 'normal',
      },
      h4: {
        lineHeight: '1',
        fontWeight: '800',
        fontSize: '1.31rem', //21px
        letterSpacing: 'normal',
      },
      h5: {
        lineHeight: '1',
        fontWeight: 'bold',
        fontSize: '1.125rem',
        letterSpacing: 'normal',
      },
      h6: {
        lineHeight: '1',
        fontWeight: 'bold',
        fontSize: '1rem',
        letterSpacing: 'normal',
      },
      span: {
        lineHeight: '1',
        fontWeight: '400',
        fontSize: '1rem',
        letterSpacing: 'normal',
      },
      fontFamily: [
        // "proxima-nova",
        // "DM Sans",
        'Roboto',
        '-apple-system',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  })

  return themes
}
