import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import authReducer from '../pages/auth/redux/reducers/authReducer';
import verificationReducer from '../pages/auth/redux/reducers/verificationReducer';
import forgotPasswordReducer from '../pages/auth/redux/reducers/forgotPasswordReducer';
import passwordResetReducer from '../pages/auth/redux/reducers/passwordResetReducer';
import loadingReducer from './loading/reducers/loadingReducer';
import algoliaPropertyReducer from './reducers/algolia/algoliaPropertyReducer';
import algoliaCityReducer from './reducers/algolia/algoliaCityReducer';
import algoliaNeighborhoodReducer from './reducers/algolia/algoliaNeighborhoodReducer';
import algoliaPropertyTypeReducer from './reducers/algolia/algoliaPropertyTypeReducer.jsx';
import propertyDetailNoReducer from './reducers/properties/propertyDetailReducer';
import ownerAuthorizationReducer from './reducers/owners/ownerAuthorizationReducer';
import ownerInventoryReducer from './reducers/owners/ownerInventoryReducer';
import filteredAdviser from './reducers/filtered/filteredAdviser';
import sidebarReducer from './sidebar/reducers/sidebarReducer';
import activityLogReducer from './reducers/dashboard/activityLogReducer';
import permissionReducer from '../pages/dashboard/settings/PermissionPage/redux/reducers/permissionReducer';
import roleReducer from '../pages/dashboard/settings/RolePage/redux/reducers/roleReducer';
import realStateReducer from '../pages/dashboard/settings/RealStatePage/redux/reducers/realStateReducer';
import userReducer from '../pages/dashboard/settings/UserPage/redux/reducers/userReducer';
import profileReducer from '../pages/dashboard/settings/UserPage/redux/reducers/profileReducer';
import bankReducer from '../pages/dashboard/settings/BankPage/redux/reducers/bankReducer';
import areaReducer from '../pages/dashboard/settings/AreaPage/redux/reducers/areaReducer';
import amenityReducer from '../pages/dashboard/settings/AmenityPage/redux/reducers/amenityReducer';
import projectReducer from '../pages/dashboard/settings/ProjectPage/redux/reducers/projectReducer';
import keyReducer from '../pages/dashboard/settings/KeyPage/redux/reducers/keyReducer';
import countryReducer from '../pages/dashboard/settings/location/CountryPage/redux/reducers/countryReducer';
import stateReducer from '../pages/dashboard/settings/location/StatePage/redux/reducers/stateReducer';
import cityReducer from '../pages/dashboard/settings/location/CityPage/redux/reducers/cityReducer';
import neighborhoodReducer from '../pages/dashboard/settings/location/NeighborhoodPage/redux/reducers/neighborhoodReducer';
import localityReducer from '../pages/dashboard/settings/location/LocalityPage/redux/reducers/localityReducer';
import originChannelReducer from '../pages/dashboard/settings/filtered/OriginChannelPage/redux/reducers/originChannelReducer';
import contactReducer from '../pages/dashboard/settings/filtered/ContactPage/redux/reducers/contactReducer';
import commercialStatusReducer from '../pages/dashboard/settings/commercial/CommercialStatusPage/redux/reducers/commercialStatusReducer';
import buildingTypeReducer from '../pages/dashboard/settings/buildings/BuildingTypePage/redux/reducers/buildingTypeReducer';
import ownerDomusReducer from './reducers/dashboard/settings/owners/ownerDomusReducer';
import ownerMobiliReducer from './reducers/dashboard/settings/owners/ownerMobiliReducer';
import propertyDomusReducer from './reducers/dashboard/settings/properties/propertyDomusReducer';
import propertyMobiliReducer from './reducers/dashboard/settings/properties/propertyMobiliReducer';
import propertyDestinationReducer from '../pages/dashboard/settings/properties/PropertyDestinationPage/redux/reducers/propertyDestinationReducer';
import propertyFacadeReducer from '../pages/dashboard/settings/properties/PropertyFacadePage/redux/reducers/propertyFacadeReducer';
import propertyFloorReducer from '../pages/dashboard/settings/properties/PropertyFloorPage/redux/reducers/propertyFloorReducer';
import propertyReserveReducer from '../pages/dashboard/settings/properties/PropertyReservePage/redux/reducers/propertyReserveReducer';
import propertyRoomServiceReducer from '../pages/dashboard/settings/properties/PropertyRoomServicePage/redux/reducers/propertyRoomServiceReducer';
import propertyStatusReducer from '../pages/dashboard/settings/properties/PropertyStatusPage/redux/reducers/propertyStatusReducer';
import propertyStatusCommercialReducer from '../pages/dashboard/settings/properties/PropertyStatusCommercialPage/redux/reducers/propertyStatusCommercialReducer';
import propertyTypeReducer from '../pages/dashboard/settings/properties/PropertyTypePage/redux/reducers/propertyTypeReducer';
import filteredReducer from './reducers/dashboard/filteredReducer';
import commercialReducer from '../pages/dashboard/CommercialPage/redux/reducers/commercialReducer';
import ownerReducer from '../pages/dashboard/OwnerPage/redux/reducers/ownerReducer';
import buildingReducer from '../pages/dashboard/BuildingPage/redux/reducers/buildingReducer';
import propertyReducer from './reducers/dashboard/propertyReducer';
import propertyDetailReducer from './reducers/dashboard/propertyDetailReducer';

const createReducer = asyncReducers => (
	combineReducers({
		auth: authReducer,
		verification: verificationReducer,
		forgotPassword: forgotPasswordReducer,
		passwordReset: passwordResetReducer,
		loading: loadingReducer,
		algoliaProperty: algoliaPropertyReducer,
		algoliaCity: algoliaCityReducer,
		algoliaNeighborhood: algoliaNeighborhoodReducer,
		algoliaPropertyType: algoliaPropertyTypeReducer,
		propertyDetailNoAuth: propertyDetailNoReducer,
		ownerAuthorization: ownerAuthorizationReducer,
		ownerInventory: ownerInventoryReducer,
		filteredAdviser: filteredAdviser,
		activityLog: activityLogReducer,
		sidebar: sidebarReducer,
		permission: permissionReducer,
		role: roleReducer,
		realState: realStateReducer,
		user: userReducer,
		profile: profileReducer,
		bank: bankReducer,
		area: areaReducer,
		amenity: amenityReducer,
		project: projectReducer,
		key: keyReducer,
		country: countryReducer,
		state: stateReducer,
		city: cityReducer,
		neighborhood: neighborhoodReducer,
		locality: localityReducer,
		originChannel: originChannelReducer,
		contact: contactReducer,
		commercialStatus: commercialStatusReducer,
		buildingType: buildingTypeReducer,
		ownerDomus: ownerDomusReducer,
		ownerMobili: ownerMobiliReducer,
		propertyDomus: propertyDomusReducer,
		propertyMobili: propertyMobiliReducer,
		propertyDestination: propertyDestinationReducer,
		propertyFacade: propertyFacadeReducer,
		propertyFloor: propertyFloorReducer,
		propertyReserve: propertyReserveReducer,
		propertyRoomService: propertyRoomServiceReducer,
		propertyStatus: propertyStatusReducer,
		propertyStatusCommercial: propertyStatusCommercialReducer,
		propertyType: propertyTypeReducer,
		filtered: filteredReducer,
		commercial: commercialReducer,
		owner: ownerReducer,
		building: buildingReducer,
		property: propertyReducer,
		propertyDetail: propertyDetailReducer,
		router: routerReducer,
		...asyncReducers
	})
);

export default createReducer;
