export const PER_PAGE_ROLE = 'PER_PAGE_ROLE';
export const PAGE_ROLE = 'PAGE_ROLE';
export const PAGES_ROLE = 'PAGES_ROLE';
export const SEARCH_ROLE = 'SEARCH_ROLE';
export const ARRAY_LIST_ROLE = 'ARRAY_LIST_ROLE';
export const ARRAY_LIST_TO_DROPDOWN_ROLE = 'ARRAY_LIST_TO_DROPDOWN_ROLE';
export const REFRESH_TABLE_ROLE = 'REFRESH_TABLE_ROLE';
export const IS_OPEN_MODAL_ROLE = 'IS_OPEN_MODAL_ROLE';
export const IS_OPEN_MODAL_PERMISSION_ROLE = 'IS_OPEN_MODAL_PERMISSION_ROLE';
export const IS_LOADING_SHOW_ROLE = 'IS_LOADING_SHOW_ROLE';
export const IS_LOADING_FORM_ROLE = 'IS_LOADING_FORM_ROLE';
export const FORM_TYPE_ROLE = 'FORM_TYPE_ROLE';
export const FORM_DATA_ROLE = 'FORM_DATA_ROLE';
