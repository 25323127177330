import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { history } from '../../../../redux/store'
import Layout from '../../../../components/mui/Layout'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import IconButton from "@material-ui/core/IconButton"
import CardContent from '@material-ui/core/CardContent'
import Controls from "../../../../components/controls/Controls"
import Display from "../../../../components/display/Display"
import Feedback from "../../../../components/feedback/Feedback"
import DropdownSelect from "../../settings/RealStatePage/components/DropdownSelect";
import DropdownSelectBank from "../../settings/BankPage/components/DropdownSelect";
import ComplementRow, { extractComplement } from '../PropertyLocationOldPage/components/ComplementRow'
import errorServices from '../../../../services/errors'
import { swalSuccess } from '../../../../services/sweetalert'
import { Permission }  from '../../../../services/permissions/Permission'
import axios from '../../../../services/axios'

const itemsStrata = [
	{ id: 1, name: "1" },
	{ id: 2, name: "2" },
	{ id: 3, name: "3" },
	{ id: 4, name: "4" },
	{ id: 5, name: "5" },
	{ id: 6, name: "6" }
]

const itemsBanks = [
	{ id: 1, name: "Ahorro" },
	{ id: 2, name: "Corriente" }
]

const arrayDropdown1 = [
	{ id: "Calle", name: "Calle" },
	{ id: "Carrera", name: "Carrera" },
	{ id: "Diagonal", name: "Diagonal" },
	{ id: "Transversal", name: "Transversal" },
	{ id: "Vía", name: "Vía" },
	{ id: "Avenida", name: "Avenida" }
]

const defaultState = {
	typeComplement: '',
	textComplement: '',
}

const PropertyBuildingShowOldPage = () => {
	const params = useParams()
	const uID = params.uID
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const { permissions } = useSelector( state  => state.auth.userData)
	const [getLoading, setLoading] = useState(true)
	const [getShowBuildingQuestion, setShowBuildingQuestion] = useState(false)
	const [getShowBuildingAnswer, setShowBuildingAnswer] = useState(false)
	const [getShowBuilding, setShowBuilding] = useState(false)
	const [getShowNewBuilding, setShowNewBuilding] = useState(false)
	const [getBuildingType, setBuildingType] = useState("Si")
	const [getModule, setModule] = useState({ module: 'Captación de la Propiedad', title: 'Captación de las Propiedades', subtitle: 'Paso 1 - Asignación de Edificación' })
	const [arrayStrata, setStrata] = useState(itemsStrata)
	const [arrayBankType, setBankType] = useState(itemsBanks)
	const [arrayDropdownRealstate, setDropdownRealstate] = useState([])
	const [arrayDropdownBank, setDropdownBank] = useState([])
	const [arrayDropdownCountry, setDropdownCountry] = useState([])
	const [arrayDropdownState, setDropdownState] = useState([])
	const [arrayDropdownCity, setDropdownCity] = useState([])
	const [arrayDropdownNeighborhood, setDropdownNeighborhood] = useState([])
	const [arrayDropdownBuilding, setDropdownBuilding] = useState([])
	const [arrayDropdownBuildingType, setDropdownBuildingType] = useState([])
	const [arrayArea, setArea] = useState([])
	const [arrayBuildingComercial, setBuildingComercial] = useState([])
	const [getItemMultiple, setItemMultiple] = useState([])
	const [getCountry, setCountry] = useState("")
	const [getCountryID, setCountryID] = useState("")
	const [getCountryError, setCountryError] = useState(false)
	const [getCountryMessage, setCountryMessage] = useState("")
	const [getState, setState] = useState("")
	const [getStateID, setStateID] = useState("")
	const [getStateError, setStateError] = useState(false)
	const [getStateMessage, setStateMessage] = useState("")
	const [getCity, setCity] = useState("")
	const [getCityID, setCityID] = useState("")
	const [getCityError, setCityError] = useState(false)
	const [getCityMessage, setCityMessage] = useState("")
	const [getNeighborhood, setNeighborhood] = useState("")
	const [getNeighborhoodID, setNeighborhoodID] = useState("")
	const [getNeighborhoodIDError, setNeighborhoodIDError] = useState(false)
	const [getNeighborhoodIDMessage, setNeighborhoodIDMessage] = useState("")
	const [getReference, setReference] = useState("")
	const [getReferenceError, setReferenceError] = useState(false)
	const [getReferenceMessage, setReferenceMessage] = useState("")
	const [getStrataID, setStrataID] = useState("")
	const [getStrataIDError, setStrataIDError] = useState(false)
	const [getStrataIDMessage, setStrataIDMessage] = useState("")
	const [getRealstate, setRealstate] = useState("")
	const [getRealstateID, setRealstateID] = useState("")
	const [getRealstateError, setRealstateError] = useState(false)
	const [getRealstateMessage, setRealstateMessage] = useState("")
	const [getBuildingID, setBuildingID] = useState("")
	const [getBuildingTypeID, setBuildingTypeID] = useState("")
	const [getBuildingTypeIDError, setBuildingTypeIDError] = useState(false)
	const [getBuildingTypeIDMessage, setBuildingTypeIDMessage] = useState("")
	const [getNit, setNit] = useState("")
	const [getCompany, setCompany] = useState("")
	const [getCompanyError, setCompanyError] = useState(false)
	const [getCompanyMessage, setCompanyMessage] = useState("")
	const [getAddress, setAddress] = useState("")
	const [getAddressBuilding, setAddressBuilding] = useState("")
	const [getAddressBuildingError, setAddressBuildingError] = useState(false)
	const [getAddressBuildingMessage, setAddressBuildingMessage] = useState("")
	const [getReferenceBuilding, setReferenceBuilding] = useState("")
	const [getYearConstruction, setYearConstruction] = useState("")
	const [getAreaConstruction, setAreaConstruction] = useState("")
	const [getAreaTotal, setAreaTotal] = useState("")
	const [getAdminID, setAdminID] = useState("")
	const [getAdminName, setAdminName] = useState("")
	const [getAdminPhone1, setAdminPhone1] = useState("")
	const [getAreaID, setAreaID] = useState("")
	const [getBankID, setBankID] = useState("")
	const [getBankTypeID, setBankTypeID] = useState("")
	const [getBankAccount, setBankAccount] = useState("")
	const [getHandleAddress, setHandleAddress] = useState('')
	const [getLongitudeBuilding, setLongitudeBuilding] = useState('-74.7889')
	const [getLatitudeBuilding, setLatitudeBuilding] = useState('10.9878')
	const [getLongitude, setLongitude] = useState('')
	const [getLatitude, setLatitude] = useState('')
	const [getDrop1Error, setDrop1Error] = useState(false)
	const [getDrop1Message, setDrop1Message] = useState('')
	const [getValue1Error, setValue1Error] = useState(false)
	const [getValue1Message, setValue1Message] = useState('')
	const [getValue2Error, setValue2Error] = useState(false)
	const [getValue2Message, setValue2Message] = useState('')
	const [getValue3Error, setValue3Error] = useState(false)
	const [getValue3Message, setValue3Message] = useState('')
	const [getLoadAddress, setLoadAddress] = useState({
		"drop1": '',
		"value1": '',
		"text1": '',
		"suffix1": '',
		"drop2": '',
		"value2": '',
		"text2": '',
		"value3": '',
		"suffix2": ''
	})
	const [complement, setComplement] = useState('')
	const [rows, setRows] = useState([])
	
	const getHandleDropdownBuilding = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/buildings`)
			
			if (data.status === 200) {
				setDropdownBuilding(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const getHandleDropdownCountry = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/location/countries`)
			
			if (data.status === 200) {
				setDropdownCountry(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const setHandleShowNewBuilding = (event) => {
		if (event){
			getHandleDropdownCountry()
			getHandleDropdownBuildingType()
			getHandleDropdownArea()
			setNit('')
			setNeighborhood('')
			setNeighborhoodID('')
			setNeighborhoodIDError(false)
			setNeighborhoodIDMessage('')
			setCompany('')
			setCompanyError(false)
			setCompanyMessage('')
			setCountry('')
			setCountryID('')
			setCountryError(false)
			setCountryMessage('')
			setStateID('')
			setStateError(false)
			setStateMessage('')
			setCity('')
			setCityID('')
			setCityError(false)
			setCityMessage('')
			setStrataID('')
			setStrataIDError(false)
			setStrataIDMessage('')
			setRealstateID('')
			setBuildingTypeID('')
			setBuildingTypeIDError(false)
			setBuildingTypeIDMessage('')
			setYearConstruction('')
			setAreaConstruction('')
			setAreaTotal('')
			setAdminID('')
			setAdminName('')
			setAdminPhone1('')
			setBankID('')
			setBankTypeID('')
			setBankAccount('')
			setReferenceBuilding('')
			setAddressBuilding('')
			setLongitudeBuilding('-74.7889')
			setLatitudeBuilding('10.9878')
			setLoadAddress({
				'drop1': '',
				'value1': '',
				'text1': '',
				'suffix1': '',
				'drop2': '',
				'value2': '',
				'text2': '',
				'value3': '',
				'suffix2': ''
			})
			setShowBuildingQuestion(false)
			setShowBuildingAnswer(false)
		} else {
			getHandleDropdownBuilding()
			setShowBuildingQuestion(true)
			setShowBuildingAnswer(true)
		}
		
		setBuildingID("")
		setShowNewBuilding(event)
	}

	const getHandleEditProperty = async () => {
		setLoading(true)

		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`

			const data = await axios.get(`dashboard/properties/${uID}\``)

			if (data.status === 200) {
				setShowBuildingQuestion(true)

				const concatenatedAddress = setLoadedAddress(data.data.data);

				let address = data.data.data.address || concatenatedAddress;
				let complement = '';

				if (data.data.data.address) {
					//remove concatenated address from address and remove more than one space and initial spaces
					complement = extractComplement(data.data.data.address);
					//if complement exists, address will be the concatenated address
					if (complement) {
						address = concatenatedAddress;
						const copyRows = [{ typeComplement: ' ', textComplement: complement }]
						//add complement to rows
						setRows(copyRows)
						//update address with complement
						handleComplement(copyRows)
					}
				}

				if (data.data.data.has_building === 'Si'){
					setBuildingType("Si")
					setShowBuildingAnswer(true)
					setShowBuilding(true)
					setLoadAddress({
						'drop1': data.data.data.drop1,
						'value1': data.data.data.value1,
						'text1': data.data.data.text1,
						'suffix1': data.data.data.suffix1,
						'drop2': data.data.data.drop2,
						'value2': data.data.data.value2,
						'text2': data.data.data.text2,
						'value3': data.data.data.value3,
						'suffix2': data.data.data.suffix2
					})
					setRealstate(data.data.data.realstate)
					setRealstateID(data.data.data.realstate_id)
					setCompany(data.data.data.company)
					setCity(data.data.data.city)
					setNeighborhood(data.data.data.neighborhood)
					setAddress(address)
					setReference(data.data.data.reference)
					setLatitude(data.data.data.latitude)
					setLongitude(data.data.data.longitude)
					setBuildingID(data.data.data.building_id)
				} else {
					setBuildingType("No")
					setShowBuildingAnswer(false)
					setShowBuilding(false)
					setBuildingID("")
				}

				setShowNewBuilding(false)
			}
		} catch (error) {
			await errorServices(error)
		}

		setLoading(false)
	}

	useEffect(() => {
		getHandleDropdownBuilding()
		getHandleDropdownCountry()
		getHandleEditProperty()
	}, [])
	
	useEffect(() => {
		setHandleShowNewBuilding(false)
	}, [permissions])
	
	const setHandleLoadAddress = (e) => {
		setLoadAddress({
			...getLoadAddress,
			[e.target.name]:e.target.value
		})
	}
	
	const setCreateAddressBuilding = (obj) => {
		let address = ""
		
		if (getLoadAddress.drop1 !== "" || getLoadAddress.drop1 !== null) {
			address = address + " " + getLoadAddress.drop1
		}
		
		if (getLoadAddress.value1 !== "" || getLoadAddress.value1 !== null) {
			address = address + " " + getLoadAddress.value1
		}
		
		if (getLoadAddress.text1 !== "" || getLoadAddress.text1 !== null) {
			address = address + getLoadAddress.text1
		}
		
		if (getLoadAddress.suffix1 !== "" || getLoadAddress.suffix1 !== null) {
			address = address + " "  + getLoadAddress.suffix1
		}

		if (getLoadAddress.drop2 !== "" || getLoadAddress.drop2 !== null) {
			address = address + " " + getLoadAddress.drop2
		}
		
		if (getLoadAddress.value2 !== "" || getLoadAddress.value2 !== null) {
			address = address + " # " + getLoadAddress.value2
		}
		
		if (getLoadAddress.text2 !== "" || getLoadAddress.text2 !== null) {
			address = address + getLoadAddress.text2
		}
		
		if (getLoadAddress.value3 !== "" || getLoadAddress.value3 !== null) {
			address = address + " - " + getLoadAddress.value3
		}
		
		if (getLoadAddress.suffix2 !== "" || getLoadAddress.suffix2 !== null) {
			address = address + " " + getLoadAddress.suffix2
		}
		
		setAddressBuilding(address)
	}

	const setLoadedAddress = (data) => {
		let address = "";

		if (data.drop1 !== "" && data.drop1 !== null) {
			address = address + " " + data.drop1;
		}

		if (data.value1 !== "" && data.value1 !== null) {
			address = address + " " + data.value1;
		}

		if (data.text1 !== "" && data.text1 !== null) {
			address = address + data.text1;
		}

		if (data.suffix1 !== "" && data.suffix1 !== null) {
			address = address + " " + data.suffix1;
		}

		if (data.drop2 !== "" && data.drop2 !== null) {
			address = address + " " + data.drop2;
		}

		if (data.value2 !== "" && data.value2 !== null) {
			address = address + " # " + data.value2;
		}

		if (data.text2 !== "" && data.text2 !== null) {
			address = address + "" + data.text2;
		}

		if (data.value3 !== "" && data.value3 !== null) {
			address = address + " - " + data.value3;
		}

		if (data.suffix2 !== "" && data.suffix2 !== null) {
			address = address + " " + data.suffix2;
		}

		return address.trim();
	}
	
	useEffect(() => {
		setCreateAddressBuilding(getLoadAddress)
	}, [getLoadAddress])
	
	const setHandleShowBuilding = (event) => {
		if (event) {
			setBuildingType("Si")
			setBuildingID("")
			getHandleDropdownBuilding()
		} else {
			setBuildingType("No")
			setRealstateError(false)
			setRealstateMessage('')
		}
		
		setShowBuildingQuestion(true)
		setShowBuildingAnswer(event)
		setShowBuilding(false)
		setShowNewBuilding(false)
	}
	
	const getHandleDropdownBuildingType = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/buildings/types`)
			
			if (data.status === 200) {
				setDropdownBuildingType(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const getHandleDropdownState = async (country_id) => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/location/states/by-country/${country_id}`)
			
			if (data.status === 200) {
				setDropdownState(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const getHandleDropdownCity = async (state_id) => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/location/cities/by-state/${state_id}`)
			
			if (data.status === 200) {
				setDropdownCity(data.data.data.records)
				
				try {
					axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
					
					const data = await axios.get(`dashboard/settings/location/states/${state_id}` )
					
					if (data.status === 200) {
						setState(data.data.data.name)
					}
				} catch (error) {
					await errorServices(error)
				}
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const getHandleDropdownNeighborhood = async (city_id) => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/location/neighborhoods/by-city/${city_id}`)
			
			if (data.status === 200) {
				setDropdownNeighborhood(data.data.data.records)
				
				try {
					axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
					
					const data = await axios.get(`dashboard/settings/location/cities/${city_id}` )
					
					if (data.status === 200) {
						setCity(data.data.data.name)
					}
				} catch (error) {
					await errorServices(error)
				}
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const setHandleForm = async (e) => {
		e.preventDefault()
		
		if (getBuildingType === 'Si') {
			if (getBuildingID === '' || getReference === '') {
				setReferenceError(getReference === "" ? true : false)
				setReferenceMessage(getReference === "" ? "'El campo es requerido'" : "")
				return false
			}
		}
		
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				building_type: getBuildingType,
				building_id: getBuildingID,
				complement: complement,
				reference: getReference,
			}
			
			const data = await axios.put(`dashboard/properties/${uID}`, param)
			
			if (data.status === 200) {
				swalSuccess('Los datos fueron actualizados correctamente')
				if (getBuildingType === 'Si'){
					await history.push(`/dashboard/propiedades/datos-generales/${data.data.data}`)
				} else {
					await history.push(`/dashboard/propiedades/localizacion/${data.data.data}`)
				}
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	const setHandleFormBuilding = async (e) => {
		e.preventDefault()
		
		if (getBuildingTypeID === "" || getCompany === ""  || getCountryID === "" || getStateID === "" || getCityID === "" || getStrataID === "" || getNeighborhoodID === "" || getLoadAddress.drop1 === "" || getLoadAddress.value1 === "" || getLoadAddress.value2 === "" || getLoadAddress.value3 === "" || getAddressBuilding === ""){
			setBuildingTypeIDError(getBuildingTypeID === "" ? true : false)
			setBuildingTypeIDMessage(getBuildingTypeID === "" ? "El campo es requerido" : "")
			setCompanyError(getCompany === "" ? true : false)
			setCompanyMessage(getCompany === "" ? "El campo es requerido" : "")
			setCountryError(getCountry === "" ? true : false)
			setCountryMessage(getCountry === "" ? "El campo es requerido" : "")
			setStateError(getState === "" ? true : false)
			setStateMessage(getState === "" ? "El campo es requerido" : "")
			setCityError(getCity === "" ? true : false)
			setCityMessage(getCity === "" ? "El campo es requerido" : "")
			setStrataIDError(getStrataID === "" ? true : false)
			setStrataIDMessage(getStrataID === "" ? "El campo es requerido" : "")
			setNeighborhoodIDError(getNeighborhoodID === "" ? true : false)
			setNeighborhoodIDMessage(getNeighborhoodID === "" ? "El campo es requerido" : "")
			setDrop1Error(getLoadAddress.drop1 === "" ? true : false)
			setDrop1Message(getLoadAddress.drop1 === "" ? "'El campo es requerido'" : "")
			setValue1Error(getLoadAddress.value1 === "" ? true : false)
			setValue1Message(getLoadAddress.value1 === "" ? "'El campo es requerido'" : "")
			setValue2Error(getLoadAddress.value2 === "" ? true : false)
			setValue2Message(getLoadAddress.value2 === "" ? "'El campo es requerido'" : "")
			setValue3Error(getLoadAddress.value3 === "" ? true : false)
			setValue3Message(getLoadAddress.value3 === "" ? "'El campo es requerido'" : "")
			setAddressBuildingError(getAddressBuilding === "" ? true : false)
			setAddressBuildingMessage(getAddressBuilding === "" ? "'El campo es requerido'" : "")
		} else {
			setLoading(true)
			
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				let amenities = null
				
				if (getItemMultiple.length > 0) {
					amenities = getItemMultiple.map((item) => {
						return ({
							"amenity_id": item
						})
					})
				}
				
				const param = {
					building_type_id: getBuildingTypeID,
					nit: getNit,
					company: getCompany,
					strata: getStrataID,
					year_construction: getYearConstruction,
					area_construction: getAreaConstruction,
					area_total: getAreaTotal,
					neighborhood_id: getNeighborhoodID,
					drop1: getLoadAddress.drop1,
					value1: getLoadAddress.value1,
					text1: getLoadAddress.text1,
					suffix1: getLoadAddress.suffix1,
					drop2: getLoadAddress.drop2,
					value2: getLoadAddress.value2,
					text2: getLoadAddress.text2,
					value3: getLoadAddress.value3,
					suffix2: getLoadAddress.suffix2,
					address: getAddressBuilding,
					reference: getReferenceBuilding,
					latitude: getLatitudeBuilding,
					longitude: getLongitudeBuilding,
					admin_id: getAdminID,
					admin_name: getAdminName,
					admin_phone1: getAdminPhone1,
					bank_id: getBankID,
					bank_type: getBankTypeID,
					bank_account: getBankAccount,
					realstate_id: getRealstateID,
					amenities: amenities
				}
				
				const data = await axios.post(`dashboard/buildings`, param)
				
				if (data.status === 200) {
					getHandleDropdownCountry()
					getHandleDropdownBuildingType()
					getHandleDropdownBuilding()
					setNit('')
					setNeighborhood('')
					setNeighborhoodID('')
					setNeighborhoodIDError(false)
					setNeighborhoodIDMessage('')
					setCompany('')
					setCompanyError(false)
					setCompanyMessage('')
					setCountry('')
					setCountryID('')
					setCountryError(false)
					setCountryMessage('')
					setStateID('')
					setStateError(false)
					setStateMessage('')
					setCity('')
					setCityID('')
					setCityError(false)
					setCityMessage('')
					setStrataID('')
					setStrataIDError(false)
					setStrataIDMessage('')
					setRealstateID('')
					setBuildingTypeID('')
					setBuildingTypeIDError(false)
					setBuildingTypeIDMessage('')
					setYearConstruction('')
					setAreaConstruction('')
					setAreaTotal('')
					setAdminID('')
					setAdminName('')
					setAdminPhone1('')
					setBankID('')
					setBankTypeID('')
					setBankAccount('')
					setReferenceBuilding('')
					setAddressBuilding('')
					setLongitudeBuilding('-74.7889')
					setLatitudeBuilding('10.9878')
					setDrop1Error(false)
					setDrop1Message('')
					setValue1Error(false)
					setValue1Message('')
					setValue2Error(false)
					setValue2Message('')
					setValue3Error(false)
					setValue3Message('')
					setLoadAddress({
						'drop1': '',
						'value1': '',
						'text1': '',
						'suffix1': '',
						'drop2': '',
						'value2': '',
						'text2': '',
						'value3': '',
						'suffix2': ''
					})
					setBuildingID("")
					setShowNewBuilding(false)
					setShowBuilding(false)
					setShowBuildingQuestion(true)
					setShowBuildingAnswer(true)
				}
			} catch (error) {
				await errorServices(error)
			}
			
			setLoading(false)
		}
	}
	
	const setHandleBuilding = async (id) => {
		setReference('')
		setShowNewBuilding(false)

		if (id === "") {
			setBuildingID("")
			setAddress('')
			setLongitude('-74.7889')
			setLatitude('10.9878')
			setShowBuilding(false)
		} else {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`

				const data = await axios.get(`dashboard/buildings/${id}`)

				if (data.status === 200) {
					setBuildingID(data.data.data.id)
					setCompany(data.data.data.company)
					setRealstate(data.data.data.realstate)
					setRealstateID(data.data.data.realstate_id)
					setCity(data.data.data.city_name)
					setNeighborhood(data.data.data.neighborhood_name)
					setAddress(data.data.data.address)
					setLongitude(data.data.data.longitude)
					setLatitude(data.data.data.latitude)
					setShowBuilding(true)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	const setHandleMap = () => {
		if (getCountryID === "" || getStateID === "" || getCityID === "" || getLoadAddress.drop1 === "" || getLoadAddress.value1 === "" || getLoadAddress.value2 === "" || getLoadAddress.value3 === ""){
			setCountryError(getCountry === "" ? true : false)
			setCountryMessage(getCountry === "" ? "El campo es requerido" : "")
			setStateError(getState === "" ? true : false)
			setStateMessage(getState === "" ? "El campo es requerido" : "")
			setCityError(getCity === "" ? true : false)
			setCityMessage(getCity === "" ? "El campo es requerido" : "")
			setDrop1Error(getLoadAddress.drop1 === "" ? true : false)
			setDrop1Message(getLoadAddress.drop1 === "" ? "'El campo es requerido'" : "")
			setValue1Error(getLoadAddress.value1 === "" ? true : false)
			setValue1Message(getLoadAddress.value1 === "" ? "'El campo es requerido'" : "")
			setValue2Error(getLoadAddress.value2 === "" ? true : false)
			setValue2Message(getLoadAddress.value2 === "" ? "'El campo es requerido'" : "")
			setValue3Error(getLoadAddress.value3 === "" ? true : false)
			setValue3Message(getLoadAddress.value3 === "" ? "'El campo es requerido'" : "")
		} else {
			let address = ""
			
			address = getCountry + ", " + getState + " " + getCity + " " + getLoadAddress.drop1 + " " + getLoadAddress.value1
			
			if (getLoadAddress.text1 !== "" || getLoadAddress.text1 !== null) {
				address = address + getLoadAddress.text1
			}
			
			address = address + " #"
			
			if (getLoadAddress.drop2 !== "" || getLoadAddress.drop2 !== null) {
				address = address + " " +getLoadAddress.drop2 + " "
			}
			
			address = address + getLoadAddress.value2
			
			if (getLoadAddress.text2 !== "" || getLoadAddress.text2 !== null) {
				address = address + getLoadAddress.text2
			}
			
			address = address + " - " + getLoadAddress.value3
			
			setHandleAddress(address)
		}
	}
	
	const getHandleDropdownArea = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/areas`)
			
			if (data.status === 200) {
				setArea(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const getHandleDropdownBuildingComercial = async (value) => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.post(`dashboard/settings/amenities/by-commercial-building`, { search: value })
			
			if (data.status === 200) {
				setBuildingComercial(data.data.data)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const handleOnChange = (index, name, value) => {
		const copyRows = [...rows]
		copyRows[index] = {
			...copyRows[index],
			[name]: value
		}
		setRows(copyRows)
		handleComplement(copyRows)
	}
	
	const handleComplement = (copyRows) => {
		if (copyRows.length > 0) {
			const array = []
			
			copyRows.map(({ typeComplement, textComplement }) => {
				array.push(`${typeComplement} ${textComplement}`)
			})
			
			if (array.toString().replace(',', ' ') !== '') {
				setComplement(array.toString().replace(',', ' '))
			}
		} else {
			setComplement('')
		}
	}
	
	const handleOnAdd = () => {
		setRows(rows.concat(defaultState))
	}
	
	const handleOnClear = () => {
		setRows([])
		setComplement('')
	}
	
	const handleOnRemove = index => {
		const copyRows = [...rows]
		copyRows.splice(index, 1)
		setRows(copyRows)
		handleComplement(copyRows)
	}
	
	return (
		<Layout.Page title="Propiedades - Edificación">
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Card className="p-3">
						<CardContent>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={6}>
									<Display.Typography
										variant="h5"
										color="textPrimary"
										label={getModule.title}
									/>
									<Display.Typography
										variant="subtitle1"
										color="textSecondary"
										label={getModule.subtitle}
									/>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			
			{
				getShowBuildingQuestion && (
					<Grid container spacing={2} className="animate__animated animate__fadeIn p-4">
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<div className="text-center">
												<Display.Typography
													variant="h5"
													color="textPrimary"
													label="¿El inmueble se encuentra en un conjunto o edificio?"
												/>
												{
													getLoading ?
														(
															<>
																<div className="margin-left40P">
																	<Feedback.Loading type="text" animation="wave" width="30%" height="30px" />
																</div>
															</>
														)
														:
														<>
															<Controls.Button
																label="Si"
																color={getShowBuildingAnswer ? "primary" : "transparent"}
																variant="contained"
																onClick={e => setHandleShowBuilding(true)}
															/>
															<Controls.Button
																label="No"
																color={getShowBuildingAnswer ? "transparent" : "primary"}
																variant="contained"
																onClick={e => setHandleShowBuilding(false)}
															/>
														</>
												}
											</div>
										</Grid>
										{
											!getShowBuildingAnswer && (
												<>
													<Grid item xs={12}>
														<div className="float-right">
															<Controls.ButtonSubmit
																label="Siguiente"
																color="primary"
																variant="contained"
																endIcon={<Display.Icons.ChevronRightIcon />}
																onClick={setHandleForm}
															/>
														</div>
													</Grid>
												</>
											)
										}
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				)
			}
			
			{
				getShowBuildingAnswer && (
					<Grid container spacing={2} className="animate__animated animate__fadeIn p-4">
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<Grid container spacing={3}>
										{
											getLoading ?
												(
													<>
														<Grid item xs={12}>
															<Feedback.Loading type="text" animation="wave" width="25%" height="30px" />
														</Grid>
														<Grid item xs={12}>
															<Feedback.Loading type="text" animation="wave" width="49%" height="30px" />
														</Grid>
														<Grid item xs={12}>
															<Feedback.Loading type="text" animation="wave" width="25%" height="30px" />
														</Grid>
														<Grid item xs={12} sm={6}>
															<Feedback.Loading type="text" animation="wave" width="100%" height="30px" />
														</Grid>
														<Grid item xs={12} sm={6}>
															<Feedback.Loading type="text" animation="wave" width="100%" height="30px" />
														</Grid>
														<Grid item xs={12} sm={6}>
															<Feedback.Loading type="text" animation="wave" width="100%" height="30px" />
														</Grid>
														<Grid item xs={12} sm={6}>
															<Feedback.Loading type="text" animation="wave" width="100%" height="30px" />
														</Grid>
													</>
												)
												:
												<>
													<Grid item xs={12} sm={6}>
														<Display.Typography
															variant="h5"
															color="textPrimary"
															label="Buscar edificaciones existentes"
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<div className="text-right">
															<Controls.Button
																label="Nueva edificación"
																color="transparent"
																variant="contained"
																endIcon={<Display.Icons.AddOutlinedIcon />}
																onClick={e => setHandleShowNewBuilding(true)}
															/>
														</div>
													</Grid>
													<Grid item xs={12} sm={6}>
														<Controls.Autocomplete
															variant="standard"
															size="small"
															label="Buscar edificación"
															name="search_building"
															itemsArray={arrayDropdownBuilding}
															handleChangeSearch={setHandleBuilding}
														/>
													</Grid>
													{
														getShowBuilding && (
															<>
																<Grid item xs={12}>
																	<Grid container spacing={3}>
																		<Grid item xs={12}>
																			<Display.Typography
																				variant="h5"
																				color="textPrimary"
																				label="Información de la edificación"
																			/>
																		</Grid>
																		<Permission
																			permission="realstates.dropdown"
																		>
																			<Grid item xs={12} sm={6}>
																				<Controls.TextArea
																					label="Inmobiliaria"
																					name="realstate"
																					defaultValue={getRealstate}
																					InputProps={{
																						readOnly: true
																					}
																					}
																				/>
																			</Grid>
																		</Permission>
																		<Grid item xs={12} sm={6} md={6}>
																			<Controls.TextArea
																				label="Nombre de la edificación"
																				name="company"
																				defaultValue={getCompany}
																				InputProps={{
																					readOnly: true
																				}
																				}
																			/>
																		</Grid>
																		<Grid item xs={12} sm={6} md={6}>
																			<Controls.TextArea
																				label="Ciudad"
																				name="city"
																				defaultValue={getCity}
																				InputProps={{
																					readOnly: true
																				}
																				}
																			/>
																		</Grid>
																		<Grid item xs={12} sm={6} md={6}>
																			<Controls.TextArea
																				label="Barrio"
																				name="neighborhood"
																				defaultValue={getNeighborhood}
																				InputProps={{
																					readOnly: true
																				}
																				}
																			/>
																		</Grid>
																		<Grid item xs={12} sm={6} md={6}>
																			<Controls.TextArea
																				label="Dirección de la edificación"
																				name="address"
																				defaultValue={`${getAddress} ${complement}`}
																				InputProps={{
																					readOnly: true
																				}
																				}
																			/>
																		</Grid>
																		<Grid item xs={12} sm={6} md={6}>
																			<Controls.TextArea
																				label="* Descripción de la propiedad"
																				name="reference"
																				defaultValue={getReference}
																				error={getReferenceError}
																				message={getReferenceMessage}
																				onChange={e => {
																					setReference(e.target.value)
																					
																					if (e.target.value === ""){
																						setReferenceError(true)
																						setReferenceMessage('El campo es requerido')
																					} else {
																						setReferenceError(false)
																						setReferenceMessage('')
																					}
																				}}
																			/>
																		</Grid>
																		{rows.length > 0 && (
																			<Grid item xs={12} className="animated animate__fadeIn">
																				{rows.map((row, index) => (
																					<ComplementRow
																						{...row}
																						onChange={(name, value) => handleOnChange(index, name, value)}
																						onRemove={() => handleOnRemove(index)}
																						key={index}
																					/>
																				))}
																			</Grid>
																		)}
																		<Grid item xs={12}>
																			<div className="d-inline">
																				Complemento
																			</div>
																			<div className="d-inline ml-2">
																				<IconButton
																					color="inherit"
																					onClick={handleOnAdd}
																					className="bg-grey height-32 width-32"
																				>
																					<Display.Icons.AddOutlinedIcon
																						className="text-dark"
																						style={{ fontSize: 20 }}
																					/>
																				</IconButton>
																				<IconButton
																					color="inherit"
																					onClick={handleOnClear}
																					className="bg-grey height-32 width-32"
																				>
																					<Display.Icons.DeleteOutlinedIcon
																						className="text-dark"
																						style={{ fontSize: 20 }}
																					/>
																				</IconButton>
																			</div>
																		</Grid>
																		<Grid item xs={12}>
																			<div className="float-right">
																				<Controls.ButtonSubmit
																					label="Siguiente"
																					color="primary"
																					variant="contained"
																					endIcon={<Display.Icons.ChevronRightIcon />}
																					onClick={setHandleForm}
																				/>
																			</div>
																		</Grid>
																	</Grid>
																</Grid>
															</>
														)
													}
												</>
										}
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				)
			}
			
			{
				getShowNewBuilding && (
					<Grid container spacing={2} className="animate__animated animate__fadeIn p-4">
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<form>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<Display.Typography
													variant="h5"
													color="textPrimary"
													label="Creación de Edificación"
												/>
												<Display.Typography
													variant="subtitle1"
													color="error"
													label="Los datos con (*) son requeridos"
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
											</Grid>
											<Grid item xs={12} sm={6}>
												<DropdownSelect
													name="realstate_id"
													value={getRealstateID}
													handleChange={(e) => {
														setRealstateID(e.target.value)
														
														if (e.target.value === ""){
															setRealstateError(true)
															setRealstateMessage('El campo es requerido')
														} else {
															setRealstateError(false)
															setRealstateMessage('')
														}
													}}
													className="font-family-roboto-regular fs-6 text-dark w-100"
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextField
													label="* Razón Social"
													name="company"
													defaultValue={getCompany}
													error={getCompanyError}
													message={getCompanyMessage}
													onChange={e => {
														setCompany(e.target.value)
														
														if (e.target.value.length > 0){
															setCompanyError(false)
															setCompanyMessage('')
														} else {
															setCompanyError(true)
															setCompanyMessage('El campo es requerido')
														}
													}}
													onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.Select
													label="* Tipo"
													name="building_type_id"
													defaultValue={getBuildingTypeID}
													error={getBuildingTypeIDError}
													message={getBuildingTypeIDMessage}
													onChange={(e) => {
														setBuildingTypeID(e.target.value)
														
														if (e.target.value === ""){
															setBuildingTypeIDError(true)
															setBuildingTypeIDMessage('El campo tipo de edificación es requerido')
														} else {
															setBuildingTypeIDError(false)
															setBuildingTypeIDMessage('')
														}
													}}
													arrayDropdown={arrayDropdownBuildingType}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.Select
													label="* Estrato"
													name="strata"
													defaultValue={getStrataID}
													error={getStrataIDError}
													message={getStrataIDMessage}
													onChange={(e) => {
														setStrataID(e.target.value)
														
														if (e.target.value === ""){
															setStrataIDError(true)
															setStrataIDMessage('El campo es requerido')
														} else {
															setStrataIDError(false)
															setStrataIDMessage('')
														}
													}}
													arrayDropdown={arrayStrata}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.Select
													label="* País"
													name="country"
													defaultValue={getCountryID}
													error={getCountryError}
													message={getCountryMessage}
													onChange={(e) => {
														getHandleDropdownState(e.target.value)
														setCountryID(e.target.value)
														
														if (e.target.value === "") {
															setCountryError(true)
															setCountryMessage('El campo es requerido')
														} else {
															setCountryError(false)
															setCountryMessage()
														}
													}}
													arrayDropdown={arrayDropdownCountry}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.Select
													label="* Departamento/Estado"
													name="state"
													defaultValue={getStateID}
													error={getStateError}
													message={getStateMessage}
													onChange={(e) => {
														getHandleDropdownCity(e.target.value)
														setStateID(e.target.value)
														
														if (e.target.value === "") {
															setStateError(true)
															setStateMessage('El campo es requerido')
														} else {
															setStateError(false)
															setStateMessage()
														}
													}}
													arrayDropdown={arrayDropdownState}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.Select
													label="* Ciudad/Provincia"
													name="city"
													defaultValue={getCityID}
													error={getCityError}
													message={getCityMessage}
													onChange={(e) => {
														getHandleDropdownNeighborhood(e.target.value)
														setCityID(e.target.value)
														
														if (e.target.value === "") {
															setCityError(true)
															setCityMessage('El campo ciudad es requerido')
														} else {
															setCityError(false)
															setCityMessage()
														}
													}}
													arrayDropdown={arrayDropdownCity}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.Select
													label="* Barrio/Urbanización"
													name="neighborhood_id"
													defaultValue={getNeighborhoodID}
													error={getNeighborhoodIDError}
													message={getNeighborhoodIDMessage}
													onChange={(e) => {
														setNeighborhoodID(e.target.value)
														
														if (e.target.value === ""){
															setNeighborhoodIDError(true)
															setNeighborhoodIDMessage('El campo es requerido')
														} else {
															setNeighborhoodIDError(false)
															setNeighborhoodIDMessage('')
														}
													}}
													arrayDropdown={arrayDropdownNeighborhood}
												/>
											</Grid>
											<Grid item xs={12}>
												<Grid container spacing={3}>
													<Grid item xs={12}>
														<Display.Typography
															variant="h5"
															color="textPrimary"
															label="A continuación digite la dirección"
														/>
														<Display.Typography
															variant="h6"
															color="textSecondary"
															label="Ejemplo: Carrera 5A SUR Calle # 12B - 30 Diagonal"
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={5}>
														<Controls.Select
															label="* Av. Principal"
															name="drop1"
															defaultValue={getLoadAddress.drop1}
															error={getDrop1Error}
															message={getDrop1Message}
															onChange={(e) => {
																setHandleLoadAddress(e)
																
																if (e.target.value === ""){
																	setDrop1Error(true)
																	setDrop1Message('El campo es requerido')
																} else {
																	setDrop1Error(false)
																	setDrop1Message('')
																}
															}}
															arrayDropdown={arrayDropdown1}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={2}>
														<Controls.TextFieldNumber
															label="* Nº 1"
															name="value1"
															defaultValue={getLoadAddress.value1}
															error={getValue1Error}
															message={getValue1Message}
															onChange={e => {
																setHandleLoadAddress(e)
																
																if (e.target.value === ""){
																	setValue1Error(true)
																	setValue1Message('El campo es requerido')
																} else {
																	setValue1Error(false)
																	setValue1Message('')
																}
															}}
															onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={2}>
														<Controls.TextField
															label="Info 1 (Ej: A,B,C)"
															name="text1"
															defaultValue={getLoadAddress.text1}
															onChange={e => setHandleLoadAddress(e)}
															onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={3}>
														<Controls.TextField
															label="Sufijo 1 (Ej: Norte)"
															name="suffix1"
															defaultValue={getLoadAddress.suffix1}
															onChange={e => setHandleLoadAddress(e)}
															onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={5}>
														<Controls.Select
															label="Av. Secundaria"
															name="drop2"
															defaultValue={getLoadAddress.drop2}
															onChange={(e) => setHandleLoadAddress(e)}
															arrayDropdown={arrayDropdown1}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={1}>
														<Controls.TextFieldNumber
															label="* Nº 2"
															name="value2"
															defaultValue={getLoadAddress.value2}
															error={getValue2Error}
															message={getValue2Message}
															onChange={(e) => {
																setHandleLoadAddress(e)
																
																if (e.target.value === ""){
																	setValue2Error(true)
																	setValue2Message('El campo es requerido')
																} else {
																	setValue2Error(false)
																	setValue2Message('')
																}
															}}
															onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={2}>
														<Controls.TextField
															label="Info 2 (Ej: A,B,C)"
															name="text2"
															defaultValue={getLoadAddress.text2}
															onChange={e => setHandleLoadAddress(e)}
															onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={1}>
														<Controls.TextFieldNumber
															label="* Nº 3"
															name="value3"
															defaultValue={getLoadAddress.value3}
															error={getValue3Error}
															message={getValue3Message}
															onChange={(e) => {
																setHandleLoadAddress(e)
																
																if (e.target.value === ""){
																	setValue3Error(true)
																	setValue3Message('El campo es requerido')
																} else {
																	setValue3Error(false)
																	setValue3Message('')
																}
															}}
															onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={3}>
														<Controls.TextField
															label="Sufijo 2 (Ej: Norte)"
															name="suffix2"
															defaultValue={getLoadAddress.suffix2}
															onChange={e => setHandleLoadAddress(e)}
															onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextArea
													label="* Dirección de la edificación"
													name="address"
													defaultValue={getAddressBuilding}
													InputProps={{
														readOnly: true
													}
													}
													error={getAddressBuildingError}
													message={getAddressBuildingMessage}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextArea
													label="* Referencia"
													name="reference"
													defaultValue={getReferenceBuilding}
													onChange={e => setReferenceBuilding(e.target.value)}
													onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextField
													label="Longitud"
													name="longitude"
													defaultValue={getLongitudeBuilding}
													InputProps={{
														readOnly: true
													}
													}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextField
													label="Latitud"
													name="latitude"
													defaultValue={getLatitudeBuilding}
													InputProps={{
														readOnly: true
													}
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<div className="float-right">
													<Controls.Button
														label="Generar geolocalización"
														color="dark"
														variant="contained"
														endIcon={<Display.Icons.RoomOutlinedIcon />}
														onClick={setHandleMap}
													/>
												</div>
											</Grid>
											<Grid item xs={12}>
												<Feedback.Map
													longitude={getLongitudeBuilding}
													latitude={getLatitudeBuilding}
													setLongitude={setLongitudeBuilding}
													setLatitude={setLatitudeBuilding}
													setHandleAddress={getHandleAddress}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextFieldNumber
													label="Año de Construcción"
													name="year_construction"
													defaultValue={getYearConstruction}
													onChange={e => setYearConstruction(e.target.value)}
													onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextFieldNumber
													label="Área de Construcción"
													name="area_construction"
													defaultValue={getAreaConstruction}
													onChange={e => setAreaConstruction(e.target.value)}
													onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextFieldNumber
													label="Área o Lote Tolal(m2)"
													name="area_total"
													defaultValue={getAreaTotal}
													onChange={e => setAreaTotal(e.target.value)}
													onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextField
													label="ID del Administrador"
													name="admin_id"
													defaultValue={getAdminID}
													onChange={e => setAdminID(e.target.value)}
													onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextField
													label="Nombre del Administrador"
													name="admin_name"
													defaultValue={getAdminName}
													onChange={e => setAdminName(e.target.value)}
													onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextField
													label="Teléfono del Administrador"
													name="admin_phone1"
													defaultValue={getAdminPhone1}
													onChange={e => setAdminPhone1(e.target.value)}
													onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<DropdownSelectBank
													name="bank_id"
													value={getBankID}
													handleChange={(e) => setBankID(e.target.value)}
													onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
													className="font-family-roboto-regular fs-6 text-dark w-100"
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.Select
													label="Tipo de Banco"
													name="bank_type"
													defaultValue={getBankTypeID}
													onChange={(e) => setBankTypeID(e.target.value)}
													arrayDropdown={arrayBankType}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextField
													label="Número de Cuenta"
													name="bank_account"
													defaultValue={getBankAccount}
													onChange={e => setBankAccount(e.target.value)}
													onKeyPress={e => e.charCode === 13 && setHandleFormBuilding(e)}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.Select
													label="Tipo de Área"
													name="area_id"
													defaultValue={getAreaID}
													onChange={e => {
														setAreaID(e.target.value)
														
														if (e.target.value !== ''){
															getHandleDropdownBuildingComercial(e.target.value)
														}
													}}
													arrayDropdown={arrayArea}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.SelectMultiple
													label="Amenidades"
													name="amenities"
													arrayDropdown={arrayBuildingComercial}
													getState={setItemMultiple}
												/>
											</Grid>
											<Grid item xs={12}>
												<div className="float-right">
													<Controls.Button
														label="Cancelar"
														color="secondary"
														variant="contained"
														endIcon={<Display.Icons.CancelOutlinedIcon />}
														onClick={e => setHandleShowNewBuilding(false)}
													/>
													<Controls.Button
														label="Guardar"
														color="primary"
														variant="contained"
														endIcon={<Display.Icons.SaveOutlinedIcon />}
														onClick={setHandleFormBuilding}
													/>
												</div>
											</Grid>
										</Grid>
									</form>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				)
			}
		</Layout.Page>
	)
}

export default PropertyBuildingShowOldPage
