import React from 'react'
import PropTypes from 'prop-types'
import { Slider } from '../../gallery'
import useSlider from '../hooks/useSlider'
import styles from './galleryVideos.module.css'
import Skeleton from '../skeleton'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoaderGallery from '../../../../pages/dashboard/TestPage/components/loader/LoaderGallery'

export function GalleryVideos({
  dataArrayVideos,
  isLoading,
  byProps,
  height,
  openSliderFuncProps,
  closeModalFuncProps,
  setDataGalleriesSliderFuncProps,
  setPage,
  hasMore,
}) {
  const {
    isOpenSlider,
    closeSliderFunc,
    openSliderFunc,
    setDataGalleriesSliderFunc,
    currentIndex,
    goToNext,
    goToPrevious,
  } = useSlider()

  const getCurrentIndex = (index) => {
    openSliderFunc(index)
    setDataGalleriesSliderFunc(dataArrayVideos)
  }

  const getCurrentIndex2 = (index) => {
    setDataGalleriesSliderFuncProps(dataArrayVideos)
    closeModalFuncProps()
    openSliderFuncProps(index, 'videos')
  }
  console.log({dataArrayVideos})
  return (
    <div
      id="scrollableVideos"
      style={{
        overflow: 'auto',
        maxHeight: height ? height : '780px',
        paddingRight: '10px',
      }}
    >
      <InfiniteScroll
        dataLength={dataArrayVideos?.length || 0}
        next={() => setPage((prevPage) => prevPage + 1)}
        hasMore={hasMore}
        loader={<LoaderGallery />}
        scrollableTarget="scrollableVideos"
      >
        {isLoading ? (
          <Skeleton type={'gallery'} />
        ) : (
          <div className={styles.gridContainer}>
            {dataArrayVideos?.map((item, index) => (
              <div key={item.name} className={styles.wrapperVideo}>
                <video
                  onClick={() =>
                    byProps ? getCurrentIndex2(index) : getCurrentIndex(index)
                  }
                  className={styles.videoMain}
                  poster=""
                >
                  <source src={item?.asset?.full_path} />
                </video>
              </div>
            ))}
          </div>
        )}
      </InfiniteScroll>

      <Slider
        typeSlider={'videos'}
        arrayDataMedia={dataArrayVideos}
        closeSlider={closeSliderFunc}
        show={isOpenSlider}
        currentIndexInitial={currentIndex}
        goToNext={goToNext}
        goToPrevious={goToPrevious}
      />
    </div>
  )
}

GalleryVideos.propTypes = {
  dataArrayVideos: PropTypes.array,
  openSliderFuncProps: PropTypes.func,
  closeModalFuncProps: PropTypes.func,
  setDataGalleriesSliderFuncProps: PropTypes.func,
  byProps: PropTypes.bool,
  height: PropTypes.string,
}
