import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Layout from '../../../../components/mui/Layout'
import CustomerModalComponent from './components/CustomerModalComponent'
import DetailedViewProperty from '../../../propertyDetails'
import { previewById } from '../../../../redux/actions/dashboard/filtered/filteredAction'

const FilteredPreviewPage = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const uID = params.uID
  const isLoading = useSelector(state => state.loading.isLoading)
  const isCompletedForm = useSelector(state => state.filtered.isCompletedForm)
  const isOpenModal = useSelector( state => state.filtered.isOpenCustomerModal)
  const formData = useSelector(state => state.filtered.formDataPreview)
  const arrayListPreviewFavorites = useSelector(state => state.filtered.arrayListPreviewFavorites)
  
  /**
   *
   * @description Execute endpoint show by id filtered preview
   * @return dispatch
   *
   */
  const handleShowById = async () => await dispatch( previewById(uID) )

  useEffect(() => {
    handleShowById()
  }, [uID, isCompletedForm])
  
  return (
    <Layout.Page title="Filtrado - Vista Previa del Caso">
      {!isLoading && (
        <DetailedViewProperty
          isAuthenticated={true}
          isAuthenticatedPage={true}
          uID={uID}
          formData={formData}
          hasFavorites={arrayListPreviewFavorites.length === 0 ? false : true}
        />
      )}
      
      <CustomerModalComponent
        isAuthenticated={true}
        isAuthenticatedPage={true}
        isOpenModal={isOpenModal}
        filterID={uID}
        customerID={formData.customer_id}
      />
    </Layout.Page>
  )
}

export default FilteredPreviewPage
