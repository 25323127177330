import { initialValuesCity as initialValues } from '../../../../../../../helpers/variablesInitialValues';
import * as types from '../types/cityType';

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	search: '',
	arrayList: [],
	arrayListToDropdown: [],
	refreshTable: false,
	isOpenModal: false,
	isOpenModalPermission: false,
	isLoadingShow: false,
	isLoadingForm: false,
	formType: '',
	formData: initialValues
};

const cityReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_CITY:
			return {
				...state,
				perPage: payload
			};
		
		case types.PAGE_CITY:
			return {
				...state,
				page: payload
			};
		
		case types.PAGES_CITY:
			return {
				...state,
				pages: payload
			};
		
		case types.SEARCH_CITY:
			return {
				...state,
				search: payload
			};
		
		case types.ARRAY_LIST_CITY:
			return {
				...state,
				arrayList: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_CITY:
			return {
				...state,
				arrayListToDropdown: payload
			};
		
		case types.REFRESH_TABLE_CITY:
			return {
				...state,
				refreshTable: payload
			};
		
		case types.IS_OPEN_MODAL_CITY:
			return {
				...state,
				isOpenModal: payload
			};
		
		case types.IS_LOADING_SHOW_CITY:
			return {
				...state,
				isLoadingShow: payload
			};
		
		case types.IS_LOADING_FORM_CITY:
			return {
				...state,
				isLoadingForm: payload
			};
		
		case types.FORM_TYPE_CITY:
			return {
				...state,
				formType: payload
			};
		
		case types.FORM_DATA_CITY:
			return {
				...state,
				formData: payload
			};
		
		default:
			return state
	}
};

export default cityReducer;
