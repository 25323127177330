import PropTypes from 'prop-types'
import CancelIcon from '@mui/icons-material/Cancel'
import styles from './modal.module.css'

export function Modal({ show, closeModal, children, width, position }) {
  const MYCLASS = {
    pTop: styles.pTop,
    pMedium: styles.pMedium,
    pBottom: styles.pBottom,
    modalXs: styles.modalXs,
    modalMd: styles.modalMd,
    modalLg: styles.modalLg,
  }

  return (
    <>
      <div
        className={`${styles.popUp} ${show ? styles.show : ''} ${
          position && MYCLASS[position]
        }`}
      >
        <div
          className={`${styles.popUpWrapper} ${width && MYCLASS[width]} ${
            show ? styles.show : ''
          }`}
        >
          <div className={`${styles.modal}`}>
            <button
              onClick={closeModal}
              title="Close"
              type="button"
              className={styles.btnClose}
            >
              <CancelIcon color="secondary" />
            </button>

            <div className={styles.modalBody}>{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}
Modal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  children: PropTypes.element,
  width: PropTypes.string,
  position: PropTypes.string,
}
