import React, { useState } from 'react'
import { Backdrop as MuiBackdrop } from '@material-ui/core'
import Display from '../display/Display'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

export default function Backdrop(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(true)

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <MuiBackdrop className={classes.backdrop} open={open} onClick={handleClose}>
            <Display.Icons.CircularProgressIcon />
        </MuiBackdrop>
    )
}