import React from 'react'
import MuiButton from "@material-ui/core/Button"
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform: 'none',
        fontSize: '18px',
    },
    primary: {
        color: '#ffffff',
        backgroundColor: '#85BF02',
        '&:hover': {
            backgroundColor: '#9fcc23',
            color: '#ffffff'
        }
    },
    primaryLight: {
        border: '0px solid transparent !important',
        boxShadow: 'none !important',
        color: '#3A50D3',
        backgroundColor: '#F0F7FF',
        '&:hover': {
            backgroundColor: '#F0F7FF',
            border: '1px solid #3A50D3 !important',
            color: '#3A50D3',
            boxShadow: 'none !important'
        }
    },
    secondary: {
        color: '#5D5D5D',
        backgroundColor: '#D9DCDF',
        '&:hover': {
            backgroundColor: '#CECECE',
            color: '#5D5D5D'
        }
    },
    whiteFront: {
        width: '190px',
        height: '50px',
        fontSize: '18px',
        color: '#2435C8',
        backgroundColor: '#f9f9f9',
        '&:hover': {
            backgroundColor: '#f9f9f9',
            color: '#2435C8'
        }
    },
    white: {
        border: '0px solid transparent !important',
        boxShadow: 'none !important',
        color: '#3A50D3',
        backgroundColor: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#f1f1f1',
            color: '#3A50D3',
            boxShadow: 'none !important'
        }
    }
}))

export default function ButtonAction(props) {

    const classes = useStyles()
    const { label, color, variant, startIcon, endIcon, href, ...other } = props

    if (color === 'primary'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                href={href || ""}
                classes={{ root: classes.root, label: classes.label }}
                className={classes.primary}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
    
    if (color === 'primaryLight'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                href={href || ""}
                classes={{ root: classes.root, label: classes.label }}
                className={classes.primaryLight}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
    
    if (color === 'secondary'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                href={href || ""}
                classes={{ root: classes.root, label: classes.label }}
                className={classes.secondary}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }

    if (color === 'whiteFront'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ label: classes.label }}
                className={classes.whiteFront}
                href={href || ""}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
    
    if (color === 'white'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                href={href || ""}
                classes={{ root: classes.root, label: classes.label }}
                className={classes.white}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
}