import * as types from '../types/sidebarType';

const initialState = {
	isOpen: true,
	isLoading: false
};

const sidebarReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.IS_OPEN_SIDEBAR:
			return {
				...state,
				isOpen: payload
			};
		
		case types.IS_LOADING_SIDEBAR:
			return {
				...state,
				isLoading: payload
			};
		
		default:
			return state;
	}
};

export default sidebarReducer;
