import React from 'react';
import Layout from '../../../../components/mui/Layout';
import SearchComponent from './components/SearchComponent';
import TableComponent from './components/TableComponent';
import ModalComponent from './components/ModalComponent';
import PermissionsComponent from './components/PermissionsComponent';

const RolePage = () => {
	
	return (
		<Layout.Page title="Roles">
			<SearchComponent />
			
			<TableComponent />
			
			<ModalComponent />
			
			<PermissionsComponent />
		</Layout.Page>
	);
};

export default RolePage;
