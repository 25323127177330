import React, { useState, forwardRef } from 'react'
import MuiDialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function Dialog(props) {
    const { maxWidth, dialogName, open, title, ...other } = props
    const [fullWidth, setFullWidth] = useState(true)

    return (
        <MuiDialog
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={Transition}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            aria-labelledby={dialogName}
            open={open}
            {...other}
        >
            <DialogTitle id={dialogName}>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                {props.children}
            </DialogContent>
        </MuiDialog>
    )
}