import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Inputs from '../../../../../components/mui/Inputs';
import Layout from '../../../../../components/mui/Layout';
import Feedback from '../../../../../components/mui/Feedback';
import DropdownSelectRole from '../../RolePage/components/DropdownSelect';
import DropdownSelectRealState from '../../RealStatePage/components/DropdownSelect';
import { setStore, getShowById, setUpdateById } from '../services';
import { setFormData, setIsLoadingShow, setIsLoadingForm, setRefreshTable, setIsOpenModal } from '../redux/actions/userAction';
import usePermission from '../../../../../hooks/usePermission';
import swalErrors from '../../../../../hooks/useErrors';
import { swalSuccess } from '../../../../../hooks/useSweetAlert';
import { initialValuesUser as initialValues } from '../../../../../helpers/variablesInitialValues';
import { typeRoles } from '../../../../../helpers/variablesColumns';

const FormComponent = () => {
	const dispatch = useDispatch();
	const refreshTable = useSelector( ({ user }) => user.refreshTable);
	const isLoadingShow = useSelector( ({ user }) => user.isLoadingShow);
	const isLoadingForm = useSelector( ({ user }) => user.isLoadingForm);
	const formType = useSelector( ({ user }) => user.formType);
	const formData = useSelector( ({ user }) => user.formData);
	const [hasRoleAdviser, setHasRoleAdviser] = useState(false);
	const permissionStore = usePermission('users.store');
	const permissionShow = usePermission('users.show');
	const permissionUpdate = usePermission('users.update');
	
	/**
	 *
	 * @description Execute endpoint show by id
	 * @return dispatch
	 *
	 */
	const handleShowById = async () => {
		if (formType === 'show' && permissionShow) {
			await dispatch( setIsLoadingShow(true) );
			
			try {
				const { data } = await getShowById(formData.id);
				
				if (data.status === 200) {
					const object = {
						id: data.data.id,
						firstName: data.data.first_name === null ? '' : data.data.first_name,
						lastName: data.data.last_name === null ? '' : data.data.last_name,
						email: data.data.email === null ? '' : data.data.email,
						mobile: data.data.mobile === null ? '' : data.data.mobile,
						phone: data.data.phone === null ? '' : data.data.phone,
						address: data.data.address === null ? '' : data.data.address,
						realState: data.data.realstate_id === null ? '' : data.data.realstate_id,
						role: data.data.role_id === null ? '' : data.data.role_id,
						typeRole: data.data.type === null ? '' : data.data.type,
						permissions: data.data.permissions.length === 0 ? [] : data.data.permissions,
					};
					
					setHasRoleAdviser(data.data.type === null ? false : true);
					await dispatch( setFormData(object) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingShow(false) );
		}
	};
	
	useEffect(() => {
		handleShowById();
	}, [formType]);
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => {
		if (formType === 'store') {
			return Yup.object({
				firstName: Yup.string()
				.required('El nombre es requerido'),
				lastName: Yup.string()
				.required('El apellido es requerido'),
				email: Yup.string()
				.email('El email no es válido')
				.required('El email es requerido'),
				mobile: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
				.required('El celular es requerido'),
				password: Yup.string()
				.min(6, 'La contraseña debe contener al menos 6 caracteres')
				.required('La contraseña es requerida'),
				passwordConfirm: Yup.string()
				.min(6, 'La confirmación de la contraseña debe contener al menos 6 caracteres')
				.required('La confirmación de la contraseña es requerida')
				.oneOf([Yup.ref('password'), null], 'La confirmación debe ser igual a la contraseña'),
				role: Yup.string()
				.required('El rol es requerido')
			})
		}
		
		return Yup.object({
			firstName: Yup.string()
			.required('El nombre es requerido'),
			lastName: Yup.string()
			.required('El apellido es requerido'),
			email: Yup.string()
			.email('El email no es válido')
			.required('El email es requerido'),
			mobile: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
			.required('El celular es requerido'),
			role: Yup.string()
			.required('El rol es requerido')
		})
	}
	
	/**
	 *
	 * @description Execute submit of the form
	 * @param values
	 * @param resetForm
	 * @param setValues
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		if (formType === 'store' ? permissionStore : permissionUpdate) {
			await dispatch( setIsLoadingForm(true) );
			
			try {
				const param = {
					first_name: formData.firstName === '' ? null : formData.firstName,
					last_name: formData.lastName === '' ? null : formData.lastName,
					email: formData.email === '' ? null : formData.email,
					password: formData.password === '' ? null : formData.password,
					password_confirm: formData.passwordConfirm === '' ? null : formData.passwordConfirm,
					mobile: formData.mobile === '' ? null : formData.mobile,
					phone: formData.phone === '' ? null : formData.phone,
					address: formData.address === '' ? null : formData.address,
					realstate: formData.realState === '' ? null : formData.realState,
					role: formData.role === '' ? null : formData.role,
					type: formData.typeRole === '' ? null : formData.typeRole
				}
				
				let data;
				
				if (formType === 'store') {
					data = await dispatch( setStore(param) );
				} else if (formType === 'show') {
					data = await dispatch( setUpdateById(formData.id, param) );
				}
				
				if (data.data.status === 200) {
					resetForm({ values: initialValues});
					setValues(initialValues);
					await swalSuccess('Captación exitosa', data.data.data);
					await dispatch( setFormData(initialValues) );
					await dispatch( setRefreshTable(!refreshTable) );
					await dispatch( setIsOpenModal(false) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingForm(false) );
		}
	}
	
	/**
	 *
	 * @description Execute dispatch formData state
	 * @return dispatch
	 *
	 */
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.value }) )
	
	return (
		<>
			{isLoadingShow && (
				<Feedback.Loading />
			)}
			{!isLoadingShow && (
				<Formik
					initialValues={formData}
					validationSchema={validationSchema}
					onSubmit={hanbleSubmit}
				>
					{({
					  errors,
					  touched,
					  handleChange,
					  handleBlur,
					  isValid,
					  dirty
				  }) => (
						<Form noValidate>
							<div className="row animate__animated animate__fadeIn">
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										name="firstName"
										label="Nombre"
										placeholder="Ingrese nombre..."
										value={formData.firstName}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.firstName && touched.firstName ? true : false}
										errorMessage={errors.firstName && touched.firstName ? errors.firstName : ''}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										name="lastName"
										label="Apellido"
										placeholder="Ingrese apellido..."
										value={formData.lastName}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.lastName && touched.lastName ? true : false}
										errorMessage={errors.lastName && touched.lastName ? errors.lastName : ''}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										type="email"
										name="email"
										label="Email"
										placeholder="Ingrese email..."
										value={formData.email}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.email && touched.email ? true : false}
										errorMessage={errors.email && touched.email ? errors.email : ''}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										type="text"
										name="mobile"
										label="Celular"
										placeholder="Ingrese celular..."
										value={formData.mobile}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.mobile && touched.mobile ? true : false}
										errorMessage={errors.mobile && touched.mobile ? errors.mobile : ''}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
									/>
								</div>
								{formType === 'store' && (
									<>
										<div className="col-sm-12 col-md-6">
											<Inputs.TextBoxPassword
												name="password"
												label="Contraseña"
												placeholder="Ingrese contraseña..."
												value={formData.password}
												onChange={e => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.password && touched.password ? true : false}
												errorMessage={errors.password && touched.password ? errors.password : ''}
												className="font-family-roboto-medium text-dark fs-5 mt-3 w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-6">
											<Inputs.TextBoxPassword
												name="passwordConfirm"
												label="Confirmar contraseña"
												placeholder="Ingrese confirmación..."
												value={formData.passwordConfirm}
												onChange={e => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.passwordConfirm && touched.passwordConfirm ? true : false}
												errorMessage={errors.passwordConfirm && touched.passwordConfirm ? errors.passwordConfirm : ''}
												className="font-family-roboto-medium text-dark fs-5 mt-3 w-100"
											/>
										</div>
									</>
								)}
								
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										type="text"
										name="phone"
										label="Teléfono"
										placeholder="Ingrese teléfono..."
										value={formData.phone}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6">
									<Inputs.TextArea
										name="address"
										label="Dirección"
										placeholder="Ingrese dirección..."
										value={formData.address}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6">
									<DropdownSelectRole
										name="role"
										value={formData.role}
										handleChange={e => {
											setHasRoleAdviser(e.target.value === 4 ? true : false)
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.role && touched.role ? true : false}
										errorMessage={errors.role && touched.role ? errors.role : ''}
										className="font-family-roboto-regular fs-6 text-dark mt-3 w-100"
									/>
								</div>
								
								{hasRoleAdviser && (
									<div className="col-sm-12 col-md-6 animate__animated animate__fadeIn">
										<Inputs.Select
											label="Tipo de Role"
											name="typeRole"
											value={formData.typeRole}
											itemsArray={typeRoles}
											onChange={handleOnChange}
											className="font-family-roboto-regular fs-6 text-dark mt-3 w-100"
										/>
									</div>
								)}
								

								<div className="col-sm-12 col-md-6">
									<DropdownSelectRealState
										name="realState"
										value={formData.realState}
										handleChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark mt-3 w-100"
									/>
								</div>
								
								{(formType === 'store' ? permissionStore : permissionUpdate) && (
									<div className="col-sm-12">
										<Inputs.LoadingButton
											isDisabled={formType === 'store' ? !(dirty && isValid) : false}
											isLoading={isLoadingForm}
											isLoadingPosition="start"
											startIcon={formType === 'store' ? <Layout.Icons.SaveIcon height={18} width={18} /> : <Layout.Icons.EditIcon height={18} width={18} />}
											label={`${formType === 'store' ? 'Guardar' : 'Actualizar'}`}
											type="submit"
											className={`${formType === 'store' ? ((dirty && isValid) && 'bg-primary') : 'bg-primary'} font-family-roboto-regular fs-6 text-capitalize mt-2 d-flex float-end`}
										/>
									</div>
								)}
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default FormComponent;
