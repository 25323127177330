export const PER_PAGE_PROPERTY_TYPE = 'PER_PAGE_PROPERTY_TYPE';
export const PAGE_PROPERTY_TYPE = 'PAGE_PROPERTY_TYPE';
export const SEARCH_PROPERTY_TYPE = 'SEARCH_PROPERTY_TYPE';
export const PAGES_PROPERTY_TYPE = 'PAGES_PROPERTY_TYPE';
export const ARRAY_LIST_PROPERTY_TYPE = 'ARRAY_LIST_PROPERTY_TYPE';
export const ARRAY_LIST_TO_DROPDOWN_PROPERTY_TYPE = 'ARRAY_LIST_TO_DROPDOWN_PROPERTY_TYPE';
export const REFRESH_TABLE_PROPERTY_TYPE = 'REFRESH_TABLE_PROPERTY_TYPE';
export const IS_OPEN_MODAL_PROPERTY_TYPE = 'IS_OPEN_MODAL_PROPERTY_TYPE';
export const IS_LOADING_SHOW_PROPERTY_TYPE = 'IS_LOADING_SHOW_PROPERTY_TYPE';
export const IS_LOADING_FORM_PROPERTY_TYPE = 'IS_LOADING_FORM_PROPERTY_TYPE';
export const FORM_TYPE_PROPERTY_TYPE = 'FORM_TYPE_PROPERTY_TYPE';
export const FORM_DATA_PROPERTY_TYPE = 'FORM_DATA_PROPERTY_TYPE';
