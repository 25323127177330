import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImageList from '@mui/material/ImageList'
import Feedback from '../../../../../../components/mui/Feedback'
import ImageListItemComponent from './ImageListItemComponent'

const ImageListComponent = ({ isLoading, arrayList = [] }) => {
	
	useEffect(() => {
	}, [isLoading, arrayList])
	
	return (
		<>
			{!isLoading && (
				arrayList.length > 0 && (
					<Feedback.ScrollBar style={{ height: 200, overflowX: 'hidden' }}>
						<ImageList
							cols={3}
							rowHeight={200}
							className="w-100 h-100 overflow-hidden"
						>
							{arrayList.map(({ name, path }, number) => (
								<ImageListItemComponent
									key={number}
									name={name}
									path={path}
								/>
							))}
						</ImageList>
					</Feedback.ScrollBar>
				)
			)}
		</>
	)
}

ImageListComponent.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	arrayList: PropTypes.array
}

export default ImageListComponent
