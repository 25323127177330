import React from 'react'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'

export default function KeyboardArrowUpIcon(props) {
    const { ...other } = props

    return (
        <KeyboardArrowUp {...other} />
    )
}
