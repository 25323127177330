export const PER_PAGE_COUNTRY = 'PER_PAGE_COUNTRY';
export const PAGE_COUNTRY = 'PAGE_COUNTRY';
export const SEARCH_COUNTRY = 'SEARCH_COUNTRY';
export const PAGES_COUNTRY = 'PAGES_COUNTRY';
export const ARRAY_LIST_COUNTRY = 'ARRAY_LIST_COUNTRY';
export const ARRAY_LIST_TO_DROPDOWN_COUNTRY = 'ARRAY_LIST_TO_DROPDOWN_COUNTRY';
export const REFRESH_TABLE_COUNTRY = 'REFRESH_TABLE_COUNTRY';
export const IS_OPEN_MODAL_COUNTRY = 'IS_OPEN_MODAL_COUNTRY';
export const IS_LOADING_SHOW_COUNTRY = 'IS_LOADING_SHOW_COUNTRY';
export const IS_LOADING_FORM_COUNTRY = 'IS_LOADING_FORM_COUNTRY';
export const FORM_TYPE_COUNTRY = 'FORM_TYPE_COUNTRY';
export const FORM_DATA_COUNTRY = 'FORM_DATA_COUNTRY';
