import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import DropdownStatusCommercial from './DropdownStatusCommercial'
import FileUpload from './FileUpload'
import ModalOriginList from './ModalOriginList'
import ModalTabs from './ModalTabs'
import Divider from '@material-ui/core/Divider'
import Layout from '../../../../../components/mui/Layout'
import Display from '../../../../../components/display/Display'
import Feedback from '../../../../../components/feedback/Feedback'
import { history } from '../../../../../redux/store'
import errorServices from '../../../../../services/errors'
import { swalSuccess } from '../../../../../services/sweetalert'
import axios from '../../../../../services/axios'
import photo from '../../../../../assets/images/icons/photo.png'
import photo360 from '../../../../../assets/images/icons/photo360.png'
import video from '../../../../../assets/images/icons/video.png'
import area from '../../../../../assets/images/icons/area_green.png'
import room from '../../../../../assets/images/icons/room_green.png'
import bathroom from '../../../../../assets/images/icons/bathroom_green.png'
import garage from '../../../../../assets/images/icons/garage_green.png'
import './PropertyPage.scss'

import {AssetsProvider} from './NewAssetComponets/AssetsContext'

const PropertyRow = ({ id, name, path, has_photo, has_photo360, has_video, code, domus_code, address, text_status, color_status, action_type, action_canon_string, action_sale_string, type, country, city, neighborhood, area_total, number_room, number_bathroom, number_garage, handleList, property_id }) => {
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [getLoading, setLoading] = useState(false)
	const [getTitleModalFileUpload, setTitleModalFileUpload] = useState('')
	const [getOpenModalFileUpload, setOpenModalFileUpload] = useState(false)
	const [getTitleModalFilePreview, setTitleModalFilePreview] = useState('')
	const [getOpenModalFilePreview, setOpenModalFilePreview] = useState(false)
	const [getTitleModalCaptation, setTitleModalCaptation] = useState('')
	const [getOpenModalCaptation, setOpenModalCaptation] = useState(false)
	
	const handleFirstCase = (value) => {
		return value.charAt(0)
	}
	
	const handleSubTitle = (type, canon_string, sale_string) => {
		return (
			<>
				{type === 'Arriendo/Venta' && (`$ ${canon_string} / $ ${sale_string}`)}
				{type === 'Arriendo' && (`$ ${canon_string}`)}
				{type === 'Venta' && (`$ ${sale_string}`)}
			</>
		)
	}
	
	useEffect(() => {
	}, [domus_code])
	
	const handleModalFileUpload = (domus_code) => {
		setOpenModalFileUpload(true)
		setTitleModalFileUpload(`Cargar archivos (${domus_code === null ? '' : domus_code})`)
	}
	
	const handleModalFilePreview = (domus_code, add) => {
		setOpenModalFilePreview(true)
		setTitleModalFilePreview(`${add === null ? '' : add} (${domus_code === null ? '' : domus_code})`)
	}
	
	const handleModalCaptation = (domus_code) => {
		setOpenModalCaptation(true)
		setTitleModalCaptation(`Captación (${domus_code === null ? '' : domus_code})`)
	}
	
	const handleStoreDomus = async (id) => {
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/properties/domus/store-on-domus/${id}`)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				handleList()
			}
		} catch (error) {
			await errorServices(error)
		}

		setLoading(false)
	}
	
	const handleShow = async () => await history.push(`/dashboard/propiedades/edificacion/${id}`)
	
	const handleDetail = async () => await history.push(`/dashboard/propiedades/detalle/${id}`)
	
	return (
		<>
			<Grid item xs={12} sm={6} md={4} lg={3}>
				<Card className="border-radius-7 box-shadox-card">
					<CardHeader
						className="float-left w-100 height-60"
						avatar={
							<Display.Tooltip
								label={text_status}
								placement="top"
							>
								<Avatar aria-label="recipe" style={{backgroundColor: color_status }}>
									{handleFirstCase(text_status)}
								</Avatar>
							</Display.Tooltip>
						}
						title={<div className="font-family-roboto-medium font-size-16 text-dark">{action_type}</div>}
						subheader={<div className="font-family-roboto-regular font-size-18 text-grey">{handleSubTitle(action_type, action_canon_string, action_sale_string)}</div>}
						action={
							<DropdownStatusCommercial
								property_id={id}
								handleList={handleList}
							/>
						}
					/>
					<CardMedia
						className="float-left w-100 height-250 object-fit-cover position-relative"
						title={name}
					>
						{path === null && (
							<div className="card-media color-dark h-100 w-100">
								<div className="div-text position-relative m-auto text-center">
									<strong>{name}</strong>
								</div>
							</div>
						)}
						{path !== null && (
							<img alt={name} className="position-relative h-100 w-100 object-fit-cover" src={path} />
						)}
						<div className="float-left position-relative bottom-100 z-1000 w-100">
							<div className="float-left position-relative d-inline w-50 height-40">
								{(has_photo || has_photo360 || has_video) && (
									<div className="m-2 p-2 bg-green-light border-radius-7" style={{
										alignContent:'center',
										display:'flex',
										width:'min-content',
										gap:'8px',
									}}>
										{has_photo && (
											<Display.Tooltip
												label="Fotos"
												placement="top"
											>
												<img alt="" className="mx-auto align-items-center d-flex flex-column" width="13" height="12" src={photo} />
											</Display.Tooltip>
										)}
										{has_photo360 && (
											<Display.Tooltip
												label="Foto360"
												placement="top"
											>
												<img alt="" className="mx-auto align-items-center d-flex flex-column" width="13" height="12" src={photo360} />
											</Display.Tooltip>
										)}
										{has_video && (
											<Display.Tooltip
												label="Videos"
												placement="top"
											>
												<img alt="" className="mx-auto align-items-center d-flex flex-column" width="13" height="12" src={video} />
											</Display.Tooltip>
										)}
									</div>
								)}
							</div>
						</div>
						{(domus_code !== null) && (
							<Display.Tooltip label="Código Domus" placement="top">
								<div style={{
									position:"absolute",
									left:10,
									bottom:10,
								}} className='p-1 bg-green-light border-radius-5 font-family-roboto-medium font-size-12'>
									{'Domus:' + domus_code}
								</div>
							</Display.Tooltip>
						)}
						{(code !== null) && (
							<div style={{
								position:"absolute",
								right:10,
								bottom:10,
							}} className='p-1 bg-green-light border-radius-5 font-family-roboto-medium font-size-12'>
								{code}
							</div>
						)}
					</CardMedia>
					<CardContent className="float-left w-100 height-130 m-0 p-2">
						<Grid container spacing={1}>
							<Grid item xs={12} className="ml-2 mb-2 mt-1">
								<div className="font-family-roboto-medium font-size-18 text-dark text-capitalize text-truncate">
									{type === '' ? '' : `${type}, `}{neighborhood === '' ? '' : neighborhood}
								</div>
								<div className="font-family-roboto-regular font-size-16 text-grey text-capitalize">
									{city === '' ? '': `${city}, `}{country === '' ? '' : country}
								</div>
							</Grid>
							<Grid item xs={3}>
								<div className="text-center">
									<img alt="" src={area} width="28" height="28"/>
									<div className="font-family-roboto-bold font-size-13 color-dark">{area_total}</div>
								</div>
							</Grid>
							<Grid item xs={3}>
								<div className="text-center">
									<img alt="" src={room} width="28" height="28"/>
									<div className="font-family-roboto-bold font-size-13 color-dark">{number_room}</div>
								</div>
							</Grid>
							<Grid item xs={3}>
								<div className="text-center">
									<img alt="" src={bathroom} width="28" height="28"/>
									<div className="font-family-roboto-bold font-size-13 color-dark">{number_bathroom}</div>
								</div>
							</Grid>
							<Grid item xs={3}>
								<div className="text-center">
									<img alt="" src={garage} width="24" height="24"/>
									<div className="font-family-roboto-bold font-size-13 color-dark">{number_garage}</div>
								</div>
							</Grid>
							<Grid item xs={12} className="mt-1">
								<Divider variant="middle" className="bg-dark" />
							</Grid>
						</Grid>
					</CardContent>
					<CardActions className="w-100 height-60 m-0 p-2" style={{display:'flex', justifyContent:'space-around'}}>
						{/* <div className="position-relative d-inline m-0 ml-2 p-0">
							<Display.Tooltip
								label="Cargar archivos"
								placement="top"
							>
								<IconButton
									className="height-40 width-40"
									onClick={() => handleModalFileUpload(domus_code)}
								>
									<Display.Icons.AttachFileIcon className="text-dark" style={{ fontSize: 20 }} />
								</IconButton>
							</Display.Tooltip>
						</div> */}
						<div className="position-relative d-inline m-0 ml-2 p-0">
							<Display.Tooltip
								label="Visor de archivos"
								placement="top"
							>
								<IconButton
									className="height-40 width-40"
									onClick={() => handleModalFilePreview(domus_code, address)}
								>
									<Display.Icons.Photo className="text-dark" style={{ fontSize: 20 }} />
								</IconButton>
							</Display.Tooltip>
						</div>
						<div className="position-relative d-inline m-0 ml-2 p-0">
							<Display.Tooltip
								label="Captación"
								placement="top"
							>
								<IconButton
									className="height-40 width-40"
									onClick={() => handleModalCaptation(domus_code)}
								>
									<Display.Icons.PersonOutlinedIcon className="text-dark" style={{ fontSize: 20 }} />
								</IconButton>
							</Display.Tooltip>
						</div>
						<div className="position-relative d-inline m-0 ml-2 p-0">
							<Display.Tooltip
								label="Editar"
								placement="top"
							>
								<IconButton
									className="height-40 width-40"
									onClick={handleShow}
								>
									<Display.Icons.EditOutlinedIcon className="text-dark" style={{ fontSize: 20 }} />
								</IconButton>
							</Display.Tooltip>
						</div>
						<div className="position-relative d-inline m-0 ml-2 p-0">
							<Display.Tooltip
								label="Detalle"
								placement="top"
							>
								<IconButton
									className="height-40 width-40"
									onClick={handleDetail}
								>
									<Layout.Icons.VisibilityIcon className="text-dark" height={18} width={18} />
								</IconButton>
							</Display.Tooltip>
						</div>
						<div className="position-relative d-inline m-0 ml-2 p-0">
							<Display.Tooltip
								label={domus_code === null ? 'Registrar en Domus' : 'Actualizar en Domus'}
								placement="top"
							>
								<IconButton
									className="height-40 width-40"
									onClick={() => handleStoreDomus(id)}
								>
									<Display.Icons.SendOutlinedIcon className="text-dark" style={{ fontSize: 20 }} />
								</IconButton>
							</Display.Tooltip>
						</div>
					</CardActions>
				</Card>
			</Grid>
			<FileUpload
				has_origin={true}
				getPropertyID={id}
				handleList={handleList}
				getTitleModal={getTitleModalFileUpload}
				getOpenModal={getOpenModalFileUpload}
				setOpenModal={setOpenModalFileUpload}
			/>
			<AssetsProvider>
				<ModalOriginList
					getPropertyID={id}
					property_id={property_id}
					getMobiliCode={code}
					getTitleModal={getTitleModalFilePreview}
					getOpenModal={getOpenModalFilePreview}
					setOpenModal={setOpenModalFilePreview}
					handleList={handleList}
				/>
			</AssetsProvider>
			<ModalTabs
				getPropertyID={id}
				getTitleModal={getTitleModalCaptation}
				getOpenModal={getOpenModalCaptation}
				setOpenModal={setOpenModalCaptation}
			/>
			<Feedback.DialogLoading
				getLoading={getLoading}
				setLoading={setLoading}
			/>
		</>
	)
}

export default PropertyRow
