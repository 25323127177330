import React from 'react'
import BarChart from '@material-ui/icons/BarChart'

export default function BarChartIcon(props) {

    const { ...other } = props

    return (
        <BarChart { ...other } />
    )
}
