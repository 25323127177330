import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DropdownActionComponent from './DropdownActionComponent';
import { setIsOpenModal, setFormData, setFormType, setIsLoadingShow, setIsLoadingForm } from '../redux/actions/permissionAction';
import { setActivityLogReset, setIsOpenModal as setIsOpenModalActivity, setLogName, setSubjectId } from '../../../../../redux/actions/dashboard/activities/activityLogAction';

const TableRowComponent = ({ id, group, name, publicName, description }) => {
	const dispatch = useDispatch();
	
	useEffect(() => {
	}, [id, group, name, publicName, description]);
	
	/**
	 *
	 * @description Execute dispatch open modal, reset formData, loading show, loading form, formType and activityLog state
	 * @return dispatch
	 *
	 */
	const handleIsOpenModal = async (formType) => {
		if (formType === 'activity-log') {
			await dispatch( setActivityLogReset() );
			await dispatch( setLogName('Permisos') );
			await dispatch( setSubjectId(id) );
			await dispatch( setIsOpenModalActivity(true) );
		} else {
			await dispatch( setFormData({ id: id }) );
			await dispatch( setFormType(formType) );
			await dispatch( setIsLoadingShow(false) );
			await dispatch( setIsLoadingForm(false) );
			await dispatch( setIsOpenModal(true) );
		}
	};
	
	return (
		<tr className="m-0 p-0">
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="15%"
			>
				<div className="font-family-roboto-regular fs-6 text-dark text-center">
					{ group }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="25%"
			>
				<div className="font-family-roboto-regular fs-6 text-dark">
					{ name }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="25%"
			>
				<div className="font-family-roboto-regular fs-6 text-dark">
					{ publicName }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="30%"
			>
				<div className="font-family-roboto-regular fs-6 text-dark">
					{ description }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="5%"
			>
				<div className="mx-auto d-flex justify-content-center">
					<DropdownActionComponent handleIsOpenModal={handleIsOpenModal} />
				</div>
			</td>
		</tr>
	);
};

TableRowComponent.propTypes = {
	group: PropTypes.string,
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	publicName: PropTypes.string,
	description: PropTypes.string
};

export default TableRowComponent;
