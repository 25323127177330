import Box from "@mui/material/Box";
import { styled } from "@mui/material";

const commomOptionTypes = {
  flexGrow: 1,
  display: "flex",
  cursor: "pointer",
  minHeight: "auto",
  userSelect: "none",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "inherit",
};
export const OptionType1 = styled(Box)(({ theme, active }) => ({
  padding: theme.spacing(5 / 8, 10 / 8),
  ...commomOptionTypes,
  minHeight: "40px",
  fontFamily: theme.typography.fontFamily,
  transition: theme.transitions.create(["background-color"], {
    duration: theme.transitions.duration.standard,
  }),
  ...(active && {
    fontWeight: 700,
    color: theme.palette.white.default,
    backgroundColor: theme.palette.darkGrey.default,
    borderRadius: "2px",
  }),
}));

export const OptionType2 = styled(Box)(({ theme, active }) => ({
  padding: theme.spacing(5 / 8, 10 / 8),
  ...commomOptionTypes,
  backgroundColor: theme.palette.common.white,
  fontFamily: theme.typography.fontFamily,
  transition: theme.transitions.create(["background-color"], {
    duration: theme.transitions.duration.standard,
  }),
  ...(active && {
    fontWeight: 700,
    color: theme.palette.white.default,
    backgroundColor: theme.palette.dark.default,
    borderRadius: "2px",
  }),
}));
