import React from 'react';
import './LoaderGallery.css';

const LoaderGallery = () => {
	return (
		<div className='loaderGallery'>
			<div className='lds-ellipsis'>
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
};

export default LoaderGallery;
