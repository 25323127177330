export const PER_PAGE_PROJECT = 'PER_PAGE_PROJECT';
export const PAGE_PROJECT = 'PAGE_PROJECT';
export const SEARCH_PROJECT = 'SEARCH_PROJECT';
export const REALSTATE_PROJECT = 'REALSTATE_PROJECT';
export const PAGES_PROJECT = 'PAGES_PROJECT';
export const ARRAY_LIST_PROJECT = 'ARRAY_LIST_PROJECT';
export const ARRAY_LIST_TO_DROPDOWN_PROJECT = 'ARRAY_LIST_TO_DROPDOWN_PROJECT';
export const REFRESH_TABLE_PROJECT = 'REFRESH_TABLE_PROJECT';
export const IS_OPEN_MODAL_PROJECT = 'IS_OPEN_MODAL_PROJECT';
export const IS_LOADING_SHOW_PROJECT = 'IS_LOADING_SHOW_PROJECT';
export const IS_LOADING_FORM_PROJECT = 'IS_LOADING_FORM_PROJECT';
export const FORM_TYPE_PROJECT = 'FORM_TYPE_PROJECT';
export const FORM_DATA_PROJECT = 'FORM_DATA_PROJECT';
