import * as types from '../../../actionsTypes/algolia/algoliaNeighborhoodTypes'
import { getArrayToDropdown } from './services'
import errorServices from '../../../../services/errors'

export const getArrayToDropdownNeighborhood = param => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getArrayToDropdown(param).then(({ data }) => {
			dispatch( setArrayListToDropdown(data.data) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const setAlgoliaNeighborhoodReset = () => (
	async (dispatch) => {
		await dispatch( setSearch('') )
		await dispatch( setArrayListToDropdown([]) )
		await dispatch( setIsLoading(false) )
	}
)

export const setSearch = value => ({
	type: types.SEARCH_NEIGHBORHOOD,
	payload: value
})

export const setArrayListToDropdown = value => ({
	type: types.ARRAY_LIST_TO_DROPDOWN_ALGOLIA_NEIGHBORHOOD,
	payload: value
})

export const setIsLoading = value => ({
	type: types.IS_LOADING_ALGOLIA_NEIGHBORHOOD,
	payload: value
})
