import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ImageListItem from '@mui/material/ImageListItem'

const ImageListItemComponent = ({ name, path }) => {
	
	useEffect(() => {
	}, [name, path])
	
	return (
		<ImageListItem>
			<img
				src={path}
	      alt={name}
	      loading="lazy"
			/>
		</ImageListItem>
	)
}

ImageListItemComponent.propTypes = {
	name: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired
}

export default ImageListItemComponent
