import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import Display from "../display/Display"
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    position: 'relative',
    height: '100%',
    textAlign: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    },
    '& .MuiCircularProgress-colorPrimary' :{
      color: '#85BF02'
    }
  }
}))

export default function Loading(props) {
  const classes = useStyles()
  const { type, animation, width, height, ...other } = props

  if (type === 'spin') {
    return (
      <div className={classes.root}>
        <Display.Icons.CircularProgressIcon {...other} />
      </div>
    )
  } else {
    return (
      <Skeleton variant={type} animation={animation} width={width} height={height} {...other} />
    )
  }
}
