import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Check from '@mui/icons-material/Check';

import { useAssets } from './AssetsContext'

export default function SelectAssetOrigin(){
  
  const { selectOrigin, origin } = useAssets();

  return (
    <Stack flexDirection="row" gap={2}>
      {['Captacion', 'Inventario', 'Reparacion', 'Desocupacion'].map(function (
        label,
      ) {
        const selected = origin === label
        return (
          <Box
            key={label}
            gap={1}
            display="flex"
            alignItems="center"
            sx={{
              cursor: 'pointer',
              border: '1px solid #C5C5C5C5',
              borderRadius: '8px',
              padding: '0px 4px',
              span: {
                fontSize: '14px',
              },
              transition: 'all ease-in-out .2s',
            }}
            onClick={() => {
              selectOrigin(label)
            }}
          >
            <span>{label}</span>
            <Box
              sx={{
                display: selected ? 'block' : 'none',
                '.MuiSvgIcon-root': { fontSize: '1rem' },
              }}
            >
              <Check fontSize="12px" />
            </Box>
          </Box>
        )
      })}
    </Stack>
  )
}