import React from 'react'
import ChevronLeft from '@material-ui/icons/ChevronLeft'

export default function ChevronLeftIcon(props) {

    const { ...other } = props

    return (
        <ChevronLeft {...other} />
    )
}