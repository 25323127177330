import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../../../../../components/mui/Layout'
import Inputs from '../../../../../../components/mui/Inputs'
import { getPropertyDomusShowByCode, setSearch, setPropertyDomusReset } from '../../../../../../redux/actions/dashboard/settings/properties/domus/propertyDomusAction'
import { getPropertyMobiliShowByCode, setPropertyMobiliStore } from '../../../../../../redux/actions/dashboard/settings/properties/mobili/propertyMobiliAction'
import { Permission } from '../../../../../../services/permissions/Permission'

const SearchComponent = () => {
	const dispatch = useDispatch()
	const isActiveButton = useSelector( state => state.propertyMobili.isActiveButton)
	const isLoadingButton = useSelector( state => state.propertyMobili.isLoadingButton)
	const eventType = useSelector( state => state.propertyMobili.eventType)
	const id = useSelector( state => state.propertyMobili.formData.id)
	const search = useSelector( state => state.propertyDomus.search)
	const formData = useSelector( state => state.propertyDomus.formData)
	const arrayListAmenities = useSelector( state => state.propertyDomus.arrayListAmenities)
	const arrayListImages = useSelector( state => state.propertyDomus.arrayListImages)
	const arrayListImages360 = useSelector( state => state.propertyDomus.arrayListImages360)
	const arrayListVideos = useSelector( state => state.propertyDomus.arrayListVideos)
	const arrayListTours = useSelector( state => state.propertyDomus.arrayListTours)
	
	const {
		codeDomus,
		statusCommercialId,
		statusCommercialName,
		registerDate,
		updateDate,
		publicationDate,
		consignationDate,
		actionId,
		actionName,
		valueAdmin,
		canon,
		saleValue,
		commissionPercentage,
		stateId,
		stateName,
		cityId,
		cityName,
		neighborhoodId,
		neighborhoodName,
		zoneId,
		zoneName,
		address,
		reference,
		comment,
		latitude,
		longitude,
		numberRoom,
		numberBathroom,
		numberGarage,
		strata,
		enrollment,
		yearConstruction,
		areaConstruction,
		areaTotal,
		areaFront,
		areaBackground,
		typeId,
		typeName,
		destinationId,
		destinationName
	} = formData
	
	const getSearchByCode = async () => {
		if (search === '') {
			handleClear()
		} else {
			await dispatch( getPropertyDomusShowByCode(search) )
			await dispatch( getPropertyMobiliShowByCode(search) )
		}
	}
	
	const handleSubmit = async () => {
		const param = {
			event_type: eventType,
			id: id === '' || id === null ? null : id,
			register_date: registerDate === '' || registerDate === null ? null : registerDate,
			update_date: updateDate === '' || updateDate === null ? null : updateDate,
			publication_date: publicationDate === '' || publicationDate === null ? null : publicationDate,
			consignation_date: consignationDate === '' || consignationDate === null ? null : consignationDate,
			domus_code: codeDomus === '' || codeDomus === null ? null : codeDomus,
			address: address === '' || address === null ? null : address,
			reference: reference === '' || reference === null ? null : reference,
			comment: comment === '' || comment === null ? null : comment,
			latitude: latitude === '' || latitude === null ? null : latitude,
			longitude: longitude === '' || longitude === null ? null : longitude,
			number_room: numberRoom === '' || numberRoom === null ? null : numberRoom,
			number_bathroom: numberBathroom === '' || numberBathroom === null ? null : numberBathroom,
			number_garage: numberGarage === '' || numberGarage === null ? null : numberGarage,
			strata: strata === '' || strata === null ? null : strata,
			enrollment: enrollment === '' || enrollment === null ? null : enrollment,
			year_construction: yearConstruction === '' || yearConstruction === null ? null : yearConstruction,
			area_construction: areaConstruction === '' || areaConstruction === null ? null : areaConstruction,
			area_total: areaTotal === '' || areaTotal === null ? null : areaTotal,
			area_front: areaFront === '' || areaFront === null ? null : areaFront,
			area_background: areaBackground === '' || areaBackground === null ? null : areaBackground,
			state_id: stateId === '' || stateId === null ? null : stateId,
			state: stateName === '' || stateName === null ? null : stateName,
			city_id: cityId === '' || cityId === null ? null : cityId,
			city: cityName === '' || cityName === null ? null : cityName,
			neighborhood_id: neighborhoodId === '' || neighborhoodId === null ? null : neighborhoodId,
			neighborhood: neighborhoodName === '' || neighborhoodName === null ? null : neighborhoodName,
			zone_id: zoneId === '' || zoneId === null ? null : zoneId,
			zone: zoneName === '' || zoneName === null ? null : zoneName,
			type_id: typeId === '' || typeId === null ? null : typeId,
			type: typeName === '' || typeName === null ? null : typeName,
			destination_id: destinationId === '' || destinationId === null ? null : destinationId,
			destination: destinationName === '' || destinationName === null ? null : destinationName,
			status_commercial_id: statusCommercialId === '' || statusCommercialId === null ? null : statusCommercialId,
			status_commercial: statusCommercialName === '' || statusCommercialName === null ? null : statusCommercialName,
			action_id: actionId === '' || actionId === null ? null : actionId,
			action: actionName === '' || actionName === null ? null : actionName,
			value_admin: valueAdmin === '' || valueAdmin === null ? null : valueAdmin,
			canon: canon === '' || canon === null ? null : canon,
			sale_value: saleValue === '' || saleValue === null ? null : saleValue,
			commission_percentage: commissionPercentage === '' || commissionPercentage === null ? null : commissionPercentage,
			amenities: arrayListAmenities.length === 0 ? [] : arrayListAmenities,
			images: arrayListImages.length === 0 ? [] : arrayListImages,
			images360: arrayListImages360.length === 0 ? [] : arrayListImages360,
			videos: arrayListVideos.length === 0 ? [] : arrayListVideos,
			tours: arrayListTours.length === 0 ? [] : arrayListTours,
		}
		
		await dispatch( setPropertyMobiliStore(param) )
	}
	
	const handleClear = () => dispatch( setPropertyDomusReset() )
	
	const handleChangeSearch = ({ target }) => dispatch( setSearch(target.value) )
	
	const handleKeyPressArrayList = async ({ charCode }) => charCode === 13 && await getSearchByCode()
	
	return (
		<Layout.Card className="border-radius-0 m-0 p-0">
			<Layout.CardContent className="m-0 p-3">
				<div className="row mt-2 mb-0">
					<div className="col-sm-12 col-md-5 col-lg-4 mb-1 d-flex justify-content-start">
						<Permission
							permission="domus.showOnDomus"
						>
							<Inputs.TextBox
								name="search"
								label="Buscar"
								placeholder="Ingrese código domus..."
								value={search}
								onChange={handleChangeSearch}
								onKeyPress={handleKeyPressArrayList}
								className="font-family-roboto-medium text-dark fs-5 w-100"
							/>
						</Permission>
					</div>
					<div className="col-sm-12 col-md-2 col-lg-3"></div>
					<div className="col-sm-12 col-md-5 col-lg-5 d-flex justify-content-end">
						{isActiveButton && (
							<Permission
								permission="domus.storeOnMobili"
							>
								<Inputs.LoadingButton
									isLoading={isLoadingButton}
									isLoadingPosition="start"
									startIcon={<Layout.Icons.SaveIcon width={20} height={20} />}
									label={eventType === 'store' ? 'Guardar' : 'Actualizar'}
									type="button"
									className="font-family-roboto-regular fs-6 bg-primary text-capitalize mr-3 animate__animated animated__fadeIn"
									onClick={handleSubmit}
								/>
							</Permission>
						)}
						<Layout.Tooltip
							title="Limpiar búsqueda"
							placement="top"
						>
							<Layout.IconButton
								color="inherit"
								size="small"
								onClick={handleClear}
								edge="start"
								className="bg-secondary font-roboto-semibold mt-1"
							>
								<Layout.Icons.CloseIcon
									width={18}
									height={18}
									className="font-roboto-semibold text-white"
								/>
							</Layout.IconButton>
						</Layout.Tooltip>
					</div>
				</div>
			</Layout.CardContent>
		</Layout.Card>
	)
}

export default SearchComponent
