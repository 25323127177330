import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../../components/mui/Layout';
import Inputs from '../../../../components/mui/Inputs';
import { setRefreshTable, setFormFilter } from '../redux/actions/commercialAction';
import usePermission from '../../../../hooks/usePermission';
import { initialValuesCommercialFilter as initialValues } from '../../../../helpers/variablesInitialValues';

const SearchComponent = () => {
	const dispatch = useDispatch();
	const formFilter = useSelector( ({ commercial }) => commercial.formFilter);
	const refreshTable = useSelector( ({ commercial })=> commercial.refreshTable);
	const permissionList = usePermission('commercial.list');

	/**
	 *
	 * @description Execute dispatch formFilter state
	 * @return dispatch
	 *
	 */
	const handleOnChange = ({ target }) => dispatch( setFormFilter( { ...formFilter, [target.name]: target.value }) );
	
	/**
	 *
	 * @description Execute dispatch refreshTable state
	 * @return dispatch
	 *
	 */
	const handleKeyPress = ({ charCode }) => charCode === 13 && dispatch( setRefreshTable(!refreshTable) );
	
	/**
	 *
	 * @description Execute dispatch search, refreshTable state
	 * @return dispatch
	 *
	 */
	const handleClear = async () => {
		await dispatch( setFormFilter(initialValues) );
		await dispatch( setRefreshTable(!refreshTable) );
	};
	
	return (
		<Layout.Card className="border-radius-0 m-0 p-0">
			<Layout.CardContent className="m-0 p-3">
				<div className="row mt-2 mb-0">
					<div className="col-sm-12">
						{permissionList && (
							<>
								<Inputs.TextBox
									name="search"
									label="Buscar"
									placeholder="Ingrese la búsqueda..."
									value={formFilter.search}
									onChange={handleOnChange}
									onKeyPress={handleKeyPress}
									className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-300px"
								/>
								<Inputs.TextBox
									type="date"
									name="start"
									label="Desde"
									placeholder="Ingrese la fecha..."
									value={formFilter.start}
									onChange={handleOnChange}
									onKeyPress={handleKeyPress}
									className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-150px"
								/>
								<Inputs.TextBox
									type="date"
									name="finish"
									label="Hasta"
									placeholder="Ingrese la fecha..."
									value={formFilter.finish}
									onChange={handleOnChange}
									onKeyPress={handleKeyPress}
									className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-150px"
								/>
							</>
						)}
						<Layout.Tooltip
							title="Limpiar búsqueda"
							placement="top"
						>
							<Layout.IconButton
								onClick={handleClear}
								edge="start"
								className="bg-secondary font-roboto-semibold d-inline float-end mt-1 ml-2 mb-2"
							>
								<Layout.Icons.CloseIcon
									width={18}
									height={18}
									className="font-roboto-semibold text-white"
								/>
							</Layout.IconButton>
						</Layout.Tooltip>
					</div>
				</div>
			</Layout.CardContent>
		</Layout.Card>
	);
};

export default SearchComponent;
