import React, { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useParams } from 'react-router-dom'
import { InventoryView } from '../views/inventoryPage'
import Layout from '../../../components/mui/Layout'
import Feedback from '../../../components/mui/Feedback'
import { getPropertyDetails } from '../../../redux/actions/owners/inventory/ownerInventoryAction'

const InventoryPage = ({ urlDetalleInmueble = false, isInventoryInternal = false, showHeader = false }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.loading.isLoading)
  const params = useParams()
  const mobiliCode = params.mobiliCode
  const albumName = params.albumName
  const uID = params.uID

  useEffect(() => {}, [mobiliCode, albumName])
  
  /**
   *
   * @description Execute endpoint show by id property detail for inventory
   * @return dispatch
   *
   */
  const handleShowById = async () => await dispatch(getPropertyDetails(uID))

  useEffect(() => {
    handleShowById()
  }, [uID])

  return (
    <>
      <Layout.Page title="Propiedades - Ficha Inventario">
        <Feedback.ScrollBar style={{ height: 'calc(100vh - 15px)', overflowX: 'hidden' }}>
          {!isLoading && (
            <InventoryView
              urlDetalleInmueble={urlDetalleInmueble}
              isInventoryInternal={isInventoryInternal}
              uID={uID}
              showHeader={showHeader}
            />
          )}
        </Feedback.ScrollBar>
      </Layout.Page>
      <Feedback.ModalLoading
        maxWidth="xs"
        isOpen={isLoading}
        title="Cargando..."
        content="Espere un momento por favor..."
      />
    </>
  )
}

export default InventoryPage
