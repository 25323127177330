import React from 'react';
import LayoutPage from '../pages/dashboard/layout/LayoutPage';

const ProtectedRouter = () => {
	
	return (
		<LayoutPage />
	);
};

export default ProtectedRouter;
