import React from 'react';
import {
	Breadcrumbs as MUIBreadcrumbs,
	Link,
	styled,
	Typography
} from '@mui/material';
import { withRouter } from '../../../helpers/withRouter';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ThemeProviderComponent from '../../../theme-v2/themeProvider';
import { convertFirstLetterToUppercase } from '../../../helpers/functions';

const MUILink = styled(Link)(({ theme }) => ({
	color: theme.palette.white.default
}));

const Breadcrumbs = (props) => {
	const { navigate, location } = props.router;
	const { pathname } = location;
	// from an array (such as an empty string etc)
	const pathnames = pathname.split('/').filter((el) => el);
	
	return (
		<ThemeProviderComponent>
			<MUIBreadcrumbs
				aria-label='breadcrumb'
				separator={<NavigateNextIcon fontSize='small' />}
			>
				{pathnames.map((pathname, index) => {
					const routeTo = `/${pathnames
						.slice(0, index + 1)
						.join('/')}`;
					// To make sure the last breadcrumb is not a link
					const isLast = index === pathnames.length - 1;
					return isLast ? (
						pathname.length < 60 ? (
							<Typography key={index}>
								{convertFirstLetterToUppercase(pathname)}
							</Typography>
						) : (
							''
						)
					) : (
						<MUILink
							key={index}
							onClick={() => navigate(routeTo)}
						>
							{convertFirstLetterToUppercase(pathname)}
						</MUILink>
					);
				})}
			</MUIBreadcrumbs>
		</ThemeProviderComponent>
	);
};

export default withRouter(Breadcrumbs);
