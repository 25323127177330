import iconTwitter from './twitter.svg'
import logo from './logo_footer.svg'
import iconFacebook from './facebook.svg'
import iconInstagram from './instagram.svg'
import iconLinkedin from './linkedin.svg'

export default {
  logo,
  iconFacebook,
  iconTwitter,
  iconInstagram,
  iconLinkedin,
}
