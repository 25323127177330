import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100% !important',
        '& .MuiFormLabel-root.Mui-focused': {
            fontWeight: '500',
            color: '#1b1b1b !important',
            fontSize: '18px'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid #1b1b1b !important'
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: '2px solid #1b1b1b !important'
        }
    }
}))

export default function TextFieldSearch(props) {

    const classes = useStyles()
    const { label, name, variant, defaultValue, onChange, ...other } = props
    
    return (
        <TextField
            label={label}
            name={name}
            variant={variant || "standard"}
            value={defaultValue || ""}
            defaultValue={defaultValue || ""}
            onChange={onChange || null}
            classes={{ root: classes.root }}
            InputProps={
                {
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchOutlinedIcon />
                        </InputAdornment>
                    )
                }
            }
            {...other}
        />
    )
}
