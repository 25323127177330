import React from 'react'
import images from '../img'
import styles from './header.module.css'

export function Header() {
  return (
    <header className={styles.header}>
      <img src={images.logoHeader} alt="logo_header" />
    </header>
  )
}
