import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Collapse from '@mui/material/Collapse'
import Layout from '../../../../../components/mui/Layout'
import TableComponent from './TableComponent'
import errorServices from '../../../../../services/errors'
import axios from '../../../../../services/axios'
import { statisticCustomerColumns as columns, perPages } from '../../../../../helpers/variablesColumns'
import summation from '../../../../../assets/images/icons/summation.png'
import average from '../../../../../assets/images/icons/average.png'

const StatisticByStatusRow = ({ row, headCells = [], getService = '', getInitDate = '', getEndDate = '', getOperator = '', getRealstateID = '' }) => {
  const { status, count, ranking, amount } = row
  const tokenType = useSelector( state  => state.auth.tokenType)
  const accessToken = useSelector( state  => state.auth.accessToken)
  const [isLoading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [arrayList, setArrayList] = useState([])
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [getTotalRows, setTotalRows] = useState(0)
  
  /**
   *
   * @description Execute open-close state
   * @return void
   *
   */
  const handleOpen = () => setOpen(!open)
  
  /**
   *
   * @description Execute endpoint array list by status detail of filtered statistic
   * @return void
   *
   */
  const handleList = async () => {
    setLoading(true)
    
    try {
      axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
      
      const param = {
        perPage: perPage,
        page : page,
        status: status,
        service: getService === '' ? null : getService,
        init_date: getInitDate === '' ? null : getInitDate,
        end_date: getEndDate === '' ? null : getEndDate,
        realstate: getRealstateID === '' ? null : getRealstateID
      }
      
      const data = await axios.post('dashboard/filtered/statistics/by-status-detail', param)
      
      if(data.status === 200) {
        setPages(data.data.data.pages)
        setTotalRows(data.data.data.totalRows)
        setArrayList(data.data.data.records)
      }
    } catch (error) {
      await errorServices(error)
    }
    
    setLoading(false)
  }
  
  useEffect(() => {
    if(open){
      handleList()
    }
  }, [
    open,
    page,
    perPage,
    getService,
    getInitDate,
    getEndDate,
    getRealstateID
  ])
  
  /**
   *
   * @description Execute perPage state
   * @return void
   *
   */
  const handleChangePerPage = ({ target }) => setPerPage(target.value)
  
  /**
   *
   * @description Execute page state
   * @return void
   *
   */
  const handleChangePage = (event, value) => setPage(value)
  
  return (
    <>
      <tr className="m-0 p-0">
        <td
          className="m-0 pt-0 pb-0 pl-2 pr-2 mx-auto text-center"
          width="5%"
        >
          <Layout.IconButton
            color="inherit"
            size="small"
            aria-label="actions"
            aria-controls="menu-actions"
            aria-haspopup="true"
            onClick={handleOpen}
          >
            {open && (
              <Layout.Icons.ExpandLessIcon
                className="text-dark"
                height={20}
                width={20}
              />
            )}
            {!open && (
              <Layout.Icons.ExpandMoreIcon
                className="text-dark"
                height={20}
                width={20}
              />
            )}
          </Layout.IconButton>
        </td>
        <td
          className="m-0 pt-0 pb-0 pl-2 pr-2"
          width="35%"
        >
          <div className="font-family-roboto-regular fs-6 text-dark">
            { status }
          </div>
        </td>
        <td
          className="m-0 pt-0 pb-0 pl-2 pr-2 mx-auto text-center"
          width="15%"
        >
          <div className="font-family-roboto-regular fs-6 text-dark">
            #{ count }
          </div>
        </td>
        <td
          className="m-0 pt-0 pb-0 pl-2 pr-2 mx-auto text-center"
          width="10%"
        >
          <div className="font-family-roboto-regular fs-6 text-dark">
            { ranking }
          </div>
        </td>
        <td
          className="m-0 pt-0 pb-0 pl-2 pr-2"
          width="35%"
        >
          <div className="d-flex justify-content-end align-self-end float-end">
            <div className="font-family-roboto-regular fs-6 text-dark mr-2 d-inline">
              { amount }
            </div>
            <img
              className="d-inline"
              width={20}
              height={20}
              src={getOperator === 'sum' ? summation : average}
              alt=""
            />
          </div>
        </td>
      </tr>
      {open && (
        <tr className="m-0 p-0 bg-white animate__animated animate__fadeIn">
          <td
            className="m-0 p-0"
            colSpan={headCells.length}
            width="100%"
          >
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              className="m-0 p-3"
            >
              <TableComponent
                isLoading={isLoading}
                page={page}
                pages={pages}
                perPage={perPage}
                perPages={perPages}
                columns={columns}
                arrayList={arrayList}
                handleChangePerPage={handleChangePerPage}
                handleChangePage={handleChangePage}
              />
            </Collapse>
          </td>
        </tr>
      )}
    </>
  )
}

StatisticByStatusRow.propTypes = {
  row: PropTypes.object.isRequired,
  headCells: PropTypes.array,
  getService: PropTypes.string,
  getInitDate: PropTypes.string,
  getEndDate: PropTypes.string,
  getRealstateID: PropTypes.string
}

export default StatisticByStatusRow
