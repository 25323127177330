import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DropdownActionComponent from './DropdownActionComponent';
import { setIsOpenModal, setFormType } from '../redux/actions/buildingAction';
import { setActivityLogReset, setIsOpenModal as setIsOpenModalActivity, setLogName, setSubjectId } from '../../../../redux/actions/dashboard/activities/activityLogAction';
import { setFormId, setIsLoadingForm, setIsLoadingShow } from '../redux/actions/buildingAction';

const TableRowComponent = ({ id, realState, company, address, strata, yearConstruction, areaConstruction, areaTotal }) => {
	const dispatch = useDispatch();
	
	useEffect(() => {
	}, [
		id,
		realState,
		company,
		address,
		strata,
		yearConstruction,
		areaConstruction,
		areaTotal
	]);
	
	/**
	 *
	 * @description Execute dispatch open modal, reset formData, loading show, loading form, formType and activityLog state
	 * @return dispatch
	 *
	 */
	const handleIsOpenModal = async (formType) => {
		if (formType === 'activity-log') {
			await dispatch( setActivityLogReset() );
			await dispatch( setLogName('Edificaciones') );
			await dispatch( setSubjectId(id) );
			await dispatch( setIsOpenModalActivity(true) );
		} else {
			await dispatch( setFormId(id) );
			await dispatch( setFormType(formType) );
			await dispatch( setIsLoadingShow(false) );
			await dispatch( setIsLoadingForm(false) );
			await dispatch( setIsOpenModal(true) );
		}
	};
	
	return (
		<>
			<tr className="m-0 p-0">
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="20%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark">
						{ realState }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="20%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark">
						{ company }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="20%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark">
						{ address }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="5%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark text-center">
						{ strata || '' }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="10%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark text-center">
						{ yearConstruction || '' }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="10%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark text-center">
						{ areaConstruction || '' }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="10%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark text-center">
						{ areaTotal || '' }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="5%"
				>
					<div className="mx-auto d-flex justify-content-center">
						<DropdownActionComponent handleIsOpenModal={handleIsOpenModal} />
					</div>
				</td>
			</tr>
		</>
	);
};

TableRowComponent.propTypes = {
	id: PropTypes.number.isRequired,
	realState: PropTypes.string.isRequired,
	company: PropTypes.string.isRequired,
	address: PropTypes.string,
	strata: PropTypes.number,
	yearConstruction: PropTypes.string,
	areaConstruction: PropTypes.string,
	areaTotal: PropTypes.string,
};

export default TableRowComponent;
