import React from 'react';
import { useRoutes } from 'react-router-dom';
import LoginPage from '../pages/auth/LoginPage';
import VerificationPage from '../pages/auth/VerificationPage';
import ForgotPasswordPage from '../pages/auth/ForgotPasswordPage';
import PasswordResetPage from '../pages/auth/PasswordResetPage';
import CallCenterPageOld from '../pages/filtered/CallCenterPageOld';
import CallCenterPage from '../pages/filtered/CallCenterPage';
import InventoryPageOld from '../pages/owners/InventoryPageOld';
import InventoryPage from '../pages/owners/InventoryPage';
import PropertyDetailPage from '../pages/properties/PropertyDetailPage';
// import { InventoryView } from '../pages/owners/views/inventoryPage';
// import DetailedViewProperty from '../pages/propertyDetails';
import { ClientPropertyView } from '../pages/clientProperty/ClientPropertyView';
import AuthorizationPage from '../pages/owners/AuthorizationPage';
import ErrorPage from '../pages/errors/ErrorPage';

const AuthRoutes = () => {
  return useRoutes([
    {
      index: true,
      path: '',
      element: <LoginPage />,
    },
    {
      index: false,
      path: 'verification/:uID',
      element: <VerificationPage />,
    },
    {
      index: false,
      path: 'forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      index: false,
      path: 'password-reset/:uID',
      element: <PasswordResetPage />,
    },
    {
      index: false,
      path: 'call-center',
      element: <CallCenterPageOld />,
    },
    {
      index: false,
      path: 'filter/call-center/:uID',
      element: <CallCenterPage />,
    },
    {
      index: false,
      path: 'filter/customer/:uID',
      element: <ClientPropertyView />,
    },
    /*{
      index: false,
      path: 'galery/properties/:mobiliCode/:album',
      element: <InventoryPageOld />,
    },*/
    {
      index: false,
      path: 'owners/inventory/:mobiliCode/:albumName/:uID',
      element: <InventoryPage showHeader={true} />,
    },
    {
      index: false,
      path: 'owners/authorization/:uID',
      element: <AuthorizationPage />,
    },
    // {
    //   index: false,
    //   path: 'inventory-fiche/:uID',
    //   element: <InventoryPage showHeader />,
    // },
    // {
    //   index: false,
    //   path: 'ficha-detalle-inmueble',
    //   element: <InventoryView />,
    // },
    // {
    //   index: false,
    //   path: 'ficha-inventario/:uID',
    //   element: <DetailedViewProperty />,
    // },
    // {
    //   index: false,
    //   path: 'ficha-inventario/:uID',
    //   element: <InventoryPage urlDetalleInmueble />,
    // },
    {
      index: false,
      path: 'properties/detail/:uID',
      element: <PropertyDetailPage isPropertyDetail={true} />,
    },
    {
      index: false,
      path: '*',
      element: <ErrorPage />,
    },
  ]);
};

export default AuthRoutes;
