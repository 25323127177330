import React from 'react'
import PropTypes from 'prop-types'
import MuiSwitch from '@mui/material/Switch'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

const Switch = (props) => {
	const {
		label = '',
		name,
		color,
		isChecked,
		position,
		hasError = false,
		errorMessage = '',
		...more
	} = props
	
	return (
		<FormControl
			component="fieldset"
			error={hasError}
		>
			<FormGroup
				aria-label="position"
				row
			>
				{label === '' && (
					<MuiSwitch
						name={name}
						color={color}
						checked={isChecked}
						{...more}
					/>
				)}
				{label !== '' && (
					<FormControlLabel
						control={
							<MuiSwitch
								name={name}
								color={color}
								checked={isChecked}
								{...more}
							/>
						}
						label={label}
						labelPlacement={position}
					/>
				)}
			</FormGroup>
			{hasError && errorMessage !== "" && (
				<div className="mt-1 bg-grey-light border-l-4 border-danger p-2 font-family-roboto-regular fs-6 text-danger animate__animated animate__fadeIn">
					{errorMessage}
				</div>
			)}
		</FormControl>
	)
}

Switch.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
	isChecked: PropTypes.bool.isRequired,
	position: PropTypes.string.isRequired,
	hasError: PropTypes.bool,
	errorMessage: PropTypes.string
}

export default Switch
