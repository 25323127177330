import React from "react"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Display from "../../../../../components/display/Display"

const KeyRow = ({ row }) => {
	const {
		created_date,
		created_at,
		name,
		adviserName,
		quantity,
		description
	} = row
	
	return (
		<>
			<TableRow>
				<TableCell className="p-2" align="center" width="15%">
					<Display.DateWithTimezone
						timezone={created_at}
						updated_date={created_date}
					/>
				</TableCell>
				<TableCell className="p-2 text-truncate" align="justify" width="20%">
					{name}
				</TableCell>
				<TableCell className="p-2 text-truncate" align="center" width="30%">
					{adviserName}
				</TableCell>
				<TableCell className="p-2 text-truncate" align="center" width="10%">
					{quantity}
				</TableCell>
				<TableCell className="p-2 text-truncate" align="center" width="25%">
					{description}
				</TableCell>
			</TableRow>
		</>
	)
}

export default KeyRow
