import endPoints from './endPoints'
import axios from '../../../../../../services/axios'

const {
	arrayList,
	arrayListToDropdown,
	arrayListToDropdownAgent,
	arrayListToDropdownAdviser,
	store,
	resendEmail,
	show,
	update,
	status,
	destroy,
	showProfile,
	assignPermissions,
	updateProfile,
	updateProfilePassword
} = endPoints

export const getArrayList = async (data) => (
	await axios.post(arrayList, data)
)

export const getArrayListToDropdown = async () => (
	await axios.get(`${arrayListToDropdown}`)
)

export const getArrayListToDropdownAgent = async (data) => (
	await axios.post(arrayListToDropdownAgent, data)
)

export const getArrayListToDropdownAdviser = async (data) => (
	await axios.post(arrayListToDropdownAdviser, data)
)

export const setStore = async (data) => (
	await axios.post(store, data)
)

export const getResendEmailById = async (id) => (
	await axios.get(`${resendEmail}${id}`)
)

export const getShowById = async (id) => (
	await axios.get(`${show}${id}`)
)

export const setUpdateById = async (id, data) => (
	await axios.put(`${update}${id}`, data)
)

export const setAssignPermissionsById = async (id, data) => (
	await axios.put(`${assignPermissions}${id}`, data)
)

export const setChangeStatusById = async (id) => (
	await axios.get(`${status}${id}`)
)

export const setDeleteById = async (id) => (
	await axios.delete(`${destroy}${id}`)
)

export const getShowProfile = async () => (
	await axios.post(`${showProfile}`)
)

export const setUpdateProfileById = async (data) => (
	await axios.post(updateProfile, data)
)

export const setUpdateProfilePasswordById = async (data) => (
	await axios.post(updateProfilePassword, data)
)
