import { Box, styled, Typography } from '@mui/material'
import React from 'react'

export const Chip = styled('span')(({ theme }) => ({
  color: theme.palette.primary.dark,
  backgroundColor: '#EEF0F3',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '0.5625rem',
  lineHeight: '150%',
  borderRadius: '20px',
  padding: '5px',
}))

export function Price({ titlePrice, price }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexGrow: '1',
        }}
      >
        <Chip>{titlePrice}</Chip>
        <Typography variant="h3">{`$${price} COP`}</Typography>
      </Box>
    </>
  )
}
