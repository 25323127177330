import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Price, GallerySlider } from '../../components'
import {
  AddressText,
  AddressTextSubtitle,
  CardAddress,
  CardFeactures,
} from '../styles'
import images from '../../../../components/v2-mobili/img'
import styles from './descriptionMain.module.css'


export function DescriptionMain({ property, priceArray }) {
  const formData = {
    features: [
      {
        id: 1,
        name: 'Cocina integral',
      },
      {
        id: 2,
        name: 'Hall de alcobas',
      },

      {
        id: 3,
        name: 'Sala-comedor',
      },
      {
        id: 4,
        name: 'Ascensor',
      },
      {
        id: 5,
        name: 'Piscina',
      },
      {
        id: 6,
        name: 'Zona infantil',
      },
      {
        id: 7,
        name: 'Divisiones',
      },
      {
        id: 8,
        name: 'Iluminación natural',
      },
      {
        id: 9,
        name: 'Vestier',
      },
      {
        id: 10,
        name: 'Citófono',
      },
      {
        id: 11,
        name: 'Portería/vigilancia',
      },
      {
        id: 12,
        name: 'Zonas verdes',
      },
      {
        id: 13,
        name: 'Gas natural',
      },
      {
        id: 14,
        name: 'Instalación de gas',
      },
      {
        id: 15,
        name: 'Zona de ropas',
      },
      {
        id: 16,
        name: 'En conjunto cerrado',
      },
      {
        id: 17,
        name: 'Vigilancia privada 24*7',
      },
      {
        id: 18,
        name: 'Área urbana',
      },
      {
        id: 19,
        name: 'MARCOS',
      },
    ],
  }

  const featureList = property?.areas?.map((area, index) =>
    area?.amenities?.map((item) => (
      <ul key={index}>
        <li key={item}>{item}</li>
      </ul>
    )),
  )

  // const featureList = formData?.areas?.map((area, index) => (
  //   <ul key={index}>
  //     <li key={area.id}>{area.name}</li>
  //   </ul>
  // ))

  return (
    <div className={styles.gridContainer}>
      <div className={styles.box}>
        <div className={styles.left}>
          <div className={styles.boxPrice}>
            {priceArray.map(
              (item) =>
                parseInt(item.price) > 0 && (
                  <Price
                    key={item.id}
                    titlePrice={item.title}
                    price={item.price}
                  />
                ),
            )}
          </div>

          <CardAddress>
            <div className={styles.boxAddress}>
              <div>
                <AddressText>{property?.address1}</AddressText>
                <AddressTextSubtitle>{property?.address2}</AddressTextSubtitle>
              </div>
              <div>
                <AddressTextSubtitle>
                  <LocationOnIcon />
                  Barrio {property?.neighborhood}, {property?.city}
                </AddressTextSubtitle>
              </div>
            </div>
          </CardAddress>
          <CardFeactures>
            <AddressTextSubtitle>
              {/* Apartamento en Venta y Arriendo para Vivienda */}
              {/* Apartamento en Venta y Arriendo para Vivienda */}
              {`${property?.type} en ${property?.action_type} para Vivienda`}
            </AddressTextSubtitle>
            <div className={styles.boxFeatures}>
              <div className={styles.boxFeaIcon}>
                <img alt="" src={images.iconM2Blue} />
                {property?.area_total}m2
                {/* 99m2 */}
              </div>
              <div className={styles.boxFeaIcon}>
                <img alt="" src={images.iconBedBlue} />
                {property?.number_room} habitaciones
              </div>
              <div className={styles.boxFeaIcon}>
                <img alt="" src={images.iconBathBlue} />
                {property?.number_bathroom} baños
              </div>
              <div className={styles.boxFeaIcon}>
                <img alt="" src={images.iconGarageBlue} />
                {property?.number_garage} puesto
              </div>
              <div className={styles.boxFeaIcon}>
                <img alt="" src={images.iconHouseBlue} />
                Estrato {property?.strata}
              </div>
            </div>

            <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
              <div className={styles.boxFeaturesList}>
                {property?.areas?.length > 0 && featureList}
              </div>
            </div>
          </CardFeactures>
        </div>
        <div className={styles.right}>
          <GallerySlider property={property} />
        </div>
      </div>
    </div>
  )
}
