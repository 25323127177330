export const PER_PAGE_REALSTATE = 'PER_PAGE_REALSTATE';
export const PAGE_REALSTATE = 'PAGE_REALSTATE';
export const SEARCH_REALSTATE = 'SEARCH_REALSTATE';
export const PAGES_REALSTATE = 'PAGES_REALSTATE';
export const ARRAY_LIST_REALSTATE = 'ARRAY_LIST_REALSTATE';
export const ARRAY_LIST_TO_DROPDOWN_REALSTATE = 'ARRAY_LIST_TO_DROPDOWN_REALSTATE';
export const REFRESH_TABLE_REALSTATE = 'REFRESH_TABLE_REALSTATE';
export const IS_OPEN_MODAL_REALSTATE = 'IS_OPEN_MODAL_REALSTATE';
export const IS_LOADING_SHOW_REALSTATE = 'IS_LOADING_SHOW_REALSTATE';
export const IS_LOADING_FORM_REALSTATE = 'IS_LOADING_FORM_REALSTATE';
export const FORM_TYPE_REALSTATE = 'FORM_TYPE_REALSTATE';
export const FORM_DATA_REALSTATE = 'FORM_DATA_REALSTATE';
