import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const TextBox = (props) => {
  const {
    type = 'text',
    variant = 'outlined',
    size = 'small',
    name,
    label,
    placeholder,
    hasLabelFixed = true,
    value = '',
    readOnly = false,
    onChange = null,
    onBlur = null,
    onKeyPress = null,
    hasError = false,
    errorMessage = '',
    startAdornment = null,
    endAdornment = null,
    ...more
  } = props;
  
  return (
    <>
      <TextField
        fullWidth
        type={type}
        variant={variant}
        size={size}
        name={name}
        label={label}
        placeholder={placeholder}
        InputLabelProps={{
          shrink: hasLabelFixed
        }}
        InputProps={{
          readOnly: readOnly,
          startAdornment: startAdornment,
          endAdornment: endAdornment
        }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        error={hasError}
        {...more}
      />
      {hasError && errorMessage !== "" && (
        <div className="mt-1 has-error-content font-family-roboto-regular fs-6 animate__animated animate__fadeIn">
          {errorMessage}
        </div>
      )}
    </>
  );
};

TextBox.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  hasLabelFixed: PropTypes.bool,
  value: PropTypes.any,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any
};

export default TextBox;
