import { initialValuesAmenity as initialValues } from '../../../../../../helpers/variablesInitialValues';
import * as types from '../types/amenityType';

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	search: '',
	arrayList: [],
	arrayListToDropdownCommercialArea: [],
	arrayListToDropdownLegalArea: [],
	arrayListToDropdownCommercialBuilding: [],
	refreshTable: false,
	isOpenModal: false,
	isOpenModalPermission: false,
	isLoadingShow: false,
	isLoadingForm: false,
	formType: '',
	formData: initialValues
};

const amenityReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_AMENITY:
			return {
				...state,
				perPage: payload
			};
		
		case types.PAGE_AMENITY:
			return {
				...state,
				page: payload
			};
		
		case types.PAGES_AMENITY:
			return {
				...state,
				pages: payload
			};
		
		case types.SEARCH_AMENITY:
			return {
				...state,
				search: payload
			};
		
		case types.ARRAY_LIST_AMENITY:
			return {
				...state,
				arrayList: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_COMMERCIAL_AREA_AMENITY:
			return {
				...state,
				arrayListToDropdownCommercialArea: payload
			};
			
		case types.ARRAY_LIST_TO_DROPDOWN_LEGAL_AREA_AMENITY:
			return {
				...state,
				arrayListToDropdownLegalArea: payload
			};
			
		case types.ARRAY_LIST_TO_DROPDOWN_COMMERCIAL_BUILDING_AMENITY:
			return {
				...state,
				arrayListToDropdownCommercialBuilding: payload
			};
		
		case types.REFRESH_TABLE_AMENITY:
			return {
				...state,
				refreshTable: payload
			};
		
		case types.IS_OPEN_MODAL_AMENITY:
			return {
				...state,
				isOpenModal: payload
			};
		
		case types.IS_LOADING_SHOW_AMENITY:
			return {
				...state,
				isLoadingShow: payload
			};
		
		case types.IS_LOADING_FORM_AMENITY:
			return {
				...state,
				isLoadingForm: payload
			};
		
		case types.FORM_TYPE_AMENITY:
			return {
				...state,
				formType: payload
			};
		
		case types.FORM_DATA_AMENITY:
			return {
				...state,
				formData: payload
			};
		
		default:
			return state
	}
};

export default amenityReducer;
