import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { InventoryView } from '../../owners/views/inventoryPage'
import Layout from '../../../components/mui/Layout'
import Feedback from '../../../components/mui/Feedback'
import { getPropertyDetails as getPropertyDetailsInventory  } from '../../../redux/actions/owners/inventory/ownerInventoryAction'
import { getPropertyDetails } from '../../../redux/actions/properties/detail/PropertyDetailAction'

const PropertyDetailPage = ({ isPropertyDetail = false, isInventoryInternal = false }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.loading.isLoading)
  const params = useParams()
  const mobiliCode = params.mobiliCode
  const albumName = params.albumName
  const uID = params.uID

  useEffect(() => {}, [mobiliCode, albumName])
  
  const handleShowById = async () => await dispatch(isPropertyDetail ? getPropertyDetails(uID) : getPropertyDetailsInventory(uID))

  useEffect(() => {
    handleShowById()
  }, [uID])
  
  return (
    <Layout.Page title="Propiedades - Detalle de la Propiedad">
      <Feedback.ScrollBar style={{ height: 'calc(100vh - 15px)', overflowX: 'hidden' }}>
        {!isLoading && (
          <InventoryView
            urlDetalleInmueble={isPropertyDetail}
            isInventoryInternal={isInventoryInternal}
            uID={uID}
            showHeader={true}
          />
        )}
      </Feedback.ScrollBar>
      <Feedback.ModalLoading
        maxWidth="xs"
        isOpen={isLoading}
        title="Cargando..."
        content="Espere un momento por favor..."
      />
    </Layout.Page>
  )
}

PropertyDetailPage.propTypes = {
  isPropertyDetail: PropTypes.bool,
  isInventoryInternal: PropTypes.bool
}

export default PropertyDetailPage
