import Alert from './Alert'
import Backdrop from './Backdrop'
import Dialog from './Dialog'
import DialogLoading from './DialogLoading'
import DialogBuilding from './DialogBuilding'
import Map from './Map'
import Popover from './Popover'
import Loading from './Loading'
import Scroll from './Scroll'

const Feedback = {
    Alert,
    Backdrop,
    Dialog,
    DialogLoading,
    DialogBuilding,
    Map,
    Popover,
    Loading,
    Scroll
}

export default Feedback
