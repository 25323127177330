const ROOT = 'dashboard/owners/'

export default {
	arrayList: `${ROOT}list`,
	arrayListProperties: `${ROOT}properties/list`,
	store: `${ROOT}store`,
	show: `${ROOT}show/`,
	showByMobile: `${ROOT}show-on-mobile/`,
	update: `${ROOT}update/`,
	status: `${ROOT}status/`,
	destroy: `${ROOT}delete/`
}
