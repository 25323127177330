import React from 'react'
import PropTypes from 'prop-types'
import { Slider } from '../../gallery'
import useSlider from '../hooks/useSlider'
import styles from './galleryPhoto360.module.css'
import Skeleton from '../skeleton'
import LoaderGallery from '../../../../pages/dashboard/TestPage/components/loader/LoaderGallery'
import InfiniteScroll from 'react-infinite-scroll-component'

export function GalleryPhoto360({
  dataArrayPhoto360,
  isLoading,
  openSliderFuncProps,
  closeModalFuncProps,
  setDataGalleriesSliderFuncProps,
  byProps,
  height,
  hasMore,
  setPage,
}) {
  const {
    isOpenSlider,
    closeSliderFunc,
    openSliderFunc,
    setDataGalleriesSliderFunc,
    currentIndex,
    goToNext,
    goToPrevious,
  } = useSlider()

  const getUrlFotos360 = (index) => {
    setDataGalleriesSliderFunc(dataArrayPhoto360)
    openSliderFunc(index)
  }

  const getUrlFotos3602 = (index) => {
    setDataGalleriesSliderFuncProps(dataArrayPhoto360)
    closeModalFuncProps()
    openSliderFuncProps(index, 'fotos360')
  }
  console.log({dataArrayPhoto360})
  return (
    <>
      <div
        id="scrollableFotos360"
        style={{
          overflow: 'auto',
          maxHeight: height ? height : '780px',
          paddingRight: '10px',
        }}
      >
        {isLoading && <Skeleton type={'gallery'} />}
        <InfiniteScroll
          dataLength={dataArrayPhoto360?.length || 0}
          next={() => setPage((prevPage) => prevPage + 1)}
          hasMore={hasMore}
          loader={<LoaderGallery />}
          scrollableTarget="scrollableFotos360"
        >
          <div className={styles.gridContainer}>
            {dataArrayPhoto360?.map((item, index) => (
              <button
                key={item.name}
                onClick={() =>
                  byProps ? getUrlFotos3602(index) : getUrlFotos360(index)
                }
              >
                <img src={item?.asset?.full_thumbnail_path || item?.asset?.full_path } alt={item?.asset?.name} />
              </button>
            ))}
          </div>
        </InfiniteScroll>
      </div>

      {isOpenSlider && (
        <Slider
          typeSlider={'fotos360'}
          arrayDataMedia={dataArrayPhoto360}
          closeSlider={closeSliderFunc}
          show={isOpenSlider}
          currentIndexInitial={currentIndex}
          goToNext={goToNext}
          goToPrevious={goToPrevious}
        />
      )}
    </>
  )
}
GalleryPhoto360.propTypes = {
  dataArrayPhoto360: PropTypes.array,
  isLoading: PropTypes.bool,
  openSliderFuncProps: PropTypes.func,
  closeModalFuncProps: PropTypes.func,
  setDataGalleriesSliderFuncProps: PropTypes.func,
  byProps: PropTypes.bool,
  height: PropTypes.string,
}
