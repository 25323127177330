import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Inputs from '../../../../../components/mui/Inputs';
import Layout from '../../../../../components/mui/Layout';
import Feedback from '../../../../../components/mui/Feedback';
import { setUpdateProfileById } from "../services";
import { setFormData, setIsLoadingForm, setIsOpenModal } from '../redux/actions/profileAction';
import { swalSuccess } from '../../../../../hooks/useSweetAlert';
import swalErrors from '../../../../../hooks/useErrors';
import usePermission from '../../../../../hooks/usePermission';
import { initialValuesProfile as initialValues } from '../../../../../helpers/variablesInitialValues';

const FormProfileComponent = () => {
	const dispatch = useDispatch();
	const isLoadingShow = useSelector(  ({ profile }) => profile.isLoadingShow);
	const isLoadingForm = useSelector( ({ profile }) => profile.isLoadingForm);
	const formData = useSelector( ({ profile }) => profile.formData);
	const permission = usePermission('users.updateProfile');
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			firstName: Yup.string()
			.required('El nombre es requerido'),
			lastName: Yup.string()
			.required('El apellido es requerido'),
			email: Yup.string()
			.email('El email no es válido')
			.required('El email es requerido'),
			mobile: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
			.required('El celular es requerido')
		})
	);
	
	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		if (permission) {
			await dispatch( setIsLoadingForm(true) );
			
			try {
				const param = {
					first_name: formData.firstName,
					last_name: formData.lastName,
					email: formData.email,
					mobile: formData.mobile,
					phone: formData.phone,
					address: formData.address
				}
				
				const { data } = await setUpdateProfileById(param);
				
				if (data.data.status === 200) {
					await swalSuccess('Actualización correcta', data.data);
					resetForm({ values: initialValues});
					setValues(initialValues);
					await dispatch( setFormData(initialValues) );
					await dispatch( setIsOpenModal(false) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingForm(false) );
		}
	};
	
	/**
	 *
	 * @description Execute dispatch formData state
	 * @return dispatch
	 *
	 */
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.value }) );
	
	return (
		<>
			{isLoadingShow && (
				<Feedback.Loading />
			)}
			{!isLoadingShow && (
				<Formik
					initialValues={formData}
					validationSchema={validationSchema}
					onSubmit={hanbleSubmit}
				>
					{({
					  errors,
					  touched,
					  handleChange,
					  handleBlur,
				  }) => (
						<Form noValidate>
							<div className="row mt-3 animate__animated animate__fadeIn">
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										name="firstName"
										label="Nombre"
										placeholder="Ingrese nombre..."
										value={formData.firstName}
										onBlur={handleBlur}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										hasError={errors.lastName && touched.lastName ? true : false}
										errorMessage={errors.lastName && touched.lastName ? errors.lastName : ''}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										name="lastName"
										label="Apellido"
										placeholder="Ingrese apellido..."
										value={formData.lastName}
										onBlur={handleBlur}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										hasError={errors.lastName && touched.lastName ? true : false}
										errorMessage={errors.lastName && touched.lastName ? errors.lastName : ''}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
									/>
								</div>
								<div className="col-sm-12 col-md-12">
									<Inputs.TextBox
										type="email"
										name="email"
										label="Email"
										placeholder="Ingrese email..."
										value={formData.email}
										onBlur={handleBlur}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										hasError={errors.email && touched.email ? true : false}
										errorMessage={errors.email && touched.email ? errors.email : ''}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										type="text"
										name="mobile"
										label="Celular"
										placeholder="Ingrese celular..."
										value={formData.mobile}
										onBlur={handleBlur}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										hasError={errors.mobile && touched.mobile ? true : false}
										errorMessage={errors.mobile && touched.mobile ? errors.mobile : ''}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
										
									/>
								</div>
								
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										type="text"
										name="phone"
										label="Teléfono"
										placeholder="Ingrese teléfono..."
										value={formData.phone}
										onBlur={handleBlur}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
									/>
								</div>
								
								<div className="col-sm-12 col-md-12">
									<Inputs.TextArea
										name="address"
										label="Dirección"
										placeholder="Ingrese dirección..."
										value={formData.address}
										onBlur={handleBlur}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
									/>
								</div>
								
								{permission && (
									<div className="col-sm-12">
										<Inputs.LoadingButton
											isLoading={isLoadingForm}
											isLoadingPosition="start"
											startIcon={<Layout.Icons.SaveIcon height={18} width={18} />}
											label="Guardar"
											type="submit"
											className="font-family-roboto-regular fs-6 bg-primary text-capitalize mt-2 d-flex float-end"
										/>
									</div>
								)}
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default FormProfileComponent;
