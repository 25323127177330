import React from 'react'
import AttachFile from '@material-ui/icons/AttachFile'

export default function AttachFileIcon(props) {

    const { ...other } = props

    return (
        <AttachFile { ...other } />
    )
}
