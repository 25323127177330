export const PER_PAGE_PERMISSION = 'PER_PAGE_PERMISSION';
export const PAGE_PERMISSION = 'PAGE_PERMISSION';
export const PAGES_PERMISSION = 'PAGES_PERMISSION';
export const SEARCH_PERMISSION = 'SEARCH_PERMISSION';
export const ARRAY_LIST_PERMISSION = 'ARRAY_LIST_PERMISSION';
export const ARRAY_LIST_TO_DROPDOWN_PERMISSION = 'ARRAY_LIST_TO_DROPDOWN_PERMISSION';
export const REFRESH_TABLE_PERMISSION = 'REFRESH_TABLE_PERMISSION';
export const IS_OPEN_MODAL_PERMISSION = 'IS_OPEN_MODAL_PERMISSION';
export const IS_LOADING_SHOW_PERMISSION = 'IS_LOADING_SHOW_PERMISSION';
export const IS_LOADING_FORM_PERMISSION = 'IS_LOADING_FORM_PERMISSION';
export const FORM_TYPE_PERMISSION = 'FORM_TYPE_PERMISSION';
export const FORM_DATA_PERMISSION = 'FORM_DATA_PERMISSION';
