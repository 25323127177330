import React, { useEffect }  from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/mui/Layout'
import Inputs from '../../components/mui/Inputs'
import { setStartDate, setFinishDate, setActivityName, getArrayDropdown } from "../../redux/actions/dashboard/activities/activityLogAction";
import { activityLogsDropdownGeneral } from '../../helpers/variablesColumns'

const TableActivityFilters = ({ logName }) => {
	const dispatch = useDispatch()
	const startDate = useSelector(state => state.activityLog.startDate)
	const finishDate = useSelector(state => state.activityLog.finishDate)
	const activityName = useSelector(state => state.activityLog.activityName)
	const arrayListDropdown = useSelector(state => state.activityLog.arrayListDropdown)
	
	const handleDropdownActivityName = async () => await dispatch( getArrayDropdown({ logName: logName }) )
	
	useEffect(() => {
		handleDropdownActivityName()
	}, [logName])
	
	const handleOnChange = ({ target }) => {
		if (target.name === 'start') {
			dispatch( setStartDate(target.value) )
		} else if (target.name === 'finish') {
			dispatch( setFinishDate(target.value) )
		} else if (target.name === 'activity') {
			dispatch( setActivityName(target.value) )
		}
	}
	
	const handleClear = () => {
		dispatch( setStartDate('') )
		dispatch( setFinishDate('') )
		dispatch( setActivityName('') )
	}
	
	return (
		<div className="col-sm-12 mb-1">
			<Inputs.TextBox
				type="date"
				name="start"
				label="Desde"
				placeholder="Ingrese la fecha..."
				hasLabelFixed={true}
				value={startDate}
				onChange={handleOnChange}
				className="font-family-roboto-regular fs-6 text-dark mb-2 w-150px"
			/>
			<Inputs.TextBox
				type="date"
				name="finish"
				label="Hasta"
				placeholder="Ingrese la fecha..."
				hasLabelFixed={true}
				value={finishDate}
				onChange={handleOnChange}
				className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-150px"
			/>
			<Inputs.Select
				label="Actividad"
				name="activity"
				value={activityName}
				itemsArray={arrayListDropdown}
				onChange={handleOnChange}
				className="font-family-roboto-regular fs-6 text-dark w-300px ml-2 mb-2"
			/>
			<Layout.Tooltip
				title="Limpiar búsqueda"
				placement="top"
			>
				<Layout.IconButton
					onClick={handleClear}
					edge="start"
					className="bg-secondary font-roboto-semibold d-inline float-end mt-1 ml-2 mb-2"
				>
					<Layout.Icons.CloseIcon
						width={18}
						height={18}
						className="font-roboto-semibold text-white"
					/>
				</Layout.IconButton>
			</Layout.Tooltip>
		</div>
	)
}

TableActivityFilters.propTypes = {
	logName: PropTypes.string.isRequired
}

export default TableActivityFilters
