import React from 'react'
import MuiButton from "@material-ui/core/Button"
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform: 'none',
        fontSize: '18px',
    },
    primary: {
        color: '#ffffff',
        backgroundColor: '#85BF02 !important',
        '&:hover': {
            backgroundColor: '#9fcc23 !important'
        }
    },
    whiteGreen: {
        color: '#85BF02',
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff'
        }
    },
    secondary: {
        color: '#5D5D5D',
        backgroundColor: '#D9DCDF',
        '&:hover': {
            backgroundColor: '#CECECE'
        }
    },
    dark: {
        color: '#f9f9f9',
        backgroundColor: '#1B1B1B',
        minWidth: '150px',
        '&:hover': {
            backgroundColor: '#1B1B1B'
        }
    },
    blue: {
        width: '190px',
        height: '50px',
        color: '#f9f9f9',
        backgroundColor: '#2435C8',
        '&:hover': {
            backgroundColor: '#2435C8',
            color: '#f9f9f9'
        }
    },
    blueLight: {
        width: '190px',
        height: '50px',
        color: '#2435C8',
        borderColor: '#2435C8',
        backgroundColor: '#F2F8FF',
        '&:hover': {
            backgroundColor: '#f9f9f9',
            color: '#2435C8',
            borderColor: '#2435C8'
        }
    },
    info: {
        color: '#2435C8',
        borderColor: '#2435C8',
        backgroundColor: '#F2F8FF',
        '&:hover': {
            backgroundColor: '#f9f9f9',
            color: '#2435C8',
            borderColor: '#2435C8'
        }
    },
    white: {
        width: '190px',
        height: '50px',
        color: '#2435C8',
        backgroundColor: '#f9f9f9',
        '&:hover': {
            backgroundColor: '#f9f9f9',
            color: '#2435C8'
        }
    },
    whiteAll: {
        fontFamily: 'Raleway',
        width: '100% !important',
        height: '35px !important',
        fontSize: '14px !important',
        color: '#2435C8',
        border: '0px solid transparent !important',
        backgroundColor: 'transparent !important',
        boxShadow: 'none !important',
        '&:hover': {
            backgroundColor: '#F2F8FF',
            color: '#2435C8',
            boxShadow: 'none !important',
        },
        '& .MuiButton-iconSizeMedium > *:first-child' : {
            fontSize: '14px !important'
        }
    },
    transparent: {
        color: '#85BF02',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}))

export default function Button(props) {

    const classes = useStyles()
    const { label, color, variant, startIcon, endIcon, ...other } = props

    if (color === 'primary'){
        return (
            <MuiButton
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ root: classes.root, label: classes.label }}
                className={classes.primary}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }

    if (color === 'whiteGreen'){
        return (
            <MuiButton
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ root: classes.root, label: classes.label }}
                className={classes.whiteGreen}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
    
    if (color === 'secondary'){
        return (
            <MuiButton
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ root: classes.root, label: classes.label }}
                className={classes.secondary}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
    
    if (color === 'dark'){
        return (
            <MuiButton
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ root: classes.root, label: classes.label }}
                className={classes.dark}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
    
    if (color === 'blue'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ label: classes.label }}
                className={classes.blue}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
    
    if (color === 'blueLight'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ label: classes.label }}
                className={classes.blueLight}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }

    if (color === 'info'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ label: classes.label }}
                className={classes.info}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
    
    if (color === 'white'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ label: classes.label }}
                className={classes.white}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }

    if (color === 'whiteAll'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ label: classes.label }}
                className={classes.whiteAll}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
    
    if (color === 'transparent'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ root: classes.root, label: classes.label }}
                className={classes.transparent}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
}
