import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../../../../../components/mui/Layout';
import Inputs from '../../../../../../components/mui/Inputs';
import { setDeleteById } from '../services';
import { setFormData, setRefreshTable, setIsOpenModal, setIsLoadingForm, setFormType } from '../redux/actions/cityAction';
import swalErrors from '../../../../../../hooks/useErrors';
import usePermission from '../../../../../../hooks/usePermission';
import { initialValuesCity as initialValues } from '../../../../../../helpers/variablesInitialValues';

const DeleteComponent = () => {
	const dispatch = useDispatch();
	const isLoadingForm = useSelector( ({ city }) => city.isLoadingForm);
	const refreshTable = useSelector( ({ city }) => city.refreshTable);
	const { id, name } = useSelector( ({ city }) => city.formData);
	const permission = usePermission('cities.delete');
	
	/**
	 *
	 * @description Execute dispatch reset formData and close modal state
	 * @return dispatch
	 *
	 */
	const handleCloseModal = async () => {
		await dispatch( setFormType('') );
		await dispatch( setFormData(initialValues) );
		await dispatch( setIsOpenModal(false) );
	};
	
	/**
	 *
	 * @description Execute endpoint delete by id
	 * @return dispatch
	 *
	 */
	const handleToDeleteById = async () => {
		if (permission) {
			await dispatch( setIsLoadingForm(true) );
			
			try {
				const { data } = await setDeleteById(id);
				
				if (data.status === 200) {
					await dispatch( setRefreshTable(!refreshTable) );
					await handleCloseModal();
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingForm(false) );
		}
	};
	
	return (
		<div className="row">
			<div className="col-sm-12">
				<div className="font-family-roboto-regular fs-5 text-dark-light">
					¿Estás seguro que desea eliminar la ciudad {name}?
				</div>
			</div>
			<div className="col-sm-12 mt-2">
				<div className="d-flex float-end">
					<Inputs.Button
						label="Cancelar"
						startIcon={<Layout.Icons.CloseIcon width={20} height={20} />}
						onClick={handleCloseModal}
						className="font-family-roboto-regular fs-6 bg-danger-outlined text-capitalize mr-2"
					/>
					{permission && (
						<Inputs.LoadingButton
							isLoading={isLoadingForm}
							isLoadingPosition="start"
							startIcon={<Layout.Icons.CheckIcon width={20} height={20} />}
							label="Aceptar"
							type="button"
							onClick={handleToDeleteById}
							className="font-family-roboto-regular fs-6 bg-primary text-capitalize mr-2"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default DeleteComponent;
