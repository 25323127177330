import Photo from './icons/Photo'
import AddOutlinedIcon from './icons/AddOutlinedIcon'
import SaveOutlinedIcon from './icons/SaveOutlinedIcon'
import SendOutlinedIcon from './icons/SendOutlinedIcon'
import VerifiedUserOutlinedIcon from './icons/VerifiedUserOutlinedIcon'
import EditOutlinedIcon from './icons/EditOutlinedIcon'
import DeleteOutlinedIcon from './icons/DeleteOutlinedIcon'
import CancelOutlinedIcon from './icons/CancelOutlinedIcon'
import ExpandLessIcon from './icons/ExpandLessIcon'
import ExpandMoreIcon from './icons/ExpandMoreIcon'
import HomeOutlinedIcon from './icons/HomeOutlinedIcon'
import AccountCircleOutlinedIcon from './icons/AccountCircleOutlinedIcon'
import AccountCircleIcon from './icons/AccountCircleIcon'
import PersonOutlinedIcon from './icons/PersonOutlinedIcon'
import ExitToAppOutlinedIcon from './icons/ExitToAppOutlinedIcon'
import SettingsOutlinedIcon from './icons/SettingsOutlinedIcon'
import AccountTreeOutlinedIcon from './icons/AccountTreeOutlinedIcon'
import CircularProgressIcon from './icons/CircularProgressIcon'
import NavigateNextOutlinedIcon from './icons/NavigateNextOutlinedIcon'
import VisibilityOffIcon from './icons/VisibilityOffIcon'
import VisibilityIcon from './icons/VisibilityIcon'
import CheckCircleOutlineOutlinedIcon from './icons/CheckCircleOutlineOutlinedIcon'
import LockOutlinedIcon from './icons/LockOutlinedIcon'
import MenuIcon from './icons/MenuIcon'
import ChevronLeftIcon from './icons/ChevronLeftIcon'
import ChevronRightIcon from './icons/ChevronRightIcon'
import RoomOutlinedIcon from './icons/RoomOutlinedIcon'
import KeyboardArrowUpIcon  from './icons/KeyboardArrowUpIcon'
import SearchIcon  from './icons/SearchIcon'
import RemoveIcon  from './icons/RemoveIcon'
import BathtubIcon  from './icons/BathtubIcon'
import SingleBedIcon  from './icons/SingleBedIcon'
import TerrainIcon  from './icons/TerrainIcon'
import DriveEtaOutlinedIcon  from './icons/DriveEtaOutlinedIcon'
import HighlightOffOutlinedIcon  from './icons/HighlightOffOutlinedIcon'
import CloseIcon  from './icons/CloseIcon'
import CloseOutlinedIcon  from './icons/CloseOutlinedIcon'
import FavoriteBorderIcon  from './icons/FavoriteBorderIcon'
import FavoriteIcon  from './icons/FavoriteIcon'
import CheckBoxOutlineBlankOutlinedIcon  from './icons/CheckBoxOutlineBlankOutlinedIcon'
import CheckBoxOutlinedIcon  from './icons/CheckBoxOutlinedIcon'
import AddLocationIcon  from './icons/AddLocationIcon'
import MailOutlineIcon from "./icons/MailOutlineIcon"
import UpdateIcon from "./icons/UpdateIcon"
import AttachFileIcon from "./icons/AttachFileIcon"
import PlaylistAddCheckIcon from "./icons/PlaylistAddCheckIcon"
import AppsIcon from "./icons/AppsIcon"
import BarChartIcon from "./icons/BarChartIcon"
import ImportExportIcon from "./icons/ImportExportIcon"
import ShareIcon from "./icons/ShareIcon"
import DeleteSweepIcon from "./icons/DeleteSweepIcon"
import FacebookIcon from "./icons/FacebookIcon"
import InstagramIcon from "./icons/InstagramIcon"
import WhatsAppIcon from "./icons/WhatsAppIcon"
import YouTubeIcon from "./icons/YouTubeIcon"
import LinkedInIcon from "./icons/LinkedInIcon"
import MoreVertIcon from "./icons/MoreVertIcon"
import PermMediaIcon from "./icons/PermMediaIcon"
import HomeWorkIcon from "./icons/HomeWorkIcon"

const Icons = {
    Photo,
    AddOutlinedIcon,
    SaveOutlinedIcon,
    SendOutlinedIcon,
    VerifiedUserOutlinedIcon,
    EditOutlinedIcon,
    DeleteOutlinedIcon,
    CancelOutlinedIcon,
    ExpandLessIcon,
    ExpandMoreIcon,
    HomeOutlinedIcon,
    AccountCircleOutlinedIcon,
    AccountCircleIcon,
    PersonOutlinedIcon,
    ExitToAppOutlinedIcon,
    SettingsOutlinedIcon,
    AccountTreeOutlinedIcon,
    CircularProgressIcon,
    NavigateNextOutlinedIcon,
    VisibilityOffIcon,
    VisibilityIcon,
    CheckCircleOutlineOutlinedIcon,
    LockOutlinedIcon,
    MenuIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    RoomOutlinedIcon,
    KeyboardArrowUpIcon,
    SearchIcon,
    RemoveIcon,
    BathtubIcon,
    SingleBedIcon,
    TerrainIcon,
    DriveEtaOutlinedIcon,
    HighlightOffOutlinedIcon,
    CloseIcon,
    CloseOutlinedIcon,
    FavoriteBorderIcon,
    FavoriteIcon,
    CheckBoxOutlineBlankOutlinedIcon,
    CheckBoxOutlinedIcon,
    AddLocationIcon,
    MailOutlineIcon,
    UpdateIcon,
    AttachFileIcon,
    PlaylistAddCheckIcon,
    AppsIcon,
    BarChartIcon,
    ImportExportIcon,
    ShareIcon,
    DeleteSweepIcon,
    FacebookIcon,
    InstagramIcon,
    WhatsAppIcon,
    YouTubeIcon,
    LinkedInIcon,
    MoreVertIcon,
    PermMediaIcon,
    HomeWorkIcon
}

export default Icons
