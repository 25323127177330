import React from "react";
import { useFormikContext } from "formik";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import useContact from "../../hooks/useContact";

const fakeListOfCases = [
  ...Array(200)
    .fill({})
    .map((_, i) => [
      `#${1245 + i}`,
      Math.random() < 0.5 ? "Activo" : "Inactivo",
      `0${2 + i}/19/2022`,
      "Arriendo",
      Math.random() * 1000000,
    ]),
];

const ValidateButton = () => {
  const { touched, errors, values, setFormikState } = useFormikContext();

  const {
    changeValidatePhoneStatus,
    validCellphone,
    setLastestCases,
    latestCases,
  } = useContact();

  const initialValues = {
    status: "initial",
    loading: false,
  };

  const [validateStatus, setValidateStatus] = React.useState(initialValues);

  const handleValidate = () => {
    // * llamar al endpoint con el valor del celular

    setValidateStatus({ ...validateStatus, loading: true });

    setTimeout(() => {
      setValidateStatus({ status: "success", loading: false });
      // * invocamos [changeValidatePhoneStatus] lo que oculta el boton validar y de inmediato
      // * se muestra la información recuperada en el context de formik
      const succesPhone = Math.random() < 0.5;
      changeValidatePhoneStatus(succesPhone);

      if (succesPhone) {
        setLastestCases({ ...latestCases, isLoading: true });
        fillFormWithValues();
        // * llamar al endpoint que trae los últimos casos
        setTimeout(() => {
          setLastestCases({
            data: fakeListOfCases,
            isLoading: false,
            status: "success",
            status_message: "no hay registros",
          });
        }, 1000);
      }
    }, 1000);
  };

  //
  const fillFormWithValues = () => {
    setFormikState({
      values: {
        firstNames: "A good firstname",
        lastNames: "A good lastname",
        cellphone: 309488588,
        sourceChannel: "Origen 8",
        contactSource: "Fuente 4",
      },
      isSubmitting: false,
      errors: {},
      touched: {},
      isValidating: false,
      submitCount: 0,
    });
  };
  return (
    <>
      {validCellphone === true && <span></span>}
      {validCellphone != true && (
        <LoadingButton
          loading={validateStatus.loading}
          disabled={
            validateStatus.loading ||
            values.cellphone === "" ||
            (errors.cellphone && touched.cellphone)
          }
          disableElevation
          variant="contained"
          fullWidth={false}
          sx={{ maxWidth: "120px", maxHeight: "40px", fontWeight: "700" }}
          onClick={handleValidate}
        >
          {/* {validateStatus.loading && } */}
          VALIDAR
        </LoadingButton>
      )}
    </>
  );
};

export default ValidateButton;
