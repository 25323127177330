import endPoints from './endPoints'
import axios from '../../../../../services/axios'

const {
	show,
	update
} = endPoints

/**
 *
 * @description Execute endpoint show by id of property authorization owner
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const getShowById = async (id) => (
	await axios.get(`${show}${id}`)
)

/**
 *
 * @description Execute endpoint update by id of property authorization owner
 * @param id
 * @param data
 * @method put
 * @return jsonResponse
 *
 */
export const setUpdateById = async (id, data) => (
	await axios.put(`${update}${id}`, data)
)
