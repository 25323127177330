import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-input' : {
            backgroundColor: '#FAFCFF',
            padding: '10px',
            borderTop: '2px solid #2435C8 !important',
            borderLeft: '2px solid #2435C8 !important',
            borderBottom: '2px solid #2435C8 !important',
            borderRight: '0px solid transparent !important',
            height: '26px !important',
            fontSize: '22px',
            color: '#272727',
            borderRadius: '4px 0px 0px 4px'
        },
        '& .MuiFormLabel-root': {
            display: 'none',
            visibility: 'hidden'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            display: 'none',
            visibility: 'hidden',
            borderTop: '1px solid #2435C8 !important',
            borderLeft: '1px solid #2435C8 !important',
            borderBottom: '1px solid #2435C8 !important',
            borderRight: '0px solid transparent !important',
            borderRadius: '4px 0px 0px 4px'
        },
        '& .MuiInput-underline:after': {
            borderTop: '1px solid #2435C8 !important',
            borderLeft: '1px solid #2435C8 !important',
            borderBottom: '1px solid #2435C8 !important',
            borderRight: '0px solid transparent !important',
            borderRadius: '4px 0px 0px 4px'
        },
        '& .MuiInput-underline:hover:before': {
            borderTop: '1px solid #2435C8 !important',
            borderLeft: '1px solid #2435C8 !important',
            borderBottom: '1px solid #2435C8 !important',
            borderRight: '0px solid transparent !important',
            borderRadius: '4px 0px 0px 4px'
        }
    }
}))

export default function TextSearch(props) {

    const classes = useStyles()
    const { label, name, variant, defaultValue, onChange, ...other } = props
    
    return (
        <TextField
            label={label}
            name={name}
            variant={variant || "standard"}
            value={defaultValue || ""}
            defaultValue={defaultValue || ""}
            onChange={onChange || ""}
            classes={{ root: classes.root }}
            {...other}
        />
    )
}