import React from 'react'
import YouTube from '@material-ui/icons/YouTube'
import PropTypes from 'prop-types'

const YouTubeIcon = ({ height, width, ...props }) => {

    return (
        <YouTube
            height={height}
            width={width}
            {...props}
        />
    )
}

YouTubeIcon.propTypes = {
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
}

export default YouTubeIcon