import React from 'react';
import { useSelector } from 'react-redux';
import Feedback from '../../../components/mui/Feedback';
import Navigation from '../../../components/mui/Navigation';
import Header from './header';
import Sidebar from './sidebar';
import ModalProfileComponent from '../settings/UserPage/components/ModalProfileComponent';
import ModalActivityLog from '../../../components/activity-logs/ModalActivityLog';
import ProtectedRoutes from '../../../routes/ProtectedRoutes';
import './Layout.scss';

const LayoutPage = () => {
	const isLoading = useSelector( state  => state.loading.isLoading);
	const isOpen = useSelector( state  => state.sidebar.isOpen);
	const isOpenModal = useSelector(state => state.activityLog.isOpenModal);
	const page = useSelector(state => state.activityLog.page);
	const pages = useSelector(state => state.activityLog.pages);
	const logName = useSelector(state => state.activityLog.logName);
	const subjectId = useSelector(state => state.activityLog.subjectId);
	
	return (
		<Feedback.ScrollBar style={{ overflowX: 'hidden' }}>
			<div className="wrapper">
				<Header />
				
				<div className={`sidebar animate__animated ${isOpen && 'sidebar-open animate__fadeInLeft'} bg-white position-fixed`}>
					<Sidebar />
				</div>
				
				<div className={`mainContent animate__animated animate__fadeIn ${!isOpen && 'mainContent-open'} d-block`}>
					<div className="content d-block position-relative">
						<ProtectedRoutes />
					</div>
				</div>
				
				<ModalProfileComponent />
				
				<ModalActivityLog
					isOpen={isOpenModal}
					page={page}
					pages={pages}
					logName={logName}
					subjectId={subjectId}
				/>
				
				<Feedback.ModalLoading
					isOpen={isLoading}
					title="Cargando..."
					content="Espere un momento por favor..."
				/>
				
				<Navigation.ScrollTop />
			</div>
		</Feedback.ScrollBar>
	);
};

export default LayoutPage;
