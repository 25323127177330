import endPoints from './endPoints'
import axios from '../../../../../services/axios'

const {
	arrayListFavorites,
	show
} = endPoints

/**
 *
 * @description Execute endpoint array list by filteredId of filtered favorites
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const getArrayListFavoritesById = async (id) => (
	await axios.get(`${arrayListFavorites}${id}`)
)

/**
 *
 * @description Execute endpoint show by id of filtered favorites
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const getShowById = async (id,source) => (
	await axios.get(`${show}${id}?source=${source}`)
)
