import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../../../../components/mui/Layout'
import Feedback from '../../../../../components/mui/Feedback'
import KanbanRowComponent from './KanbanRowComponent'
import { getArrayListRegister, setPageRegister } from '../../../../../redux/actions/dashboard/filtered/filteredAction'

const KanbanRegisterComponent = ({ isActiveSearch, realState, search, start, finish, agent, adviser }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.filtered.isLoadingRegister)
  const perPage = useSelector(state => state.filtered.perPageRegister)
  const page = useSelector(state => state.filtered.pageRegister)
  const hasNextPage = useSelector(state => state.filtered.hasNextPageRegister)
  const arrayList = useSelector(state => state.filtered.arrayListRegister)
  
  /**
   *
   * @description Execute endpoint array list by status registrado (borrador) of filtered
   * @return dispatch
   *
   */
  const handleArrayList = async (isPagination = false) => {
    const param = {
      realstate: realState,
      perPage: perPage,
      page: page,
      search: search === '' ? null : search,
      start: start === '' ? null : start,
      finish: finish === '' ? null : finish,
      agent: agent === '' ? null : agent,
      adviser: adviser === '' ? null : adviser,
      status: 'Registrado'
    }
  
    if (isPagination) {
      await dispatch( getArrayListRegister(param, arrayList, isPagination) )
    } else {
      await dispatch( getArrayListRegister(param) )
    }
  }

  useEffect(() => {
    handleArrayList(page === 1 ? false : true)
  }, [
    realState,
    agent,
    adviser,
    isActiveSearch,
    page
  ])
  
  /**
   *
   * @description Execute dispatch next page state
   * @return dispatch
   *
   */
  const handleNextPage = () => dispatch( setPageRegister(page + 1) )

  return (
    <Layout.Box
      id="scrollRegister"
      width="100%"
      margin="0 auto"
      overflow="scroll"
      className="card-kanban"
    >
      <Feedback.InfinityScrolling
        dataLength={arrayList.length}
        next={handleNextPage}
        hasMore={hasNextPage}
        loader={isLoading && <Layout.KanbanRowLoading />}
        endMessage={<div className="mt-4 mb-4" />}
        scrollableTarget="scrollRegister"
      >
        {!isLoading && arrayList.length === 0 && (
          <div className="font-family-roboto-regular fs-6 text-center">No hay datos para esta consulta</div>
        )}
        {!isLoading && arrayList.length > 0 && (
          arrayList.map((filtered, index) => (
            <KanbanRowComponent
              key={index}
              filtered={filtered}
              status="Registrado"
            />
          ))
        )}
      </Feedback.InfinityScrolling>
    </Layout.Box>
  )
}

KanbanRegisterComponent.propTypes = {
  isActiveSearch: PropTypes.bool.isRequired,
  realState: PropTypes.any,
  search: PropTypes.string,
  start: PropTypes.string,
  finish: PropTypes.string,
  agent: PropTypes.any,
  adviser: PropTypes.any,
}

export default KanbanRegisterComponent
