import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../../../../components/mui/Layout';
import TableRowComponent from './TableRowComponent';
import { getArrayList } from '../services';
import { setIsLoading } from '../../../../../redux/loading/actions/loadingAction';
import { setPerPage, setPage, setPages, setArrayList } from '../redux/actions/projectAction';
import swalErrors from '../../../../../hooks/useErrors';
import usePermission from '../../../../../hooks/usePermission';
import { projectSuperAdminColumns, projectAdminColumns, perPages } from '../../../../../helpers/variablesColumns';

const TableComponent = () => {
	const dispatch = useDispatch();
	const userData = useSelector(({ auth }) => auth.userData);
	const isLoading = useSelector( ({ loading }) => loading.isLoading);
	const refreshTable = useSelector( ({ project }) => project.refreshTable);
	const perPage = useSelector( ({ project }) => project.perPage);
	const page = useSelector( ({ project }) => project.page);
	const search = useSelector( ({ project }) => project.search);
	const realState = useSelector(({ project }) => project.realState);
	const pages = useSelector( ({ project }) => project.pages);
	const arrayList = useSelector( ({ project }) => project.arrayList);
	const permission = usePermission('projects.list');
	
	/**
	 *
	 * @description Execute endpoint array list of projects
	 * @return dispatch
	 *
	 */
	const handleArrayList = async () => {
		if (permission) {
			await dispatch(setIsLoading(page === 1 ? true : false));
			
			try {
				const param = {
					perPage: perPage,
					page: page,
					search: search === '' ? null : search,
					realstate: realState
				};
				
				const {data} = await getArrayList(param);
				
				if (data.status === 200) {
					await dispatch(setPages(data.data.pages));
					await dispatch(setArrayList(data.data.records));
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch(setIsLoading(false));
		}
	};
	
	useEffect(() => {
		handleArrayList();
	}, [
		perPage,
		page,
		refreshTable,
		realState
	]);
	
	/**
	 *
	 * @description Execute dispatch perPage state
	 * @return dispatch
	 *
	 */
	const handleChangePerPage = ({ target }) => dispatch( setPerPage(target.value) );
	
	/**
	 *
	 * @description Execute dispatch page state
	 * @return dispatch
	 *
	 */
	const handleChangePage = (event, value) => dispatch( setPage(value) );
	
	return (
		<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
			<Layout.CardContent className="m-0 p-4">
				{permission && (
					<>
						<Layout.TablePagination
							arrayList={arrayList.length}
							perPage={perPage}
							perPages={perPages}
							page={page}
							pages={pages}
							handleChangePerPage={handleChangePerPage}
							handleChangePage={handleChangePage}
						/>
						<Layout.Table>
							<Layout.TableHead columns={userData.roles[0] === 'Super Administrador' ? projectSuperAdminColumns : projectAdminColumns} />
							<tbody>
								{isLoading && (
									<Layout.TableRowLoading columns={userData.roles[0] === 'Super Administrador' ? projectSuperAdminColumns : projectAdminColumns} />
								)}
								{!isLoading && (
									<>
										{arrayList.length === 0 && (
											<Layout.TableRowNoFound colSpan={userData.roles[0] === 'Super Administrador' ? projectSuperAdminColumns.length : projectAdminColumns.length} />
										)}
										{arrayList.length > 0 && (
											arrayList.map(({ id, realstate, name, status }, index) => (
												<TableRowComponent
													key={index}
													id={id}
													realState={realstate}
													name={name}
													status={status}
												/>
											))
										)}
									</>
								)}
							</tbody>
						</Layout.Table>
						<Layout.TablePagination
							arrayList={arrayList.length}
							perPage={perPage}
							perPages={perPages}
							page={page}
							pages={pages}
							handleChangePerPage={handleChangePerPage}
							handleChangePage={handleChangePage}
						/>
					</>
				)}
			</Layout.CardContent>
		</Layout.Card>
	);
};

export default TableComponent;
