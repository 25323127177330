import InfinityScrolling from './InfinityScrolling'
import Loading from './Loading'
import MapBox from './MapBox'
import Modal from './Modal'
import ModalLoading from './ModalLoading'
import ScrollBar from './ScrollBar'
import Skeleton from './Skeleton'

const index = {
	InfinityScrolling,
	Loading,
	MapBox,
	Modal,
	ModalLoading,
	ScrollBar,
	Skeleton
}

export default index
