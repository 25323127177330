import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { history, store, persistor } from './redux/store';
import ThemeProviderComponent from './theme-v2/themeProvider';
import AppRouter from './routers/AppRouter';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'animate.css/animate.min.css';
import 'simplebar/src/simplebar.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl/dist/mapbox-gl.js';
import './App.scss';

const App = () => {
  
  return (
    <ThemeProviderComponent>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate
            loading={null}
            persistor={persistor}
          >
            <AppRouter history={history} />
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </ThemeProviderComponent>
  );
};

export default App;
