import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Display from '../../../../../components/display/Display'
import Controls from '../../../../../components/controls/Controls'
import errorServices from '../../../../../services/errors'
import { swalSuccess } from '../../../../../services/sweetalert'
import axios from '../../../../../services/axios'

const FormCreate = ({ getData, getOpcion, getOpcionForm, handleList, setOpenModal }) => {
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [arrayDropdownState, setDropdownState] = useState([])
	const [arrayDropdownCity, setDropdownCity] = useState([])
	const [arrayDropdownBank, setDropdownBank] = useState([])
	const [arrayDropdownOriginChannel, setDropdownOriginChannel] = useState([])
	const [getLoading, setLoading] = useState(true)
	const [getPersonType, setPersonType] = useState(getData.person_type === undefined ? 'Natural' : getData.person_type)
	const [getCompany, setCompany] = useState(getData.company === undefined ? '' : getData.company)
	const [getCompanyError, setCompanyError] = useState(false)
	const [getCompanyMessage, setCompanyMessage] = useState("")
	const [getFirstName, setFirstName] = useState(getData.first_name === undefined ? '' : getData.first_name)
	const [getFirstNameError, setFirstNameError] = useState(false)
	const [getFirstNameMessage, setFirstNameMessage] = useState("")
	const [getLastName, setLastName] = useState(getData.last_name === undefined ? '' : getData.last_name)
	const [getLastNameError, setLastNameError] = useState(false)
	const [getLastNameMessage, setLastNameMessage] = useState("")
	const [getEmail, setEmail] = useState(getData.email === undefined ? '' : getData.email)
	const [getEmailError, setEmailError] = useState(false)
	const [getEmailMessage, setEmailMessage] = useState("")
	const [getDocType, setDocType] = useState(getData.doc_type === undefined ? 'DNI' : getData.doc_type)
	const [getDocID, setDocID] = useState(getData.doc_id === undefined ? '' : getData.doc_id)
	const [getMobile, setMobile] = useState(getData.mobile === undefined ? '' : getData.mobile)
	const [getMobileError, setMobileError] = useState(false)
	const [getMobileMessage, setMobileMessage] = useState("")
	const [getPhone, setPhone] = useState(getData.phone === undefined ? '' : getData.phone)
	const [getAddress, setAddress] = useState(getData.address === undefined ? '' : getData.address)
	const [getFirstNameContact, setFirstNameContact] = useState(getData.representative_first_name === undefined ? '' : getData.representative_first_name)
	const [getFirstNameContactError, setFirstNameContactError] = useState(false)
	const [getFirstNameContactMessage, setFirstNameContactMessage] = useState("")
	const [getLastNameContact, setLastNameContact] = useState(getData.representative_last_name === undefined ? '' : getData.representative_last_name)
	const [getLastNameContactError, setLastNameContactError] = useState(false)
	const [getLastNameContactMessage, setLastNameContactMessage] = useState("")
	const [getEmailContact, setEmailContact] = useState(getData.representative_email === undefined ? '' : getData.representative_email)
	const [getEmailContactError, setEmailContactError] = useState(false)
	const [getEmailContactMessage, setEmailContactMessage] = useState("")
	const [getMobileContact, setMobileContact] = useState(getData.representative_mobile === undefined ? '' : getData.representative_mobile)
	const [getMobileContactError, setMobileContactError] = useState(false)
	const [getMobileContactMessage, setMobileContactMessage] = useState("")
	const [getStateID, setStateID] = useState(getData.state_id === undefined ? '' : getData.state_id)
	const [getCityID, setCityID] = useState(getData.city_id === undefined ? '' : getData.city_id)
	const [getOriginChannelID, setOriginChannelID] = useState(getData.origin_channel_id === undefined ? '' : getData.origin_channel_id)
	const [getAcceptData, setAcceptData] = useState(getData.accept_data === undefined ? 0 : getData.accept_data)
	const [getBankID, setBankID] = useState(getData.bank_id === undefined ? 'Ahorro' : getData.bank_id)
	const [getAccountType, setAccountType] = useState(getData.account_type === undefined ? 'Ahorro' : getData.account_type)
	const [getAccountNumber, setAccountNumber] = useState(getData.account_number === undefined ? '' : getData.account_number)
	const [getNotes, setNotes] = useState(getData.notes === undefined ? '' : getData.notes)
	
	const handleDropdownState = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/location/states/by-country/1`)
			
			if (data.status === 200) {
				setDropdownState(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
		handleDropdownState()
	}, [])
	
	const handleDropdownCity = async (id) => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/location/cities/by-state/${id}`)
			
			if (data.status === 200) {
				setDropdownCity(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
		getData.state_id !== undefined && handleDropdownCity(getData.state_id)
	}, [getData.state_id])
	
	const handleDropdownBank = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/banks`)
			
			if (data.status === 200) {
				setDropdownBank(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
		getAcceptData === 1 && handleDropdownBank()
	}, [getAcceptData])
	
	const handleDropdownOriginChannel = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/filtered/origin-channels`)
			
			if (data.status === 200) {
				setDropdownOriginChannel(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
		handleDropdownOriginChannel()
	}, [])
	
	const setHandleForm = async (e) => {
		e.preventDefault()
		
		if (getEmail === "" || getMobile === ""){
			setEmailError(getEmail === "" ? true : false)
			setEmailMessage(getEmail === "" ? "El campo es requerido" : "")
			setMobileError(getMobile === "" ? true : false)
			setMobileMessage(getMobile === "" ? "El campo es requerido" : "")
			return false
		}
		
		if (getPersonType === 'Natural') {
			if (getFirstName === "" || getLastName === ""){
				setFirstNameError(getFirstName === "" ? true : false)
				setFirstNameMessage(getFirstName === "" ? "El campo es requerido" : "")
				setLastNameError(getLastName === "" ? true : false)
				setLastNameMessage(getLastName === "" ? "El campo es requerido" : "")
				return false
			}
		} else {
			if (getCompany === "" || getFirstNameContact === "" || getEmailContact === "" || getMobileContact === ""){
				setCompanyError(getCompany === "" ? true : false)
				setCompanyMessage(getCompany === "" ? "El campo es requerido" : "")
				setFirstNameContactError(getFirstNameContact === "" ? true : false)
				setFirstNameContactMessage(getFirstNameContact === "" ? "El campo es requerido" : "")
				setLastNameContactError(getLastNameContact === "" ? true : false)
				setLastNameContactMessage(getLastNameContact === "" ? "El campo es requerido" : "")
				setEmailContactError(getEmailContact === "" ? true : false)
				setEmailContactMessage(getEmailContact === "" ? "El campo es requerido" : "")
				setMobileContactError(getMobileContact === "" ? true : false)
				setMobileContactMessage(getMobileContact === "" ? "El campo es requerido" : "")
				return false
			}
		}
		
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				"person_type": getPersonType,
				"company": getPersonType === 'Natural' ? null : getCompany,
				"first_name": getPersonType === 'Natural' ? getFirstName : null,
				"last_name": getPersonType === 'Natural' ? getLastName : null,
				"email": getEmail,
				"doc_type": getPersonType === 'Natural' ? getDocType : 'NIT',
				"doc_id": getDocID === '' ? null : getDocID,
				"mobile": getMobile,
				"phone": getPhone === '' ? null : getPhone,
				"address": getAddress === '' ? null : getAddress,
				"city_id": getCityID === '' ? null : getCityID,
				"origin_channel_id": getOriginChannelID === '' ? null : getOriginChannelID,
				"representative_first_name": getPersonType === 'Natural' ? null : getFirstNameContact,
				"representative_last_name": getPersonType === 'Natural' ? null : getLastNameContact,
				"representative_email": getPersonType === 'Natural' ? null : getEmailContact,
				"representative_mobile": getPersonType === 'Natural' ? null : getMobileContact,
				"accept_data": getAcceptData === 1 ? 1 : 0,
				"bank_id": getAcceptData === 1 ? (getBankID === '' ? null : getBankID) : null,
				"account_type": getAcceptData === 1 ? (getAccountType === '' ? null : getAccountType) : null,
				"account_number": getAcceptData === 1 ? (getAccountNumber === '' ? null : getAccountNumber) : null,
				"notes": getNotes === '' ? null : getNotes
			}
			
			const data = await axios.post('dashboard/owners', param)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				handleList()
				setFirstName('')
				setLastName('')
				setPersonType('Natural')
				setDocType('DNI')
				setDocID('')
				setMobile('')
				setPhone('')
				setAddress('')
				setStateID('')
				setCityID('')
				setOriginChannelID('')
				setFirstNameContact('')
				setLastNameContact('')
				setEmailContact('')
				setMobileContact('')
				setAcceptData(0)
				setBankID('')
				setAccountType('Ahorro')
				setAccountNumber('')
				if (getOpcion !== 'modal') {
					setOpenModal(false)
				}
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	const handleCancel = () => {
		if (getOpcion === 'modal') {
			handleList()
		} else {
			setOpenModal(false)
		}
	}
	
	return (
		<form>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Display.Typography
						variant="subtitle1"
						color="error"
						label="Los datos con (*) son requeridos"
					/>
				</Grid>
				<Grid item xs={12}>
					<FormGroup row>
						<FormLabel component="legend">* Tipo de Persona</FormLabel>
					</FormGroup>
					<div className="btn-group" role="group" aria-label="Basic example">
						<button
							type="button"
							onClick={() => {
								setPersonType('Natural')
								setDocType('DNI')
							}}
							className={`btn btn-outline-white height-45 font-family-roboto-bold font-size-18 ${getPersonType === 'Natural' && 'active'}`}
						>
							Natural
						</button>
						<button
							type="button"
							onClick={() => {
								setPersonType('Jurídica')
								setDocType('NIT')
							}}
							className={`btn btn-outline-white height-45 font-family-roboto-bold font-size-18 ${getPersonType === 'Jurídica' && 'active'}`}
						>
							Jurídica
						</button>
					</div>
				</Grid>
				{getPersonType === 'Jurídica' && (
					<Grid item xs={12}>
						<Controls.TextField
							label="* Razón social"
							name="company"
							defaultValue={getCompany}
							error={getCompanyError}
							message={getCompanyMessage}
							onChange={e => {
								setCompany(e.target.value)
								
								if(e.target.value === ""){
									setCompanyError(true)
									setCompanyMessage('El campo es requerido')
								} else {
									setCompanyError(false)
									setCompanyMessage('')
								}
							}}
							onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
						/>
					</Grid>
				)}
				{getPersonType === 'Natural' && (
					<>
						<Grid item xs={12} sm={6}>
							<Controls.TextField
								label="* Nombre"
								name="first_name"
								defaultValue={getFirstName}
								error={getFirstNameError}
								message={getFirstNameMessage}
								onChange={e => {
									setFirstName(e.target.value)
									
									if(e.target.value === ""){
										setFirstNameError(true)
										setFirstNameMessage('El campo es requerido')
									} else {
										setFirstNameError(false)
										setFirstNameMessage('')
									}
								}}
								onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controls.TextField
								label="* Apellido"
								name="last_name"
								defaultValue={getLastName}
								error={getLastNameError}
								message={getLastNameMessage}
								onChange={e => {
									setLastName(e.target.value)
									
									if(e.target.value === ""){
										setLastNameError(true)
										setLastNameMessage('El campo es requerido')
									} else {
										setLastNameError(false)
										setLastNameMessage('')
									}
								}}
								onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
							/>
						</Grid>
					</>
				)}
				<Grid item xs={12}>
					<Controls.TextField
						label="* Email"
						name="email"
						defaultValue={getEmail}
						error={getEmailError}
						message={getEmailMessage}
						onChange={e => {
							setEmail(e.target.value)
							
							if(e.target.value === ""){
								setEmailError(true)
								setEmailMessage('El campo es requerido')
							} else {
								setEmailError(false)
								setEmailMessage('')
							}
						}}
						onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormGroup row>
						<FormLabel component="legend">* Tipo de Documento</FormLabel>
					</FormGroup>
					<div className="btn-group" role="group" aria-label="Basic example">
						{getPersonType === 'Natural' && (
							<>
								<button
									type="button"
									onClick={() => setDocType('DNI')}
									className={`btn btn-outline-white height-45 font-family-roboto-bold font-size-18 ${getDocType === 'DNI' && 'active'}`}
								>
									Cédula
								</button>
								<button
									type="button"
									onClick={() => setDocType('Pasaporte')}
									className={`btn btn-outline-white height-45 font-family-roboto-bold font-size-18 ${getDocType === 'Pasaporte' && 'active'}`}
								>
									Pasaporte
								</button>
							</>
						)}
						{getPersonType === 'Jurídica' && (
							<button
								type="button"
								className="btn btn-outline-white height-45 font-family-roboto-bold font-size-18 active"
							>
								NIT
							</button>
						)}
					</div>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Controls.TextFieldNumber
						label="Documento de Identidad"
						name="doc_id"
						defaultValue={getDocID}
						onChange={e => setDocID(e.target.value)}
						onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Controls.TextFieldNumber
						label="* Celular"
						name="mobile"
						defaultValue={getMobile}
						error={getMobileError}
						message={getMobileMessage}
						onChange={e => {
							setMobile(e.target.value)
							
							if(e.target.value === ""){
								setMobileError(true)
								setMobileMessage('El campo es requerido')
							} else {
								setMobileError(false)
								setMobileMessage('')
							}
						}}
						onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Controls.TextFieldNumber
						label="Teléfono"
						name="phone"
						defaultValue={getPhone}
						onChange={e => setPhone(e.target.value)}
						onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Controls.Select
						label="Departamento/Estado"
						name="state"
						defaultValue={getStateID}
						onChange={(e) => {
							handleDropdownCity(e.target.value)
							setStateID(e.target.value)
						}}
						arrayDropdown={arrayDropdownState}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Controls.Select
						label="Ciudad/Provincia"
						name="city"
						defaultValue={getCityID}
						onChange={(e) => setCityID(e.target.value)}
						arrayDropdown={arrayDropdownCity}
					/>
				</Grid>
				<Grid item xs={12}>
					<Controls.TextArea
						label="Dirección"
						name="address"
						defaultValue={getAddress}
						onChange={e => setAddress(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Controls.Select
						label="Canal de origen"
						name="channel_id"
						defaultValue={getOriginChannelID}
						onChange={e => setOriginChannelID(e.target.value)}
						arrayDropdown={arrayDropdownOriginChannel}
					/>
				</Grid>
				{getPersonType === 'Jurídica' && (
					<>
						<Grid item xs={12} sm={6}>
							<Controls.TextField
								label="* Nombre (Representante Legal)"
								name="first_name_repre"
								defaultValue={getFirstNameContact}
								error={getFirstNameContactError}
								message={getFirstNameContactMessage}
								onChange={e => {
									setFirstNameContact(e.target.value)
									
									if(e.target.value === ""){
										setFirstNameContactError(true)
										setFirstNameContactMessage('El campo es requerido')
									} else {
										setFirstNameContactError(false)
										setFirstNameContactMessage('')
									}
								}}
								onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controls.TextField
								label="* Apellido (Representante Legal)"
								name="last_name_repre"
								defaultValue={getLastNameContact}
								error={getLastNameContactError}
								message={getLastNameContactMessage}
								onChange={e => {
									setLastNameContact(e.target.value)
									
									if(e.target.value === ""){
										setLastNameContactError(true)
										setLastNameContactMessage('El campo es requerido')
									} else {
										setLastNameContactError(false)
										setLastNameContactMessage('')
									}
								}}
								onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controls.TextField
								label="* Email (Representante Legal)"
								name="email_repre"
								defaultValue={getEmailContact}
								error={getEmailContactError}
								message={getEmailContactMessage}
								onChange={e => {
									setEmailContact(e.target.value)
									
									if(e.target.value === ""){
										setEmailContactError(true)
										setEmailContactMessage('El campo es requerido')
									} else {
										setEmailContactError(false)
										setEmailContactMessage('')
									}
								}}
								onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controls.TextFieldNumber
								label="* Celular (Representante Legal)"
								name="mobile"
								defaultValue={getMobileContact}
								error={getMobileContactError}
								message={getMobileContactMessage}
								onChange={e => {
									setMobileContact(e.target.value)
									
									if(e.target.value === ""){
										setMobileContactError(true)
										setMobileContactMessage('El campo es requerido')
									} else {
										setMobileContactError(false)
										setMobileContactMessage('')
									}
								}}
								onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
							/>
						</Grid>
					</>
				)}
				<Grid item xs={12}>
					<Controls.Switch
						label="¿Aceptó habeas data?"
						name="accept_data"
						checked={getAcceptData === 1 ? true : false}
						onClick={() => setAcceptData(getAcceptData === 1 ? 0 : 1)}
					/>
				</Grid>
				{getAcceptData === 1 && (
					<>
						<Grid item xs={12} sm={6}>
							<Controls.Select
								label="Banco"
								name="bank_id"
								defaultValue={getBankID}
								onChange={(e) => setBankID(e.target.value)}
								arrayDropdown={arrayDropdownBank}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormGroup row>
								<FormLabel component="legend">Tipo de Cuenta</FormLabel>
							</FormGroup>
							<div className="btn-group" role="group" aria-label="Basic example">
								<button
									type="button"
									onClick={() => setAccountType('Ahorro')}
									className={`btn btn-outline-white height-45 font-family-roboto-bold font-size-18 ${getAccountType === 'Ahorro' && 'active'}`}
								>
									Ahorro
								</button>
								<button
									type="button"
									onClick={() => setAccountType('Corriente')}
									className={`btn btn-outline-white height-45 font-family-roboto-bold font-size-18 ${getAccountType === 'Corriente' && 'active'}`}
								>
									Corriente
								</button>
							</div>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controls.TextFieldNumber
								label="Número de Cuenta"
								name="account_number"
								defaultValue={getAccountNumber}
								onChange={e => setAccountNumber(e.target.value)}
								onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
							/>
						</Grid>
					</>
				)}
				<Grid item xs={12}>
					<Controls.TextArea
						label="Notas"
						name="notes"
						defaultValue={getNotes}
						onChange={e => setNotes(e.target.value)}
						onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
					/>
				</Grid>
				<Grid item xs={12}>
					<div className="float-right">
						<Controls.Button
							label="Cancelar"
							color="secondary"
							variant="contained"
							endIcon={<Display.Icons.CancelOutlinedIcon />}
							onClick={handleCancel}
						/>
						<Controls.Button
							label="Guardar"
							color="primary"
							variant="contained"
							endIcon={<Display.Icons.SaveOutlinedIcon />}
							onClick={setHandleForm}
						/>
					</div>
				</Grid>
			</Grid>
		</form>
	)
}

export default FormCreate
