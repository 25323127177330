import React from 'react';
import Box from '@mui/material/Box';
import './Styles.css';

export default function FullscreenThumbnail(props) {
  const { url, open, onClose  } = props
  React.useEffect( function(){
    const listener = function(event){
      // console.log(event);
      if(event.key == 'Escape' ){
        //close
        onClose()
        document.querySelector('.fullscreen-container').style.transform = 'scale(0)';
      }

    }
    document.addEventListener('keydown', listener);

    return ()=>{
      document.removeEventListener('keydown', listener);
    }
  },[]);

  React.useEffect(function(){
    if(open){
      document.querySelector('.fullscreen-container').style.transform = 'scale(1)';
      return;
    }
    document.querySelector('.fullscreen-container').style.transform = 'scale(0)';
  },[open])
  return (
    <Box className='fullscreen-container' >
      {/* <div className="modalBarrier" /> */}
      <svg style={{
          position:"relative",
          right:'5px',
          cursor:'pointer',
          float:'right',
          margin:'8px',
        }}
        onClick={onClose}
        height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)"><circle cx="8.5" cy="8.5" r="8"/><g transform="matrix(0 1 -1 0 17 0)"><path d="m5.5 11.5 6-6"/><path d="m5.5 5.5 6 6"/></g></g></svg>
      <img src={url} />
    </Box>
  )
}