import endPoints from './endPoints';
import axios from '../../../../services/axios';

const {
	arrayList,
	status,
} = endPoints;

/**
 *
 * @description Execute endpoint array list of commercial
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const getArrayList = async (data) => (
	await axios.post(arrayList, data)
);

/**
 *
 * @description Execute endpoint change status by id
 * @param id
 * @param data
 * @method put
 * @return jsonResponse
 *
 */
export const setUpdateStatusById = async (id, data) => (
	await axios.put(`${status}${id}`, data)
);
