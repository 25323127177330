/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoaderGallery from '../../../../pages/dashboard/TestPage/components/loader/LoaderGallery'
import Skeleton from '../skeleton'

export function GalleryTourVirtual({
  dataArrayTourVirtual,
  isLoading,
  hasMore,
  setPage,
  height,
}) {
  return (
    <div
      id="scrollableTourVirtual"
      style={{ overflow: 'auto', height: '780px', padding: '10px' }}
    >
      {isLoading && <Skeleton type={'gallery'} />}
      <InfiniteScroll
        dataLength={dataArrayTourVirtual?.length || 0}
        next={() => setPage((prevPage) => prevPage + 1)}
        hasMore={hasMore}
        loader={<LoaderGallery />}
        scrollableTarget="scrollableTourVirtual"
      >
        <div>
          {dataArrayTourVirtual?.map((item, index) => (
            <div key={index}>
              <iframe
                style={{ backgroundColor: 'lightgray' }}
                width="100%"
                height={height ? height : 500}
                frameBorder={0}
                allow="xr-spatial-tracking; gyroscope; accelerometer"
                allowFullScreen
                scrolling="no"
                src={item.path}
              />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  )
}
