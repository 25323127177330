import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Feedback from '../../../../../components/mui/Feedback'
import DropdownActionComponent from './DropdownActionComponent'
import DeleteComponent from './DeleteComponent'
import { Permission } from '../../../../../services/permissions/Permission'
import errorServices from '../../../../../services/errors'
import { swalSuccess } from '../../../../../services/sweetalert'
import axios from '../../../../../services/axios'

const TableRowComponent = ({ id, area, areaName, floorName, width = '', long = '', amenities = '', handleList }) => {
	const tokenType = useSelector(state => state.auth.tokenType)
	const accessToken = useSelector(state => state.auth.accessToken)
	const [isOpenModal, setIsOpenModal] = useState(false)
	
	useEffect(() => {
	}, [id, area, areaName, floorName, width, long, amenities])
	
	const handleToDeleteById = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.delete(`dashboard/properties/areas/${id}`)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				handleList()
				handleIsOpenModal()
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const handleIsOpenModal = () => setIsOpenModal(!isOpenModal)
	
	return (
		<>
			<tr className="m-0 p-0">
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="20%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark">
						{ area }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="20%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark">
						{ areaName }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="15%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark">
						{ floorName }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2 text-center"
					width="10%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark">
						{ width }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2 text-center"
					width="10%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark">
						{ long }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="20%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark">
						{ amenities }
					</div>
				</td>
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="5%"
				>
					<div className="mx-auto d-flex justify-content-center">
						<DropdownActionComponent
							handleIsOpenModal={handleIsOpenModal}
						/>
					</div>
				</td>
			</tr>
			<Permission permission="properties.areas.delete">
				<Feedback.Modal
					maxWidth="sm"
					isOpen={isOpenModal}
					isClose={handleIsOpenModal}
					title="Eliminación del Área"
				>
					<DeleteComponent
						name={areaName}
						handleCloseModal={handleIsOpenModal}
						handleToDeleteById={handleToDeleteById}
					/>
				</Feedback.Modal>
			</Permission>
		</>
	)
}

TableRowComponent.propTypes = {
	id: PropTypes.number.isRequired,
	area: PropTypes.string.isRequired,
	areaName: PropTypes.string.isRequired,
	floorName: PropTypes.string.isRequired,
	width: PropTypes.string,
	long: PropTypes.string,
	amenities: PropTypes.string,
	handleList: PropTypes.func.isRequired
}

export default TableRowComponent
