import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Box, Button, Typography } from '@mui/material'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { GalleryMain, Tabs } from '../../../../components/v2-mobili/gallery'
import { PropertyDetails } from './components/propertyDetails'
import { FormFicha } from './components/formFicha'
import { Footer, Header, Modal } from '../../../../components/v2-mobili'
import { useTaps } from '../../../../components/v2-mobili/gallery/hooks/useTaps'
import { useModal } from '../../../../components/v2-mobili/hooks'
import ThemeProviderComponent from '../../../../theme-v2/themeProvider'
import MainActivity from '../../../../components/v2-mobili/activity/mainActivity/MainActivity'
import ShareIcon from '../../../../components/display/icons/ShareIcon'
import TableActivityLog from '../../../../components/activity-logs/TableActivityLog'
import { setActivityLogReset, setLogName, setSubjectId } from '../../../../redux/actions/dashboard/activities/activityLogAction'
import dataArray from './utils/features.json'
import styles from './inventory.module.css'

const itemsTaps = [
  {
    id: 1,
    name: 'Galería',
  },
  {
    id: 2,
    name: 'Actividad',
  },
]

export function InventoryView({ urlDetalleInmueble = false, isInventoryInternal = false, uID = '', showHeader = false, isAuthenticatedPage = false }) {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector( state => state.auth.isAuthenticated)
  const formData = useSelector(state => urlDetalleInmueble ? (isAuthenticatedPage ? state.propertyDetail.formData : state.propertyDetailNoAuth.formData) : state.ownerInventory.formData)
  const arrayListAlbumsTypes = useSelector(state => urlDetalleInmueble ? (isAuthenticatedPage ? state.propertyDetail.arrayListAlbumsTypes : state.propertyDetailNoAuth.arrayListAlbumsTypes) : state.ownerInventory.arrayListAlbumsTypes)
  const pageActivityLog = useSelector(state => state.activityLog.page)
  const pagesActivityLog = useSelector(state => state.activityLog.pages)
  const logName = useSelector(state => state.activityLog.logName)
  const subjectId = useSelector(state => state.activityLog.subjectId)
  const [showAlert, setShowAlert] = useState(true)
  const { handleTap, isActive } = useTaps()
  const modalShare = useModal()

  const handleCopyEnlace = () => {
    let url = document.createElement('input')
    url.setAttribute(
      'value',
      `${window.location.protocol}//${window.location.host}/inventory-fiche/${uID}`,
    )
    document.body.appendChild(url)
    url.select()
    document.execCommand('copy')
    document.body.removeChild(url)

    setShowAlert(false)

    setTimeout(() => {
      setShowAlert(true)
    }, 5000)
  }
  
  const handleActivityLog = async () => {
    await dispatch( setActivityLogReset() )
    await dispatch( setLogName('Propiedades') )
    await dispatch( setSubjectId(formData.id) )
  }
  
  useEffect(() => {
    if (isAuthenticated && isActive === 2) {
      handleActivityLog()
    }
  }, [formData.id, isActive])
  
  return (
    <ThemeProviderComponent>
      {showHeader && <Header />}
      <main className={styles.gridContainer}>
        <section className={styles.left}>
          <PropertyDetails
            urlDetalleInmueble={urlDetalleInmueble}
            formData={formData}
          />
          <FormFicha
            dataArrayFeatures={dataArray}
            urlDetalleInmueble={urlDetalleInmueble}
            isInventoryInternal={isInventoryInternal}
            formData={formData}
          />
        </section>
        <section className={styles.footerMain}>
          <Footer />
        </section>
        <section className={styles.right}>
          {urlDetalleInmueble ? (
            <>
              <Tabs
                isAuthenticated={isAuthenticated}
                itemsArray={itemsTaps}
                tapActive={isActive}
                handleTap={handleTap}
                tabsHeader
              />
              {isActive === 1 && (
                <GalleryMain
                  formData={formData}
                />
              )}
              {/* ACTIVITY LOG */}
              {isAuthenticated && isActive === 2 && (
                <>
                  <TableActivityLog
                    page={pageActivityLog}
                    pages={pagesActivityLog}
                    logName={logName}
                    subjectId={subjectId}
                  />
                  {/*<MainActivity />*/}
                </>
              )}
            </>
          ) : (
            <>
              {arrayListAlbumsTypes?.length > 0 && (
                <GalleryMain
                  formData={formData}
                />
              )}
              <Box mt={5} display="flex" justifyContent="center">
                {isInventoryInternal && (
                  <Button
                    onClick={modalShare.openModalFunc2}
                    sx={{ textTransform: 'none' }}
                    variant="outlined"
                    startIcon={<ShareIcon />}
                    className="bg-secondary-outlined"
                  >
                    Compartir
                  </Button>
                )}
              </Box>
            </>
          )}
        </section>

        <Modal
          show={modalShare.isOpenModal}
          closeModal={modalShare.closeModalFunc2}
          width={'modalMd'}
          position={'pMedium'}
        >
          <Box
            display="flex"
            alignItems={'flex-start'}
            justifyContent="flex-start"
            flexDirection={'column'}
            gap={3}
          >
            <Typography variant="h3">Compartir ficha comercial</Typography>
            <Typography variant="h4">Enviar vínculo</Typography>
            <Typography variant="span">
              Cualquier persona que tenga el vínculo podrá acceder a la
              ficha compartida.
            </Typography>

            <Box sx={{ display: showAlert ? 'none' : '' }}>
              <Alert
                sx={{ width: '100%' }}
                iconMapping={{
                  success: <CheckCircleOutlineIcon fontSize="inherit" />,
                }}
              >
                Enlace copiado con Exito!
              </Alert>
            </Box>

            <Button
              onClick={handleCopyEnlace}
              sx={{ textTransform: 'none' }}
              variant="outlined"
              startIcon={<InsertLinkIcon />}
              className="bg-secondary-outlined"
            >
              Copiar enlace
            </Button>
          </Box>
        </Modal>
      </main>
    </ThemeProviderComponent>
  )
}
