import React from 'react'
import LinkedIn from '@material-ui/icons/LinkedIn'
import PropTypes from 'prop-types'

const LinkedInIcon = ({ height, width, ...props }) => {

    return (
        <LinkedIn
            height={height}
            width={width}
            {...props}
        />
    )
}

LinkedInIcon.propTypes = {
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
}

export default LinkedInIcon