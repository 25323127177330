import React from 'react'
import { TextFieldReadOnly } from '../fields'
import styles from './formFicha.module.css'

export const FormFicha = ({ information }) => {
  return (
    <div className={styles.cardForm}>
      <div className={styles.dFlexBetween}>
        <TextFieldReadOnly
          label={'Tipo de propiedad'}
          value={`${information?.type || ''}`}
        />
        <TextFieldReadOnly
          label={'Acción'}
          value={`${information?.action || ''}`}
        />
        <TextFieldReadOnly
          label={'Destinación'}
          value={`${information?.destination || ''}`}
        />
      </div>
      <div className={styles.dFlexBetween}>
        <TextFieldReadOnly
          label={'Estrato'}
          value={`${information?.strata || ''}`}
        />
        <TextFieldReadOnly
          label={'Habitaciones'}
          value={`${information?.number_room || ''}`}
        />
        <TextFieldReadOnly
          label={'Baños'}
          value={`${information?.number_bathroom || ''}`}
        />
      </div>

      <div className={styles.dFlexBetween}>
        <TextFieldReadOnly
          label={'Garage'}
          value={`${
            information?.has_garage ? information?.number_garage : 'No'
          }`}
        />
        <TextFieldReadOnly
          label={'Posee edificación'}
          value={`${information?.has_building || ''}`}
        />
      </div>

      <div className={styles.dFlexBetween}>
        <TextFieldReadOnly
          label={'Propiedad'}
          value={`${information?.mobili_code || ''}`}
        />
        <TextFieldReadOnly
          label={'Álbum'}
          value={`${information?.album_name || ''}`}
        />
        <TextFieldReadOnly
          label={'Código Domus'}
          value={`${information?.domus_code || ''}`}
        />
      </div>
    </div>
  )
}
