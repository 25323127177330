import * as types from '../types/sidebarType';

/**
 *
 * @description Set openSidebar state
 * @param value
 * @return object
 *
 */
export const setIsOpenSidebar = value => ({
	type: types.IS_OPEN_SIDEBAR,
	payload: value
});

/**
 *
 * @description Set loadingSidebar state
 * @param value
 * @return object
 *
 */
export const setIsLoadingSidebar = value => ({
	type: types.IS_LOADING_SIDEBAR,
	payload: value
});
