import React from 'react'
import { useSelector } from 'react-redux'
import { SxProps, Theme } from '@mui/material'
import Layout from '../../../../../components/mui/Layout'
import KanbanRegisterComponent from './KanbanRegisterComponent'
import KanbanDiscardedComponent from './KanbanDiscardedComponent'
import KanbanCommercialComponent from './KanbanCommercialComponent'

const KanbanComponent = () => {
	const isActiveSearch = useSelector( state => state.filtered.isActiveSearch)
	const { realState, search, start, finish, agent, adviser } = useSelector( state => state.filtered.formFilter)
	
	return (
		<Layout.Box sx={KanbanWrapperStyle}>
			<Layout.Box sx={KanbanColumnStyle}>
				<Layout.Typography
					variant="h5"
					textAlign="center"
					color="dark.default"
				>
					BORRADOR
				</Layout.Typography>
				<Layout.Box
					sx={ScrollContentStyle}
					className="card-kanban"
				>
					<KanbanRegisterComponent
						isActiveSearch={isActiveSearch}
						realState={realState}
						search={search}
						start={start}
						finish={finish}
						agent={agent}
						adviser={adviser}
					/>
				</Layout.Box>
			</Layout.Box>
			<Layout.Box sx={KanbanColumnStyle}>
				<Layout.Typography
					variant="h5"
					textAlign="center"
					color="dark.default"
				>
					DESECHADO
				</Layout.Typography>
				<Layout.Box
					sx={ScrollContentStyle}
					className="card-kanban"
				>
					<KanbanDiscardedComponent
						isActiveSearch={isActiveSearch}
						realState={realState}
						search={search}
						start={start}
						finish={finish}
						agent={agent}
						adviser={adviser}
					/>
				</Layout.Box>
			</Layout.Box>
			<Layout.Box sx={KanbanColumnStyle}>
				<Layout.Typography
					variant="h5"
					textAlign="center"
					color="dark.default"
				>
					COMERCIAL
				</Layout.Typography>
				<Layout.Box
					sx={ScrollContentStyle}
					className="card-kanban"
				>
					<KanbanCommercialComponent
						isActiveSearch={isActiveSearch}
						realState={realState}
						search={search}
						start={start}
						finish={finish}
						agent={agent}
						adviser={adviser}
					/>
				</Layout.Box>
			</Layout.Box>
		</Layout.Box>
	)
}

/**
 * @type {SxProps<Theme>}
 */
const KanbanWrapperStyle = {
	m: 2,
	gap: "30px",
	display: "grid",
	margin: '0 auto',
	mt:2,
	maxWidth:'1080px',
	gridTemplateColumns: "repeat(auto-fill, minmax(340px,1fr))",
}

/**
 * @type {SxProps<Theme>}
 */
const KanbanColumnStyle = {
	display: "flex",
	borderRadius: "5px",
	flexDirection: "column",
	gap: ({ spacing }) => spacing(2.5), // 20px
	p: ({ spacing }) => spacing(1.875, 1.25), // 15px 10px
	bgcolor: "lightGrey.default",
	height: "90vh", // ? 715px figma has design
	overflow: "hidden",
	position: "relative",
}

const ScrollContentStyle = {
	gap: 2,
	display: "flex",
	overflowY: "scroll",
	overflowX: "none",
	flexDirection: "column",
}

export default KanbanComponent
