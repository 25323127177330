import endPoints from './endPoints'
import axios from '../../../../../services/axios'

const {
	arrayList,
	arrayListProperties,
	store,
	show,
	showByMobile,
	update,
	status,
	destroy
} = endPoints

export const getArrayList = async (data) => (
	await axios.post(arrayList, data)
)

export const getArrayListProperties = async (data) => (
	await axios.post(arrayListProperties, data)
)

export const setStore = async (data) => (
	await axios.post(store, data)
)

export const getShowById = async (id) => (
	await axios.get(`${show}${id}`)
)

export const getShowByMobile = async (mobile) => (
	await axios.get(`${showByMobile}${mobile}`)
)

export const setUpdateById = async (id, data) => (
	await axios.put(`${update}${id}`, data)
)

export const setChangeStatusById = async (id) => (
	await axios.get(`${status}${id}`)
)

export const setDeleteById = async (id) => (
	await axios.delete(`${destroy}${id}`)
)
