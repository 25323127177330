import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  Grid,
  Stack,
  Button,
  Typography,
  Chip,
  styled,
  Rating,
} from '@mui/material'
import { history } from '../../../../redux/store'
import Layout from '../../../../components/mui/Layout'
import ListIconsDetails from '../../../../components/properties/icons/ListIconsDetails/ListIconsDetails'
import { setFormDataCustomer, setIsOpenCustomerModal } from '../../../../redux/actions/dashboard/filtered/filteredAction'
import { setIsOpenCustomerModal as setIsOpenCustomerModalNoAuth } from '../../../../redux/actions/filtered/adviserForm/filteredAdviserAction'
import { convertFirstLetterToUppercase } from '../../../../helpers/functions'
import { Permission } from '../../../../services/permissions/Permission'
import { initialValuesFilteredCustomerForm as initialValues } from '../../../../helpers/variablesInitialValues'
import styles from './propertyHeader.module.css'

const ChipCustom = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'myPropBackground',
})(({ theme, myPropBackground }) => ({
  backgroundColor: myPropBackground
    ? myPropBackground
    : theme.palette.mobiligrey.default,
  color: theme.palette.white.default,
  fontWeight: 700,
  fontSize: '12px',
  height: '20px',
  padding: '0 12px',
  marginBottom: '5px',
}))

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.palette.mobiligrey.default,
  },
}))

const DividerHeader = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '2fr 1fr',
}))

const PropertyHeader = ({ isAuthenticated = false, isAuthenticatedPage, formData, hasButtonBack }) => {
  const dispatch = useDispatch()
  const isCommercial = formData?.status === 'Comercial' ?? false
  const isDiscarded = formData?.status === 'Desechado' ?? false
  
  useEffect(() => {
  }, [
    isAuthenticated,
    isAuthenticatedPage,
    formData,
    hasButtonBack
  ])
  
  const handleIsOpenModal = () => {
    dispatch( setFormDataCustomer(initialValues) )
    dispatch( isAuthenticatedPage ? setIsOpenCustomerModal(true) : setIsOpenCustomerModalNoAuth(true) )
  }

  return (
    <div className={styles.cardCustom}>
      <div className={styles.wrapperBtn}>
        {hasButtonBack && (
          <button
            className={styles.btnLeftArrow}
            onClick={() =>
              history.push('/dashboard/filtrado/registro-de-casos')
            }
          >
            Regresar
          </button>
        )}
        {/* {urlDetalleInmueble !== true && <ChipCustom label="EN PROCESO" />} */}
      </div>

      <DividerHeader>
        <Grid container flexDirection="column" gap={5 / 8}>
          <Typography color="dark.default" variant="h3">
            {formData.customer === null ? '' : formData.customer}
            {isAuthenticated && (
              <Permission permission='filtereds.customers.show'>
                <Layout.IconButton
                  color="inherit"
                  size="medium"
                  className="ml-2"
                  onClick={handleIsOpenModal}
                >
                  <Layout.Icons.EditIcon
                    height={18}
                    width={18}
                    className="text-primary"
                  />
                </Layout.IconButton>
              </Permission>
            )}
          </Typography>
          <Stack direction="row" spacing={10 / 8} alignItems="center">
            <Layout.Icons.PhoneIcon height={19} width={19} />
            <div className="font-family-roboto-semibold fs-5 text-dark">
              {formData.mobile === null ? '' : formData.mobile}
            </div>
          </Stack>
          <Stack direction="row" spacing={10 / 8} alignItems="center">
            {isCommercial && (
              <>
                <StyledRating
                  name="read-only"
                  value={formData.ranking === null ? null : formData.ranking}
                  readOnly
                />

                <ChipCustom
                  label={'Enviado a comercial'}
                  myPropBackground={'#004087'}
                />
              </>
            )}
            {isDiscarded && (
              <ChipCustom label={'Desechado'} myPropBackground={'#69757C'} />
            )}
          </Stack>
        </Grid>

        <Stack textAlign="right" fontSize="12px">
          <div className="font-family-roboto-semibold text-dark-light">
            <Layout.Timezone
              date={formData.created_date === null ? '' : formData.created_date}
              dateAt={formData.created_at === null ? '' : formData.created_at}
            />
          </div>
          <div className="font-family-roboto-semibold text-dark-light">
            {convertFirstLetterToUppercase(formData.created_ago === null ? '' : formData.created_ago)}
          </div>
          <>
            <div className="font-family-roboto-semibold text-dark-light">
              Canal de origen: {formData.origin_channel === null ? '' : formData.origin_channel}
            </div>
            <div className="font-family-roboto-semibold text-dark-light">
              Fuente de contacto: {formData.contact === null ? '' : formData.contact}
            </div>
            <div className="font-family-roboto-semibold text-dark-light">
              Filtrador: {formData.agent === null ? '' : formData.agent}
            </div>
            <div className="font-family-roboto-semibold text-dark-light">
              Asesor: {formData.adviser === null ? '' : formData.adviser}
            </div>
          </>
        </Stack>
      </DividerHeader>

      <ListIconsDetails
        areaTotal={formData.area_total === null ? '' : formData.area_total}
        numberRoom={formData.room === null ? '' : formData.room}
        numberBathroom={formData.bathroom === null ? '' : formData.bathroom}
        numbreGarage={formData.parking === null ? '' : formData.parking}
        numbreStrata={formData.stratas === null ? '' : formData.stratas}
      />
    </div>
  )
}

PropertyHeader.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAuthenticatedPage: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  hasButtonBack: PropTypes.bool.isRequired
}

export default PropertyHeader
