import React from 'react'
import Remove from '@material-ui/icons/Remove'

export default function RemoveIcon(props) {
    const { ...other } = props

    return (
        <Remove { ...other } />
    )
}
