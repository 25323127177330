import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const TextArea = (props) => {
	const {
		rows = 4,
		variant = 'outlined',
		size = 'small',
		name,
		label,
		placeholder,
		hasLabelFixed = true,
		value = '',
		readOnly = false,
		onChange = null,
		onBlur = null,
		onKeyPress = null,
		hasError = false,
		errorMessage = '',
		...more
	} = props;
	
	return (
		<>
			<TextField
				fullWidth
				multiline
				rows={rows}
				maxRows={4}
				variant={variant}
				size={size}
				name={name}
				label={label}
				placeholder={placeholder}
				InputLabelProps={{
					shrink: hasLabelFixed
				}}
				InputProps={{
					readOnly: readOnly
				}}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				onKeyPress={onKeyPress}
				error={hasError}
				{...more}
			/>
			{hasError && errorMessage !== "" && (
				<div className="mt-1 has-error-content font-family-roboto-regular fs-6 animate__animated animate__fadeIn">
					{errorMessage}
				</div>
			)}
		</>
	);
};

TextArea.propTypes = {
	rows: PropTypes.number,
	variant: PropTypes.string,
	size: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	hasLabelFixed: PropTypes.bool,
	value: PropTypes.any,
	readOnly: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onKeyPress: PropTypes.func,
	hasError: PropTypes.bool,
	errorMessage: PropTypes.string,
};

export default TextArea;
