import React, {useEffect, useState} from "react"
import {useSelector} from 'react-redux'
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import IconButton from "@material-ui/core/IconButton"
import Display from "../../../../../components/display/Display"
import Feedback from "../../../../../components/feedback/Feedback"
import AuthorizationRowByOwner from "./AuthorizationRowByOwner"
import errorServices from '../../../../../services/errors'
import { swalSuccess } from '../../../../../services/sweetalert'
import axios from '../../../../../services/axios'

const AuthorizationRow = ({ row, handleList }) => {
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const {
		id,
		has_owners,
		created_date,
		created_at,
		status,
		authorization_for_commercial,
		conditions_for_rent,
		conditions_for_sale,
		duration,
		responsibility,
		executive_merit
	} = row
	const [open, setOpen] = useState(false)
	const [getID, setID] = useState('')
	const [getLoading, setLoading] = useState(false)
	
	const handleOpen = () => {
		setOpen(!open)
	}
	
	const handleDetail = async () => {
		setID(open ? id : '')
	}
	
	useEffect(() => {
		handleDetail()
	}, [open])
	
	useEffect(() => {
	}, [getID])
	
	const handleSendMail = async () => {
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/authorizations/send-mail-request/${id}`)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				handleList()
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	return (
		<>
			<TableRow>
				<TableCell className="p-2" align="center" width="10%">
					<IconButton aria-label="expand row" size="small" onClick={handleOpen}>
						{open ? <Display.Icons.ExpandLessIcon /> : <Display.Icons.ExpandMoreIcon />}
					</IconButton>
				</TableCell>
				<TableCell className="p-2" align="center" width="30%">
					<Display.DateWithTimezone
						timezone={created_at}
						updated_date={created_date}
					/>
				</TableCell>
				<TableCell className="p-2" align="center" width="30%">
					<div
						className={`badge ${status ? 'bg-success' : 'bg-warning cursor-pointer'}`}
						onClick={status ? null : handleSendMail }
					>
						{status ? 'Autorización enviada' : 'Enviar autorización'}
					</div>
				</TableCell>
				<TableCell className="p-2" align="center" width="30%">
					<div
						className={`badge ${has_owners ? 'bg-success' : 'bg-danger'}`}
					>
						{has_owners ? 'Si' : 'No'}
					</div>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					colSpan={8}
					className="p-0 m-0 bg-white"
				>
					<AuthorizationRowByOwner
						open={open}
						authorizationID={id}
						authorization_for_commercial={authorization_for_commercial}
						conditions_for_rent={conditions_for_rent}
						conditions_for_sale={conditions_for_sale}
						duration={duration}
						responsibility={responsibility}
						executive_merit={executive_merit}
					/>
				</TableCell>
			</TableRow>
			<Feedback.DialogLoading
				getLoading={getLoading}
				setLoading={setLoading}
			/>
		</>
	)
}

export default AuthorizationRow
