import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { history } from '../../../../redux/store'
import Layout from '../../../../components/mui/Layout'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import IconButton from "@material-ui/core/IconButton"
import CardContent from '@material-ui/core/CardContent'
import Controls from "../../../../components/controls/Controls"
import Display from "../../../../components/display/Display"
import Feedback from "../../../../components/feedback/Feedback"
import ComplementRow, { extractComplement } from './components/ComplementRow'
import errorServices from '../../../../services/errors'
import { swalSuccess } from '../../../../services/sweetalert'
import axios from '../../../../services/axios'
import SearchComponent from "../components/SearchComponent";

const arrayDropdown1 = [
	{ id: "Calle", name: "Calle" },
	{ id: "Carrera", name: "Carrera" },
	{ id: "Diagonal", name: "Diagonal" },
	{ id: "Transversal", name: "Transversal" },
	{ id: "Vía", name: "Vía" },
	{ id: "Avenida", name: "Avenida" }
]

const defaultState = {
	typeComplement: '',
	textComplement: '',
}


const PropertyLocationOldPage = () => {
	const params = useParams()
	const uID = params.uID
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [getLoading, setLoading] = useState(true)
	const [arrayDropdownCountry, setDropdownCountry] = useState([])
	const [arrayDropdownState, setDropdownState] = useState([])
	const [arrayDropdownCity, setDropdownCity] = useState([])
	const [arrayDropdownNeighborhood, setDropdownNeighborhood] = useState([])
	const [getAddress, setAddress] = useState("")
	const [isDuplicate, setIsDuplicate] = useState(0);
	const [getAddressError, setAddressError] = useState(false)
	const [getAddressMessage, setAddressMessage] = useState("")
	const [getReference, setReference] = useState("")
	const [getReferenceError, setReferenceError] = useState(false)
	const [getReferenceMessage, setReferenceMessage] = useState("")
	const [getCountry, setCountry] = useState("")
	const [getCountryID, setCountryID] = useState("")
	const [getCountryError, setCountryError] = useState(false)
	const [getCountryMessage, setCountryMessage] = useState("")
	const [getState, setState] = useState("")
	const [getStateID, setStateID] = useState("")
	const [getStateError, setStateError] = useState(false)
	const [getStateMessage, setStateMessage] = useState("")
	const [getCity, setCity] = useState("")
	const [getCityID, setCityID] = useState("")
	const [getCityError, setCityError] = useState(false)
	const [getCityMessage, setCityMessage] = useState("")
	const [getNeighborhoodID, setNeighborhoodID] = useState("")
	const [getNeighborhoodIDError, setNeighborhoodIDError] = useState(false)
	const [getNeighborhoodIDMessage, setNeighborhoodIDMessage] = useState("")
	const [getHandleAddress, setHandleAddress] = useState('')
	const [getLongitude, setLongitude] = useState('-74.7889')
	const [getLatitude, setLatitude] = useState('10.9878')
	const [getDrop1Error, setDrop1Error] = useState(false)
	const [getDrop1Message, setDrop1Message] = useState('')
	const [getValue1Error, setValue1Error] = useState(false)
	const [getValue1Message, setValue1Message] = useState('')
	const [getValue2Error, setValue2Error] = useState(false)
	const [getValue2Message, setValue2Message] = useState('')
	const [getValue3Error, setValue3Error] = useState(false)
	const [getValue3Message, setValue3Message] = useState('')
	const [getLoadAddress, setLoadAddress] = useState({
		"drop1": '',
		"value1": '',
		"text1": '',
		"suffix1": '',
		"drop2": '',
		"value2": '',
		"text2": '',
		"value3": '',
		"suffix2": ''
	})
	const [module, setModule] = useState({
		step: 2,
		title: 'Localización'
	})
	const [complement, setComplement] = useState('')
	const [rows, setRows] = useState([])
	
	const getHandleDropdownCountry = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/location/countries`)
			
			if (data.status === 200) {
				setDropdownCountry(data.data.data.records)
				setCountryID(1)
				await getHandleDropdownState(1)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
		getHandleDropdownCountry()
	}, [])
	
	const getHandleEditProperty = async () => {
		setLoading(false)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/${uID}`)
			
			if (data.status === 200) {
				if (data.data.data.has_building === 'Si'){
					await history.push(`/dashboard/propiedades/datos-generales/${uID}`)
				} else {
					await setLoadAddress({
						'drop1': data.data.data.drop1 === null ? '': data.data.data.drop1,
						'value1': data.data.data.value1 === null ? '': data.data.data.value1,
						'text1': data.data.data.text1 === null ? '': data.data.data.text1,
						'suffix1': data.data.data.suffix1 === null ? '': data.data.data.suffix1,
						'drop2': data.data.data.drop2 === null ? '': data.data.data.drop2,
						'value2': data.data.data.value2 === null ? '': data.data.data.value2,
						'text2': data.data.data.text2 === null ? '': data.data.data.text2,
						'value3': data.data.data.value3 === null ? '': data.data.data.value3,
						'suffix2': data.data.data.suffix2 === null ? '': data.data.data.suffix2
					})
					await setReference(data.data.data.reference === null ? '' : data.data.data.reference)
					await setLongitude(data.data.data.longitude === null ? getLongitude : data.data.data.longitude)
					await setLatitude(data.data.data.latitude === null ? getLatitude : data.data.data.latitude)

					if (data.data.data.neighborhood_id !== null){
						await setCountryID(data.data.data.country_id)
						await setStateID(data.data.data.state_id)
						await setCityID(data.data.data.city_id)
						await getHandleDropdownState(data.data.data.country_id)
						await getHandleDropdownCity(data.data.data.state_id)
						await getHandleDropdownNeighborhood(data.data.data.city_id)
						await setNeighborhoodID(data.data.data.neighborhood_id)
					}

					const concatenatedAddress = setLoadedAddress(data.data.data);

					let address = data.data.data.address || concatenatedAddress;
					let complement = '';

					if (data.data.data.address) {
						//remove concatenated address from address
						complement = extractComplement(data.data.data.address);
						//if complement exists, address will be the concatenated address
						if (complement) {
							address = concatenatedAddress;
							const copyRows = [{ typeComplement: ' ', textComplement: complement }]
							//add complement to rows
							setRows(copyRows)
							//update address with complement
							handleComplement(copyRows)
						}
					}

					await setAddress(address)
					await setIsDuplicate(data.data.data.is_duplicate === null ? 0 : data.data.data.is_duplicate)
				}
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}

	useEffect(() => {
		getHandleEditProperty()
	}, [uID])
	
	const setHandleLoadAddress = (e) => {
		setLoadAddress({
			...getLoadAddress,
			[e.target.name]:e.target.value
		})
	}

	const setCreateAddress = () => {
		let address = ""

		if (getLoadAddress.drop1 !== "" || getLoadAddress.drop1 !== null) {
			address = address + " " + getLoadAddress.drop1
		}

		if (getLoadAddress.value1 !== "" || getLoadAddress.value1 !== null) {
			address = address + " " + getLoadAddress.value1
		}

		if (getLoadAddress.text1 !== "" || getLoadAddress.text1 !== null) {
			address = address + getLoadAddress.text1
		}

		if (getLoadAddress.suffix1 !== "" || getLoadAddress.suffix1 !== null) {
			address = address + " "  + getLoadAddress.suffix1
		}

		if (getLoadAddress.drop2 !== "" || getLoadAddress.drop2 !== null) {
			address = address + " " + getLoadAddress.drop2
		}

		if (getLoadAddress.value2 !== "" || getLoadAddress.value2 !== null) {
			address = address + " # " + getLoadAddress.value2
		}

		if (getLoadAddress.text2 !== "" || getLoadAddress.text2 !== null) {
			address = address + getLoadAddress.text2
		}

		if (getLoadAddress.value3 !== "" || getLoadAddress.value3 !== null) {
			address = address + " - " + getLoadAddress.value3
		}

		if (getLoadAddress.suffix2 !== "" || getLoadAddress.suffix2 !== null) {
			address = address + " " + getLoadAddress.suffix2
		}

		setAddress(address)
	}

	useEffect(() => {
		setCreateAddress()
	}, [getLoadAddress])

	const setLoadedAddress = (data) => {
		let address = "";

		if (data.drop1 !== "" && data.drop1 !== null) {
			address = address + " " + data.drop1;
		}

		if (data.value1 !== "" && data.value1 !== null) {
			address = address + " " + data.value1;
		}

		if (data.text1 !== "" && data.text1 !== null) {
			address = address + data.text1;
		}

		if (data.suffix1 !== "" && data.suffix1 !== null) {
			address = address + " " + data.suffix1;
		}

		if (data.drop2 !== "" && data.drop2 !== null) {
			address = address + " " + data.drop2;
		}

		if (data.value2 !== "" && data.value2 !== null) {
			address = address + " # " + data.value2;
		}

		if (data.text2 !== "" && data.text2 !== null) {
			address = address + data.text2;
		}

		if (data.value3 !== "" && data.value3 !== null) {
			address = address + " - " + data.value3;
		}

		if (data.suffix2 !== "" && data.suffix2 !== null) {
			address = address + " " + data.suffix2;
		}

		return address.trim();
	}

	const getHandleDropdownState = async (country_id) => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/location/states/by-country/${country_id}`)
			
			if (data.status === 200) {
				setDropdownState(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const getHandleDropdownCity = async (state_id) => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/location/cities/by-state/${state_id}`)
			
			if (data.status === 200) {
				setDropdownCity(data.data.data.records)
				
				try {
					axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
					
					const data = await axios.get(`dashboard/settings/location/states/${state_id}` )
					
					if (data.status === 200) {
						setState(data.data.data.name)
					}
				} catch (error) {
					await errorServices(error)
				}
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const getHandleDropdownNeighborhood = async (city_id) => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/settings/location/neighborhoods/by-city/${city_id}`)
			
			if (data.status === 200) {
				setDropdownNeighborhood(data.data.data.records)
				
				try {
					axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
					
					const data = await axios.get(`dashboard/settings/location/cities/${city_id}` )
					
					if (data.status === 200) {
						setCity(data.data.data.name)
					}
				} catch (error) {
					await errorServices(error)
				}
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const setHandleForm = async (e) =>{
		e.preventDefault()

		if ((getCountryID === "" || getStateID === "" || getCityID === "" || getNeighborhoodID === "" || getLoadAddress.drop1 === "" || getLoadAddress.value1 === "" || getLoadAddress.value2 === "" || getLoadAddress.value3 === "" || getAddress === "" || getReference === "") && isDuplicate === 0){
			setCountryError(getCountry === "" ? true : false)
			setCountryMessage(getCountry === "" ? "El campo es requerido" : "")
			setStateError(getState === "" ? true : false)
			setStateMessage(getState === "" ? "El campo es requerido" : "")
			setCityError(getCity === "" ? true : false)
			setCityMessage(getCity === "" ? "El campo es requerido" : "")
			setNeighborhoodIDError(getNeighborhoodID === "" ? true : false)
			setNeighborhoodIDMessage(getNeighborhoodID === "" ? "El campo barrio es requerido" : "")
			setDrop1Error(getLoadAddress.drop1 === "" ? true : false)
			setDrop1Message(getLoadAddress.drop1 === "" ? "'El campo es requerido'" : "")
			setValue1Error(getLoadAddress.value1 === "" ? true : false)
			setValue1Message(getLoadAddress.value1 === "" ? "'El campo es requerido'" : "")
			setValue2Error(getLoadAddress.value2 === "" ? true : false)
			setValue2Message(getLoadAddress.value2 === "" ? "'El campo es requerido'" : "")
			setValue3Error(getLoadAddress.value3 === "" ? true : false)
			setValue3Message(getLoadAddress.value3 === "" ? "'El campo es requerido'" : "")
			setAddressError(getAddress === "" ? true : false)
			setAddressMessage(getAddress === "" ? "'El campo es requerido'" : "")
			setReferenceError(getReference === "" ? true : false)
			setReferenceMessage(getReference === "" ? "'El campo es requerido'" : "")
		} else {
			setLoading(true)

			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`

				const param = {
					neighborhood_id: getNeighborhoodID,
					drop1: getLoadAddress.drop1,
					value1: getLoadAddress.value1,
					text1: getLoadAddress.text1,
					suffix1: getLoadAddress.suffix1,
					drop2: getLoadAddress.drop2,
					value2: getLoadAddress.value2,
					text2: getLoadAddress.text2,
					value3: getLoadAddress.value3,
					suffix2: getLoadAddress.suffix2,
					address: `${getAddress} ${complement}`,
					reference: getReference,
					latitude: getLatitude,
					longitude: getLongitude,
					is_duplicate: isDuplicate
				}

				const data = await axios.put(`dashboard/properties/location/${uID}`, param)

				if (data.status === 200) {
					swalSuccess(data.data.data)
					await history.push(`/dashboard/propiedades/datos-generales/${uID}`)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	const setHandleMap = () => {
		if (getCountryID === "" || getStateID === "" || getCityID === "" || getLoadAddress.drop1 === "" || getLoadAddress.value1 === "" || getLoadAddress.value2 === "" || getLoadAddress.value3 === ""){
			setCountryError(getCountry === "" ? true : false)
			setCountryMessage(getCountry === "" ? "El campo es requerido" : "")
			setStateError(getState === "" ? true : false)
			setStateMessage(getState === "" ? "El campo es requerido" : "")
			setCityError(getCity === "" ? true : false)
			setCityMessage(getCity === "" ? "El campo es requerido" : "")
			setDrop1Error(getLoadAddress.drop1 === "" ? true : false)
			setDrop1Message(getLoadAddress.drop1 === "" ? "'El campo es requerido'" : "")
			setValue1Error(getLoadAddress.value1 === "" ? true : false)
			setValue1Message(getLoadAddress.value1 === "" ? "'El campo es requerido'" : "")
			setValue2Error(getLoadAddress.value2 === "" ? true : false)
			setValue2Message(getLoadAddress.value2 === "" ? "'El campo es requerido'" : "")
			setValue3Error(getLoadAddress.value3 === "" ? true : false)
			setValue3Message(getLoadAddress.value3 === "" ? "'El campo es requerido'" : "")
		} else {
			let address = ""
			
			address = getCountry + ", " + getState + " " + getCity + " " + getLoadAddress.drop1 + " " + getLoadAddress.value1
			
			if (getLoadAddress.text1 !== "" || getLoadAddress.text1 !== null) {
				address = address + getLoadAddress.text1
			}
			
			address = address + " #"
			
			if (getLoadAddress.drop2 !== "" || getLoadAddress.drop2 !== null) {
				address = address + " " +getLoadAddress.drop2 + " "
			}
			
			address = address + getLoadAddress.value2
			
			if (getLoadAddress.text2 !== "" || getLoadAddress.text2 !== null) {
				address = address + getLoadAddress.text2
			}
			
			address = address + " - " + getLoadAddress.value3
			
			setHandleAddress(address)
		}
	}
	
	const handleOnChange = (index, name, value) => {
		const copyRows = [...rows]
		copyRows[index] = {
			...copyRows[index],
			[name]: value
		}
		setRows(copyRows)
		handleComplement(copyRows)
	}
	
	const handleComplement = (copyRows) => {
		if (copyRows.length > 0) {
			const array = []
			
			copyRows.map(({ typeComplement, textComplement }) => {
				array.push(`${typeComplement} ${textComplement}`)
			})
			
			if (array.toString().replace(',', ' ') !== '') {
				setComplement(array.toString().replace(',', ' '))
			}
		} else {
			setComplement('')
		}
	}
	
	const handleOnAdd = () => {
		setRows(rows.concat(defaultState))
	}
	
	const handleOnClear = () => {
		setRows([])
		setComplement('')
	}
	
	const handleOnRemove = index => {
		const copyRows = [...rows]
		copyRows.splice(index, 1)
		setRows(copyRows)
		handleComplement(copyRows)
	}
	
	return (
		<Layout.Page title="Propiedades - Localización">
			<SearchComponent
				module={module}
				back={`/dashboard/propiedades/edificacion/${uID}`}
				next={`/dashboard/propiedades/datos-generales/${uID}`}
			/>
			
			<Grid container spacing={2} className="animate__animated animate__fadeIn p-4">
				<Grid item xs={12}>
					<Card>
						<CardContent>
							{
								getLoading ?
									(
										<>
											<Grid container spacing={3}>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
											</Grid>
											<Grid container spacing={3}>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
												<Grid item xs={12} sm={6}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="40px" />
												</Grid>
												<Grid item xs={12} sm={12}>
													<Feedback.Loading type="text" animation="wave" width="100%" height="300px" />
												</Grid>
											</Grid>
										</>
									)
									:
									<form>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<Display.Typography
													variant="subtitle1"
													color="error"
													label="Los datos con (*) son requeridos"
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.Select
													label="* País"
													name="country"
													defaultValue={getCountryID}
													error={getCountryError}
													message={getCountryMessage}
													onChange={(e) => {
														getHandleDropdownState(e.target.value)
														setCountryID(e.target.value)
														
														if (e.target.value === "") {
															setCountryError(true)
															setCountryMessage('El campo es requerido')
														} else {
															setCountryError(false)
															setCountryMessage()
														}
													}}
													arrayDropdown={arrayDropdownCountry}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.Select
													label="* Departamento/Estado"
													name="state"
													defaultValue={getStateID}
													error={getStateError}
													message={getStateMessage}
													onChange={(e) => {
														getHandleDropdownCity(e.target.value)
														setStateID(e.target.value)
														
														if (e.target.value === "") {
															setStateError(true)
															setStateMessage('El campo es requerido')
														} else {
															setStateError(false)
															setStateMessage()
														}
													}}
													arrayDropdown={arrayDropdownState}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.Select
													label="* Ciudad/Provincia"
													name="city"
													defaultValue={getCityID}
													error={getCityError}
													message={getCityMessage}
													onChange={(e) => {
														getHandleDropdownNeighborhood(e.target.value)
														setCityID(e.target.value)
														
														if (e.target.value === "") {
															setCityError(true)
															setCityMessage('El campo ciudad es requerido')
														} else {
															setCityError(false)
															setCityMessage()
														}
													}}
													arrayDropdown={arrayDropdownCity}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.Select
													label="* Barrio/Urbanización"
													name="neighborhood_id"
													defaultValue={getNeighborhoodID}
													error={getNeighborhoodIDError}
													message={getNeighborhoodIDMessage}
													onChange={(e) => {
														setNeighborhoodID(e.target.value)
														
														if (e.target.value === ""){
															setNeighborhoodIDError(true)
															setNeighborhoodIDMessage('El campo barrio es requerido')
														} else {
															setNeighborhoodIDError(false)
															setNeighborhoodIDMessage('')
														}
													}}
													arrayDropdown={arrayDropdownNeighborhood}
												/>
											</Grid>
											<Grid item xs={12}>
												<Grid container spacing={3}>
													<Grid item xs={12}>
														<Display.Typography
															variant="h5"
															color="textPrimary"
															label="A continuación digite la dirección"
														/>
														<Display.Typography
															variant="h6"
															color="textSecondary"
															label="Ejemplo: Carrera 5A SUR Calle # 12B - 30 Diagonal"
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={5}>
														<Controls.Select
															label="* Av. Principal"
															name="drop1"
															defaultValue={getLoadAddress.drop1}
															error={getDrop1Error}
															message={getDrop1Message}
															onChange={(e) => {
																setHandleLoadAddress(e)
																
																if (e.target.value === ""){
																	setDrop1Error(true)
																	setDrop1Message('El campo es requerido')
																} else {
																	setDrop1Error(false)
																	setDrop1Message('')
																}
															}}
															arrayDropdown={arrayDropdown1}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={2}>
														<Controls.TextFieldNumber
															label="* Nº 1"
															name="value1"
															defaultValue={getLoadAddress.value1}
															error={getValue1Error}
															message={getValue1Message}
															onChange={e => {
																setHandleLoadAddress(e)
																
																if (e.target.value === ""){
																	setValue1Error(true)
																	setValue1Message('El campo es requerido')
																} else {
																	setValue1Error(false)
																	setValue1Message('')
																}
															}}
															onKeyPress={e => e.charCode === 13 && setHandleForm(e)}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={2}>
														<Controls.TextField
															label="Info 1 (Ej: A,B,C)"
															name="text1"
															defaultValue={getLoadAddress.text1}
															onChange={e => setHandleLoadAddress(e)}
															onKeyPress={e => e.charCode === 13 && setHandleForm(e)}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={3}>
														<Controls.TextField
															label="Sufijo 1 (Ej: Norte)"
															name="suffix1"
															defaultValue={getLoadAddress.suffix1}
															onChange={e => setHandleLoadAddress(e)}
															onKeyPress={e => e.charCode === 13 && setHandleForm(e)}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={5}>
														<Controls.Select
															label="Av. Secundaria"
															name="drop2"
															defaultValue={getLoadAddress.drop2}
															onChange={(e) => setHandleLoadAddress(e)}
															arrayDropdown={arrayDropdown1}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={1}>
														<Controls.TextFieldNumber
															label="* Nº 2"
															name="value2"
															defaultValue={getLoadAddress.value2}
															error={getValue2Error}
															message={getValue2Message}
															onChange={(e) => {
																setHandleLoadAddress(e)
																
																if (e.target.value === ""){
																	setValue2Error(true)
																	setValue2Message('El campo es requerido')
																} else {
																	setValue2Error(false)
																	setValue2Message('')
																}
															}}
															onKeyPress={e => e.charCode === 13 && setHandleForm(e)}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={2}>
														<Controls.TextField
															label="Info 2 (Ej: A,B,C)"
															name="text2"
															defaultValue={getLoadAddress.text2}
															onChange={e => setHandleLoadAddress(e)}
															onKeyPress={e => e.charCode === 13 && setHandleForm(e)}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={1}>
														<Controls.TextFieldNumber
															label="* Nº 3"
															name="value3"
															defaultValue={getLoadAddress.value3}
															error={getValue3Error}
															message={getValue3Message}
															onChange={(e) => {
																setHandleLoadAddress(e)
																
																if (e.target.value === ""){
																	setValue3Error(true)
																	setValue3Message('El campo es requerido')
																} else {
																	setValue3Error(false)
																	setValue3Message('')
																}
															}}
															onKeyPress={e => e.charCode === 13 && setHandleForm(e)}
														/>
													</Grid>
													<Grid item xs={6} sm={4} md={3}>
														<Controls.TextField
															label="Sufijo 2 (Ej: Norte)"
															name="suffix2"
															defaultValue={getLoadAddress.suffix2}
															onChange={e => setHandleLoadAddress(e)}
															onKeyPress={e => e.charCode === 13 && setHandleForm(e)}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextArea
													label="* Dirección de la propiedad"
													name="address"
													defaultValue={`${getAddress} ${complement}`}
													InputProps={{
														readOnly: true
													}
													}
													error={getAddressError}
													message={getAddressMessage}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextArea
													label="* Descripción de la propiedad"
													name="reference"
													defaultValue={getReference}
													error={getReferenceError}
													message={getReferenceMessage}
													onChange={e => {
														setReference(e.target.value)
														
														if (e.target.value === ""){
															setReferenceError(true)
															setReferenceMessage('El campo es requerido')
														} else {
															setReferenceError(false)
															setReferenceMessage('')
														}
													}}
													onKeyPress={e => e.charCode === 13 && setHandleForm(e)}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextField
													label="Longitud"
													name="longitude"
													defaultValue={getLongitude}
													InputProps={{
														readOnly: true
													}
													}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Controls.TextField
													label="Latitud"
													name="latitude"
													defaultValue={getLatitude}
													InputProps={{
														readOnly: true
													}
													}
												/>
											</Grid>
											{rows.length > 0 && (
												<Grid item xs={12} className="animated animate__fadeIn">
													{rows.map((row, index) => (
														<ComplementRow
															{...row}
															onChange={(name, value) => handleOnChange(index, name, value)}
															onRemove={() => handleOnRemove(index)}
															key={index}
														/>
													))}
												</Grid>
											)}
											<Grid item xs={12}>
												<div className="d-inline">
													Complemento
												</div>
												<div className="d-inline ml-2">
													<IconButton
														color="inherit"
														onClick={handleOnAdd}
														className="bg-grey height-32 width-32"
													>
														<Display.Icons.AddOutlinedIcon
															className="text-dark"
															style={{ fontSize: 20 }}
														/>
													</IconButton>
													<IconButton
														color="inherit"
														onClick={handleOnClear}
														className="bg-grey height-32 width-32"
													>
														<Display.Icons.DeleteOutlinedIcon
															className="text-dark"
															style={{ fontSize: 20 }}
														/>
													</IconButton>
												</div>
											</Grid>
											<Grid item xs={12}>
												<div className="float-right">
													<Controls.Button
														label="Generar geolocalización"
														color="dark"
														variant="contained"
														endIcon={<Display.Icons.RoomOutlinedIcon />}
														onClick={setHandleMap}
													/>
												</div>
											</Grid>
											<Grid item xs={12}>
												<Feedback.Map
													longitude={getLongitude}
													latitude={getLatitude}
													setLongitude={setLongitude}
													setLatitude={setLatitude}
													setHandleAddress={getHandleAddress}
												/>
											</Grid>
											<Grid item xs={12}>
												<div className="float-right">
													<Controls.ButtonAction
														label="Atrás"
														color="secondary"
														variant="contained"
														startIcon={<Display.Icons.ChevronLeftIcon />}
														href={`/dashboard/propiedades/edificacion/${uID}`}
													/>
													<Controls.ButtonSubmit
														label="Siguiente"
														color="primary"
														variant="contained"
														endIcon={<Display.Icons.ChevronRightIcon />}
														onClick={setHandleForm}
													/>
												</div>
											</Grid>
										</Grid>
									</form>
							}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Layout.Page>
	)
}

export default PropertyLocationOldPage
