import React from 'react'
import PropTypes from 'prop-types'
import styles from './tabs.module.css'

export function Tabs({ isAuthenticated = false, itemsArray, tapActive = 1, handleTap, tabsHeader = false }) {
  const listItems = itemsArray.map(({ id, name }, index) => {
    return (
      <>
        {(!isAuthenticated && name !== 'Actividad') && (
          <button
            key={index}
            onClick={() => handleTap(id)}
            className={`${styles.tabs__item} ${tapActive === id ? styles.active : ''}`}
          >
            {name}
          </button>
        )}
        {isAuthenticated && (
          <button
            key={index}
            onClick={() => handleTap(id)}
            className={`${styles.tabs__item} ${tapActive === id ? styles.active : ''}`}
          >
            {name}
          </button>
        )}
      </>
    )
  })

  return <div className={styles.tabs}>{listItems}</div>
}

Tabs.propTypes = {
  isAuthenticated: PropTypes.bool,
  itemsArray: PropTypes.array.isRequired,
  tapActive: PropTypes.number,
  handleTap: PropTypes.func,
}
