const ROOT = 'dashboard/settings/commercial/status';

export default {
	arrayList: `${ROOT}/list`,
	arrayListToDropdown: `${ROOT}/by-realstate/`,
	store: `${ROOT}`,
	show: `${ROOT}/`,
	status: `${ROOT}/status/`,
	update: `${ROOT}/`,
	destroy: `${ROOT}/`,
};
