import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { history } from '../../../../redux/store'
import Layout from '../../../../components/mui/Layout'
import Inputs from '../../../../components/mui/Inputs'
import SearchComponent from '../components/SearchComponent'
import { setIsLoading } from '../../../../redux/actions/loadingAction'
import { Permission } from '../../../../services/permissions/Permission'
import errorServices from '../../../../services/errors'
import { swalSuccess } from '../../../../services/sweetalert'
import axios from '../../../../services/axios'

const PropertyGeneralDataOlPage = () => {
	const params = useParams()
	const uID = params.uID
	const dispatch = useDispatch()
	const { permissions } = useSelector( state  => state.auth.userData)
	const tokenType = useSelector(state => state.auth.tokenType)
	const accessToken = useSelector(state => state.auth.accessToken)
	const [isLoading, setLoadingForm] = useState(false)
	const [arrayPropertyService, setPropertyService] = useState([])
	const [getRoot, setRoot] = useState('localizacion')
	const [formData, setFormData] = useState({
		number_room: '',
		number_bathroom: '',
		number_garage: '',
		room_service_id: '',
	})
	const [module, setModule] = useState({
		step: 3,
		title: 'Datos Generales'
	})
	
	const validationSchema = () => (
		Yup.object({
			number_room: Yup.number()
			.required('El número da habitaciones es requerido'),
			number_bathroom: Yup.number()
			.required('El número de baños es requerido'),
		})
	)
	
	const handleEditProperty = async () => {
		dispatch( setIsLoading(true) )
		
		await handleDropdownPropertyRoomService()
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/${uID}`)
			
			if (data.status === 200) {
				const response = data.data.data
				if (response.has_building === 'Si') {
					setRoot('edificacion')
					setModule({...module, step: 2 })
				}
				
				setFormData({
					number_room: response.number_room === null ? '' : response.number_room,
					number_bathroom: response.number_bathroom === null ? '' : response.number_bathroom,
					number_garage: response.number_garage === null ? '' : response.number_garage,
					room_service_id: response.room_service_id === null ? '' : response.room_service_id
				})
			}
		} catch (error) {
			await errorServices(error)
		}
		
		dispatch( setIsLoading(false) )
	}
	
	useEffect(() => {
		handleEditProperty()
	}, [])
	
	const handleDropdownPropertyRoomService = async () => {
		if (permissions.find((item) => item === 'propertyRoomServices.dropdown')) {
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const data = await axios.get(`dashboard/settings/properties/room-services`)
				
				if (data.status === 200) {
					setPropertyService(data.data.data.records)
				}
			} catch (error) {
				await errorServices(error)
			}
		}
	}
	
	const hanbleSubmit = async () => {
		setLoadingForm(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				id: uID,
				number_room: formData.number_room === '' ? null :formData.number_room,
				number_bathroom: formData.number_bathroom === '' ? null :formData.number_bathroom,
				number_garage: formData.number_garage === '' ? null :formData.number_garage,
				room_service_id: formData.room_service_id === '' ? null :formData.room_service_id
			}
			
			const data = await axios.put(`dashboard/properties/general-data/${uID}`, param)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				await history.push(`/dashboard/propiedades/tipos/${uID}`)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoadingForm(false)
	}
	
	const handleOnChange = ({ target }) => setFormData({...formData, [target.name]: target.value})
	
	return (
		<Layout.Page title="Propiedades - Datos Generales">
			<SearchComponent
				module={module}
				back={`/dashboard/propiedades/${getRoot}/${uID}`}
				next={`/dashboard/propiedades/tipos/${uID}`}
			/>
			
			<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
				<Layout.CardContent className="m-0 p-4">
					<Formik
						initialValues={formData}
						validationSchema={validationSchema}
						onSubmit={() => {}}
					>
						{({
						  errors,
						  touched,
						  handleChange,
						  handleBlur,
						  isValid
					  }) => (
							<Form noValidate>
								<div className="row">
									<div className="col-sm-12 col-md-6 mb-3">
										<Inputs.TextBox
											type="number"
											variant="outlined"
											size="small"
											name="number_room"
											label="Número de Alcobas"
											placeholder="Ingrese número de alcobas..."
											hasLabelFixed={true}
											value={formData.number_room}
											onChange={(e) => {
												handleOnChange(e)
												handleChange(e)
											}}
											onBlur={handleBlur}
											hasError={errors.number_room && touched.number_room ? true : false}
											errorMessage={errors.number_room && touched.number_room ? errors.number_room : ''}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
									<div className="col-sm-12 col-md-6 mb-3">
										<Inputs.TextBox
											type="number"
											variant="outlined"
											size="small"
											name="number_bathroom"
											label="Número de Baños"
											placeholder="Ingrese número de baños..."
											hasLabelFixed={true}
											value={formData.number_bathroom}
											onChange={(e) => {
												handleOnChange(e)
												handleChange(e)
											}}
											onBlur={handleBlur}
											hasError={errors.number_bathroom && touched.number_bathroom ? true : false}
											errorMessage={errors.number_bathroom && touched.number_bathroom ? errors.number_bathroom : ''}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
									<div className="col-sm-12 col-md-6 mb-3">
										<Inputs.TextBox
											type="number"
											variant="outlined"
											size="small"
											name="number_garage"
											label="Número de Garages"
											placeholder="Ingrese número de garages..."
											hasLabelFixed={true}
											value={formData.number_garage}
											onChange={handleOnChange}
											className="font-family-roboto-medium text-dark fs-6 w-100"
										/>
									</div>
									<Permission permission="propertyRoomServices.dropdown">
										<div className="col-sm-12 col-md-6 mb-3">
											<Inputs.Select
												size="small"
												label="Alcoba de Servicio"
												name="room_service_id"
												value={formData.room_service_id}
												itemsArray={arrayPropertyService}
												onChange={handleOnChange}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
									</Permission>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<Inputs.LoadingButton
											variant="outlined"
											isLoading={isLoading}
											isLoadingPosition="start"
											startIcon={<Layout.Icons.SaveIcon height={18} width={18}/>}
											label="Guardar"
											type="button"
											onClick={hanbleSubmit}
											className={`${isValid && 'bg-primary'} font-family-roboto-medium fs-6 text-inherit`}
										/>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</Layout.CardContent>
			</Layout.Card>
		</Layout.Page>
	)
}

export default PropertyGeneralDataOlPage
