import { initialValuesOwnerDomus as initialValues } from '../../../../../../helpers/variablesInitialValues'
import * as types from '../../../../../actionsTypes/dashboard/settings/owners/ownerDomusTypes'
import { setIsLoading } from '../../../../loadingAction'
import { setOwnerMobiliReset } from '../mobili/ownerMobiliAction'
import { getShowByMobile } from './services'
import errorServices from '../../../../../../services/errors'

export const showByMobile = mobile => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		await dispatch( setIsLoading(true) )
		
		await getShowByMobile(mobile).then(({ data }) => {
			const object = {
				personType: data.data.person_type,
				company: data.data.company === null ? '' : data.data.company,
				firstName: data.data.first_name === null ? '' : data.data.first_name,
				lastName: data.data.last_name === null ? '' : data.data.last_name,
				email: data.data.email,
				mobile: data.data.mobile,
				phone: data.data.phone === null ? '' : data.data.phone,
				state_id: data.data.state_id === null ? '' : data.data.state_id,
				state: data.data.state === null ? '' : data.data.state,
				cityId: data.data.city_id === null ? '' : data.data.city_id,
				city: data.data.city === null ? '' : data.data.city
			}
			
			dispatch( setFormData(object) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setOwnerDomusReset = () => (
	async (dispatch) => {
		await dispatch( setOwnerMobiliReset() )
		await dispatch( setSearch('') )
		await dispatch( setIsLoadingForm(false) )
		await dispatch( setFormData(initialValues) )
	}
)

export const setSearch = value => ({
	type: types.SEARCH_OWNER_DOMUS,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_OWNER_DOMUS,
	payload: value
})


export const setFormData = value => ({
	type: types.FORM_DATA_OWNER_DOMUS,
	payload: value
})
