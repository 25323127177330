import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'

const InfinityScrolling = ({ dataLength, next, hasMore, loader = '', endMessage = null, scrollableTarget, children, ...more }) => {
	return (
		<InfiniteScroll
			dataLength={dataLength}
			next={next}
			hasMore={hasMore}
			loader={loader}
			endMessage={endMessage}
			scrollableTarget={scrollableTarget}
			{...more}
		>
			{children}
		</InfiniteScroll>
	)
}

InfinityScrolling.propTypes = {
	dataLength: PropTypes.number.isRequired,
	next: PropTypes.func.isRequired,
	hasMore: PropTypes.bool.isRequired,
	loader: PropTypes.any,
	endMessage: PropTypes.any,
	scrollableTarget: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired
}

export default InfinityScrolling
