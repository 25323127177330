import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Slider } from '../slider'
import Skeleton from '../skeleton';
import useSlider from '../../hooks/useSlider';
import styles from './gallery.module.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoaderGallery from '../loader/LoaderGallery';

export const Gallery = ({ images, isLoading, setPage, hasMore }) => {
	const {
		isOpenSlider,
		closeSliderFunc,
		openSliderFunc,
		setDataGalleriesSliderFunc,
		dataGalleries,
		currentIndex,
		goToNext,
		goToPrevious
	} = useSlider();

	const getCurrentIndex = (index) => {
		openSliderFunc(index);
	};

	let imgItems = images?.map((item, index) => {
		return (
			<div key={item?.name} className={styles.imagesBox}>
				<button onClick={() => getCurrentIndex(index)}>
					<img loading='lazy' src={item?.path} alt={item?.name} />
				</button>
			</div>
		);
	});

	useEffect(() => {
		setDataGalleriesSliderFunc(images);
	}, []);

	return (
		<main className={styles.main}>
			<div
				style={{ overflow: 'auto', height: '680px', padding: '10px' }}
				id='scrollableDiv'>
				<InfiniteScroll
					dataLength={imgItems.length}
					next={() => setPage((prevPage) => prevPage + 1)}
					hasMore={hasMore}
					loader={<LoaderGallery />}
					scrollableTarget='scrollableDiv'>
					<section className={styles.galleries}>
						{isLoading ? <Skeleton type={'gallery'} /> : imgItems}
					</section>
				</InfiniteScroll>
			</div>

			<Slider
				slider={dataGalleries}
				closeSlider={closeSliderFunc}
				show={isOpenSlider}
				currentIndexInitial={currentIndex}
				goToNext={goToNext}
				goToPrevious={goToPrevious}
			/>
		</main>
	);
};

Gallery.propTypes = {}
