import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Feedback from '../../../../../../components/mui/Feedback';
import FormComponent from './FormComponent';
import DeleteComponent from './DeleteComponent';
import { setFormData, setFormType, setIsOpenModal, setIsLoadingShow, setIsLoadingForm } from '../redux/actions/propertyFacadeAction';
import usePermission from '../../../../../../hooks/usePermission';
import { initialValuesPropertyFacade as initialValues } from '../../../../../../helpers/variablesInitialValues';

const ModalComponent = () => {
	const dispatch = useDispatch();
	const isOpenModal = useSelector( ({ propertyFacade }) => propertyFacade.isOpenModal);
	const formType = useSelector( ({ propertyFacade }) => propertyFacade.formType);
	const permissionStore = usePermission('propertyFacades.store');
	const permissionShow = usePermission('propertyFacades.show');
	const permissionDelete = usePermission('propertyFacades.delete');
	
	/**
	 *
	 * @description Execute reset formData, loading show, loading form, close modal state
	 * @return dispatch
	 *
	 */
	const handleCloseModal = async () => {
		await dispatch( setFormData(initialValues) );
		await dispatch( setFormType('') );
		await dispatch( setIsLoadingShow(false) );
		await dispatch( setIsLoadingForm(false) );
		await dispatch( setIsOpenModal(false) );
	};
	
	return (
		<Feedback.Modal
			maxWidth="sm"
			isOpen={isOpenModal}
			isClose={handleCloseModal}
			title={formType === 'delete' ? `Eliminación de la fachada` : 'Captación de fachadas'}
		>
			{(formType === 'store' || formType === 'show') && (
				(formType === 'store' ? permissionStore : permissionShow) && (
					<FormComponent />
				)
			)}
			{formType === 'delete' && (
				permissionDelete && (
					<DeleteComponent />
				)
			)}
		</Feedback.Modal>
	);
};

export default ModalComponent;
