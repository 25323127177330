import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Inputs from '../../../../components/mui/Inputs';
import Layout from '../../../../components/mui/Layout';
import Feedback from '../../../../components/mui/Feedback';
import DropdownSelect from '../../settings/RealStatePage/components/DropdownSelect';
import DropdownSelectCountry from '../../settings/location/CountryPage/components/DropdownSelect';
import DropdownSelectState from '../../settings/location/StatePage/components/DropdownSelect';
import DropdownSelectCity from '../../settings/location/CityPage/components/DropdownSelect';
import DropdownSelectNeighborhood from '../../settings/location/NeighborhoodPage/components/DropdownSelect';
import DropdownSelectBuildingType from '../../settings/buildings/BuildingTypePage/components/DropdownSelect';
import DropdownSelectArea from '../../settings/AreaPage/components/DropdownSelect';
import DropdownSelectBank from '../../settings/BankPage/components/DropdownSelect';
import DropdownSelectCommercialBuilding from '../../settings/AmenityPage/components/DropdownSelectCommercialBuilding';
import { getShowById as getShowByIdCity } from '../../settings/location/CityPage/services';
import { setStore, getShowById, setUpdateById } from '../services';
import { setFormData as setFormDataCountry } from '../../settings/location/CountryPage/redux/actions/countryAction';
import { setFormData as setFormDataState } from '../../settings/location/StatePage/redux/actions/stateAction';
import { setFormData as setFormDataCity } from '../../settings/location/CityPage/redux/actions/cityAction';
import { setFormId, setFormData, setIsLoadingShow, setIsLoadingForm, setIsLoadingMap, setRefreshTable, setIsOpenModal } from '../redux/actions/buildingAction';
import swalErrors from '../../../../hooks/useErrors';
import { swalError } from '../../../../hooks/useSweetAlert';
import usePermission from '../../../../hooks/usePermission';
import { strataArray, addressArray, actionsSwitchBank } from '../../../../helpers/variablesColumns';
import { initialValuesBuilding as initialValues } from '../../../../helpers/variablesInitialValues';

const FormComponent = () => {
	const dispatch = useDispatch();
	const refreshTable = useSelector( ({ building }) => building.refreshTable);
	const isLoadingMap = useSelector( ({ building }) => building.isLoadingMap);
	const isLoadingShow = useSelector( ({ building }) => building.isLoadingShow);
	const isLoadingForm = useSelector( ({ building }) => building.isLoadingForm);
	const formType = useSelector( ({ building }) => building.formType);
	const formData = useSelector( ({ building }) => building.formData);
	const formId = useSelector( ({ building }) => building.formId);
	const formDataCountry = useSelector( ({ country }) => country.formData);
	const formDataState = useSelector( ({ state }) => state.formData);
	const formDataCity = useSelector( ({ city }) => city.formData);
	const [addressMap, setAddressMap] = useState('');
	const [longitude, setLongitude] = useState('-74.7889');
	const [latitude, setLatitude] = useState('10.9878');
	const permissionStore = usePermission('buildings.store');
	const permissionShow = usePermission('buildings.show');
	const permissionUpdate = usePermission('buildings.update');
	
	useEffect(() => {
		if (formType === 'show') {
			setLongitude(formData.longitude);
			setLatitude(formData.latitude);
		}
	}, [formType]);
	
	/**
	 *
	 * @description Execute endpoint show by id in city
	 * @return dispatch
	 *
	 */
	const handleShowCity = async (id) => {
		if (id !== '') {
			try {
				const { data } = await getShowByIdCity(id);
				
				if (data.status === 200) {
					const object1 = {
						id: data.data.country_id,
						name: data.data.country_name === null ? '' : data.data.country_name
					};
					
					const object2 = {
						id: data.data.state_id,
						country: data.data.country_id,
						name: data.data.state_name === null ? '' : data.data.state_name
					};
					
					const object3 = {
						id: data.data.id,
						country: data.data.country_id,
						state: data.data.state_id,
						name: data.data.name === null ? '' : data.data.name
					};
					
					await dispatch( setFormDataCountry(object1) );
					await dispatch( setFormDataState(object2) );
					await dispatch( setFormDataCity(object3) );
				}
			} catch (error) {
				await swalErrors(error);
			}
		}
	};
	
	/**
	 *
	 * @description Execute endpoint show by id
	 * @return dispatch
	 *
	 */
	const handleShowById = async () => {
		if (formType === 'show' && permissionShow) {
			await dispatch( setIsLoadingShow(true) );
			
			try {
				const { data } = await getShowById(formId);
				
				if (data.status === 200) {
					const object = {
						id: data.data.id,
						realState: data.data.realstate_id === null ? '' : data.data.realstate_id,
						nit: data.data.nit === null ? '' : data.data.nit,
						company: data.data.company === null ? '' : data.data.company,
						type: data.data.building_type_id === null ? '' : data.data.building_type_id,
						strata: data.data.strata === null ? '' : data.data.strata,
						country: data.data.country_id === null ? '' : data.data.country_id,
						state: data.data.state_id === null ? '' : data.data.state_id,
						city: data.data.city_id === null ? '' : data.data.city_id,
						neighborhood: data.data.neighborhood_id === null ? '' : data.data.neighborhood_id,
						drop1: data.data.drop1 === null ? '' : data.data.drop1,
						value1: data.data.value1 === null ? '' : data.data.value1,
						text1: data.data.text1 === null ? '' : data.data.text1,
						suffix1: data.data.suffix1 === null ? '' : data.data.suffix1,
						drop2: data.data.drop2 === null ? '' : data.data.drop2,
						value2: data.data.value2 === null ? '' : data.data.value2,
						text2: data.data.text2 === null ? '' : data.data.text2,
						value3: data.data.value3 === null ? '' : data.data.value3,
						suffix2: data.data.suffix2 === null ? '' : data.data.suffix2,
						address: data.data.address === null ? '' : data.data.address,
						reference: data.data.reference === null ? '' : data.data.reference,
						longitude: data.data.longitude === null ? '' : data.data.longitude,
						latitude: data.data.latitude === null ? '' : data.data.latitude,
						yearConstruction: data.data.year_construction === null ? '' : data.data.year_construction,
						areaConstruction: data.data.area_construction === null ? '' : data.data.area_construction,
						areaTotal: data.data.area_total === null ? '' : data.data.area_total,
						adminId: data.data.admin_id === null ? '' : data.data.admin_id,
						adminName: data.data.admin_name === null ? '' : data.data.admin_name,
						adminPhone: data.data.admin_phone1 === null ? '' : data.data.admin_phone1,
						area: '',
						amenities: data.data.amenities.length === 0 ? [] : data.data.amenities,
						bank: data.data.bank_id === null ? '' : data.data.bank_id,
						bankType: data.data.bank_type === null ? '' : data.data.bank_type,
						bankAccount: data.data.bank_account === null ? '' : data.data.bank_account,
					};
					
					const object1 = {
						id: data.data.country_id,
						name: data.data.country_name === null ? '' : data.data.country_name
					};
					
					const object2 = {
						id: data.data.state_id,
						country: data.data.country_id,
						name: data.data.state_name === null ? '' : data.data.state_name
					};
					
					const object3 = {
						id: data.data.city_id,
						country: data.data.country_id,
						state: data.data.state_id,
						name: data.data.city_name === null ? '' : data.data.city_name
					};
					
					await dispatch( setFormDataCountry(object1) );
					await dispatch( setFormDataState(object2) );
					await dispatch( setFormDataCity(object3) );
					await dispatch( setFormId('') );
					await dispatch( setFormData(object) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingShow(false) );
		}
	};
	
	useEffect(() => {
		handleShowById();
	}, [formType]);
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			company: Yup.string()
			.required('La razón social es requerida'),
			type: Yup.string()
			.required('El tipo de edificación es requerido'),
			strata: Yup.string()
			.required('El estrato es requerido'),
			country: Yup.string()
			.required('El país es requerido'),
			state: Yup.string()
			.required('El departamento es requerido'),
			city: Yup.string()
			.required('La ciudad es requerida'),
			neighborhood: Yup.string()
			.required('El barrio es requerido'),
			drop1: Yup.string()
			.required('La av. principal es requerida'),
			value1: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
			.required('El valor n° 1 es requerido'),
			value2: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
			.required('El valor n° 2 es requerido'),
			value3: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
			.required('El valor n° 3 es requerido'),
		})
	);
	
	/**
	 *
	 * @description Execute submit of the form
	 * @param values
	 * @param resetForm
	 * @param setValues
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (resetForm, setValues) => {
		if (formType === 'store' ? permissionStore : permissionUpdate) {
			await dispatch( setIsLoadingForm(true) );
			
			try {
				let amenities = []
				
				if (formData.amenities.length > 0) {
					formData.amenities.map((item) => {
						amenities.push({ "id": item })
					})
				}
				
				const param = {
					realstate_id: formData.realState === '' ? null : formData.realState,
					nit: formData.nit === '' ? null : formData.nit,
					company: formData.company === '' ? null : formData.company,
					building_type_id: formData.type === '' ? null : formData.type,
					strata: formData.strata === '' ? null : formData.strata,
					neighborhood_id: formData.neighborhood === '' ? null : formData.neighborhood,
					drop1: formData.drop1 === '' ? null : formData.drop1,
					value1: formData.value1 === '' ? null : formData.value1,
					text1: formData.text1 === '' ? null : formData.text1,
					suffix1: formData.suffix1 === '' ? null : formData.suffix1,
					drop2: formData.drop2 === '' ? null : formData.drop2,
					value2: formData.value2 === '' ? null : formData.value2,
					text2: formData.text2 === '' ? null : formData.text2,
					value3: formData.value3 === '' ? null : formData.value3,
					suffix2: formData.suffix2 === '' ? null : formData.suffix2,
					address: formData.address === '' ? null : formData.address,
					reference: formData.reference === '' ? null : formData.reference,
					longitude: longitude === '' ? null : longitude,
					latitude: latitude === '' ? null : latitude,
					year_construction: formData.yearConstruction === '' ? null : formData.yearConstruction,
					area_construction: formData.areaConstruction === '' ? null : formData.areaConstruction,
					area_total: formData.areaTotal === '' ? null : formData.areaTotal,
					admin_id: formData.adminId === '' ? null : formData.adminId,
					admin_name: formData.adminName === '' ? null : formData.adminName,
					admin_phone1: formData.adminPhone === '' ? null : formData.adminPhone,
					amenities: amenities.length === 0 ? [] : amenities,
					bank_id: formData.bank === '' ? null : formData.bank,
					bank_type: formData.bankType === '' ? null : formData.bankType,
					bank_account: formData.bankAccount === '' ? null : formData.bankAccount,
				};
				
				let data;
				
				if (formType === 'store') {
					data = await setStore(param);
				} else {
					data = await setUpdateById(formData.id, param);
				}
				
				if (data.data.status === 200) {
					resetForm({ values: initialValues});
					setValues(initialValues);
					await dispatch( setFormData(initialValues) );
					await dispatch( setRefreshTable(!refreshTable) );
					await dispatch( setIsOpenModal(false) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingForm(false) );
		}
	};
	
	/**
	 *
	 * @description Execute dispatch formData state
	 * @return dispatch
	 *
	 */
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]:target.value }) );
	
	/**
	 *
	 * @description Execute dispatch create address state
	 * @return dispatch
	 *
	 */
	const handleCreateAddress = () => {
		let address = ''
		
		if (formData.drop1 !== "") {
			address = `${address} ${formData.drop1}`
		}
		
		if (formData.value1 !== "") {
			address = `${address} ${formData.value1}`
		}
		
		if (formData.text1 !== "") {
			address = `${address} ${formData.text1}`
		}
		
		if (formData.suffix1 !== "") {
			address = `${address} ${formData.suffix1}`
		}
		
		if (formData.value2 !== "") {
			address = `${address} # ${formData.value2}`
		}
		
		if (formData.text2 !== "") {
			address = `${address} ${formData.text2}`
		}
		
		if (formData.value3 !== "") {
			address = `${address} - ${formData.value3}`
		}
		
		if (formData.suffix2 !== "") {
			address = `${address} ${formData.suffix2}`
		}
		
		dispatch( setFormData( { ...formData, "address": address }) )
	};
	
	useEffect(() => {
		handleCreateAddress();
	}, [
		formData.drop1,
		formData.value1,
		formData.text1,
		formData.suffix1,
		formData.drop2,
		formData.value2,
		formData.text2,
		formData.value3,
		formData.suffix2
	]);
	
	/**
	 *
	 * @description Execute dispatch create address map state
	 * @return dispatch
	 *
	 */
	const handleCreateAddressMap = async () => {
		if (formType === 'store') {
			await handleShowCity(formData.city);
		}

		if (formDataCountry.name === '') {
			await swalError('El país no se encuentra seleccionado');
			return false;
		}

		if (formDataState.name === '') {
			await swalError('El departamento no se encuentra seleccionado');
			return false;
		}

		if (formDataCity.name === '') {
			await swalError('La ciudad no se encuentra seleccionada');
			return false;
		}

		await dispatch( setIsLoadingMap(true) );

		let address = '';

		address = formDataCountry.name + ", " + formDataState.name + " " + formDataCity.name + " " + formData.drop1 + " " + formData.value1;

		if (formData.text1 !== "") {
			address = `${address} ${formData.text1}`;
		}

		address = `${address} #`;

		if (formData.drop2 !== "") {
			address = `${address} ${formData.drop2} `;
		}

		address = `${address} ${formData.value2}`;

		if (formData.text2 !== "") {
			address = `${address} ${formData.text2}`;
		}

		address = `${address} - ${formData.value3}`;

		setAddressMap(address);

		await dispatch( setIsLoadingMap(false) );
	}
	
	/**
	 *
	 * @description Execute dispatch longitude state
	 * @return dispatch
	 *
	 */
	const handleLng = (value) => dispatch( setFormData( { ...formData, "longitude":value }) );
	
	/**
	 *
	 * @description Execute dispatch latitude state
	 * @return dispatch
	 *
	 */
	const handleLat = (value) => dispatch( setFormData( { ...formData, "latitude":value }) );
	
	return (
		<>
			{isLoadingShow && (
				<Feedback.Loading />
			)}
			{!isLoadingShow && (
				<Formik
					initialValues={formData}
					validationSchema={validationSchema}
					onSubmit={() => {}}
				>
					{({
					  errors,
					  touched,
					  handleChange,
					  handleBlur,
					  isValid,
					  dirty,
					  resetForm,
					  setValues
				  }) => (
						<Form noValidate>
							<div className="row">
								{formType === 'store' && (
									<div className="col-sm-12 col-md-6 mb-3">
										<DropdownSelect
											name="realState"
											value={formData.realState}
											handleChange={handleOnChange}
											className="font-family-roboto-regular fs-6 text-dark w-100"
										/>
									</div>
								)}
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextBox
										name="nit"
										label="NIT"
										placeholder="Ingrese nit..."
										hasLabelFixed={true}
										value={formData.nit}
										onChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextBox
										name="company"
										label="Razón social"
										placeholder="Ingrese razón social..."
										hasLabelFixed={true}
										value={formData.company}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.company && touched.company ? true : false}
										errorMessage={errors.company && touched.company ? errors.company : ''}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mb-3">
									<DropdownSelectBuildingType
										name="type"
										value={formData.type}
										handleChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.type && touched.type ? true : false}
										errorMessage={errors.type && touched.type ? errors.type : ''}
										className="font-family-roboto-medium text-dark fs-6 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.Select
										label="Estrato"
										name="strata"
										value={formData.strata}
										itemsArray={strataArray}
										onChange={(e) => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.strata && touched.strata ? true : false}
										errorMessage={errors.strata && touched.strata ? errors.strata : ''}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mb-3">
									<DropdownSelectCountry
										name="country"
										value={formData.country}
										handleChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.country && touched.country ? true : false}
										errorMessage={errors.country && touched.country ? errors.country : ''}
										className="font-family-roboto-medium text-dark fs-6 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mb-3">
									<DropdownSelectState
										name="state"
										value={formData.state}
										countryId={formData.country}
										handleChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.state && touched.state ? true : false}
										errorMessage={errors.state && touched.state ? errors.state : ''}
										className="font-family-roboto-medium text-dark fs-6 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mb-3">
									<DropdownSelectCity
										name="city"
										value={formData.city}
										stateId={formData.state}
										handleChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.city && touched.city ? true : false}
										errorMessage={errors.city && touched.city ? errors.city : ''}
										className="font-family-roboto-medium text-dark fs-6 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6 mb-3">
									<DropdownSelectNeighborhood
										name="neighborhood"
										value={formData.neighborhood}
										cityId={formData.city}
										handleChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.neighborhood && touched.neighborhood ? true : false}
										errorMessage={errors.neighborhood && touched.neighborhood ? errors.neighborhood : ''}
										className="font-family-roboto-medium text-dark fs-6 w-100"
									/>
								</div>
								
								<div className="col-sm-12 mb-3">
									<div className="row">
										<div className="col-sm-12 col-md-4 col-lg-4 mb-3">
											<Inputs.Select
												label="Av. Principal"
												name="drop1"
												value={formData.drop1}
												itemsArray={addressArray}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.drop1 && touched.drop1 ? true : false}
												errorMessage={errors.drop1 && touched.drop1 ? errors.drop1 : ''}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-2 col-lg-2 mb-3">
											<Inputs.TextBox
												type="number"
												name="value1"
												label="N° 1"
												placeholder="Ingrese n° 1..."
												hasLabelFixed={true}
												value={formData.value1}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.value1 && touched.value1 ? true : false}
												errorMessage={errors.value1 && touched.value1 ? errors.value1 : ''}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-3 col-lg-3 mb-3">
											<Inputs.TextBox
												name="text1"
												label="Info 1 (Ej: A,B,C)"
												placeholder="Ingrese n° 1..."
												hasLabelFixed={true}
												value={formData.text1}
												onChange={handleOnChange}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-3 col-lg-3 mb-3">
											<Inputs.TextBox
												name="suffix1"
												label="Sufijo 1 (Ej: Norte)"
												placeholder="Ingrese sufijo 1..."
												hasLabelFixed={true}
												value={formData.suffix1}
												onChange={handleOnChange}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-12 col-md-4 col-lg-4 mb-3">
											<Inputs.Select
												label="Av. Secundaria"
												name="drop2"
												value={formData.drop2}
												itemsArray={addressArray}
												onChange={handleOnChange}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-2 col-lg-2 mb-3">
											<Inputs.TextBox
												type="number"
												name="value2"
												label="N° 2"
												placeholder="Ingrese n° 2..."
												hasLabelFixed={true}
												value={formData.value2}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.value2 && touched.value2 ? true : false}
												errorMessage={errors.value2 && touched.value2 ? errors.value2 : ''}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-2 col-lg-2 mb-3">
											<Inputs.TextBox
												name="text2"
												label="Info 2 (Ej: A,B,C)"
												placeholder="Ingrese n° 2..."
												hasLabelFixed={true}
												value={formData.text2}
												onChange={handleOnChange}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-2 col-lg-2 mb-3">
											<Inputs.TextBox
												type="number"
												name="value3"
												label="N° 3"
												placeholder="Ingrese n° 3..."
												hasLabelFixed={true}
												value={formData.value3}
												onChange={(e) => {
													handleOnChange(e)
													handleChange(e)
												}}
												onBlur={handleBlur}
												hasError={errors.value3 && touched.value3 ? true : false}
												errorMessage={errors.value3 && touched.value3 ? errors.value3 : ''}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
										<div className="col-sm-12 col-md-2 col-lg-2 mb-3">
											<Inputs.TextBox
												name="suffix2"
												label="Sufijo 2 (Ej: Norte)"
												placeholder="Ingrese sufijo 2..."
												hasLabelFixed={true}
												value={formData.suffix2}
												onChange={handleOnChange}
												className="font-family-roboto-regular fs-6 text-dark w-100"
											/>
										</div>
									</div>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextArea
										readOnly={true}
										name="address"
										label="Dirección"
										placeholder="Ingrese dirección..."
										hasLabelFixed={true}
										value={formData.address}
										onChange={(e) => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.address && touched.address ? true : false}
										errorMessage={errors.address && touched.address ? errors.address : ''}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextArea
										name="reference"
										label="Referencia"
										placeholder="Ingrese referencia..."
										hasLabelFixed={true}
										value={formData.reference}
										onChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextBox
										readOnly={true}
										name="longitude"
										label="Longitud"
										placeholder="Ingrese longitud..."
										hasLabelFixed={true}
										value={longitude}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextBox
										readOnly={true}
										name="latitude"
										label="Latitud"
										placeholder="Ingrese latitud..."
										hasLabelFixed={true}
										value={latitude}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 mb-3">
									<Inputs.LoadingButton
										type="button"
										isLoading={isLoadingMap}
										isLoadingPosition="end"
										endIcon={<Layout.Icons.AddLocationIcon height={18} width={18} />}
										label="Generar geolocalización"
										onClick={handleCreateAddressMap}
										className={`bg-dark text-white font-family-roboto-regular fs-6 text-capitalize`}
									/>
								</div>
								
								<div className="col-sm-12 mb-3">
									<Feedback.MapBox
										longitude={formData.longitude}
										latitude={formData.latitude}
										address={addressMap}
										setLongitude={setLongitude}
										setLatitude={setLatitude}
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextBox
										type="number"
										name="yearConstruction"
										label="Año de construcción"
										placeholder="Ingrese año de construcción..."
										hasLabelFixed={true}
										value={formData.yearConstruction}
										onChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextBox
										name="areaConstruction"
										label="Área de construcción"
										placeholder="Ingrese área de construcción..."
										hasLabelFixed={true}
										value={formData.areaConstruction}
										onChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextBox
										name="areaTotal"
										label="Área total"
										placeholder="Ingrese área total..."
										hasLabelFixed={true}
										value={formData.areaTotal}
										onChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextBox
										name="adminId"
										label="ID del administrador"
										placeholder="Ingrese ID del administrador..."
										hasLabelFixed={true}
										value={formData.adminId}
										onChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextBox
										name="adminName"
										label="Nombre del administrador"
										placeholder="Ingrese nombre del administrador..."
										hasLabelFixed={true}
										value={formData.adminName}
										onChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextBox
										name="adminPhone"
										label="Teléfono del administrador"
										placeholder="Ingrese teléfono del administrador..."
										hasLabelFixed={true}
										value={formData.adminPhone}
										onChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<DropdownSelectArea
										name="area"
										value={formData.area}
										handleChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<DropdownSelectCommercialBuilding
										name="amenities"
										value={formData.amenities}
										areaId={formData.area}
										handleChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<DropdownSelectBank
										name="bank"
										value={formData.bank}
										handleChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<Inputs.TextBox
										type="number"
										name="bankAccount"
										label="Número de cuenta"
										placeholder="Ingrese número de cuenta..."
										hasLabelFixed={true}
										value={formData.bankAccount}
										onChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								
								<div className="col-sm-12 col-md-6 mb-3">
									<div className="font-family-roboto-light fs-6 text-dark w-100 mb-1">
										Tipo de Banco
									</div>
									<Inputs.ButtonGroup
										variant="outlined"
										name="bankType"
										value={formData.bankType === 'Ahorro' ? 'Corriente' : 'Ahorro'}
										itemsArray={actionsSwitchBank}
										className="font-family-roboto-regular fs-6 text-capitalize bg-dark-light-outlined"
										condition={formData.bankType}
										onClick={handleOnChange}
									/>
								</div>
							</div>
							
							{(formType === 'store' ? permissionStore : permissionUpdate) && (
								<div className="row">
									<div className="col-sm-12">
										<Inputs.LoadingButton
											type="button"
											isDisabled={formType === 'store' ? !(dirty && isValid) : false}
											isLoading={isLoadingForm}
											isLoadingPosition="start"
											startIcon={formType === 'store' ? <Layout.Icons.SaveIcon height={18} width={18} /> : <Layout.Icons.EditIcon height={18} width={18} />}
											label={`${formType === 'store' ? 'Guardar' : 'Actualizar'}`}
											onClick={() => hanbleSubmit(resetForm, setValues)}
											className={`${formType === 'store' ? ((dirty && isValid) && 'bg-primary') : 'bg-primary'} font-family-roboto-regular fs-6 text-capitalize mt-2 d-flex float-end`}
										/>
									</div>
								</div>
							)}
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default FormComponent;
