import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Layout from '../../../../components/mui/Layout'
import Information from '../../../../components/noAuthenticated/Information'
import FormComponent from './components/FormComponent'
import FormComponentAdviser from './components/FormComponentAdviser'
import ToggleSwitchComponent from './components/ToggleSwitchComponent'
import PropertiesComponent from './components/PropertiesComponent'
import PropertiesFavoritesComponent from './components/PropertiesFavoritesComponent'
import OccurrencesComponent from '../FilteredPage/components/OccurrencesComponent'
import TableActivityLog from '../../../../components/activity-logs/TableActivityLog'
import { showById } from '../../../../redux/actions/dashboard/filtered/filteredAction'
import { setActivityLogReset, setLogName, setSubjectId } from '../../../../redux/actions/dashboard/activities/activityLogAction'
import { Permission } from '../../../../services/permissions/Permission'

const FilteredDetailPage = ({ isCommercial }) => {
	const dispatch = useDispatch()
	const params = useParams()
	const uID = params.uID
	const isLoading = useSelector(state => state.loading.isLoading)
	const isActiveHistorical = useSelector(state => state.filtered.isActiveHistorical)
	const isSelectedToggleSwitch = useSelector(state => state.filtered.isSelectedToggleSwitch)
	const formDataDetail = useSelector(state => state.filtered.formDataDetail)
	const page = useSelector((state) => state.activityLog.page)
	const pages = useSelector((state) => state.activityLog.pages)
	const logName = useSelector((state) => state.activityLog.logName)
	const subjectId = useSelector((state) => state.activityLog.subjectId)
	
	useEffect(() => {
		}, [formDataDetail])
	
	/**
	 *
	 * @description Execute endpoint show by id of filtered
	 * @return dispatch
	 *
	 */
	const handleShowById = async () => await dispatch( showById(uID) )
	
	useEffect(() => {
		handleShowById()
	}, [uID])
	
	/**
	 *
	 * @description Execute endpoint show by name and subject id of activity log
	 * @return dispatch
	 *
	 */
	const handleActivityLog = async () => {
		await dispatch( setActivityLogReset() )
		await dispatch( setLogName('Filtrado') )
		await dispatch( setSubjectId(uID) )
	}
	
	useEffect(() => {
		if (isSelectedToggleSwitch === 2) {
			handleActivityLog()
		}
	}, [uID, isSelectedToggleSwitch])
	
	return (
		<Layout.Page title="Filtrado - Detalle del Caso">
			<Layout.Box sx={MainContentStyles}>
				<Layout.Box sx={LeftSectionStyles}>
					<Permission permission="filtereds.show">
						<Information
							isLoading={isLoading}
							createdDate={formDataDetail.createdDate}
							createdAt={formDataDetail.createdAt}
							createdAgo={formDataDetail.createdAgo}
							title={formDataDetail.customer}
							hasMobile={true}
							hasHistoricalButton={true}
							hasContact={true}
							mobile={formDataDetail.mobile}
							originChannel={formDataDetail.originChannel}
							contact={formDataDetail.contact}
						/>
					</Permission>
					
					{!isLoading && (
						<Layout.Box flexGrow={1} sx={ScrollableFormStyles}>
							<div className="row mt-1 p-3">
								<OccurrencesComponent isActive={isActiveHistorical} />
							</div>
							<FormComponent
								isActive={!isActiveHistorical}
								isCommercial={isCommercial}
							/>
							<FormComponentAdviser isActive={!isActiveHistorical} />
						</Layout.Box>
					)}
				</Layout.Box>
				
				<Layout.Box display="flex" overflow="hidden" flexDirection="column">
					<ToggleSwitchComponent
						isAuthenticated={true}
						hasProperties={true}
						hasFavorites={true}
						hasActivityLog={true}
					/>
					
					{/* SHOW PROPERTIES CARDS */}
					<PropertiesComponent
						isAuthenticatedPage={true}
					/>
					
					{/* SHOW FAVORITE CARDS */}
					<Permission permission="filtereds.favorites.list">
						{!isActiveHistorical && (
							<PropertiesFavoritesComponent
								isAuthenticated={true}
								isAuthenticatedPage={true}
								uID={uID}
							/>
						)}
					</Permission>
					
					{/* ACTIVITY LOG */}
					{(isSelectedToggleSwitch === 2 || isActiveHistorical) && (
						<TableActivityLog
							page={page}
							pages={pages}
							logName={logName}
							subjectId={subjectId}
						/>
					)}
				</Layout.Box>
			</Layout.Box>
		</Layout.Page>
	)
}

/**
 * @type {React.CSSProperties}
 */
const MainContentStyles = {
	gap: "50px",
	display: "grid",
	overflow: "hidden",
	position: "relative",
	height: "calc(100vh - 70px)",
	// outline: "1px solid red",
	gridTemplateColumns: "1fr 1fr",
	['&']: (theme) => {
		return {
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns:'1fr'
			}
		}
	}
}

/**
 * @type {React.CSSProperties}
 */
const LeftSectionStyles = {
	display: "flex",
	height: "calc(100vh - 70px)",
	overflowX: "none",
	overflowY: "hidden",
	flexDirection: "column",
	backgroundColor: (theme) => theme.palette.background.paper,
}

const ScrollableFormStyles = {
	overflowX: "hidde",
	overflowY: "scroll"
}

export default FilteredDetailPage
