import React, { useState } from 'react'
import Layout from '../../../../components/mui/Layout'
import Inputs from '../../../../components/mui/Inputs'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import StatisticByStatus from './components/StatisticByStatus'
import StatisticByAgent from './components/StatisticByAgent'
import StatisticByAdviser from './components/StatisticByAdviser'
import StatisticByOriginChannel from './components/StatisticByOriginChannel'
import StatisticByOriginContact from './components/StatisticByOriginContact'
import DropdownSelect from "../../settings/RealStatePage/components/DropdownSelect";

const arrayService = [
	{ id: 'Arriendo', name: 'Arriendo' },
	{ id: 'Compra', name: 'Compra' },
	{ id: 'Captación', name: 'Captación' },
	{ id: 'Proyecto', name: 'Proyecto' }
]

const arrayStatus = [
	{ id: 'Registrado', name: 'Registrado' },
	{ id: 'Desechado', name: 'Desechado' },
	{ id: 'Comercial', name: 'Comercial' }
]

const FilteredStatisticPage = () => {
	const [tag, setTag] = useState('1')
	const [getRealstateID, setRealstateID] = useState('')
	const [arrayDropdownService, setDropdownService] = useState(arrayService)
	const [arrayDropdownStatus, setDropdownStatus] = useState(arrayStatus)
	const [getService, setService] = useState('Arriendo')
	const [getStatus, setStatus] = useState('')
	const [getInitDate, setInitDate] = useState('')
	const [getEndDate, setEndDate] = useState('')
	
	const handleChange = (event, newValue) => {
		setTag(newValue)
		handleClear()
	}
	
	const handleClear = () => {
		setService('Arriendo')
		setInitDate('')
		setEndDate('')
	}
	
	return (
		<Layout.Page title="Filtrado - Estadísticas">
			<Layout.Card className="border-radius-0 m-0 p-0">
				<Layout.CardContent className="m-0 p-3">
					<div className="row mt-2 mb-0">
						<div className="col-sm-12">
							<DropdownSelect
								name="realState"
								value={getRealstateID}
								handleChange={e => setRealstateID(e.target.value)}
								className="font-family-roboto-regular fs-6 text-dark ml-2 mb-2 w-300px"
							/>
							<Inputs.Select
								label="Servicio"
								name="service"
								value={getService}
								itemsArray={arrayDropdownService}
								onChange={e => setService(e.target.value)}
								className="font-family-roboto-regular fs-6 text-dark w-150px ml-2 mb-2"
							/>
							<Inputs.Select
								label="Estado"
								name="stattus"
								value={getStatus}
								itemsArray={arrayDropdownStatus}
								onChange={e => setStatus(e.target.value)}
								className="font-family-roboto-regular fs-6 text-dark w-150px ml-2 mb-2"
							/>
							<Inputs.TextBox
								type="date"
								label="Desde"
								name="init_date"
								placeholder="Ingrese la fecha..."
								value={getInitDate}
								onChange={e => setInitDate(e.target.value)}
								className="font-family-roboto-regular fs-6 text-dark w-150px ml-2 mb-2"
							/>
							<Inputs.TextBox
								type="date"
								label="Hasta"
								name="end_date"
								placeholder="Ingrese la fecha..."
								value={getEndDate}
								onChange={e => setEndDate(e.target.value)}
								className="font-family-roboto-regular fs-6 text-dark w-150px ml-2 mb-2"
							/>
							<Layout.Tooltip
								title="Limpiar búsqueda"
								placement="top"
							>
								<Layout.IconButton
									color="inherit"
									onClick={handleClear}
									edge="start"
									className="bg-secondary font-roboto-semibold d-inline float-end mt-1 ml-2 mb-2"
								>
									<Layout.Icons.CloseIcon
										width={18}
										height={18}
										className="font-roboto-semibold text-white"
									/>
								</Layout.IconButton>
							</Layout.Tooltip>
						</div>
					</div>
				</Layout.CardContent>
			</Layout.Card>
			
			<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
				<Layout.CardContent className="m-0 p-0">
					<div className="row">
						<div className="col-sm-12">
							<TabContext value={tag}>
								<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
									<TabList onChange={handleChange}>
										<Tab
											className="font-family-roboto-medium fs-6 text-capitalize text-dark p-2"
											label="Manejo de contactos"
											value="1"
										/>
										<Tab
											className="font-family-roboto-medium fs-6 text-capitalize text-dark p-2"
											label="Origen de contacto"
											value="2"
										/>
									</TabList>
								</Box>
								<TabPanel value="1">
									<div className="row">
										<div className="col-sm-12">
											<div className="font-family-roboto-semibold fs-5 text-dark">
												Manejo de contactos
											</div>
										</div>
										<div className="col-sm-12 mt-2">
											<StatisticByStatus
												getRealstateID={getRealstateID}
												getService={getService}
												getInitDate={getInitDate}
												getEndDate={getEndDate}
											/>
										</div>
										<div className="col-sm-12 mt-3">
											<div className="font-family-roboto-semibold fs-5 text-dark">
												Filtrado
											</div>
										</div>
										<div className="col-sm-12 mt-2">
											<StatisticByAgent
												getRealstateID={getRealstateID}
												getService={getService}
												getStatus={getStatus}
												getInitDate={getInitDate}
												getEndDate={getEndDate}
											/>
										</div>
										<div className="col-sm-12 mt-3">
											<div className="font-family-roboto-semibold fs-5 text-dark">
												Negocios asignados
											</div>
										</div>
										<div className="col-sm-12 mt-2">
											<StatisticByAdviser
												getRealstateID={getRealstateID}
												getService={getService}
												getInitDate={getInitDate}
												getEndDate={getEndDate}
											/>
										</div>
									</div>
								</TabPanel>
								<TabPanel value="2">
									<div className="row">
										<div className="col-sm-12">
											<div className="font-family-roboto-semibold fs-5 text-dark">
												Contactos por fuente
											</div>
										</div>
										<div className="col-sm-12 mt-2">
											<StatisticByOriginContact
												getRealstateID={getRealstateID}
												getService={getService}
												getStatus={getStatus}
												getInitDate={getInitDate}
												getEndDate={getEndDate}
											/>
										</div>
										<div className="col-sm-12 mt-3">
											<div className="font-family-roboto-semibold fs-5 text-dark">
												Contactos por canal
											</div>
										</div>
										<div className="col-sm-12 mt-2">
											<StatisticByOriginChannel
												getRealstateID={getRealstateID}
												getService={getService}
												getStatus={getStatus}
												getInitDate={getInitDate}
												getEndDate={getEndDate}
											/>
										</div>
									</div>
								</TabPanel>
							</TabContext>
						</div>
					</div>
				</Layout.CardContent>
			</Layout.Card>
		</Layout.Page>
	)
}

export default FilteredStatisticPage
