import endPoints from './endPoints'
import axios from '../../../../../../services/axios'

const {
	show,
	arrayListFilesByAlbumsTypes
} = endPoints

export const getShowById = async (id) => (
	await axios.get(`${show}${id}`)
)

export const getArrayListFilesByAlbumsTypes = async (data) => (
	await axios.post(arrayListFilesByAlbumsTypes, data)
)

