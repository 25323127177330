import { useState } from "react";

export const useTaps = (initialValue = 1) => {
    const [isActive, setIsActive] = useState(initialValue);

    const handleTap = (id) => {
        setIsActive(id);
    };

    return {
        isActive,
        handleTap,
    };
};
