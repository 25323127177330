import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../../../../components/mui/Layout';
import TableRowComponent from './TableRowComponent';
import { getArrayList } from '../services';
import { setIsLoading } from '../../../../../redux/loading/actions/loadingAction';
import { setPerPage, setPage, setPages, setArrayList } from '../redux/actions/userAction';
import swalErrors from '../../../../../hooks/useErrors';
import usePermission from '../../../../../hooks/usePermission';
import { userSuperAdminColumns, userAdminColumns, perPages } from '../../../../../helpers/variablesColumns';

const TableComponent = () => {
	const dispatch = useDispatch();
	const isLoading = useSelector( ({ loading }) => loading.isLoading);
	const userData = useSelector(({ auth }) => auth.userData);
	const refreshTable = useSelector( ({ user }) => user.refreshTable);
	const perPage = useSelector( ({ user }) => user.perPage);
	const page = useSelector( ({ user }) => user.page);
	const search = useSelector( ({ user }) => user.search);
	const realState = useSelector( ({ user }) => user.realState);
	const pages = useSelector( ({ user }) => user.pages);
	const arrayList = useSelector( ({ user }) => user.arrayList);
	const permission = usePermission('users.list');
	
	/**
	 *
	 * @description Execute endpoint array list of users
	 * @return dispatch
	 *
	 */
	const handleArrayList = async () => {
		if (permission) {
			await dispatch( setIsLoading(page === 1 ? true : false) );
			
			try {
				const param = {
					perPage: perPage,
					page: page,
					search: search === '' ? null : search,
					realstate: realState
				};
				
				const { data } = await getArrayList(param);
				
				if (data.status === 200) {
					await dispatch( setPages(data.data.pages) );
					await dispatch( setArrayList(data.data.records) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoading(false) );
		}
	};
	
	
	useEffect(() => {
		handleArrayList();
	}, [
		perPage,
		page,
		realState,
		refreshTable
	]);
	
	/**
	 *
	 * @description Execute dispatch perPage state
	 * @return dispatch
	 *
	 */
	const handleChangePerPage = ({ target }) => dispatch( setPerPage(target.value) );
	
	/**
	 *
	 * @description Execute dispatch page state
	 * @return dispatch
	 *
	 */
	const handleChangePage = (event, value) => dispatch( setPage(value) );
	
	return (
		<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
			<Layout.CardContent className="m-0 p-4">
				{permission && (
					<>
						<Layout.TablePagination
							arrayList={arrayList.length}
							perPage={perPage}
							perPages={perPages}
							page={page}
							pages={pages}
							handleChangePerPage={handleChangePerPage}
							handleChangePage={handleChangePage}
						/>
						<Layout.Table>
							<Layout.TableHead columns={userData.roles[0] === 'Super Administrador' ? userSuperAdminColumns : userAdminColumns} />
							<tbody>
							{isLoading && (
								<Layout.TableRowLoading columns={userData.roles[0] === 'Super Administrador' ? userSuperAdminColumns : userAdminColumns} />
							)}
							{!isLoading && (
								<>
									{arrayList.length === 0 && (
										<Layout.TableRowNoFound colSpan={userData.roles[0] === 'Super Administrador' ? userSuperAdminColumns.length : userAdminColumns.length} />
									)}
									{arrayList.length > 0 && (
										arrayList.map(({ id, avatar, first_name, last_name, email, role, realstate, status, verified, permissions }, index) => (
											<TableRowComponent
												key={index}
												id={id}
												avatar={avatar}
												firstName={first_name}
												lastName={last_name}
												email={email}
												role={role}
												realState={realstate}
												status={status}
												verified={verified}
												permissions={permissions}
											/>
										))
									)}
								</>
							)}
							</tbody>
						</Layout.Table>
						<Layout.TablePagination
							arrayList={arrayList.length}
							perPage={perPage}
							perPages={perPages}
							page={page}
							pages={pages}
							handleChangePerPage={handleChangePerPage}
							handleChangePage={handleChangePage}
						/>
					</>
				)}
			</Layout.CardContent>
		</Layout.Card>
	);
};

export default TableComponent;
