import React from 'react'
import MuiButton from "@material-ui/core/Button"
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform: 'none',
        fontSize: '18px',
    },
    primary: {
        color: '#ffffff',
        backgroundColor: '#85BF02',
        '&:hover': {
            backgroundColor: '#9fcc23'
        }
    },
    secondary: {
        color: '#5D5D5D',
        backgroundColor: '#D9DCDF',
        '&:hover': {
            backgroundColor: '#CECECE'
        }
    }
}))

export default function ButtonSubmit(props) {

    const classes = useStyles()
    const { label, color, variant, startIcon, endIcon, ...other } = props

    if (color === 'primary') {
        return (
            <MuiButton
                type="submit"
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ root: classes.root, label: classes.label }}
                className={classes.primary}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
    
    if (color === 'secondary') {
        return (
            <MuiButton
                type="submit"
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ root: classes.root, label: classes.label }}
                className={classes.secondary}
                {...other}
            >
                {label}
            </MuiButton>
        )
    }
}
