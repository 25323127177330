import React from 'react'
import WhatsApp from '@material-ui/icons/WhatsApp'
import PropTypes from 'prop-types'

const WhatsAppIcon = ({ height, width, ...props }) => {

    return (
        <WhatsApp
            height={height}
            width={width}
            {...props}
        />
    )
}

WhatsAppIcon.propTypes = {
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
}

export default WhatsAppIcon