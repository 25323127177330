import { initialValuesKey as initialValues } from '../../../../../../helpers/variablesInitialValues';
import * as types from '../types/keyType';

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	search: '',
	arrayList: [],
	arrayListToDropdown: [],
	refreshTable: false,
	isOpenModal: false,
	isOpenModalPermission: false,
	isLoadingShow: false,
	isLoadingForm: false,
	formType: '',
	formData: initialValues
};

const keyReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_KEY:
			return {
				...state,
				perPage: payload
			};
		
		case types.PAGE_KEY:
			return {
				...state,
				page: payload
			};
		
		case types.PAGES_KEY:
			return {
				...state,
				pages: payload
			};
		
		case types.SEARCH_KEY:
			return {
				...state,
				search: payload
			};
		
		case types.ARRAY_LIST_KEY:
			return {
				...state,
				arrayList: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_KEY:
			return {
				...state,
				arrayListToDropdown: payload
			};
		
		case types.REFRESH_TABLE_KEY:
			return {
				...state,
				refreshTable: payload
			};
		
		case types.IS_OPEN_MODAL_KEY:
			return {
				...state,
				isOpenModal: payload
			};
		
		case types.IS_LOADING_SHOW_KEY:
			return {
				...state,
				isLoadingShow: payload
			};
		
		case types.IS_LOADING_FORM_KEY:
			return {
				...state,
				isLoadingForm: payload
			};
		
		case types.FORM_TYPE_KEY:
			return {
				...state,
				formType: payload
			};
		
		case types.FORM_DATA_KEY:
			return {
				...state,
				formData: payload
			};
		
		default:
			return state
	}
};

export default keyReducer;
