import { initialValuesCommercialStatus as initialValues } from '../../../../../../../helpers/variablesInitialValues';
import * as types from '../types/commercialStatusType';

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	search: '',
	realState: '',
	arrayList: [],
	arrayListToDropdown: [],
	refreshTable: false,
	isOpenModal: false,
	isOpenModalPermission: false,
	isLoadingShow: false,
	isLoadingForm: false,
	formType: '',
	formData: initialValues
};

const commercialStatusReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_COMMERCIAL_STATUS:
			return {
				...state,
				perPage: payload
			};
		
		case types.PAGE_COMMERCIAL_STATUS:
			return {
				...state,
				page: payload
			};
		
		case types.PAGES_COMMERCIAL_STATUS:
			return {
				...state,
				pages: payload
			};
		
		case types.SEARCH_COMMERCIAL_STATUS:
			return {
				...state,
				search: payload
			};
			
		case types.REALSTATE_COMMERCIAL_STATUS:
			return {
				...state,
				realState: payload
			};
		
		case types.ARRAY_LIST_COMMERCIAL_STATUS:
			return {
				...state,
				arrayList: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_COMMERCIAL_STATUS:
			return {
				...state,
				arrayListToDropdown: payload
			};
		
		case types.REFRESH_TABLE_COMMERCIAL_STATUS:
			return {
				...state,
				refreshTable: payload
			};
		
		case types.IS_OPEN_MODAL_COMMERCIAL_STATUS:
			return {
				...state,
				isOpenModal: payload
			};
		
		case types.IS_LOADING_SHOW_COMMERCIAL_STATUS:
			return {
				...state,
				isLoadingShow: payload
			};
		
		case types.IS_LOADING_FORM_COMMERCIAL_STATUS:
			return {
				...state,
				isLoadingForm: payload
			};
		
		case types.FORM_TYPE_COMMERCIAL_STATUS:
			return {
				...state,
				formType: payload
			};
		
		case types.FORM_DATA_COMMERCIAL_STATUS:
			return {
				...state,
				formData: payload
			};
		
		default:
			return state
	}
};

export default commercialStatusReducer;
