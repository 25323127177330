export const PER_PAGE_PROPERTY_DESTINATION = 'PER_PAGE_PROPERTY_DESTINATION';
export const PAGE_PROPERTY_DESTINATION = 'PAGE_PROPERTY_DESTINATION';
export const SEARCH_PROPERTY_DESTINATION = 'SEARCH_PROPERTY_DESTINATION';
export const PAGES_PROPERTY_DESTINATION = 'PAGES_PROPERTY_DESTINATION';
export const ARRAY_LIST_PROPERTY_DESTINATION = 'ARRAY_LIST_PROPERTY_DESTINATION';
export const ARRAY_LIST_TO_DROPDOWN_PROPERTY_DESTINATION = 'ARRAY_LIST_TO_DROPDOWN_PROPERTY_DESTINATION';
export const REFRESH_TABLE_PROPERTY_DESTINATION = 'REFRESH_TABLE_PROPERTY_DESTINATION';
export const IS_OPEN_MODAL_PROPERTY_DESTINATION = 'IS_OPEN_MODAL_PROPERTY_DESTINATION';
export const IS_LOADING_SHOW_PROPERTY_DESTINATION = 'IS_LOADING_SHOW_PROPERTY_DESTINATION';
export const IS_LOADING_FORM_PROPERTY_DESTINATION = 'IS_LOADING_FORM_PROPERTY_DESTINATION';
export const FORM_TYPE_PROPERTY_DESTINATION = 'FORM_TYPE_PROPERTY_DESTINATION';
export const FORM_DATA_PROPERTY_DESTINATION = 'FORM_DATA_PROPERTY_DESTINATION';
