import * as types from '../../actionsTypes/dashboard/sidebarTypes'

export const setIsOpenSidebar = value => ({
	type: types.IS_OPEN_SIDEBAR,
	payload: value
})

export const setIsLoadingSidebar = value => ({
	type: types.IS_LOADING_SIDEBAR,
	payload: value
})
