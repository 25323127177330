import endPoints from './endPoints'
// import axios from '../../../../../services/axios'
import axios from 'axios'

const {
	arrayListDropdown
} = endPoints

/**
 *
 * @description Execute endpoint array list in dropdown of cities algolia
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const getArrayListDropdown = async (data) => (
	await axios.get(arrayListDropdown, data)
)
