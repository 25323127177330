export const PER_PAGE_CONTACT = 'PER_PAGE_CONTACT';
export const PAGE_CONTACT = 'PAGE_CONTACT';
export const SEARCH_CONTACT = 'SEARCH_CONTACT';
export const PAGES_CONTACT = 'PAGES_CONTACT';
export const ARRAY_LIST_CONTACT = 'ARRAY_LIST_CONTACT';
export const ARRAY_LIST_TO_DROPDOWN_CONTACT = 'ARRAY_LIST_TO_DROPDOWN_CONTACT';
export const REFRESH_TABLE_CONTACT = 'REFRESH_TABLE_CONTACT';
export const IS_OPEN_MODAL_CONTACT = 'IS_OPEN_MODAL_CONTACT';
export const IS_LOADING_SHOW_CONTACT = 'IS_LOADING_SHOW_CONTACT';
export const IS_LOADING_FORM_CONTACT = 'IS_LOADING_FORM_CONTACT';
export const FORM_TYPE_CONTACT = 'FORM_TYPE_CONTACT';
export const FORM_DATA_CONTACT = 'FORM_DATA_CONTACT';
