import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  imageFileTypes,
  videoFileTypes,
  returnFileSize,
  validAssetFileType,
} from '../../../../../../utils/assetUtils'

/**
 * @param {{
  title: string;
  subtitle: string;
  max_file_size?: number;
  multiple?: boolean;
  showPreview?: boolean;
  [key: string]: any;
  onInputChange:(files:Files[])=>void
}} obj 
 * @returns 
 */
function CommonUploadBox({
  max_file_size = 0,
  multiple = false,
  showPreview = true,
  onInputChange,
  asset_type,
  ...otherProps
}) {
  const wrapperRef = React.useRef(null)

  const inputRef = React.useRef(null)
  const onDragEnter = () => wrapperRef.current.classList.add('dragover')
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover')
  const onDrop = () => wrapperRef.current.classList.remove('dragover')

  React.useEffect(() => {
    function updateImagePreview() {
      /** @type { File[] } */
      const currentFiles = inputRef.current?.files ?? []

      const validFiles = []

      if (currentFiles.length === 0) {
        // const p = document.createElement("p");
        // p.textContent = "No files currently selected for upload";
        // preview.appendChild(p);
      } else {
        // const previewList = document.createElement("ol");
        // preview.appendChild(previewList);

        for (const file of currentFiles) {
          // const listItem = document.createElement("li");
          // const paragraph = document.createElement("p");

          if (validAssetFileType(file, asset_type)) {
            // paragraph.textContent = `File name ${
            //   file.name
            // }, file size ${returnFileSize(file.size)}.`;

            validFiles.push({
              name: file.name,
              sizeFormated: returnFileSize(file.size),
              previewBlob: URL.createObjectURL(file),
              type:file.type,
              lastModified:file.lastModified,
              file,
            })

            // const image = document.createElement("img");

            // image.src = URL.createObjectURL(file);
            // image.style.width = "100px";
            // image.style.backgroundSize = "cover";

            // listItem.appendChild(image);
            // listItem.appendChild(paragraph);
          } else {
            // paragraph.style.fontWeight = "bold";
            // paragraph.textContent = `File name ${file.name}: Not a valid file type. Update your selection.`;
            // listItem.appendChild(paragraph);
          }

          // previewList.appendChild(listItem);
          // paragraph.classList.add("margin-0");
        }

        onInputChange(validFiles)
        inputRef.current.value = null
      }
    }
    inputRef.current?.addEventListener('change', updateImagePreview)
    return () =>
      inputRef.current?.removeEventListener('change', updateImagePreview)
  }, [])
  return (
    <Box
      gap={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ transition: 'all ease-in-out 200ms' }}
    >
      {/* <Box className="preview" sx={{ display: "none", overflowY:'scroll' }} maxHeight="40vh">
        <strong>preview</strong>
      </Box> */}
      <Box
        gap={1}
        width="100%"
        display="flex"
        minHeight="120px"
        maxHeight="400px"
        alignItems="center"
        position="relative"
        justifyContent="center"
        borderRadius="8px"
        border={`2px dashed`}
        sx={{
          cursor: 'pointer',
          transition: 'all ease-in-out .2s',
        }}
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        {...otherProps}
      >
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M33.0563 17.1517C31.8946 11.2579 26.7183 6.83334 20.5 6.83334C15.5629 6.83334 11.275 9.63501 9.13958 13.735C3.9975 14.2817 0 18.6379 0 23.9167C0 29.5713 4.59542 34.1667 10.25 34.1667H32.4583C37.1733 34.1667 41 30.34 41 25.625C41 21.115 37.4979 17.4592 33.0563 17.1517ZM23.9167 22.2083V29.0417H17.0833V22.2083H11.9583L20.5 13.6667L29.0417 22.2083H23.9167Z"
            fill="black"
          />
        </svg>

        <Typography fontWeight="700">
          Arrastra y suelta los archivos aquí o <u>seleccionalos</u>
        </Typography>
        <input
          id="asas"
          accept={{
            1:imageFileTypes.join(','),
            2:imageFileTypes.join(','),
            3:videoFileTypes.join(',')
          }[asset_type]}
          ref={inputRef}
          type="file"
          multiple={multiple}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            opacity: 0,
          }}
        />
      </Box>
    </Box>
  )
}

export default CommonUploadBox
