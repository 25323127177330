import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { history } from '../../../../../redux/store'
import Layout from '../../../../../components/mui/Layout'
import { setFormDataDetail, setIsActiveHistorical, setIsOpenModal, setIsSelectedToggleSwitch, setResetValidate } from '../../../../../redux/actions/dashboard/filtered/filteredAction'
import { setLogName, setPage, setPages, setSubjectId } from '../../../../../redux/actions/dashboard/activities/activityLogAction'
import { Permission } from '../../../../../services/permissions/Permission'

const OccurrencesRowComponent = ({ position, id, number, status, createdDate, createdAt, service, amount }) => {
	const dispatch = useDispatch()
	const isActiveHistorical = useSelector( state => state.filtered.isActiveHistorical)
	const formDataDetail = useSelector( state => state.filtered.formDataDetail)
	
	useEffect(() => {
	}, [position, id, number, status, createdDate, createdAt, service, amount])
	
	/**
	 *
	 * @description Execute redirect filtered detail (by status registrado), or redirect filtered preview (by status <> registrado)
	 * @return dispatch
	 *
	 */
	const handleShow = async () => {
		if (isActiveHistorical) {
			dispatch( setFormDataDetail({ ...formDataDetail, "id": id }) )
			dispatch( setPage(1) )
			dispatch( setPages(1) )
			dispatch( setLogName('Filtrado') )
			dispatch( setSubjectId(id) )
		} else {
			dispatch( setResetValidate() )
			dispatch( setIsActiveHistorical(false) )
			dispatch( setIsOpenModal(true) )
			
			if (status === 'Borrador') {
				dispatch( setIsSelectedToggleSwitch(0) )
				await history.push(`/dashboard/filtrado/registro-de-casos/detalle/${id}`)
			} else {
				dispatch( setIsSelectedToggleSwitch(1) )
				await history.push(`/dashboard/filtrado/registro-de-casos/vista-previa/${id}`)
			}
		}
	}
	
	return (
		<tr className={`m-0 p-0 h-30px ${position % 2 != 0 && 'bg-kanban'}`}>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2 text-center"
				width="15%"
			>
				<div className="font-family-roboto-regular fs-7 text-dark">
					{ number }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2 text-center"
				width="15%"
			>
				<div className="font-family-roboto-regular fs-7 text-dark">
					{ status }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2 text-center"
				width="25%"
			>
				<div className="font-family-roboto-regular fs-7 text-dark">
					<Layout.Timezone
						date={createdDate}
						dateAt={createdAt}
					/>
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2 text-center"
				width="15%"
			>
				<div className="font-family-roboto-regular fs-7 text-dark">
					{ service }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2 text-end"
				width="23%"
			>
				<div className="font-family-roboto-regular fs-7 text-dark">
					{ amount }
				</div>
			</td>
			<td
				className="m-0 p-0 text-center"
				width="7%"
			>
				<div className="iconShow animate__animated animated__fadeIn">
					<Permission
						permission="filtereds.logs.list"
					>
						<Layout.IconButton
							color="inherit"
							size="sm"
							className="w-26px h-26px text-dark"
							onClick={handleShow}
						>
							<Layout.Icons.ArrowRightAltIcon
								height={16}
								width={16}
							/>
						</Layout.IconButton>
					</Permission>
				</div>
			</td>
		</tr>
	)
}

OccurrencesRowComponent.propTypes = {
	position: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired,
	number: PropTypes.string.isRequired,
	status: PropTypes.string.isRequired,
	createdDate: PropTypes.string.isRequired,
	createdAt: PropTypes.number.isRequired,
	service: PropTypes.string.isRequired,
	amount: PropTypes.string.isRequired
}

export default OccurrencesRowComponent
