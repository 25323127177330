import * as types from '../types/passwordResetType';

/**
 *
 * @description Set reset password reset state
 * @return dispatch
 *
 */
export const setResetPasswordReset = () => (
	async (dispatch) => {
		await dispatch( setColorStatus('') );
		await dispatch( setTextStatus('') );
		await dispatch( setIsActiveAlert(false) );
		await dispatch( setIsActiveButton(false) );
		await dispatch( setIsActiveForm(false) );
		await dispatch( setIsLoadingForm(false) );
	}
);

/**
 *
 * @description Set colorStatus state
 * @param value
 * @return object
 *
 */
export const setColorStatus = value => ({
	type: types.COLOR_STATUS_PASSWORD_RESET,
	payload: value
});

/**
 *
 * @description Set textStatus state
 * @param value
 * @return object
 *
 */
export const setTextStatus = value => ({
	type: types.TEXT_STATUS_PASSWORD_RESET,
	payload: value
});

/**
 *
 * @description Set activeAlert state
 * @param value
 * @return object
 *
 */
export const setIsActiveAlert = value => ({
	type: types.IS_ACTIVE_ALERT_PASSWORD_RESET,
	payload: value
});

/**
 *
 * @description Set activeButton state
 * @param value
 * @return object
 *
 */
export const setIsActiveButton = value => ({
	type: types.IS_ACTIVE_BUTTON_PASSWORD_RESET,
	payload: value
});

/**
 *
 * @description Set activeForm state
 * @param value
 * @return object
 *
 */
export const setIsActiveForm = value => ({
	type: types.IS_ACTIVE_FORM_PASSWORD_RESET,
	payload: value
});

/**
 *
 * @description Set loadingForm state
 * @param value
 * @return object
 *
 */
export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_PASSWORD_RESET,
	payload: value
});
