import * as types from '../../../actionsTypes/filtered/filteredAdviserTypes'
import { initialValuesFilteredAdviserForm as initialValues } from '../../../../helpers/variablesInitialValues'
import { setIsLoading } from '../../loadingAction'
import { getShowById, getArrayListFavoritesById } from './services'
import errorServices from '../../../../services/errors'

export const getArrayListFavorites = id => (
	async (dispatch) => {
		await dispatch(setIsLoadingFavorites(true))
		
		await getArrayListFavoritesById(id).then(({ data }) => {
			dispatch(setArrayListFavorites(data.data))
		}).catch((error) => errorServices(error))
		
		await dispatch(setIsLoadingFavorites(false))
	}
)

export const showById = id => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getShowById(id,'call-center').then(({ data }) => {
			dispatch( setFormData(data.data) )
			dispatch( setArrayListFavorites(data.data.favorites) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const setFilteredAdviserReset = () => (
	async (dispatch) => {
		await dispatch( setArrayListFavorites([]) )
		await dispatch( setIsOpenCustomerModal(false) )
		await dispatch( setIsLoadingFavorites(false) )
		await dispatch( setFormData(initialValues) )
	}
)

export const setArrayListFavorites = value => ({
	type: types.ARRAY_LIST_FAVORITES_FILTERED_ADVISER_FORM,
	payload: value
})

export const setIsOpenCustomerModal = value => ({
	type: types.IS_OPEN_CUSTOMER_MODAL_FILTERED_ADVISER_FORM,
	payload: value
})

export const setIsLoadingFavorites = value => ({
	type: types.IS_LOADING_FAVORITES_FILTER_ADVISER_FORM,
	payload: value
})

export const setFormData = value => ({
	type: types.FORM_DATA_FILTERED_ADVISER_FORM,
	payload: value
})
