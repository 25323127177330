import React, { useEffect, useState } from 'react'
import {
  Tabs,
  GalleryPhoto360,
  GalleryPictures,
  GalleryVideos,
} from '../../gallery'
import { useTaps } from '../hooks/useTaps'
import { GalleryTourVirtual } from '../galleryTourVirtual/GalleryTourVirtual'
import styles from './gallery.module.css'
import { postListFilesByAlbumsTypes } from '../../../../api/properties'
import Skeleton from '../skeleton'
import { swalError } from '../../../../services/sweetalert'

export function GalleryMain({ formData }) {
  const { handleTap, isActive } = useTaps()
  const [isLoadingView, _] = useState(false)

  // Paginations
  // const [pagePhotos, setPagePhotos] = useState(1)
  // const [pagePhotos360, setPagePhotos360] = useState(1)
  // const [pageVideos, setPageVideos] = useState(1)
  // const [pageTour, setPageTour] = useState(1)

  const resources = formData?.property_assets ?? []

  const has_photo_360 =
    resources.find(
      (album) => album.asset.asset_type_id == 2,
    ) != undefined;

  const has_tour =
    resources.find(
      (album) => album.asset.asset_type_id == 4,
    ) != undefined;

  const has_videos =
    resources.find(
      (album) => album.asset.asset_type_id == 3,
    ) != undefined;


  const itemsTaps = [
    {
      id: 1,
      name: 'Fotos',
    },
    has_photo_360
      ? {
          id: 2,
          name: 'Fotos 360°',
        }
      : { id: -1 },
    has_tour
      ? {
          id: 4,
          name: 'Tour Virtual',
        }
      : { id: -1 },
    has_videos
      ? {
          id: 3,
          name: 'Videos',
        }
      : { id: -1 },
  ];
  
  // useEffect(() => {
  //   if (itemsTaps.length > 0) {
  //     itemsTaps.find(({ id }) => id !== isActive && handleTap(id))
  //   }
  // }, [])


  if (isLoadingView) {
    return <Skeleton type={'gallery'} />
    // return <>Loading</>
  }

  return (
    <section className={styles.gallery_box}>
      <Tabs
        itemsArray={itemsTaps}
        tapActive={isActive}
        handleTap={handleTap}
      />
      {isActive === 1 && (
        <GalleryPictures
          isLoading={false}
          dataArrayFotos={
            resources.filter(
              (album) => album.asset.asset_type_id == 1,
            )
          }
          setPage={()=>{}}
          hasMore={false}
        />
      )}
      {isActive === 2 && (
        <GalleryPhoto360
          dataArrayPhoto360={
            resources.filter(
              (album) => album.asset.asset_type_id == 2,
            )
          }
          isLoading={false}
          setPage={()=>{}}
          hasMore={false}
        />
      )}
      {isActive === 3 && (
        <GalleryVideos
          dataArrayVideos={
            resources.filter(
              (album) => album.asset.asset_type_id == 3,
            )
          }
          isLoading={false}
          setPage={()=>{}}
          hasMore={false}
        />
      )}
      {isActive === 4 && (
        <GalleryTourVirtual
          dataArrayTourVirtual={
            resources.filter(
              (album) => album.asset.asset_type_id == 4,
            )
          }
          isLoading={false}
          setPage={()=>{}}
          hasMore={false}
        />
      )}
    </section>
  )
}
