import React from 'react';
import Layout from '../../../components/mui/Layout';

const MainPage = () => {
  
  return (
    <Layout.Page title="Escritorio">
      <Layout.HeaderContent
        title="Dashboard"
      />
    </Layout.Page>
  );
};

export default MainPage;
