import React from 'react'
import PropTypes from 'prop-types'
import LockOpen from '@mui/icons-material/LockOpen'

const LockOpenIcon = ({ height, width, ...more }) => {

  return (
    <LockOpen
      height={height}
      width={width}
      {...more}
    />
  )
}

LockOpenIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
}

export default LockOpenIcon
