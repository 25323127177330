import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
	return (
		<div className="row animate__animated animate__fadeIn">
			<div className="col-sm-12 text-center">
				<CircularProgress
					style={{height: '32px', width: '32px'}}
					color="inherit"
				/>
				<div className="font-family-roboto-regular fs-6 text-secondary">
					Espere un momento por favor...
				</div>
			</div>
		</div>
	);
};

export default Loading;
