import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Inputs from '../../../../../../components/mui/Inputs';
import DropdownActionComponent from './DropdownActionComponent';
import { setUpdateStatusById } from '../services';
import { setIsOpenModal, setRefreshTable, setFormData, setFormType, setIsLoadingShow, setIsLoadingForm } from '../redux/actions/commercialStatusAction';
import { setActivityLogReset, setIsOpenModal as setIsOpenModalActivity, setLogName, setSubjectId } from '../../../../../../redux/actions/dashboard/activities/activityLogAction';
import swalErrors from '../../../../../../hooks/useErrors';
import { swalSuccess } from '../../../../../../hooks/useSweetAlert';
import usePermission from '../../../../../../hooks/usePermission';

const TableRowComponent = (props) => {
	const {
		id,
		realState,
		order,
		name,
		status
	} = props;
	const dispatch = useDispatch();
	const userData = useSelector(state => state.auth.userData)
	const refreshTable = useSelector(({ commercialStatus }) => commercialStatus.refreshTable);
	const permission = usePermission('commercialStatus.status');
	
	useEffect(() => {
	}, [
		id,
		realState,
		name,
		status
	]);
	
	/**
	 *
	 * @description Execute dispatch open modal, reset formData, loading show, loading form, formType and activityLog state
	 * @return dispatch
	 *
	 */
	const handleIsOpenModal = async (formType) => {
		if (formType === 'activity-log') {
			await dispatch( setActivityLogReset() );
			await dispatch( setLogName('Estatus Comercial') );
			await dispatch( setSubjectId(id) );
			await dispatch( setIsOpenModalActivity(true) );
		} else {
			await dispatch( setFormData({ id: id, name: name }) );
			await dispatch( setFormType(formType) );
			await dispatch( setIsLoadingShow(false) );
			await dispatch( setIsLoadingForm(false) );
			await dispatch( setIsOpenModal(true) );
		}
	};
	
	/**
	 *
	 * @description Execute endpoint change status
	 * @return dispatch
	 *
	 */
	const handleUpdateStatusById = async () => {
		if (permission) {
			try {
				const { data } = await setUpdateStatusById(id);
				
				if (data.status === 200) {
					await swalSuccess('Cambio de estatus', data.data);
					await dispatch( setRefreshTable(!refreshTable) );
				}
			} catch (error) {
				await swalErrors(error);
			}
		}
	};
	
	return (
		<tr className="m-0 p-0">
			{userData.roles[0] === 'Super Administrador' && (
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="40%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark">
						{ realState === null ? '' : realState }
					</div>
				</td>
			)}
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="10%"
			>
				<div className="font-family-roboto-regular fs-6 text-dark text-wrap text-center">
					{ order }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width={userData.roles[0] === 'Super Administrador' ? '35%' : '75%'}
			>
				<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
					{ name }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="5%"
			>
				<div className="text-center">
					<Inputs.Switch
						name="status"
						color={status === 'Active' ? 'success' : 'error'}
						isChecked={status === 'Active' ? true : false}
						position="end"
						onChange={handleUpdateStatusById}
					/>
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="5%"
			>
				<div className="mx-auto d-flex justify-content-center">
					<DropdownActionComponent handleIsOpenModal={handleIsOpenModal} />
				</div>
			</td>
		</tr>
	);
};

TableRowComponent.propTypes = {
	id: PropTypes.number.isRequired,
	realState: PropTypes.string.isRequired,
	order: PropTypes.number,
	name: PropTypes.string.isRequired,
	status: PropTypes.any.isRequired
};

export default TableRowComponent;

