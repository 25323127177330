const ROOT = 'dashboard/settings/permissions';

export default {
	arrayList: `${ROOT}/list`,
	arrayListToDropdown: `${ROOT}`,
	store: `${ROOT}`,
	show: `${ROOT}/`,
	update: `${ROOT}/`,
	destroy: `${ROOT}/`,
};
