import React from 'react';
import Layout from '../../../../../components/mui/Layout';
import SearchComponent from './components/SearchComponent';
import TableComponent from './components/TableComponent';
import ModalComponent from './components/ModalComponent';

const PropertyFloorPage = () => {
	
	return (
		<Layout.Page title="Pisos">
			<SearchComponent />
			
			<TableComponent />
			
			<ModalComponent />
		</Layout.Page>
	);
};

export default PropertyFloorPage;
