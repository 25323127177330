import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Chip from '@mui/material/Chip'
import Delete from '@mui/icons-material/Delete'
import Check from '@mui/icons-material/Check'
import CloudOutlined from '@mui/icons-material/CloudOutlined'
import LinearProgress from '@mui/material/LinearProgress'
import CircularProgress from '@mui/material/CircularProgress'


import { useAssets } from './AssetsContext'

import tour360Placeholder from '../../../../../../assets/images/placeholders/360Placeholder.png'
import videoPlaceholder from '../../../../../../assets/images/placeholders/videoPlaceholder.png'

import './Scroll.css';

export default function AssetRow({asset_type}) {
  const { files, removeAssetFromList } = useAssets()
  return (
    <Box
      className="custom-scroll"
      sx={{ overflowY: 'scroll', p: 1 }}
      maxHeight="40vh"
      minHeight="30vh"
    >
      {files.map((asset) => (
        <Box
          key={asset.name}
          sx={{
            display: 'grid',
            gridTemplateColumns: 'max-content 1fr max-content',
            border: '1px solid #C5C5C5',
            borderRadius: '8px',
            overflow: 'hidden',
            gap: 2,
            mb: 1,
          }}
        >
          <img
            src={
             {
              1: asset.previewBlob,
              2: asset.previewBlob,
              3: videoPlaceholder,
              4: tour360Placeholder,
             }[asset_type]
            }
            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
          />
          <Stack py={2}>
            <p className="line-clamp-1" style={{ fontWeight: 500, margin: 0 }}>{asset.name}</p>
            <i fontWeight="bold">{asset.sizeFormated}</i>
            <Stack direction="row" gap={2} alignItems="center">
              {asset.s3Status === 'loading' && (
                <LinearProgress sx={{width:'100%'}} />
              )}
              {asset.s3Status === 'uploaded' && (
                <Box sx={{ maxWidth:'28px', aspectRatio:"1", bgcolor:'rgba(0, 170, 18, 0.14)', borderRadius:'50%', display:'grid', placeContent:'center'}}>
                  <Check color='success' />
                </Box>
              )}

              {asset.mobiliStatus  === 'loading' &&  (
                <CircularProgress sx={{width:'22px !important', height:'22px !important', aspectRatio:'1'}}/>
              )}

              {asset.mobiliStatus  === 'uploaded' &&  (
                  <CloudOutlined color='success' />
              )}

              
            </Stack>
          </Stack>
          
          <Box
            display="grid"
            sx={{
              placeContent: 'center',
              '.MuiIconButton-root': {
                color: '#FFA5A5',
              },
            }}
          >
            <IconButton 
              disabled={asset.s3Status != 'error' && asset.s3Status != null }
              onClick={() => removeAssetFromList(asset.name)}
              >
              <Delete />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  )
}
