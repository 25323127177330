import React from 'react'
import PropTypes from 'prop-types'
import styles from './slider.module.css'
export const Slider = ({
  show,
  slider,
  closeSlider,
  goToPrevious,
  goToNext,
  currentIndexInitial,
}) => {
  return (
    <>
      <div
        style={{ zIndex: '99999' }}
        className={`${styles.popUp} ${show ? styles.show : ''}`}
      >
        <div className={`${styles.popUpWrapper} ${show ? styles.show : ''}`}>
          <div className={`${styles.modal}`}>
            <button
              onClick={closeSlider}
              title="Close"
              type="button"
              className={styles.btnClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1={18} y1={6} x2={6} y2={18} />
                <line x1={6} y1={6} x2={18} y2={18} />
              </svg>
            </button>

            <button
              title="Back"
              onClick={goToPrevious}
              className={styles.leftArrow}
            >
              {' '}
              ❰{' '}
            </button>
            <button
              title="Next"
              onClick={goToNext}
              className={styles.rightArrow}
            >
              {' '}
              ❱{' '}
            </button>

            <div className={styles.modalBody}>
              {/* <img
                loading='lazy'
                src={slider?.records?.[currentIndexInitial]?.path}
                alt={slider?.records?.[currentIndexInitial]?.name}
              /> */}

              {/* <video controls>
                <source
                  src='https://www.w3schools.com/html/mov_bbb.mp4'
                  type='video/mp4'
                />
                Your browser does not support HTML video.
              </video> */}

              <div>
                <video
                  // className={styles.videosResponsiveItem}
                  // width={640}
                  // height={360}
                  controls
                  poster=""
                >
                  <source
                    src="//2017-media.weareenvoy.net/hero-blossom.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
              {/* https://codepen.io/corteric/pen/EEbePj */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Slider.propTypes = {
  show: PropTypes.bool,
  slider: PropTypes.any,
  closeSlider: PropTypes.func,
  goToPrevious: PropTypes.func,
  goToNext: PropTypes.func,
  currentIndexInitial: PropTypes.number,
}
