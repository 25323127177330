import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../../redux/store';
import Layout from '../../../components/mui/Layout';
import Inputs from '../../../components/mui/Inputs';
import Feedback from '../../../components/mui/Feedback';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import FormComponent from './components/FormComponent';
import { getPasswordVerification, setPasswordResendVerification } from '../services';
import { setIsLoading } from '../../../redux/loading/actions/loadingAction';
import { setIsLoadingForm, setIsActiveButton, setColorStatus, setTextStatus, setIsActiveAlert, setIsActiveForm, setResetPasswordReset } from '../redux/actions/passwordResetAction';
import { swalSuccess } from '../../../hooks/useSweetAlert';
import swalErrors from '../../../hooks/useErrors';
import '../Auth.scss';

const PasswordResetPage = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const uID = params.uID;
	const isLoading = useSelector( ({ loading }) => loading.isLoading);
	const isLoadingForm = useSelector( ({ passwordReset })=> passwordReset.isLoadingForm);
	const isActiveButton = useSelector( ({ passwordReset }) => passwordReset.isActiveButton);
	const isActiveForm = useSelector( ({ passwordReset }) => passwordReset.isActiveForm);
	const isActiveAlert = useSelector( ({ passwordReset }) => passwordReset.isActiveAlert);
	const colorStatus = useSelector( ({ passwordReset }) => passwordReset.colorStatus);
	const textStatus = useSelector( ({ passwordReset }) => passwordReset.textStatus);
	
	/**
	 *
	 * @description Execute verification of the password reset
	 * @return dispatch
	 *
	 */
	const handleVerification = async () => {
		await dispatch( setIsLoading(true) );
		
		try {
			const { data } = await getPasswordVerification(uID);
			
			if (data.status === 200) {
				await dispatch( setColorStatus('') );
				await dispatch( setTextStatus('') );
				await dispatch( setIsActiveButton(false) );
				await dispatch( setIsActiveAlert(false) );
				await dispatch( setIsActiveForm(true) );
			}
		} catch ({ response }) {
			await dispatch( setIsActiveButton(response.data.errors[0] === 'El usuario no se encuentra registrado' ? false : true) );
			await dispatch( setColorStatus('danger') );
			await dispatch( setTextStatus(response.data.errors[0]) );
			await dispatch( setIsActiveAlert(true) );
			await dispatch( setIsActiveForm(false) );
		}
		
		await dispatch( setIsLoading(false) );
	};
	
	useEffect(() => {
		handleVerification();
	}, [uID]);
	
	/**
	 *
	 * @description Execute resend verification of the password reset
	 * @return dispatch
	 *
	 */
	const handleResendVerification = async () => {
		await dispatch( setIsLoadingForm(true) );
		
		try {
			const { data } = await setPasswordResendVerification(uID);
			
			if (data.status === 200) {
				await swalSuccess('Solicitud exitosa', data.data);
				await dispatch( setResetPasswordReset() );
				await history.push('/');
			}
		} catch (error) {
			await swalErrors(error);
		}
		
		await dispatch( setIsLoadingForm(false) );
	};
	
	return (
		<Layout.Page title="Cambio de contraseña">
			<Layout.Card className="card form-container">
				<HeaderComponent />
				<Layout.CardContent className="m-0 pt-4 pr-4 pl-4 pb-0">
					{!isLoading && isActiveAlert && (
						<div className={`animated animate__fadeIn mb-2 alert alert-${colorStatus}`}>
							<div className="font-family-roboto-medium fs-6 text-center">
								{textStatus}
							</div>
						</div>
					)}
					{isActiveButton && (
						<Inputs.LoadingButton
							variant="contained"
							isLoading={isLoadingForm}
							isLoadingPosition="start"
							startIcon={<Layout.Icons.MailIcon height={18} width={18} />}
							label="Reenviar solicitud"
							type="button"
							className="animated animate__fadeIn font-family-roboto-regular fs-6 bg-primary text-capitalize w-100"
							onClick={handleResendVerification}
						/>
					)}
					{isActiveForm && (
						<FormComponent uID={uID} />
					)}
				</Layout.CardContent>
				<FooterComponent
					to="/"
					label="Volver al inicio de sesión"
				/>
			</Layout.Card>
			<Feedback.ModalLoading
				isOpen={isLoading}
				title="Cargando..."
				content="Espere un momento por favor..."
			/>
		</Layout.Page>
	);
};

export default PasswordResetPage;
