import React from 'react'
import Favorite from "@material-ui/icons/Favorite"

export default function FavoriteIcon(props) {

    const { ...other } = props

    return (
        <Favorite {...other} />
    )
}
