import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Layout from '../../../../../components/mui/Layout'
import { Permission } from '../../../../../services/permissions/Permission'

const DropdownActionComponent = ({ handleIsOpenModal }) => {
	const [anchorEl, setAnchorEl] = useState(null)
	
	const handleIsOpenMenu = event => setAnchorEl(event.currentTarget)
	
	const handleCloseMenu = () => setAnchorEl(null)
	
	const handleAction = async () => {
		await handleIsOpenModal()
		handleCloseMenu()
	}
	
	return (
		<>
			<Stack
				direction="row"
				spacing={2}
				onClick={handleIsOpenMenu}
			>
				<Layout.IconButton
					color="inherit"
					size="medium"
					aria-label="actions"
					aria-controls="menu-actions"
					aria-haspopup="true"
				>
					<Layout.Icons.MoreIcon
						width={20}
						height={20}
						className="text-dark"
					/>
				</Layout.IconButton>
			</Stack>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				open={Boolean(anchorEl)}
				onClose={handleCloseMenu}
				sx={{
					display: { xs: 'block' },
				}}
			>
				<Permission permission="properties.areas.delete">
					<MenuItem onClick={handleAction}>
						<ListItemIcon className="font-family-roboto-regular fs-6 text-dark">
							<Layout.Icons.DeleteIcon
								height={18}
								width={18}
							/>
						</ListItemIcon>
						<ListItemText className="font-family-roboto-regular fs-6 text-dark">
							Eliminar
						</ListItemText>
					</MenuItem>
				</Permission>
			</Menu>
		</>
	)
}

DropdownActionComponent.propTypes = {
	handleIsOpenModal: PropTypes.func.isRequired
}

export default DropdownActionComponent
