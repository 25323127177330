import { initialValuesUser as initialValues } from '../../../../../helpers/variablesInitialValues'
import * as types from '../../../../actionsTypes/dashboard/settings/userTypes'
import { setIsLoading } from '../../../loadingAction'
import { getArrayList, getArrayListToDropdown, getArrayListToDropdownAgent, getArrayListToDropdownAdviser, setStore, getResendEmailById, getShowById, setUpdateById, setAssignPermissionsById, setChangeStatusById, setDeleteById } from './services'
import { swalSuccess } from '../../../../../services/sweetalert'
import errorServices from '../../../../../services/errors'

export const getArray = param => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getArrayList(param).then(({ data }) => {
			dispatch( setPages(data.data.pages) )
			dispatch( setArrayList(data.data.records) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const getArrayToDropdownUser = () => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getArrayListToDropdown().then(({ data }) => {
			dispatch( setArrayListToDropdown(data.data.records) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const getArrayToDropdownAgent = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getArrayListToDropdownAgent(param).then(({ data }) => {
			dispatch( setArrayListToDropdownAgent(data.data.records) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const getArrayToDropdownAdviser = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getArrayListToDropdownAdviser(param).then(({ data }) => {
			dispatch( setArrayListToDropdownAdviser(data.data.records) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const store = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setStore(param).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Captación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const resendEmailById = id => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getResendEmailById(id).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Reenvío de verificación exitoso', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const showById = id => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getShowById(id).then(({ data }) => {
			const object = {
				id: data.data.id,
				firstName: data.data.first_name,
				lastName: data.data.last_name,
				email: data.data.email,
				mobile: data.data.mobile,
				phone: data.data.phone,
				address: data.data.address,
				realState: data.data.realstate_id,
				role: data.data.role_id,
				permissions: data.data.permissions
			}
			dispatch( setFormData(object) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const updateById = (id, param) => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setUpdateById(id, param).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Captación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const assignPermissionsById = (id, param) => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setAssignPermissionsById(id, param).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Asignación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const changeStatusById = id => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await setChangeStatusById(id).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Cambio de Estatus exitoso', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const deleteById = id => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await setDeleteById(id).then(({ data }) => {
			dispatch( setRefresh() )
			swalSuccess('Eliminación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

const setRefresh = () => (
	async (dispatch) => {
		await dispatch( setFormType('') )
		await dispatch( setFormData(initialValues) )
		await dispatch( setIsOpenModal(false) )
	}
)

export const setUserReset = () => (
	async (dispatch) => {
		await dispatch( setPerPage(10) )
		await dispatch( setPage(1) )
		await dispatch( setPages(1) )
		await dispatch( setSearch('') )
		await dispatch( setRealState('') )
		await dispatch( setArrayList([]) )
		await dispatch( setArrayListToDropdown([]) )
		await dispatch( setArrayListToDropdownAgent([]) )
		await dispatch( setArrayListToDropdownAdviser([]) )
		await dispatch( setRefreshTable(false) )
		await dispatch( setIsOpenModal(false) )
		await dispatch( setIsOpenModalPermission(false) )
		await dispatch( setIsLoadingForm(false) )
		await dispatch( setFormType('') )
		await dispatch( setFormData(initialValues) )
	}
)

export const setPerPage = value => ({
	type: types.PER_PAGE_USER,
	payload: value
})

export const setPage = value => ({
	type: types.PAGE_USER,
	payload: value
})

export const setPages = value => ({
	type: types.PAGES_USER,
	payload: value
})

export const setSearch = value => ({
	type: types.SEARCH_USER,
	payload: value
})

export const setRealState = value => ({
	type: types.REALSTATE_USER,
	payload: value
})

export const setArrayList = value => ({
	type: types.ARRAY_LIST_USER,
	payload: value
})

export const setArrayListToDropdown = value => ({
	type: types.ARRAY_LIST_TO_DROPDOWN_USER,
	payload: value
})

export const setArrayListToDropdownAgent = value => ({
	type: types.ARRAY_LIST_TO_DROPDOWN_AGENT_USER,
	payload: value
})

export const setArrayListToDropdownAdviser = value => ({
	type: types.ARRAY_LIST_TO_DROPDOWN_ADVISER_USER,
	payload: value
})

export const setRefreshTable = value => ({
	type: types.REFRESH_TABLE_USER,
	payload: value
})

export const setIsOpenModal = value => ({
	type: types.IS_OPEN_MODAL_USER,
	payload: value
})

export const setIsOpenModalPermission = value => ({
	type: types.IS_OPEN_MODAL_PERMISSION_USER,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_USER,
	payload: value
})

export const setFormType = value => ({
	type: types.FORM_TYPE_USER,
	payload: value
})

export const setFormData = value => ({
	type: types.FORM_DATA_USER,
	payload: value
})
