import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Inputs from '../../../../../components/mui/Inputs';
import { getArrayListToDropdown } from '../services';
import { setArrayListToDropdown } from '../redux/actions/roleAction';
import usePermission from '../../../../../hooks/usePermission';
import swalErrors from '../../../../../hooks/useErrors';

const DropdownSelect = ({ name, value = '', handleChange, ...more }) => {
	const dispatch = useDispatch();
	const arrayListToDropdown = useSelector( ({ role }) => role.arrayListToDropdown);
	const permission = usePermission('roles.dropdown');
	
	/**
	 *
	 * @description Execute endpoint array list roles in dropdown
	 * @return dispatch
	 *
	 */
	const handleArrayList = async () => {
		if (permission) {
			try {
				const { data } = await getArrayListToDropdown();
				
				if (data.status === 200) {
					await dispatch( setArrayListToDropdown(data.data.records) );
				}
			} catch (error) {
				await swalErrors(error);
			}
		}
	};
	
	useEffect(() => {
		handleArrayList();
	}, []);
	
	return (
		<>
			{permission && (
				<Inputs.Select
					label="Roles"
					name={name}
					value={value}
					itemsArray={arrayListToDropdown}
					onChange={handleChange}
					{...more}
				/>
			)}
		</>
	);
};

DropdownSelect.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.any,
	handleChange: PropTypes.func.isRequired
};

export default DropdownSelect;
