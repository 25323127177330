import { styled } from '@mui/material'

export const H1 = styled('h1')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontStyle: 'normal',
  fontWeight: '800',
  fontSize: '3.125em',
  lineHeight: '59px',
  margin: '0',
  marginBottom: '5px',
}))

export const H3 = styled('h3')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: '1.3125rem',
  lineHeight: '25px',
}))

export const H4 = styled('h4')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '1.25rem',
  lineHeight: '23px',
}))

export const H6 = styled('h6')(({ theme }) => ({
  color: theme.palette.text.grey,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '0.75rem',
  lineHeight: '150%',
  margin: 0,
}))

export const Span = styled('span')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '0.75rem',
  lineHeight: '150%',
}))

export const Card = styled('div')(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  padding: '15px 20px',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  borderRadius: '10px',
}))

export const CardAddress = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.text.disabled,
  padding: '10px 35px',
  marginTop: '20px',
}))

export const CardFeactures = styled('div')(({ theme }) => ({
  backgroundColor: '#ffffff',
  padding: '20px 35px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
}))

export const AddressText = styled('h4')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '1.125rem',
  lineHeight: '150%',
}))

export const AddressTextSubtitle = styled('p')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '150%',
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
}))
