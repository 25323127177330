import React from 'react';
import PropTypes from 'prop-types';
import MuiIconButton from '@mui/material/IconButton';

const IconButton = props => {
	const {
		color = 'inherit',
		size= 'small',
		onClick = null,
		children,
		hasError = false,
		errorMessage = '',
		...more
	} = props;
	
	return (
		<>
			<MuiIconButton
				color={color}
				size={size}
				onClick={onClick}
				{...more}
			>
				{children}
			</MuiIconButton>
			{hasError && errorMessage !== "" && (
				<div className="mt-1 bg-grey-light border-l-4 border-danger p-2 font-family-roboto-regular fs-6 text-danger animate__animated animate__fadeIn">
					{errorMessage}
				</div>
			)}
		</>
	);
};

IconButton.propTypes = {
	color: PropTypes.string,
	size: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.any.isRequired,
	hasError: PropTypes.bool,
	errorMessage: PropTypes.string
};

export default IconButton;
