import * as types from '../../../../actionsTypes/dashboard/settings/owners/ownerDomusTypes'
import { initialValuesOwnerDomus as initialValues } from '../../../../../helpers/variablesInitialValues'

const initialState = {
	search: '',
	isLoadingForm: false,
	formData: initialValues
}

const ownerDomusReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.SEARCH_OWNER_DOMUS:
			return {
				...state,
				search: payload
			}
		
		case types.IS_LOADING_FORM_OWNER_DOMUS:
			return {
				...state,
				isLoadingForm: payload
			}
		
		case types.FORM_DATA_OWNER_DOMUS:
			return {
				...state,
				formData: payload
			}
		
		default:
			return state
	}
}

export default ownerDomusReducer
