import * as types from '../types/forgotPasswordType';

/**
 *
 * @description Set reset forgot password state
 * @return dispatch
 *
 */
export const setResetForgotPassword = () => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(false) );
	}
);

/**
 *
 * @description Set loadingForm state
 * @param value
 * @return object
 *
 */
export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_FORGOT_PASSWORD,
	payload: value
});
