import React from 'react'
import PropTypes from 'prop-types'
import MuiChip from '@mui/material/Chip'

const Chip = ({ label, size, deleteIcon = null, onDelete = null, ...more }) => {
	return (
		<MuiChip
			label={label}
			size={size}
			deleteIcon={deleteIcon}
			onDelete={onDelete}
			{...more}
		/>
	)
}

Chip.propTypes = {
	label: PropTypes.string.isRequired,
	size: PropTypes.string.isRequired,
	deleteIcon: PropTypes.any,
	onDelete: PropTypes.func
}

export default Chip
