import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setIsOpenSidebar } from '../../../redux/actions/dashboard/sidebarAction'

export const useModal = (initialValue = false) => {
  const dispatch = useDispatch()
  const [isOpenModal, setIsOpenModal] = useState(initialValue)

  const closeModalFunc = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'visible'
    setIsOpenModal(false)
  }

  const openModalFunc = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    setIsOpenModal(true)
  }

  const closeModalFunc2 = () => {
    document.getElementsByTagName('header')[0].style.zIndex = ''
    document.getElementsByTagName('body')[0].style.overflow = 'visible'
    setIsOpenModal(false)
  }

  const openModalFunc2 = () => {
    dispatch(setIsOpenSidebar(false))
    document.getElementsByTagName('header')[0].style.zIndex = '9'
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    setIsOpenModal(true)
  }
  return {
    isOpenModal,
    closeModalFunc,
    openModalFunc,
    openModalFunc2,
    closeModalFunc2,
  }
}
