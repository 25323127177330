export const PER_PAGE_BANK = 'PER_PAGE_BANK';
export const PAGE_BANK = 'PAGE_BANK';
export const SEARCH_BANK = 'SEARCH_BANK';
export const PAGES_BANK = 'PAGES_BANK';
export const ARRAY_LIST_BANK = 'ARRAY_LIST_BANK';
export const ARRAY_LIST_TO_DROPDOWN_BANK = 'ARRAY_LIST_TO_DROPDOWN_BANK';
export const REFRESH_TABLE_BANK = 'REFRESH_TABLE_BANK';
export const IS_OPEN_MODAL_BANK = 'IS_OPEN_MODAL_BANK';
export const IS_LOADING_SHOW_BANK = 'IS_LOADING_SHOW_BANK';
export const IS_LOADING_FORM_BANK = 'IS_LOADING_FORM_BANK';
export const FORM_TYPE_BANK = 'FORM_TYPE_BANK';
export const FORM_DATA_BANK = 'FORM_DATA_BANK';
