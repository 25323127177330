import React, {useEffect} from 'react'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Display from '../display/Display'
import Feedback from './Feedback'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        // top: theme.spacing(1)
    },
}))


export default function DialogLoading({ getLoading, setLoading }) {
    const classes = useStyles()

    const handleClose = () => {
        setLoading(false)
    }

    useEffect(() => {

    }, [getLoading])

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="xs"
            aria-labelledby="dialogForm"
            open={getLoading}
        >
            <DialogTitle id="dialogForm">
                Cargando...

                <Display.Tooltip
                    label="Cerrar"
                    placement="top"
                >
                    <IconButton
                        onClick={handleClose}
                        className="height-32 width-32"
                        classes={{ root: classes.closeButton }}
                        aria-label="like"
                    >
                        <Display.Icons.CloseIcon
                            className="text-dark"
                            style={{ fontSize: 20 }}
                        />
                    </IconButton>
                </Display.Tooltip>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="p-4">
                        <div className="float-left position-relative d-inline">
                            <Feedback.Loading
                                type="spin"
                            />
                        </div>
                        <div className="float-left position-relative d-inline d-inline ml-3 mt-2 font-family-roboto-regular font-size-18 text-grey">
                            Espere un momento por favor...
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
