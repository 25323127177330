import React, { useCallback, useEffect, useRef, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import styles from '../mapa/mapa.module.css'

// const geojson = {
//   type: 'FeatureCollection',
//   features: [
//     {
//       type: 'Feature',
//       geometry: {
//         type: 'Point',
//         coordinates: [-74.8183, 10.98573],
//       },
//       properties: {
//         title: 'Developer 🍔',
//         description: 'Washington, D.C.',
//         image:
//           'https://arquitectopablorestrepo.com/wp-content/uploads/2019/11/dise%C3%B1o-casa-campestre-las-palmas-4-800x600.jpg',
//       },
//     },
//     {
//       type: 'Feature',
//       geometry: {
//         type: 'Point',
//         coordinates: [-74.78490791361224, 10.98733270999709],
//       },
//       properties: {
//         title: 'Marcos ✔',
//         description: 'San Francisco, California',
//         image:
//           'https://images.adsttc.com/media/images/5f90/e509/63c0/1779/0100/010e/newsletter/3.jpg',
//       },
//     },
//     {
//       type: 'Feature',
//       geometry: {
//         type: 'Point',
//         coordinates: [-74.837532, 11.008843],
//       },
//       properties: {
//         title: 'C. R. Olivenza',
//         description: 'Apto 1020 Torre 6 Conjunto Residencial Olivenza',
//         image:
//           'https://images.adsttc.com/media/images/622b/9c0b/6a2b/af01/6506/d38b/newsletter/004-ok.jpg',
//       },
//     },
//     {
//       type: 'Feature',
//       geometry: {
//         type: 'Point',
//         coordinates: [-74.80335673744729, 11.01984647339784],
//       },
//       properties: {
//         title: 'MOB00104078 - (Domus: 12235)',
//         description:
//           'Carrera 78 # 79B - 25 Conjunto CÓMODA VIVIENDA DE DOS PISOS DENTRO DE CONJUNTO, CON PISOS EN CERÁMICA Y PORCELANATO. EN EXCELENTE UBICACIÓN, CERCA AL HOSPITAL NIÑO JESÚS, SUPERMERCADOS Y CENTROS COMERCIALES.',
//         image:
//           'https://pictures.domus.la/inmobiliaria_432/12235_0_98448380.jpg',
//       },
//     },
//   ],
// }

mapboxgl.accessToken = `${process.env.REACT_APP_MAP_BOX_TOKEN}`

export function useMaps(pointStart, geojson) {
  const mapaMain = useRef()
  const mapa = useRef()
  const setRefFunc = useCallback((node) => {
    mapaMain.current = node
  }, [])

  const [mapBtn, setMapBtn] = useState(null)

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapaMain.current, // container ID
      style: 'mapbox://styles/mapbox/streets-v12',
      //   style: 'mapbox://styles/mapbox/light-v10', // style URL
      center: [pointStart.lng, pointStart.lat], // starting position [lng, lat]
      zoom: pointStart.zoom, // starting zoom
    })
    setMapBtn(map)
    mapa.current = map

    for (const feature of geojson.features) {
      // create a HTML element for each feature
      const el = document.createElement('div')
      el.className = styles.marker

      const marker = new mapboxgl.Marker(el)

      marker
        .setLngLat(feature.geometry.coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<h5>${feature.properties.title}</h5>
                <img style = "width: 100%; height: 90px; object-fit: cover;" src='${feature.properties.image}'/>
                <p>${feature.properties.description}</p>`,
            ),
        )

        .addTo(mapa.current)
    }

    // }
  }, [pointStart])

  const goToLocationFunc = () => {
    const start = {
      center: [pointStart.lng, pointStart.lat],
      zoom: pointStart.zoom,
      pitch: 0,
      bearing: 0,
    }

    mapBtn.flyTo({
      ...start, // Fly to the selected target
      duration: 4000, // Animate over 4 seconds
      essential: true, // This animation is considered essential with
      //respect to prefers-reduced-motion
    })
  }

  return { goToLocationFunc, setRefFunc }
}
