import endPoints from './endPoints'
import axios from '../../../../../services/axios'

const {
	arrayListFilesByAlbumsTypes,
	show
} = endPoints

/**
 *
 * @description Execute endpoint array list of property albums types
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const getArrayListFilesByAlbumsTypes = async (data) => (
	await axios.post(arrayListFilesByAlbumsTypes, data)
)

/**
 *
 * @description Execute endpoint show by id files of property albums types
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const getShowById = async (id) => (
	await axios.get(`${show}${id}`)
)

// export const getPropertyDetails = async (id) => {
// 	try {
// 		const data = await axios.get(`${show}${id}`);
// 		return data;
// 	} catch (error) {
// 		console.log('Error', error);
// 		return error.response;
// 	}
// };
export const getPhtosProperty = async (id) => {
	try {
		const data = await axios.get(`${show}${id}`);
		return data;
	} catch (error) {
		console.log('Error', error);
		return error.response;
	}
};
