import React from 'react'
import images from '../../../v2-mobili/img/index'
import styles from './ListIconsDetails.module.css'
//src\pages\owners\views\inventoryPage\img\index_.jsx

const ListIconsDetails = ({
  areaTotal,
  numberRoom,
  numberBathroom,
  numbreGarage,
  numbreStrata,
}) => {
  return (
    <div className={styles.propertyDetails}>
      <span>
        <img src={images.iconM2} alt="iconM2" />
        {areaTotal === '' ? '' : `${areaTotal}m²`}
      </span>
      <span>
        <img src={images.iconBed} alt="iconBed" />
        {numberRoom === '' ? '' : `${numberRoom} Habitaciones`}
      </span>
      <span>
        <img src={images.iconBath} alt="iconBath" />
        {numberBathroom === '' ? '' : `${numberBathroom} Baños`}
      </span>
      <span>
        <img src={images.iconGarage} alt="iconGarage" />
        {numbreGarage === '' ? '' : `${numbreGarage} puesto`}
      </span>
      <span>
        <img src={images.iconGroup} alt="iconGroup" />
        {numbreStrata === '' ? '' : numbreStrata}
      </span>
    </div>
  )
}

export default ListIconsDetails
