import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Layout from '../../../../components/mui/Layout'
import SearchComponent from '../components/SearchComponent'
import TableComponent from './components/TableComponent'
import ModalComponent from './components/ModalComponent'
import errorServices from '../../../../services/errors'
import axios from '../../../../services/axios'

const PropertyAreaOldPage = () => {
	const params = useParams()
	const uID = params.uID
	const tokenType = useSelector(state => state.auth.tokenType)
	const accessToken = useSelector(state => state.auth.accessToken)
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [search, setSearch] = useState('')
	const [refresh, setRefresh] = useState(false);
	const [module, setModule] = useState({
		step: 5,
		title: 'Áreas'
	})
	
	const handleEditProperty = async () => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/${uID}`)
			
			if (data.status === 200) {
				if (data.data.data.has_building === 'Si'){
					setModule({...module, step: 4 })
				}
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
		handleEditProperty()
	}, [])
	
	const handleIsOpenModal = () => setIsOpenModal(!isOpenModal)
	
	const handleRefresh = () => setRefresh(!refresh)
	
	const handleOnChange = ({ target }) => setSearch(target.value)
	
	return (
		<Layout.Page title="Propiedades - Áreas">
			<SearchComponent
				module={module}
				hasNewButton={true}
				permissionNewButton="properties.areas.store"
				hasSearch={true}
				permissionSearch="properties.areas.list"
				search={search}
				back={`/dashboard/propiedades/tipos/${uID}`}
				next={`/dashboard/propiedades/acciones/${uID}`}
				handleIsOpenModal={handleIsOpenModal}
				handleOnChange={handleOnChange}
			/>
			
			<TableComponent
				uID={uID}
				search={search}
				refresh={refresh}
			/>
			
			<ModalComponent
				isOpen={isOpenModal}
				uID={uID}
				handleRefresh={handleRefresh}
				handleIsOpenModal={handleIsOpenModal}
			/>
		</Layout.Page>
	)
}

export default PropertyAreaOldPage
