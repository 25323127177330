import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Layout from '../../../../components/mui/Layout'
import Inputs from '../../../../components/mui/Inputs'
import { Permission } from '../../../../services/permissions/Permission'

const SearcComponent = (props) => {
	const {
		module,
		hasNewButton = false,
		permissionNewButton = '',
		hasSearch = false,
		permissionSearch = '',
		search = '',
		back,
		next = '',
		handleIsOpenModal = null,
		handleOnChange = null
	} = props
	
	useEffect(() => {
	}, [
		module,
		hasNewButton,
		permissionNewButton,
		hasSearch,
		permissionSearch,
		search,
		back,
		next
	])
	
	return (
		<Layout.Card className="border-radius-0 m-0 p-0">
			<Layout.CardContent className="m-0 p-3">
				<div className="row m-0 p-0">
					<div className={(hasNewButton || hasSearch) ? 'col-sm-12 mb-2' : 'col-sm-12 col-md-6 mb-2'}>
						<div className="font-family-roboto-regular fs-5 text-dark w-100">
							Captación de las Propiedades
						</div>
						<div className="font-family-roboto-regular fs-6 text-dark-light w-100 mt-1">
							Paso {module.step} - {module.title}
						</div>
					</div>
					{!(hasNewButton && hasSearch) && (
						<div className="col-sm-12 col-md-6">
							{next !== '' && (
								<Inputs.Button
									variant="outlined"
									endIcon={<Layout.Icons.NavigateNextIcon height={18} width={18}/>}
									label="Siguiente"
									href={next}
									className="bg-primary font-family-roboto-medium fs-6 text-inherit d-flex float-end ml-2"
								/>
							)}
							<Inputs.Button
								variant="outlined"
								startIcon={<Layout.Icons.NavigateBeforeIcon height={18} width={18}/>}
								label="Atrás"
								href={back}
								className="bg-secondary-outlined font-family-roboto-medium fs-6 text-inherit d-flex float-end"
							/>
						</div>
					)}
				</div>
				{(hasNewButton || hasSearch) && (
					<div className="row m-0 p-0">
						<div className="col-sm-12 col-md-6">
							{hasNewButton && (
								<Permission permission={permissionNewButton}>
									<Inputs.Button
										variant="contained"
										label="Nuevo"
										startIcon={<Layout.Icons.AddCircleIcon width={20} height={20} />}
										className="bg-primary font-family-roboto-medium fs-6 text-inherit"
										onClick={handleIsOpenModal}
									/>
								</Permission>
							)}
							{hasSearch && (
								<Permission permission={permissionSearch}>
									<Inputs.TextBox
										type="text"
										variant="outlined"
										size="small"
										name="search"
										label="Buscar"
										placeholder="Ingrese la búsqueda..."
										hasLabelFixed={true}
										value={search}
										onChange={handleOnChange}
										className="font-family-roboto-regular fs-6 text-dark ml-2 w-300px"
									/>
								</Permission>
							)}
						</div>
						<div className="col-sm-12 col-md-6">
							{next !== '' && (
								<Inputs.Button
									variant="outlined"
									endIcon={<Layout.Icons.NavigateNextIcon height={18} width={18}/>}
									label="Siguiente"
									href={next}
									className="bg-primary font-family-roboto-medium fs-6 text-inherit d-flex float-end ml-2"
								/>
							)}
							<Inputs.Button
								variant="outlined"
								startIcon={<Layout.Icons.NavigateBeforeIcon height={18} width={18}/>}
								label="Atrás"
								href={back}
								className="bg-secondary-outlined font-family-roboto-medium fs-6 text-inherit d-flex float-end"
							/>
						</div>
					</div>
				)}
			</Layout.CardContent>
		</Layout.Card>
	)
}

SearcComponent.propTypes = {
	module: PropTypes.object.isRequired,
	hasNewButton: PropTypes.bool,
	permissionNewButton: PropTypes.string,
	hasSearch: PropTypes.bool,
	permissionSearch: PropTypes.string,
	search: PropTypes.string,
	back: PropTypes.string.isRequired,
	next: PropTypes.string,
	handleIsOpenModal: PropTypes.func,
	handleOnChange: PropTypes.func
}

export default SearcComponent
