import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Chip, styled, Button, Menu, MenuItem, TextField } from '@mui/material';
import ListIconsDetails from '../../../../../../components/properties/icons/ListIconsDetails/ListIconsDetails';
import images from '../../../../../../components/v2-mobili/img';
import styles from './propertyDetails.module.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ContentCopy, MoreVert } from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import axios from '../../../../../../services/axios';
import { swalSuccess } from '../../../../../../services/sweetalert';
import errorServices from '../../../../../../services/errors';
import { useSelector } from 'react-redux';

const ChipCustom = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'myPropBackground',
})(({ theme, myPropBackground }) => {
  let color = '#FFFFFF';
  let bg = theme.palette.buttonGreyContained.mediumGrey;

  if (myPropBackground === 'primary') {
    bg = '#004087';
  } else if (myPropBackground === 'secondary') {
    bg = theme.palette.buttonGreyContained.mediumGrey;
    color = theme.palette.dark;
  } else if (myPropBackground === 'success') {
    bg = '#1AE5B2';
  } else if (myPropBackground === 'error') {
    bg = '#BE123C';
  } else if (myPropBackground === 'orange') {
    bg = theme.palette.orange.default;
    color = theme.palette.dark;
  }

  return {
    backgroundColor: bg,
    color: color,
    fontWeight: 700,
    fontSize: '12px',
    height: '20px',
    padding: '0 12px',
    marginBottom: '5px',
  };
});

const Subtitle = styled('span')(({ theme }) => ({
  color: theme.palette.dark.default,
  fontWeight: 400,
  fontStyle: 'normal',
  fontSize: '1rem',
  lineHeight: '150%',
  margin: '5px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '8px',
}));

export function PropertyDetails({ urlDetalleInmueble = false, formData }) {
  const tokenType = useSelector((state) => state.auth.tokenType);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState(formData.address ?? '');
  const [error, setError] = useState('');

  const open = Boolean(anchorEl);
  const encryptedId = formData.id;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDuplicate = async (propertyId, address) => {
    if (address.length <= 4) {
      setError('La dirección debe tener más de 4 caracteres');
      return;
    }

    setLoading(true);
    try {
      axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`;
      const param = {
        property_id: propertyId,
        address: address,
      };
      const { data } = await axios.post('dashboard/properties/options/duplicate', param);
      if (data.status === 200) {
        swalSuccess('Duplicar Propiedad', data.data);
      }
    } catch (error) {
      await errorServices(error);
    } finally {
      setLoading(false);
      setModalOpen(false);
    }
  };

  const handleTextFieldChange = (e) => {
    const value = e.target.value;
    setTextFieldValue(value);
    if (value.length <= 4) {
      setError('La dirección debe tener más de 4 caracteres');
    } else {
      setError('');
    }
  };

  return (
    <div className={styles.cardCustom}>
      <div className={styles.wrapperBtn}>
        {urlDetalleInmueble && (
          <button
            className={styles.btnLeftArrow}
            onClick={() => window.history.back()}
          >
            Regresar
          </button>
        )}
        {(urlDetalleInmueble !== true && formData.status !== null) && (
          <>
            {formData.status === 'Solicitud' && (
              <ChipCustom label="EN SOLICITUD" myPropBackground="primary" />
            )}
            {formData.status === 'Proceso' && (
              <ChipCustom label="EN PROCESO" myPropBackground="orange" />
            )}
            {formData.status === 'Aprobado' && (
              <ChipCustom label="APROBADA" myPropBackground="success" />
            )}
            {formData.status === 'Rechazado' && (
              <ChipCustom label="RECHAZADA" myPropBackground="error" />
            )}
          </>
        )}
        <Button
          id="property-option-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="contained"
          endIcon={<MoreVert />}
        >
          Opciones
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        >
          {urlDetalleInmueble && (
            <MenuItem onClick={() => { handleClose(); setModalOpen(true); }}>
              <ListItemIcon>
                <ContentCopy />
              </ListItemIcon>
              Duplicar Propiedad
            </MenuItem>
          )}
        </Menu>
        {isModalOpen && (
          <Dialog open={isModalOpen} onClose={() => setModalOpen(false)}>
            <DialogTitle>Confirmación</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <TextField
                  required
                  fullWidth
                  id="outlined-required"
                  label="Dirección"
                  style={{ marginTop: '5px', marginBottom: '5px' }}
                  defaultValue={formData.address === null ? '' : formData.address}
                  value={textFieldValue}
                  onChange={handleTextFieldChange}
                  error={!!error}
                  helperText={error}
                />
                ¿Estás seguro de que quieres duplicar esta propiedad?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleDuplicate(encryptedId, textFieldValue)} color="primary" disabled={isLoading}>
                Confirmar
              </Button>
              <Button onClick={() => setModalOpen(false)} color="secondary" disabled={isLoading}>
                Cancelar
              </Button>
            </DialogActions>
            {isLoading && <p>Cargando...</p>}
          </Dialog>
        )}
      </div>
      <Typography color="dark.default" variant="h3">
        {formData.address1 === null ? '' : formData.address1}
      </Typography>
      {formData.address2 && <Subtitle>{formData.address2 === null ? '' : formData.address2}</Subtitle>}
      <Subtitle>
        <img src={images.iconLocation} alt="iconLocation" />
        Barrio {formData.neighborhood === null ? '' : formData.neighborhood}, {formData.city === null ? '' : formData.city}
      </Subtitle>
      <ListIconsDetails
        areaTotal={formData.area_total === null ? '' : formData.area_total}
        numberRoom={formData.number_room === null ? '' : formData.number_room}
        numberBathroom={formData.number_bathroom === null ? '' : formData.number_bathroom}
        numbreGarage={formData.number_garage === null ? '' : formData.number_garage}
        numbreStrata={formData.strata === null ? '' : formData.strata}
      />
    </div>
  );
}

PropertyDetails.propTypes = {
  urlDetalleInmueble: PropTypes.bool,
  formData: PropTypes.object.isRequired,
};
