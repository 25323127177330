import React from 'react'
import HomeWork from '@material-ui/icons/HomeWork'

export default function HomeWorkIcon(props) {
	const { ...other } = props
	
	return (
		<HomeWork {...other} />
	)
}
