import endPoints from './endPoints'
// import axios from '../../../../../services/axios'
import axios from 'axios'

const {
	arrayList
} = endPoints

/**
 *
 * @description Execute endpoint array list of properties algolia
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const getArrayList = async (data) => (
	await axios.post(arrayList, data)
)
