import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Feedback from '../../../../../components/mui/Feedback'
import FormComponent from './FormComponent'
import { setIsOpenModal } from '../../../../../redux/actions/dashboard/filtered/filteredAction'
import { Permission } from '../../../../../services/permissions/Permission'
import * as Styles from '../../../../../components/v2-mobili/modals/styles'

const ModalComponent = () => {
	const dispatch = useDispatch()
	const isOpenModal = useSelector( state => state.filtered.isOpenModal)
	
	/**
	 *
	 * @description Execute close modal state
	 * @return dispatch
	 *
	 */
	const handleCloseModal = () => dispatch( setIsOpenModal(false) )
	
	return (
		<Feedback.Modal
			maxWidth="sm"
			isOpen={isOpenModal}
			isClose={handleCloseModal}
			title="Nuevo contacto"
			PaperProps={{
				sx: {
					...Styles.PaperPropsStyles,
					maxWidth: "660px"
				}
			}}
		>
			<Permission permission="filtereds.store">
				<FormComponent />
			</Permission>
		</Feedback.Modal>
	)
}

export default ModalComponent
