import React from 'react'
import { useSelector } from 'react-redux'
import Layout from '../../../../../../components/mui/Layout'
import Feedback from '../../../../../../components/mui/Feedback'

const MobiliComponent = () => {
	const isLoading = useSelector( state => state.ownerMobili.isLoadingForm)
	const formData = useSelector( state => state.ownerMobili.formData)
	
	const {
		personType,
		company,
		firstName,
		lastName,
		email,
		mobile,
		phone,
		state,
		city,
	} = formData
	
	return (
		<Layout.Card className="border-radius-5">
			<Layout.CardContent className="m-0 p-4">
				<div className="row">
					<div className="col-sm-12">
						<div className="font-family-roboto-semibold fs-5 text-center text-dark text-wrap">
							<h5>Información en MOBILI</h5>
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Tipo de persona:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={35}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ personType }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Compañía:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={35}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ company }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Nombre y Apellido:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={35}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ firstName } { lastName }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Email:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={35}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ email }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Celular:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={35}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ mobile }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Teléfono:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={35}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ phone }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Departamento:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={35}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ state }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Ciudad:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={35}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ city }
								</div>
							)}
						</div>
					</div>
				</div>
			</Layout.CardContent>
		</Layout.Card>
	)
}

export default MobiliComponent
