import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Layout from '../../../../../components/mui/Layout'

const TableRowComponent = ({ id, client = '', email = '', amount = '' }) => {
	
	useEffect(() => {
	}, [
		id,
		client,
		email,
		amount
	])
	
	return (
		<tr className="m-0 p-0">
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="30%"
			>
				<div className="font-family-roboto-medium fs-6">
					{ client }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="30%"
			>
				<div className="font-family-roboto-medium fs-6">
					{ email }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="30%"
			>
				<div className="mx-auto d-flex justify-content-end">
					<div className="font-family-roboto-medium fs-6">
						{ amount }
					</div>
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="10%"
			>
				<div className="mx-auto d-flex justify-content-center">
					<Layout.IconButton
						color="inherit"
						size="small"
						target="_blank"
            href={`/filter/call-center/${id}`}
					>
						<Layout.Icons.SearchIcon
							width={20}
							height={20}
							className="text-dark"
						/>
					</Layout.IconButton>
				</div>
			</td>
		</tr>
	)
}

TableRowComponent.propTypes = {
	id: PropTypes.string.isRequired,
	client: PropTypes.string,
	email: PropTypes.string,
	amount: PropTypes.string
}

export default TableRowComponent
