import MyLocationIcon from '@mui/icons-material/MyLocation'
import { Button } from '@mui/material'
import { useMaps } from '../hooks'
import styles from './mapa.module.css'

const pointStart = {
  lng: -74.8123,
  lat: 10.9813,
  zoom: 10.9809,
}

export function Map({ geojson }) {
  //console.log('geojson', geojson)
  const { goToLocationFunc, setRefFunc } = useMaps(pointStart, geojson)
  return (
    <>
      <div className={styles.boxMapa}>
        <Button
          className={styles.btnLocation}
          onClick={goToLocationFunc}
          sx={{ textTransform: 'none' }}
          variant="contained"
          startIcon={<MyLocationIcon />}
        >
          Ubicación
        </Button>
        <div ref={setRefFunc} className={styles.mapContainer} />
      </div>
    </>
  )

}
