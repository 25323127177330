import React from "react";
import { useFormikContext, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import FieldErrors from "../../../../components/v2-mobili/errors/FieldErrors";

const _neighborhoodOptions = [
  ...Array(10)
    .fill()
    .map((_, index) => {
      return { label: `Barrio ${index + 1}`, id: index + 1 };
    }),
];
const NeighborhoodField = ({ readOnly = false }) => {
  const { values, setFieldValue } = useFormikContext();

  const [neighborhoodOptions, setNeighborhoodOptions] = React.useState([]);

  const loading = neighborhoodOptions.length < 1;

  React.useEffect(() => {
    // * aqui simulo la peticion al servidor, de modo que le puse 3200 ms para que se viera
    // * el circular progress
    setTimeout(() => {
      setNeighborhoodOptions(_neighborhoodOptions);
    }, 3200);
  }, []);
  return (
    <Grid container flexDirection="column" item>
      <Autocomplete
        multiple
        limitTags={1}
        readOnly={readOnly}
        id="neighborhood"
        options={neighborhoodOptions}
        loading={loading}
        value={values.neighborhood}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, values) => {
          setFieldValue("neighborhood", values);
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.label}
              variant="outlined"
              size="small"
              {...getTagProps({ index })}
              sx={{
                ["&.MuiAutocomplete-tag"]: {
                  mx: 0.2,
                  my: 0.2,
                },
              }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Barrio"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <FieldErrors>
        <ErrorMessage name="neighborhood" />
      </FieldErrors>
    </Grid>
  );
};

export default NeighborhoodField;
