import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Layout from '../../../../components/mui/Layout'
import SearchComponent from './components/SearchComponent'
import PropertiesGridLoading from './components/PropertiesGridLoading'
import PropertyRow from './components/PropertyRow'
import { setIsLoading } from '../../../../redux/actions/loadingAction'
import { setPerPage, setPage, setPages, setArrayList } from '../../../../redux/actions/dashboard/properties/property/PropertyAction'
import { Permission } from '../../../../services/permissions/Permission'
import { perPages } from '../../../../helpers/variablesColumns'
import errorServices from '../../../../services/errors'
import axios from '../../../../services/axios'

const PropertyOldPage = () => {
	const dispatch = useDispatch()
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const isLoading = useSelector( state => state.loading.isLoading)
	const refreshTable = useSelector( state => state.property.refreshTable)
	const perPage = useSelector( state => state.property.perPage)
	const page = useSelector( state => state.property.page)
	const search = useSelector( state => state.property.search)
	const realState = useSelector( state => state.property.realState)
	const statusCommercial = useSelector( state => state.property.statusCommercial)
	const pages = useSelector( state => state.property.pages)
	const arrayList = useSelector( state => state.property.arrayList)
	
	const handleArrayList = async () => {
		dispatch( setIsLoading(true) )
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				perPage: perPage,
				page: page,
				search: search === '' ? null : search,
				realstate: realState,
				status_commercial: statusCommercial,
			}
			
			const data = await axios.post('dashboard/properties/list', param)
			
			if (data.status === 200) {
				dispatch( setPages(data.data.data.pages) )
				dispatch( setArrayList(data.data.data.records) )
			}
		} catch (error) {
			await errorServices(error)
		}
		
		dispatch( setIsLoading(false) )
	}
	
	useEffect(() => {
		handleArrayList()
	}, [
		perPage,
		page,
		realState,
		statusCommercial,
		refreshTable
	])
	
	const handleChangePerPage = ({ target }) => dispatch( setPerPage(target.value) )
	
	const handleChangePage = (event, value) => dispatch( setPage(value) )
	
	return (
		<Layout.Page title="Propiedades V1">
			<SearchComponent />
			
			<Layout.Card className="border-radius-4 mt-4 mr-4 ml-4">
				<Layout.CardContent className="m-0 p-4">
					<Permission permission="properties.list">
						<Layout.TablePagination
							arrayList={arrayList.length}
							perPage={perPage}
							perPages={perPages}
							page={page}
							pages={pages}
							handleChangePerPage={handleChangePerPage}
							handleChangePage={handleChangePage}
						/>
					</Permission>
				</Layout.CardContent>
			</Layout.Card>
			
			<Grid container spacing={2} className="animate__animated animate__fadeIn p-4">
				{isLoading && (
					<PropertiesGridLoading />
				)}
				{!isLoading && (
					<>
						{arrayList.length > 0 && (
							arrayList.map(({ id, name, path, has_photo, has_photo360, has_video, code, domus_code, address, text_status, color_status, action_type, action_canon_string, action_sale_string, type, country, city, neighborhood, area_total, number_room, number_bathroom, number_garage, property_id }, number) => (
								<PropertyRow
									key={number}
									id={id}
									name={name}
									path={path}
									has_photo={has_photo}
									has_photo360={has_photo360}
									has_video={has_video}
									code={code}
									domus_code={domus_code}
									address={address}
									text_status={text_status}
									color_status={color_status}
									action_type={action_type}
									action_canon_string={action_canon_string}
									action_sale_string={action_sale_string}
									type={type}
									country={country}
									city={city}
									neighborhood={neighborhood}
									area_total={area_total}
									number_room={number_room}
									number_bathroom={number_bathroom}
									number_garage={number_garage}
									property_id={property_id}
									handleList={handleArrayList}
								/>
							))
						)}
					</>
				)}
			</Grid>
			
			<Layout.Card className="border-radius-4 mr-4 ml-4">
				<Layout.CardContent className="m-0 p-4">
					<Permission permission="properties.list">
						<Layout.TablePagination
							arrayList={arrayList.length}
							perPage={perPage}
							perPages={perPages}
							page={page}
							pages={pages}
							handleChangePerPage={handleChangePerPage}
							handleChangePage={handleChangePage}
						/>
					</Permission>
				</Layout.CardContent>
			</Layout.Card>
		</Layout.Page>
	)
}

export default PropertyOldPage
