import React, {Fragment, useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Inputs from '../../../../../../components/mui/Inputs';
import Layout from '../../../../../../components/mui/Layout';
import Feedback from '../../../../../../components/mui/Feedback';
import DropdownSelectCountry from '../../CountryPage/components/DropdownSelect';
import DropdownSelectState from '../../StatePage/components/DropdownSelect';
import DropdownSelectCity from '../../CityPage/components/DropdownSelect';
import DropdownSelectLocality from '../../LocalityPage/components/DropdownSelect';
import { setStore, getShowById, setUpdateById } from '../services';
import { setFormData, setIsLoadingShow, setIsLoadingForm, setRefreshTable, setIsOpenModal } from '../redux/actions/neighborhoodAction';
import swalErrors from '../../../../../../hooks/useErrors';
import usePermission from '../../../../../../hooks/usePermission';
import { initialValuesNeighborhood as initialValues } from '../../../../../../helpers/variablesInitialValues';

const FormComponent = () => {
	const dispatch = useDispatch();
	const refreshTable = useSelector( ({ neighborhood }) => neighborhood.refreshTable);
	const isLoadingShow = useSelector( ({ neighborhood }) => neighborhood.isLoadingShow);
	const isLoadingForm = useSelector( ({ neighborhood }) => neighborhood.isLoadingForm);
	const formType = useSelector( ({ neighborhood }) => neighborhood.formType);
	const formData = useSelector( ({ neighborhood }) => neighborhood.formData);
	const arrayListToDropdown = useSelector( ({ locality }) => locality.arrayListToDropdown);
	const permissionStore = usePermission('neighborhoods.store');
	const permissionShow = usePermission('neighborhoods.show');
	const permissionUpdate = usePermission('neighborhoods.update');
	const [areasArrayList, setAreasArrayList] = useState([]);
	
	/**
	 *
	 * @description Execute endpoint show by id
	 * @return dispatch
	 *
	 */
	const handleShowById = async () => {
		if (formType === 'show' && permissionShow) {
			await dispatch( setIsLoadingShow(true) );
			
			try {
				const { data } = await getShowById(formData.id);
				
				if (data.status === 200) {
					const object = {
						id: data.data.id,
						country: data.data.country_id === null ? '' : data.data.country_id,
						state: data.data.state_id === null ? '' : data.data.state_id,
						city: data.data.city_id === null ? '' : data.data.city_id,
						name: data.data.name === null ? '' : data.data.name,
						localities: data.data.localities || []
					};
					
					await dispatch( setFormData(object) );
					setAreasArrayList(object.localities);
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingShow(false) );
		}
	};
	
	useEffect(() => {
		handleShowById();
	}, [formType]);
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			name: Yup.string().required('El nombre es requerido')
		})
	)
	
	/**
	 *
	 * @description Execute submit of the form
	 * @param values
	 * @param resetForm
	 * @param setValues
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		if (formType === 'store' ? permissionStore : permissionUpdate) {
			await dispatch( setIsLoadingForm(true) );
			
			try {
				const param = {
					city_id: formData.city === '' ? null : formData.city,
					name: formData.name === '' ? null : formData.name,
					localities: areasArrayList.length === 0 ? [] : areasArrayList
				};
				
				let data;
				
				if (formType === 'store') {
					data = await setStore(param);
				} else {
					data = await setUpdateById(formData.id, param);
				}
				
				if (data.data.status === 200) {
					resetForm({ values: initialValues});
					setValues(initialValues);
					await dispatch( setFormData(initialValues) );
					await dispatch( setRefreshTable(!refreshTable) );
					await dispatch( setIsOpenModal(false) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingForm(false) );
		}
	};
	
	/**
	 *
	 * @description Execute dispatch formData state
	 * @return dispatch
	 *
	 */
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.name === 'hasChangeLogo' ? !formData.hasChangeLogo : target.value }) );
	
	/**
	 *
	 * @description Execute add item area
	 * @return void
	 *
	 */
	const handleonChangeArrayList = ({ target }) => {
		const array = [...areasArrayList];
		const find = areasArrayList.find(({ id }) => id === target.value);
		
		if (find) {
			handleDeleteItem(target.value);
		} else {
			const object = arrayListToDropdown.find(({ id }) => id === target.value);
			
			array.push({ id: object.id, name: object.name });
			setAreasArrayList(array);
		}
	};
	
	/**
	 *
	 * @description Execute delete item area
	 * @return void
	 *
	 */
	const handleDeleteItem = value => setAreasArrayList(areasArrayList.filter(({ id }) => id !== value));
	
	return (
		<>
			{isLoadingShow && (
				<Feedback.Loading />
			)}
			{!isLoadingShow && (
				<Formik
					initialValues={formData}
					validationSchema={validationSchema}
					onSubmit={hanbleSubmit}
				>
					{({
					  errors,
					  touched,
					  handleChange,
					  handleBlur,
					  isValid,
					  dirty
				  }) => (
						<Form noValidate>
							<div className="row animate__animated animate__fadeIn">
								<div className="col-sm-12">
									<DropdownSelectCountry
										name="country"
										value={formData.country}
										handleChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.country && touched.country ? true : false}
										errorMessage={errors.country && touched.country ? errors.country : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12">
									<DropdownSelectState
										name="state"
										value={formData.state}
										countryId={formData.country}
										handleChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.state && touched.state ? true : false}
										errorMessage={errors.state && touched.state ? errors.state : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12">
									<DropdownSelectCity
										name="city"
										value={formData.city}
										stateId={formData.state}
										handleChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.city && touched.city ? true : false}
										errorMessage={errors.city && touched.city ? errors.city : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12">
									<Inputs.TextBox
										name="name"
										label="Nombre"
										placeholder="Ingrese nombre..."
										value={formData.name}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.name && touched.name ? true : false}
										errorMessage={errors.name && touched.name ? errors.name : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12">
									<DropdownSelectLocality
										name="locality"
										value={formData.locality}
										handleChange={e => {
											handleOnChange(e)
											handleonChangeArrayList(e)
										}}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								{areasArrayList.length > 0 && (
									<div className="row">
										<div className="col-sm-12 mt-3">
											{areasArrayList.map(({ id, name }, index) => (
												<Fragment key={index}>
													<Layout.Chip
														label={name}
														size="medium"
														onDelete={() => handleDeleteItem(id)}
														className={`${index > 0 && 'ml-2'}`}
													/>
												</Fragment>
											))}
										</div>
									</div>
								)}
							</div>
							
							{(formType === 'store' ? permissionStore : permissionUpdate) && (
								<div className="row">
									<div className="col-sm-12">
										<Inputs.LoadingButton
											isDisabled={formType === 'store' ? !(dirty && isValid) : false}
											isLoading={isLoadingForm}
											isLoadingPosition="start"
											startIcon={formType === 'store' ? <Layout.Icons.SaveIcon height={18} width={18} /> : <Layout.Icons.EditIcon height={18} width={18} />}
											label={`${formType === 'store' ? 'Guardar' : 'Actualizar'}`}
											type="submit"
											className={`${formType === 'store' ? ((dirty && isValid) && 'bg-primary') : 'bg-primary'} font-family-roboto-regular fs-6 text-capitalize mt-2 d-flex float-end`}
										/>
									</div>
								</div>
							)}
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default FormComponent;
