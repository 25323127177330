import endPoints from './endPoints'
import axios from '../../../../../services/axios'

const {
	arrayList,
	arrayListDropdown
} = endPoints

export const getArrayList = async (data) => (
	await axios.post(arrayList, data)
)

export const getArrayListDropdown = async (data) => (
	await axios.post(arrayListDropdown, data)
)
