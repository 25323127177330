import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Display from '../../../../../components/display/Display'
import Feedback from '../../../../../components/feedback/Feedback'
import FormList from './FormList'
import FormCreate from './FormCreate'
import PropertiesOwnersList from './PropertiesOwnersList'
import Key from './Key'
import Authorization from './Authorization'
import errorServices from '../../../../../services/errors'
import { swalSuccess } from '../../../../../services/sweetalert'
import axios from '../../../../../services/axios'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		// top: theme.spacing(1)
	},
}))

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`
	}
}

function TabPanel({ children, value, index, ...other }) {
	
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				children
			)}
		</div>
	)
}

const ModalTabs = ({ getPropertyID, getTitleModal, getOpenModal, setOpenModal }) => {
	const classes = useStyles()
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [value, setValue] = useState(0)
	const [active, setList] = useState(false)
	const [getActive, setListActive] = useState(false)
	const [open, setOpen] = useState(false)
	
	const handleChange = (event, newValue) => {
		setValue(newValue)
	}
	
	const handleClose = () => {
		setValue(0)
		setOpenModal(false)
	}
	
	useEffect(() => {
	
	}, [getPropertyID, getTitleModal, getOpenModal])
	
	const handleShow = () => setOpen(!open)
	
	useEffect(() => {
	
	}, [open])
	
	const handleList = async () => {
		setList(true)
		setOpen(false)
	}
	
	useEffect(() => {
	
	}, [active])
	
	const handleStoreItems = async (id) => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				property_id: getPropertyID,
				owner_id: id
			}
			
			const data = await axios.post(`dashboard/owners/properties`, param)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				setListActive(!getActive)
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	useEffect(() => {
	
	}, [getActive])
	
	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			fullWidth={true}
			maxWidth="xl"
			aria-labelledby="dialogForm"
			open={getOpenModal}
			className="vh-100"
		>
			<DialogTitle id="dialogForm">
				{getTitleModal}
				
				<IconButton
					onClick={handleClose}
					className="bg-white height-32 width-32"
					classes={{ root: classes.closeButton }}
					aria-label="like"
				>
					<Display.Icons.CloseIcon
						className="text-dark"
						style={{ fontSize: 20 }}
					/>
				</IconButton>
			</DialogTitle>
			<DialogContent
				className="vh-100"
				dividers
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Tabs
							value={value}
							onChange={handleChange}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="auto"
							aria-label="scrollable auto tabs example"
						>
							<Tab label="Propietarios" {...a11yProps(0)} />
							<Tab label="Asignación de Llaves" {...a11yProps(1)} />
							<Tab label="Autorización" {...a11yProps(2)} />
						</Tabs>
					</Grid>
					<Grid item xs={12} className="p-4">
						<TabPanel value={value} index={0}>
							<PropertiesOwnersList
								active={getActive}
								getPropertyID={getPropertyID}
							/>
							<List
								component="nav"
								aria-labelledby="nested-list-subheader"
								className="p-0 w-100"
							>
								<ListItem
									button
									className="border-top"
									onClick={handleShow}
								>
									<ListItemIcon>
										<Display.Icons.AddOutlinedIcon />
									</ListItemIcon>
									<ListItemText className="font-size-18">
										<div className="float-start font-family-roboto-semibold font-size-22 left--20">
											Crear nuevo
										</div>
										<div className="float-end">
											{open ? <Display.Icons.ExpandLessIcon /> : <Display.Icons.ExpandMoreIcon />}
										</div>
									</ListItemText>
								</ListItem>
							</List>
							<Collapse
								className="m-0 p-0 w-100 padding-40 animate__animated animate__fadeIn"
								in={open}
								timeout="auto"
								unmountOnExit
							>
								<FormCreate
									getData={{}}
									getOpcion="modal"
									getOpcionForm="store"
									handleList={handleList}
								/>
							</Collapse>
							<FormList
								active={active}
								handleStoreItems={handleStoreItems}
							/>
						</TabPanel>
						<TabPanel value={value} index={1}>
							<Key
								getPropertyID={getPropertyID}
							/>
						</TabPanel>
						<TabPanel value={value} index={2}>
							<Authorization
								getPropertyID={getPropertyID}
							/>
						</TabPanel>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ModalTabs
