import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Inputs from '../../../../../../components/mui/Inputs'

export const TextFieldReadOnly = ({ value, label, ...props }) => {
  return (
    <Grid container flexDirection="column" item>
      <Inputs.TextBox
        type="text"
        variant="outlined"
        size="small"
        name="email"
        label={label}
        placeholder={label}
        hasLabelFixed={true}
        value={value === null ? '' : value}
        readOnly={true}
        className="font-family-roboto-medium text-dark fs-6 w-100"
        {...props}
      />
    </Grid>
  )
}

TextFieldReadOnly.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
}
