export const PER_PAGE_REGISTER_FILTER = 'PER_PAGE_REGISTER_FILTER'
export const PER_PAGE_DISCARDED_FILTER = 'PER_PAGE_DISCARDED_FILTER'
export const PER_PAGE_COMMERCIAL_FILTER = 'PER_PAGE_COMMERCIAL_FILTER'
export const PER_PAGE_LOGS_FILTER = 'PER_PAGE_LOGS_FILTER'
export const PAGE_REGISTER_FILTER = 'PAGE_REGISTER_FILTER'
export const PAGE_DISCARDED_FILTER = 'PAGE_DISCARDED_FILTER'
export const PAGE_COMMERCIAL_FILTER = 'PAGE_COMMERCIAL_FILTER'
export const PAGE_LOGS_FILTER = 'PAGE_LOGS_FILTER'
export const HAS_NEXT_PAGE_REGISTER_FILTER = 'HAS_NEXT_PAGE_REGISTER_FILTER'
export const HAS_NEXT_PAGE_DISCARDED_FILTER = 'HAS_NEXT_PAGE_DISCARDED_FILTER'
export const HAS_NEXT_PAGE_COMMERCIAL_FILTER = 'HAS_NEXT_PAGE_COMMERCIAL_FILTER'
export const SEARCH_FILTER = 'SEARCH_FILTER'
export const PAGES_REGISTER_FILTER = 'PAGES_REGISTER_FILTER'
export const PAGES_DISCARDED_FILTER = 'PAGES_DISCARDED_FILTER'
export const PAGES_COMMERCIAL_FILTER = 'PAGES_COMMERCIAL_FILTER'
export const PAGES_LOGS_FILTER = 'PAGES_LOGS_FILTER'
export const ARRAY_LIST_REGISTER_FILTER = 'ARRAY_LIST_REGISTER_FILTER'
export const ARRAY_LIST_DISCARDED_FILTER = 'ARRAY_LIST_DISCARDED_FILTER'
export const ARRAY_LIST_COMMERCIAL_FILTER = 'ARRAY_LIST_COMMERCIAL_FILTER'
export const ARRAY_LIST_FAVORITES_FILTER = 'ARRAY_LIST_FAVORITES_FILTER'
export const ARRAY_LIST_PREVIEW_FAVORITES_FILTER = 'ARRAY_LIST_PREVIEW_FAVORITES_FILTER'
export const ARRAY_LIST_LOGS_FILTER = 'ARRAY_LIST_LOGS_FILTER'
export const IS_ACTIVE_SEARCH_FILTER = 'IS_ACTIVE_SEARCH_FILTER'
export const IS_OPEN_MODAL_FILTER = 'IS_OPEN_MODAL_FILTER'
export const IS_OPEN_CUSTOMER_MODAL_FILTER = 'IS_OPEN_CUSTOMER_MODAL_FILTER'
export const IS_OPEN_OCCURRENCES_FILTER = 'IS_OPEN_OCCURRENCES_FILTER'
export const IS_ACTIVE_VALIDATE_BUTTON_FILTER = 'IS_ACTIVE_VALIDATE_BUTTON_FILTER'
export const IS_ACTIVE_HISTORICAL_FILTER = 'IS_ACTIVE_HISTORICAL_FILTER'
export const IS_LOADING_REGISTER_FILTER = 'IS_LOADING_REGISTER_FILTER'
export const IS_LOADING_DISCARDED_FILTER = 'IS_LOADING_DISCARDED_FILTER'
export const IS_LOADING_COMMERCIAL_FILTER = 'IS_LOADING_COMMERCIAL_FILTER'
export const IS_LOADING_FAVORITES_FILTER = 'IS_LOADING_FAVORITES_FILTER'
export const IS_LOADING_LOGS_FILTER = 'IS_LOADING_LOGS_FILTER'
export const IS_LOADING_OCCURRENCES_FILTER = 'IS_LOADING_OCCURRENCES_FILTER'
export const IS_LOADING_VALIDATE_FILTER = 'IS_LOADING_VALIDATE_FILTER'
export const IS_LOADING_FORM_FILTER = 'IS_LOADING_FORM_FILTER'
export const IS_COMPLETED_FORM_FILTER = 'IS_COMPLETED_FORM_FILTER'
export const IS_SELECTED_TOGGLE_SWITCH_FILTER = 'IS_SELECTED_TOGGLE_SWITCH_FILTER'
export const FORM_FILTER_FILTER = 'FORM_FILTER_FILTER'
export const FORM_DATA_FILTER = 'FORM_DATA_FILTER'
export const FORM_DATA_DETAIL_FILTER = 'FORM_DATA_DETAIL_FILTER'
export const FORM_DATA_PREVIEW_FILTER = 'FORM_DATA_PREVIEW_FILTER'
export const FORM_DATA_CUSTOMER_FILTER = 'FORM_DATA_CUSTOMER_FILTER'
export const FORM_TYPE_FILTER = 'FORM_TYPE_FILTER'
