import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

const Scroll = ({ children }) => {
	const sideScrollStyle = {
		zIndex: 5,
		height: "calc(95vh - 200px)"
	}
	
	return (
		<Scrollbars
			autoHide
			style={sideScrollStyle}
			renderThumbVertical={({ style, ...props }) => (
				<div {...props} className="sidebar-scrollbar-style" />
			)}
			renderThumbHorizontal={({ style, ...props }) => <div {...props} />}
			renderTrackVertical={({ style, ...props }) => (
				<div
					{...props}
					style={{
						...style,
						zIndex: 5,
						position: "absolute",
						width: "6px",
						right: "2px",
						bottom: "2px",
						top: "2px",
						borderRadius: "3px"
					}}
				/>
			)}
		>
			{children}
		</Scrollbars>
	)
}

export default Scroll
