import React, { useEffect, useState } from 'react'
import Layout from '../../components/mui/Layout'
import { Footer, Header, Map } from '../../components/v2-mobili'

import ThemeProviderComponent from '../../theme-v2/themeProvider'
import { DescriptionMain, InfoCustomer, Maps } from './components'
import styles from './clientPropertyView.module.css'
import { getFilterData } from '../../api/customer'
import { useParams } from 'react-router-dom'
import { swalError } from '../../services/sweetalert'
import Loader from '../dashboard/TestPage/components/loader/loader'

export function ClientPropertyView() {
  const params = useParams()
  const [filterData, setFilterData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const handleFilterData = async () => {
    setIsLoading(true)
    const { data, status } = await getFilterData(params?.uID,'customer')

    if (status !== 200) {
      swalError(data)
      setIsLoading(false)
      return
    }

    setFilterData(data?.data)
    setIsLoading(false)
  }

  useEffect(() => {
    handleFilterData()
  }, [])

  //console.log('filterData', filterData)

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <ThemeProviderComponent>
        <Header />
        <Layout.Page title={'Ficha Cliente'}>
          <main className={styles.gridContainer}>
            <InfoCustomer filterData={filterData} />
          </main>
          <Map geojson={filterData?.geojson} />

          {filterData?.favorites?.map((property) => {
            const priceArray = [
              {
                id: 1,
                title: 'PRECIO VENTA',
                price: property?.action_sale_string,
              },
              {
                id: 2,
                title: 'PRECIO ARRIENDO',
                price: property?.action_canon_string,
              },
              {
                id: 3,
                title: 'COSTO ADMINISTRACIÓN',
                price: property?.action_value_admin_string,
              },
            ]
            return (
              <DescriptionMain
                key={property.id}
                property={property}
                priceArray={priceArray}
              />
            )
          })}

          <Footer />
        </Layout.Page>
      </ThemeProviderComponent>
    </>
  )

}
