import axios from 'axios'
import { useEffect, useState } from 'react'
import axiosServices from '../services/axios'

export const postPropertiesShow = async (albumId) => {
  try {
    const data = await axiosServices.get(`/properties/albums/show/${albumId}`)
    return data
  } catch (error) {
    return error.response
  }
}

export const postListFilesByAlbumsTypes = async (
  albumId,
  page,
  perPage = false,
) => {
  try {
    const data = await axiosServices.post(
      '/properties/albums/list-files-by-albums-types',
      {
        album_type_id: `${albumId}`,
        page: `${page}`,
        perPage: perPage ? perPage : '25',
      },
    )
    return data
  } catch (error) {
    return error.response
  }
}
export const postOwnersProperties = async (ownerId) => {
  try {
    const data = await axiosServices.post('/dashboard/owners/properties/list', {
      owner_id: `${ownerId}`,
      page: 1,
      perPage: '25',
    })
    return data
  } catch (error) {
    return error.response
  }
}

export const useGetApi = (url) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        setIsLoading(false)
      })
  }, [url])
  return { data, isLoading }
}
