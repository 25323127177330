import AccountCircleIcon from './icons/AccountCircleIcon'
import AddCircleIcon from './icons/AddCircleIcon'
import AddIcon from './icons/AddIcon'
import AddLocationIcon from './icons/AddLocationIcon'
import AdminPanelSettingsIcon from './icons/AdminPanelSettingsIcon'
import ApartmentIcon from './icons/ApartmentIcon'
import ArrowRightAltIcon from './icons/ArrowRightAltIcon'
import BarChartIcon from './icons/BarChartIcon'
import CalendarMonthIcon from './icons/CalendarMonthIcon'
import CheckIcon from './icons/CheckIcon'
import ChevronRightIcon from './icons/ChevronRightIcon'
import CloseIcon from './icons/CloseIcon'
import ContactPageIcon from './icons/ContactPageIcon'
import DeleteIcon from './icons/DeleteIcon'
import EditIcon from './icons/EditIcon'
import ExitToAppIcon from './icons/ExitToAppIcon'
import ExpandLessIcon from './icons/ExpandLessIcon'
import ExpandMoreIcon from './icons/ExpandMoreIcon'
import FacebookIcon from './icons/FacebookIcon'
import FavoriteBorderIcon from './icons/FavoriteBorderIcon'
import FavoriteIcon from './icons/FavoriteIcon'
import GppBadIcon from './icons/GppBadIcon'
import HomeWorkIcon from './icons/HomeWorkIcon'
import InstagramIcon from './icons/InstagramIcon'
import KeyboardArrowUpIcon from './icons/KeyboardArrowUpIcon'
import LinkedInIcon from './icons/LinkedInIcon'
import ListIcon from './icons/ListIcon'
import LocalPhoneIcon from './icons/LocalPhoneIcon'
import LockOpenIcon from './icons/LockOpenIcon'
import MailIcon from './icons/MailIcon'
import MailOutlineIcon from './icons/MailOutlineIcon'
import MenuIcon from './icons/MenuIcon'
import MoreIcon from './icons/MoreIcon'
import NavigateBeforeIcon from './icons/NavigateBeforeIcon'
import NavigateNextIcon from './icons/NavigateNextIcon'
import PatternIcon from './icons/PatternIcon'
import PhoneIcon from './icons/PhoneIcon'
import PhotoCameraIcon from './icons/PhotoCameraIcon'
import RemoveIcon from './icons/RemoveIcon'
import SaveIcon from './icons/SaveIcon'
import SearchIcon from './icons/SearchIcon'
import SendIcon from './icons/SendIcon'
import SettingsIcon from './icons/SettingsIcon'
import VerifiedUserIcon from './icons/VerifiedUserIcon'
import VisibilityIcon from './icons/VisibilityIcon'
import VisibilityOffIcon from './icons/VisibilityOffIcon'
import YouTubeIcon from './icons/YouTubeIcon'
import ZoomInIcon from './icons/ZoomInIcon'

const Icons = {
  AccountCircleIcon,
  AddCircleIcon,
  AddIcon,
  AddLocationIcon,
  AdminPanelSettingsIcon,
  ApartmentIcon,
  ArrowRightAltIcon,
  BarChartIcon,
  CalendarMonthIcon,
  CheckIcon,
  ChevronRightIcon,
  CloseIcon,
  ContactPageIcon,
  DeleteIcon,
  EditIcon,
  ExitToAppIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  FacebookIcon,
  FavoriteBorderIcon,
  FavoriteIcon,
  GppBadIcon,
  HomeWorkIcon,
  InstagramIcon,
  KeyboardArrowUpIcon,
  LinkedInIcon,
  ListIcon,
  LocalPhoneIcon,
  LockOpenIcon,
  MailIcon,
  MailOutlineIcon,
  MenuIcon,
  MoreIcon,
  NavigateBeforeIcon,
  NavigateNextIcon,
  PatternIcon,
  PhoneIcon,
  PhotoCameraIcon,
  RemoveIcon,
  SaveIcon,
  SearchIcon,
  SendIcon,
  SettingsIcon,
  VerifiedUserIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  YouTubeIcon,
  ZoomInIcon
}

export default Icons
