const ROOT = 'dashboard/settings/users';

export default {
	arrayList: `${ROOT}/list`,
	arrayListToDropdown: `${ROOT}`,
	arrayListToDropdownAgent: `${ROOT}/by-agent`,
	arrayListToDropdownAdviser: `${ROOT}/by-adviser`,
	store: `${ROOT}`,
	resendEmail: `${ROOT}/resend-email/`,
	show: `${ROOT}/`,
	status: `${ROOT}/status/`,
	update: `${ROOT}/`,
	assignPermissions: `${ROOT}/assign-permissions/`,
	destroy: `${ROOT}/`,
	showProfile: `${ROOT}/show-profile`,
	updateProfile: `${ROOT}/update-profile`,
	updateProfilePassword: `${ROOT}/update-profile-password`
};
