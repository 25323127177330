import React, {useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Divider from '@material-ui/core/Divider'
import Feedback from '../../../../../components/feedback/Feedback'

const data = [
	{},
	{},
	{},
	{},
	{},
	{},
	{},
	{}
]

const PropertiesGridLoading = () => {
	const [records, setRecords] = useState(data)
	
	return (
		<>
			{records.map((item, index) => (
				<Grid item key={index} xs={12} sm={6} md={4} lg={3}>
					<Card className="border-radius-7 box-shadow-card">
						<CardHeader
							className="float-left w-100 height-60"
							avatar={
								<Feedback.Loading type="circle" animation="wave" width="40px" height="40px" />
							}
							title={<div><Feedback.Loading type="text" animation="wave" width="40%" height="25px" /></div>}
							subheader={<div><Feedback.Loading type="text" animation="wave" width="80%" height="25px" /></div>}
						/>
						<CardMedia className="float-left w-100 height-250">
							<Feedback.Loading type="rect" animation="wave" width="100%" height="250px" />
						</CardMedia>
						<CardContent className="float-left w-100 height-130 m-0 p-2">
							<Grid container spacing={1}>
								<Grid item xs={12} className="mb-2 mt-1">
									<Feedback.Loading type="text" animation="wave" width="80%" height="25px" />
									<Feedback.Loading type="text" animation="wave" width="60%" height="25px" />
								</Grid>
								<Grid item xs={3}>
									<Feedback.Loading type="text" animation="wave" width="100%" height="28px" />
								</Grid>
								<Grid item xs={3}>
									<Feedback.Loading type="text" animation="wave" width="100%" height="28px" />
								</Grid>
								<Grid item xs={3}>
									<Feedback.Loading type="text" animation="wave" width="100%" height="28px" />
								</Grid>
								<Grid item xs={3}>
									<Feedback.Loading type="text" animation="wave" width="100%" height="28px" />
								</Grid>
								<Grid item xs={12} className="mt-1">
									<Divider variant="middle" className="bg-dark" />
								</Grid>
							</Grid>
						</CardContent>
						<CardActions className="float-left w-100 height-50 m-0 p-2">
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<div className="float-left d-inline m-0 ml-2 p-0">
										<Feedback.Loading type="circle" animation="wave" width="32px" height="32px" />
									</div>
									<div className="float-left d-inline m-0 ml-2 p-0">
										<Feedback.Loading type="circle" animation="wave" width="32px" height="32px" />
									</div>
									<div className="float-left d-inline m-0 ml-2 p-0">
										<Feedback.Loading type="circle" animation="wave" width="32px" height="32px" />
									</div>
									<div className="float-left d-inline m-0 ml-2 p-0">
										<Feedback.Loading type="circle" animation="wave" width="32px" height="32px" />
									</div>
								</Grid>
							</Grid>
						</CardActions>
					</Card>
				</Grid>
			))}
		</>
	)
}

export default PropertiesGridLoading
