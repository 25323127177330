import * as types from '../../actionsTypes/algolia/algoliaPropertyTypes'

const initialState = {
	perPage: 20,
	page: 1,
	pages: 0,
	hasNextPage: true,
	arrayList: [],
	isLoading: false
}

const algoliaPropertyReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_ALGOLIA_PROPERTY:
			return {
				...state,
				perPage: payload
			}
		
		case types.PAGE_ALGOLIA_PROPERTY:
			return {
				...state,
				page: payload
			}
		
		case types.PAGES_ALGOLIA_PROPERTY:
			return {
				...state,
				pages: payload
			}
			
		case types.HAS_NEXT_PAGE_ALGOLIA_PROPERTY:
			return {
				...state,
				hasNextPage: payload
			}
		
		case types.ARRAY_LIST_ALGOLIA_PROPERTY:
			return {
				...state,
				arrayList: payload
			}
			
		case types.IS_LOADING_ALGOLIA_PROPERTY:
			return {
				...state,
				isLoading: payload
			}
			
		default:
			return state
	}
}

export default algoliaPropertyReducer
