import React from 'react'
import Photo from '@material-ui/icons/Photo'

export default function PhotoIcon(props) {
	
	const { ...other } = props
	
	return (
		<Photo { ...other } />
	)
}
