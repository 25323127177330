import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from "@material-ui/core/IconButton"
import Display from "../../../../../components/display/Display"
import Controls from "../../../../../components/controls/Controls"
import Feedback from "../../../../../components/feedback/Feedback"
import { makeStyles } from '@material-ui/core/styles'
import errorServices from '../../../../../services/errors'
import { swalSuccess } from '../../../../../services/sweetalert'
import axios from '../../../../../services/axios'

const useStyles = makeStyles((theme) => ({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		// top: theme.spacing(1)
	},
}))

const array = [
	{ id: 'Aprobado', name: 'Aprobado' },
	{ id: 'Rechazado', name: 'Rechazado' }
]

const ModalAuthorizationByOwnerStatus = ({ id, open, setOpen, handleList }) => {
	const classes = useStyles()
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [getLoading, setLoading] = useState(false)
	const [getPropertyCode, setPropertyCode] = useState('')
	const [getPropertyDomusCode, setPropertyDomusCode] = useState('')
	const [getPersonType, setPersonType] = useState('')
	const [getRepresentative, setRepresentative] = useState('')
	const [getOwner, setOwner] = useState('')
	const [getEmail, setEmail] = useState('')
	const [getAuthorization, setAuthorization] = useState('')
	const [arrayDropdown, setDropdown] = useState(array)
	const [getStatus, setStatus] = useState('')
	const [getStatusError, setStatusError] = useState(false)
	const [getStatusMessage, setStatusMessage] = useState("")
	
	const handleShow = async () => {
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/authorizations/owners/${id}`)
			
			if (data.status === 200) {
				setPropertyCode(data.data.data.property_code)
				setPropertyDomusCode(data.data.data.property_domus_code)
				setPropertyDomusCode(data.data.data.property_domus_code)
				setPersonType(data.data.data.person_type)
				setOwner(data.data.data.owner)
				setRepresentative(`${data.data.data.representative_first_name} ${data.data.data.representative_last_name}`)
				setEmail(data.data.data.email)
				setAuthorization(data.data.data.authorization)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	useEffect(() => {
		open && handleShow()
	}, [id, open])
	
	const handleClose = () => {
		setOpen(false)
	}
	
	const setHandleForm = async (e) => {
		e.preventDefault()
		
		if (getStatus === ""){
			setStatusError(getStatus === "" ? true : false)
			setStatusMessage(getStatus === "" ? "El campo es requerido" : "")
			
			return false
		}
		
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				status: getStatus
			}
			
			const data = await axios.put(`dashboard/properties/authorizations/owners/status/${id}`, param)
			
			if (data.status === 200) {
				setStatus('')
				swalSuccess(data.data.data)
				handleList()
				handleClose()
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	return (
		<>
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				fullWidth={true}
				maxWidth="sm"
				aria-labelledby="dialogForm"
				open={open}
			>
				<DialogTitle id="dialogForm">
					Asignación de estatus
					
					<IconButton
						onClick={handleClose}
						className="bg-white height-32 width-32"
						classes={{ root: classes.closeButton }}
						aria-label="like"
					>
						<Display.Icons.CloseIcon
							className="text-dark"
							style={{ fontSize: 20 }}
						/>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<form>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<h5 className="text-center"><strong>Datos de la Propiedad</strong></h5>
							</Grid>
							<Grid item xs={12}>
								<h6>Código mobili: <small><strong>{getPropertyCode}</strong></small></h6>
							</Grid>
							<Grid item xs={12}>
								<h6>Código domus: <small><strong>{getPropertyDomusCode}</strong></small></h6>
							</Grid>
							<Grid item xs={12}>
								<h5 className="text-center"><strong>Datos del Propietario</strong></h5>
							</Grid>
							<Grid item xs={12}>
								<h6>Tipo de Persona: <small><strong>{getPersonType}</strong></small></h6>
							</Grid>
							<Grid item xs={12}>
								<h6>
									{getPersonType === 'Natural' && 'Nombre y apellido '}
									{getPersonType !== 'Natural' && 'Razón Social '}
									: <small><strong>{getOwner}</strong></small>
								</h6>
							</Grid>
							<Grid item xs={12}>
								<h6>Email: <small><strong>{getEmail}</strong></small></h6>
							</Grid>
							{getPersonType !== 'Natural' && (
								<Grid item xs={12}>
									<h6>Representante Legal: <small><strong>{getRepresentative}</strong></small></h6>
								</Grid>
							)}
							<Grid item xs={12}>
								<h5 className="text-center">Autorización: </h5>
								<h6><strong>{getAuthorization}</strong></h6>
							</Grid>
							<Grid item xs={12}>
								<Controls.Select
									label="Estatus"
									name="status"
									defaultValue={getStatus}
									error={getStatusError}
									message={getStatusMessage}
									onChange={e => {
										setStatus(e.target.value)
										
										if(e.target.value === ""){
											setStatusError(true)
											setStatusMessage('El campo es requerido')
										} else {
											setStatusError(false)
											setStatusMessage('')
										}
									}}
									arrayDropdown={arrayDropdown}
								/>
							</Grid>
							<Grid item xs={12}>
								<div className="float-right">
									<Controls.Button
										label="Cancelar"
										color="secondary"
										variant="contained"
										endIcon={<Display.Icons.CancelOutlinedIcon />}
										onClick={handleClose}
									/>
									<Controls.Button
										label="Guardar"
										color="primary"
										variant="contained"
										endIcon={<Display.Icons.SaveOutlinedIcon />}
										onClick={(e) => setHandleForm(e)}
									/>
								</div>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
			<Feedback.DialogLoading
				getLoading={getLoading}
				setLoading={setLoading}
			/>
		</>
	)
}

export default ModalAuthorizationByOwnerStatus
