import React from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MuiSelect from '@mui/material/Select'

const Select = (props) => {
	const {
		minWidth = 0,
		size = 'small',
		name,
		label,
		value = '',
		itemsArray = [],
		onChange = null,
		hasError = false,
		errorMessage = '',
		...more
	} = props
	
	return (
		<FormControl
			sx={{ minWidth: minWidth }}
			size={size}
			error={hasError}
			{...more}
		>
			<InputLabel id={name}>{label}</InputLabel>
			<MuiSelect
				labelId={name}
				id={name}
				name={name}
				value={value}
				label={label}
				onChange={onChange}
			>
				{itemsArray.length === 0 && (
					<MenuItem value="">
						No hay datos para esta consulta
					</MenuItem>
				)}
				{itemsArray.length > 0 && (
					itemsArray.map(({ id, name }, number) => (
						<MenuItem
							key={number}
							value={id}
						>
							{name}
						</MenuItem>
					))
				)}
			</MuiSelect>
			{hasError && errorMessage !== "" && (
				<div className="mt-1 bg-grey-light border-l-4 border-danger p-2 font-family-roboto-regular fs-6 text-danger animate__animated animate__fadeIn">
					{errorMessage}
				</div>
			)}
		</FormControl>
	)
}

Select.propTypes = {
	minWidth: PropTypes.number,
	size: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.any,
	itemsArray: PropTypes.array,
	onChange: PropTypes.func,
	hasError: PropTypes.bool,
	errorMessage: PropTypes.string
}

export default Select
