import React from 'react'
import PropTypes from 'prop-types'
import MuiDivider from '@mui/material/Divider'

const Divider = ({ component = null, variant = null, color, text = null, ...more  }) => {

  return (
    <MuiDivider
      component={component}
      variant={variant}
      color={color}
      {...more}
    >
      {text}
    </MuiDivider>
  )
}

Divider.propTypes = {
  component: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string.isRequired,
  text: PropTypes.string
}

export default Divider
