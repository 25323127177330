import * as types from '../../actionsTypes/algolia/algoliaNeighborhoodTypes'

const initialState = {
	search: '',
	arrayListToDropdown: [],
	isLoading: false
}

const algoliaNeighborhoodReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.SEARCH_NEIGHBORHOOD:
			return {
				...state,
				search: payload
			}
		
		case types.ARRAY_LIST_TO_DROPDOWN_ALGOLIA_NEIGHBORHOOD:
			return {
				...state,
				arrayListToDropdown: payload
			}
		
		case types.IS_LOADING_ALGOLIA_NEIGHBORHOOD:
			return {
				...state,
				isLoading: payload
			}
		
		default:
			return state
	}
}

export default algoliaNeighborhoodReducer
