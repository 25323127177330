import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Inputs from '../../../../../components/mui/Inputs';
import Layout from '../../../../../components/mui/Layout';
import Feedback from '../../../../../components/mui/Feedback';
import { setStore, getShowById, setUpdateById } from '../services';
import { setFormData, setIsLoadingShow, setIsLoadingForm, setRefreshTable, setIsOpenModal } from '../redux/actions/realStateAction';
import swalErrors from '../../../../../hooks/useErrors';
import usePermission from '../../../../../hooks/usePermission';
import { initialValuesRealState as initialValues } from '../../../../../helpers/variablesInitialValues';
import '../realState.scss';

const FormComponent = () => {
	const dispatch = useDispatch();
	const refreshTable = useSelector( ({ realState }) => realState.refreshTable);
	const isLoadingShow = useSelector( ({ realState }) => realState.isLoadingShow);
	const isLoadingForm = useSelector( ({ realState }) => realState.isLoadingForm);
	const formType = useSelector( ({ realState }) => realState.formType);
	const formData = useSelector( ({ realState }) => realState.formData);
	const fileRef = useRef(null);
	const permissionStore = usePermission('realstates.store');
	const permissionShow = usePermission('realstates.show');
	const permissionUpdate = usePermission('realstates.update');
	
	/**
	 *
	 * @description Execute endpoint show by id
	 * @return dispatch
	 *
	 */
	const handleShowById = async () => {
		if (formType === 'show' && permissionShow) {
			await dispatch( setIsLoadingShow(true) );
			
			try {
				const { data } = await getShowById(formData.id);
				
				if (data.status === 200) {
					const object = {
						id: data.data.id,
						name: data.data.name === null ? '' : data.data.name,
						email: data.data.email === null ? '' : data.data.email,
						numberId: data.data.number_id === null ? '' : data.data.number_id,
						brokerId: data.data.broker_id === null ? '' : data.data.broker_id,
						apiTokenDomus: data.data.api_token_domus === null ? '' : data.data.api_token_domus,
						idDomus: data.data.id_domus === null ? '' : data.data.id_domus,
						apiTokenPipeDrive: data.data.api_token_pipedrive === null ? '' : data.data.api_token_pipedrive,
						subDomainPipeDrive: data.data.subdomain_pipedrive === null ? '' : data.data.subdomain_pipedrive,
						color: data.data.color === null ? '' : data.data.color,
						logo: '',
						logoPreview: data.data.logo === null ? '' : data.data.logo,
						hasChangeLogo: false
					};
					
					await dispatch( setFormData(object) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingShow(false) );
		}
	};
	
	useEffect(() => {
		handleShowById();
	}, [formType]);
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => {
		if (formType === 'store' || (formType === 'show' && formData.hasChangeLogo)) {
			return Yup.object({
				name: Yup.string()
					.required('El nombre es requerido'),
				email: Yup.string()
					.email('El email no es válido')
					.required('El email es requerido'),
				numberId: Yup.number()
					.required('El número es requerido'),
				brokerId: Yup.number()
					.required('El broker es requerido'),
				apiTokenDomus: Yup.string()
					.required('El api token para domus es requerido'),
				idDomus: Yup.number()
					.required('El id para domus es requerido'),
				apiTokenPipeDrive: Yup.string()
					.required('El api token para pipedrive es requerido'),
				subDomainPipeDrive: Yup.string()
					.required('La url para pipedrive es requerido'),
				color: Yup.string()
					.required('El color es requerido'),
				logo: Yup.string()
					.required('El logo es requerido')
			});
		}
		
		return Yup.object({
			name: Yup.string()
				.required('El nombre es requerido'),
			email: Yup.string()
				.email('El email no es válido')
				.required('El email es requerido'),
			numberId: Yup.number()
				.required('El número es requerido'),
			brokerId: Yup.number()
				.required('El broker es requerido'),
			apiTokenDomus: Yup.string()
				.required('El api token para domus es requerido'),
			idDomus: Yup.number()
				.required('El id para domus es requerido'),
			apiTokenPipeDrive: Yup.string()
				.required('El api token para pipedrive es requerido'),
			subDomainPipeDrive: Yup.string()
				.required('La url para pipedrive es requerido'),
			color: Yup.string()
				.required('El color es requerido')
		});
	}
	
	/**
	 *
	 * @description Execute submit of the form
	 * @param values
	 * @param resetForm
	 * @param setValues
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		if (formType === 'store' ? permissionStore : permissionUpdate) {
			await dispatch( setIsLoadingForm(true) );
			
			try {
				const param = new FormData();
				param.append('id', formData.id);
				param.append('name', formData.name === '' ? null : formData.name);
				param.append('email', formData.email === '' ? null : formData.email);
				param.append('number_id', formData.numberId === '' ? null : formData.numberId);
				param.append('broker_id', formData.brokerId === '' ? null : formData.brokerId);
				param.append('api_token_domus', formData.apiTokenDomus === '' ? null : formData.apiTokenDomus);
				param.append('id_domus', formData.idDomus === '' ? null : formData.idDomus);
				param.append('api_token_pipedrive', formData.apiTokenPipeDrive === '' ? null : formData.apiTokenPipeDrive);
				param.append('subdomain_pipedrive', formData.subDomainPipeDrive === '' ? null : formData.subDomainPipeDrive);
				param.append('color', formData.color === '' ? null : formData.color);
				
				let data;
				
				if (formType === 'store') {
					param.append('file', fileRef.current.files[0]);
					
					data = await setStore(param);
				} else {
					if (formData.hasChangeLogo) {
						param.append('file', fileRef.current.files[0]);
					}
					
					data = await setUpdateById(formData.id, param);
				}
				
				if (data.data.status === 200) {
					resetForm({ values: initialValues});
					setValues(initialValues);
					await dispatch( setFormData(initialValues) );
					await dispatch( setRefreshTable(!refreshTable) );
					await dispatch( setIsOpenModal(false) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingForm(false) );
		}
	};
	
	/**
	 *
	 * @description Execute dispatch formData state
	 * @return dispatch
	 *
	 */
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.name === 'hasChangeLogo' ? !formData.hasChangeLogo : target.value }) );
	
	return (
		<>
			{isLoadingShow && (
				<Feedback.Loading />
			)}
			{!isLoadingShow && (
				<Formik
					initialValues={formData}
					validationSchema={validationSchema}
					onSubmit={hanbleSubmit}
				>
					{({
					  errors,
					  touched,
					  handleChange,
					  handleBlur,
					  isValid,
					  dirty
				  }) => (
						<Form noValidate>
							<div className="row animate__animated animate__fadeIn">
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										name="name"
										label="Nombre"
										placeholder="Ingrese nombre..."
										value={formData.name}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.name && touched.name ? true : false}
										errorMessage={errors.name && touched.name ? errors.name : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										type="email"
										name="email"
										label="Email"
										placeholder="Ingrese email..."
										value={formData.email}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.email && touched.email ? true : false}
										errorMessage={errors.email && touched.email ? errors.email : ''}
										className="font-family-roboto-medium text-dark fs-5 w-100 mt-3"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										type="number"
										name="numberId"
										label="Número"
										placeholder="Ingrese número..."
										value={formData.numberId}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.numberId && touched.numberId ? true : false}
										errorMessage={errors.numberId && touched.numberId ? errors.numberId : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										type="number"
										name="brokerId"
										label="Broker"
										placeholder="Ingrese broker..."
										value={formData.brokerId}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.brokerId && touched.brokerId ? true : false}
										errorMessage={errors.brokerId && touched.brokerId ? errors.brokerId : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										name="apiTokenDomus"
										label="Api Token Domus"
										placeholder="Ingrese api token para Domus..."
										value={formData.apiTokenDomus}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.apiTokenDomus && touched.apiTokenDomus ? true : false}
										errorMessage={errors.apiTokenDomus && touched.apiTokenDomus ? errors.apiTokenDomus : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										type="number"
										name="idDomus"
										label="ID Domus"
										placeholder="Ingrese ID para Domus..."
										value={formData.idDomus}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.idDomus && touched.idDomus ? true : false}
										errorMessage={errors.idDomus && touched.idDomus ? errors.idDomus : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										name="apiTokenPipeDrive"
										label="Api Token Pipedrive"
										placeholder="Ingrese api token para Pipedrive..."
										value={formData.apiTokenPipeDrive}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.apiTokenPipeDrive && touched.apiTokenPipeDrive ? true : false}
										errorMessage={errors.apiTokenPipeDrive && touched.apiTokenPipeDrive ? errors.apiTokenPipeDrive : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										name="subDomainPipeDrive"
										label="Sub-Dominio Pipedrive"
										placeholder="Ingrese sub-dominio para Pipedrive..."
										value={formData.subDomainPipeDrive}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.subDomainPipeDrive && touched.subDomainPipeDrive ? true : false}
										errorMessage={errors.subDomainPipeDrive && touched.subDomainPipeDrive ? errors.subDomainPipeDrive : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12 col-md-6">
									<Inputs.TextBox
										type="color"
										name="color"
										label="Color"
										placeholder="Ingrese broker..."
										value={formData.color}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.color && touched.color ? true : false}
										errorMessage={errors.color && touched.color ? errors.color : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
							</div>
							{formType === 'show' && (
								<div className="row animate__animated animate__fadeIn">
									<div className="col-sm-12 col-sm-6 mt-2">
										<div className="d-block w-100">
											<Inputs.Switch
												label="¿Desea cambiar el logo?"
												name="hasChangeLogo"
												color="success"
												isChecked={formData.hasChangeLogo}
												position="end"
												onChange={handleOnChange}
											/>
										</div>
										{formData.logoPreview !== '' && (
											<div className="d-block w-100">
												<img className="logoRealState" src={formData.logoPreview} alt="logo" />
											</div>
										)}
									</div>
								</div>
							)}
							{(formType === 'store' || (formType === 'show' && formData.hasChangeLogo)) && (
								<div className="row animate__animated animate__fadeIn">
									<div className="col-sm-12 mt-2">
										<Layout.IconButton
											color="inherit"
											size="lg"
											className="bg-primary"
											aria-label="upload picture"
											component="label"
											hasError={errors.logo && touched.logo ? true : false}
											errorMessage={errors.logo && touched.logo ? errors.logo : ''}
										>
											<input
												accept="image/*"
												name="logo"
												onBlur={handleBlur}
												onChange={e => {
													handleOnChange(e)
													handleChange(e)
												}}
												type="file"
												ref={fileRef}
												hidden
											/>
											<Layout.Icons.PhotoCameraIcon
												height={20}
												width={20}
												className="text-white"
											/>
										</Layout.IconButton>
									</div>
								</div>
							)}
							
							{(formType === 'store' ? permissionStore : permissionUpdate) && (
								<div className="row">
									<div className="col-sm-12">
										<Inputs.LoadingButton
											isDisabled={formType === 'store' ? !(dirty && isValid) : false}
											isLoading={isLoadingForm}
											isLoadingPosition="start"
											startIcon={formType === 'store' ? <Layout.Icons.SaveIcon height={18} width={18} /> : <Layout.Icons.EditIcon height={18} width={18} />}
											label={`${formType === 'store' ? 'Guardar' : 'Actualizar'}`}
											type="submit"
											className={`${formType === 'store' ? ((dirty && isValid) && 'bg-primary') : 'bg-primary'} font-family-roboto-regular fs-6 text-capitalize mt-2 d-flex float-end`}
										/>
									</div>
								</div>
							)}
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default FormComponent;
