import { useFormikContext, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FieldErrors from "../../../../../../components/v2-mobili/errors/FieldErrors";

const contactOptions = [
  ...Array(10)
    .fill()
    .map((_, index) => `Fuente ${index + 1}`),
];
const ContactSourceField = () => {
  const { getFieldProps, errors, touched } = useFormikContext();
  return (
    <Grid container flexDirection="column" item>
      <TextField
        select
        error={errors.contactSource && touched.contactSource}
        size="small"
        label="Fuente de contacto"
        {...getFieldProps("contactSource")}
      >
        {contactOptions.map((option, key) => (
          <MenuItem key={key} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <FieldErrors>
        <ErrorMessage name="contactSource" />
      </FieldErrors>
    </Grid>
  );
};

export default ContactSourceField;
