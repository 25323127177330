import axiosServices from '../services/axios'

export const getFilterData = async (filterId,source) => {
  try {
    const data = await axiosServices.get(`/filtered/${filterId}?source=${source}`)
    return data
  } catch (error) {
    return error.response
  }
}
