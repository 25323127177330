import React from 'react'
import SendOutlined from '@material-ui/icons/SendOutlined'

export default function SendOutlinedIcon(props) {

    const { ...other } = props

    return (
        <SendOutlined { ...other } />
    )
}
