import * as types from '../types/verificationType';

const initialState = {
	colorStatus: '',
	textStatus: '',
	isActiveAlert: false,
	isActiveButton: false,
	isLoadingForm: false
};

const verificationReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.COLOR_STATUS_VERIFICATION:
			return {
				...state,
				colorStatus: payload
			};
		
		case types.TEXT_STATUS_VERIFICATION:
			return {
				...state,
				textStatus: payload
			};
		
		case types.IS_ACTIVE_ALERT_VERIFICATION:
			return {
				...state,
				isActiveAlert: payload
			};
		
		case types.IS_ACTIVE_BUTTON_VERIFICATION:
			return {
				...state,
				isActiveButton: payload
			};
		
		case types.IS_LOADING_FORM_VERIFICATION:
			return {
				...state,
				isLoadingForm: payload
			};
		
		default:
			return state;
	}
};

export default verificationReducer;
