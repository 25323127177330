import * as Yup from "yup";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";
import AppointedOfficialField from "./AppointedOfficialField";
import { BottomActionStyles, ButtonsCommonStyles } from "./Styles";

const btnProps = ButtonsCommonStyles;

const validationSchema = Yup.object().shape({
  official: Yup.string()
    .required("Se requiere el funcionario designado")
    .nullable(),
  rating: Yup.number().min(1).max(5).optional().nullable(),
});

const SendToCommercialLeftSection = () => {
  const initialValues = {
    official: null,
    rating: null,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, {}) => {
        console.log(values);
      }}
    >
      {({ submitForm, values, setFieldValue }) => (
        <Form>
          <Paper sx={{ ...BottomActionStyles, flexDirection: "column" }}>
            <Grid
              container
              gap={2}
              display="grid"
              gridTemplateColumns="max-content 1fr"
              alignItems="center"
            >
              <Grid item>
                <Rating
                  sx={{ color: "secondary.main" }}
                  name="no-value"
                  value={values.rating}
                  onChange={(_, newValue) => setFieldValue("rating", newValue)}
                />
              </Grid>
              <Grid item>
                <AppointedOfficialField />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={2} mt={2}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                {...btnProps}
              >
                Desechar
              </Button>
              <Button
                fullWidth
                variant="contained"
                disableElevation
                {...btnProps}
                onClick={() => submitForm()}
              >
                Enviar a Comercial
              </Button>
            </Stack>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default SendToCommercialLeftSection;
