export const CommonSmallLabelStyles = {
	color: "darkGrey.default",
	fontWeight: 600,
	fontSize: 14 / 16 + "rem",
};

export const OptionsWrapperStyles = {
	display: "flex",
	borderRadius: "3px",
	border: ({ palette }) => `1.5px solid ${palette.mediumGrey.default}`,
};

export const AdormentBtnStyle = {
	width: "25px",
	aspectRatio: 1,
	display: "flex",
	cursor: "pointer",
	borderRadius: "4px",
	position: "relative",
	justifyContent: "center",
	backgroundColor: "lightGrey.default",
	"&:hover": {
		backgroundColor: "mediumGrey.default",
	},
	"&:active": {
		backgroundColor: "dark.default",
		color: "lightestGrey.default",
	},
};

export const ButtonsCommonStyles = {
	size: "large",
	sx: { fontWeight: 700, borderRadius: "2px" },
};

export const BottomActionStyles = {
	px: ({ spacing }) => spacing(35 / 8),
	pt: ({ spacing }) => spacing(20 / 8),
	pb: ({ spacing }) => spacing(25 / 8),
	
	gag: 1,
	display: "flex",
	justifyContent: "space-between",
	borderTop: ({ palette }) => `2px solid ${palette.lightGrey.default}`,
	boxShadow: "0px 0px 5px 5px rgba(15, 23, 42, 0.1)",
	// gridTemplateColumns:"1fr max-content max-content",
};
