import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100% !important',
        '& .MuiFormLabel-root.Mui-focused': {
            fontWeight: '500',
            color: '#1b1b1b !important',
            fontSize: '18px'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid #85BF02 !important'
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: '1px solid #85BF02 !important'
        }
    }
}))

export default function DatePicker(props) {
    const classes = useStyles()
    const { label, name, defaultValue, error, message, ...other } = props

    return (
        <TextField
            name={name}
            label={label}
            value={defaultValue}
            defaultValue={defaultValue}
            error={error || false}
            helperText={message || ""}
            type="date"
            classes={{ root: classes.root }}
            InputLabelProps={{
                shrink: true
            }}
            {...other}
        />
    )
}