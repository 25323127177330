import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../../../../components/mui/Layout';
import Inputs from '../../../../../components/mui/Inputs';
import DropdownActionComponent from './DropdownActionComponent';
import { getResendEmailById, setUpdateStatusById } from '../services';
import { setIsOpenModal, setRefreshTable, setIsOpenModalPermission, setFormData, setFormType, setIsLoadingShow, setIsLoadingForm } from '../redux/actions/userAction';
import { setActivityLogReset, setIsOpenModal as setIsOpenModalActivity, setLogName, setSubjectId } from '../../../../../redux/actions/dashboard/activities/activityLogAction';
import swalErrors from '../../../../../hooks/useErrors';
import { swalSuccess } from '../../../../../hooks/useSweetAlert';
import usePermission from '../../../../../hooks/usePermission';
import avatarDefault from '../../../../../assets/images/avatar-default.jpg';

const TableRowComponent = (props) => {
	const {
		id,
		avatar,
		firstName,
		lastName,
		email,
		role,
		realState,
		status,
		verified,
		permissions = []
	} = props;
	const dispatch = useDispatch();
	const userData = useSelector(({ auth }) => auth.userData);
	const refreshTable = useSelector(({ user }) => user.refreshTable);
	const permissionResendEmail = usePermission('users.resendEmail');
	const permissionStatus = usePermission('users.status');
	
	useEffect(() => {
	}, [
		id,
		avatar,
		firstName,
		lastName,
		email,
		role,
		realState,
		status,
		verified,
		permissions
	]);
	
	/**
	 *
	 * @description Execute dispatch open modal, reset formData, loading show, loading form, formType and activityLog state
	 * @return dispatch
	 *
	 */
	const handleIsOpenModal = async (formType) => {
		if (formType === 'activity-log') {
			await dispatch( setActivityLogReset() )
			await dispatch( setLogName('Usuarios') )
			await dispatch( setSubjectId(id) )
			await dispatch( setIsOpenModalActivity(true) )
		} else {
			const object = {
				id: id,
				firstName: firstName,
				lastName: lastName,
				permissions: permissions.length === 0 ? [] : permissions,
			};
			
			await dispatch( setFormData(object) );
			await dispatch( setFormType(formType) );
			await dispatch( setIsLoadingForm(false) );
			
			if (formType === 'show' || formType === 'delete') {
				await dispatch( setIsLoadingShow(false) );
				await dispatch( setIsOpenModal(true) );
			}
			
			if (formType === 'permission') {
				await dispatch( setIsOpenModalPermission(true) );
			}
		}
	};
	
	/**
	 *
	 * @description Execute endpoint resend email verification
	 * @return dispatch
	 *
	 */
	const handleResendEmailById = async () => {
		if (permissionResendEmail) {
			try {
				const { data } = await getResendEmailById(id);
				
				if (data.status === 200) {
					await swalSuccess('Reenvío de email', data.data);
					await dispatch( setRefreshTable(!refreshTable) );
				}
			} catch (error) {
				await swalErrors(error);
			}
		}
	};
	
	/**
	 *
	 * @description Execute endpoint change status
	 * @return dispatch
	 *
	 */
	const handleUpdateStatusById = async () => {
		if (permissionStatus) {
			try {
				const { data } = await setUpdateStatusById(id);
				
				if (data.status === 200) {
					await swalSuccess('Cambio de estatus', data.data);
					await dispatch( setRefreshTable(!refreshTable) );
				}
			} catch (error) {
				await swalErrors(error);
			}
		}
	};
	
	return (
		<tr>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="5%"
			>
				<div className="mx-auto justify-content-center text-center">
					{verified === 'Verified' && (
						<Layout.Tooltip
							title="Usuario verificado"
							placement="top"
						>
							<Layout.Icons.VerifiedUserIcon
								height={18}
								width={18}
								className="text-primary"
							/>
						</Layout.Tooltip>
					)}
				</div>
			</td>
			<td
				className="m-0 pt-1 pb-1 pl-2 pr-2"
				width="5%"
			>
				<div className="mx-auto justify-content-center text-center">
					<Layout.Avatar
						alt="avatar"
						src={avatar === null ? avatarDefault : avatar}
						size="sm"
						icon={false}
					/>
				</div>
			</td>
			{userData.roles[0] === 'Super Administrador' && (
				<td
					className="m-0 pt-0 pb-0 pl-2 pr-2"
					width="20%"
				>
					<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
						{ realState === null ? '' : realState }
					</div>
				</td>
			)}
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width={userData.roles[0] === 'Super Administrador' ? '30%' : '40%'}
			>
				<div className="fs-6 text-dark text-wrap">
					<div className="font-family-roboto-regular">{ firstName } { lastName }</div>
					<div className="font-family-roboto-semibold">Rol: { role[0] }</div>
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width={userData.roles[0] === 'Super Administrador' ? '25%' : '35%'}
			>
				<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
					{ email }
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="10%"
			>
				<div className="text-center">
					<Inputs.Switch
						name="status"
						color={status === 'Active' ? 'success' : 'error'}
						isChecked={status === 'Active' ? true : false}
						position="end"
						onChange={handleUpdateStatusById}
					/>
				</div>
			</td>
			<td
				className="m-0 pt-0 pb-0 pl-2 pr-2"
				width="5%"
			>
				<div className="mx-auto d-flex justify-content-center">
					<DropdownActionComponent
						verified={verified}
						handleResendEmailById={handleResendEmailById}
						handleIsOpenModal={handleIsOpenModal}
					/>
				</div>
			</td>
		</tr>
	);
};

TableRowComponent.propTypes = {
	id: PropTypes.number.isRequired,
	avatar: PropTypes.string,
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	role: PropTypes.array.isRequired,
	realState: PropTypes.string,
	status: PropTypes.string.isRequired,
	verified: PropTypes.string.isRequired,
	permissions: PropTypes.array
};

export default TableRowComponent;
