import React, { useState, useEffect } from 'react'
import {useSelector} from 'react-redux'
import Grid from '@material-ui/core/Grid'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Controls from "../../../../../components/controls/Controls"
import Display from "../../../../../components/display/Display"
import Feedback from "../../../../../components/feedback/Feedback"
import errorServices from '../../../../../services/errors'
import { swalSuccess } from '../../../../../services/sweetalert'
import axios from '../../../../../services/axios'

const headCells = [
	{ id: 'actions', label: 'Acciones', align: 'center', width: '10%' },
	{ id: 'type', label: 'Tipo de Persona', align: 'center', width: '15%' },
	{ id: 'name', label: 'Nombre completo ó Razón social', align: 'left', width: '30%' },
	{ id: 'email', label: 'Email', align: 'left', width: '15%' },
	{ id: 'doc', label: 'Documento', align: 'center', width: '15%' },
	{ id: 'mobile', label: 'Celular', align: 'center', width: '15%' }
]

const arrayType = [
	{ id: 'Natural', name: 'Natural' },
	{ id: 'Jurídica', name: 'Jurídica' }
]

const FormList = ({ active, handleStoreItems }) => {
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [getLoading, setLoading] = useState(true)
	const [getRecords, setRecords] = useState([])
	const [getPages, setPages] = useState(0)
	const [getTotalRows, setTotalRows] = useState(0)
	const [getPage, setPage] = useState(1)
	const [getSearch, setSearch] = useState('')
	const [arrayPersonType, setArrayPersonType] = useState(arrayType)
	const [personType, setPersonType] = useState('Natural')
	
	const {
		TblContainer,
		TblHead,
	} = Display.Table(headCells, getRecords)
	
	const handleList = async () => {
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				page : getPage,
				perPage: 10,
				person_type: personType,
				search : getSearch === '' ? null : getSearch
			}
			
			const data = await axios.post(`dashboard/owners/list`, param)
			
			if (data.status === 200) {
				setPages(data.data.data.pages)
				setTotalRows(data.data.data.totalRows)
				setRecords(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	useEffect(() => {
		handleList()
	}, [getPage, personType, active])
	
	const handleChangePage = (value) => {
		setPage(value)
	}
	
	const handleSearch = event => {
		if (event.charCode === 13) {
			handleList()
		}
	}
	
	return (
		<>
			<Grid container spacing={2} className="m-0 animate__animated animate__fadeIn">
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={4}>
							<Controls.Select
								label="Tipo de persona"
								name="type"
								defaultValue={personType}
								onChange={e => setPersonType(e.target.value)}
								arrayDropdown={arrayPersonType}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Controls.TextFieldSearch
								label="Buscar..."
								name="search"
								defaultValue={getSearch}
								onChange={e => setSearch(e.target.value)}
								onKeyPress={handleSearch}
							/>
						</Grid>
					</Grid>
				</Grid>
				{getTotalRows > 20 && (
					<Grid item xs={12} className="m-0 animate__animated animate__fadeIn">
						<Display.Pagination
							pageCount={getPages}
							pageIndex={getPage}
							handleChangePage={handleChangePage}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<TblContainer>
						<TblHead />
						<TableBody>
							{getLoading && (
								<TableRow>
									<TableCell align="center" width="10%">
										<Feedback.Loading type="text" animation="wave" width="100%" height="30px" />
									</TableCell>
									<TableCell align="center" width="15%">
										<Feedback.Loading type="text" animation="wave" width="100%" height="30px" />
									</TableCell>
									<TableCell align="center" width="30%">
										<Feedback.Loading type="text" animation="wave" width="100%" height="30px" />
									</TableCell>
									<TableCell align="center" width="15%">
										<Feedback.Loading type="text" animation="wave" width="100%" height="30px" />
									</TableCell>
									<TableCell align="center" width="15%">
										<Feedback.Loading type="text" animation="wave" width="100%" height="30px" />
									</TableCell>
									<TableCell align="center" width="15%">
										<Feedback.Loading type="text" animation="wave" width="100%" height="30px" />
									</TableCell>
								</TableRow>
							)}
							{!getLoading && (
								getRecords.length > 0 ?
									getRecords.map((item, index) => (
										<TableRow key={index}>
											<TableCell align="center" width="10%">
												<Display.Tooltip
													label="Agregar"
													placement="top"
												>
													<IconButton
														type="link"
														className="color-dark"
														onClick={() => handleStoreItems(item.id)}
													>
														<Display.Icons.AddOutlinedIcon style={{ fontSize: 24 }} />
													</IconButton>
												</Display.Tooltip>
											</TableCell>
											<TableCell align="center" width="15%">{item.person_type}</TableCell>
											{item.person_type === 'Natural' && (
												<TableCell align="left" width="30%">
													{item.first_name} {item.last_name}
												</TableCell>
											)}
											{item.person_type === 'Jurídica' && (
												<TableCell align="left" width="30%">
													{item.company}
												</TableCell>
											)}
											<TableCell align="left" width="15%">{item.email}</TableCell>
											<TableCell align="center" width="15%">{item.doc_type}: {item.doc_id}</TableCell>
											<TableCell align="center" width="15%">{item.mobile}</TableCell>
										</TableRow>
									))
									:
									(<TableRow>
										<TableCell colSpan={headCells.length} align="center" width="100%">No hay registros</TableCell>
									</TableRow>)
							)}
						</TableBody>
					</TblContainer>
				</Grid>
				{getTotalRows > 20 && (
					<Grid item xs={12} className="m-0 animate__animated animate__fadeIn">
						<Display.Pagination
							pageCount={getPages}
							pageIndex={getPage}
							handleChangePage={handleChangePage}
						/>
					</Grid>
				)}
			</Grid>
			<Feedback.DialogLoading
				getLoading={getLoading}
				setLoading={setLoading}
			/>
		</>
	)
}

export default FormList
