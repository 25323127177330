import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Stack, Button, Chip, styled } from '@mui/material'
import Layout from '../mui/Layout'
import Feedback from '../mui/Feedback'
import {
  verificationByMobile,
  setIsActiveHistorical,
} from '../../redux/actions/dashboard/filtered/filteredAction'
// src\pages\owners\views\inventoryPage\img\index_.jsx
import images from '../v2-mobili/img'
import ListIconsDetails from '../properties/icons/ListIconsDetails/ListIconsDetails'
import { convertFirstLetterToUppercase } from '../../helpers/functions'

const ChipCustom = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'myPropBackground',
})(({ theme, myPropBackground }) => ({
  backgroundColor: myPropBackground
    ? myPropBackground
    : theme.palette.mobiligrey.default,
  color: theme.palette.white.default,
  fontWeight: 700,
  fontSize: '12px',
  height: '20px',
  padding: '0 12px',
  marginBottom: '5px',
}))

const Information = (props) => {
  const dispatch = useDispatch()
  const isActiveHistorical = useSelector(
    (state) => state.filtered.isActiveHistorical,
  )

  const {
    isLoading,
    createdDate,
    createdAt,
    createdAgo,
    title,
    hasMobile = false,
    hasHistoricalButton = false,
    hasAddress = false,
    hasCityNeighborhood = false,
    hasRanking = false,
    hasContact = false,
    mobile = '',
    address = '',
    city = '',
    neighborhood = '',
    ranking = '',
    originChannel = '',
    contact = '',
  } = props

  useEffect(() => {}, [
    isLoading,
    createdDate,
    createdAt,
    createdAgo,
    title,
    hasMobile,
    hasHistoricalButton,
    hasAddress,
    hasCityNeighborhood,
    hasRanking,
    hasContact,
    mobile,
    address,
    city,
    neighborhood,
    ranking,
    originChannel,
    contact,
  ])

  const hanbleValidate = async () => {
    if (hasHistoricalButton) {
      dispatch(setIsActiveHistorical(!isActiveHistorical))
      await dispatch(verificationByMobile(mobile))
    }
  }

  return (
    <>
      <Layout.Paper sx={Styles}>
        <div style={StylesContent}>
          <Grid container flexDirection="column" gap={5 / 8}>
            <Grid item>
              {isLoading && (
                <Feedback.Skeleton variant="text" width="80%" height={50} />
              )}
              {!isLoading && (
                <div className="font-family-roboto-semibold fs-2 text-dark">
                  {title}
                </div>
              )}
            </Grid>

            {hasMobile && (
              <Stack direction="row" spacing={10 / 8} alignItems="center">
                <Layout.Icons.PhoneIcon height={26} width={26} />
                {isLoading && (
                  <Feedback.Skeleton variant="text" width="50%" height={40} />
                )}
                {!isLoading && (
                  <div className="font-family-roboto-semibold fs-4 text-dark">
                    {mobile}
                  </div>
                )}
                {hasHistoricalButton && (
                  <Button
                    variant="contained"
                    disableElevation
                    color="buttonGreyContained"
                    sx={{
                      fontWeight: '700',
                      textTransform: 'capitalize',
                      fontSize: '1rem',
                    }}
                    startIcon={
                      isActiveHistorical ? (
                        <Layout.Icons.NavigateBeforeIcon
                          height={18}
                          width={18}
                        />
                      ) : (
                        ''
                      )
                    }
                    endIcon={
                      !isActiveHistorical ? (
                        <Layout.Icons.ChevronRightIcon height={18} width={18} />
                      ) : (
                        ''
                      )
                    }
                    onClick={hanbleValidate}
                  >
                    {isActiveHistorical ? 'Regresar' : 'Historial'}
                  </Button>
                )}
              </Stack>
            )}
            <Stack direction="row" spacing={10 / 8} alignItems="center">
              {isLoading && (
                <Feedback.Skeleton variant="text" width="75%" height={40} />
              )}
              {!isLoading && (
                <div className="font-family-roboto-regular fs-5 text-dark w-100">
                  <ChipCustom label="Guardado como borrador" />
                </div>
              )}
            </Stack>

            {hasAddress && (
              <Stack direction="row" spacing={10 / 8} alignItems="center">
                {isLoading && (
                  <Feedback.Skeleton variant="text" width="75%" height={40} />
                )}
                {!isLoading && (
                  <div className="font-family-roboto-regular fs-5 text-dark w-100">
                    {address}
                  </div>
                )}
              </Stack>
            )}
            {hasCityNeighborhood && (
              <Stack direction="row" spacing={10 / 8} alignItems="center">
                {isLoading && (
                  <Feedback.Skeleton variant="text" width="75%" height={25} />
                )}
                {!isLoading && (
                  <>
                    <div className="font-family-roboto-semibold fs-6 text-grey d-inline">
                      Ciudad:
                    </div>
                    <div className="font-family-roboto-regular fs-6 ml-2 text-dark d-inline">
                      {city}
                    </div>
                  </>
                )}
              </Stack>
            )}
          </Grid>

          <Stack textAlign="right" sx={stylesStack}>
            {isLoading && (
              <Feedback.Skeleton
                variant="text"
                width="75%"
                height={25}
                className="d-flex mx-auto"
              />
            )}
            {!isLoading && (
              <div className="font-family-roboto-semibold text-dark-light">
                <Layout.Timezone
                  date={createdDate}
                  dateAt={createdAt}
                />
              </div>
            )}
            {isLoading && (
              <Feedback.Skeleton
                variant="text"
                width="75%"
                height={25}
                className="d-flex mx-auto"
              />
            )}
            {!isLoading && (
              <div className="font-family-roboto-semibold text-dark-light">
                {convertFirstLetterToUppercase(createdAgo)}
              </div>
            )}
            {hasContact && (
              <>
                {isLoading && (
                  <Feedback.Skeleton
                    variant="text"
                    width="75%"
                    height={25}
                    className="d-flex mx-auto"
                  />
                )}
                {!isLoading && (
                  <div className="font-family-roboto-semibold text-dark-light">
                    Canal de origen: {originChannel}
                  </div>
                )}
                {isLoading && (
                  <Feedback.Skeleton
                    variant="text"
                    width="75%"
                    height={25}
                    className="d-flex mx-auto"
                  />
                )}
                {!isLoading && (
                  <div className="font-family-roboto-semibold text-dark-light">
                    Fuente de contacto: {contact}
                  </div>
                )}
              </>
            )}
            {hasCityNeighborhood && (
              <Stack direction="row" spacing={2} textAlign="right">
                <Grid container display="grid">
                  {isLoading && (
                    <Feedback.Skeleton
                      variant="text"
                      width="75%"
                      height={25}
                      className="d-flex mx-auto"
                    />
                  )}
                  {!isLoading && (
                    <>
                      <div className="font-family-roboto-semibold fs-6 text-grey">
                        Barrio:
                        <span className="font-family-roboto-regular fs-6 ml-2 text-dark">
                          {neighborhood}
                        </span>
                      </div>
                    </>
                  )}
                </Grid>
              </Stack>
            )}
          </Stack>
        </div>
      </Layout.Paper>
    </>
  )
}

/**
 * @type {React.CSSProperties}
 */
const Styles = {
  padding: ({ spacing }) => spacing(20 / 8, 35 / 8),
  boxShadow: ` 0px 0px 1px rgba(15, 23, 42, 0.03),
   0px 4px 6px -1px rgba(15, 23, 42, 0.05),
    0px 0px 5px 5px rgba(15, 23, 42, 0.03)`,
}
const StylesContent = {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '2fr 1fr',
}
const stylesStack = {
  fontSize: '12px',
}

Information.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  createdDate: PropTypes.string.isRequired,
  createdAt: PropTypes.any.isRequired,
  createdAgo: PropTypes.string.isRequired,
  title: PropTypes.string,
  hasMobile: PropTypes.bool,
  hasHistoricalButton: PropTypes.bool,
  hasAddress: PropTypes.bool,
  hasCityNeighborhood: PropTypes.bool,
  hasRanking: PropTypes.bool,
  hasContact: PropTypes.bool,
  mobile: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  neighborhood: PropTypes.string,
  ranking: PropTypes.any,
  originChannel: PropTypes.string,
  contact: PropTypes.string,
}

export default Information
