import React from 'react'
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck'

export default function PlaylistAddCheckIcon(props) {

    const { ...other } = props

    return (
        <PlaylistAddCheck { ...other } />
    )
}
