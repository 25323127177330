import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const usePermission = (param = '') => {
	const { permissions } = useSelector(({ auth }) => auth.userData);
	const [permission] = useState(permissions.find(value => value === param) ? true : false);
	
	return permission;
};

usePermission.propTypes = {
	param: PropTypes.string
};

export default usePermission;
