import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Feedback from '../../../../components/mui/Feedback'
import { TextFieldReadOnly } from '../../../owners/views/inventoryPage/components/fields'
import styles from './propertyForm.module.css'

const PropertyForm = ({ formData }) => {
  const rentalService = formData?.service === 'Arriendo' ?? false
  const servicePurchase = formData?.service === 'Compra' ?? false
  const uptakeService = formData?.service === 'Captación' ?? false
  const projectService = formData?.service === 'Proyecto' ?? false
  
  useEffect(() => {
  }, [formData])
  
  // Obtain the value of the budget
  const budget = formData?.service === 'Compra' ? (formData.range_total_buy === null ? '' : formData.range_total_buy) : (formData.range_total_lease === null ? '' : formData.range_total_lease)
  
  return (
    // <Feedback.ScrollBar style={{ height: '400px', overflowY: 'none' }}>
      <div className={styles.cardForm}>
        <div className={styles.dFlexBetween}>
          {/* <TextFieldReadOnly label={'Venta'} value={`$${formData?.sale} COP`} /> */}
          <TextFieldReadOnly
            label={'Correo electrónico'}
            value={formData.email === null ? '' : formData.email}
          />
          <TextFieldReadOnly
            label={'Teléfono 2'}
            value={formData.phone === null ? '' : formData.phone}
          />
        </div>

        {(rentalService || servicePurchase) && (
          <div className={styles.dFlexBetween}>
            <TextFieldReadOnly
              label={`Presupuesto de ${formData.service === null ? '' : formData.service}`}
              value={budget === null ? '' :`$${budget} COP`}
            />
            {rentalService && (
              <TextFieldReadOnly
                label={'Fecha estimada de mudanza'}
                value={formData.lease_date === null ? '' : formData.lease_date}
              />
            )}
          </div>
        )}

        <div className={styles.dFlexBetween}>
          <TextFieldReadOnly label={'Ciudad'} value={formData.cities === null ? '' : formData.cities} />
          <TextFieldReadOnly
            label={'Barrio'}
            value={formData.neighborhoods === null ? '' : formData.neighborhoods}
          />
        </div>
        <div className={styles.dFlexBetween}>
          <TextFieldReadOnly
            label={'Tipo de propiedad'}
            value={formData.types === null ? '' : formData.types}
          />
        </div>
        <div className={styles.dFlexBetween}>
          <TextFieldReadOnly
            label={'Solicitud'}
            value={
              formData?.service
                ? `${formData?.service} #000${formData.number_count === null ? '' : formData.number_count}`
                : ''
            }
          />
          <TextFieldReadOnly
            label={'Requisitos'}
            value={formData.requirement === null ? '' : formData.requirement}
          />
        </div>
        {uptakeService && (
          <>
            <div className={styles.dFlexBetween}>
              <>
                <TextFieldReadOnly
                  label={'Precio'}
                  value={formData.price === null ? '' : `$${formData.price} COP`}
                />
                <TextFieldReadOnly
                  label={'Fecha de estimada de desocupación'}
                  value={formData.empty_date === null ? '' : formData.empty_date}
                />
              </>
            </div>
          </>
        )}
        {(servicePurchase || projectService) && (
          <div className={styles.dFlexBetween}>
            <>
              {projectService && (
                <TextFieldReadOnly
                  label={'Estimación'}
                  value={formData.estimation === null ? '' : `$${formData.estimation} COP`}
                />
              )}
              <TextFieldReadOnly
                label={'Método de pago'}
                value={formData.payment_method === null ? '' : formData.payment_method}
              />
            </>
          </div>
        )}
        
        <section className={styles.box}>
          <h5 className={styles.boxTitle}>Observaciones:</h5>
          <p>{formData.observation === null ? '' : formData.observation}</p>
        </section>
      </div>
    // </Feedback.ScrollBar>
  )
}

PropertyForm.propTypes = {
  formData: PropTypes.object.isRequired
}

export default PropertyForm
