import React, { useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { NumericFormat } from 'react-number-format'
import { Grid, Box, Stack, styled, InputAdornment, InputLabel, TextField } from '@mui/material'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import { getArrayToDropdownCity } from '../../../../../redux/actions/algolia/cities/algoliaCityAction'
import { getArrayToDropdownNeighborhood, setSearch } from '../../../../../redux/actions/algolia/negihborhoods/algoliaNeighborhood'
import { getArrayToDropdownPropertyType } from '../../../../../redux/actions/algolia/propertyTypes/algoliaPropertyTypeAction'
import { setPage } from '../../../../../redux/actions/algolia/properties/algoliaPropertyAction'
import { setFormDataDetail, setIsActiveSearch, updateById } from '../../../../../redux/actions/dashboard/filtered/filteredAction'
import { Permission } from '../../../../../services/permissions/Permission'
import { filteredServiceSwitch, filteredStrataSwitch, filteredPaymentMethod } from '../../../../../helpers/variablesColumns'
import { CommonSmallLabelStyles, OptionsWrapperStyles } from './Styles'
import { AdormentBtnStyle, BottomActionStyles } from './Styles'

const divider = {
  pb: 20 / 8,
  borderBottom: ({ palette }) => `1px solid ${palette.mediumGrey.default}`,
}

const commomOptionTypes = {
  flexGrow: 1,
  display: 'flex',
  cursor: 'pointer',
  minHeight: 'auto',
  userSelect: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'inherit',
}

export const OptionType = styled(Box)(({ theme, active }) => ({
  padding: theme.spacing(5 / 8, 10 / 8),
  ...commomOptionTypes,
  minHeight: '40px',
  fontFamily: theme.typography.fontFamily,
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.standard,
  }),
  ...(active && {
    fontWeight: 700,
    color: theme.palette.white.default,
    backgroundColor: theme.palette.darkGrey.default,
    borderRadius: '2px',
  }),
}))

const FormComponent = ({ isActive, isCommercial }) => {
  const dispatch = useDispatch()
  const isLoadingSearch = useSelector(state => state.algoliaProperty.isLoading)
  const isLoadingForm = useSelector(state => state.filtered.isLoadingForm)
  const isActiveSearch = useSelector( state => state.filtered.isActiveSearch)
  const formType = useSelector(state => state.filtered.formType)
  const formData = useSelector(state => state.filtered.formDataDetail)
  const arrayListToDropdownCity = useSelector(state => state.algoliaCity.arrayListToDropdown)
  const arrayListToDropdownNeighborhood = useSelector(state => state.algoliaNeighborhood.arrayListToDropdown)
  const arrayListToDropdownPropertyType = useSelector(state => state.algoliaPropertyType.arrayListToDropdown)
  const searchNeighborhood = useSelector(state => state.algoliaNeighborhood.search)

  useEffect(() => {
  }, [isActive])
  
  /**
   *
   * @description Validation schema
   * @return object
   *
   */
  const validationSchema = () => (
    Yup.object({
      email: Yup.string()
        .email('El email no es válido')
        .required('El email es requerido'),
    })
  )
  
  /**
   *
   * @description Execute endpoint array list in dropdown of cities algolia
   * @return dispatch
   *
   */
  const handleDropdownCity = async () =>
    await dispatch(getArrayToDropdownCity())

  useEffect(() => {
    if (isActive) handleDropdownCity()
  }, [])
  
  /**
   *
   * @description Execute endpoint array list in dropdown of neighborhoods algolia
   * @return dispatch
   *
   */
  const handleDropdownNeighborhood = async () => {
    const param = {
      city: formData.city,
      search: searchNeighborhood
    }
    await dispatch(getArrayToDropdownNeighborhood(formData.city))
  }

  useEffect(() => {
    if (isActive) handleDropdownNeighborhood()
  }, [formData.city, searchNeighborhood])
  
  /**
   *
   * @description Execute endpoint array list in dropdown of properties algolia
   * @return dispatch
   *
   */
  const handleDropdownPropertyType = async () =>
    await dispatch(getArrayToDropdownPropertyType())

  useEffect(() => {
    if (isActive) handleDropdownPropertyType()
  }, [])
  
  /**
   *
   * @description Execute search state of neighborhood algolia
   * @return dispatch
   *
   */
  const handleChangeSearchNeighborhood = (value) => dispatch(setSearch(value))

  useEffect(() => {
    if (formData.neighborhoods.length === 0) {
      dispatch(setSearch(''))
    }
  }, [formData.neighborhoods])
  
  /**
   *
   * @description Execute dispatch formDataDetail state
   * @return dispatch
   *
   */
  const handleOnChange = (attr, value) => {
    let array = []

    if (attr === 'strata' || attr === 'paymentMethod' || attr === 'arrayListCode') {
      if (attr === 'strata') {
        array = [...formData.strata]
      } else if (attr === 'paymentMethod') {
        array = [...formData.paymentMethod]
      } else if (attr === 'arrayListCode') {
        array = [...formData.arrayListCode]
      }

      if (array.length > 0) {
        const find = array.find(({ id }) => id === value)

        if (find) {
          array.map(({ id }, index) => {
            if (id === value) array.splice(index, 1)
          })
        } else {
          array.push({ id: value, name: value })
        }
      } else {
        array.push({ id: value, name: value })
      }
    }

    if (attr === 'city') {
      dispatch(setFormDataDetail({ ...formData, neighborhoods: [] }))
    }
    
    dispatch(
      setFormDataDetail({
        ...formData,
        [attr]: attr === 'strata' || attr === 'paymentMethod' || attr === 'arrayListCode' ? array : value
      })
    )
  }

  useEffect(() => {}, [formData])
  
  /**
   *
   * @description Execute dispatch increase state
   * @return dispatch
   *
   */
  const increase = (attr) => {
    let number = ''
  
    if (formData[attr] >= 1) {
      number = parseInt(formData[attr]) + 1
    } else if (formData[attr] === '' || formData[attr] <= 0 || formData[attr] === null) {
      number = 1
    }
    
    handleOnChange(attr, number)
  }
  
  /**
   *
   * @description Execute dispatch decrease state
   * @return dispatch
   *
   */
  const decrease = (attr) => {
    let number = ''
  
    if (formData[attr] >= 1) {
      number = parseInt(formData[attr]) - 1
    }
    
    handleOnChange(attr, number)
  }
  
  /**
   *
   * @description Execute submit of the form
   * @param status
   * @return dispatch
   *
   */
  const hanbleSubmit = async (status) => {
    const param = {
      email: formData.email === '' ? null : formData.email,
      phone: formData.phone === '' ? null : formData.phone,
      service: formData.service === '' ? 'Arriendo' : formData.service,
      code: formData.arrayListCode.length === 0 ? [] : formData.arrayListCode,
      city: formData.city === '' ? 1 : formData.city,
      neighborhoods: formData.neighborhoods.length === 0 ? [] : formData.neighborhoods,
      property_types: formData.propertyTypes.length === 0 ? [] : formData.propertyTypes,
      strata: formData.strata.length === 0 ? [] : formData.strata,
      room: formData.numberRoom === '' ? null : formData.numberRoom,
      bathroom: formData.numberBathRoom === '' ? null : formData.numberBathRoom,
      area_total: formData.areaTotal === '' ? null : formData.areaTotal,
      range_total_lease: formData.rangeTotalLease === '' ? null : formData.rangeTotalLease,
      range_total_buy: formData.rangeTotalBuy === '' ? null : formData.rangeTotalBuy,
      price: formData.price === '' ? null : formData.price,
      estimation: formData.estimation === '' ? null : formData.estimation,
      lease_date: formData.leaseDate === '' ? null : formData.leaseDate,
      empty_date: formData.emptyDate === '' ? null : formData.emptyDate,
      payment_method: formData.paymentMethod.length === 0 ? [] : formData.paymentMethod,
      requirement: formData.parkingCheck ? 'Si' : 'No',
      parking: formData.parkingCheck ? 'Si' : 'No',
      observation: formData.observation === '' ? null : formData.observation,
      status: status,
    }

    await dispatch(updateById(formData.id, param, status))
  }

  const handleKeyPress = ({ charCode }) => charCode === 13 && handleOnChange('arrayListCode', formData.code)
  
  const handleSearch = () => {
    dispatch( setPage(1) )
    dispatch( setIsActiveSearch(!isActiveSearch) )
  }

  return (
    <Permission permission="filtereds.show">
      {isActive && (
        <Layout.Box className="animate__animated animate__fadeIn">
          <Formik
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={() => {}}
          >
            {({ errors, handleChange, handleBlur, isValid }) => (
              <Form noValidate>
                <Layout.Box flexGrow={1} className="p-3">
                  <Stack direction="row" spacing={2} sx={divider}>
                    <Grid container flexDirection="column" item>
                      <Inputs.TextBox
                        type="email"
                        variant="outlined"
                        size="small"
                        name="email"
                        label="Email"
                        placeholder="Ingrese email..."
                        hasLabelFixed={true}
                        value={formData.email}
                        onChange={(e) => {
                          handleOnChange('email', e.target.value)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        hasError={errors.email ? true : false}
                        errorMessage={errors.email ? errors.email : ''}
                        className="font-family-roboto-medium fs-6 text-dark w-100"
                      />
                    </Grid>
                    <Grid container flexDirection="column" item>
                      <Inputs.TextBox
                        type="text"
                        variant="outlined"
                        size="small"
                        name="phone"
                        label="Teléfono"
                        placeholder="Ingrese teléfono..."
                        hasLabelFixed={true}
                        value={formData.phone}
                        onChange={(e) => {
                          handleOnChange('phone', e.target.value)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        className="font-family-roboto-medium fs-6 text-dark w-100"
                      />
                    </Grid>
                  </Stack>

                  <Grid
                    container
                    gap={15 / 8}
                    display="grid"
                    alignItems="center"
                    gridTemplateColumns="max-content 1fr"
                    className="mt-3"
                  >
                    <Grid item>
                      <Layout.Typography {...CommonSmallLabelStyles}>
                        Servicio que busca
                      </Layout.Typography>
                    </Grid>

                    <Grid item container sx={OptionsWrapperStyles}>
                      {filteredServiceSwitch.length > 0 &&
                        filteredServiceSwitch.map(({ id, label }, index) => (
                          <OptionType
                            key={index}
                            active={formData.service === id ? true : false}
                            onClick={() => handleOnChange('service', id)}
                          >
                            {label}
                          </OptionType>
                        ))}
                    </Grid>
                  </Grid>

                  <Stack direction="row" spacing={2} className="mt-3">
                    <Grid container flexDirection="column" item>
                      <Inputs.CheckBox
                        color="secondary"
                        label="Buscar propiedad por código"
                        size="small"
                        name="searchCheck"
                        isChecked={formData.searchCheck}
                        value={formData.searchCheck}
                        position="end"
                        onChange={({ target }) =>
                          handleOnChange('searchCheck', target.checked)
                        }
                      />
                    </Grid>
                  </Stack>

                  {formData.searchCheck && (
                    <>
                      <Stack
                        direction="row"
                        spacing={2}
                        className="mt-3 animate__animated animate__fadeIn"
                      >
                        <Grid container flexDirection="column" item>
                          <Inputs.TextBox
                            type="text"
                            variant="outlined"
                            size="small"
                            name="code"
                            label="Buscar"
                            placeholder="Ingrese la búsqueda..."
                            hasLabelFixed={true}
                            value={formData.code}
                            onChange={(e) => {
                              handleOnChange('code', e.target.value)
                              handleChange(e)
                            }}
                            onKeyPress={handleKeyPress}
                            endAdornment={
                              <InputAdornment position="end">
                                <Layout.IconButton
                                  size="small"
                                  color="inherit"
                                  onClick={() => handleOnChange('code', '')}
                                >
                                  <Layout.Icons.CloseIcon
                                    height={16}
                                    width={16}
                                  />
                                </Layout.IconButton>
                              </InputAdornment>
                            }
                            className="font-family-roboto-regular fs-6 text-dark w-100"
                          />
                        </Grid>
                      </Stack>
                      {formData.arrayListCode.length > 0 && (
                        <div className="row mt-2 mb-2 animate__animated animate__fadeIn">
                          <div className="col-sm-12">
                            {formData.arrayListCode.map(
                              ({ id, name }, number) => (
                                <Fragment key={number}>
                                  <Layout.Chip
                                    label={name}
                                    size="medium"
                                    onDelete={() =>
                                      handleOnChange('arrayListCode', id)
                                    }
                                    className={`${number > 0 && 'ml-2'}`}
                                  />
                                </Fragment>
                              ),
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <Stack direction="row" spacing={2} className="mt-3">
                    <Grid container flexDirection="column" item>
                      <Inputs.Select
                        size="small"
                        label="Ciudad"
                        name="city"
                        value={formData.city}
                        itemsArray={arrayListToDropdownCity}
                        onChange={(e) => {
                          handleOnChange('city', e.target.value)
                          handleChange(e)
                        }}
                        className="font-family-roboto-regular fs-6 text-dark w-100"
                      />
                    </Grid>
                    <Grid container flexDirection="column" item>
                      <Inputs.Autocomplete
                        variant="outlined"
                        size="small"
                        label="Barrios"
                        name="neighborhoods"
                        values={formData.neighborhoods}
                        itemsArray={arrayListToDropdownNeighborhood}
                        onChange={(e) => handleOnChange('neighborhoods', e)}
                        handleChangeSearch={handleChangeSearchNeighborhood}
                        className="font-family-roboto-regular fs-6 text-dark w-100"
                      />
                    </Grid>
                  </Stack>

                  <Stack direction="row" spacing={2} className="mt-3">
                    <Grid container flexDirection="column" item>
                      <Inputs.Autocomplete
                        variant="outlined"
                        size="small"
                        label="Tipos de propiedades"
                        name="propertyTypes"
                        values={formData.propertyTypes}
                        itemsArray={arrayListToDropdownPropertyType}
                        onChange={(e) => handleOnChange('propertyTypes', e)}
                        className="font-family-roboto-regular fs-6 text-dark w-100"
                      />
                    </Grid>
                    <Grid
                      container
                      gap={15 / 8}
                      display="grid"
                      alignItems="center"
                      gridTemplateColumns="max-content 1fr"
                    >
                      <Grid item>
                        <Layout.Typography {...CommonSmallLabelStyles}>
                          Estrato
                        </Layout.Typography>
                      </Grid>

                      <Grid item container sx={OptionsWrapperStyles}>
                        {filteredStrataSwitch.length > 0 &&
                          filteredStrataSwitch.map(({ id, label }, index) => (
                            <OptionType
                              key={index}
                              active={formData.strata.find((value) => value.id === id) ? true : false}
                              onClick={() => handleOnChange('strata', id)}
                            >
                              {label}
                            </OptionType>
                          ))}
                      </Grid>
                    </Grid>
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    sx={divider}
                    className="mt-2"
                  >
                    <Grid container flexDirection="column" item>
                      <InputLabel
                        htmlFor="numberRoom"
                        sx={{ fontSize: '.9rem' }}
                      >
                        Habitaciones
                      </InputLabel>
                      <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        id="numberRoom"
                        name="numberRoom"
                        label=" "
                        value={formData.numberRoom}
                        placeholder="Ingrese cantidad..."
                        onChange={(e) => {
                          handleOnChange('numberRoom', e.target.value)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          // readOnly: formType === 2 ? true : false,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Layout.Box
                                sx={AdormentBtnStyle}
                                onClick={() => decrease('numberRoom')}
                              >
                                <Layout.Icons.RemoveIcon
                                  color="mobiligrey.default"
                                  height={16}
                                  width={16}
                                />
                              </Layout.Box>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Layout.Box
                                sx={AdormentBtnStyle}
                                onClick={() => increase('numberRoom')}
                              >
                                <Layout.Icons.AddIcon height={16} width={16} />
                              </Layout.Box>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          '& > input': {
                            textAlign: 'center',
                          },
                        }}
                        className="font-family-roboto-medium fs-6 text-dark text-center w-100"
                      />
                    </Grid>
                    <Grid container flexDirection="column" item>
                      <InputLabel
                        htmlFor="numberBathRoom"
                        sx={{ fontSize: '.9rem' }}
                      >
                        Baños
                      </InputLabel>
                      <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        id="numberBathRoom"
                        name="numberBathRoom"
                        label=" "
                        value={formData.numberBathRoom}
                        placeholder="Ingrese cantidad..."
                        onChange={(e) => {
                          handleOnChange('numberBathRoom', e.target.value)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          // readOnly: formType === 2 ? true : false,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Layout.Box
                                sx={AdormentBtnStyle}
                                onClick={() => decrease('numberBathRoom')}
                              >
                                <Layout.Icons.RemoveIcon
                                  color="mobiligrey.default"
                                  height={16}
                                  width={16}
                                />
                              </Layout.Box>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Layout.Box
                                sx={AdormentBtnStyle}
                                onClick={() => increase('numberBathRoom')}
                              >
                                <Layout.Icons.AddIcon height={16} width={16} />
                              </Layout.Box>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          '& > input': {
                            textAlign: 'center',
                          },
                        }}
                        className="font-family-roboto-medium fs-6 text-dark text-center w-100"
                      />
                    </Grid>
                    <Grid container flexDirection="column" item>
                      <InputLabel
                        htmlFor="areaTotal"
                        sx={{ fontSize: '.9rem' }}
                      >
                        Área
                      </InputLabel>
                      <Inputs.TextBox
                        sx={{
                          '& > input': {
                            textAlign: 'center',
                          },
                        }}
                        type="number"
                        variant="outlined"
                        size="small"
                        name="areaTotal"
                        label=" "
                        value={formData.areaTotal}
                        placeholder=""
                        hasLabelFixed={true}
                        onChange={(e) => {
                          handleOnChange('areaTotal', e.target.value)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        endAdornment={
                          <InputAdornment position="end">m²</InputAdornment>
                        }
                        className="font-family-roboto-medium fs-6 text-dark text-right w-100"
                      />
                    </Grid>
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    sx={divider}
                    className="mt-3"
                  >
                    {(formData.service === 'Arriendo' ||
                      formData.service === 'Captación') && (
                      <Grid
                        container
                        flexDirection="column"
                        item
                        className="animate__animated animate__fadeIn"
                      >
                        {formData.service === 'Arriendo' && (
                          <Inputs.TextBox
                            type="date"
                            variant="outlined"
                            size="small"
                            name="leaseDate"
                            label="Fecha de mundanza"
                            placeholder="Ingrese fecha de mundanza..."
                            hasLabelFixed={true}
                            value={formData.leaseDate}
                            onChange={(e) => {
                              handleOnChange('leaseDate', e.target.value)
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            className="font-family-roboto-medium fs-6 text-dark w-100 animate__animated animate__fadeIn"
                          />
                        )}
                        {formData.service === 'Captación' && (
                          <Inputs.TextBox
                            type="date"
                            variant="outlined"
                            size="small"
                            name="emptyDate"
                            label="Fecha de desocupación"
                            placeholder="Ingrese fecha de desocupación..."
                            hasLabelFixed={true}
                            value={formData.emptyDate}
                            onChange={(e) => {
                              handleOnChange('emptyDate', e.target.value)
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            className="font-family-roboto-medium fs-6 text-dark w-100 animate__animated animate__fadeIn"
                          />
                        )}
                      </Grid>
                    )}

                    {formData.service !== 'Proyecto' && (
                      <Grid
                        container
                        flexDirection="column"
                        item
                        className="animate__animated animate__fadeIn"
                      >
                        {formData.service === 'Arriendo' && (
                          <NumericFormat
                            value={formData.rangeTotalLease}
                            prefix="$"
                            decimalSeparator={','}
                            decimalPrecision={2}
                            thousandSeparator={'.'}
                            onValueChange={(value) => {
                              handleOnChange('rangeTotalLease', value.value)
                              handleChange(value.value)
                            }}
                            customInput={Inputs.TextBox}
                            name="rangeTotalLease"
                            label="Presupuesto de arriendo"
                            placeholder="Ingrese presupuesto de arriendo..."
                            onBlur={handleBlur}
                            {...numberFieldProps}
                          />
                        )}

                        {formData.service === 'Compra' && (
                          <Box sx={StylesCompra}>
                            <Grid
                              container
                              flexDirection="column"
                              item
                              className="animate__animated animate__fadeIn mt-4"
                            >
                              <NumericFormat
                                value={formData.rangeTotalBuy}
                                prefix="$"
                                decimalSeparator={','}
                                decimalPrecision={2}
                                thousandSeparator={'.'}
                                onValueChange={(value) => {
                                  handleOnChange('rangeTotalBuy', value.value)
                                  handleChange(value.value)
                                }}
                                customInput={Inputs.TextBox}
                                name="rangeTotalBuy"
                                label="Presupuesto de compra"
                                placeholder="Ingrese presupuesto de compra..."
                                onBlur={handleBlur}
                                {...numberFieldProps}
                              />
                            </Grid>

                            <Grid
                              container
                              flexDirection="column"
                              item
                              className="animate__animated animate__fadeIn"
                            >
                              <InputLabel sx={{ fontSize: '.9rem' }}>
                                Método de pago
                              </InputLabel>
                              <Grid item container sx={OptionsWrapperStyles}>
                                {filteredPaymentMethod.length > 0 &&
                                  filteredPaymentMethod.map(
                                    ({ id, label }, index) => (
                                      <OptionType
                                        key={index}
                                        active={formData.paymentMethod.find((value) => value.id === id) ? true : false}
                                        onClick={() => handleOnChange('paymentMethod', id)}
                                      >
                                        {label}
                                      </OptionType>
                                    ),
                                  )}
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                        {formData.service === 'Captación' && (
                          <NumericFormat
                            value={formData.price}
                            prefix="$"
                            decimalSeparator={','}
                            decimalPrecision={2}
                            thousandSeparator={'.'}
                            onValueChange={(value) => {
                              handleOnChange('price', value.value)
                              handleChange(value.value)
                            }}
                            customInput={Inputs.TextBox}
                            name="price"
                            label="Precio"
                            placeholder="Ingrese precio..."
                            onBlur={handleBlur}
                            {...numberFieldProps}
                          />
                        )}
                      </Grid>
                    )}

                    {formData.service === 'Proyecto' && (
                      <>
                        <Grid
                          container
                          flexDirection="column"
                          item
                          className="animate__animated animate__fadeIn mt-4"
                        >
                          <NumericFormat
                            value={formData.estimation}
                            prefix="$"
                            decimalSeparator={','}
                            decimalPrecision={2}
                            thousandSeparator={'.'}
                            onValueChange={(value) => {
                              handleOnChange('estimation', value.value)
                              handleChange(value.value)
                            }}
                            customInput={Inputs.TextBox}
                            name="estimation"
                            label="Estimación"
                            placeholder="Ingrese estimación..."
                            onBlur={handleBlur}
                            {...numberFieldProps}
                          />
                        </Grid>
                        <Grid
                          container
                          flexDirection="column"
                          item
                          className="animate__animated animate__fadeIn"
                        >
                          <InputLabel sx={{ fontSize: '.9rem' }}>
                            Método de pago
                          </InputLabel>
                          <Grid item container sx={OptionsWrapperStyles}>
                            {filteredPaymentMethod.length > 0 &&
                              filteredPaymentMethod.map(
                                ({ id, label }, index) => (
                                  <OptionType
                                    key={index}
                                    active={formData.paymentMethod.find((value) => value.id === id) ? true : false}
                                    onClick={() => handleOnChange('paymentMethod', id)}
                                  >
                                    {label}
                                  </OptionType>
                                ),
                              )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    className="mt-2"
                  >
                    <Stack direction="row" alignItems="center">
                      <Inputs.CheckBox
                        color="secondary"
                        label="Requisitos"
                        size="small"
                        name="requirementCheck"
                        isChecked={formData.requirementCheck}
                        value={formData.requirementCheck}
                        position="end"
                        onChange={(e) => {
                          handleOnChange('requirementCheck', e.target.checked)
                          handleChange(e)
                        }}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Inputs.CheckBox
                        color="secondary"
                        label="Parqueadero"
                        size="small"
                        name="parkingCheck"
                        isChecked={formData.parkingCheck}
                        value={formData.parkingCheck}
                        position="end"
                        onChange={(e) => {
                          handleOnChange('parkingCheck', e.target.checked)
                          handleChange(e)
                        }}
                      />
                    </Stack>
                  </Stack>
                  
                  <Grid
                    container
                    flexDirection="column"
                    item
                    className="mt-3 mb-2"
                  >
                    <Inputs.TextArea
                      variant="outlined"
                      rows={2}
                      size="small"
                      name="observation"
                      label="Observación"
                      placeholder="Ingrese observación..."
                      hasLabelFixed={true}
                      value={formData.observation}
                      onChange={(e) => {
                        handleOnChange('observation', e.target.value)
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      className="font-family-roboto-medium fs-6 text-dark w-100"
                    />
                  </Grid>
                  
                  <Grid
                    container
                    flexDirection="column"
                    item
                    className="mt-3 mb-2"
                  >
                    <Inputs.LoadingButton
                      variant="outlined"
                      isLoading={isLoadingSearch}
                      isLoadingPosition="start"
                      startIcon={<Layout.Icons.SearchIcon height={18} width={18} />}
                      label="Buscar"
                      type="button"
                      onClick={handleSearch}
                      className={`bg-primary font-family-roboto-medium fs-6 text-uppercase d-flex w-100`}
                    />
                  </Grid>
                </Layout.Box>
                
                {isCommercial && (
                  <Layout.Paper
                    className="animate__animated animate__fadeIn"
                    sx={BottomActionStyles}
                  >
                    <Inputs.LoadingButton
                      isDisabled={!isValid}
                      variant="outlined"
                      isLoading={isLoadingForm}
                      isLoadingPosition="start"
                      label="Cancelar"
                      type="button"
                      onClick={() => hanbleSubmit('Cancelar')}
                      className={`${isValid && 'bg-secondary-outlined'} font-family-roboto-medium fs-6 text-uppercase d-flex float-start`}
                    />

                    <Inputs.LoadingButton
                      isDisabled={!isValid}
                      variant="outlined"
                      isLoading={isLoadingForm}
                      isLoadingPosition="end"
                      endIcon={<Layout.Icons.SaveIcon height={18} width={18} />}
                      label="Guardar Cambios"
                      type="button"
                      onClick={() => hanbleSubmit('GuardarCambios')}
                      className={`${isValid && 'bg-primary'} font-family-roboto-medium fs-6 text-uppercase d-flex float-start`}
                    />
                  </Layout.Paper>
                )}
                {!isCommercial && (
                  formType === 1 && (
                    <Layout.Paper
                      className="animate__animated animate__fadeIn"
                      sx={BottomActionStyles}
                    >
                      <Inputs.LoadingButton
                        isDisabled={!isValid}
                        variant="outlined"
                        isLoading={isLoadingForm}
                        isLoadingPosition="start"
                        startIcon={<Layout.Icons.SaveIcon height={18} width={18} />}
                        label="Borrador"
                        type="button"
                        onClick={() => hanbleSubmit('Registrado')}
                        className={`${isValid && 'bg-secondary-outlined'} font-family-roboto-medium fs-6 text-uppercase d-flex float-start`}
                      />
                      <Inputs.LoadingButton
                        isDisabled={!isValid}
                        variant="outlined"
                        isLoading={isLoadingForm}
                        isLoadingPosition="start"
                        startIcon={<Layout.Icons.DeleteIcon height={18} width={18} />}
                        label="Desechar"
                        type="button"
                        onClick={() => hanbleSubmit('Desechado')}
                        className={`${isValid && 'bg-secondary-outlined'} font-family-roboto-medium fs-6 text-uppercase d-flex float-start`}
                      />
                      <Inputs.LoadingButton
                        isDisabled={!isValid}
                        variant="outlined"
                        isLoading={isLoadingForm}
                        isLoadingPosition="end"
                        endIcon={<Layout.Icons.NavigateNextIcon height={18} width={18} />}
                        label="Comercial"
                        type="button"
                        onClick={() => hanbleSubmit('Comercial')}
                        className={`${isValid && 'bg-primary'} font-family-roboto-medium fs-6 text-uppercase d-flex float-start`}
                      />
                    </Layout.Paper>
                  )
                )}
              </Form>
            )}
          </Formik>
        </Layout.Box>
      )}
    </Permission>
  )
}

const numberFieldProps = {
  variant: 'outlined',
  size: 'small',
  hasLabelFixed: true,
  className:
    'font-family-roboto-medium fs-6 text-dark w-100 animate__animated animate__fadeIn',
}

const StylesCompra = {
  display: 'flex',
  gap: '16px',
}

FormComponent.propTypes = {
	isActive: PropTypes.bool.isRequired
}

export default FormComponent
