import React, { useEffect, useState } from "react"
import {useSelector} from 'react-redux'
import Collapse from "@material-ui/core/Collapse"
import Grid from "@material-ui/core/Grid"
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Feedback from "../../../../../components/feedback/Feedback"
import Display from "../../../../../components/display/Display"
import AuthorizationRowByOwnerRow from "./AuthorizationRowByOwnerRow"
import errorServices from '../../../../../services/errors'
import axios from '../../../../../services/axios'

const headCells = [
	{ id: 'date', label: 'Fecha', align: 'center', width: '20%' },
	{ id: 'status', label: 'Estatus', align: 'center', width: '10%' },
	{ id: 'ownerJ', label: 'Razón Social', align: 'left', width: '25%' },
	{ id: 'ownerN', label: 'Nombres y Apellidos', align: 'left', width: '25%' },
	{ id: 'authorization', label: 'Autorización', align: 'center', width: '15%' },
	{ id: 'action', label: '', align: 'center', width: '5%' }
]

const AuthorizationRowByOwner = ({ open, authorizationID, authorization_for_commercial, conditions_for_rent, conditions_for_sale, duration, responsibility, executive_merit }) => {
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [getLoading, setLoading] = useState(false)
	const [getCells, setCells] = useState(headCells)
	const [getRecords, setRecords] = useState([])
	const [getPages, setPages] = useState(0)
	const [getTotalRows, setTotalRows] = useState(0)
	const [getPage, setPage] = useState(1)
	const { TblContainer, TblHead } = Display.Table(getCells, getRecords)
	
	const handleList = async () => {
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				perPage: 10,
				page: getPage,
				authorization_id: authorizationID
			}
			
			const data = await axios.post('dashboard/properties/authorizations/owners/list', param)
			
			if (data.status === 200) {
				setPages(data.data.data.pages)
				setTotalRows(data.data.data.totalRows)
				setRecords(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	useEffect(() => {
		open && handleList()
	}, [open, authorizationID])
	
	const handleChangePage = (value) => {
		setPage(value)
	}
	
	return (
		<Collapse
			className="p-0 m-0"
			in={open}
			timeout="auto"
			unmountOnExit
		>
			<div className="bg-light p-2 font-family-roboto-regular font-size-14 text-dark w-100">
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<strong>Autorización para comercial: </strong> { authorization_for_commercial }
					</Grid>
					<Grid item xs={12} sm={6}>
						<strong>Condiciones para arriendo: </strong> { conditions_for_rent }
					</Grid>
					<Grid item xs={12} sm={6}>
						<strong>Condiciones para venta: </strong> { conditions_for_sale }
					</Grid>
					<Grid item xs={12} sm={6}>
						<strong>Duración: </strong> { duration }
					</Grid>
					<Grid item xs={12} sm={6}>
						<strong>Responsabilidad: </strong> { responsibility }
					</Grid>
					<Grid item xs={12} sm={6}>
						<strong>Mérito ejecutivo: </strong> { executive_merit }
					</Grid>
				</Grid>
			</div>
			<div className="bg-light p-2 font-family-roboto-regular font-size-14 text-dark w-100">
				<strong>Propietarios asignados</strong>
			</div>
			<div className="m-0 p-0 w-100">
				<Grid container spacing={1}>
					{getTotalRows > 19 && (
						<Grid item xs={12} className="m-0 animate__animated animate__fadeIn">
							<Display.Pagination
								pageCount={getPages}
								pageIndex={getPage}
								handleChangePage={handleChangePage}
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						<TblContainer>
							<TblHead />
							<TableBody>
								{getLoading && (
									<TableRow>
										{headCells.map(({ width }, index) => (
											<TableCell
												key={index}
												className="p-2"
												align="center"
												width={width}
											>
												<Feedback.Loading type="text" animation="wave" width="100%" height="30px" />
											</TableCell>
										))}
									</TableRow>
								)}
								{!getLoading && getRecords.length > 0 && (
									getRecords.map((item, number) => (
										<AuthorizationRowByOwnerRow
											key={number}
											row={item}
											handleList={handleList}
										/>
									))
								)}
								{!getLoading && getRecords.length === 0 && (
									<TableRow>
										<TableCell className="p-2" colSpan={headCells.length} align="center" width="100%">No hay registros</TableCell>
									</TableRow>
								)}
							</TableBody>
						</TblContainer>
					</Grid>
					{getTotalRows > 19 && (
						<Grid item xs={12} className="m-0 animate__animated animate__fadeIn">
							<Display.Pagination
								pageCount={getPages}
								pageIndex={getPage}
								handleChangePage={handleChangePage}
							/>
						</Grid>
					)}
				</Grid>
				<Feedback.DialogLoading
					getLoading={getLoading}
					setLoading={setLoading}
				/>
			</div>
		</Collapse>
	)
}

export default AuthorizationRowByOwner
