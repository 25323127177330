import * as types from '../../../../actionsTypes/dashboard/settings/properties/propertyDomusTypes'
import { initialValuesPropertyDomus as initialValues } from '../../../../../helpers/variablesInitialValues'

const initialState = {
	search: '',
	arrayListAmenities: [],
	arrayListImages: [],
	arrayListImages360: [],
	arrayListVideos: [],
	arrayListTours: [],
	isLoadingForm: false,
	formData: initialValues
}

const propertyDomusReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.SEARCH_PROPERTY_DOMUS:
			return {
				...state,
				search: payload
			}
		
		case types.ARRAY_LIST_AMENITIES_PROPERTY_DOMUS:
			return {
				...state,
				arrayListAmenities: payload
			}
			
		case types.ARRAY_LIST_IMAGES_PROPERTY_DOMUS:
			return {
				...state,
				arrayListImages: payload
			}
			
		case types.ARRAY_LIST_IMAGES360_PROPERTY_DOMUS:
			return {
				...state,
				arrayListImages360: payload
			}
			
		case types.ARRAY_LIST_VIDEOS_PROPERTY_DOMUS:
			return {
				...state,
				arrayListVideos: payload
			}
			
		case types.ARRAY_LIST_TOURS_PROPERTY_DOMUS:
			return {
				...state,
				arrayListTours: payload
			}
		
		case types.IS_LOADING_FORM_PROPERTY_DOMUS:
			return {
				...state,
				isLoadingForm: payload
			}
		
		case types.FORM_DATA_PROPERTY_DOMUS:
			return {
				...state,
				formData: payload
			}
		
		default:
			return state
	}
}

export default propertyDomusReducer
