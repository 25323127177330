import { history } from '../../../store'
import {
	initialValuesFiltered as initialValues,
	initialValuesFilter,
	initialValuesFilteredDetail,
	initialValuesFilteredAdviserForm,
	initialValuesFilteredCustomerForm
} from '../../../../helpers/variablesInitialValues'
import * as types from '../../../actionsTypes/dashboard/filteredTypes'
import { setIsLoading } from '../../loadingAction'
import { setIsOpenCustomerModal as setIsOpenCustomerModalNoAuth } from '../../filtered/adviserForm/filteredAdviserAction'
import { setPage as setPageActivityLog, setPages as setPagesActivityLog, setArrayList as setArrayListActivityLog } from '../activities/activityLogAction'
import {
	getArrayList,
	getVerificationByMobile,
	setStore,
	getShowById,
	getPreviewById,
	setUpdateById,
	setUpdateByIdAdviser,
	getArrayListFavoritesById,
	getArrayListLogsById,
	setStoreFavorite,
	setDeleteFavoriteById,
	getShowCustomerById,
	setUpdateCustomerById
} from './services'
import {swalSuccess, swalWarning } from '../../../../services/sweetalert'
import errorServices from '../../../../services/errors'

export const getArrayListRegister = (param, arrayList = [], isPagination = false) => (
  async (dispatch) => {
    await dispatch(setIsLoadingRegister(true))

    await getArrayList(param).then(({ data }) => {
      dispatch(setPageRegister(data.data.page))
      dispatch(setPagesRegister(data.data.pages))
      dispatch(setHasNextPageRegister(data.data.pages > data.data.page ? true : false))
	
	    if (isPagination) {
		    dispatch(setArrayListRegister(data.data.records.length === 0 ? [] : [...arrayList, ...data.data.records]))
	    } else {
		    dispatch(setArrayListRegister(data.data.records.length === 0 ? [] : data.data.records))
	    }
    }).catch((error) => errorServices(error))

    await dispatch(setIsLoadingRegister(false))
  }
)

export const getArrayListDiscarded = (param, arrayList = [], isPagination = false) => (
  async (dispatch) => {
    await dispatch(setIsLoadingDiscarded(true))

    await getArrayList(param).then(({ data }) => {
      dispatch(setPageDiscarded(data.data.page))
      dispatch(setPagesDiscarded(data.data.pages))
      dispatch(setHasNextPageDiscarded(data.data.pages > data.data.page ? true : false))
	
	    if (isPagination) {
		    dispatch(setArrayListDiscarded(data.data.records.length === 0 ? [] : [...arrayList, ...data.data.records]))
	    } else {
		    dispatch(setArrayListDiscarded(data.data.records.length === 0 ? [] : data.data.records))
	    }
    }).catch((error) => errorServices(error))

    await dispatch(setIsLoadingDiscarded(false))
  }
)

export const getArrayListCommercial = (param, arrayList = [], isPagination = false) => (
	async (dispatch) => {
	  await dispatch(setIsLoadingCommercial(true))
	
	  await getArrayList(param).then(({ data }) => {
      dispatch(setPageCommercial(data.data.page))
      dispatch(setPagesCommercial(data.data.pages))
      dispatch(setHasNextPageCommercial(data.data.pages > data.data.page ? true : false))
		
		  if (isPagination) {
			  dispatch(setArrayListCommercial(data.data.records.length === 0 ? [] : [...arrayList, ...data.data.records]))
		  } else {
			  dispatch(setArrayListCommercial(data.data.records.length === 0 ? [] : data.data.records))
		  }
    }).catch((error) => errorServices(error))
	
	  await dispatch(setIsLoadingCommercial(false))
	}
)

export const getArrayListFavorites = id => (
	async (dispatch) => {
	  await dispatch(setIsLoadingFavorites(true))
	
	  await getArrayListFavoritesById(id).then(({ data }) => {
	      dispatch(setArrayListFavorites(data.data))
	    }).catch((error) => errorServices(error))
	
	  await dispatch(setIsLoadingFavorites(false))
	}
)

export const getArrayListLogs = param => (
	async (dispatch) => {
	  await dispatch(setIsLoadingLogs(true))
	
	  await getArrayListLogsById(param).then(({ data }) => {
	      dispatch(setPagesLogs(data.data.pages))
	      dispatch(setArrayListLogs(data.data.records))
	    }).catch((error) => errorServices(error))
	
	  await dispatch(setIsLoadingLogs(false))
	}
)

export const verificationByMobile = mobile => (
	async (dispatch) => {
	  await dispatch(setIsLoadingValidate(true))
	  await dispatch(setIsLoadingOccurrences(true))

    await getVerificationByMobile(mobile).then(({ data }) => {
      if (data.data === 'El celular no se encuentra registrado') {
	      dispatch( setPageActivityLog(1) )
	      dispatch( setPagesActivityLog(1) )
	      dispatch( setArrayListActivityLog([]) )
        dispatch(setIsOpenOccurrences(false))
        dispatch(setIsActiveValidateButton(false))
        swalWarning('El cliente no se encuentra registrado')
      } else {
        dispatch(setIsOpenOccurrences(true))
        dispatch(setIsActiveValidateButton(true))

        const object = {
          realState: '',
          mobile: data.data.mobile,
          firstName: data.data.first_name,
          lastName: data.data.last_name,
          originChannel: data.data.origin_channel_id,
          originChannelName: data.data.origin_channel,
          contact: data.data.contact_id,
          contactName: data.data.contact,
          occurrences: data.data.occurrences,
          occurrencesTest: data.data.occurrences_test,
        }

        dispatch(setFormData(object))
        dispatch(setIsActiveValidateButton(true))
      }
    }).catch((error) => {
      dispatch(setIsOpenOccurrences(false))
      dispatch(setIsActiveValidateButton(false))
      errorServices(error)
    })
	
	  await dispatch(setIsLoadingValidate(false))
	  await dispatch(setIsLoadingOccurrences(false))
	}
)

export const store = (param, status) => (
	async (dispatch) => {
		await dispatch( setIsCompletedForm(false) )
		await dispatch( setIsLoadingForm(true) )
		
		await setStore(param).then(({ data }) => {
			swalSuccess('Registro exitoso', 'El caso se ha registrado correctamente')
			dispatch( setResetValidate() )
			dispatch( setIsOpenModal(status === 'Registrado' ? true : false) )
			dispatch( setIsCompletedForm(true) )
			
			if (status === 'Next') {
				dispatch( setFormType(1) )
				history.push(`/dashboard/filtrado/registro-de-casos/detalle/${data.data}`)
			}
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const showById = id => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getShowById(id).then(({ data }) => {
			const object = {
				id: data.data.id,
				status: data.data.status,
				step: (data.data.step === null || data.data.step === 1) ? 1 : data.data.step,
				realState: data.data.realstate,
				createdDate: data.data.created_date,
				createdAt: data.data.created_at,
				createdAgo: data.data.created_ago,
				customer: data.data.customer,
				email: data.data.email === null ? '' : data.data.email,
				mobile: data.data.mobile,
				phone: data.data.phone === null ? '' : data.data.phone,
				originChannel: data.data.origin_channel,
				contact: data.data.contact,
				service: data.data.service === null ? 'Arriendo' : data.data.service,
				searchCheck: data.data.code.length === 0 ? false : true,
				code: '',
				arrayListCode: data.data.code.length === 0 ? [] : data.data.code,
				city: data.data.city === null ? 1 : data.data.city,
				neighborhoods: data.data.neighborhoods.length === 0 ? [] : data.data.neighborhoods,
				propertyTypes: data.data.property_types.length === 0 ? [] : data.data.property_types,
				strata: data.data.strata.length === 0 ? [] : data.data.strata,
				numberRoom: (data.data.room === null || data.data.room === 0) ? '' : data.data.room,
				numberBathRoom: (data.data.bathroom === null || data.data.bathroom === 0) ? '' : data.data.bathroom,
				areaTotal: (data.data.area_total === null || data.data.area_total === 0) ? '' : data.data.area_total,
				leaseDate: data.data.lease_date === null ? '' : data.data.lease_date,
				emptyDate: data.data.empty_date === null ? '' : data.data.empty_date,
				rangeTotalLease: (data.data.range_total_lease === null || data.data.range_total_lease === 0) ? '' : data.data.range_total_lease,
				rangeTotalBuy: (data.data.range_total_buy === null || data.data.range_total_buy === 0) ? '' : data.data.range_total_buy,
				price: (data.data.price === null || data.data.price === 0) ? '' : data.data.price,
				estimation: (data.data.estimation === null || data.data.estimation === 0) ? '' : data.data.estimation,
				paymentMethod: data.data.payment_method.length === 0 ? [] : data.data.payment_method,
				parkingCheck: data.data.parking === 'Si' ? true : false,
				requirementCheck: data.data.requirement === 'Si' ? true : false,
				observation: data.data.observation === null ? '' : data.data.observation,
				ranking: '',
				adviser: ''
			}
			
			dispatch( setFormType((data.data.step === null || data.data.step === 1) ? 1 : data.data.step) )
			dispatch( setIsOpenOccurrences(false) )
			dispatch( setFormData(initialValues) )
			dispatch( setFormDataDetail(object) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const previewById = id => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getPreviewById(id).then(({ data }) => {
			dispatch( setFormDataPreview(data.data) )
			dispatch( setArrayListPreviewFavorites(data.data.favorites) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const updateById = (id, param, status) => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setUpdateById(id, param).then(({ data }) => {
      if (status === 'Comercial') {
        swalSuccess('Enviado exitosamente', data.data)
        dispatch(setFormType(2))
      } else if (status === 'GuardarCambios') {
        swalSuccess('Cambios guardados', data.data)
        history.push(`/dashboard/filtrado/comercial/${id}`)
      } else if (status === 'Cancelar') {
        history.push(`/dashboard/filtrado/comercial/${id}`)
      } else {
        swalSuccess('Enviado exitosamente', data.data)
        dispatch(setIsOpenModal(false))
        dispatch(setResetValidate())
        history.push('/dashboard/filtrado/registro-de-casos')
      }
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const updateByIdAdviser = (id, param) => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setUpdateByIdAdviser(id, param).then(({ data }) => {
			swalSuccess('Registro exitoso', data.data)
			dispatch( setIsOpenModal(false) )
			dispatch( setResetValidate() )
			history.push('/dashboard/filtrado/registro-de-casos')
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const storeFavorite = param => (
	async (dispatch) => {
		await dispatch( setIsCompletedForm(false) )
		await dispatch( setIsLoadingForm(true) )
		
		await setStoreFavorite(param).then(({ data }) => {
			// swalSuccess('Asignación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const deleteFavoriteById = (id) => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setDeleteFavoriteById(id).then(({ data }) => {
			// swalSuccess('Eliminación exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const showCustomerById = id => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getShowCustomerById(id).then(({ data }) => {
			const object = {
				id: data.data.id,
				firstName: data.data.first_name === null ? '' : data.data.first_name,
				lastName: data.data.last_name === null ? '' : data.data.last_name,
				email: data.data.email === null ? '' : data.data.email,
				mobile: data.data.mobile,
				phone: data.data.phone === null ? '' : data.data.phone,
			}
			
			dispatch( setFormDataCustomer(object) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const updateCustomerById = (id, param) => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await setUpdateCustomerById(id, param).then(({ data }) => {
			swalSuccess('Captación exitosa', data.data)
			dispatch( setFormDataCustomer(initialValuesFilteredCustomerForm) )
			dispatch( setIsOpenCustomerModal(false) )
			dispatch( setIsOpenCustomerModalNoAuth(false) )
			dispatch( setIsCompletedForm(true) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setResetValidate = () => (
	async (dispatch) => {
		await dispatch( setFormData(initialValues) )
		await dispatch( setFormDataDetail(initialValuesFilteredDetail) )
		await dispatch( setFormType(1) )
		await dispatch( setIsCompletedForm(false) )
		await dispatch( setIsOpenOccurrences(false) )
		await dispatch( setIsActiveValidateButton(false) )
	}
)

export const setFilteredReset = () => (
	async (dispatch) => {
		await dispatch( setPerPageRegister(10) )
		await dispatch( setPerPageDiscarded(10) )
		await dispatch( setPerPageCommercial(10) )
		await dispatch( setPerPageLogs(10) )
		await dispatch( setPageRegister(1) )
		await dispatch( setPageDiscarded(1) )
		await dispatch( setPageCommercial(1) )
		await dispatch( setPageLogs(0) )
		await dispatch( setHasNextPageRegister(true) )
		await dispatch( setHasNextPageDiscarded(true) )
		await dispatch( setHasNextPageCommercial(true) )
		await dispatch( setPagesRegister(1) )
		await dispatch( setPagesDiscarded(1) )
		await dispatch( setPagesCommercial(1) )
		await dispatch( setPagesLogs(0) )
		await dispatch( setArrayListRegister([]) )
		await dispatch( setArrayListDiscarded([]) )
		await dispatch( setArrayListCommercial([]) )
		await dispatch( setArrayListFavorites([]) )
		await dispatch( setArrayListPreviewFavorites([]) )
		await dispatch( setArrayListLogs([]) )
		await dispatch( setIsActiveSearch(false) )
		await dispatch( setIsOpenModal(false) )
		await dispatch( setIsOpenCustomerModal(false) )
		await dispatch( setIsOpenOccurrences(false) )
		await dispatch( setIsActiveValidateButton(false) )
		await dispatch( setIsActiveHistorical(false) )
		await dispatch( setIsLoadingRegister(true) )
		await dispatch( setIsLoadingDiscarded(true) )
		await dispatch( setIsLoadingCommercial(true) )
		await dispatch( setIsLoadingFavorites(false) )
		await dispatch( setIsLoadingLogs(false) )
		await dispatch( setIsLoadingValidate(false) )
		await dispatch( setIsLoadingOccurrences(false) )
		await dispatch( setIsLoadingForm(false) )
		await dispatch( setIsCompletedForm(false) )
		await dispatch( setIsSelectedToggleSwitch(0) )
		await dispatch( setFormFilter(initialValuesFilter) )
		await dispatch( setFormData(initialValues) )
		await dispatch( setFormDataDetail(initialValuesFilteredDetail) )
		await dispatch( setFormDataPreview(initialValuesFilteredAdviserForm) )
		await dispatch( setFormDataCustomer(initialValuesFilteredCustomerForm) )
		await dispatch( setFormType(1) )
	}
)

export const setPerPageRegister = value => ({
	type: types.PER_PAGE_REGISTER_FILTER,
	payload: value
})

export const setPerPageDiscarded = value => ({
	type: types.PER_PAGE_DISCARDED_FILTER,
	payload: value
})

export const setPerPageCommercial = value => ({
	type: types.PER_PAGE_COMMERCIAL_FILTER,
	payload: value
})

export const setPerPageLogs = value => ({
	type: types.PER_PAGE_LOGS_FILTER,
	payload: value
})

export const setPageRegister = value => ({
	type: types.PAGE_REGISTER_FILTER,
	payload: value
})

export const setPageDiscarded = value => ({
	type: types.PAGE_DISCARDED_FILTER,
	payload: value
})

export const setPageCommercial = value => ({
	type: types.PAGE_COMMERCIAL_FILTER,
	payload: value
})

export const setPageLogs = value => ({
	type: types.PAGE_LOGS_FILTER,
	payload: value
})

export const setPagesRegister = value => ({
	type: types.PAGES_REGISTER_FILTER,
	payload: value
})

export const setPagesDiscarded = value => ({
	type: types.PAGES_DISCARDED_FILTER,
	payload: value
})

export const setPagesCommercial = value => ({
	type: types.PAGES_COMMERCIAL_FILTER,
	payload: value
})

export const setPagesLogs = value => ({
	type: types.PAGES_LOGS_FILTER,
	payload: value
})

export const setHasNextPageRegister = value => ({
	type: types.HAS_NEXT_PAGE_REGISTER_FILTER,
	payload: value
})

export const setHasNextPageDiscarded = value => ({
	type: types.HAS_NEXT_PAGE_DISCARDED_FILTER,
	payload: value
})

export const setHasNextPageCommercial = value => ({
	type: types.HAS_NEXT_PAGE_COMMERCIAL_FILTER,
	payload: value
})

export const setArrayListRegister = value => ({
	type: types.ARRAY_LIST_REGISTER_FILTER,
	payload: value
})

export const setArrayListDiscarded = value => ({
	type: types.ARRAY_LIST_DISCARDED_FILTER,
	payload: value
})

export const setArrayListCommercial = value => ({
	type: types.ARRAY_LIST_COMMERCIAL_FILTER,
	payload: value
})

export const setArrayListFavorites = value => ({
	type: types.ARRAY_LIST_FAVORITES_FILTER,
	payload: value
})

export const setArrayListPreviewFavorites = value => ({
	type: types.ARRAY_LIST_PREVIEW_FAVORITES_FILTER,
	payload: value
})

export const setArrayListLogs = value => ({
	type: types.ARRAY_LIST_LOGS_FILTER,
	payload: value
})

export const setIsActiveSearch = value => ({
	type: types.IS_ACTIVE_SEARCH_FILTER,
	payload: value
})

export const setIsOpenModal = value => ({
	type: types.IS_OPEN_MODAL_FILTER,
	payload: value
})

export const setIsOpenCustomerModal = value => ({
	type: types.IS_OPEN_CUSTOMER_MODAL_FILTER,
	payload: value
})

export const setIsOpenOccurrences = value => ({
	type: types.IS_OPEN_OCCURRENCES_FILTER,
	payload: value
})

export const setIsActiveValidateButton = value => ({
	type: types.IS_ACTIVE_VALIDATE_BUTTON_FILTER,
	payload: value
})

export const setIsActiveHistorical = value => ({
	type: types.IS_ACTIVE_HISTORICAL_FILTER,
	payload: value
})

export const setIsLoadingRegister = value => ({
	type: types.IS_LOADING_REGISTER_FILTER,
	payload: value
})

export const setIsLoadingDiscarded = value => ({
	type: types.IS_LOADING_DISCARDED_FILTER,
	payload: value
})

export const setIsLoadingCommercial = value => ({
	type: types.IS_LOADING_COMMERCIAL_FILTER,
	payload: value
})

export const setIsLoadingFavorites = value => ({
	type: types.IS_LOADING_FAVORITES_FILTER,
	payload: value
})

export const setIsLoadingLogs = value => ({
	type: types.IS_LOADING_LOGS_FILTER,
	payload: value
})

export const setIsLoadingValidate = value => ({
	type: types.IS_LOADING_VALIDATE_FILTER,
	payload: value
})

export const setIsLoadingOccurrences = value => ({
	type: types.IS_LOADING_OCCURRENCES_FILTER,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_FILTER,
	payload: value
})

export const setIsCompletedForm = value => ({
	type: types.IS_COMPLETED_FORM_FILTER,
	payload: value
})

export const setIsSelectedToggleSwitch = value => ({
	type: types.IS_SELECTED_TOGGLE_SWITCH_FILTER,
	payload: value
})

export const setFormFilter = value => ({
	type: types.FORM_FILTER_FILTER,
	payload: value
})

export const setFormData = value => ({
	type: types.FORM_DATA_FILTER,
	payload: value
})

export const setFormDataDetail = value => ({
	type: types.FORM_DATA_DETAIL_FILTER,
	payload: value
})

export const setFormDataPreview = value => ({
	type: types.FORM_DATA_PREVIEW_FILTER,
	payload: value
})

export const setFormDataCustomer = value => ({
	type: types.FORM_DATA_CUSTOMER_FILTER,
	payload: value
})

export const setFormType = value => ({
	type: types.FORM_TYPE_FILTER,
	payload: value
})
