import React from 'react'
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Display from "../../../../../components/display/Display"
import Controls from "../../../../../components/controls/Controls"

const arrayDropdownTypeComplement = [
	{ id: "Apto", name: "Apto" },
	{ id: "Agrupación", name: "Agrupación" },
	{ id: "Bodega", name: "Bodega" },
	{ id: "Bloque", name: "Bloque" },
	{ id: "Camino", name: "Camino" },
	{ id: "Carretera", name: "Carretera" },
	{ id: "Celula", name: "Celula" },
	{ id: "Casa", name: "Casa" },
	{ id: "Conjunto", name: "Conjunto" },
	{ id: "Consultorio", name: "Consultorio" },
	{ id: "Deposito", name: "Deposito" },
	{ id: "Edificio", name: "Edificio" },
	{ id: "Entrada", name: "Entrada" },
	{ id: "Esquina", name: "Esquina" },
	{ id: "Etapa", name: "Etapa" },
	{ id: "Garage", name: "Garage" },
	{ id: "Interior", name: "Interior" },
	{ id: "Kilómetro", name: "Kilómetro" },
	{ id: "Local", name: "Local" },
	{ id: "Lote", name: "Lote" },
	{ id: "Manzana", name: "Manzana" },
	{ id: "Mezanine", name: "Mezanine" },
	{ id: "Modulo", name: "Modulo" },
	{ id: "Oficina", name: "Oficina" },
	{ id: "Paseo", name: "Paseo" },
	{ id: "Parcela", name: "Parcela" },
	{ id: "Pent House", name: "Pent House" },
	{ id: "Piso", name: "Piso" },
	{ id: "Puente", name: "Puente" },
	{ id: "Predio", name: "Predio" },
	{ id: "Salón Comunal", name: "Salón Comunal" },
	{ id: "Sector", name: "Sector" },
	{ id: "Solar", name: "Solar" },
	{ id: "Semisotano", name: "Semisotano" },
	{ id: "Super Manzana", name: "Super Manzana" },
	{ id: "Torre", name: "Torre" },
	{ id: "Unidad", name: "Unidad" },
	{ id: "Unidad Residencial", name: "Unidad Residencial" },
	{ id: "Unidad Residencial", name: "Unidad Residencial" },
	{ id: "Urbanización", name: "Urbanización" },
	{ id: "Finca", name: "Finca" },
	{ id: "Vereda", name: "Vereda" },
	{ id: "Condominio", name: "Condominio" },
	{ id: "Hacienda", name: "Hacienda" },
	{ id: "Vía", name: "Vía" },
	{ id: "Área", name: "Área" },
	{ id: "Centro Comercial", name: "Centro Comercial" },
	{ id: "Centro Empresarial", name: "Centro Empresarial" },
	{ id: "Centro Médico", name: "Centro Médico" },
	{ id: "Hotel", name: "Hotel" },
	{ id: "Parque Industrial", name: "Parque Industrial" },
	{ id: "Mail", name: "Mail" },
	{ id: "Pto Referencia", name: "Pto Referencia" },
	{ id: "Suite", name: "Suite" },
	{ id: "Aparta Estudios", name: "Aparta Estudios" },
	{ id: "Conjunto Residencial", name: "Conjunto Residencial" },
	{ id: "Parqueadero", name: "Parqueadero" },
	{ id: "Conjunto Multifamiliar", name: "Conjunto Multifamiliar" },
	{ id: " ", name: "Otro" },
]

const ComplementRow = ({ onChange, onRemove, typeComplement, textComplement }) => {
	
	return (
		<Grid container spacing={3}>
			<Grid item xs={5} md={5} lg={5}>
				<Controls.Select
					label="Tipo de complemento"
					name="typeComplement"
					defaultValue={typeComplement}
					onChange={e => onChange("typeComplement", e.target.value)}
					arrayDropdown={arrayDropdownTypeComplement}
				/>
			</Grid>
			<Grid item xs={5} md={5} lg={5}>
				<Controls.TextField
					label="Complemento..."
					name="textComplement"
					placeholder="Ingrese complemento..."
					defaultValue={textComplement}
					onChange={e => onChange("textComplement", e.target.value)}
				/>
			</Grid>
			<Grid item xs={2} md={2} lg={2} className="d-flex justify-content-end">
				<IconButton
					color="inherit"
					onClick={onRemove}
					className="bg-grey mt-4 height-32 width-32 float-end text-right"
				>
					<Display.Icons.RemoveIcon
						className="text-dark"
						style={{ fontSize: 20 }}
					/>
				</IconButton>
			</Grid>
		</Grid>
	)
}

export const extractComplement = (fullAddress) => {
	const exclude = arrayDropdownTypeComplement.map(item => item.name).join('|');
	const regex = new RegExp(`^\\w* \\d+(?: ?\\w*)* # ?\\d+(?: ?\\w*)? ?- ?\\d+ ?(?!${exclude})\\w\\S* (.*)`, "mu");
	const match = fullAddress.match(regex);
	return match ? match[1].trim() : '';
};

export default ComplementRow
