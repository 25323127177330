import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Inputs from '../../../../../components/mui/Inputs';
import Layout from '../../../../../components/mui/Layout';
import Feedback from '../../../../../components/mui/Feedback';
import DropdownSelect from '../../AreaPage/components/DropdownSelect';
import { setStore, getShowById, setUpdateById } from '../services';
import { setFormData, setIsLoadingShow, setIsLoadingForm, setRefreshTable, setIsOpenModal } from '../redux/actions/amenityAction';
import swalErrors from '../../../../../hooks/useErrors';
import usePermission from '../../../../../hooks/usePermission';
import { initialValuesAmenity as initialValues } from '../../../../../helpers/variablesInitialValues';
import { amenityArrayType, amenityArrayTypeInventory } from '../../../../../helpers/variablesColumns';

const FormComponent = () => {
	const dispatch = useDispatch();
	const refreshTable = useSelector( ({ amenity }) => amenity.refreshTable);
	const isLoadingShow = useSelector( ({ amenity }) => amenity.isLoadingShow);
	const isLoadingForm = useSelector( ({ amenity }) => amenity.isLoadingForm);
	const formType = useSelector( ({ amenity }) => amenity.formType);
	const formData = useSelector( ({ amenity }) => amenity.formData);
	const arrayListToDropdown = useSelector( ({ area }) => area.arrayListToDropdown);
	const permissionStore = usePermission('amenities.store');
	const permissionShow = usePermission('amenities.show');
	const permissionUpdate = usePermission('amenities.update');
	const [areasArrayList, setAreasArrayList] = useState([]);
	
	/**
	 *
	 * @description Execute endpoint show by id
	 * @return dispatch
	 *
	 */
	const handleShowById = async () => {
		if (formType === 'show' && permissionShow) {
			await dispatch( setIsLoadingShow(true) );
			
			try {
				const { data } = await getShowById(formData.id);
				
				if (data.status === 200) {
					const object = {
						id: data.data.id,
						type: data.data.type === null ? '' : data.data.type,
						name: data.data.name === null ? '' : data.data.name,
						typeInventory: data.data.type_inventory === null ? '' : data.data.type_inventory,
					};
					
					setAreasArrayList(data.data.active_area.length === 0 ? [] : data.data.active_area);
					await dispatch( setFormData(object) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingShow(false) );
		}
	};
	
	useEffect(() => {
		handleShowById();
	}, [formType]);
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			type: Yup.string().required('El tipo es requerido'),
			typeInventory: Yup.string().required('El tipo de inventario es requerido'),
			name: Yup.string().required('El nombre es requerido')
		})
	)
	
	/**
	 *
	 * @description Execute submit of the form
	 * @param values
	 * @param resetForm
	 * @param setValues
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		if (formType === 'store' ? permissionStore : permissionUpdate) {
			await dispatch( setIsLoadingForm(true) );
			
			try {
				const param = {
					type: formData.type === '' ? null : formData.type,
					name: formData.name === '' ? null : formData.name,
					type_inventory: formData.typeInventory === '' ? null : formData.typeInventory,
					active_area: areasArrayList.length === 0 ? [] : areasArrayList
				};
				
				let data;
				
				if (formType === 'store') {
					data = await setStore(param);
				} else {
					data = await setUpdateById(formData.id, param);
				}
				
				if (data.data.status === 200) {
					resetForm({ values: initialValues});
					setValues(initialValues);
					await dispatch( setFormData(initialValues) );
					await dispatch( setRefreshTable(!refreshTable) );
					await dispatch( setIsOpenModal(false) );
				}
			} catch (error) {
				await swalErrors(error);
			}
			
			await dispatch( setIsLoadingForm(false) );
		}
	};
	
	/**
	 *
	 * @description Execute dispatch formData state
	 * @return dispatch
	 *
	 */
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.name === 'hasChangeLogo' ? !formData.hasChangeLogo : target.value }) );
	
	/**
	 *
	 * @description Execute add item area
	 * @return void
	 *
	 */
	const handleonChangeArrayList = ({ target }) => {
		const array = [...areasArrayList];
		const find = areasArrayList.find(({ id }) => id === target.value);
		
		if (find) {
			handleDeleteItem(target.value);
		} else {
			const object = arrayListToDropdown.find(({ id }) => id === target.value);
			
			array.push({ id: object.id, name: object.name });
			setAreasArrayList(array);
		}
	};
	
	/**
	 *
	 * @description Execute delete item area
	 * @return void
	 *
	 */
	const handleDeleteItem = value => setAreasArrayList(areasArrayList.filter(({ id }) => id !== value));
	
	return (
		<>
			{isLoadingShow && (
				<Feedback.Loading />
			)}
			{!isLoadingShow && (
				<Formik
					initialValues={formData}
					validationSchema={validationSchema}
					onSubmit={hanbleSubmit}
				>
					{({
					  errors,
					  touched,
					  handleChange,
					  handleBlur,
					  isValid,
					  dirty
				  }) => (
						<Form noValidate>
							<div className="row animate__animated animate__fadeIn">
								<div className="col-sm-12">
									<Inputs.Select
										label="Tipo"
										name="type"
										value={formData.type}
										itemsArray={amenityArrayType}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.type && touched.type ? true : false}
										errorMessage={errors.type && touched.type ? errors.type : ''}
										className="font-family-roboto-regular fs-6 text-dark w-100"
									/>
								</div>
								<div className="col-sm-12">
									<Inputs.Select
										label="Tipo de inventario"
										name="typeInventory"
										value={formData.typeInventory}
										itemsArray={amenityArrayTypeInventory}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.typeInventory && touched.typeInventory ? true : false}
										errorMessage={errors.typeInventory && touched.typeInventory ? errors.typeInventory : ''}
										className="font-family-roboto-regular fs-6 text-dark w-100 mt-3"
									/>
								</div>
								<div className="col-sm-12">
									<Inputs.TextBox
										name="name"
										label="Nombre"
										placeholder="Ingrese nombre..."
										value={formData.name}
										onChange={e => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.name && touched.name ? true : false}
										errorMessage={errors.name && touched.name ? errors.name : ''}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
								<div className="col-sm-12">
									<DropdownSelect
										name="area"
										value={formData.area}
										handleChange={e => {
											handleOnChange(e)
											handleonChangeArrayList(e)
										}}
										className="font-family-roboto-medium text-dark fs-6 mt-3 w-100"
									/>
								</div>
							</div>
							
							{areasArrayList.length > 0 && (
								<div className="row">
									<div className="col-sm-12 mt-3">
										{areasArrayList.map(({ id, name }, index) => (
											<Fragment key={index}>
												<Layout.Chip
													label={name}
													size="medium"
													onDelete={() => handleDeleteItem(id)}
													className={`${index > 0 && 'ml-2'}`}
												/>
											</Fragment>
										))}
									</div>
								</div>
							)}
							
							{(formType === 'store' ? permissionStore : permissionUpdate) && (
								<div className="row">
									<div className="col-sm-12">
										<Inputs.LoadingButton
											isDisabled={formType === 'store' ? !(dirty && isValid) : false}
											isLoading={isLoadingForm}
											isLoadingPosition="start"
											startIcon={formType === 'store' ? <Layout.Icons.SaveIcon height={18} width={18} /> : <Layout.Icons.EditIcon height={18} width={18} />}
											label={`${formType === 'store' ? 'Guardar' : 'Actualizar'}`}
											type="submit"
											className={`${formType === 'store' ? ((dirty && isValid) && 'bg-primary') : 'bg-primary'} font-family-roboto-regular fs-6 text-capitalize mt-2 d-flex float-end`}
										/>
									</div>
								</div>
							)}
						</Form>
					)}
				</Formik>
			)}
		</>
	);
};

export default FormComponent;
