import React, { useEffect } from 'react'
import { history } from '../../../redux/store'

const CallCenterPageOld = () => {
	const param = new URLSearchParams(window.location.search)
	const uID = param.get("case_ID")
	
	useEffect(() => {
		history.push(`./filter/call-center/${uID}`)
	}, [uID])
	
	return (
		<></>
	)
}

export default CallCenterPageOld
