import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Inputs from '../../../../../components/mui/Inputs'
import Layout from '../../../../../components/mui/Layout'
import OccurrencesComponent from './OccurrencesComponent'
import DropdownSelect from '../../../settings/RealStatePage/components/DropdownSelect';
import DropdownSelectOriginChannel from '../../../settings/filtered/OriginChannelPage/components/DropdownSelect';
import DropdownSelectContact from '../../../settings/filtered/ContactPage/components/DropdownSelect';
import { setFormData, verificationByMobile, store, setResetValidate } from '../../../../../redux/actions/dashboard/filtered/filteredAction'
import { Permission } from '../../../../../services/permissions/Permission'
import { initialValuesFiltered as initialValues } from '../../../../../helpers/variablesInitialValues'

const FormComponent = () => {
	const dispatch = useDispatch()
	const isActiveValidateButton = useSelector( state => state.filtered.isActiveValidateButton)
	const isLoadingValidate = useSelector( state => state.filtered.isLoadingValidate)
	const isLoadingForm = useSelector( state => state.filtered.isLoadingForm)
	const isCompletedForm = useSelector( state => state.filtered.isCompletedForm)
	const formData = useSelector( state => state.filtered.formData)
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			firstName: Yup.string()
			.required('El nombre es requerido'),
			lastName: Yup.string()
			.required('El apellido es requerido'),
			mobile: Yup.string().matches(/^[0-9]+$/, "Sólo se permiten números")
			.required('El celular es requerido'),
			originChannel: Yup.string()
			.required('El canal de origen es requerido'),
			contact: Yup.string()
			.required('El contacto es requerido')
		})
	)
	
	/**
	 *
	 * @description Execute submit of the form
	 * @param status
	 * @param resetForm
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (status, resetForm) => {
		const param = {
			realstate: formData.realState,
			first_name: formData.firstName,
			last_name: formData.lastName,
			mobile: formData.mobile,
			origin_channel: formData.originChannel,
			contact: formData.contact
		}
		
		await dispatch( store(param, status) )
		await hanbleReset(isCompletedForm, resetForm)
	}
	
	/**
	 *
	 * @description Execute dispatch formData state
	 * @return dispatch
	 *
	 */
	const handleOnChange = ({ target }) => dispatch( setFormData( { ...formData, [target.name]: target.value }) )
	
	/**
	 *
	 * @description Execute endpoint verification by mobile
	 * @return dispatch
	 *
	 */
	const handleValidate = async () => await dispatch( verificationByMobile(formData.mobile) )
	
	/**
	 *
	 * @description Execute reset of the form
	 * @param isComplete
	 * @param resetForm
	 * @return dispatch
	 *
	 */
	const hanbleReset = async (isComplete, resetForm) => {
		if (isComplete) {
			await dispatch( setResetValidate() )
			await resetForm({ values: initialValues })
		}
	}
	
	useEffect(() => {
	
	}, [isCompletedForm])
	
	return (
		<Formik
			initialValues={formData}
			validationSchema={validationSchema}
			onSubmit={() => {}}
		>
			{({
				resetForm,
				errors,
				handleChange,
				handleBlur,
				isValid,
				dirty,
				touched
			}) => (
				<Form noValidate>
					<Permission permission="realstates.dropdown">
						<div className="row mb-3">
							<div className="col-sm-12 col-md-6">
								<DropdownSelect
									name="realState"
									value={formData.realState}
									handleChange={(e) => {
										handleOnChange(e)
										handleChange(e)
									}}
									className="font-family-roboto-regular fs-6 text-dark w-100"
								/>
							</div>
						</div>
					</Permission>

					<div className="row">
						<div className="col-8 col-sm-9 col-md-6">
							{!isActiveValidateButton && (
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="mobile"
									label="Celular"
									placeholder="Ingrese celular..."
									hasLabelFixed={true}
									value={formData.mobile}
									onChange={(e) => {
										handleOnChange(e)
										handleChange(e)
									}}
									onBlur={handleBlur}
									hasError={errors.mobile && touched.mobile ? true : false}
									errorMessage={errors.mobile && touched.mobile ? errors.mobile : ''}
									className="font-family-roboto-medium text-dark fs-6 w-100 animate__animated animate__fadeIn"
								/>
							)}
							{isActiveValidateButton && (
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="mobileReadOnly"
									label="Celular"
									placeholder="Ingrese celular..."
									hasLabelFixed={true}
									value={formData.mobile}
									readOnly={true}
									className="font-family-roboto-medium text-dark fs-6 w-100 animate__animated animate__fadeIn"
								/>
							)}
						</div>
						<div className="col-4 col-sm-3 col-md-6">
							{!isActiveValidateButton && (
								<Permission permission='filtereds.verification'>
									<Inputs.LoadingButton
										isDisabled={errors.mobile || formData.mobile === '' ? true : false}
										variant='contained'
										isLoading={isLoadingValidate}
										isLoadingPosition="start"
										startIcon={
											<Layout.Icons.SearchIcon
												height={18}
												width={18}
											/>
										}
										label="Validar"
										type="button"
										onClick={handleValidate}
										className={`${
											!errors.mobile &&
											formData.mobile !== '' &&
											'bg-primary'
										} font-family-roboto-regular fs-6 text-capitalize animate__animated animate__fadeIn`}
									/>
								</Permission>
							)}
						</div>
					</div>

					<div className="row">
						<div className="col-sm-12 col-md-6 mt-3">
							{!isActiveValidateButton && (
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="firstName"
									label="Nombre"
									placeholder="Ingrese nombre..."
									hasLabelFixed={true}
									value={formData.firstName}
									onChange={(e) => {
										handleOnChange(e)
										handleChange(e)
									}}
									onBlur={handleBlur}
									hasError={errors.firstName && touched.firstName ? true : false}
									errorMessage={errors.firstName && touched.firstName ? errors.firstName : ''}
									className="font-family-roboto-medium text-dark fs-6 w-100 animate__animated animate__fadeIn"
								/>
							)}
							{isActiveValidateButton && (
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="firstNameReadOnly"
									label="Nombre"
									placeholder="Ingrese nombre..."
									hasLabelFixed={true}
									value={formData.firstName}
									readOnly={true}
									className="font-family-roboto-medium text-dark fs-6 w-100 animate__animated animate__fadeIn"
								/>
							)}
						</div>
						<div className="col-sm-12 col-md-6 mt-3">
							{!isActiveValidateButton && (
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="lastName"
									label="Apellido"
									placeholder="Ingrese apellido..."
									hasLabelFixed={true}
									value={formData.lastName}
									onChange={(e) => {
										handleOnChange(e)
										handleChange(e)
									}}
									onBlur={handleBlur}
									hasError={errors.lastName && touched.lastName ? true : false}
									errorMessage={errors.lastName && touched.lastName ? errors.lastName : ''}
									className="font-family-roboto-medium text-dark fs-6 w-100 animate__animated animate__fadeIn"
								/>
							)}
							{isActiveValidateButton && (
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="lastNameReadOnly"
									label="Apellido"
									placeholder="Ingrese apellido..."
									hasLabelFixed={true}
									value={formData.lastName}
									readOnly={true}
									className="font-family-roboto-medium text-dark fs-6 w-100 animate__animated animate__fadeIn"
								/>
							)}
						</div>
					</div>

					<div className="row">
						<div className="col-sm-12 col-md-6 mt-3">
							{!isActiveValidateButton && (
								<DropdownSelectOriginChannel
									name="originChannel"
									value={formData.originChannel}
									handleChange={(e) => {
										handleOnChange(e)
										handleChange(e)
									}}
									onBlur={handleBlur}
									hasError={errors.originChannel && touched.originChannel ? true : false}
									errorMessage={errors.originChannel && touched.originChannel ? errors.originChannel : ''}
									className="font-family-roboto-regular fs-6 text-dark w-100 animate__animated animate__fadeIn"
								/>
							)}
							{isActiveValidateButton && (
								<Inputs.TextBox
									type="text"
									variant="outlined"
									size="small"
									name="originChannelName"
									label="Canal de origen"
									placeholder="Ingrese canal de origen..."
									hasLabelFixed={true}
									value={formData.originChannelName}
									readOnly={true}
									className="font-family-roboto-medium text-dark fs-6 w-100 animate__animated animate__fadeIn"
								/>
							)}
						</div>
						<Permission permission='contacts.dropdown'>
							<div className="col-sm-12 col-md-6 mt-3">
								{!isActiveValidateButton && (
									<DropdownSelectContact
										name="contact"
										value={formData.contact}
										handleChange={(e) => {
											handleOnChange(e)
											handleChange(e)
										}}
										onBlur={handleBlur}
										hasError={errors.contact && touched.contact ? true : false}
										errorMessage={errors.contact && touched.contact ? errors.contact : ''}
										className="font-family-roboto-regular fs-6 text-dark w-100 animate__animated animate__fadeIn"
									/>
								)}
								{isActiveValidateButton && (
									<Inputs.TextBox
										type="text"
										variant="outlined"
										size="small"
										name="contactName"
										label="Fuente de contacto"
										placeholder="Ingrese fuente de contacto..."
										hasLabelFixed={true}
										value={formData.contactName}
										readOnly={true}
										className="font-family-roboto-medium text-dark fs-6 w-100 animate__animated animate__fadeIn"
									/>
								)}
							</div>
						</Permission>
					</div>

					<div className="row min-h-40px">
						<OccurrencesComponent isActive={true} />

						<div className="col-12 col-sm-6 col-md-6 mt-3">
							{!isActiveValidateButton && formData.mobile !== '' && (
								<Permission permission='filtereds.store'>
									<Inputs.LoadingButton
										isDisabled={!(dirty && isValid)}
										variant="outlined"
										isLoading={isLoadingForm}
										isLoadingPosition="start"
										startIcon={<Layout.Icons.SaveIcon height={18} width={18} />}
										label="Guardar como Borrador"
										type="button"
										onClick={() => hanbleSubmit('Registrado', resetForm)}
										className={`${dirty && isValid && 'bg-secondary-outlined'} font-family-roboto-medium fs-6 text-uppercase d-flex float-start`}
									/>
								</Permission>
							)}
							{isActiveValidateButton && (
								<Inputs.Button
									variant="outlined"
									startIcon={<Layout.Icons.NavigateBeforeIcon height={18} width={18} />}
									label="Regresar"
									type="button"
									onClick={() => hanbleReset(true, resetForm)}
									className="font-family-roboto-medium fs-6 bg-secondary-outlined text-uppercase d-flex float-start"
								/>
							)}
						</div>
						<div className="col-12 col-sm-6 col-md-6 mt-3">
							{formData.mobile !== '' && (
								<Permission permission='filtereds.store'>
									<Inputs.LoadingButton
										isDisabled={isActiveValidateButton ? false : !(dirty && isValid)}
										variant='contained'
										isLoading={isLoadingForm}
										isLoadingPosition='end'
										endIcon={<Layout.Icons.NavigateNextIcon height={18} width={18} />}
										label={isActiveValidateButton ? 'Crear Nuevo Caso' : 'Crear y Continuar'}
										type="button"
										onClick={() => hanbleSubmit('Next', resetForm)}
										className={`${isActiveValidateButton ? 'bg-primary' : dirty && isValid && 'bg-primary'} font-family-roboto-medium fs-6 text-uppercase d-flex float-end`}
									/>
								</Permission>
							)}
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default FormComponent
