import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';

const ModalLoading = ({ maxWidth = 'xs', isOpen, title, content }) => {
	
	return (
		<Dialog
			disableEscapeKeyDown
			fullWidth={true}
			maxWidth={maxWidth}
			open={isOpen}
		>
			<DialogTitle className="p-2">
				<div className="font-family-roboto-medium fs-5 text-dark">
					{title}
				</div>
			</DialogTitle>
			<DialogContent dividers>
				<div className="d-inline-block">
					<CircularProgress
						style={{height: '32px', width: '32px'}}
						color="inherit"
					/>
				</div>
				<div className="position-absolute d-inline-block ml-3 mt-1">
					<div className="font-family-roboto-regular fs-6 text-secondary">
						{content}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

ModalLoading.propTypes = {
	maxWidth: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired
};

export default ModalLoading;
