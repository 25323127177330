import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../../components/mui/Layout';
import HeaderComponent from '../../auth/components/HeaderComponent';
import FooterComponent from '../../auth/components/FooterComponent';
import '../../auth/Auth.scss';

const ErrorPage = () => {
	const isAuthenticated = useSelector( state  => state.auth.isAuthenticated);
	
	useEffect(() => {
	}, [isAuthenticated]);
	
	return (
		<Layout.Page title="Error 404">
			<Layout.Card className="card form-container">
				<HeaderComponent />
				<Layout.CardContent className="m-0 pt-4 pr-4 pl-4 pb-0">
					<div className="row">
						<div className="col-sm-12 m-2">
							<h1 className="font-family-roboto-medium fs-1 text-danger text-center">
								404
							</h1>
							<h6 className="font-family-roboto-regular fs-5 text-gray text-center">
								Lo sentimos pero no pudimos encontrar la página que buscas
							</h6>
						</div>
					</div>
				</Layout.CardContent>
				<FooterComponent
					to={isAuthenticated ? '/dashboard/main' : '/'}
					label={`Volver al ${isAuthenticated ? 'escritorio' : 'inicio de sesión'}`}
				/>
			</Layout.Card>
		</Layout.Page>
	);
};

export default ErrorPage;
