import { initialValuesPropertyMobili as initialValues } from '../../../../../../helpers/variablesInitialValues'
import * as types from '../../../../../actionsTypes/dashboard/settings/properties/propertyMobiliTypes'
import { setPropertyDomusReset } from '../domus/propertyDomusAction'
import { getShowByCode, setStoreByCode } from './services'
import errorServices from '../../../../../../services/errors'
import { swalSuccess } from '../../../../../../services/sweetalert'

export const setPropertyMobiliStore = param => (
	async (dispatch) => {
		await dispatch( setIsLoadingButton(true) )
		
		await setStoreByCode(param).then(({ data }) => {
			dispatch( setPropertyDomusReset() )
			dispatch( setPropertyMobiliReset() )
			swalSuccess('Migración exitosa', data.data)
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoadingButton(false) )
	}
)

export const getPropertyMobiliShowByCode = code => (
	async (dispatch) => {
		await dispatch( setIsLoadingForm(true) )
		
		await getShowByCode(code).then(({ data }) => {
			let amenitiesName = ''
			
			if (data.data.areas.length > 0) {
				let array = []
				
				data.data.areas.map(({ amenities }) => {
					if (amenities.length > 0) {
						amenities.map((row) => {
							array.push(row)
						})
					}
				})
				
				amenitiesName = array.length === 0 ? '' : array.toString().split(', ')
			}
			
			const object = {
				id: data.data.id,
				codeDomus: data.data.domus_code,
				statusCommercialName: data.data.status_commercial === null ? null : data.data.status_commercial,
				registerDate: data.data.register_date === null ? null : data.data.register_date,
				registerAt: data.data.register_date === null ? null : data.data.register_at,
				updateDate: data.data.update_date === null ? null : data.data.update_date,
				updateAt: data.data.update_date === null ? null : data.data.update_at,
				publicationDate: data.data.publication_date === null ? null : data.data.publication_date,
				publicationAt: data.data.publication_date === null ? null : data.data.publication_at,
				consignationDate: data.data.consignation_date === null ? null : data.data.consignation_date,
				consignationAt: data.data.consignation_date === null ? null : data.data.consignation_at,
				updatedDate: data.data.updated_date === null ? null : data.data.updated_date,
				updatedAgo: data.data.updated_ago === null ? null : data.data.updated_ago,
				updatedAt: data.data.updated_at === null ? null : data.data.updated_at,
				synchronizedDate: data.data.synchronized_date === null ? null : data.data.synchronized_date,
				synchronizedAgo: data.data.synchronized_ago === null ? null : data.data.synchronized_ago,
				synchronizedAt: data.data.synchronized_at === null ? null : data.data.synchronized_at,
				actionName: data.data.action === null ? null : data.data.action,
				valueAdmin: data.data.value_admin === null ? null : data.data.value_admin,
				valueAdminString: data.data.value_admin_string === null ? null : data.data.value_admin_string,
				canon: data.data.canon === null ? null : data.data.canon,
				canonString: data.data.canon_string === null ? null : data.data.canon_string,
				saleValue: data.data.sale_value === null ? null : data.data.sale_value,
				saleValueString: data.data.sale_value_string === null ? null : data.data.sale_value_string,
				commissionPercentage: data.data.commission_percentage === null ? null : data.data.commission_percentage,
				stateName: data.data.state === null ? null : data.data.state,
				cityName: data.data.city === null ? null : data.data.city,
				neighborhoodName: data.data.neighborhood === null ? null : data.data.neighborhood,
				zoneName: data.data.zone === null ? null : data.data.zone,
				address: data.data.address === null ? null : data.data.address,
				reference: data.data.reference === null ? null : data.data.reference,
				comment: data.data.comment === null ? null : data.data.comment,
				latitude: data.data.latitude === null ? null : data.data.latitude,
				longitude: data.data.longitude === null ? null : data.data.longitude,
				numberRoom: data.data.number_room === null ? null : data.data.number_room,
				numberBathroom: data.data.number_bathroom === null ? null : data.data.number_bathroom,
				numberGarage: data.data.number_garage === null ? null : data.data.number_garage,
				strata: data.data.strata === null ? null : data.data.strata,
				enrollment: data.data.enrollment === null ? null : data.data.enrollment,
				yearConstruction: data.data.year_construction === null ? null : data.data.year_construction,
				areaConstruction: data.data.area_construction === null ? null : data.data.area_construction,
				areaTotal: data.data.area_total === null ? null : data.data.area_total,
				areaFront: data.data.front === null ? null : data.data.front,
				areaBackground: data.data.background === null ? null : data.data.background,
				typeName: data.data.type === null ? null : data.data.type,
				destinationName: data.data.destination === null ? null : data.data.destination,
				amenitiesName: amenitiesName
			}
			
			dispatch( setFormData(object) )
			dispatch( setArrayListImages(data.data.images.length === 0 ? [] : data.data.images) )
			dispatch( setArrayListImages360(data.data.images360.length === 0 ? [] : data.data.images360) )
			dispatch( setArrayListVideos(data.data.videos.length === 0 ? [] : data.data.videos) )
			dispatch( setArrayListTours(data.data.tours.length === 0 ? [] : data.data.tours) )
			dispatch( setEventType('update') )
		}).catch(error => {
			dispatch( setArrayListImages([]) )
			dispatch( setArrayListImages360([]) )
			dispatch( setArrayListVideos([]) )
			dispatch( setArrayListTours([]) )
			dispatch( setEventType('store') )
			dispatch( setFormData(initialValues) )
			errorServices(error)
		})
		
		await dispatch( setIsLoadingForm(false) )
	}
)

export const setPropertyMobiliReset = () => (
	async (dispatch) => {
		await dispatch( setArrayListImages([]) )
		await dispatch( setArrayListImages360([]) )
		await dispatch( setArrayListVideos([]) )
		await dispatch( setArrayListTours([]) )
		await dispatch( setIsActiveButton(false) )
		await dispatch( setIsLoadingButton(false) )
		await dispatch( setIsLoadingForm(false) )
		await dispatch( setEventType('') )
		await dispatch( setFormData(initialValues) )
	}
)

export const setArrayListImages = value => ({
	type: types.ARRAY_LIST_IMAGES_PROPERTY_MOBILI,
	payload: value
})

export const setArrayListImages360 = value => ({
	type: types.ARRAY_LIST_IMAGES360_PROPERTY_MOBILI,
	payload: value
})

export const setArrayListVideos = value => ({
	type: types.ARRAY_LIST_VIDEOS_PROPERTY_MOBILI,
	payload: value
})

export const setArrayListTours = value => ({
	type: types.ARRAY_LIST_TOURS_PROPERTY_MOBILI,
	payload: value
})

export const setIsActiveButton = value => ({
	type: types.IS_ACTIVE_BUTTON_PROPERTY_MOBILI,
	payload: value
})

export const setIsLoadingButton = value => ({
	type: types.IS_LOADING_BUTTON_PROPERTY_MOBILI,
	payload: value
})

export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_PROPERTY_MOBILI,
	payload: value
})

export const setEventType = value => ({
	type: types.EVENT_TYPE_PROPERTY_MOBILI,
	payload: value
})

export const setFormData = value => ({
	type: types.FORM_DATA_PROPERTY_MOBILI,
	payload: value
})
