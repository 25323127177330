import React from 'react'
import { Box } from '@mui/material'
import Layout from '../../../components/mui/Layout'
import ThemeProviderComponent from '../../../theme-v2/themeProvider'

import ModalForContract from '../FilteredPage/components-v2/ModalForContract'
import ContactProvider from '../FilteredPage/components-v2/contexts/ContactContext'

import ContactSection from './components/ContactInfoSection'
import ActionButtonsLeftSide from './components/ActionButtonsLeftSide'
import FormComponent from './components/FormComponent'
import SendToCommercialLeftSection from './components/SendToCommercialLeftSection'
import SquarePropertyCard from '../../../components/v2-mobili/cards/SquarePropertyCard'
import ToogleSwitchForRightSection from './components/ToggleSwitchForRightSection'
import { TableForActivityRecords } from '../../../components/v2-mobili/tables'

const fakeListOfRecords = [
  ...Array.from(Array(100)).map((_, i) => [
    `#${1245 + i}`,
    'lorem sdsdsdsd',
    'John Doe',
  ]),
]

const ContactPage = () => {
  // const ref = React.useRef(null);
  // React.useEffect(() => {
  //   // ref.current.style.hei
  // }, []);
  const [sendToCommercialSection, setSendToCommercialSection] =
    React.useState(true)
  return (
    <ThemeProviderComponent>
      <Layout.Page title='Pagina de contactos - inmuebles'>
        <Box sx={MainContentStyles}>
          <Box sx={LeftSectionStyles}>
            <ContactSection />
            <Box flexGrow={1} sx={ScrollableFormStyles}>
              <FormComponent />
            </Box>
            {sendToCommercialSection && <SendToCommercialLeftSection />}
            {!sendToCommercialSection && <ActionButtonsLeftSide />}
          </Box>
          <Box
            display='flex'
            overflow='hidden'
            flexDirection='column'
            // gap={2}
            // sx={{ outline: "1px solid transparent" }}
          >
            <ToogleSwitchForRightSection />
            <Box
              gap={2}
              width='95%'
              display='grid'
              margin='0 auto'
              overflow='scroll'
              gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'>
              {Array.from(Array(2)).map((_, index) => (
                <SquarePropertyCard
                  key={index}
                  favorite={(index + 1) % 2 == 0}
                />
              ))}
            </Box>
            <Box
              sx={{
                px: 2,
                // pt: 1,
                bgcolor: 'background.paper',
              }}>
              <TableForActivityRecords
                list={fakeListOfRecords}
                sxProps={{
                  mt: 2,
                  maxHeight: '220px',
                  overflow: 'scroll',
                  // display: "grid",
                  // gap: "5px",
                }}
              />
            </Box>
          </Box>
        </Box>

        <ContactProvider>
          <ModalForContract />
        </ContactProvider>
      </Layout.Page>
    </ThemeProviderComponent>
  )
}
/**
 * @type {React.CSSProperties}
 */
const MainContentStyles = {
  gap: '50px',
  display: 'grid',
  overflow: 'hidden',
  position: 'relative',
  height: 'calc(100vh - 70px)',
  outline: '1px solid red',
  gridTemplateColumns: '1fr 1fr',
}

/**
 * @type {React.CSSProperties}
 */
const LeftSectionStyles = {
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  flexDirection: 'column',
  backgroundColor: (theme) => theme.palette.background.paper,
}

const ScrollableFormStyles = {
  p: 2,
  overflow: 'scroll',
}

export default ContactPage
