import React from 'react'
import PermMedia from '@material-ui/icons/PermMedia'

export default function PermMediaIcon(props) {
	
	const { ...other } = props
	
	return (
		<PermMedia { ...other } />
	)
}
