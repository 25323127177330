import React from 'react'
import PropTypes from 'prop-types'
import ImageList from '@mui/material/ImageList'
import Feedback from '../../../../../../components/mui/Feedback'

const VideosToursComponent = ({ isLoading, arrayList = [] }) => {
	
	return (
		<>
			{!isLoading && (
				arrayList.length > 0 && (
					<Feedback.ScrollBar style={{ minHeight: '200px', maxHeight: '450px', overflowX: 'hidden' }}>
						<ImageList
							cols={2}
							rowHeight={200}
							className="w-100 h-100 overflow-hidden"
						>
							{arrayList.map(({ path }, number) => (
								<div key={number}>
									<iframe
										style={{ backgroundColor: 'lightgray' }}
										width="100%"
										height={200}
										frameBorder={0}
										allow="xr-spatial-tracking; gyroscope; accelerometer"
										allowFullScreen
										scrolling="no"
										src={path}
									/>
								</div>
							))}
						</ImageList>
					</Feedback.ScrollBar>
				)
			)}
		</>
	)
}

VideosToursComponent.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	arrayList: PropTypes.array
}

export default VideosToursComponent
