import * as types from '../types/commercialType';
import { initialValuesCommercialFilter as initialValues } from '../../../../../helpers/variablesInitialValues';

/**
 *
 * @description Set reset commercialStatus state
 * @return dispatch
 *
 */
export const setCommercialReset = () => (
	async (dispatch) => {
		await dispatch( setFormFilter(initialValues) );
		await dispatch( setRefreshTable(false) );
	}
);

/**
 *
 * @description Set filter state
 * @param value
 * @return object
 *
 */
export const setFormFilter = value => ({
	type: types.FORM_FILTER_COMMERCIAL,
	payload: value
});

/**
 *
 * @description Set refreshTable state
 * @param value
 * @return object
 *
 */
export const setRefreshTable = value => ({
	type: types.REFRESH_TABLE_COMMERCIAL,
	payload: value
});
