import React from 'react'
import MuiButton from "@material-ui/core/Button"
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    label: {
        textTransform: 'none',
        fontSize: '18px',
    },
    blue: {
        width: '6%',
        height: '50px',
        fontSize: '36px',
        color: '#f9f9f9',
        backgroundColor: '#2435C8',
        borderRadius: '0px 5px 5px 0px !important',
        boxShadow: 'none !important',
        '&:hover': {
            borderRadius: '0px 5px 5px 0px !important',
            backgroundColor: '#1d2a97',
            color: '#f9f9f9',
            boxShadow: 'none !important'
        }
    },
}))

export default function ButtonSearch(props) {

    const classes = useStyles()
    const { icon, color, variant, ...other } = props

    return (
        <MuiButton
            color="inherit"
            variant={variant || "standard"}
            classes={{ label: classes.label }}
            className={classes.blue}
            {...other}
        >
            {icon}
        </MuiButton>
    )
}