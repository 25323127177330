import React from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore'

export default function ExpandMoreIcon(props) {

    const { ...other } = props

    return (
        <ExpandMore { ...other }  />
    )
}
