import { Avatar } from '@mui/material'
import React from 'react'
import { Card, H1, H3, H4, H6, Span } from '../styles'
import styles from './infocustomer.module.css'

export function InfoCustomer({ filterData }) {
  return (
    <>
      <section className={styles.box}>
        <div>
          <H1>Hola {filterData?.customer}</H1>
          <H4>
            He seleccionado{' '}
            {filterData?.favorites?.length > 1
              ? `${filterData?.favorites?.length} propiedades para tí`
              : `${filterData?.favorites?.length} propiedad para tí`}
          </H4>
        </div>
        <Card>
          {/* <Avatar
            alt="Remy Sharp"
            src="https://randomuser.me/api/portraits/women/6.jpg"
            sx={{ width: 100, height: 100 }}
          /> */}
          <div className={styles.infoUser}>
            <H6>Asesor</H6>
            <H3>{filterData?.adviser}</H3>
            <div className={styles.infoUserTel}>
              {[filterData?.phone, filterData?.mobile].filter(phone => phone).map(
                (phone, index) => (
                  <Span key={index}>{phone}</Span>
                )
              )}
            </div>
            <div className={styles.infoEmail}>
              <Span key="email">{filterData?.email}</Span>
            </div>
          </div>
        </Card>
      </section>
    </>
  )

}
