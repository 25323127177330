import {
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import SearchIcon from '../../../display/icons/SearchIcon'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Autocomplete from '@mui/material/Autocomplete'
import images from '../../img'
import { makeStyles } from '@material-ui/core/styles'
import SimpleBarReact from 'simplebar-react'

const useStyles = makeStyles({
  title: {
    color: '#273137',
    fontSize: '.80rem',
    fontWeight: 700,
  },
  root: {
    color: '#273137',
    fontSize: '.80rem',
    fontWeight: 700,
  },
})

const FormAddActivity = () => {
  const classes = useStyles()
  const [value, setValue] = React.useState(null)
  const [showPopupIcon, setShowPopupIcon] = useState(true)
  const [showBoxAddImages, setShowBoxAddImages] = useState(false)
  return (
    <Box
      display="flex"
      alignItems={'flex-start'}
      justifyContent="flex-start"
      flexDirection={'column'}
      gap={'25px'}
    >
      <Typography variant="h3">Nuevo inventario</Typography>

      <Box display="flex" gap={4} width={'100%'}>
        <TextField
          placeholder="Selecciona una opción"
          label="Tipo de inventario"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ width: '100%' }}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Fecha inventario inicial"
            value={value}
            onChange={(newValue) => {
              setValue(newValue)
            }}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: '100%' }} size="small" />
            )}
          />
        </LocalizationProvider>
      </Box>

      <Typography variant="h5">Funcionarios asignados</Typography>

      <Box display="flex" gap={4} width={'100%'}>
        <Autocomplete
          disablePortal
          id="combo-box-asesor"
          options={top100Films}
          sx={{ width: '100%' }}
          popupIcon={showPopupIcon ? <SearchIcon /> : () => null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Selecciona un usuario"
              label="Asesor"
              size="small"
            />
          )}
          onFocus={() => setShowPopupIcon(false)}
          onBlur={() => setShowPopupIcon(true)}
        />
        <Autocomplete
          disablePortal
          id="combo-box-Inventarista"
          options={top100Films}
          sx={{ width: '100%' }}
          popupIcon={<SearchIcon />}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Selecciona un usuario"
              label="Inventarista"
              size="small"
            />
          )}
        />
      </Box>

      <TextField
        placeholder="Escribe una descripción aquí..."
        label="Descripción"
        size="small"
        sx={{ width: '100%' }}
        multiline
        rows={2}
      />

      <Typography variant="h5">Agregar contenido media</Typography>

      <TextField
        placeholder="Selecciona una opción"
        label="Tipo de archivo"
        size="small"
        select
        sx={{ width: '100%' }}
      >
        {optionsTipoFile.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Box sx={StyleBoxAddFiles}>
        <Box
          display="flex"
          alignItems={'flex-start'}
          justifyContent="flex-start"
          flexDirection={'column'}
        >
          <Typography variant="h6">Agrega contenido media</Typography>
        </Box>

        {/* section for uploading and displaying photos and videos */}
        {showBoxAddImages ? (
          <Box sx={StyleBoxAddImg}>
            <div>
              <img src={images.AddFilesBro} alt="" />
            </div>
            <div>
              <Button
                variant="outlined"
                component="label"
                size="small"
                className="bg-secondary-outlined"
              >
                SELECCIONAR ARCHIVOS
                <input type="file" multiple hidden />
              </Button>
            </div>
          </Box>
        ) : (
          <Box sx={StyleBoxShowImg}>
            <SimpleBarReact
              style={{
                maxHeight: '145px',
                width: '100%',
                overflowX: 'hidden',
                marginTop: '1rem',
                // overflowY: 'hidden',
              }}
            >
              <ImageList cols="3">
                {itemData.map((item) => (
                  <ImageListItem
                    key={item.img}
                    sx={{
                      fontWeight: '700',
                      fontSize: '9px',
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <img
                      src={`${item.img}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.title}
                      loading="lazy"
                      style={{
                        borderRadius: '5px',
                        width: '190px',
                        height: '125px',
                      }}
                    />

                    <Box
                      width={'190px'}
                      display="flex"
                      alignItems={'center'}
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="small"
                        classes={{ root: classes.root }}
                      >
                        {item.title}
                      </Typography>
                      <div>
                        <img
                          src={images.IconDelete}
                          alt=""
                          style={{
                            width: '15px',
                            height: '15px',
                          }}
                        />
                      </div>
                    </Box>
                  </ImageListItem>
                ))}
              </ImageList>
            </SimpleBarReact>
            <Button
              sx={{ width: '10rem' }}
              variant="outlined"
              component="label"
              size="small"
              className="bg-secondary-outlined"
            >
              AGREGAR MÁS
              <input type="file" multiple hidden />
            </Button>
          </Box>
        )}
      </Box>
      <Button variant="contained">AGREGAR ACTIVIDAD</Button>
    </Box>
  )
}

export default FormAddActivity

const StyleBoxAddFiles = {
  width: '100%',
  height: '258px',
  border: '1.5px solid #DFE3E9',
  borderRadius: '2px',
  padding: '15px',
}
const StyleBoxAddImg = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}
const StyleBoxShowImg = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',
}

const optionsTipoFile = [
  {
    label: 'Foto',
    value: 1,
  },
  {
    label: 'Foto 360',
    value: 2,
  },
  {
    label: 'Video',
    value: 3,
  },
  {
    label: 'Tour Virtual',
    value: 4,
  },
]

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  {
    label: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { label: 'The Good, the Bad and the Ugly', year: 1966 },
  { label: 'Fight Club', year: 1999 },
  {
    label: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    label: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { label: 'Forrest Gump', year: 1994 },
  { label: 'Inception', year: 2010 },
  {
    label: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: 'Goodfellas', year: 1990 },
  { label: 'The Matrix', year: 1999 },
  { label: 'Seven Samurai', year: 1954 },
  {
    label: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
]

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    author: '@nolanissac',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    author: '@hjrc33',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    author: '@southside_customs',
  },
]
