import React from 'react';
import Layout from '../../../components/mui/Layout';
import SearchComponent from './components/SearchComponent';
import KanbanComponent from './components/KanbanComponent';

const CommercialPage = () => {

  return (
    <Layout.Page title="Comercial">
      <SearchComponent />

      <KanbanComponent />
    </Layout.Page>
  );
};

export default CommercialPage;
