import { initialValuesUser as initialValues } from '../../../../../../helpers/variablesInitialValues';
import * as types from '../types/userType';

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	search: '',
	realState: '',
	arrayList: [],
	arrayDropdown: [],
	arrayDropdownAgent: [],
	arrayDropdownAdviser: [],
	refreshTable: false,
	isOpenModal: false,
	isOpenModalPermission: false,
	isLoadingShow: false,
	isLoadingForm: false,
	formType: '',
	formData: initialValues
};

const userReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_USER:
			return {
				...state,
				perPage: payload
			};
		
		case types.PAGE_USER:
			return {
				...state,
				page: payload
			};
		
		case types.PAGES_USER:
			return {
				...state,
				pages: payload
			};
		
		case types.SEARCH_USER:
			return {
				...state,
				search: payload
			};
		
		case types.REALSTATE_USER:
			return {
				...state,
				realState: payload
			};
		
		case types.ARRAY_LIST_USER:
			return {
				...state,
				arrayList: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_USER:
			return {
				...state,
				arrayDropdown: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_AGENT_USER:
			return {
				...state,
				arrayDropdownAgent: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_ADVISER_USER:
			return {
				...state,
				arrayDropdownAdviser: payload
			};
		
		case types.REFRESH_TABLE_USER:
			return {
				...state,
				refreshTable: payload
			};
		
		case types.IS_OPEN_MODAL_USER:
			return {
				...state,
				isOpenModal: payload
			};
		
		case types.IS_OPEN_MODAL_PERMISSION_USER:
			return {
				...state,
				isOpenModalPermission: payload
			};
		
		case types.IS_LOADING_SHOW_USER:
			return {
				...state,
				isLoadingShow: payload
			};
			
		case types.IS_LOADING_FORM_USER:
			return {
				...state,
				isLoadingForm: payload
			};
		
		case types.FORM_TYPE_USER:
			return {
				...state,
				formType: payload
			};
		
		case types.FORM_DATA_USER:
			return {
				...state,
				formData: payload
			};
		
		default:
			return state;
	}
};

export default userReducer;
