import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from "@material-ui/core/IconButton"
import Display from "../../../../../components/display/Display"
import Controls from "../../../../../components/controls/Controls"
import Feedback from "../../../../../components/feedback/Feedback"
import { makeStyles } from '@material-ui/core/styles'
import errorServices from '../../../../../services/errors'
import { swalSuccess } from '../../../../../services/sweetalert'
import axios from '../../../../../services/axios'

const useStyles = makeStyles((theme) => ({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		// top: theme.spacing(1)
	},
}))

const ModalAuthorization = ({ getPropertyID, open, setOpen, handleList }) => {
	const classes = useStyles()
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [getLoading, setLoading] = useState(false)
	const [getAuthorizationCommercial, setAuthorizationCommercial] = useState('')
	const [getAuthorizationCommercialError, setAuthorizationCommercialError] = useState(false)
	const [getAuthorizationCommercialMessage, setAuthorizationCommercialMessage] = useState("")
	const [getConditionRent, setConditionRent] = useState('')
	const [getConditionRentError, setConditionRentError] = useState(false)
	const [getConditionRentMessage, setConditionRentMessage] = useState("")
	const [getConditionSale, setConditionSale] = useState('')
	const [getConditionSaleError, setConditionSaleError] = useState(false)
	const [getConditionSaleMessage, setConditionSaleMessage] = useState("")
	const [getDuration, setDuration] = useState('')
	const [getDurationError, setDurationError] = useState(false)
	const [getDurationMessage, setDurationMessage] = useState("")
	const [getResponsibility, setResponsibility] = useState('')
	const [getResponsibilityError, setResponsibilityError] = useState(false)
	const [getResponsibilityMessage, setResponsibilityMessage] = useState("")
	const [getExecutiveMerit, setExecutiveMerit] = useState('')
	const [getExecutiveMeritError, setExecutiveMeritError] = useState(false)
	const [getExecutiveMeritMessage, setExecutiveMeritMessage] = useState("")
	
	const handlePropertyShow = async () => {
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.get(`dashboard/properties/${getPropertyID}`)
			
			if (data.status === 200) {
				setAuthorizationCommercial(`En mi calidad de propietario, tenedor o apoderado, consigno a ${data.data.data.realstate} el inmueble cuyos datos y especificaciones se describen en este documento.  Autorizo a ${data.data.data.realstate}, a gestionar el arriendo de dicho inmueble en la forma y oportunidades que consideren convenientes y necesarias para tal fin.`)
				setConditionRent(`Una vez se arriende el inmueble consignado a un cliente presentado por ${data.data.data.realstate}, me obligo a firmar un convenio de administración con ${data.data.data.realstate} y en consecuencia, a pagar los conceptos de comisión de administración, seguro inmobiliario y comisión de mercadeo segun se indica en las Condiciones de Comercialización del presente documento. El pago de la comisión por administración y el seguro (si lo hubiere) se hacen exigibles mensualmente, desde la fecha en que se perfeccione el contrato de arriendo. La comisión de mercadeo se pagará una única vez, para cada nuevo contrato de arriendo.  Todos los valores son calculados con base en el valor del canon mensual.  La comisión de administración tiene un mínimo, que se aplica cuando el valor calculado es inferior.`)
				setConditionSale(`En caso de que el inmueble se venda a un cliente presentado por ${data.data.data.realstate}, me obligo a pagar a ${data.data.data.realstate} por concepto de remuneración por corretaje sobre el valor de la venta, la comisión que se indica en las Condiciones de Comercialización del presente documento.  El pago de dicha remuneración se hace exigible desde la fecha en que se firme la promesa de compraventa o la escritura de venta, lo que primero ocurra para efectos legales.`)
				setDuration(`El término de este convenio se establece por un periodo de noventa (90) días vencidos.  Si alguna de las dos partes no lo da por terminado mediante aviso escrito comunicado con treinta (30) días de anticipación, se entenderá prorrogado por períodos iguales sucesivos.`)
				setResponsibility(`Afirmo reconocer y aceptar que ${data.data.data.realstate} no es ni será responsable por daños o destrucción del inmueble causado por fuerza mayor o caso fortuito (incendios, explosión, asonadas, robos, etc.). Asimismo, no tiene responsabilidad por daños y/o robos al inmueble mientras está desocupado. Es responsabilidad exclusiva del propietario contratar la seguridad que estime conveniente mientras el inmueble se encuentra desocupado, así como el pago de servicios públicos y administración.`)
				setExecutiveMerit(`Para los efectos legales y para cobros de los dineros adeudados este documento presentará merito ejecutivo.`)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	useEffect(() => {
		open  && handlePropertyShow()
	}, [getPropertyID, open])
	
	const handleClose = () => {
		setOpen(false)
	}
	
	const setHandleForm = async (e) => {
		e.preventDefault()
		
		if (open) {
			if (getAuthorizationCommercial === "" || getConditionRent === "" || getConditionSale === '' || getDuration === '' || getResponsibility === '' || getExecutiveMerit === '') {
				setAuthorizationCommercialError(getAuthorizationCommercial === "" ? true : false)
				setAuthorizationCommercialMessage(getAuthorizationCommercial === "" ? "El campo es requerido" : "")
				setConditionRentError(getConditionRent === "" ? true : false)
				setConditionRentMessage(getConditionRent === "" ? "El campo es requerido" : "")
				setConditionSaleError(getConditionSale === "" ? true : false)
				setConditionSaleMessage(getConditionSale === "" ? "El campo es requerido" : "")
				setDurationError(getDuration === "" ? true : false)
				setDurationMessage(getDuration === "" ? "El campo es requerido" : "")
				setResponsibilityError(getResponsibility === "" ? true : false)
				setResponsibilityMessage(getResponsibility === "" ? "El campo es requerido" : "")
				setExecutiveMeritError(getExecutiveMerit === "" ? true : false)
				setExecutiveMeritMessage(getExecutiveMerit === "" ? "El campo es requerido" : "")
				
				return false
			}
			
			setLoading(true)
			
			try {
				axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
				
				const param = {
					"property_id": getPropertyID,
					"authorization_for_commercial": getAuthorizationCommercial,
					"conditions_for_rent": getConditionRent,
					"conditions_for_sale": getConditionSale,
					"duration": getDuration,
					"responsibility": getResponsibility,
					"executive_merit": getExecutiveMerit
				}
				
				const data = await axios.post(`dashboard/properties/authorizations`, param)
				
				if (data.status === 200) {
					setAuthorizationCommercial('')
					setConditionRent('')
					setConditionSale('')
					setDuration('')
					setResponsibility('')
					setExecutiveMerit('')
					swalSuccess(data.data.data)
					handleList()
					handleClose()
				}
			} catch (error) {
				await errorServices(error)
			}
			
			setLoading(false)
		}
	}
	
	return (
		<>
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				fullWidth={true}
				maxWidth="md"
				aria-labelledby="dialogForm"
				open={open}
			>
				<DialogTitle id="dialogForm">
					Captación de la autorización
					
					<IconButton
						onClick={handleClose}
						className="bg-white height-32 width-32"
						classes={{ root: classes.closeButton }}
						aria-label="like"
					>
						<Display.Icons.CloseIcon
							className="text-dark"
							style={{ fontSize: 20 }}
						/>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<form>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6}>
								<Controls.TextArea
									label="Autorización para comercial"
									name="authorization_for_commercial"
									defaultValue={getAuthorizationCommercial}
									error={getAuthorizationCommercialError}
									message={getAuthorizationCommercialMessage}
									onChange={e => {
										setAuthorizationCommercial(e.target.value)
										
										if(e.target.value === ""){
											setAuthorizationCommercialError(true)
											setAuthorizationCommercialMessage('El campo es requerido')
										} else {
											setAuthorizationCommercialError(false)
											setAuthorizationCommercialMessage('')
										}
									}}
									onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controls.TextArea
									label="Condiciones para arriendo"
									name="condition_for_rent"
									defaultValue={getConditionRent}
									error={getConditionRentError}
									message={getConditionRentMessage}
									onChange={e => {
										setConditionRent(e.target.value)
										
										if(e.target.value === ""){
											setConditionRentError(true)
											setConditionRentMessage('El campo es requerido')
										} else {
											setConditionRentError(false)
											setConditionRentMessage('')
										}
									}}
									onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controls.TextArea
									label="Condiciones para venta"
									name="condition_for_sale"
									defaultValue={getConditionSale}
									error={getConditionSaleError}
									message={getConditionSaleMessage}
									onChange={e => {
										setConditionSale(e.target.value)
										
										if(e.target.value === ""){
											setConditionSaleError(true)
											setConditionSaleMessage('El campo es requerido')
										} else {
											setConditionSaleError(false)
											setConditionSaleMessage('')
										}
									}}
									onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controls.TextArea
									label="Duración"
									name="duration"
									defaultValue={getDuration}
									error={getDurationError}
									message={getDurationMessage}
									onChange={e => {
										setDuration(e.target.value)
										
										if(e.target.value === ""){
											setDurationError(true)
											setDurationMessage('El campo es requerido')
										} else {
											setDurationError(false)
											setDurationMessage('')
										}
									}}
									onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controls.TextArea
									label="Responsabilidad"
									name="responsibility"
									defaultValue={getResponsibility}
									error={getResponsibilityError}
									message={getResponsibilityMessage}
									onChange={e => {
										setResponsibility(e.target.value)
										
										if(e.target.value === ""){
											setResponsibilityError(true)
											setResponsibilityMessage('El campo es requerido')
										} else {
											setResponsibilityError(false)
											setResponsibilityMessage('')
										}
									}}
									onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controls.TextArea
									label="Mérito ejecutivo"
									name="executive_merit"
									defaultValue={getExecutiveMerit}
									error={getExecutiveMeritError}
									message={getExecutiveMeritMessage}
									onChange={e => {
										setExecutiveMerit(e.target.value)
										
										if(e.target.value === ""){
											setExecutiveMeritError(true)
											setExecutiveMeritMessage('El campo es requerido')
										} else {
											setExecutiveMeritError(false)
											setExecutiveMeritMessage('')
										}
									}}
									onKeyPress={e => e.charCode === 13 && setHandleForm(e) }
								/>
							</Grid>
							<Grid item xs={12}>
								<div className="float-right">
									<Controls.Button
										label="Cancelar"
										color="secondary"
										variant="contained"
										endIcon={<Display.Icons.CancelOutlinedIcon />}
										onClick={handleClose}
									/>
									<Controls.Button
										label="Guardar"
										color="primary"
										variant="contained"
										endIcon={<Display.Icons.SaveOutlinedIcon />}
										onClick={(e) => setHandleForm(e)}
									/>
								</div>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
			<Feedback.DialogLoading
				getLoading={getLoading}
				setLoading={setLoading}
			/>
		</>
	)
}

export default ModalAuthorization
