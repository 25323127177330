const ROOT = 'dashboard/settings/projects';

export default {
	arrayList: `${ROOT}/list`,
	arrayListToDropdown: `${ROOT}`,
	store: `${ROOT}`,
	show: `${ROOT}/`,
	status: `${ROOT}/status/`,
	update: `${ROOT}/`,
	destroy: `${ROOT}/`,
};
