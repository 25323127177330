export const PER_PAGE_LOCALITY = 'PER_PAGE_LOCALITY';
export const PAGE_LOCALITY = 'PAGE_LOCALITY';
export const SEARCH_LOCALITY = 'SEARCH_LOCALITY';
export const PAGES_LOCALITY = 'PAGES_LOCALITY';
export const ARRAY_LIST_LOCALITY = 'ARRAY_LIST_LOCALITY';
export const ARRAY_LIST_TO_DROPDOWN_LOCALITY = 'ARRAY_LIST_TO_DROPDOWN_LOCALITY';
export const REFRESH_TABLE_LOCALITY = 'REFRESH_TABLE_LOCALITY';
export const IS_OPEN_MODAL_LOCALITY = 'IS_OPEN_MODAL_LOCALITY';
export const IS_LOADING_SHOW_LOCALITY = 'IS_LOADING_SHOW_LOCALITY';
export const IS_LOADING_FORM_LOCALITY = 'IS_LOADING_FORM_LOCALITY';
export const FORM_TYPE_LOCALITY = 'FORM_TYPE_LOCALITY';
export const FORM_DATA_LOCALITY = 'FORM_DATA_LOCALITY';
