// import ReactJwtDecode from 'jwt-decode'
import { useSelector } from 'react-redux'

const PermissionRoutes = permission => {
	// const accessToken = useSelector(state => state.auth.accessToken)
	const { permissions } = useSelector(state => state.auth.userData)
	
	if (permissions.length > 0) {
		return permissions.find(value => value === permission) ? true : false
	}
	
	// if (accessToken !== '') {
	// 	const decode = ReactJwtDecode(accessToken)
	//
	// 	return decode.permissions.find(value => value === permission) ? true : false
	// }
	
	return false
}

export default PermissionRoutes
