import { initialValuesBuildingType as initialValues } from '../../../../../../../helpers/variablesInitialValues';
import * as types from '../types/buildingType';

const initialState = {
	perPage: 10,
	page: 1,
	pages: 1,
	search: '',
	arrayList: [],
	arrayListToDropdown: [],
	refreshTable: false,
	isOpenModal: false,
	isOpenModalPermission: false,
	isLoadingShow: false,
	isLoadingForm: false,
	formType: '',
	formData: initialValues
};

const buildingTypeReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.PER_PAGE_BUILDING_TYPE:
			return {
				...state,
				perPage: payload
			};
		
		case types.PAGE_BUILDING_TYPE:
			return {
				...state,
				page: payload
			};
		
		case types.PAGES_BUILDING_TYPE:
			return {
				...state,
				pages: payload
			};
		
		case types.SEARCH_BUILDING_TYPE:
			return {
				...state,
				search: payload
			};
		
		case types.ARRAY_LIST_BUILDING_TYPE:
			return {
				...state,
				arrayList: payload
			};
		
		case types.ARRAY_LIST_TO_DROPDOWN_BUILDING_TYPE:
			return {
				...state,
				arrayListToDropdown: payload
			};
		
		case types.REFRESH_TABLE_BUILDING_TYPE:
			return {
				...state,
				refreshTable: payload
			};
		
		case types.IS_OPEN_MODAL_BUILDING_TYPE:
			return {
				...state,
				isOpenModal: payload
			};
		
		case types.IS_LOADING_SHOW_BUILDING_TYPE:
			return {
				...state,
				isLoadingShow: payload
			};
		
		case types.IS_LOADING_FORM_BUILDING_TYPE:
			return {
				...state,
				isLoadingForm: payload
			};
		
		case types.FORM_TYPE_BUILDING_TYPE:
			return {
				...state,
				formType: payload
			};
		
		case types.FORM_DATA_BUILDING_TYPE:
			return {
				...state,
				formData: payload
			};
		
		default:
			return state
	}
};

export default buildingTypeReducer;
