import React from 'react'
import { TextField as MuiTextField } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100% !important',
        '& .MuiFormLabel-root.Mui-focused': {
            fontWeight: '500',
            color: '#1b1b1b',
            fontSize: '18px'
        },
        '& .MuiFormLabel-root.Mui-focused.Mui-error': {
            fontWeight: '500',
            color: '#f44336',
            fontSize: '18px'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #1b1b1b !important'
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottom: '2px solid #f44336 !important'
        },
        '& .MuiInput-underline:hover:before': {
            borderBottom: '2px solid #1b1b1b !important'
        }
    }
}))

export default function TextField(props) {
    const classes = useStyles()
    const { label, name, variant, defaultValue, error, message, ...other } = props

    return (
        <MuiTextField
            label={label}
            name={name}
            variant={variant || "standard"}
            value={defaultValue || ""}
            defaultValue={defaultValue || ""}
            error={error || false}
            helperText={message || ""}
            classes={{ root : classes.root }}
            {...other}
        />
    )
}
