import * as types from '../../actionsTypes/filtered/filteredAdviserTypes'
import { initialValuesFilteredAdviserForm as initialValues } from '../../../helpers/variablesInitialValues'

const initialState = {
	arrayListFavorites: [],
	isOpenCustomerModal: false,
	isLoadingFavorites: false,
	formData: initialValues
}

const filteredAdviser = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.ARRAY_LIST_FAVORITES_FILTERED_ADVISER_FORM:
			return {
				...state,
				arrayListFavorites: payload
			}
		
		case types.IS_OPEN_CUSTOMER_MODAL_FILTERED_ADVISER_FORM:
			return {
				...state,
				isOpenCustomerModal: payload
			}
			
		case types.IS_LOADING_FAVORITES_FILTER_ADVISER_FORM:
			return {
				...state,
				isLoadingFavorites: payload
			}
			
		case types.FORM_DATA_FILTERED_ADVISER_FORM:
			return {
				...state,
				formData: payload
			}
		
		default:
			return state
	}
}

export default filteredAdviser
