import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Display from '../../../../../components/display/Display'
import Feedback from '../../../../../components/feedback/Feedback'
import Controls from '../../../../../components/controls/Controls'
import errorServices from '../../../../../services/errors'
import { swalSuccess } from '../../../../../services/sweetalert'
import axios from '../../../../../services/axios'

const PropertiesOwnersList = ({ active, getPropertyID }) => {
	const tokenType = useSelector( state  => state.auth.tokenType)
	const accessToken = useSelector( state  => state.auth.accessToken)
	const [getLoading, setLoading] = useState(false)
	const [getRecords, setRecords] = useState([])
	const [getPages, setPages] = useState(0)
	const [getTotalRows, setTotalRows] = useState(0)
	const [getPage, setPage] = useState(1)
	const [items, setItems] = useState([])
	
	const handleList = async () => {
		setLoading(true)
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				perPage: 10,
				page: getPage,
				property_id: getPropertyID
			}
			
			const data = await axios.post('dashboard/owners/properties/list', param)
			
			if (data.status === 200) {
				setPages(data.data.data.pages)
				setTotalRows(data.data.data.totalRows)
				setRecords(data.data.data.records)
			}
		} catch (error) {
			await errorServices(error)
		}
		
		setLoading(false)
	}
	
	useEffect(() => {
		handleList()
	}, [getPage, active])
	
	const handleChangePage = (value) => {
		setPage(value)
	}
	
	const handleDeleteItem = async (id) => {
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const data = await axios.delete(`dashboard/owners/properties/${id}`)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				handleList()
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	const handleChange = ({ target }) => {
		const array = [...items]
		const find = array.find(({id}) => id === target.id)
		
		if (find === undefined) {
			setItems([
				...items,
				{id: target.id, percentage: target.value}
			])
		} else {
			array.map(({ id, percentage }, index) => {
				if (id === target.id) {
					array[index].percentage = target.value
				}
			})
		}
	}
	
	useEffect(() => {
	}, [items])
	
	const handleForm = async (e) => {
		e.preventDefault()
		
		try {
			axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
			
			const param = {
				owners: items
			}
			
			const data = await axios.put(`dashboard/owners/properties/${getPropertyID}`, param)
			
			if (data.status === 200) {
				swalSuccess(data.data.data)
				setItems([])
				handleList()
			}
		} catch (error) {
			await errorServices(error)
		}
	}
	
	return (
		<>
			<Grid container spacing={2} className="m-0 animate__animated animate__fadeIn">
				{getTotalRows > 20 && (
					<Grid item xs={12} className="m-0 animate__animated animate__fadeIn">
						<Display.Pagination
							pageCount={getPages}
							pageIndex={getPage}
							handleChangePage={handleChangePage}
						/>
					</Grid>
				)}
				{getLoading && (
					<Grid item xs={12} className="m-0 animate__animated animate__fadeIn">
						<ul className="list-group">
							<li className="list-group-item d-flex justify-content-between align-items-start">
								<div className="ms-2 me-auto">
									<div className="fw-bold">
										<Feedback.Loading type="text" animation="wave" width="300px" height="30px" />
									</div>
								</div>
							</li>
							<li className="list-group-item d-flex justify-content-between align-items-start">
								<div className="ms-2 me-auto">
									<div className="fw-bold">
										<Feedback.Loading type="text" animation="wave" width="300px" height="30px" />
									</div>
								</div>
							</li>
							<li className="list-group-item d-flex justify-content-between align-items-start">
								<div className="ms-2 me-auto">
									<div className="fw-bold">
										<Feedback.Loading type="text" animation="wave" width="300px" height="30px" />
									</div>
								</div>
							</li>
						</ul>
					</Grid>
				)}
				{!getLoading && (
					getRecords.length > 0 && (
						<>
							<Grid item xs={12} className="m-0 animate__animated animate__fadeIn">
								<ul className="list-group">
									{getRecords.map(({properties_owners_id, person_type, company, first_name, last_name, percentage}, index) => (
										<li key={index} className="list-group-item d-flex justify-content-between align-items-start">
											<div className="ms-2 me-auto">
												<div>{person_type != 'Natural' ? company : `${first_name} ${last_name}`}, <span className="fw-bold">(Persona {person_type}) {percentage === null ? '' : `- ${percentage}%` }</span></div>
												<div className="from-group">
													<input
														placeholder="Ingrese ó cambie %"
														className="form-control"
														maxLength={3}
														id={properties_owners_id}
														name="percentage"
														onChange={handleChange}
														type="number"
													/>
												</div>
											</div>
											<span>
                        <Display.Tooltip
                          label="Eliminar"
                          placement="top"
                        >
                            <IconButton
                              type="link"
                              className="color-dark"
                              onClick={() => handleDeleteItem(properties_owners_id)}
                            >
                                <Display.Icons.DeleteOutlinedIcon style={{ fontSize: 24 }} />
                            </IconButton>
                        </Display.Tooltip>
                    </span>
										</li>
									))}
								</ul>
							</Grid>
							<Grid item xs={12} className="m-0 animate__animated animate__fadeIn">
								<div className="float-right">
									<Controls.Button
										label="Guardar"
										color="primary"
										variant="contained"
										endIcon={<Display.Icons.SaveOutlinedIcon />}
										onClick={handleForm}
									/>
								</div>
							</Grid>
						</>
					)
				)}
			</Grid>
			<Feedback.DialogLoading
				getLoading={getLoading}
				setLoading={setLoading}
			/>
		</>
	)
}

export default PropertiesOwnersList
