import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer'
import styles from './slider.module.css'
export const Slider = ({
  show,
  arrayDataMedia,
  closeSlider,
  goToPrevious,
  goToNext,
  currentIndexInitial,
  typeSlider,
  urlFotos360,
}) => {
  const [urlTest, setUrlTest] = useState('')

  const ReactPhoto360 = () => {
    return (
      <ReactPhotoSphereViewer
        src={`${urlTest.replaceAll('http://', 'https://')}?cacheblock=true`}
        height={'50vh'}
        width={'100%'}
      ></ReactPhotoSphereViewer>
    )
  }

  useEffect(() => {
    let testUrl =  arrayDataMedia?.[currentIndexInitial]?.asset?.full_published_path || arrayDataMedia?.[currentIndexInitial]?.asset?.full_path;
    setUrlTest(testUrl)
  }, [arrayDataMedia, currentIndexInitial])

  return (
    <>
      <div className={`${styles.popUp} ${show ? styles.show : ''}`}>
        <div className={`${styles.popUpWrapper} ${show ? styles.show : ''}`}>
          <div className={`${styles.modal}`}>
            <button
              onClick={closeSlider}
              title="Close"
              type="button"
              className={styles.btnClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1={18} y1={6} x2={6} y2={18} />
                <line x1={6} y1={6} x2={18} y2={18} />
              </svg>
            </button>

            <button
              title="Back"
              onClick={goToPrevious}
              className={styles.leftArrow}
            >
              {' '}
              ❰{' '}
            </button>
            <button
              title="Next"
              onClick={goToNext}
              className={styles.rightArrow}
            >
              {' '}
              ❱{' '}
            </button>

            <div className={styles.modalBody}>
              {typeSlider === 'fotos' && (
                <img
                  loading="lazy"
                  src={arrayDataMedia?.[currentIndexInitial]?.asset?.full_path}
                  alt={arrayDataMedia?.[currentIndexInitial]?.asset?.name}
                />
              )}

              {typeSlider === 'videos' && (
                <div>
                  <video
                    controls
                    poster=""
                    src={arrayDataMedia?.[currentIndexInitial]?.asset?.full_path}
                  />
                </div>
              )}

              {typeSlider === 'fotos360' && (
                <div className={styles.boxPhotos360}>
                  {urlTest && <ReactPhoto360 />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Slider.propTypes = {
  show: PropTypes.bool,
  arrayDataMedia: PropTypes.any,
  closeSlider: PropTypes.func,
  goToPrevious: PropTypes.func,
  goToNext: PropTypes.func,
  currentIndexInitial: PropTypes.number,
  typeSlider: PropTypes.string,
  urlFotos360: PropTypes.string,
}
