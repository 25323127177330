import React from 'react'
import { useSelector } from 'react-redux'
import Layout from '../../../../../../components/mui/Layout'
import Feedback from '../../../../../../components/mui/Feedback'
import ImageListComponent from './ImageListComponent'
import VideosToursComponent from './VideosToursComponent'

const DomusComponent = () => {
	const isLoading = useSelector( state => state.propertyDomus.isLoadingForm)
	const formData = useSelector( state => state.propertyDomus.formData)
	const arrayListImages = useSelector( state => state.propertyDomus.arrayListImages)
	const arrayListImages360 = useSelector( state => state.propertyDomus.arrayListImages360)
	const arrayListVideos = useSelector( state => state.propertyDomus.arrayListVideos)
	const arrayListTours = useSelector( state => state.propertyDomus.arrayListTours)
	
	const {
		codeDomus,
		statusCommercialName,
		registerAt,
		updateAt,
		publicationAt,
		consignationAt,
		actionName,
		valueAdminString,
		canonString,
		saleValueString,
		commissionPercentage,
		stateName,
		cityName,
		neighborhoodName,
		zoneName,
		address,
		reference,
		comment,
		latitude,
		longitude,
		numberRoom,
		numberBathroom,
		numberGarage,
		strata,
		enrollment,
		yearConstruction,
		areaConstruction,
		areaTotal,
		areaFront,
		areaBackground,
		typeName,
		destinationName,
		amenitiesName
	} = formData
	
	return (
		<Layout.Card className="border-radius-5 mb-3">
			<Layout.CardContent className="m-0 p-4">
				<div className="row">
					<div className="col-sm-12">
						<div className="font-family-roboto-semibold fs-5 text-center text-dark text-wrap">
							<h5>Información en DOMUS</h5>
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Código Domus:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ codeDomus }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Estado comercial:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ statusCommercialName }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								F. Registro:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ registerAt }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								F. Actualización:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ updateAt }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								F. Publicación:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ publicationAt }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								F. Consignación:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ consignationAt }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Acción:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ actionName }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Valor de Administración:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									${ valueAdminString } COP
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Canon:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									${ canonString } COP
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Valor de Venta:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									${ saleValueString } COP
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Porcentage de comisión:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ commissionPercentage }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Departamento:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ stateName }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Ciudad:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ cityName }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Barrio:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ neighborhoodName }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Zona:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ zoneName }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Dirección:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ address }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Referencia:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ reference }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Comentario:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ comment }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Latitud:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ latitude }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Longitud:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ longitude }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Habitaciones:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ numberRoom }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Baños:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ numberBathroom }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Garage:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ numberGarage }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Estrato:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ strata }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Matrícula:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ enrollment }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Año de Construcción:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ yearConstruction }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Área de Construcción (m²):
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ areaConstruction }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Área Total ó Lote (m²):
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ areaTotal }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Área del Frente (m²):
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ areaFront }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Área del Fondo (m²):
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ areaBackground }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Tipo de propiedad:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ typeName }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Destinación:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ destinationName }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Amenidades:
							</div>
						</div>
						<div className="d-inline float-start w-65">
							{isLoading && (
								<div className="top--8">
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={20}
									/>
								</div>
							)}
							{!isLoading && (
								<div className="font-family-roboto-semibold fs-6 text-dark text-wrap">
									{ amenitiesName }
								</div>
							)}
						</div>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Imágenes:
							</div>
						</div>
					</div>
					<div className="col-sm-12">
						<ImageListComponent
							isLoading={isLoading}
							arrayList={arrayListImages}
						/>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Imágenes 360:
							</div>
						</div>
					</div>
					<div className="col-sm-12">
						<ImageListComponent
							isLoading={isLoading}
							arrayList={arrayListImages360}
						/>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Videos:
							</div>
						</div>
					</div>
					<div className="col-sm-12">
						<VideosToursComponent
							isLoading={isLoading}
							arrayList={arrayListVideos}
						/>
					</div>
					<div className="col-sm-12">
						<div className="d-inline float-start w-35">
							<div className="font-family-roboto-regular fs-6 text-dark text-wrap">
								Tours:
							</div>
						</div>
					</div>
					<div className="col-sm-12">
						<VideosToursComponent
							isLoading={isLoading}
							arrayList={arrayListTours}
						/>
					</div>
				</div>
			</Layout.CardContent>
		</Layout.Card>
	)
}

export default DomusComponent
