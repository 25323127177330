import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../../components/mui/Layout';
import Feedback from "../../../components/mui/Feedback";
import FormComponent from './components/FormComponent';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import '../Auth.scss';

const LoginPage = () => {
  const isLoading = useSelector( ({ loading }) => loading.isLoading);
  
  return (
    <Layout.Page title="Login">
      <Layout.Card className="card form-container">
        <HeaderComponent />
        <Layout.CardContent className="m-0 pt-4 pr-4 pl-4 pb-0">
          <FormComponent />
        </Layout.CardContent>
        <FooterComponent
          to="/forgot-password"
          label="¿Has olvidado la contraseña?"
        />
      </Layout.Card>
      <Feedback.ModalLoading
        isOpen={isLoading}
        title="Iniciando sesión..."
        content="Espere un momento por favor..."
      />
    </Layout.Page>
  );
};

export default LoginPage;
