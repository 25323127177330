import React from 'react'
import Layout from '../components/mui/Layout'

const SidebarRoutes = [
	{
		permission: 'main.menu',
		icon: <Layout.Icons.BarChartIcon height={20} width={20} />,
		label: 'Escritorio',
		path: 'main',
		hasChildren: false,
		children: []
	},
	{
		permission: 'settings.menu',
		icon: <Layout.Icons.SettingsIcon height={20} width={20} />,
		label: 'Ajustes',
		path: null,
		hasChildren: true,
		children: [
			{
				permission: 'permissions.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Permisos',
				path: 'ajustes/permisos',
				hasChildren: false,
				children: []
			},
			{
				permission: 'roles.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Roles',
				path: 'ajustes/roles',
				hasChildren: false,
				children: []
			},
			{
				permission: 'realstates.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Inmobiliarias',
				path: 'ajustes/inmobiliarias',
				hasChildren: false,
				children: []
			},
			{
				permission: 'users.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Usuarios',
				path: 'ajustes/usuarios',
				hasChildren: false,
				children: []
			},
			{
				permission: 'banks.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Bancos',
				path: 'ajustes/bancos',
				hasChildren: false,
				children: []
			},
			{
				permission: 'areas.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Áreas',
				path: 'ajustes/areas',
				hasChildren: false,
				children: []
			},
			{
				permission: 'amenities.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Amenidades',
				path: 'ajustes/amenidades',
				hasChildren: false,
				children: []
			},
			{
				permission: 'projects.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Proyectos',
				path: 'ajustes/proyectos',
				hasChildren: false,
				children: []
			},
			{
				permission: 'keys.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Llaves',
				path: 'ajustes/llaves',
				hasChildren: false,
				children: []
			},
			{
				permission: 'countries.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Países',
				path: 'ajustes/ubicacion/paises',
				hasChildren: false,
				children: []
			},
			{
				permission: 'states.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Departamentos',
				path: 'ajustes/ubicacion/departamentos',
				hasChildren: false,
				children: []
			},
			{
				permission: 'cities.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Ciudades',
				path: 'ajustes/ubicacion/ciudades',
				hasChildren: false,
				children: []
			},
			{
				permission: 'neighborhoods.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Barrios',
				path: 'ajustes/ubicacion/barrios',
				hasChildren: false,
				children: []
			},
			{
				permission: 'localities.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Localidades',
				path: 'ajustes/ubicacion/localidades',
				hasChildren: false,
				children: []
			},
			{
				permission: 'originChannels.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Canal de origen',
				path: 'ajustes/filtrado/canal-de-origen',
				hasChildren: false,
				children: []
			},
			{
				permission: 'contacts.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Contactos',
				path: 'ajustes/filtrado/fuente-de-contacto',
				hasChildren: false,
				children: []
			},
			{
				permission: 'commercialStatus.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Estatus para Comercial',
				path: 'ajustes/comercial/estatus',
				hasChildren: false,
				children: []
			},
			{
				permission: 'buildingTypes.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Tipo de edificaciones',
				path: 'ajustes/edificaciones/tipos',
				hasChildren: false,
				children: []
			},
			{
				permission: 'domus.owners.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Domus Propietarios',
				path: 'ajustes/propietarios/consulta-domus',
				hasChildren: false,
				children: []
			},
			{
				permission: 'domus.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Domus Propiedades',
				path: 'ajustes/propiedades/consulta-domus',
				hasChildren: false,
				children: []
			},
			{
				permission: 'propertyDestinations.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Destinaciones',
				path: 'ajustes/propiedades/destinaciones',
				hasChildren: false,
				children: []
			},
			{
				permission: 'propertyFacades.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Fachadas',
				path: 'ajustes/propiedades/fachadas',
				hasChildren: false,
				children: []
			},
			{
				permission: 'propertyFloors.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Pisos',
				path: 'ajustes/propiedades/pisos',
				hasChildren: false,
				children: []
			},
			{
				permission: 'propertyReserves.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Reservas',
				path: 'ajustes/propiedades/reservas',
				hasChildren: false,
				children: []
			},
			{
				permission: 'propertyRoomServices.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Cuartos de servicio',
				path: 'ajustes/propiedades/cuarto-de-servicio',
				hasChildren: false,
				children: []
			},
			{
				permission: 'propertyStatus.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Estatus',
				path: 'ajustes/propiedades/estatus',
				hasChildren: false,
				children: []
			},
			{
				permission: 'propertyStatusCommercial.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Estatus comercial',
				path: 'ajustes/propiedades/estatus-comercial',
				hasChildren: false,
				children: []
			},
			{
				permission: 'propertyTypes.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Tipos de propiedades',
				path: 'ajustes/propiedades/tipos',
				hasChildren: false,
				children: []
			},
		]
	},
	{
		permission: 'filtereds.menu',
		icon: <Layout.Icons.ListIcon height={20} width={20} />,
		label: 'Filtrado',
		path: null,
		hasChildren: true,
		children: [
			{
				permission: 'filtereds.register.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Registro de casos',
				path: 'filtrado/registro-de-casos',
				hasChildren: false,
				children: []
			},
			{
				permission: 'filtereds.statistic.menu',
				icon: <Layout.Icons.RemoveIcon height={20} width={20} />,
				label: 'Estadísticas',
				path: 'filtrado/estadisticas',
				hasChildren: false,
				children: []
			},
		]
	},
	{
		permission: 'commercial.menu',
		icon: <Layout.Icons.ListIcon height={20} width={20} />,
		label: 'Comercial',
		path: 'comercial',
		hasChildren: false,
		children: []
	},
	{
		permission: 'owners.menu',
		icon: <Layout.Icons.ContactPageIcon height={20} width={20} />,
		label: 'Propietarios',
		path: 'propietarios',
		hasChildren: false,
		children: []
	},
	{
		permission: 'buildings.menu',
		icon: <Layout.Icons.ApartmentIcon height={20} width={20} />,
		label: 'Edificaciones',
		path: 'edificaciones',
		hasChildren: false,
		children: []
	},
	{
		permission: 'properties.menu',
		icon: <Layout.Icons.HomeWorkIcon height={20} width={20} />,
		label: 'Propiedades',
		path: 'propiedades',
		hasChildren: false,
		children: []
	},
]

export default SidebarRoutes

