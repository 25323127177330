import * as types from '../../../actionsTypes/algolia/algoliaCityTypes'
import { getArrayListDropdown } from './services'
import errorServices from '../../../../services/errors'

export const getArrayToDropdownCity = () => (
	async (dispatch) => {
		await dispatch( setIsLoading(true) )
		
		await getArrayListDropdown().then(({ data }) => {
			dispatch( setArrayListToDropdown(data.data) )
		}).catch(error => errorServices(error))
		
		await dispatch( setIsLoading(false) )
	}
)

export const setAlgoliaCityReset = () => (
	async (dispatch) => {
		await dispatch( setSearch('') )
		await dispatch( setArrayListToDropdown([]) )
		await dispatch( setIsLoading(false) )
	}
)

export const setSearch = value => ({
	type: types.SEARCH_CITY,
	payload: value
})

export const setArrayListToDropdown = value => ({
	type: types.ARRAY_LIST_TO_DROPDOWN_ALGOLIA_CITY,
	payload: value
})

export const setIsLoading = value => ({
	type: types.IS_LOADING_ALGOLIA_CITY,
	payload: value
})
