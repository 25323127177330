export const PER_PAGE_BUILDING_TYPE = 'PER_PAGE_BUILDING_TYPE';
export const PAGE_BUILDING_TYPE = 'PAGE_BUILDING_TYPE';
export const SEARCH_BUILDING_TYPE = 'SEARCH_BUILDING_TYPE';
export const PAGES_BUILDING_TYPE = 'PAGES_BUILDING_TYPE';
export const ARRAY_LIST_BUILDING_TYPE = 'ARRAY_LIST_BUILDING_TYPE';
export const ARRAY_LIST_TO_DROPDOWN_BUILDING_TYPE = 'ARRAY_LIST_TO_DROPDOWN_BUILDING_TYPE';
export const REFRESH_TABLE_BUILDING_TYPE = 'REFRESH_TABLE_BUILDING_TYPE';
export const IS_OPEN_MODAL_BUILDING_TYPE = 'IS_OPEN_MODAL_BUILDING_TYPE';
export const IS_LOADING_SHOW_BUILDING_TYPE = 'IS_LOADING_SHOW_BUILDING_TYPE';
export const IS_LOADING_FORM_BUILDING_TYPE = 'IS_LOADING_FORM_BUILDING_TYPE';
export const FORM_TYPE_BUILDING_TYPE = 'FORM_TYPE_BUILDING_TYPE';
export const FORM_DATA_BUILDING_TYPE = 'FORM_DATA_BUILDING_TYPE';
